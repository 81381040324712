// Components
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';

// uuid
import { v4 as uuid } from 'uuid';
import { setFilter, toggleFiltersModal } from '../CalendarSlice';
import { useLogic } from './Filters.logic';

// Style
import './Filters.scss';

export const Filters = () => {
  const {
    teacherCalendarState,
    setSelectedFilter,
    dispatch,
    filters
  } = useLogic();

  return (
    <div className="calendar-filters-container">
      {(teacherCalendarState.filter.classesIds || teacherCalendarState.filter.countries || teacherCalendarState.filter.courses || teacherCalendarState.filter.teachersIds) && (
        <div className="filter-container selected">
          <ButtonBase
            tagName="div"
            onClick={() => dispatch(setFilter({ countries: undefined, courses: undefined, teachersIds: undefined, classesIds: undefined }))}
          >
            <p>Clear all</p>
          </ButtonBase>
        </div>
      )}
      {filters.map((filter) => (
        <ButtonBase
          tagName="div"
          onClick={() => {
            setSelectedFilter(filter.filter);
            dispatch(setFilter({ selectedFilter: filter.filter }));
            dispatch(toggleFiltersModal(true));
          }}
          key={uuid()}
        >
          <div className={`filter-container ${filter.active && 'selected'}`}>
            <p>{filter.filter}</p>
          </div>
        </ButtonBase>
      ))}
    </div>
  );
};
