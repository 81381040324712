// Types
import { TeacherStudentsQuery, TeacherStudentsQueryVariables } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useGetTeacherStudents = ({ filterBy, desc, limit, offset, search, sortBy } : TeacherStudentsQueryVariables) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeacherStudentsQuery, Error> = useQuery<TeacherStudentsQuery, Error>(['teacher-students', filterBy, desc, sortBy, search, offset, limit], async () => client.request(
    gql`
    query teacherAllUsersStat($sortBy: String, $search: String, $offset: Float, $limit: Float, $filterBy: teacherFilterByObject, $desc: Boolean) {
      teacherAllUsersStat(sortBy: $sortBy, search: $search, offset: $offset, limit: $limit, filterBy: $filterBy, DESC: $desc) {
        usersTotal
        allUsersStat {
          id
          name
          takenSessions
          missedSessions
          remainingSessions
          country
          status
          nearest
          picture
          createdAt
          courses {
            name
            planId
            id
          }
        } 
      }
    }
    `,
    {
      filterBy: {
        courses: filterBy.courses,
        userStatus: filterBy.userStatus,
        country: filterBy.country
      },
      sortBy,
      search,
      desc,
      limit,
      offset
    }
  ).catch((error) => console.error(error)));
  return data;
};
