import Button from 'components/General/Button/Button';
import { setFilter, StudentsSlice, toggleFiltersModal } from '../../StudentsSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ClassOfFilter.scss';
import SelectBox from 'components/General/SelectBox/SelectBox';
import moment from 'moment-timezone';

type Option = {
  label: string,
  value: string
};

export const ClassOfFilter = () => {
  const studentsState = useSelector((state: { students: StudentsSlice }) => state.students);
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>();
  const years: Option[] = [];

  const getYears = () => {
    const currentYear = moment().year();
    let year = currentYear - 20;
    for (let i = 0; i < 40; i++) {
      years.push({ label: String(year), value: String(year) });
      year++;
    }
    return years;
  };

  const handleApplyFilters = () => {
    dispatch(setFilter({ classOf: value }));
    dispatch(toggleFiltersModal(false));
  };

  useEffect(() => {
    if (!studentsState.filter.classOf) {
      setValue(undefined);
    }
  }, [studentsState.filter]);

  return (
    <div className="search-filter-container">
      <h2>Class of year</h2>
      <SelectBox
        options={getYears()}
        onChange={(e: any) => setValue(e.value)}
        value={{ label: value, value }}
      />
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
