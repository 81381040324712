import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useCreateCallLog } from 'services/AdminDashboard/Leads/Logs/Mutations/CreateCallLog/useCreateCallLog';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import leads from '../../LeadsSlice';

export const useLogic = () => {
  const dispatch = useDispatch();
  const { leadId } = useParams();
  const { mutateAsync: createCallLog, isLoading: isLoadingCreateCallLog } = useCreateCallLog();
  const [time, setTime] = useState<{ label?: string, code?: string }>();
  const [date, setDate] = useState<Date>();
  const [note, setNote] = useState<string>();
  const queryClient = useQueryClient();
  const { toggleCallLogModal } = leads.actions;

  const hours = [
    { label: '12:00 AM', code: '00:00' },
    { label: '12:30 AM', code: '00:30' },
    { label: '1:00 AM', code: '01:00' },
    { label: '1:30 AM', code: '01:30' },
    { label: '2:00 AM', code: '02:00' },
    { label: '2:30 AM', code: '02:30' },
    { label: '3:00 AM', code: '03:00' },
    { label: '3:30 AM', code: '03:30' },
    { label: '4:00 AM', code: '04:00' },
    { label: '4:30 AM', code: '04:30' },
    { label: '5:00 AM', code: '05:00' },
    { label: '5:30 AM', code: '05:30' },
    { label: '6:00 AM', code: '06:00' },
    { label: '6:30 AM', code: '06:30' },
    { label: '7:00 AM', code: '07:00' },
    { label: '7:30 AM', code: '07:30' },
    { label: '8:00 AM', code: '08:00' },
    { label: '8:30 AM', code: '08:30' },
    { label: '9:00 AM', code: '09:00' },
    { label: '9:30 AM', code: '09:30' },
    { label: '10:00 AM', code: '10:00' },
    { label: '10:30 AM', code: '10:30' },
    { label: '11:00 AM', code: '11:00' },
    { label: '11:30 AM', code: '11:30' },
    { label: '12:00 PM', code: '12:00' },
    { label: '12:30 PM', code: '12:30' },
    { label: '1:00 PM', code: '13:00' },
    { label: '1:30 PM', code: '13:30' },
    { label: '2:00 PM', code: '14:00' },
    { label: '2:30 PM', code: '14:30' },
    { label: '3:00 PM', code: '15:00' },
    { label: '3:30 PM', code: '15:30' },
    { label: '4:00 PM', code: '16:00' },
    { label: '4:30 PM', code: '16:30' },
    { label: '5:00 PM', code: '17:00' },
    { label: '5:30 PM', code: '17:30' },
    { label: '6:00 PM', code: '18:00' },
    { label: '6:30 PM', code: '18:30' },
    { label: '7:00 PM', code: '19:00' },
    { label: '7:30 PM', code: '19:30' },
    { label: '8:00 PM', code: '20:00' },
    { label: '8:30 PM', code: '20:30' },
    { label: '9:00 PM', code: '21:00' },
    { label: '9:30 PM', code: '21:30' },
    { label: '10:00 PM', code: '22:00' },
    { label: '10:30 PM', code: '22:30' },
    { label: '11:00 PM', code: '23:00' },
    { label: '11:30 PM', code: '23:30' },
  ];

  const handleSave = () => {
    if (time && date) {
      createCallLog({
        futureCall: new Date(moment(date).add(time.code, 'hours').format('YYYY-MM-DD HH:mm:ss')),
        note,
        leadId: Number(leadId),
      }).then(() => {
        toast.success('Call log created successfully');
        queryClient.refetchQueries('call-logs');
        dispatch(toggleCallLogModal(false));
        setTime({});
        setDate(undefined);
        setNote('');
      })
        .catch(() => toast.error('Error creating call log'));
    } else {
      toast.error('Please select a time and date');
    }
  };

  return {
    hours,
    time,
    setTime,
    setDate,
    date,
    note,
    setNote,
    handleSave,
    isLoadingCreateCallLog
  };
};
