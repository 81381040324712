import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useComplaint = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async (courseId: number) => client.request(
    gql`
    mutation UserUpdateLeadStatus($courseId: Float!) {
      userUpdateLeadStatus(courseId: $courseId) {
        message
      }
    }
    `,
    {
      courseId,
    }
  ));
  return Mutation;
};
