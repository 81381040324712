// Types
import { PostEvent as IProps } from './PostEvent.types';

// Styling
import Button from 'components/General/Button/Button';
import './PostEvent.scss';

export const PostEvent = ({ title, body, onClick }: IProps) => (
  <div className="event-container">
    <div className="event-top">
      <div className="title">
        <h3>{title}</h3>
      </div>
    </div>
    <div className="event-bottom">
      <div className="event-body">
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </div>
      <div className="event-button">
        <Button
          color="primary"
          onClick={onClick}
        >
          Start
        </Button>
      </div>
    </div>
  </div>
);
