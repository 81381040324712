import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { HOME, SIGNIN } from '../CONSTANTS';
import { useRoles } from 'assets/utils/useRoles';

const RequireAuth = ({ children, authPage, adminPage }: { children: JSX.Element, authPage?: boolean; adminPage?: boolean; }) => {
  const { user } = useAuth();
  const { isAdmin } = useRoles();
  const location = useLocation();

  /**
   * If the route is an auth page and
   * the user does exist then
   * redirect user to home page
  */
  if (authPage && user) {
    return (
      <Navigate
        to={HOME}
        state={{ from: location }}
      />
    );
  }

  /**
   * If the route is an auth page and
   * the user does exist then
   * redirect user to home page
  */
  if (adminPage && !isAdmin) {
    return (
      <Navigate
        to={HOME}
        state={{ from: location }}
      />
    );
  }

  /**
   * If the route is a page that requires
   * the user to be authenticated and he's not then
   * redirect user to sign in page
  */
  if (!authPage && !user) {
    return (
      <Navigate
        to={SIGNIN}
        state={{ from: location }}
      />
    );
  }

  return children;
};
export default RequireAuth;
