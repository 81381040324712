// Styling
import './StudentRecordingCard.scss';

// Types
import { StudentRecordingCard as IProps } from './StudentRecordingCard.types';

// Image
import RecordingAvatar from 'assets/images/recording-avatar.svg';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import moment from 'moment-timezone';
import {
  faPencilAlt as EditIcon,
  faExclamationCircle as ProblemIcon,
  faTrash as DeleteIcon
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StudentRecordingCard = ({ sessionNumber, active, date, edit, remove, problem, duration, status, onClick, onEditClick, onProblemClick, onRemoveClick }:IProps) => (

  <div className="student-recording-card-container">
    <div className="recording-top-container">
      <div className="img-title-container">
        <img
          src={RecordingAvatar}
          alt=""
        />
        <h2>{`Rec-Session ${sessionNumber} ${duration ? `(${duration} ${duration > 1 ? 'hours' : 'hour'})` : ''}`}</h2>
      </div>

      <span className={active ? 'active-span' : 'not-active-span'}>
        <ButtonBase
          tagName="div"
          onClick={onClick}
        >
          <h3>Open</h3>
        </ButtonBase>
      </span>
    </div>
    <div className="recording-bottom-container">
      <h3>{moment(date).format('YYYY-MM-DD')}</h3>
      <div className="right-container">
        { edit && (
          <ButtonBase
            tagName="div"
            onClick={onEditClick}
          >
            <div className="edit">
              <FontAwesomeIcon
                icon={EditIcon}
                className="icon"
                color="#6371E0"
                display={12}
              />
            </div>
          </ButtonBase>
        )}
        { remove && (
          <ButtonBase
            tagName="div"
            onClick={onRemoveClick}
          >
            <div className="remove">
              <FontAwesomeIcon
                icon={DeleteIcon}
                className="icon"
                color="#ec516e"
                display={12}
              />
            </div>
          </ButtonBase>
        )}
        { problem && (
          <ButtonBase
            tagName="div"
            onClick={onProblemClick}
          >
            <div className="problem">
              <h4>Problem</h4>
              <FontAwesomeIcon
                icon={ProblemIcon}
                className="icon"
                color="#EC516E"
                display={12}
              />
            </div>
          </ButtonBase>
        )}
        <div className={`session-status ${active ? 'active-status-span' : 'not-active-status-span'}`}>
          <h4 className={active ? 'active-status-body' : 'not-active-status-body'}>
            {active ? 'Available' : 'Processing'}
          </h4>
        </div>
        <div className={`attendance-status ${status === 'Attended' ? 'active-attendance-status' : 'not-active-attendance-status'}`}>
          <h4 className={status === 'Attended' ? 'active-attendance-status-body' : 'not-active-attendance-status-body'}>
            {status}
          </h4>
        </div>
      </div>
    </div>
  </div>
);
