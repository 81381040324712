// Components
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';

// Icons
import HomeIcon from 'assets/images/home.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft as LeftArrowIcon } from '@fortawesome/free-solid-svg-icons';

// Styiling
import './DashboardSideBar.scss';
import { useNavigate } from 'react-router';
import { HOME } from 'routes/CONSTANTS';
import { useCookies } from 'react-cookie';
import { useState } from 'react';

export const DashboardSideBar = () => {
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['delfina/sidenav/is_collapsed']);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(JSON.parse(cookies['delfina/sidenav/is_collapsed'] || false));
  const handleToggleCollapse = () => setIsCollapsed((prev) => {
    setCookie('delfina/sidenav/is_collapsed', !prev);
    return !prev;
  });

  return (
    <div className={`dashboard-sidebar-container ${isCollapsed ? 'collapse' : ''}`}>
      <div className="top-container">
        <ButtonBase
          tagName="div"
          onClick={() => navigate(HOME)}
        >
          <div className="dashboard-button-container">
            <img
              src={HomeIcon}
              alt="home"
            />
            <h3>Dashboard</h3>
          </div>
        </ButtonBase>
      </div>
      <div className="collapse-container">
        <ButtonBase
          tagName="div"
          onClick={() => handleToggleCollapse()}
        >
          <div className={`collapse-button ${isCollapsed ? 'collapse' : ''}`}>
            <FontAwesomeIcon
              icon={LeftArrowIcon}
              className="arrow-icon"
              color="#6371e0"
            />
            <h3>Collapse</h3>
          </div>
        </ButtonBase>
      </div>
    </div>
  );
};
