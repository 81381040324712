// Components
import Button from 'components/General/Button/Button';

// Styling
import './UpcomingSession.scss';

// Types
import { UpcomingSession as IProps } from './UpcomingSession.types';

export const UpcomingSession = ({ onClick, courseName, time, title, date, disabled }: IProps) => (
  <div className={`upcoming-session-container ${disabled && 'disabled'}`}>
    <div className="right-container">
      <h3>{courseName}</h3>
      <h4 className="date">{date}</h4>
    </div>
    <div className="left-container">
      <h3 className="title">{title}</h3>
      <h4>{time}</h4>
    </div>
    <div className="button-container">
      <Button
        color="white"
        onClick={onClick}
        disabled={disabled}
      >
        Start
      </Button>
    </div>
  </div>
);
