import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useSolveSessionProblem } from 'services/Dashboard/Sessions/Mutations/useSolveSessionProblem';
import { SessionSlice, setSessionDuration } from '../SessionsSlice';

export const useLogic = () => {
  const dispatch = useDispatch();
  const [duration, setDuration] = useState<string>();
  const [status, setStatus] = useState<string>();
  const sessionState = useSelector((state: { sessions: SessionSlice }) => state.sessions);
  const { mutateAsync: solve, isLoading } = useSolveSessionProblem();
  const queryClient = useQueryClient();
  const statusArr = [
    {
      label: 'Absent',
      value: 'ABSENT',
    },
    {
      label: 'Attended',
      value: 'ATTENDED',
    }
  ];

  const handleSolve = () => {
    solve({
      duration: Number(duration?.split(' ')[0]),
      sessionId: sessionState.sessionDuration.sessionId,
      status: statusArr.find((item) => item.label === status)?.value
    }).then(() => {
      dispatch(setSessionDuration({ modal: false }));
      queryClient.refetchQueries('class-sessions');
      setDuration('');
      setStatus('');
    });
  };

  return {
    duration,
    setDuration,
    sessionState,
    handleSolve,
    isLoading,
    statusArr,
    setStatus,
    status
  };
};
