import ListingTable from 'components/AdminDashboard/ListingTable/ListingTable';
import { useLogic } from './LeadNotes.logic';
import './LeadNotes.scss';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash as DeleteIcon } from '@fortawesome/free-solid-svg-icons';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import moment from 'moment-timezone';
import Button from 'components/General/Button/Button';
import Modal from 'components/General/Modal/Modal';
import NewCallLog from '../NewCallLog/NewCallLog';
import { CircleLoader } from 'components/General/Loader/Loader';
import Label from 'components/General/Label/Label';
import UpdateCallLog from '../UpdateCallLog/UpdateCallLog';

export const LeadNotes = () => {
  const {
    Columns,
    data,
    isLoading,
    deleteCallLog,
    isLoadingDeleteLog,
    leadsState,
    dispatch,
    toggleCallLogModal,
    futureCallLabel,
    handleUpdateLog,
    toggleUpdateCallLogModal,
  } = useLogic();
  return (
    <div className="notes-container">
      <div className="header">
        <h3>Call logs</h3>
        <div className="log-button">
          <Button
            color="primary"
            onClick={() => dispatch(toggleCallLogModal(true))}
          >
            Create call log
          </Button>
        </div>
      </div>

      <div className="notes-table-container">
        <ListingTable
          columns={Columns}
          isLoading={isLoading}
          data={data?.adminGetCallLogs?.map((record) => (
            [
              { value: <p className="sub-text">{record.id}</p> },
              { value: <p className="sub-text">{moment(record.createdAt).format('YYYY-MM-DD')}</p> },
              { value: <p className="sub-text comment">{record.note || '-'}</p> },
              { value: (
                <ButtonBase
                  tagName="div"
                  onClick={() => handleUpdateLog(record.id)}
                >
                  <Label color={futureCallLabel(record.futureCall, record.contacted).color}>{record.futureCall ? futureCallLabel(record.futureCall, record.contacted).date : '-'}</Label>
                </ButtonBase>
              ) },
              { value: <p className="sub-text">{`${record.createdBy.first_name} ${record.createdBy.last_name}`}</p> },
              { value:
                isLoadingDeleteLog && (leadsState.callLogId === record.id) ? (
                  <CircleLoader />
                ) : (
                  <ButtonBase
                    tagName="div"
                    onClick={() => deleteCallLog(record.id)}
                  >
                    <FontAwesomeIcon
                      icon={DeleteIcon}
                      className="icon"
                      color="#97ACCC"
                      display={12}
                    />
                  </ButtonBase>
                )
              },
            ]
          ))}
        />
      </div>
      <Modal
        isClosble
        width={800}
        isOpen={leadsState.callLogModal}
        onRequestClose={() => dispatch(toggleCallLogModal(false))}
        fullHeight
      >
        <NewCallLog />
      </Modal>
      <Modal
        isClosble
        width={300}
        isOpen={leadsState.updateCallLogModal}
        onRequestClose={() => dispatch(toggleUpdateCallLogModal(false))}
      >
        <UpdateCallLog />
      </Modal>
    </div>
  );
};
