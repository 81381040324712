// Components
import { DashboardNavbar } from 'components/Dashboard/DashboardNavbar/DashboardNavbar';
import { DashboardSideBar } from 'components/Dashboard/DashboardSideBar/DashboardSideBar';

// Styles
import './LeadPersonalInfoLayout.scss';

import { LeadPersonalInfoPage } from './LeadPersonalInfoPage/LeadPersonalInfoPage';
import SearchModal from 'components/General/SearchModal/SearchModal';
import useLogic from './LeadPersonalInfoLayout.logic';
import { StudentSearchCard } from 'components/General/SearchCards/StudentSearchCard/StudentSearchCard';
import { LeadNotes } from 'pages/Dashboard/Admin/Leads/PersonalInfo/LeadNotes/LeadNotes';

export const LeadPersonalInfoLayout = () => {
  const {
    showSearch,
    leads,
    isSearching,
    setSearchValue,
    setShowSearch,
    handleStudentNavigation,
    userInfo,
  } = useLogic();

  return (
    <div>
      <DashboardNavbar
        onClick={() => setShowSearch(true)}
        name={`${userInfo?.userGetInfo.first_name || ''} ${userInfo?.userGetInfo.last_name || ''}`}
        image={userInfo?.userGetInfo.picture}
      />
      <SearchModal
        isOpen={showSearch}
        onRequestClose={() => setShowSearch(false)}
        onSearch={(value) => setSearchValue(value)}
        isLoading={isSearching}
        searchResults={
          leads?.adminAllLeadsStat.leadsStatistics.map((lead) => ({
            element: <StudentSearchCard
              name={`${lead.firstName} ${lead.lastName}` || ''}
              avatar={`https://eu.ui-avatars.com/api/?background=F1F1F1&color=6371e0&name=${encodeURI(`${lead.firstName} ${lead.lastName}` || '')}&size=80`}
            />,
            onClick: () => handleStudentNavigation(lead.leadId || 0)
          })) || []
        }
      />
      <div className="personal-info-layout-container">
        <div className="side-container">
          <DashboardSideBar />
        </div>
        <div className="body-container">
          <LeadPersonalInfoPage>
            <LeadNotes />
          </LeadPersonalInfoPage>
        </div>
      </div>
    </div>
  );
};
