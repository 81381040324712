import { useEffect, useState } from 'react';

// Types
import { CashFlowItem } from './CashPaymentInstructions.types';

// Components
import Button from 'components/General/Button/Button';

// Logic

// Icons
import bg from 'assets/images/background.png';

// Styling
import './CashPaymentInstructions.scss';
import { setShowPaymentInstructions } from 'pages/Dashboard/DashboardSlice';
import { useDispatch } from 'react-redux';

export const CashPaymentInstructions = () => {
  const [step, setStep] = useState<number>(1);
  const [flowItem, setFlowItem] = useState<CashFlowItem>();
  const dispatch = useDispatch();

  const cashFlow = [
    {
      id: 1,
      image: bg,
      title: 'Step 1',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec nisl vitae, quis tellus pellentesque mauris enim praesent morbi. Et elit elit felis, bibendum eu.'
    },
    {
      id: 2,
      image: bg,
      title: 'Step 2',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec nisl vitae, quis tellus pellentesque mauris enim praesent morbi. Et elit elit felis, bibendum eu.'
    },
    {
      id: 3,
      image: bg,
      title: 'Step 3',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec nisl vitae, quis tellus pellentesque mauris enim praesent morbi. Et elit elit felis, bibendum eu.'
    },
  ];

  useEffect(() => {
    switch (step) {
      case 1:
        setFlowItem(cashFlow?.[0]);
        break;
      case 2:
        setFlowItem(cashFlow?.[1]);
        break;
      case 3:
        setFlowItem(cashFlow?.[2]);
        break;
      default:
        setStep(0);
    }
  }, [step]);

  return (
    <div className="cash-container">
      <h2>Payment Directions</h2>
      <img
        src={flowItem?.image}
        alt=""
      />
      <h2>{flowItem?.title}</h2>
      <p className="mb-3">{flowItem?.body}</p>

      { step === 3 ? (
        <Button
          color="primary"
          onClick={() => dispatch(setShowPaymentInstructions(false))}
        >
          Back to payment
        </Button>
      ) : (
        <Button
          color="primary"
          onClick={() => setStep(step + 1)}
        >
          Next
        </Button>
      )}

      { step === 1 ? (
        <div className="mt-1">
          <Button
            color="primary"
            onClick={() => dispatch(setShowPaymentInstructions(false))}
          >
            Back to payment
          </Button>
        </div>
      ) : (
        <div className="mt-1">
          <Button
            color="primary"
            onClick={() => setStep(step - 1)}
          >
            Back
          </Button>
        </div>

      )}
    </div>
  );
};
