import useWindowDimensions from 'assets/utils/getWindowDimentions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mainCalendar, { TeacherCalendarsSlice, resetTimeSlots, setPlanData, toggleAppointmentViewModal, setDate, setCurrentDate } from './CalendarSlice';
import ClassFilters from './Filters/ClassFilter/ClassFilter';
import { LocationFilter } from './Filters/CountryFilter/LocationFilter';
import { Teacher } from 'services/AdminDashboard/Calendar/types';
import { ClassIdFilter } from './Filters/ClassIdFilter/ClassIdFilter';
import moment from 'moment-timezone';
import './Calendar.scss';
import { useTeacherCalendar } from 'services/Dashboard/Calendar/useTeacherCalendar';

export const useLogic = () => {
  const { height, width } = useWindowDimensions();
  const teacherCalendarState = useSelector((state: { teacherCalendar: TeacherCalendarsSlice }) => state.teacherCalendar);
  const [month, setMonth] = useState(new Date());
  const { data: calendarData, isLoading, isFetching } = useTeacherCalendar({ ...teacherCalendarState.filter, month });
  const { setFilter, toggleFiltersModal } = mainCalendar.actions;
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const [session, setSession] = useState<any>();
  const [teachers, setTeachers] = useState<Teacher[]>();
  const [timeline, setTimeline] = useState<{ min: number, max: number }>({ min: 12, max: 24 });
  const [view, setView] = useState<string>('Day');

  // Calendar Logic
  const resources = [{
    fieldName: 'teacherId',
    title: 'Teacher',
    instances: teachers?.length ? teachers : [{ text: 'No teachers yet', id: 1, color: '#ec516e' }],
  }];

  const grouping = [{
    resourceName: 'teacherId',
  }];

  const groupOrientation = (viewName: any) => viewName.split(' ')[0];

  useEffect(() => {
    if (calendarData) {
      setData(() => calendarData.teacherCalendar?.sessions?.map((item) => ({
        id: item.id,
        title: (
          <div className="calender-appointment">
            <p>{item.course}</p>
            <p>{`Student: ${item.studentName}`}</p>
            <p>{`Teacher: ${item.teacherName}`}</p>
          </div>
        ),
        startDate: item.startDate,
        endDate: item.endDate,
        teacherId: item.teacherId,
        planId: item.planId,
        course: item.course,
        dateTime: item.dateTime,
        sessionNumber: item.sessionNumber,
        acuityCalendarId: item.acuityCalendarId,
        acuityAppTypeIds: item.acuityAppTypeIds,
        teacherName: item.teacherName,
        studentName: item.studentName,
        link: item.teacherLink,
        isAccessible: item.isAccessible
      })));
      setTeachers(() => calendarData.teacherCalendar?.teachers?.map((item) => ({
        text: item.text,
        id: item.id,
        color: item.color,
      })) || [{ text: 'No Teachers found', id: 1 }]);

      if (calendarData.teacherCalendar.sessions.length) {
        // timeline limits
        let min = 24;
        let max = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const item of calendarData?.teacherCalendar?.sessions) {
          console.log(moment(item.endDate).hour());
          // console.log('test', moment('2022-10-25T12:30:00.000Z').hour());
          if (moment(item.startDate).hour() < min) {
            min = moment(item.startDate).hour();
          }
          if (moment(item.endDate).hour() > max) {
            max = moment(item.endDate).hour() + 1;
          }
        }
        console.log({ min, max });
        setTimeline({ min, max });
      }
    }
  }, [calendarData]);

  const generateFilter = (item?: string) => {
    switch (item) {
      case 'Subject':
        return <ClassFilters />;
      case 'Country':
        return <LocationFilter />;
      case 'Class ID':
        return <ClassIdFilter />;
      default:
        return <ClassFilters />;
    }
  };

  // Rescheduling Logic
  useEffect(() => {
    if (session) {
      dispatch(setPlanData({
        id: session?.planId,
        teacherID: session?.teacherId,
        calendarID: session?.acuityCalendarId,
      }));
    }
  }, [session]);

  useEffect(() => {
    if (!teacherCalendarState.rescheduleModal) {
      dispatch(resetTimeSlots());
      dispatch(setPlanData({}));
    }
  }, [teacherCalendarState.rescheduleModal]);

  useEffect(() => {
    dispatch(setDate(teacherCalendarState.currentDate));
  }, [teacherCalendarState.currentDate]);

  useEffect(() => {
    const date = moment().date(moment(teacherCalendarState.currentDate).date()).month(moment(teacherCalendarState.currentDate).month()).year(moment(teacherCalendarState.currentDate).year())
      .toDate();
    setMonth(date);
  }, [moment(teacherCalendarState.currentDate).month()]);

  return {
    height,
    width,
    data,
    setCurrentDate,
    resources,
    grouping,
    groupOrientation,
    setFilter,
    toggleFiltersModal,
    toggleAppointmentViewModal,
    teacherCalendarState,
    dispatch,
    generateFilter,
    isLoading,
    isFetching,
    teachers,
    setSession,
    setPlanData,
    timeline,
    setView,
    view,
    setTimeline,
  };
};
