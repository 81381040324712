// Types
import { PostComment as IProps } from './PostComment.types';

// Styling
import './PostComment.scss';

// Moment
import moment from 'moment-timezone';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV as OptionsIcon,
  faTrash as DeleteIcon } from '@fortawesome/free-solid-svg-icons';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { useState } from 'react';

export const PostComment = ({ image, name, time, messageBody, onRemove, isOwner }: IProps) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div className="post-message-container">
      <div className="message-image">
        <img
          src={image}
          alt=""
        />
      </div>
      <div className="message-container">
        <div className="message-header">
          <div className="message-name">
            <p>{name}</p>
          </div>
          <div className="right-container">
            <div className="message-time">
              <p className="sub-text">{moment(time).fromNow()}</p>
            </div>
            {isOwner && (
              <div className="options-container">
                <FontAwesomeIcon
                  icon={OptionsIcon}
                  className="icon"
                  color="#97ACCC"
                  display={12}
                  onClick={() => setShowOptions(!showOptions)}
                />
                <div className={showOptions ? 'show-tooltip' : 'hide-tooltip'}>
                  <div className="tooltiptext">
                    <ButtonBase
                      tagName="div"
                      onClick={onRemove}
                    >
                      <div className="delete">
                        <FontAwesomeIcon
                          icon={DeleteIcon}
                          className="icon"
                          color="#97ACCC"
                          display={12}
                        />
                        <p>Delete</p>
                      </div>
                    </ButtonBase>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
        <div className="message-body">
          <p className="sub-text">{messageBody}</p>
        </div>
      </div>
    </div>
  );
};
