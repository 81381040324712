// Types
import { StreamPostsQuery } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useGetStreamPosts = (id: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<StreamPostsQuery, Error> = useQuery<StreamPostsQuery, Error>(['streamPosts'], async () => client.request(
    gql`
    query Query($classId: Float!, $limit: Float!, $offset: Float!) {
      userClassPosts(classId: $classId, limit: $limit, offset: $offset) {
        id
        text
        classId
        createdAt
        link
        assignment{
          id
          name
          details
          dueDate
          fullMark
          status
        }
        test{
          id
          name
          details
          dueDate
        }
        postedBy{
          id
          first_name
          last_name
          picture
        }
        comments{
          id
          text
          createdAt
          commentedBy{
            id
            first_name
            last_name
            picture
          }
        }
        attachments {
          id
          attachment
        }
      }
    }
    `,
    {
      classId: id,
      limit: 0,
      offset: 0
    }
  ));
  return data;
};
