import Button from 'components/General/Button/Button';
import { setFilter, toggleFiltersModal } from '../../StudentsSlice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import './SchoolFilter.scss';
import { SchoolsSelectBox } from 'components/General/SelectBox/SelectBox';

export const SchoolFilter = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<string[]>([]);

  const handleApplyFilters = () => {
    dispatch(setFilter({ schools: value }));
    dispatch(toggleFiltersModal(false));
  };

  return (
    <div className="search-filter-container">
      <h2>Search</h2>
      <SchoolsSelectBox
        label="School name"
        placeholder="School name"
        onChange={(e: any) => setValue(e?.label)}
      />
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
