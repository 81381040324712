import Button from 'components/General/Button/Button';
import { TeachersSlice, setFilter, toggleFiltersModal } from '../../TeachersSlice';
import { useEffect, useState } from 'react';
import { CheckboxGroup } from 'react-rainbow-components';
import { useDispatch, useSelector } from 'react-redux';
import './StatusFilter.scss';

export const StatusFilter = () => {
  const dispatch = useDispatch();
  const teachersState = useSelector((state: { teachers: TeachersSlice }) => state.teachers);
  const [values, setVlaues] = useState<string[] | undefined>(teachersState.filter.status);

  const handleApplyFilters = () => {
    dispatch(setFilter({ status: values }));
    dispatch(toggleFiltersModal(false));
  };

  const options = [
    { value: 'Active', label: 'Active', disabled: false },
    { value: 'Inactive', label: 'Inactive', disabled: false },
  ];

  useEffect(() => {
    setVlaues(teachersState.filter.status);
  }, [teachersState.filter.status]);

  return (
    <div className="status-filter-container">
      <h2>Status</h2>
      <div className="filters-container">
        <CheckboxGroup
          options={options}
          value={values}
          onChange={(e) => setVlaues(e)}
          className="checkbox"
        />
      </div>
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
