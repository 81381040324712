import { FC } from 'react';
import { v4 as uuid } from 'uuid';

// Types
import { CheckBoxProps } from './CheckBox.types';

import './CheckBox.scss';

const Checkbox: FC<CheckBoxProps> = ({ label, ...rest }) => {
  const id = uuid();
  return (
    <div className="simple-checkbox">
      <input
        {...rest}
        id={id}
        type="checkbox"
      />
      <label
        htmlFor={id}
        className="icon"
      >
        {}
        <i className="icon-check" />
      </label>
      <div className="label">
        {label}
      </div>
    </div>
  );
};

const Toggle: FC<CheckBoxProps> = ({ ...rest }) => {
  const id = uuid();
  return (
    <div className="toggle-container">
      <label
        className="switch"
        htmlFor={id}
      >
        <input
          {...rest}
          type="checkbox"
          id={id}
        />
        <div className="slider round" />
      </label>
    </div>
  );
};

export default Checkbox;
export { Toggle };
