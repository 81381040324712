import Button from 'components/General/Button/Button';
import { setFilter, toggleFiltersModal } from 'pages/Dashboard/DashboardSlice';
import { useState } from 'react';
import { CheckboxGroup } from 'react-rainbow-components';
import { useDispatch } from 'react-redux';
import './ClassFilter.scss';

const ClassFilters = () => {
  const dispatch = useDispatch();
  const [values, setVlaues] = useState<string[]>([]);

  const handleApplyFilters = () => {
    dispatch(setFilter({ courses: values }));
    dispatch(toggleFiltersModal(false));
  };

  const options = [
    { value: 'SAT', label: 'SAT', disabled: false },
    { value: 'GMAT', label: 'GMAT', disabled: false },
    { value: 'IELTS', label: 'IELTS', disabled: false },
  ];

  return (
    <div className="class-filter-container">
      <h2>Class</h2>
      <div className="filters-container">
        <CheckboxGroup
          options={options}
          value={values}
          onChange={(e) => setVlaues(e)}
          className="checkbox"
        />
      </div>
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default ClassFilters;
