import { countries } from 'assets/files/countries';
import { PostTextField } from 'components/Dashboard/PostTextField/PostTextField';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import SelectBox from 'components/General/SelectBox/SelectBox';
import TextField from 'components/General/TextField/TextField';
import { Controller } from 'react-hook-form';
import { useLogic } from './EditTeacher.logic';
import './EditTeacher.scss';
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt as EditIcon } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/General/Button/Button';
import { CheckboxGroup, PhoneInput } from 'react-rainbow-components';
import { CircleLoader, OverlayLoader } from 'components/General/Loader/Loader';

export const EditTeacher = () => {
  const {
    control,
    errors,
    onSubmit,
    isLoadingUpdateTeacher,
    isLoading,
    image,
    input,
    isUploading,
    handleUpload,
    files,
    options,
    courses,
    setCourses
  } = useLogic();

  return (
    <>
      { isLoading ? (
        <OverlayLoader />
      ) : (
        <>
          <div className="new-teacher-container mb-2">
            <form onSubmit={onSubmit}>
              <div className="right-container">
                <h3>Edit teacher</h3>
                <div className="image-container">
                  <img
                    src={files.length ? `data:image/jpeg;base64, ${files[0].base64}` : image}
                    alt="Profile"
                  />
                  <ButtonBase tagName="div">
                    <span>
                      {isUploading ? (
                        <CircleLoader />
                      ) : (
                        <FontAwesomeIcon
                          icon={EditIcon}
                          className="icon"
                          color="#97ACCC"
                          display={12}
                          onClick={() => {
                            input.current?.click();
                          }}
                        />
                      )}
                      <input
                        ref={input}
                        type="file"
                        name="filetobase64"
                        accept="image"
                        onChange={handleUpload}
                      />
                    </span>
                  </ButtonBase>
                </div>
                <div className="text-fields-contaienr">
                  <Controller
                    control={control}
                    name="firstName"
                    rules={{
                      required: 'This field is required',
                      minLength: {
                        value: 2,
                        message: 'Should be longer than 2 characters'
                      },
                      maxLength: {
                        value: 20,
                        message: 'Cannot be longer than 20 characters'
                      }
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        color="light-gray"
                        variant="rounded"
                        placeholder="First name"
                        id="first-name"
                        label="First name"
                        type="text"
                        onChange={onChange}
                        error={errors?.firstName?.message}
                        defaultValue={value}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="lastName"
                    rules={{
                      required: 'This field is required',
                      minLength: {
                        value: 2,
                        message: 'Should be longer than 2 characters'
                      },
                      maxLength: {
                        value: 20,
                        message: 'Cannot be longer than 20 characters'
                      }
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        color="light-gray"
                        variant="rounded"
                        placeholder="Last name"
                        id="last-name"
                        label="Last name"
                        type="text"
                        onChange={onChange}
                        error={errors?.lastName?.message}
                        defaultValue={value}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: 'This field is required',
                      minLength: {
                        value: 2,
                        message: 'Should be longer than 2 characters'
                      },
                      maxLength: {
                        value: 30,
                        message: 'Cannot be longer than 30 characters'
                      }
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        color="light-gray"
                        variant="rounded"
                        placeholder="Mahdmoud@shyp.studio"
                        id="email"
                        label="Email"
                        type="text"
                        onChange={onChange}
                        error={errors?.email?.message}
                        defaultValue={value}
                        disabled
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="country"
                    rules={{
                      required: {
                        value: true,
                        message: 'This field is required'
                      }
                    }}
                    render={({ fieldState: { error }, field: { onChange, value } }) => (
                      <SelectBox
                        options={countries}
                        label="Country"
                        placeholder="United Arab Emirates"
                        error={error?.message}
                        onChange={onChange}
                        value={value}
                        defaultValue={value}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="city"
                    rules={{
                      required: 'This field is required',
                      minLength: {
                        value: 2,
                        message: 'Should be longer than 2 characters'
                      },
                      maxLength: {
                        value: 20,
                        message: 'Cannot be longer than 20 characters'
                      }
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        color="light-gray"
                        variant="rounded"
                        placeholder="Beirut"
                        id="city"
                        label="City"
                        type="text"
                        onChange={onChange}
                        error={errors?.city?.message}
                        defaultValue={value}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="phone"
                    rules={{
                      required: {
                        value: true,
                        message: 'This field is required'
                      },
                      minLength: {
                        value: 8,
                        message: 'Phone number should be longer than 8 characters'
                      },
                      maxLength: {
                        value: 20,
                        message: 'Phone number cannot be longer than 20 characters'
                      }
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        label="Phone Number"
                        placeholder="Enter your phone number"
                        onChange={onChange}
                        value={value}
                        className={`phone-input ${`${errors?.phone?.phone?.message && 'error'}`}`}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="yearsOfExperience"
                    rules={{
                      required: 'This field is required',
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        color="light-gray"
                        variant="rounded"
                        placeholder="Years of experience"
                        id="yearsOfExperience"
                        label="Years of experience"
                        type="number"
                        onChange={onChange}
                        error={errors?.yearsOfExperience?.message}
                        defaultValue={value}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="tutoringHours"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        color="light-gray"
                        variant="rounded"
                        placeholder="Tutoring hours"
                        id="tutoringHours"
                        label="Tutoring hours"
                        type="number"
                        onChange={onChange}
                        error={errors?.tutoringHours?.message}
                        defaultValue={value}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="left-container">
                <h3>About me</h3>
                <Controller
                  control={control}
                  name="details"
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PostTextField
                      onChange={onChange}
                      defaultValue={value}
                      color="gray"
                      rows={8}
                      variant="round"
                      error={errors.details?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="acuityCalendarId"
                  rules={{
                    required: 'This field is required',
                    minLength: {
                      value: 2,
                      message: 'Should be longer than 2 characters'
                    },
                    maxLength: {
                      value: 20,
                      message: 'Cannot be longer than 20 characters'
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      color="light-gray"
                      variant="rounded"
                      placeholder="Acuity calendar ID"
                      id="acuityId"
                      label="Acuity calendar ID"
                      type="number"
                      onChange={onChange}
                      error={errors?.acuityCalendarId?.message}
                      defaultValue={value}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="clickupFolderId"
                  rules={{
                    required: 'This field is required',
                    minLength: {
                      value: 2,
                      message: 'Should be longer than 2 characters'
                    },
                    maxLength: {
                      value: 20,
                      message: 'Cannot be longer than 20 characters'
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      color="light-gray"
                      variant="rounded"
                      placeholder="ClickUp Folder ID"
                      id="clickup-id"
                      label="ClickUp Folder ID"
                      type="number"
                      onChange={onChange}
                      error={errors?.clickupFolderId?.message}
                      defaultValue={value}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="clickupPk"
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      color="light-gray"
                      variant="rounded"
                      placeholder="ClickUp private key"
                      id="clickupPk"
                      label="ClickUp private key"
                      type="text"
                      onChange={onChange}
                      error={errors?.clickupPk?.message}
                      defaultValue={value}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="rate"
                  rules={{
                    required: 'This field is required',
                    max: {
                      value: 5,
                      message: 'Rate cannot be greater then 5'
                    },
                    min: {
                      value: 0,
                      message: 'Rate cannot be less then 0'
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      color="light-gray"
                      variant="rounded"
                      placeholder="0.00"
                      id="clickupPk"
                      label="Rate"
                      type="text"
                      onChange={onChange}
                      error={errors?.rate?.message}
                      value={value}
                    />
                  )}
                />
                <div className="education-container">
                  <h3 className="pt-3">Educational background</h3>
                  <Controller
                    control={control}
                    name="university"
                    rules={{
                      required: 'This field is required',
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        defaultValue={value}
                        color="light-gray"
                        variant="rounded"
                        placeholder="American university of Beirut"
                        id="university"
                        label="University"
                        type="text"
                        onChange={onChange}
                        error={errors?.university?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="degree"
                    rules={{
                      required: 'This field is required',
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        color="light-gray"
                        variant="rounded"
                        placeholder="Math"
                        id="degree"
                        label="Degree"
                        type="text"
                        onChange={onChange}
                        error={errors?.firstName?.message}
                        defaultValue={value}
                      />
                    )}
                  />
                  <div className="courses-container">
                    <h4>Subjects they teach</h4>
                    <CheckboxGroup
                      options={options}
                      value={courses}
                      onChange={(e) => setCourses(e)}
                      className="checkbox"
                      orientation="horizontal"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <Button
            color="primary"
            type="submit"
            onClick={onSubmit}
            isLoading={isLoadingUpdateTeacher}
            disabled={isUploading}
          >
            Save
          </Button>
        </>
      )}
    </>
  );
};
