// Types
import { PostContainer as IProps } from './PostContainer.types';

// Styling
import './PostContainer.scss';
import { PostTextField } from '../PostTextField/PostTextField';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes as ExitIcon, faPaperPlane as SendIcon, faEllipsisV as OptionsIcon, faPencilAlt as EditIcon, faTrash as DeleteIcon, } from '@fortawesome/free-solid-svg-icons';

// Moment
import moment from 'moment-timezone';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { useLogic } from './PostContainer.logic';
import { EditPost } from '../EditPost/EditPost';

export const PostContainer = ({ id, event, comments, text, link, name, time, userImage, ownerImage, onEdit, onRemove, editable, setEditable, isOwner }: IProps) => {
  const {
    commentText,
    setCommentText,
    handeSendComment,
    showOptions,
    setShowOptions,
    streamState
  } = useLogic();

  return (
    <div className="post-container">
      <div className="post-header">
        <div className="post-header-left">
          <img
            src={ownerImage}
            alt=""
          />
          <div className="name-container">
            <div className="post-header-title">
              <h4>{name}</h4>
            </div>
            <div className="post-header-time">
              <h5>{moment(time).fromNow()}</h5>
            </div>
          </div>
        </div>
        {editable && (streamState.post.id === id) ? (
          <FontAwesomeIcon
            icon={ExitIcon}
            className="exit-icon"
            color="#97ACCC"
            display={12}
            onClick={() => {
              setEditable();
              setShowOptions(!showOptions);
            }}
          />
        ) : (
          <>
            {isOwner && (
              <div className="post-header-right">
                <FontAwesomeIcon
                  icon={OptionsIcon}
                  className="icon"
                  color="#97ACCC"
                  display={12}
                  onClick={() => setShowOptions(!showOptions)}
                />
                <div className={showOptions ? 'show-tooltip' : 'hide-tooltip'}>
                  <div className="tooltiptext">
                    <ButtonBase
                      tagName="div"
                      onClick={onEdit}
                    >
                      <div className="edit">
                        <FontAwesomeIcon
                          icon={EditIcon}
                          className="icon"
                          color="#97ACCC"
                          display={12}
                        />
                        <p>Edit</p>
                      </div>
                    </ButtonBase>
                    <ButtonBase
                      tagName="div"
                      onClick={onRemove}
                    >
                      <div className="delete">
                        <FontAwesomeIcon
                          icon={DeleteIcon}
                          className="icon"
                          color="#97ACCC"
                          display={12}
                        />
                        <p>Delete</p>
                      </div>
                    </ButtonBase>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

      </div>
      {editable && (streamState.post.id === id) ? (
        <EditPost />
      ) : (
        <>
          <div className="post-body">
            <div className="post-text">
              {text}
            </div>
            <div className="post-link">
              {link}
            </div>
            <div className="post-content">
              {event}
            </div>
            <div>
              {comments}
            </div>
          </div>
          <div className="post-bottom">
            <div className="bottom-image">
              <img
                src={userImage}
                alt=""
              />
            </div>
            <div className="post-text-input">
              <div className="input-container">
                <PostTextField
                  placeholder="Start typing..."
                  color="gray"
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  tabIndex={0}
                  typeof="submit"
                />
              </div>
              <div className="icon-container">
                <FontAwesomeIcon
                  icon={SendIcon}
                  className="icon"
                  color="#97ACCC"
                  display={12}
                  onClick={() => handeSendComment(id)}
                  tabIndex={0}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
