import { useEffect, useState } from 'react';

// Components
import { DashboardLayout } from 'layouts/DashboardLayout/DashboardLayout';
import { TabBar } from 'components/Dashboard/TabBar/TabBar';
import { Classes } from './Classes/Classes';

// Styling
import './Dashboard.scss';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardSlice, setAssignment, setFeedbackData } from '../DashboardSlice';
import Modal from 'components/General/Modal/Modal';
import { useGetSessionFeedbacks } from 'services/Dashboard/Sessions/Queries/Feedbacks/useGetSessionFeedbacks';
import { useRoles } from 'assets/utils/useRoles';
import { RateSession } from './Classes/RateSession/RateSession';

const StudentDashboard = () => {
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const { isTeacher } = useRoles();
  const { data: feedbacks } = useGetSessionFeedbacks();
  const location = useLocation();
  const dispatch = useDispatch();
  const tabs = [
    'Overview'
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  useEffect(() => {
    dispatch(setAssignment({}));
  }, [location]);

  useEffect(() => {
    try {
      if (feedbacks?.feedbackSessions[0]) {
        dispatch(setFeedbackData({
          modal: true,
          classId: feedbacks?.feedbackSessions[0].class?.classId,
          sessionId: feedbacks?.feedbackSessions[0].id,
          ratedUserId: isTeacher ? feedbacks?.feedbackSessions[0].teacher.id : feedbacks?.feedbackSessions[0].userId,
          sessionNumber: feedbacks?.feedbackSessions[0].sessionNumber,
          courseName: String(feedbacks?.feedbackSessions[0].class.plan.course.name),
          date: feedbacks?.feedbackSessions[0].dueAt
        }));
      }
    } catch {
      console.error('error');
    }
  }, [feedbacks]);

  return (
    <>
      <Modal
        isOpen={dashboardState.feedbacks.modal}
        onRequestClose={() => dispatch(setFeedbackData({ modal: false }))}
      >
        <RateSession />
      </Modal>
      <DashboardLayout>
        <div className="student-dashboard-container">
          <TabBar
            selectedTab={selectedTab || ''}
            setSelectedTab={setSelectedTab}
            tabs={tabs}
          />
          <Classes />
        </div>
      </DashboardLayout>
    </>
  );
};
export default StudentDashboard;
