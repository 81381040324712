import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { useLogic } from './Filters.logic';
import { v4 as uuid } from 'uuid';
import TextField from 'components/General/TextField/TextField';
import { CircleLoader } from 'components/General/Loader/Loader';
import './Filters.scss';
import Dropdown from 'components/General/Dropdown/Dropdown';

export const Filters = ({ isLoading }: { isLoading: boolean }) => {
  const {
    dashboardState,
    dispatch,
    setFilter,
    setValue,
    value,
    setSelectedFilter,
    toggleFiltersModal,
    filters,
    status,
    setStatus,
    handleStatusFilter
  } = useLogic();
  return (
    <div className="classses-filters-container">
      <div className="left-filters-container">
        {(dashboardState.filter.classStatus || dashboardState.filter.country || dashboardState.filter.courses || dashboardState.filter.search) && (
        <div className="filter-container selected">
          <ButtonBase
            tagName="div"
            onClick={() => {
              dispatch(setFilter({ country: undefined, courses: undefined, search: undefined, classStatus: undefined }));
              setValue('');
              setStatus('All');
            }}
          >
            <p>Clear all</p>
          </ButtonBase>
        </div>
        )}
        {filters.map((filter) => (
          <ButtonBase
            tagName="div"
            onClick={() => {
              setSelectedFilter(filter.filter);
              dispatch(setFilter({ selectedFilter: filter.filter }));
              dispatch(toggleFiltersModal(true));
            }}
            key={uuid()}
          >
            <div className={`filter-container ${filter.active && 'selected'}`}>
              <p>{filter.filter}</p>
            </div>
          </ButtonBase>
        ))}
        <div className="search-filter-container">
          <TextField
            placeholder="Search"
            variant="rounded"
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        { isLoading && (
        <CircleLoader />
        )}
      </div>
      <div className="filters-dropdown">
        <Dropdown
          options={['All', 'Active', 'Cancelled', 'Archive']}
          onChange={(e: any) => handleStatusFilter(e.target.value)}
          value={status}
        />
      </div>
    </div>
  );
};
