// Types
import { CreatePost as IProps } from './CreatePost.types';

// Components
import Button from 'components/General/Button/Button';
import { PostTextField } from '../PostTextField/PostTextField';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip as AttachIcon, faSmile as EmojiIcon, faTimes as ExitIcon } from '@fortawesome/free-solid-svg-icons';
import DocumentIcon from 'assets/images/document-icon.svg';

// Styling
import './CreatePost.scss';

// Emoji picker
import Picker from 'emoji-picker-react';
import { useLogic } from './CreatePost.logic';

// Progressbar
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// uuid
import { v4 as uuid } from 'uuid';

export const CreatePost = ({ image, name }: IProps) => {
  const {
    text,
    showEmojis,
    input,
    files,
    progress,
    isLoading,
    setShowEmojis,
    onEmojiClick,
    handlePost,
    setText,
    handleUpload,
    handleRemoveImage,
    isLoadingCreatePost
  } = useLogic();

  return (
    <div className="create-post-container">
      <div className="create-post-top">
        <div className="create-post-image-container">
          <img
            src={image}
            alt=""
          />
        </div>
        <div className="create-post-body-container">
          <h3>{name}</h3>
          <div className="input-container">
            <PostTextField
              placeholder="Enter text here..."
              onChange={(e) => setText(e.target.value)}
              value={text}
              rows={2}
            />
          </div>
          {files.length > 0 && (
          <div className="uploads-container">
            <div className="student-files-container">
              <h3>Uploaded files</h3>
              <div className="images-container">
                {files.map((file) => (
                  <div
                    className="image-container"
                    key={uuid()}
                  >
                    <FontAwesomeIcon
                      icon={ExitIcon}
                      className="icon"
                      color="#97ACCC"
                      display={12}
                      onClick={() => handleRemoveImage(files.indexOf(file))}
                    />
                    {file.type.includes('image') ? (
                      <img
                        src={`data:image/jpeg;base64, ${file.base64}`}
                        alt=""
                      />
                    ) : (
                      <div className="document-icon">
                        <img
                          src={DocumentIcon}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
      <div className="create-post-bottom">
        <div className="create-post-bottom-attachment">
          <FontAwesomeIcon
            icon={AttachIcon}
            className="icon"
            color="#D8D8D8"
            onClick={() => input.current?.click()}
          />
          <input
            ref={input}
            type="file"
            name="filetobase64"
            multiple
            accept="image/*, .pdf"
            onChange={handleUpload}
          />
          {isLoading && (
          <div style={{ width: 30, height: 30 }}>
            <CircularProgressbar
              value={progress}
              styles={{ path: { stroke: '#6371E0' }, text: { fill: '#6371E0', fontSize: 30 } }}
              text={String(progress)}
            />
          </div>
          )}
        </div>
        <div className="create-post-bottom-button">
          <FontAwesomeIcon
            icon={EmojiIcon}
            className="icon"
            color="#D8D8D8"
            onClick={() => setShowEmojis(!showEmojis)}
          />
          <div className="emojis-container">
            {showEmojis && (
            <Picker
              onEmojiClick={(event: any, emojiObject: any) => onEmojiClick(event, emojiObject)}
            />
            )}
          </div>
          <Button
            color="primary"
            onClick={handlePost}
            disabled={text?.length === 0 || text === undefined || progress > 0 || isLoadingCreatePost}
            isLoading={isLoadingCreatePost}
          >
            Post
          </Button>
        </div>
      </div>
    </div>
  );
};
