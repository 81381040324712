import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Filters = {
  courses?: string[],
  country?: string[],
  status?: string[],
  teacherId?: number,
  selectedFilter?: string
  selectedBox?: string
};

export type Status = {
  leadId?: number,
  modal: boolean,
};

export type PaymentConfirmation = {
  id?: number;
  date?: string;
  attachments?:{
    id: number,
    attachment: string
  }[],
};

export type ManualPayment = {
  classId?: number,
  modal?: boolean,
};

export type Payment = {
  classId?: number,
  modal?: boolean,
  cashPaymentModal?: boolean,
  paymentConfirmation?: PaymentConfirmation,
  chosePaymentMethod?: boolean,
  sessions?: number,
  course?: string,
};

export interface LeadsSlice {
  filter: Filters;
  filtersModal: boolean;
  callLogModal: boolean;
  updateCallLogModal: boolean;
  status: Status;
  callLogId?: number;
  payment: Payment;
  manualPayment: ManualPayment
}

const initialState: LeadsSlice = {
  filter: {
    status: ['New', 'Chose subject', 'Entered info', 'Chose a plan', 'Scheduled', 'Help in scheduling', 'Need payment review'],
    selectedBox: 'Pending leads'
  },
  filtersModal: false,
  callLogModal: false,
  updateCallLogModal: false,
  status: { modal: false },
  payment: {
    modal: false,
    cashPaymentModal: false,
    chosePaymentMethod: false,
  },
  manualPayment: { modal: false }
};

const leads = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    toggleFiltersModal: (state, { payload }: PayloadAction<boolean>) => {
      state.filtersModal = payload;
    },
    setFilter: (state, { payload }: PayloadAction<Filters>) => {
      state.filter = { ...state.filter, ...payload };
    },
    toggleCallLogModal: (state, { payload }: PayloadAction<boolean>) => {
      state.callLogModal = payload;
    },
    setCallLogId: (state, { payload }: PayloadAction<number>) => {
      state.callLogId = payload;
    },
    toggleUpdateCallLogModal: (state, { payload }: PayloadAction<boolean>) => {
      state.updateCallLogModal = payload;
    },
    setUpdateStatus: (state, { payload }: PayloadAction<Status>) => {
      state.status = payload;
    },
    setPayment: (state, { payload }: PayloadAction<Payment>) => {
      state.payment = payload;
    },
    setManualPayment: (state, { payload }: PayloadAction<ManualPayment>) => {
      state.manualPayment = { ...state.manualPayment, ...payload };
    }
  },
});

export default leads;
export const {
  setFilter,
  toggleFiltersModal,
  toggleCallLogModal,
  setCallLogId,
  toggleUpdateCallLogModal,
  setUpdateStatus,
  setPayment,
  setManualPayment
} = leads.actions;
