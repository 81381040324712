// Types
import { NotificationCard as IProps } from './NotificationCard.types';

// Styling
import 'assets/scss/spacing.scss';
import 'assets/scss/typography.scss';
import './NotificationCard.scss';

export const NotificationCard = ({ title, createdAt, message }: IProps) => (
  <div className="notification-card">
    <div className="top-container">
      <div className="title-container">
        <p>{title}</p>
      </div>
      <div className="date-container">
        <p className="sub-text">{createdAt}</p>
      </div>
    </div>
    <div className="bottom-container">
      <p>{message}</p>
    </div>
  </div>
);
