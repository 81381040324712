// Components
import OnboardingLayout from 'layouts/OnboardingLayout/OnboardingLayout';
import FamilyInfo from './Steps/FamilyInfo/FamilyInfo';
import PersonalInfo from './Steps/PersonalInfo/PersonalInfo';
import { Payment } from './Steps/Payment/Payment';
import SchoolInfoSAT from './Steps/SchoolInfo/SchoolInfoSAT/SchoolInfo';
import SchoolInfoGMAT from './Steps/SchoolInfo/SchoolInfoGMAT/SchoolInfo';
import SchoolInfoIELTS from './Steps/SchoolInfo/SchoolInfoIELTS/SchoolInfo';
import Schedule from './Steps/Schedule/Schedule';

// Redux
import Plans from './Plans/Plans';
import { useLogic } from './Onboarding.logic';
import { OverlayLoader } from 'components/General/Loader/Loader';

export default () => {
  const { onboardingState, className } = useLogic();

  const getSchoolComponent = () => {
    if (className || onboardingState.plan.course) {
      switch (className || onboardingState.plan.course) {
        case 'SAT':
          return <SchoolInfoSAT />;
        case 'GMAT':
          return <SchoolInfoGMAT />;
        case 'IELTS':
          return <SchoolInfoIELTS />;
        default:
          return <SchoolInfoSAT />;
      }
    }
    return 'Something is wrong';
  };

  const generateStep = () => {
    if (onboardingState?.userSteps.length === 6) {
      switch (onboardingState.activeStep) {
        case 1:
          return <PersonalInfo />;
        case 2:
          return <FamilyInfo />;
        case 3:
          return getSchoolComponent();
        case 4:
          return <Plans />;
        case 5:
          return <Schedule />;
        case 6:
          return <Payment />;
        default:
          return <PersonalInfo />;
      }
    }
    if (onboardingState?.userSteps.length === 5) {
      switch (onboardingState.activeStep) {
        case 1:
          return <PersonalInfo />;
        case 2:
          return getSchoolComponent();
        case 3:
          return <Plans />;
        case 4:
          return <Schedule />;
        case 5:
          return <Payment />;
        default:
          return <PersonalInfo />;
      }
    } else {
      return <OverlayLoader />;
    }
  };

  return (
    <OnboardingLayout steps={onboardingState?.userSteps}>
      {onboardingState?.userSteps && generateStep()}
    </OnboardingLayout>
  );
};
