import { TableColumn } from 'components/Dashboard/Table/Table.types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClassFilters from '../Filters/ClassFilter/ClassFilter';
import { StatusFilter } from '../Filters/StatusFilter/StatusFilter';
import { StudentsSlice } from '../StudentsSlice';
import { useGetTeacherStudents } from 'services/Teacher/Students/Queries/TeacherStudents/useGetTeacherStudents';
import { useNavigate } from 'react-router';
import { LocationFilter } from '../Filters/CountryFilter/LocationFilter';

export const useLogic = () => {
  const studentsState = useSelector((state: { teacherStudents: StudentsSlice }) => state.teacherStudents);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddPatient, setShowAddPatient] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [sortBy, setSortBy] = useState('created_at');
  const [desc, setDesc] = useState(true);
  const { data, isLoading: isLoadingTableData, isFetching } = useGetTeacherStudents({ search: studentsState.filter.search, filterBy: studentsState.filter, offset, sortBy, desc, limit: 20 });
  const Columns: TableColumn[] = [
    {
      value: 'Name',
      sortable: true,
      key: 'Name',
      align: 'left'
    },
    {
      value: 'Subject',
      sortable: true,
      key: 'Courses taken',
    },
    {
      value: 'Status',
      sortable: true,
      key: 'Status'
    },
    {
      value: 'Country',
      sortable: true,
      key: 'Country'
    },
    {
      value: 'Completed sessions',
      sortable: true,
      key: 'Sessions taken',
    },
    {
      value: 'Remaining sessions',
      sortable: true,
      key: 'Remaining sessions',
    },
    {
      value: 'Next session',
      sortable: true,
      key: 'Next session',
    },
  ];

  // export enum ColumnToSortBy {
  //   NAME = "Name",
  //   COURSES_TAKEN = "Courses taken",
  //   STATUS = "Status",
  //   COUNTRY = "Country",
  //   TAUGHT_BY = "Taught by",
  //   REMAINING_SESSIONS = "Remaining sessions",
  //   SESSIONS_TAKEN = "Sessions taken",
  //   NEXT_SESSION = "Next session",
  //   CREATED_AT = "Created at",
  // }

  const generateFilter = (item?: string) => {
    switch (item) {
      case 'Subject':
        return <ClassFilters />;
      case 'Status':
        return <StatusFilter />;
      case 'Country':
        return <LocationFilter />;
      default:
        return <ClassFilters />;
    }
  };

  return {
    generateFilter,
    studentsState,
    dispatch,
    showAddPatient,
    setShowAddPatient,
    Columns,
    data,
    isLoadingTableData,
    offset,
    setOffset,
    sortBy,
    setSortBy,
    setDesc,
    navigate,
    isFetching
  };
};
