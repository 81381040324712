// Components
import { StudentCourseCard } from 'components/Dashboard/StudentCourseCard/StudentCourseCard';
import { TodoComponent } from 'components/Dashboard/TodoComponent/TodoComponent';
import { UpcomingSession } from 'components/Dashboard/UpcomingSession/UpcomingSession';
import { ClassesLayout } from 'layouts/ClassesLayout/ClassesLayout';

// Grid system
import { Col, Row } from 'react-grid-system';

// Styling
import './Classes.scss';

// uuid
import { v4 as uuid } from 'uuid';
import { useLogic } from './Classes.logic';
import moment from 'moment-timezone';
import DotsLoader from 'components/General/Loader/Loader';
import Modal from 'components/General/Modal/Modal';
import { ONBOARDING_COURSES } from 'routes/CONSTANTS';
import { setCashTransaction, togglePaymentModal } from '../../DashboardSlice';
import { Payment } from './Payment/Payment';
import { CashPayment } from './CashPayment/CashPayment';
import { FinalTest } from './FinalTest/FinalTest';
import { TargetScore } from './TargetScore/TargetScore';

export const Classes = () => {
  const {
    studentClasses,
    handleCourseChange,
    userNearestSession,
    isLoadingNearestSession,
    dashboardState,
    dispatch,
    isLoading,
    handleClick,
    userDrafts,
    isLoadingDrafts,
    handleDraft,
    handleRemoveDraft,
    setPlanData,
    isLoadingUnpaidClasses,
    unpaidClasses,
    setPayment,
    isLoadingRemoveDraft,
    toggleCashPaymentModal,
    finalTestsData,
    isLoadingFinalTests,
    setFinalTest,
    setTargetScore,
  } = useLogic();

  return (
    <div className="student-classes-container">
      <ClassesLayout sidebarContent={(
        <div className="sidebar-sessions-container">
          <h2>Upcoming sessions</h2>
          <div className="sidebar-session-container">
            {!isLoadingNearestSession ? (
              <>
                {Number(userNearestSession?.userNearestSession.length) > 0 ? (
                  userNearestSession?.userNearestSession.map((session) => (
                    <div
                      className="session-container"
                      key={session.id}
                    >
                      <UpcomingSession
                        disabled={session.studentLink === null}
                        title={`Session ${session.sessionNumber}`}
                        courseName={String(session.class.plan.course.name)}
                        time={`${moment(session.dueAt).format('h:mm')} - ${moment(session.dueAt).add(session.duration, 'h').format('h:mm a')}`}
                        date={moment(session.dueAt).format('MM/DD/YYYY')}
                        onClick={() => handleClick(session.studentLink)}
                      />
                    </div>
                  ))
                ) : (
                  <h3>No sessions yet.</h3>
                )}
              </>
            ) : (
              <DotsLoader />
            )}
          </div>
        </div>
      )}
      >
        <div className="todos-main-container">
          {isLoadingDrafts || isLoadingUnpaidClasses || isLoadingNearestSession || isLoadingFinalTests ? (
            <DotsLoader />
          ) : (
            <>
              {
                (
                  Number(userDrafts?.userViewDrafts.length) > 0
                || Number(unpaidClasses?.userTodoUnpaidClasses.length) > 0
                || Number(finalTestsData?.finalTest.length) > 0
                || Number(finalTestsData?.targetScore.length) > 0) && (
                <div className="todos-container">
                  <h2>To do</h2>
                  {unpaidClasses?.userTodoUnpaidClasses.map((todo) => (
                    <TodoComponent
                      key={todo.id}
                      rejectionMessage={todo.cashTransactions?.[0]?.rejectionReason}
                      body={`Pay for your ${todo.plan.course.name} class`}
                      status={todo.cashTransactions?.[0]?.status}
                      buttonText={todo.cashTransactions.length ? 'Edit' : 'Pay'}
                      onClick={() => {
                        const data = {
                          id: todo.id,
                          classId: todo.id,
                          className: todo.plan.course.name,
                          price: todo.plan.price,
                          session: todo.plan.sessions_count,
                          customerStripeId: todo.user.stripeCustomerId
                        };
                        dispatch(setPayment(data));
                        if (todo.cashTransactions.length) {
                          dispatch(toggleCashPaymentModal(true));
                          dispatch(setCashTransaction(todo.cashTransactions));
                        } else {
                          dispatch(togglePaymentModal(true));
                        }
                      }}
                    />

                  ))}
                  {userDrafts?.userViewDrafts && (
                    userDrafts.userViewDrafts.map((draft) => (
                      <div
                        key={draft.id}
                      >
                        <TodoComponent
                          isRemovable
                          isLoading={isLoadingRemoveDraft}
                          body={`Continue your ${draft.course?.name} course application.`}
                          buttonText="Continue"
                          onClick={() => {
                            const data = {
                              id: draft.plan?.id,
                              courseID: draft.course?.id,
                              sessionsCount: draft.plan?.sessions_count,
                              price: draft.plan?.price,
                              course: draft.course?.name,
                            };
                            handleDraft(data);
                          }}
                          onRemove={() => handleRemoveDraft(draft.id)}
                        />
                      </div>
                    ))
                  )}
                  {finalTestsData?.finalTest.map((item) => (
                    <TodoComponent
                      key={item.id}
                      body={`Set your final exam date for ${item.plan.course.name} class #${item.classId}`}
                      buttonText="Set"
                      onClick={() => dispatch(setFinalTest({ id: item.id, modal: true }))}
                    />
                  ))}
                  {finalTestsData?.targetScore.map((item) => (
                    <TodoComponent
                      key={item.id}
                      body={`Set your final exam target score for ${item.plan.course.name} class #${item.classId}`}
                      buttonText="Set"
                      onClick={() => dispatch(setTargetScore({ id: item.id, modal: true, course: item.plan.course.name }))}
                    />
                  ))}
                </div>
                )
              }
            </>
          )}
        </div>
        <div className="next-session-container">
          {!isLoadingNearestSession && (
            <>
              {Number(userNearestSession?.userNearestSession.length) > 0 ? (
                <>
                  <h2>Next session</h2>
                  <div
                    className="session-container"
                    key={userNearestSession?.userNearestSession[0]?.id}
                  >
                    <UpcomingSession
                      disabled={userNearestSession?.userNearestSession[0]?.studentLink === null}
                      title={`Session ${userNearestSession?.userNearestSession[0]?.sessionNumber}`}
                      courseName={String(userNearestSession?.userNearestSession[0]?.class.plan.course.name)}
                      time={`${moment(userNearestSession?.userNearestSession[0]?.dueAt).format('h:mm')} - ${moment(userNearestSession?.userNearestSession[0]?.dueAt).add(1, 'h').format('h:mm a')}`}
                      date={moment(userNearestSession?.userNearestSession[0]?.dueAt).format('MM/DD/YYYY')}
                      onClick={() => handleClick(userNearestSession?.userNearestSession[0]?.studentLink || '')}
                    />
                  </div>
                </>
              ) : (
                <h3>No sessions yet.</h3>
              )}
            </>
          )}
        </div>
        <div className="classes-container">
          <div className="cards-container">
            <h2>Your subjects</h2>
            {isLoading ? (
              <DotsLoader />
            ) : (
              <Row
                style={{ margin: 0, rowGap: 20 }}
                className="cards"
              >
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  style={{ paddingRight: 60, paddingLeft: 0, columnGap: 20 }}
                >
                  <StudentCourseCard
                    courseName="Take new subjects"
                    onClick={() => {
                      window.location.href = `${ONBOARDING_COURSES}`;
                      dispatch(setPlanData({ isDraft: false }));
                    }}
                  />
                </Col>
                {studentClasses?.userClassView.map((item) => (
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    xl={6}
                    style={{ paddingRight: 60, paddingLeft: 0, columnGap: 20 }}
                    key={uuid()}
                  >
                    <StudentCourseCard
                      disabled={!item.isAccessible}
                      message={(!(item.isPaid) && item.status !== 'Cancelled') ? 'Please pay to unlock your course.' : ''}
                      courseName={item.plan.course.name}
                      body={item.status}
                      courseID={item.classId}
                      teacherName={`${item.sessions?.[0]?.teacher.user.first_name} ${item.sessions?.[0]?.teacher.user.last_name}`}
                      onClick={() => {
                        if (item.isAccessible) {
                          handleCourseChange(item.plan.course.name, item.classId, item.id);
                        }
                      }}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </div>
        </div>
      </ClassesLayout>
      <Modal
        isClosble
        isOpen={dashboardState.paymentModal}
        onRequestClose={() => dispatch(togglePaymentModal(false))}
      >
        <Payment />
      </Modal>
      <Modal
        isClosble
        isOpen={dashboardState.cashPaymentModal}
        onRequestClose={() => dispatch(toggleCashPaymentModal(false))}
      >
        <CashPayment />
      </Modal>
      <Modal
        isClosble
        isOpen={dashboardState.finalTest.modal}
        onRequestClose={() => dispatch(setFinalTest({ modal: false }))}
      >
        <FinalTest />
      </Modal>
      <Modal
        isClosble
        isOpen={dashboardState.targetScore.modal}
        onRequestClose={() => dispatch(setTargetScore({ modal: false }))}
      >
        <TargetScore />
      </Modal>
    </div>

  );
};
