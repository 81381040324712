import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Step = {
  active: boolean,
  id: number,
  order: number,
  name: string,
};

export type Plan = {
  id?: number,
  sessionsCount?: number,
  teacherID?: number,
  typeID?: number,
  calendarID?: number,
  sessionID?: number,
  duration?: string,
  sessionNumber?: number;
  hours?: number;
  price?: number,
  course?: 'SAT' | 'GMAT' | 'IELTS' | string,
  courseID?: number;
  classID?: number;
  isPayingCash?: boolean;
  isDraft?: boolean;
  sessionDate?: string;
  sessionLength?: number;
};

type UserInfo = {
  firstName?: string;
  lastName?: string;
  email?: string;
  birthday?: string;
  country?: string;
  schoolName?: string;
  graduationYear?: string;
  guardainName?: string;
  guardainNumber?: string;
  guardainEmail?: string;
  phoneNumber?: string;
  info?: {
    done_courses?: {
      IELTS: CourseGrades,
      SAT: CourseGrades,
      GMAT: CourseGrades
    }
  }
};

type CourseGrades = {
  taken: boolean,
  scores: {
    englishScore?: number,
    totalScore?: number,
    mathScore?: number,
    verbalScore?: number,
    QuantitativeScore?: number,
    reading?: number,
    writing?: number,
    speaking?: number,
    listening?: number
  }
};

export interface OnboardingSlice {
  userSteps: Step[],
  activeStep: number,
  selectedTimeSlots: TimeSLot[],
  plan: Plan,
  chosePaymentMethod: boolean;
  paymentMethod: string;
  userInfo: UserInfo;
  showComplaintModal: boolean;
  selectedTeacher: boolean;
  showConfirmation: boolean,
  totalTime: number,
  manualPaymentConfirmationModal: boolean
}

export type TimeSLot = {
  datetime: string,
  appointmentTypeId?: number,
  totalTime?: string,
  length?: number
};

const initialState: OnboardingSlice = {
  userSteps: [],
  activeStep: 1,
  selectedTimeSlots: [],
  plan: {
    id: undefined,
    sessionsCount: undefined,
    teacherID: undefined,
    typeID: undefined,
    calendarID: undefined,
    sessionNumber: undefined,
    price: undefined,
    course: 'SAT',
    isPayingCash: false,
    isDraft: false,
    courseID: 1
  },
  chosePaymentMethod: false,
  paymentMethod: '',
  userInfo: {},
  showComplaintModal: false,
  selectedTeacher: false,
  showConfirmation: false,
  totalTime: 0,
  manualPaymentConfirmationModal: false
};

const onboarding = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setItems: (state, { payload }: PayloadAction<Step[]>) => {
      state.userSteps = payload;
    },
    setActive: (state, { payload }: PayloadAction<number>) => {
      state.activeStep = payload;
    },
    nextPage: (state) => {
      state.activeStep += 1;
    },
    previousPage: (state) => {
      // FIXME we should use step ids instead of index
      if (state.activeStep === (state.plan.course === 'IELTS' ? 4 : 5)) {
        state.plan.isDraft = false;
      }
      state.activeStep -= 1;
    },
    toggleTimeSlot: (state, { payload }: PayloadAction<TimeSLot>) => {
      const index = state.selectedTimeSlots.findIndex((item) => item.datetime === payload.datetime);
      if (index !== -1) {
        state.selectedTimeSlots.splice(index, 1);
      } else {
        state.selectedTimeSlots.push(payload);
      }
    },
    removeTimeSlot: (state, { payload }: PayloadAction<TimeSLot>) => {
      state.selectedTimeSlots = state.selectedTimeSlots.filter((item) => item.datetime !== payload.datetime);
    },
    setPlanData: (state, { payload }: PayloadAction<Plan>) => {
      state.plan = {
        ...state.plan,
        ...payload,
      };
    },
    setChosePaymentMethod: (state, { payload }: PayloadAction<boolean>) => {
      state.chosePaymentMethod = payload;
    },
    setPaymentMethod: (state, { payload }: PayloadAction<string>) => {
      state.paymentMethod = payload;
    },
    updateUserInfo: (state, { payload }: PayloadAction<UserInfo>) => {
      state.userInfo = { ...state.userInfo, ...payload };
    },
    resetTimeSlots: (state) => {
      state.selectedTimeSlots = [];
    },
    setComplaintModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showComplaintModal = payload;
    },
    setSelectedTeacher: (state, { payload }: PayloadAction<boolean>) => {
      state.selectedTeacher = payload;
    },
    setShowConfirmation: (state, { payload }: PayloadAction<boolean>) => {
      state.showConfirmation = payload;
    },
    setManualPaymentConfirmationModal: (state, { payload }: PayloadAction<boolean>) => {
      state.manualPaymentConfirmationModal = payload;
    },
    setTotalTime: (state, { payload }: PayloadAction<number>) => {
      state.totalTime = payload;
    },
  },
});

export default onboarding;
