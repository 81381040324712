// Types
import { AdminDraftsQuery } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminDraft = (userId: number, enabled?: boolean) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<AdminDraftsQuery, Error> = useQuery<AdminDraftsQuery, Error>(['adminDraft'], async () => client.request(
    gql`
    query AdminGetUserDrafts($userId: Float!) {
      adminGetUserDrafts(userId: $userId) {
        id
        userId
        planId
        courseId
        createdAt
        updatedAt
        plan {
          name
          id
          sessions_count
          hours_count
          price
        }
        course {
          name
          id
        }
      }
    }
    `,
    {
      userId
    }
  ).catch((error) => console.error(error)), { enabled });
  return data;
};
