import { useGetHomeworkStat } from 'services/Dashboard/Assignments/Queries/AssignmentsStatistics/useGetHomeworkStat';

export const useLogic = () => {
  const { data: statistics, isLoading } = useGetHomeworkStat();

  return {
    statistics,
    isLoading
  };
};
