// Types
import { DashboardLayout as IProps } from './DashboardLayout.types';

// Components
import { DashboardNavbar } from 'components/Dashboard/DashboardNavbar/DashboardNavbar';
import { DashboardSideBar } from 'components/Dashboard/DashboardSideBar/DashboardSideBar';

// Styles
import './DashboardLayout.scss';

// Grid system

// Context
import { useUserInfo } from 'services/Onboarding/General/useOnboarding';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { setAssignment } from 'pages/Dashboard/DashboardSlice';

export const DashboardLayout = ({ children }: IProps) => {
  const { data: userInfo } = useUserInfo();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setAssignment({}));
  }, [location]);

  return (
    <div>
      <DashboardNavbar
        name={`${userInfo?.userGetInfo.first_name || ''} ${userInfo?.userGetInfo.last_name || ''}`}
        image={userInfo?.userGetInfo.picture}
      />
      <div className="dashboard-container">
        <div className="sidebar-container">
          <DashboardSideBar />
        </div>
        <div className="body-container">
          {children}
        </div>
      </div>
    </div>
  );
};
