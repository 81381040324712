import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as ExitIcon } from '@fortawesome/free-solid-svg-icons';
import { Editor } from '@tinymce/tinymce-react';
import Button from 'components/General/Button/Button';
import TextField from 'components/General/TextField/TextField';
import { Controller } from 'react-hook-form';
import { DatePicker } from 'react-rainbow-components';
import { useLogic } from './NewPracticeTest.logic';
import './NewPracticeTest.scss';
import { NewPracticeTest as IProps } from './NewPracticeTest.types';
import { v4 as uuid } from 'uuid';
import DocumentIcon from 'assets/images/document-icon.svg';

// Progressbar
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { PostTextField } from 'components/Dashboard/PostTextField/PostTextField';

export const NewPracticeTest = ({ closeModal }: IProps) => {
  const {
    control,
    errors,
    onSubmit,
    setValue,
    getValues,
    handleChange,
    editorRef,
    className,
    classId,
    editorInitialValue,
    isLoadingCreateTest,
    isLoadingUpdateTest,
    practiceTestState,
    input,
    isUploading,
    progress,
    handleUpload,
    files,
    handleRemoveUploadedFile,
    isImage,
    testURLs,
    handleRemoveNewFile
  } = useLogic();

  return (
    <div className="new-assignment-container">
      <div className="title">
        <h2>{practiceTestState.editPracticeTestId ? 'Edit practice test' : 'New practice test'}</h2>
        <h2>{(practiceTestState?.reusePracticeTest?.id && !practiceTestState.editPracticeTestId) && (`(Reuse ${className} class #${classId})`)}</h2>
      </div>
      <form onSubmit={onSubmit}>
        <Controller
          control={control}
          name="name"
          rules={{
            required: 'This field is required',
            minLength: {
              value: 2,
              message: 'Practice test name should be longer than 2 characters'
            },
            maxLength: {
              value: 30,
              message: 'Practice test cannot be longer than 30 characters'
            }
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              color="light-gray"
              variant="rounded"
              placeholder={practiceTestState?.reusePracticeTest?.name ? practiceTestState.reusePracticeTest.name : 'Practice test 6'}
              id="practice-name"
              label="Practice test"
              type="text"
              onChange={onChange}
              error={errors?.name?.message}
              value={value}
            />
          )}
        />
        <div className="text-fields-container">
          <div className="date-container">
            <Controller
              control={control}
              name="date"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                }
              }}
              render={() => (
                <div className="rainbow-m-around_small">
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    labelAlignment="left"
                    className="date-picker"
                    formatStyle="small"
                    label="Date"
                    value={getValues().date}
                    onChange={(value) => {
                      setValue('date', value);
                    }}
                    error={errors.date?.message}
                    icon={<></>}
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className="editor-container">
          {Editor ? (
            <Editor
              onInit={(editor) => editorRef.current = editor}
              initialValue={editorInitialValue}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect |'
              + 'bold italic underline link | alignleft aligncenter '
              + 'alignright alignjustify',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          ) : (
            <PostTextField
              onChange={(e) => handleChange(e)}
              value={editorInitialValue}
              rows={10}
              color="gray"
            />
          )}
        </div>
        {(testURLs && testURLs.length > 0) && (
          <div className="uploads-container mt-2">
            <div className="student-files-container">
              <p>Uploaded files</p>
              <div className="images-container">
                {testURLs?.filter((url) => url.teacherAttachment === true).map((url) => (
                  <div
                    className="image-container"
                    key={uuid()}
                  >
                    <FontAwesomeIcon
                      icon={ExitIcon}
                      className="icon"
                      color="#97ACCC"
                      display={12}
                      onClick={() => handleRemoveUploadedFile(testURLs.indexOf(url))}
                    />
                    {isImage(url.attachment) ? (
                      <img
                        src={url.attachment}
                        alt=""
                      />
                    ) : (
                      <div className="document-icon">
                        <img
                          src={DocumentIcon}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="button-container ml-1 mt-2">
          <p>{files.length === 0 ? 'Upload files' : 'Uploaded files'}</p>
          <div>
            {isUploading && (
            <div
              style={{ width: 40, height: 40 }}
              className="progress=bar"
            >
              <CircularProgressbar
                value={progress}
                styles={{ path: { stroke: '#6371E0' }, text: { fill: '#6371E0', fontSize: 30 } }}
                text={String(progress)}
              />
            </div>
            )}
            <div>
              <Button
                color="primary"
                onClick={() => input.current?.click()}
                    // FIXME shouldn't be disabled while uploading
                disabled={isUploading}
              >
                Upload
              </Button>
            </div>
            <input
              ref={input}
              type="file"
              name="filetobase64"
              multiple
              accept="image/*, .pdf"
              onChange={handleUpload}
            />
          </div>
        </div>
        <div className="uploads-container">
          <>
            <div className="images-container">
              {files.map((file) => (
                <div
                  className="image-container"
                  key={uuid()}
                >
                  <FontAwesomeIcon
                    icon={ExitIcon}
                    className="icon"
                    color="#97ACCC"
                    display={12}
                    onClick={() => handleRemoveNewFile(files.indexOf(file))}
                  />
                  {file.type.includes('image') ? (
                    <img
                      src={`data:image/jpeg;base64, ${file.base64}`}
                      alt=""
                    />
                  ) : (
                    <div className="document-icon">
                      <img
                        src={DocumentIcon}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        </div>

        <div className="button-container mt-3">
          <Button
            color="primary"
            type="submit"
            onClick={isLoadingCreateTest ? closeModal : () => {}}
            isLoading={isLoadingCreateTest || isLoadingUpdateTest}
            disabled={isUploading}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};
