// Components
import OnboardingCard from 'components/Onboarding/OnboardingCard/OnboardingCard';
import Button from 'components/General/Button/Button';
import { Checkbox } from 'components/General/Checkbox/Checkbox';

// Grid system
import { Col, Row } from 'react-grid-system';

// UUID
import { v4 as uuidv4 } from 'uuid';

// Styling
import './Payment.scss';

// Logic
import { useLogic } from './Payment.logic';
import { useNavigate } from 'react-router';
import { STUDENT_DASHBOARD } from 'routes/CONSTANTS';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { CircleLoader, SectionLoader } from 'components/General/Loader/Loader';
import Coupon from 'components/General/Coupon/Coupon';
import { PostTextField } from 'components/Dashboard/PostTextField/PostTextField';
import Modal from 'components/General/Modal/Modal';
import { ConfirmationModal } from './ConfirmationModal/ConfirmationModal';
import { ManualPaymentConfirmationModal } from './ManualPaymentConfirmationModal/ManualPaymentConfirmationModal';

export const Payment = () => {
  const {
    onboardingState,
    paymentMethods,
    paymentMethod,
    setPaymentMethod,
    generatePaymentMethod,
    dispatch,
    setChosePaymentMethod,
    paymentInfo,
    isLoadingPaymentInfo,
    setPromoCode,
    checkCoupon,
    isCheckingCoupon,
    invalidCode,
    setShowCodeInput,
    showCodeInput,
    handleRemoveCoupon,
    isLoadingRemoveCoupon,
    userId,
    confirmationModal,
    setConfirmationModal,
    setManualPaymentConfirmationModal
  } = useLogic();
  const navigate = useNavigate();
  return (
    <Row>
      <Col
        md={6}
        offset={{ md: 3 }}
      >
        <OnboardingCard>
          {!onboardingState.chosePaymentMethod ? (
            <div className="payment-container mt-1">
              <div className="payment-header">
                <h2>How would you like to pay?</h2>
              </div>
              <div className="payment-options mt-4 mb-3">
                {paymentMethods.map((method) => (
                  <div
                    className={`payment-box ${method.diabled && 'disabled'}`}
                    key={uuidv4()}
                  >
                    <img
                      src={method.icon}
                      alt=""
                    />
                    <h4>{method.name}</h4>
                    <Checkbox
                      checked={paymentMethod === method.name}
                      onChange={() => setPaymentMethod(method.name)}
                      disabled={method.diabled}
                    />
                  </div>
                ))}
              </div>
              <div className="payment-summary-container mb-3">
                <>
                  { isLoadingPaymentInfo ? (
                    <SectionLoader />
                  ) : (
                    <>
                      <div className="top-container">
                        <div className="course-cost">
                          <h4 className="title">{`You enrolled in ${onboardingState.plan.course} Single Subject (${onboardingState.plan.hours} hours).`}</h4>
                        </div>
                        <div className="sub-total">
                          <h4 className="title">Total amount</h4>
                          <h4>{`USD ${paymentInfo?.userGetPaymentInfo?.totalAmount}`}</h4>
                        </div>
                      </div>
                      <div className="promo-code-container">
                        { paymentInfo?.userGetPaymentInfo.usedCoupon ? (
                          <div className="coupon">
                            <Coupon
                              name={paymentInfo.userGetPaymentInfo.usedCoupon}
                              discount={(paymentInfo.userGetPaymentInfo.discountAmount / paymentInfo.userGetPaymentInfo.totalAmount) * 100}
                              onClick={() => handleRemoveCoupon()}
                              isLoading={isLoadingRemoveCoupon}
                            />
                            <h4>{`-$${paymentInfo?.userGetPaymentInfo.discountAmount}`}</h4>
                          </div>
                        ) : (
                          <>
                            { showCodeInput ? (
                              <>
                                <div className="code-input-container">
                                  <div className="code-input">
                                    <PostTextField
                                      placeholderColor="primary"
                                      value={paymentInfo?.userGetPaymentInfo.usedCoupon && paymentInfo?.userGetPaymentInfo.usedCoupon}
                                      onChange={(e) => setPromoCode(e.target.value)}
                                    />
                                  </div>
                                  <div className="apply-container">
                                    {isCheckingCoupon && <CircleLoader />}
                                    <ButtonBase
                                      tagName="div"
                                      onClick={() => checkCoupon()}
                                    >
                                      <p>Apply</p>
                                    </ButtonBase>
                                  </div>
                                </div>
                                <div className="invalid-code">
                                  {invalidCode && <p>This code is invalid</p>}
                                </div>
                              </>
                            ) : (
                              <div className="code-button">
                                <ButtonBase
                                  tagName="div"
                                  onClick={() => setShowCodeInput(true)}
                                >
                                  <p>Add Promotion code</p>
                                </ButtonBase>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="bottom-container">
                        <div className="total">
                          <h4 className="title">Total Amount Due</h4>
                          <h4>{`USD ${paymentInfo?.userGetPaymentInfo.finalAmount}`}</h4>
                        </div>
                      </div>
                    </>
                  )}
                </>
              </div>
              <div className={userId && 'mb-2'}>
                <Button
                  color="primary"
                  onClick={() => {
                    if (paymentMethod === 'Cash' && userId) {
                      setConfirmationModal(true);
                    } else {
                      dispatch(setChosePaymentMethod(true));
                    }
                  }}
                  disabled={paymentMethod === undefined}
                >
                  Pay
                </Button>
                { userId && (
                  <div className="mt-1">
                    <Button
                      color="white-fuchsia"
                      onClick={() => dispatch(setManualPaymentConfirmationModal(true))}
                    >
                      Pay later
                    </Button>
                  </div>
                )}
              </div>
              {(!userId) && (
              <h3>
                <a
                  href="#"
                  onClick={() => navigate(STUDENT_DASHBOARD)}
                >
                  Skip
                </a>

              </h3>
              )}
            </div>
          ) : (
            generatePaymentMethod()
          )}
        </OnboardingCard>
      </Col>
      <Modal
        isClosble
        isOpen={confirmationModal}
        onRequestClose={() => setConfirmationModal(false)}
      >
        <ConfirmationModal />
      </Modal>
      <Modal
        isClosble
        isOpen={onboardingState.manualPaymentConfirmationModal}
        onRequestClose={() => dispatch(setManualPaymentConfirmationModal(false))}
      >
        <ManualPaymentConfirmationModal />
      </Modal>
    </Row>
  );
};
