import { base64 } from 'assets/utils/getBase64';
import { useRoles } from 'assets/utils/useRoles';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useUserSubmitAssignment } from 'services/Dashboard/Assignments/Mutations/useUserSubmitAssignment';
import { useFileUploadMutation } from 'services/UploadFiles/useUploadFile';
import { Files } from './AssignmentCard.types';

export const useLogic = () => {
  const { isAdmin, isTeacher } = useRoles();
  const { getBase64 } = base64();
  const [showAssignmentBody, setShowAssignmentBody] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [files, setFiles] = useState<Files>([]);
  const input = useRef<HTMLInputElement | null>(null);
  const downlowadRef = useRef<HTMLAnchorElement | null>(null);
  const [attachmentsIds, setAttachmentsIds] = useState<number[]>([]);
  const { mutateAsync: submitAssignment } = useUserSubmitAssignment();
  const { progress, setProgress, mutation: { mutateAsync: uploadFiles, isLoading } } = useFileUploadMutation();
  const [showGrading, setShowGrading] = useState(false);

  const addFiles = (file: Files) => {
    setFiles([...file, ...files]);
  };

  const handleUpload = async (e: any) => {
    if (!e.target.files) return;

    const formData = new FormData();
    const promises = [];
    const temp: Files = [];

    for (let i = 0; i < e.target.files.length || 0; i++) {
      if (e.target.files[i].size > 26214400) {
        toast.error('File cannot be bigger than 25 Megabytes');
        e.target.value = '';
        return;
      }
      formData.append('files[]', e.target.files[i]);
      promises.push(getBase64(e.target.files[i]));
    }
    const responses = await Promise.all(promises);
    responses.map((res: any) => {
      const startIndex = String(res.result).search('base64,') + 7;
      const base46 = String(res.result).slice(startIndex, String(res.result).length);
      temp.push({ base64: base46, type: res.type });
      return res;
    });
    addFiles(temp);

    uploadFiles({ file: formData })
      .then((res: any) => {
        setProgress(0);
        setAttachmentsIds([...attachmentsIds, ...res.data.attachmentsIds]);
      }).catch(() => {
        setFiles([...files].slice(-1));
      });

    e.target.value = '';
  };

  const handleSubmit = (id?: number) => {
    if (attachmentsIds.length < 1) {
      toast('You have to upload your work');
      return;
    }
    submitAssignment({ id, attachmentsIds });
  };

  const handleRemoveImage = (index: number) => {
    setFiles(files.filter((file) => files.indexOf(file) !== index));
    setAttachmentsIds(attachmentsIds.filter((attachment) => attachmentsIds.indexOf(attachment) !== index));
  };

  const isImage = (url: string) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);

  return {
    showAssignmentBody,
    setShowAssignmentBody,
    showOptions,
    setShowOptions,
    files,
    setFiles,
    input,
    handleRemoveImage,
    isAdmin,
    isTeacher,
    handleUpload,
    handleSubmit,
    isLoading,
    progress,
    downlowadRef,
    isImage,
    showGrading,
    setShowGrading,
  };
};
