import { gql } from 'graphql-request';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export type Rating = {
  rate?: number,
  comment?: string,
  ratedUserId?: number,
  sessionId?: number
};

export const useRateSession = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ comment, rate, ratedUserId, sessionId }: Rating) => client.request(
    gql`
    mutation RateSession($rate: Float!, $comment: String!, $ratedUserId: Float!, $sessionId: Float!) {
      rateSession(rate: $rate, comment: $comment, ratedUserId: $ratedUserId, sessionId: $sessionId) {
        message
      }
    }
    `,
    {
      rate,
      comment,
      ratedUserId,
      sessionId
    }
  ).catch((error) => toast(error.response.errors[0].message)), {
    onSuccess: (data: any) => { toast.success(data?.rateSession.message); }
  });
  return Mutation;
};
