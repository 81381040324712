import { useEffect, useState } from 'react';

// Types
import { CashFlowItem } from './Cash.types';

// Components
import Button from 'components/General/Button/Button';

// Logic
import { useLogic } from '../Payment.logic';

// Icons
import bg from 'assets/images/background.png';

// Styling
import './Cash.scss';
import { useNavigate } from 'react-router';
import { STUDENT_DASHBOARD } from 'routes/CONSTANTS';
import onboarding from 'pages/Onboarding/OnboardingSlice';

export const Cash = () => {
  const { setChosePaymentMethod, setPlanData } = onboarding.actions;
  const { dispatch } = useLogic();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [flowItem, setFlowItem] = useState<CashFlowItem>();

  const handleGoToDashboard = () => {
    navigate(STUDENT_DASHBOARD);
    dispatch(setPlanData({ isPayingCash: true }));
  };

  const cashFlow = [
    {
      id: 1,
      image: bg,
      title: 'Step 1',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec nisl vitae, quis tellus pellentesque mauris enim praesent morbi. Et elit elit felis, bibendum eu.'
    },
    {
      id: 2,
      image: bg,
      title: 'Step 2',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec nisl vitae, quis tellus pellentesque mauris enim praesent morbi. Et elit elit felis, bibendum eu.'
    },
    {
      id: 3,
      image: bg,
      title: 'Step 3',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec nisl vitae, quis tellus pellentesque mauris enim praesent morbi. Et elit elit felis, bibendum eu.'
    },
  ];

  useEffect(() => {
    switch (step) {
      case 1:
        setFlowItem(cashFlow?.[0]);
        break;
      case 2:
        setFlowItem(cashFlow?.[1]);
        break;
      case 3:
        setFlowItem(cashFlow?.[2]);
        break;
      default:
        setStep(0);
    }
  }, [step]);

  return (
    <div className="cash-container">
      <h2>Payment Directions</h2>
      <img
        src={flowItem?.image}
        alt=""
      />
      <h2>{flowItem?.title}</h2>
      <p className="mb-3">{flowItem?.body}</p>

      { step === 3 ? (
        <Button
          color="primary"
          onClick={() => handleGoToDashboard()}
        >
          Go to dashboard
        </Button>
      ) : (
        <Button
          color="primary"
          onClick={() => setStep(step + 1)}
        >
          Next
        </Button>
      )}

      { step === 1 ? (
        <div className="mt-1">
          <Button
            color="primary"
            onClick={() => dispatch(setChosePaymentMethod(false))}
          >
            Back to payment
          </Button>
        </div>
      ) : (
        <div className="mt-1">
          <Button
            color="primary"
            onClick={() => setStep(step - 1)}
          >
            Back
          </Button>
        </div>

      )}
      <h3>
        <a
          href="#"
          onClick={() => navigate(STUDENT_DASHBOARD)}
        >
          Skip
        </a>

      </h3>
    </div>
  );
};
