import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

type Props = {
  manualTransId: number,
  amount: number,
  comment?: string,
  discount: number,
  paymentMethod: 'CASH' | 'CARD',
  submittedAt: string
};

export const useAdminEditManualPayment = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ manualTransId, amount, comment, discount, paymentMethod, submittedAt }: Props) => client.request(
    gql`
    mutation AdminEditManualPayment($manualTransId: Float!, $amount: Float, $comment: String, $discount: Float, $paymentMethod: paymentMethod, $submittedAt: DateTime) {
      adminEditManualPayment(manualTransId: $manualTransId, amount: $amount, comment: $comment, discount: $discount, paymentMethod: $paymentMethod, submittedAt: $submittedAt) {
        message
      }
    }
    `,
    {
      manualTransId,
      amount,
      comment,
      discount,
      paymentMethod,
      submittedAt
    }
  ));
  return Mutation;
};
