import { TableColumn } from 'components/Dashboard/Table/Table.types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SessionAssignment } from 'services/AdminDashboard/PersonalInfo/CourseActivity/HomeworkRecords/types';
import { useHomeworkRecords } from 'services/AdminDashboard/PersonalInfo/CourseActivity/HomeworkRecords/useHomeworkRecords';

export const useLogic = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isFetching: isLoading } = useHomeworkRecords(Number(id));
  const [selected, setSelected] = useState<{ id?: number, name?: string }>({});
  const [course, setCourse] = useState<SessionAssignment>();
  const Columns: TableColumn[] = [
    {
      value: '#',
      sortable: true,
      key: 'id',
    },
    {
      value: 'Course',
      sortable: true,
      key: 'course',
    },
    {
      value: 'Done',
      sortable: true,
      key: 'done'
    },
    {
      value: 'Date',
      sortable: true,
      key: 'date'
    },
    {
      value: 'Status',
      sortable: true,
      key: 'status'
    },
    {
      value: 'Mark',
      sortable: true,
      key: 'mark'
    },
    {
      value: 'Details',
      sortable: true,
      key: 'details'
    },
  ];

  useEffect(() => {
    if (data) {
      setSelected({ id: data.adminGetUserClassAss?.[0]?.id, name: data.adminGetUserClassAss?.[0]?.plan?.course?.name });
      setCourse(data.adminGetUserClassAss?.[0]);
    }
  }, [data]);

  const handleSelect = (cardId?: number, cardName?: string) => {
    setSelected({ id: cardId, name: cardName });
    setCourse(data?.adminGetUserClassAss?.find((item) => item.id === cardId));
  };

  return {
    Columns,
    handleSelect,
    selected,
    isLoading,
    data,
    course,
    navigate
  };
};
