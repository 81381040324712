// Styling
import './Teachers.scss';

// Icons
import EditIcon from 'assets/images/edit-icon.svg';

// Logic
import { useLogic } from './Teachers.logic';
import Modal from 'components/General/Modal/Modal';
import Typography from 'components/General/Typography/Typography';
import Table from 'components/Dashboard/Table/Table';
import Label from 'components/General/Label/Label';
import Avatar from 'components/General/Avatar/Avatar';
import moment from 'moment-timezone';
import { Statistics } from '../Statistics/Statistics';
import { Filters } from '../Filters/Filters';
import { toggleFiltersModal } from '../TeachersSlice';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import stringLimit from 'assets/utils/stringLimit';
import Button from 'components/General/Button/Button';
import { AddTeacher } from '../AddTeacher/AddTeacher';
import { EditTeacher } from '../EditTeacher/EditTeacher';
import { ActivityModal } from './ActivityModal/ActivityModal';
import { StudentsNames } from './StudentsNames/StudentsNames';
import { AdminDashboardLayout } from 'layouts/AdminDashboardLayout/AdminDashboardLayout';

export const Teachers = () => {
  const {
    generateFilter,
    teachersState,
    dispatch,
    Columns,
    data,
    isLoadingTableData,
    offset,
    setOffset,
    setSortBy,
    setDesc,
    toggleAddTeacherModal,
    toggleEditTeacherModal,
    setTeacher,
    setTeacherStatus,
    setNames,
    isFetching
  } = useLogic();

  const NameCol = ({ src, name }: { src: string; name: string; }) => (
    <div className="name-col">
      <Avatar
        src={src}
        size={45}
      />
      <div className="name">
        <Typography
          color="black"
          className="mb-05"
        >
          {name}
        </Typography>
      </div>
    </div>
  );

  return (
    <AdminDashboardLayout>
      <div className="students-container">
        <div className="top-container">
          <Filters isLoading={isFetching} />
          <div>
            <Button
              color="primary"
              variant="small"
              onClick={() => dispatch(toggleAddTeacherModal(true))}
            >
              Add teacher
            </Button>
          </div>
        </div>
        <Statistics />
        <div className="table mt-4">
          <Table
            onSort={({ key, direction }) => {
              setDesc(direction === 'desc');
              setSortBy(key);
            }}
            isLoading={isLoadingTableData}
            columns={Columns}
            data={
              data?.adminAllTeachersStat.teachersStatistics.map((user) => ([
                { value: <NameCol
                  src={user.picture}
                  name={user.name}
                /> },
                { value: <Typography>{user.email ? stringLimit(user.email, 13) : '-'}</Typography> },
                { value: user.courses.length > 0
                  ? (
                    <div style={{ display: 'flex', gap: 5 }}>
                      {
                        Array.from(new Set(user.courses.map(((course) => course)))).map((course) => (
                          <Label color={course}>{course}</Label>
                        ))
                      }
                    </div>
                  )
                  : '-'
                },
                { value: (
                  <ButtonBase
                    tagName="div"
                    onClick={() => {
                      if (user.status === 'Active' || user.status === 'Inactive') {
                        dispatch(setTeacherStatus({ userId: user.id, modal: true }));
                      }
                    }}
                  >
                    <Label color={user.status}>{user.status }</Label>
                  </ButtonBase>
                ) },
                { value: <Typography>{user.country ? user.country : '-'}</Typography> },
                { value: <Typography>{(user.phone || '-')}</Typography> },
                { value: <Typography>{user.rate || '-'}</Typography> },
                { value: <Typography>{user.defaultRate || '-'}</Typography> },
                { value: <Typography>{user.tutoringHours || '-'}</Typography> },
                { value: <Typography>{user.defaultTutoringHours || '-'}</Typography> },

                { value: user.ActiveStudentsNumber ? (
                  <Button
                    variant="small"
                    color="primary"
                    onClick={() => {
                      dispatch(setNames({ names: user.ActiveStudentsNames, modal: true }));
                    }}
                  >
                    {user.ActiveStudentsNumber}
                  </Button>
                ) : (
                  '-'
                ) },
                { value: <Typography>{user.weekHours || '-'}</Typography> },

                { value: <Typography>{user.numberOfRatings || '-'}</Typography> },
                { value: <Typography>{user.createdAt ? moment(user.createdAt).format('YYYY-MM-DD') : '-'}</Typography> },
                { value: (
                  <ButtonBase
                    tagName="div"
                    onClick={() => {
                      dispatch(setTeacher({ id: user.id }));
                      dispatch(toggleEditTeacherModal(true));
                    }}
                  >
                    <img
                      src={EditIcon}
                      alt=""
                    />
                  </ButtonBase>
                ) },
              ]))
            }
          />
        </div>
        {!isLoadingTableData && (
          <div className="pagination">
            <ButtonBase
              tagName="i"
              onClick={() => {
                if (offset < 20) return;
                setOffset((prev) => prev - 20);
              }}
            >
              <i className={`icon-chevron-left ${offset < 20 && 'disabled'}`} />
            </ButtonBase>
            <Typography>
              {`${offset} - ${Number(data?.adminAllTeachersStat.totalTeachers) - offset > 20 ? offset + 20 : data?.adminAllTeachersStat.totalTeachers} of ${data?.adminAllTeachersStat.totalTeachers}`}
            </Typography>
            <ButtonBase
              tagName="i"
              onClick={() => {
                if (Number(data?.adminAllTeachersStat.totalTeachers) - offset <= 20) return;
                setOffset((prev) => prev + 20);
              }}
            >
              <i className={`icon-chevron-right ${(Number(data?.adminAllTeachersStat.totalTeachers) - offset <= 20) && 'disabled'}`} />
            </ButtonBase>
          </div>
        )}
        <Modal
          isClosble
          isOpen={teachersState.filtersModal}
          onRequestClose={() => dispatch(toggleFiltersModal(false))}
        >
          {generateFilter(teachersState.filter.selectedFilter)}
        </Modal>
        <Modal
          isClosble
          isOpen={teachersState.addTeacherModal}
          onRequestClose={() => dispatch(toggleAddTeacherModal(false))}
          width={800}
          fullHeight
        >
          <AddTeacher />
        </Modal>
        <Modal
          isClosble
          isOpen={teachersState.status.modal}
          onRequestClose={() => dispatch(setTeacherStatus({ modal: false }))}
        >
          <ActivityModal />
        </Modal>
        <Modal
          isClosble
          isOpen={teachersState.editTeacherModal}
          onRequestClose={() => dispatch(toggleEditTeacherModal(false))}
          width={800}
          fullHeight
        >
          {teachersState.teacher.id && (
            <EditTeacher />
          )}
        </Modal>
        <Modal
          isClosble
          isOpen={teachersState.names.modal}
          onRequestClose={() => dispatch(setNames({ modal: false }))}
        >
          <StudentsNames />
        </Modal>
      </div>
    </AdminDashboardLayout>
  );
};
