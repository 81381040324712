import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const usePostPersonalInfo = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ userId, step_id, phoneNumber, birthday, first_name, last_name }: { userId: number, first_name: string; last_name: string; birthday: string; phoneNumber: string, step_id: number }) => client.request(
    gql`
    mutation PersonalInfo($stepId: Float!, $phoneNumber: String!, $firstName: String!, $lastName: String!, $birthday: String!, $userId: Float!) {
      personalInfo(step_id: $stepId, phoneNumber: $phoneNumber, first_name: $firstName, last_name: $lastName, birthday: $birthday, userId: $userId) {
        message
      }
    }
    `,
    {
      stepId: step_id,
      phoneNumber,
      firstName: first_name,
      lastName: last_name,
      birthday,
      userId
    }
  ));
  return Mutation;
};
