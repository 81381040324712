// Types
import { AdminGetPeronalInfo } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminGetPeronalInfo = (userId: number, enabled?: boolean) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<AdminGetPeronalInfo, Error> = useQuery<AdminGetPeronalInfo, Error>(['admin-get-personal-info', userId], async () => client.request(
    gql`
    query AdminGetUserInfo($userId: Float!) {
      adminGetUserInfo(userId: $userId) {
        id
        first_name
        last_name
        birthday
        picture
        email
        phoneNumber
        status
        coursesInfo {
          course
          finalTest
          id
          status
          targetScore
        }
        info {
          country
          school_name
          graduation_year
          guardain_name
          guardain_number
          guardain_email
          done_courses {
            IELTS {
              taken
              scores {
                englishScore
                totalScore
                mathScore
                verbalScore
                QuantitativeScore
                reading
                writing
                speaking
                listening
              }
            }
            SAT {
              taken
              scores {
                englishScore
                totalScore
                mathScore
                verbalScore
                QuantitativeScore
                reading
                writing
                speaking
                listening
              }
            }
            GMAT {
              taken
              scores {
                englishScore
                totalScore
                mathScore
                verbalScore
                QuantitativeScore
                reading
                writing
                speaking
                listening
              }
            }
          }
        }
      }
    }
    `,
    {
      userId
    },
  ).catch((error) => console.error(error)), { enabled });
  return data;
};
