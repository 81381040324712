import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminScheduleSession = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ sessions, planId, userId, teacherId, calendarId }: { sessions: { datetime?: string, appointmentTypeId?: number }[], appointmentTypeId?: number, planId?: number, userId?: number, teacherId?: number, calendarId?: number }) => client.request(
    gql`
    mutation AdminSchedule($calendarId: Float!, $planId: Float!, $sessions: [AdminSessionsDateTime!]!, $teacherId: Float!, $userId: Float!) {
      adminSchedule(calendarId: $calendarId, planId: $planId, sessions: $sessions, teacherId: $teacherId, userId: $userId) {
        classId
        stripeId
      }
    }
    `,
    {
      calendarId,
      planId,
      sessions,
      teacherId,
      userId
    }
  ));
  return Mutation;
};
