// Types
import { TeachersData } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminGetTeachersInfo = (userId: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeachersData, Error> = useQuery<TeachersData, Error>(['teacher-data', [userId]], async () => client.request(
    gql`
    query AdminGetTeacherInfo($userId: Float!) {
      adminGetTeacherInfo(userId: $userId) {
        id
        first_name
        last_name
        phoneNumber
        picture
        email
        teacher {
          acuityCalendarId
          education
          defaultRate
          defaultTutoringHours
          tutoringHours
          university
          yearsOfExperience
          city
          country
          description
          courses {
            name
          }
        }
        clickupCredential {
          clickupFolderId
          clickupPK
        }
      }
    }
    `,
    {
      userId
    }
  ).catch((error) => console.error(error)), { enabled: !!userId });
  return data;
};
