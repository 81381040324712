// Styling
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import DotsLoader from 'components/General/Loader/Loader';
import './StatisticsCardLeads.scss';

// Types
import { Todos as IProps } from './StatisticsCardLeads.types';

export const StatisticsCardLeads = ({ image, count, description, isLoading, percentage, selected, onClick }: IProps) => (
  <ButtonBase
    tagName="div"
    onClick={onClick}
  >
    <div className={`leads-statistics-container ${selected && 'selected'}`}>
      <div className="left-container">
        <img
          src={image}
          alt=""
        />
      </div>
      <div className="right-container">
        <div className="upper-container">
          { isLoading ? (
            <DotsLoader />
          ) : (
            <div className="numbers-container">
              <h2>{count}</h2>
              <h4>{percentage ? `(${percentage}%)` : ''}</h4>
            </div>
          )}
        </div>
        <div className="bottom-container">
          <p>{description}</p>
        </div>
      </div>
    </div>
  </ButtonBase>
);
