import { CourseLayout as IProps } from './CourseLayout.types';
import './CourseLayout.scss';
import { BackButtonBar } from 'components/Dashboard/BackButtonBar/BackButtonBar';
import { useReactQuerySubscription } from 'services/Socket/socket.subscription';
import { DashboardSlice, setAssignment } from 'pages/Dashboard/DashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { HOME } from 'routes/CONSTANTS';
import { DashboardLayout } from 'layouts/DashboardLayout/DashboardLayout';
import { CourseTabBar } from 'components/Dashboard/CourseTabBar/CourseTabBar';

export const CourseLayout = ({ children, sidebarContent }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { classId, className, id } = useParams();
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  useReactQuerySubscription(Number(id));
  const tabs = [
    'Stream',
    'Assignments',
    'Practice tests',
    'Sessions'
  ];
  const handleBack = () => (dashboardState.assignment?.assignment?.id ? dispatch(setAssignment({})) : navigate(HOME));

  return (
    <DashboardLayout>
      <BackButtonBar
        className={dashboardState.assignment.assignment?.name ? dashboardState.assignment.assignment?.name : className}
        classID={dashboardState.assignment.assignment?.id ? `${dashboardState.assignment.tab} #${dashboardState.assignment.assignment?.id}` : `Class #${Number(classId)}`}
        onClick={handleBack}
      />
      <CourseTabBar
        tabs={tabs}
        selectedTab="Stream"
        parentRoute={`class/${id}/${classId}/${className}`}
      />
      <div className="course-layout-container">
        <div className="body-container">
          {children}
        </div>
        <div className="course-sidebar-container">
          {sidebarContent}
        </div>
      </div>
    </DashboardLayout>
  );
};
