import { useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useReuseAssignment } from 'services/Dashboard/Assignments/Queries/ReuseAssignment/useReuseAssignments';
import { AssignmentSliceType } from '../Assignments.types';
import { setReuseAssignment } from '../AssignmentSlice';

export const useLogic = () => {
  const { className } = useParams();
  const dispatch = useDispatch();
  const { data: reuseAssignmentData, isFetching: isLoading } = useReuseAssignment(className);
  const [OpenedFolder, setOpenedFolder] = useState<number | undefined>(undefined);
  const [isOpenFolder, setIsOpenFolder] = useState(false);
  const [searchValue, setSearchValue] = useState<number>();

  const searchAssignments = (id?: number) => {
    if (id) {
      const data = reuseAssignmentData?.adminAllAssignments.filter((item: any) => String(item.classId).includes(String(id)));
      return data;
    }
    return reuseAssignmentData?.adminAllAssignments;
  };

  const handleSetReusableAssignment = (assignment: AssignmentSliceType) => {
    dispatch(setReuseAssignment(assignment));
  };

  return {
    className,
    searchValue,
    reuseAssignmentData,
    OpenedFolder,
    isOpenFolder,
    isLoading,
    handleSetReusableAssignment,
    setSearchValue,
    setIsOpenFolder,
    setOpenedFolder,
    searchAssignments
  };
};
