import { PostTextField } from 'components/Dashboard/PostTextField/PostTextField';
import Button from 'components/General/Button/Button';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import Dropdown from 'components/General/Dropdown/Dropdown';
import TextField from 'components/General/TextField/TextField';
import { Controller } from 'react-hook-form';
import { DatePicker } from 'react-rainbow-components';
import { useLogic } from './CreatePayment.logic';
import './CreatePayment.scss';

export const CreatePayment = () => {
  const {
    onSubmit,
    control,
    errors,
    getValues,
    setValue,
    isLoading,
    type,
    setType,
    rules,
    watch
  } = useLogic();

  return (
    <div className="create-payment-container">
      <div className="payment-buttons-container">
        <ButtonBase
          tagName="div"
          onClick={() => setType(true)}
        >
          <span className={type ? 'selected' : ''}>Payment</span>
        </ButtonBase>
        <ButtonBase
          tagName="div"
          onClick={() => setType(false)}
        >
          <span className={type ? '' : 'selected'}>Refund</span>
        </ButtonBase>
      </div>
      <form onSubmit={onSubmit}>
        <Controller
          control={control}
          name="paymentMethod"
          rules={{
            required: 'This field is required',
          }}
          render={({ field: { onChange, value } }) => (
            <div className="dropdown-container">
              <p>Payment method</p>
              <Dropdown
                options={['Credit card', 'Cash']}
                onChange={onChange}
                value={value}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="date"
          rules={{
            required: {
              value: true,
              message: 'This field is required'
            }
          }}
          render={() => (
            <div className="rainbow-m-around_small">
              <DatePicker
                placeholder="YYYY-MM-DD"
                formatStyle="medium"
                labelAlignment="left"
                className="date-picker"
                label="Date"
                value={getValues().date}
                onChange={(value) => {
                  setValue('date', value);
                }}
                error={errors.date?.message}
                icon={<></>}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="amount"
          rules={rules}
          render={({ field: { onChange, value } }) => (
            <TextField
              color="light-gray"
              variant="rounded"
              placeholder="Amount"
              label="Amount"
              type="text"
              onChange={onChange}
              error={errors?.amount?.message}
              value={value}
            />
          )}
        />
        { type && (
          <Controller
            control={control}
            name="discount"
            rules={{
              min: {
                value: 0,
                message: 'Discount should not be less than 0'
              },
              max: {
                value: Number(watch().amount),
                message: `Discount cannot be more than ${watch().amount}`
              }
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                color="light-gray"
                variant="rounded"
                placeholder="Discount"
                label="Discount"
                type="text"
                onChange={onChange}
                error={errors?.discount?.message}
                value={value}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="comment"
          render={({ field: { onChange, value } }) => (
            <div className="comment-contianer">
              <h3>Comment</h3>
              <PostTextField
                color="gray"
                variant="round"
                placeholder="Type your comment"
                onChange={onChange}
                error={errors?.comment?.message}
                value={value}
                rows={7}
              />
            </div>
          )}
        />
        <div className="mt-1">
          <Button
            color="primary"
            type="submit"
            isLoading={isLoading()}
          >
            Save

          </Button>
        </div>
      </form>
    </div>
  );
};
