// Types
import { AuthenticationLayout as IProps } from './AuthenticationLayout.types';

// Components
import Navbar from 'components/General/Navbar/Navbar';

// Styling
import './AuthenticationLayout.scss';

// Icons
import SliderImage from 'assets/images/slider.svg';
import SliderImage2 from 'assets/images/slider-2.svg';
import SliderImage3 from 'assets/images/slider-3.svg';

// Grid system
import { Container, Row, Col } from 'react-grid-system';

// React carousal
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useEffect, useState } from 'react';
import { OverlayLoader } from 'components/General/Loader/Loader';
import { useLocation } from 'react-router';

const AuthenticationLayout = ({ children }: IProps) => {
  const location = useLocation();
  // const selectedTab = location.pathname.split('/')?.[1];
  const [isLoading, setIsLoadig] = useState(false);

  useEffect(() => {
    setIsLoadig(true);
    const timer = setTimeout(() => {
      setIsLoadig(false);
    }, 750);
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {isLoading && (
        <OverlayLoader />
      )}
      <Navbar />
      <Container style={{ paddingTop: 60 }}>
        <Row className="auth-container">
          <Col
            lg={6}
            sm={12}
          >
            {children}
          </Col>
          <Col
            lg={6}
            sm={12}
          >
            <div className="image-slider-container">
              <Carousel
                showThumbs={false}
                showArrows={false}
                autoPlay
                infiniteLoop
                showStatus={false}
              >
                <div className="slider-item">
                  <img
                    src={SliderImage}
                    alt=""
                    height={370}
                  />
                  <h2>Live 1:1 tutoring</h2>
                  <div className="image-slider-body">
                    <h4>our teachers have engaging personalities and scores above the 90th percentile. They complete a full training certification program before they start teaching.</h4>
                  </div>
                </div>
                <div className="slider-item">
                  <img
                    src={SliderImage2}
                    alt=""
                    height={370}
                  />
                  <>
                    <h2>How it works</h2>
                    <div className="image-slider-body">
                      <h4>1- Selct a tutor.</h4>
                      <h4>2- Choose a schedule.</h4>
                      <h4>3- Start attending your classes.</h4>
                    </div>
                  </>
                </div>
                <div className="slider-item">
                  <img
                    src={SliderImage3}
                    alt=""
                    height={370}
                  />
                  <h2>Practice makes perfect</h2>
                  <div className="image-slider-body">
                    <h4>1- Watch the recording of your session.</h4>
                    <h4>2- Solve Your Homework.</h4>
                    <h4>3- Take Your Full-length practice tests. </h4>
                  </div>

                </div>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AuthenticationLayout;
