import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useClassSessions } from 'services/Dashboard/Sessions/Queries/ClassSessions/useClassSessions';
import { SessionSlice, setRescheduleData, setRescheduleModal, setSessionDuration, setSessionProblem, setSessionsInfo, setRemoveSession } from '../SessionsSlice';
import onboarding from 'pages/Onboarding/OnboardingSlice';
import { useRoles } from 'assets/utils/useRoles';
import { setStreamRateData, StreamSlice } from '../../Stream/StreamSlice';
import moment from 'moment-timezone';
import { setCalendarDate } from '../Sessions/RescheduleCalendar/RescheduleCalendar.slice';

export const useLogic = () => {
  const { id, className } = useParams();
  const { isAdmin, isTeacher } = useRoles();
  const dispatch = useDispatch();
  const { data, isFetching: isLoading } = useClassSessions({ classId: Number(id), filter: 'All', isAdmin: Boolean(isAdmin) });
  const sessionState = useSelector((state: { sessions: SessionSlice }) => state.sessions);
  const streamState = useSelector((state: { stream: StreamSlice }) => state.stream);
  const [acuityTypeID, setAcuityTypeID] = useState<number>();
  const [isAllowed, setIsAllowed] = useState<boolean>(true);
  const [duration, setDuration] = useState(2);
  const appointmentTypeIds: any = {
    IELTS: {
      0.5: {
        id: 37361247,
        time: '30 minutes'
      },
      1: {
        id: 32390757,
        time: '1 hours'
      },
      1.5: {
        id: 37361257,
        time: '1.5 hours'
      },
      2: {
        id: 37361273,
        time: '2 hours'
      },
      2.5: {
        id: 37361913,
        time: '2.5 hours'
      },
      3: {
        id: 37361924,
        time: '3 hours'
      }
    },
    SAT: {
      0.5: {
        id: 37360737,
        time: '30 minutes'
      },
      1: {
        id: 32366529,
        time: '1 hours'
      },
      1.5: {
        id: 37361009,
        time: '1.5 hours'
      },
      2: {
        id: 37361035,
        time: '2 hours'
      },
      2.5: {
        id: 37362044,
        time: '2.5 hours'
      },
      3: {
        id: 37362059,
        time: '3 hours'
      }
    },
    GMAT: {
      0.5: {
        id: 37361235,
        time: '30 minutes'
      },
      1: {
        id: 32390362,
        time: '1 hours'
      },
      1.5: {
        id: 37361094,
        time: '1.5 hours'
      },
      2: {
        id: 37361116,
        time: '2 hours'
      },
      2.5: {
        id: 37361991,
        time: '2.5 hours'
      },
      3: {
        id: 37362009,
        time: '3 hours'
      }
    }
  };

  const handleRescheduleClick = (record: any) => {
    if (!isAllowed && !isTeacher && !isAdmin) return;
    setDuration(record.duration);
    dispatch(setRescheduleModal(true));
    dispatch(onboarding.actions.setPlanData({ sessionID: record.id, sessionNumber: record.sessionNumber, sessionDate: record.dueAt }));
    dispatch(setCalendarDate(moment(record.dueAt).toDate()));
  };

  const handleOpenClick = (record: any) => {
    if ((record?.studentLink && record.status === 'Not Started') || (record?.teacherLink && record.status === 'Not Started') || (record?.adminLink && record.status === 'Not Started')) {
      const link = () => {
        if (isAdmin) return record.adminLink;
        if (isTeacher) return record.teacherLink;
        return record.studentLink;
      };
      let meetingWindow = window.open(link(), '_blank');
      let timer: any = null;

      const windowListner = () => {
        if (meetingWindow?.closed) {
          clearInterval(timer);
          meetingWindow = null;
          const ratingData = {
            toggleRatingModal: true,
            sessionId: record?.id,
            ratedUserId: isTeacher ? data?.classSessions?.userId : record.teacher.userId,
          };
          dispatch(setStreamRateData(ratingData));
        }
      };

      timer = setInterval(windowListner, 500);
    }
  };

  useEffect(() => {
    dispatch(onboarding.actions.setPlanData({
      id: data?.classSessions?.planId,
      teacherID: data?.classSessions?.filteredSessions?.[0]?.teacher.userId,
      typeID: acuityTypeID,
      calendarID: data?.classSessions?.filteredSessions?.[0]?.teacher.acuityCalendarId
    }));
    dispatch(setSessionsInfo({
      sessions: data?.classSessions.takenSessions,
      takenHours: data?.classSessions.takenHours,
      planHours: data?.classSessions.planHours
    }));
  }, [data, acuityTypeID]);

  useEffect(() => {
    if (data?.classSessions?.rescheduledSessions) {
      const rescheduleSessionsCount = data?.classSessions?.rescheduledSessions;
      const rescheduleSessionsLimit = data?.classSessions?.rescheduledSessionsLimit;

      if (rescheduleSessionsCount === rescheduleSessionsLimit) {
        setIsAllowed(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!sessionState.sessionProblem.modal) {
      dispatch(setSessionProblem({}));
    }
  }, [sessionState.sessionProblem.modal]);

  useEffect(() => {
    if (className) {
      dispatch(setRescheduleData({ ...sessionState.rescheduleData, duration: appointmentTypeIds[className][duration]?.time }));
      setAcuityTypeID(appointmentTypeIds[className][duration]?.id);
    }
  }, [duration]);

  return {
    data,
    isLoading,
    dispatch,
    sessionState,
    setRescheduleModal,
    onboarding,
    isAllowed,
    isAdmin,
    isTeacher,
    handleOpenClick,
    handleRescheduleClick,
    streamState,
    setStreamRateData,
    setSessionDuration,
    setSessionProblem,
    setRemoveSession
  };
};
