// Components
import Button from 'components/General/Button/Button';
import OnboardingCard from 'components/Onboarding/OnboardingCard/OnboardingCard';
import TextField from 'components/General/TextField/TextField';

// Styling
import './PersonalInfo.scss';

// Logic
import { useLogic } from './PersonalInfo.logic';

// Date picker
import { DatePicker, PhoneInput } from 'react-rainbow-components';

// React hook form
import { Controller } from 'react-hook-form';

// Grid system
import { Col, Row } from 'react-grid-system';
import { useNavigate, useParams } from 'react-router';
import { ONBOARDING_COURSES, STUDENT_DASHBOARD } from 'routes/CONSTANTS';

const PersonalInfo = () => {
  const { onSubmit, control, errors, setValue, getValues, phoneError } = useLogic();
  const navigate = useNavigate();
  const { userId } = useParams();

  return (
    <Row>
      <Col
        md={6}
        offset={{ md: 3 }}
      >
        <OnboardingCard>
          <h2>Personal info</h2>

          <form onSubmit={onSubmit}>
            <Controller
              control={control}
              name="firstName"
              rules={{
                required: 'This field is required',
                minLength: {
                  value: 2,
                  message: 'First name should be longer than 2 characters'
                },
                maxLength: {
                  value: 20,
                  message: 'First name cannot be longer than 20 characters'
                }
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  color="light-gray"
                  variant="rounded"
                  placeholder="First name"
                  id="first-name"
                  label="First name"
                  type="text"
                  onChange={onChange}
                  error={errors?.firstName?.message}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                minLength: {
                  value: 2,
                  message: 'Last name should be longer than 2 characters'
                },
                maxLength: {
                  value: 20,
                  message: 'Last name cannot be longer than 20 characters'
                }
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  color="light-gray"
                  variant="rounded"
                  placeholder="Last name"
                  id="last-name"
                  label="Last name"
                  onChange={onChange}
                  error={errors?.lastName?.message}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name="birthday"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                }
              }}
              render={() => (
                <div className="rainbow-m-around_small">
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    formatStyle="medium"
                    labelAlignment="left"
                    className="date-picker"
                    label="Birthday"
                    maxDate={new Date()}
                    value={getValues().birthday}
                    onChange={(value) => {
                      setValue('birthday', value);
                    }}
                    error={errors.birthday?.message}
                    icon={<></>}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                minLength: {
                  value: 8,
                  message: 'Phone number should be longer than 8 characters'
                },
                maxLength: {
                  value: 20,
                  message: 'Phone number cannot be longer than 20 characters'
                }
              }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  label="Phone Number"
                  placeholder="70123456"
                  onChange={onChange}
                  value={value}
                  className={`phone-input ${`${phoneError && 'error'}`}`}
                />
              )}
            />
            <div className="button-container mb-1">
              <Button
                color="primary"
                type="submit"
              >
                Next
              </Button>
              <div className="skip-container mb-3">
                <div className="back-container mb-2 mt-2">
                  <Button
                    color="white"
                    onClick={() => navigate(ONBOARDING_COURSES)}
                  >
                    Back
                  </Button>
                </div>
              </div>
              {!userId && (
                <h3>
                  <a
                    href="#"
                    onClick={() => navigate(STUDENT_DASHBOARD)}
                  >
                    Skip
                  </a>

                </h3>
              )}
            </div>
          </form>
        </OnboardingCard>
      </Col>
    </Row>
  );
};

export default PersonalInfo;
