/**
 * Auth routes
*/
export const SIGNIN = '/signin';

export const SIGNUP = '/signup';
export const SIGNUP_VERIFY = '/signup/verification';

export const FORGOT_PASSWORD = '/forgot_password';

export const RESET_VERIFY = '/send_code';
export const RESET_PASSWORD = '/reset_password';

/**
 * Public routes
*/

// Onboarding
export const ONBOARDING = '/onbording';
export const ONBOARDING_COURSES = '/onboarding_courses';

export const ADMIN_ONBOARDING = (userId: string, course: string) => `/admin_onboarding/${userId}/${course}`;
export const ADMIN_ONBOARDING_COURSES = (userId: string) => `/onboarding_courses/${userId}`;

// Privacy policy
export const PRIVACY = '/privacy';

// Dashboard
export const HOME = '/';
// FIXME should be /class/:classID
export const CLASS = '/class/:id/:classId/:className';
// export const CLASS = (id: string, classId: string, className: string) => `/class/:${id}/:${classId}/:${className}`;
/*-*/ export const STREAM = 'stream';
/*-*/ export const ASSIGNMENTS = 'assignments';
/*-*/ export const TESTS = 'practice_tests';
/*-*/ export const SESSIONS_RECORDINGS = 'sessions';

export const STUDENT_DASHBOARD = '/student_dashboard';

/**
 * Teacher Dashboard routes
*/
export const TEACHER_DASHBOARD = 'teacher_dashboard';
/*-*/ export const TEACHER_CLASSES = 'teacher_classes';
/*-*/ export const TEACHER_TODOS = 'teacher_todos';
/*-*/ export const TEACHER_CALENDAR = 'teacher_calendar';
/*-*/ export const TEACHER_HOMEWORK = 'teacher_homework';
/*-*/ export const TEACHER_STUDENTS = 'teacher_students';

/**
 * Dashboard routes
*/
export const DASHBOARD = 'dashboard';
/*-*/ export const CLASSES = 'classes';
/*-*/ export const TODOS = 'todos';
/*-*/ export const CALENDAR = 'calendar';
/*-*/ export const STUDENTS = 'students';
/*-*/ export const LEADS = 'leads';
/*-*/ export const TEACHERS = 'teachers';
/*-*/ export const LEAD_PROFILE = (id: string, leadId?: string, className?: string) => `lead/${id}/${leadId}/${className}`;
/*-*/ export const STUDENT_PROFILE = (id: string) => `student/${id}`;
/*------*/ export const ENROLLMENTS = 'enrollments';
/*------*/ export const PAYMENT_STATUS = 'payment_status';
/*------*/ export const NOTES = 'notes';
/*------*/ export const COURSE_ACTIVITY = 'course_activity';
/*------------*/ export const PROGRESS_DIAGRAM = 'progress_diagram';
/*------------*/ export const ATTENDANCE_RECORDS = 'attendance_records';
/*------------*/ export const HOMEWORK_RECORDS = 'homework_records';
