// Logic
// import { useLogic } from "./Session.logic";

// Components
import { CourseLayout } from 'layouts/CourseLayout/CourseLayout';

// Style
import './SessionTab.scss';

// Images
import SideBarImage from 'assets/images/student-side.svg';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import Button from 'components/General/Button/Button';
import { useLogic } from './SessionsTab.logic';
import Modal from 'components/General/Modal/Modal';
import { SessionsStats } from './SessionsStats/SessionsStats';
import { SessionDuration } from './SessionDuration/SessionDuration';
import { SessionProblem } from './SessionProblem/SessionProblem';
import RescheduleCalendar from './Sessions/RescheduleCalendar/RescheduleCalendar';
import { RateSession } from './RateSession/RateSession';
import CreateSessionCalendar from './CreateSession/CreateSession';

export const Session = () => {
  const {
    dispatch,
    getSelectedTab,
    handleTabChange,
    selectedTab,
    sessionState,
    isAdmin,
    setRescheduleModal,
    setStreamRateData,
    streamState,
    setSessionDuration,
    setSessionProblem,
    setAddExtraSession
  } = useLogic();

  return (
    <CourseLayout
      sidebarContent={(
        <div className="sessions-sidebar-events-container">
          <div className="sidebar-event-container">
            { !isAdmin && (
              <img
                src={SideBarImage}
                alt=""
              />
            )}
          </div>
          { isAdmin && (
            <SessionsStats />
          )}
          { isAdmin && (
            <div className="extra-session-container">
              <h3>Create extra sessions</h3>
              <Button
                color="primary"
                onClick={() => dispatch(setAddExtraSession({ modal: true }))}
              >
                Create
              </Button>
            </div>
          )}
        </div>
      )}
    >
      <div className="session-tab-page-container">
        <div className="navigation-bar">
          <div className="navigation-tabs">
            <ButtonBase
              tagName="div"
              onClick={() => handleTabChange('All')}
            >
              <span className={selectedTab === 'All' ? 'selected' : ''}>
                <p>All</p>
              </span>
            </ButtonBase>
            <ButtonBase
              tagName="div"
              onClick={() => handleTabChange('Upcoming')}
            >
              <span className={selectedTab === 'Upcoming' ? 'selected' : ''}>
                <p>Upcoming</p>
              </span>
            </ButtonBase>
            <ButtonBase
              tagName="div"
              onClick={() => handleTabChange('Recording')}
            >
              <span className={selectedTab === 'Recording' ? 'selected' : ''}>
                <p>Recording</p>
              </span>
            </ButtonBase>
          </div>
          {sessionState.sessionsInfo.takenHours ? (
            <div className="plan-details">
              <p>{`Plan: ${sessionState.sessionsInfo?.takenHours}/${sessionState.sessionsInfo?.planHours} hours`}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        {getSelectedTab()}
      </div>
      <Modal
        isClosble
        isOpen={sessionState.rescheduleModal}
        onRequestClose={() => dispatch(setRescheduleModal(false))}
      >
        {sessionState.rescheduleModal && (
          <RescheduleCalendar />
        )}
      </Modal>
      <Modal
        isClosble
        isOpen={streamState.rateData.toggleRatingModal}
        onRequestClose={() => dispatch(setStreamRateData({ toggleRatingModal: false }))}
      >
        <RateSession />
      </Modal>
      <Modal
        isClosble
        isOpen={sessionState.sessionProblem.modal}
        onRequestClose={() => dispatch(setSessionProblem({ modal: false }))}
        fullHeight
      >
        <SessionProblem />
      </Modal>
      <Modal
        isClosble
        isOpen={sessionState.sessionDuration.modal}
        onRequestClose={() => dispatch(setSessionDuration({ modal: false }))}
        fullHeight
      >
        <SessionDuration />
      </Modal>
      <Modal
        isClosble
        isOpen={sessionState.extraSession.modal}
        onRequestClose={() => dispatch(setAddExtraSession({ modal: false }))}
        fullHeight
      >
        <CreateSessionCalendar />
      </Modal>
    </CourseLayout>
  );
};
