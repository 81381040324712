import { useEffect, useState } from 'react';

// Types
import { SchoolInfoForm } from './SchoolInfo.types';

// Redux
import onboarding, { OnboardingSlice } from '../../../OnboardingSlice';
import { useDispatch, useSelector } from 'react-redux';

// Sevices
import { postSchoolInfo } from 'services/Onboarding/General/useOnboarding';
import { useQueryClient } from 'react-query';

// React hook form
import { useForm } from 'react-hook-form';
import { useFinishOnboardingInfo } from 'services/Onboarding/General/Mutations/useFinishOnboardingInfo';
import { useParams } from 'react-router';
import { usePostSchoolInfo } from 'services/Onboarding/AdminOnboarding/Mutations/usePostSchoolInfo';
import { useAdminFinishOnboardingInfo } from 'services/Onboarding/AdminOnboarding/Mutations/useAdminFinishOnboardingInfo';
import { useRoles } from 'assets/utils/useRoles';

export const useLogic = () => {
  const { userId } = useParams();
  const { isAdmin } = useRoles();
  const { nextPage, updateUserInfo, previousPage } = onboarding.actions;
  const { mutateAsync: adminPostPersonalInfo, isLoading: isLoadingAdminPostInfo } = usePostSchoolInfo();
  const { mutateAsync: finishOnboarding, isLoading: isLoadingFinishOnboarding } = useFinishOnboardingInfo();
  const { mutateAsync: adminFinishOnboarding, isLoading: isLoadingAdminFinishOnboarding } = useAdminFinishOnboardingInfo();
  const dispatch = useDispatch();
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const [takenSat, setTakenSat] = useState<any>();
  const queryClient = useQueryClient();
  let promise1: any;
  let promise2: any;

  const { control, handleSubmit, formState: { errors }, watch, setValue, getValues, } = useForm<SchoolInfoForm>({
    defaultValues: {
      graduationYear: undefined,
      takenCourse: {
        label: '',
        value: false
      },
      scores: {
        totalScore: 0,
        QuantitativeScore: 0,
        verbalScore: 0
      }
    }
  });

  const formatDate = (birthday: any) => {
    const date = birthday.getDate();
    const month = birthday.getMonth() + 1;
    const year = birthday.getFullYear();
    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}`;
  };

  const onSubmit = handleSubmit(async (data) => {
    if (errors.country?.label?.message || errors.schoolName?.label?.message || errors.graduationYear?.message || errors.scores?.totalScore?.message || errors.scores?.QuantitativeScore?.message || errors.scores?.verbalScore?.message) return;
    if (userId) {
      promise1 = await adminPostPersonalInfo({
        userId: Number(userId),
        country: data.country.label,
        schoolName: data.schoolName?.label || '',
        graduationYear: formatDate(data.graduationYear),
        courseName: onboardingState.plan.course,
        stepId: onboardingState.activeStep,
        scores: {
          totalScore: Number(data.scores?.totalScore),
          QuantitativeScore: Number(data.scores?.QuantitativeScore),
          verbalScore: Number(data.scores?.verbalScore)
        }
      });
    } else {
      promise2 = await postSchoolInfo({
        country: data.country.label,
        school_name: data.schoolName?.label || '',
        graduation_year: formatDate(data.graduationYear),
        course_name: onboardingState.plan.course,
        step_id: onboardingState.activeStep,
        scores: data.scores
      });
    }
    dispatch(updateUserInfo({
      country: data.country.label,
      schoolName: data.schoolName?.label || '',
      graduationYear: formatDate(data.graduationYear),
    }));

    const promise3 = !isAdmin && finishOnboarding(onboardingState.plan.courseID);
    const promise4 = isAdmin && adminFinishOnboarding({
      userId: Number(userId),
      courseId: Number(onboardingState.plan.courseID),
    });
    queryClient.refetchQueries(['userinfo']);
    await Promise.any([promise1, promise2, promise3, promise4]).then(() => dispatch(nextPage()));
  });

  useEffect(() => {
    setTakenSat(watch('takenCourse.value'));
  }, [watch('takenCourse')]);

  const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  useEffect(() => {
    if (onboardingState.userInfo.country) { setValue('country', { label: onboardingState.userInfo.country, code: '' }); }
    if (onboardingState.userInfo.schoolName) { setValue('schoolName', { label: onboardingState.userInfo.schoolName, value: '' }); }
    if (onboardingState.userInfo.graduationYear) { setValue('graduationYear', new Date(onboardingState.userInfo.graduationYear || 0)); }
    if (onboardingState.userInfo.info?.done_courses?.GMAT.scores.verbalScore) { setValue('scores.verbalScore', onboardingState.userInfo.info.done_courses.GMAT.scores.verbalScore); }
    if (onboardingState.userInfo.info?.done_courses?.GMAT.scores.QuantitativeScore) { setValue('scores.QuantitativeScore', onboardingState.userInfo.info.done_courses.GMAT.scores.QuantitativeScore); }
    if (onboardingState.userInfo.info?.done_courses?.GMAT.scores.totalScore) { setValue('scores.totalScore', onboardingState.userInfo.info.done_courses.GMAT.scores.totalScore); }
  }, [onboardingState.userInfo]);

  return {
    options,
    errors,
    control,
    takenSat,
    onboardingState,
    watch,
    onSubmit,
    setTakenSat,
    setValue,
    getValues,
    isLoadingAdminPostInfo,
    isLoadingFinishOnboarding,
    isLoadingAdminFinishOnboarding,
    previousPage,
    dispatch
  };
};
