// React
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { TeacherCalendarsSlice } from '../CalendarSlice';
import moment from 'moment-timezone';

const AppointmentView: FC<any> = () => {
  const teacherCalendarState = useSelector((state: { teacherCalendar: TeacherCalendarsSlice }) => state.teacherCalendar);

  return (
    <div className="appt-view-container">
      <h3>{`Course: ${teacherCalendarState.plan.session?.course}`}</h3>
      <h3>{`Student: ${teacherCalendarState.plan.session?.studentName}`}</h3>
      <h3>{`Teacher: ${teacherCalendarState.plan.session?.teacherName}`}</h3>
      <h3>{`Session number: ${teacherCalendarState.plan.session?.sessionNumber}`}</h3>
      <h3>{`Date: ${moment(teacherCalendarState.plan.session?.startDate).format('YYYY-MM-DD')}`}</h3>
      <h3>{`Time: ${moment(teacherCalendarState.plan.session?.startDate).format('h:mm a')} - ${moment(teacherCalendarState.plan.session?.endDate).format('h:mm a')}`}</h3>
    </div>
  );
};

export default AppointmentView;
