import { useLeadsStatistics } from 'services/AdminDashboard/Leads/LeadsStatistics/useLeadsStatistics';

export const useLogic = () => {
  const { data: statistics, isLoading } = useLeadsStatistics();

  return {
    statistics,
    isLoading
  };
};
