import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useUpdateCallLog = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ callLogId, contacted }: { callLogId?: number, contacted: boolean }) => client.request(
    gql`
    mutation AdminUpdateCallLog($callLogId: Float!, $contacted: Boolean!) {
      adminUpdateCallLog(callLogId: $callLogId, contacted: $contacted) {
        id
        createdById
        contacted
        leadId
        note
        futureCall
        updatedAt
        createdAt
      }
    }
    `,
    {
      callLogId,
      contacted
    }
  ));
  return Mutation;
};
