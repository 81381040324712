import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LeadsSlice } from '../LeadsSlice';

export const useLogic = () => {
  const dispatch = useDispatch();
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);

  const filters = [
    { filter: 'Subjects', active: leadsState.filter.courses },
    { filter: 'Country', active: leadsState.filter.country },
    { filter: 'Status', active: leadsState.filter.status },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filters?.[0]?.filter);

  return {
    filters,
    selectedFilter,
    setSelectedFilter,
    dispatch,
    leadsState
  };
};
