import { useDispatch, useSelector } from 'react-redux';
import { PracticeTestSlice, setEditPracticeTestId, setMark, setReusePracticeTest, setToggleNewPracticeTestModal, PracticeTest as Type } from './PracticeTestSlice';
import { useParams } from 'react-router';
import { DashboardSlice } from 'pages/Dashboard/DashboardSlice';
import { useRoles } from 'assets/utils/useRoles';
import { useAdminNearestPracticeTest } from 'services/Dashboard/PracticeTests/Queries/AdminNearestPracticeTest/useAdminNearestPracticeTest';
import { useUserNearestPracticeTest } from 'services/Dashboard/PracticeTests/Queries/UserNearestPracticeTest/useUserNearestPracticeTest';
import { useGetPracticeTests } from 'services/Dashboard/PracticeTests/Queries/PracticeTests/useGetPracticeTests';
import { useDeletePracticeTest } from 'services/Dashboard/PracticeTests/Mutations/useDeletePracticeTest';
import { useGetTestsChart } from 'services/Dashboard/PracticeTests/Queries/PracticeTestsChart/useGetTestsChart';
import toast from 'react-hot-toast';

export const useLogic = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const practiceTestState = useSelector((state: { practiceTest: PracticeTestSlice }) => state.practiceTest);
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const { mutateAsync: deletePracticeTest } = useDeletePracticeTest();
  const { data: practiceTests, isFetching: isLoading } = useGetPracticeTests(Number(id));
  const { data: userNearestPracticeTest, isFetching: isLoadingnUserNarestPracticeTest } = useUserNearestPracticeTest(Number(id));
  const { data: adminNearestPracticeTest, isFetching: isLoadingnAdminNarestPracticeTest } = useAdminNearestPracticeTest(Number(id));
  const { data: chartData, isLoading: isLoadingChartData } = useGetTestsChart(Number(id));
  const { isAdmin, isTeacher } = useRoles();

  const handeRemove = (_id?: number) => {
    deletePracticeTest(_id).then((data) => toast.success(data.adminDeleteTest.message));
  };

  const handleEdit = (test: Type) => {
    dispatch(setReusePracticeTest(test));
    dispatch(setEditPracticeTestId(test.id));
    dispatch(setToggleNewPracticeTestModal());
  };

  return {
    practiceTestState,
    dashboardState,
    isLoading,
    practiceTests,
    handeRemove,
    dispatch,
    setMark,
    userNearestPracticeTest,
    adminNearestPracticeTest,
    isLoadingnAdminNarestPracticeTest,
    isLoadingnUserNarestPracticeTest,
    handleEdit,
    isAdmin,
    isTeacher,
    chartData,
    isLoadingChartData,
  };
};
