import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useChoosePlan = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async (planId: number) => client.request(
    gql`
    mutation UserChoosePlan($planId: Float!) {
        userChoosePlan(planId: $planId) {
            message
        }
    }
    `,
    {
      planId,
    }
  ));
  return Mutation;
};
