import useWindowDimensions from 'assets/utils/getWindowDimentions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAdminCalendar } from 'services/AdminDashboard/Calendar/useAdminCalendar';
import mainCalendar, { MainCalendarsSlice, resetTimeSlots, setPlanData, toggleAppointmentViewModal } from './CalendarSlice';
import ClassFilters from './Filters/ClassFilter/ClassFilter';
import { LocationFilter } from './Filters/CountryFilter/LocationFilter';
import { TeacherFilter } from './Filters/TeacherFilter/TeacherFilter';
import { Teacher } from 'services/AdminDashboard/Calendar/types';
import { ClassIdFilter } from './Filters/ClassIdFilter/ClassIdFilter';
import { SessionSlice, setRescheduleModal } from 'pages/Dashboard/Course/Session/SessionsSlice';
import { setAcuityCalendarID, setAcuityTypeID } from './RescheduleCalendar/RescheduleCalendar.slice';
import moment from 'moment-timezone';
import './Calendar.scss';
import ReactTooltip from 'react-tooltip';

export const useLogic = () => {
  const { height, width } = useWindowDimensions();
  const mainCalendarState = useSelector((state: { mainCalendar: MainCalendarsSlice }) => state.mainCalendar);
  const sessionState = useSelector((state: { sessions: SessionSlice }) => state.sessions);
  const [month, setMonth] = useState(new Date());
  const { data: calendarData, isLoading, isFetching } = useAdminCalendar({ ...mainCalendarState.filter, month });
  const [currentDate, setCurrentDate] = useState(new Date());
  const { setFilter, toggleFiltersModal } = mainCalendar.actions;
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const [session, setSession] = useState<any>([]);
  const [teachers, setTeachers] = useState<Teacher[]>();
  const [acuityAppTypeId, setAcuityAppTypeId] = useState<number>();
  const [timeline, setTimeline] = useState<{ min: number, max: number }>({ min: 12, max: 24 });
  const [view, setView] = useState<string>('Day');
  const [sessionsCount, setSessionsCount] = useState(0);
  const appointmentTypeIds: any = {
    IELTS: {
      0.5: {
        id: 37361247,
        time: '30 minutes'
      },
      1: {
        id: 32390757,
        time: '1 hours'
      },
      1.5: {
        id: 37361257,
        time: '1.5 hours'
      },
      2: {
        id: 37361273,
        time: '2 hours'
      },
      2.5: {
        id: 37361913,
        time: '2.5 hours'
      },
      3: {
        id: 37361924,
        time: '3 hours'
      }
    },
    SAT: {
      0.5: {
        id: 37360737,
        time: '30 minutes'
      },
      1: {
        id: 32366529,
        time: '1 hours'
      },
      1.5: {
        id: 37361009,
        time: '1.5 hours'
      },
      2: {
        id: 37361035,
        time: '2 hours'
      },
      2.5: {
        id: 37362044,
        time: '2.5 hours'
      },
      3: {
        id: 37362059,
        time: '3 hours'
      }
    },
    GMAT: {
      0.5: {
        id: 37361235,
        time: '30 minutes'
      },
      1: {
        id: 32390362,
        time: '1 hours'
      },
      1.5: {
        id: 37361094,
        time: '1.5 hours'
      },
      2: {
        id: 37361116,
        time: '2 hours'
      },
      2.5: {
        id: 37361991,
        time: '2.5 hours'
      },
      3: {
        id: 37362009,
        time: '3 hours'
      }
    }
  };

  const calculataData = (teacherId: number, date: Date) => {
    let startDate: string;
    let endDate: string;
    let dateFilteredData: any;
    let alldateFilteredData: any;
    switch (view) {
      case 'Day':
        startDate = moment(date).format('YYYY-MM-DD');
        endDate = moment(date).format('YYYY-MM-DD');
        break;
      case 'Week':
        startDate = moment(date).startOf('week').isoWeekday(0).add(1, 'week')
          .format('YYYY-MM-DD');
        endDate = moment(date).endOf('week').isoWeekday(-1).add(1, 'week')
          .format('YYYY-MM-DD');
        break;
      case 'Month':
        startDate = moment(date).startOf('month').format('YYYY-MM-DD');
        endDate = moment(date).endOf('month').format('YYYY-MM-DD');
        break;
      default:
        startDate = moment().format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
    }
    const teacherData: any[] = data.filter((item) => item.teacherId === teacherId);
    if (view === 'Day') {
      dateFilteredData = teacherData.filter((item) => moment(moment(item.endDate).format('YYYY-MM-DD')).isSame(moment(startDate).format('YYYY-MM-DD')));
      alldateFilteredData = data.filter((item) => moment(moment(item.endDate).format('YYYY-MM-DD')).isSame(moment(startDate).format('YYYY-MM-DD')));
    } else {
      dateFilteredData = teacherData.filter((item) => moment(item.endDate).isBetween(moment(startDate).subtract(12, 'hours'), moment(endDate).add(12, 'hours')));
      alldateFilteredData = data.filter((item) => moment(item.endDate).isBetween(moment(startDate).subtract(12, 'hours'), moment(endDate).add(12, 'hours')));
    }

    setSessionsCount(alldateFilteredData.length);
    const dateFilteredDataCount = dateFilteredData.length;
    return {
      data: dateFilteredData,
      dataCount: dateFilteredDataCount,
    };
  };

  // Calendar Logic
  const resources = [{
    fieldName: 'teacherId',
    title: 'Teacher',
    instances: teachers?.length ? teachers : [{ text: 'No teachers yet', id: 1, color: '#ec516e' }],
  }];

  const grouping = [{
    resourceName: 'teacherId',
  }];

  const groupOrientation = (viewName: any) => viewName.split(' ')[0];

  useEffect(() => {
    if (calendarData) {
      setData(() => calendarData.adminCalendar.sessions.map((item) => ({
        id: item.id,
        title: (
          <>
            <ReactTooltip />
            <div
              className="calender-appointment"
              data-tip={!item.isAccessible ? 'User cannot access this class' : ''}
              data-place="top"
              data-type="warning"
              data-effect="solid"
            >
              <p>{item.course}</p>
              <p>{`Student: ${item.studentName}`}</p>
              <p>{`Teacher: ${item.teacherName}`}</p>
              <p>{item.status}</p>
            </div>
          </>
        ),
        startDate: item.startDate,
        endDate: item.endDate,
        teacherId: item.teacherId,
        planId: item.planId,
        course: item.course,
        dateTime: item.dateTime,
        duration: item.duration,
        sessionNumber: item.sessionNumber,
        acuityCalendarId: item.acuityCalendarId,
        acuityAppTypeIds: item.acuityAppTypeIds,
        teacherName: item.teacherName,
        studentName: item.studentName,
        link: item.adminLink,
        isAccessible: item.isAccessible
      })));
    }
  }, [calendarData]);

  useEffect(() => {
    if (calendarData) {
      setTeachers(() => calendarData.adminCalendar.teachers.map((item) => ({
        text: `${item.text} - ${calculataData(item.id, currentDate).dataCount} ${calculataData(item.id, currentDate).dataCount === 1 ? 'Session' : 'Sessions'}`,
        id: item.id,
        color: item.color,
      })));
      if (calendarData.adminCalendar?.sessions?.length) {
        // timeline limits
        let min = 24;
        let max = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const item of calendarData?.adminCalendar?.sessions) {
          if (moment(item.startDate).hour() < min) {
            min = moment(item.startDate).hour();
          }
          if (moment(item.endDate).hour() > max) {
            max = moment(item.endDate).hour() + 1;
          }
        }
        setTimeline({ min, max });
      }
    }
  }, [data, currentDate, view]);

  const generateFilter = (item?: string) => {
    switch (item) {
      case 'Subject':
        return <ClassFilters />;
      case 'Teacher':
        return <TeacherFilter />;
      case 'Country':
        return <LocationFilter />;
      case 'Class ID':
        return <ClassIdFilter />;
      default:
        return <ClassFilters />;
    }
  };

  // Rescheduling Logic
  useEffect(() => {
    if (session && acuityAppTypeId) {
      dispatch(setPlanData({
        id: session?.planId,
        teacherID: session?.teacherId,
        typeID: acuityAppTypeId,
        calendarID: session?.acuityCalendarId,
      }));
      dispatch(setAcuityTypeID(acuityAppTypeId));
      dispatch(setAcuityCalendarID(session?.acuityCalendarId));
    }
  }, [acuityAppTypeId, session]);

  useEffect(() => {
    if (session?.course) {
      setAcuityAppTypeId(appointmentTypeIds[session?.course][session?.duration]?.id);
      dispatch(setPlanData({ ...mainCalendarState.plan, duration: appointmentTypeIds[session?.course][session?.duration]?.time }));
    }
  }, [session]);

  useEffect(() => {
    if (!sessionState.rescheduleModal) {
      dispatch(resetTimeSlots());
      dispatch(setPlanData({}));
      dispatch(setAcuityTypeID(undefined));
      dispatch(setAcuityCalendarID(undefined));
    }
  }, [sessionState.rescheduleModal]);

  useEffect(() => {
    const date = moment().date(moment(currentDate).date()).month(moment(currentDate).month()).year(moment(currentDate).year())
      .toDate();
    setMonth(date);
  }, [moment(currentDate).month()]);

  return {
    height,
    width,
    data,
    currentDate,
    setCurrentDate,
    resources,
    grouping,
    groupOrientation,
    setFilter,
    toggleFiltersModal,
    toggleAppointmentViewModal,
    mainCalendarState,
    dispatch,
    generateFilter,
    isLoading,
    isFetching,
    teachers,
    sessionState,
    setRescheduleModal,
    setSession,
    setPlanData,
    timeline,
    setView,
    view,
    sessionsCount
  };
};
