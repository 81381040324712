import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminPayClass = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ classId, expMonth, expYear, cvc, cardNumber }: { classId?: number, expMonth?: number, expYear?: number, cvc?: string, cardNumber?: string }) => client.request(
    gql`
    mutation AdminPayClass($classId: Float!, $expMonth: Float!, $expYear: Float!, $cvc: String!, $cardNumber: String!) {
      adminPayClass(classId: $classId, exp_month: $expMonth, exp_year: $expYear, cvc: $cvc, cardNumber: $cardNumber) {
        message
      }
    }
    `,
    {
      classId,
      expMonth,
      expYear,
      cvc,
      cardNumber
    }
  ));
  return Mutation;
};
