// Types
import { HomeworkStatQuery } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useGetHomeworkStat = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<HomeworkStatQuery, Error> = useQuery<HomeworkStatQuery, Error>(['homework-stat'], async () => client.request(
    gql`
    query TeacherAssignmentsStat {
      teacherAssignmentsStat {
        allAssignments
        totalPending
        totalPendingPercentage
        totalSubmitted
        totalSubmittedPercentage
        totalGraded
        totalGradedPercentage
      }
    }
    `,
  ).catch((error) => console.error(error)));
  return data;
};
