// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { AdminSessionsStats } from './types';

export const useAdminSessionsStats = ({ classId }: { classId: number }) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<AdminSessionsStats, Error> = useQuery<AdminSessionsStats, Error>(['admin-sessions-stats'], async () => client.request(
    gql`
    query UserClass($classId: Float!) {
      userClass(classId: $classId) {
        rescheduledSessions
        remainingSessions
        attendedSessions
        missedSessions
      }
    }
    `,
    {
      classId: Number(classId),
    }
  ));
  return data;
};
