// Types
import { SessionPayments } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const usePaymentStatus = (userId: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<SessionPayments, Error> = useQuery<SessionPayments, Error>(['payment-status', userId], async () => client.request(
    gql`
    query AdminGetUserClassPayments($userId: Float!) {
      adminGetUserClassPayments(userId: $userId) {
        allDiscount
        allPayments
        netPayments
        remainingAmount
        classes {
          isPaid
          id
          classId
          isAccessible
          status
          plan {
            price
            course {
              name
            }
          }
          paidByAdmin {
            first_name
            last_name
          }
          payments{
            id
            paymentMethod
            createdBy
            amount
            amountPaid
            refund
            discount
            comment
            transactionDate
            type
          }
        }
      }
    }
    `,
    {
      userId
    }
  ).catch((error) => console.error(error)));
  return data;
};
