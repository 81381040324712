// Types
import { SessionTests } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useProgressDiagram = (userId: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<SessionTests, Error> = useQuery<SessionTests, Error>(['session-tests', userId], async () => client.request(
    gql`
    query AdminGetUserClassTests($userId: Float!) {
      adminGetUserClassTests(userId: $userId) {
        id
        classId
        status
        plan {
          course {
            name
          }
        }
        marksArray
        dates
        tests {
          id
          name
          dueDate
          receivedMark {
            QuantitativeScore
            englishScore
            listening
            mathScore
            reading
            speaking
            totalScore
            verbalScore
            writing
          }
        }
      }
    }
    `,
    {
      userId
    }
  ).catch((error) => console.error(error)));
  return data;
};
