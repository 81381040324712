import { gql } from 'graphql-request';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { AttachmentProps } from './types';

export const useUserSubmitAssignment = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ id, attachmentsIds, alreadySubmitted }: AttachmentProps) => client.request(
    gql`
    mutation UserSubmitAssignment($alreadySubmitted: Boolean, $attachmentsIds: [Float!], $assignmentId: Float!) {
      userSubmitAssignment(alreadySubmitted: $alreadySubmitted, attachmentsIds: $attachmentsIds, assignmentId: $assignmentId) {
        id
        name
        fullMark
        createdById
        classId
        receivedMark
        details
        status
        dueDate
        createdAt
        attachments {
          id
          attachment
          teacherAttachment
        }
      }
    }
    `,
    {
      assignmentId: id,
      attachmentsIds,
      alreadySubmitted
    }
  ).catch((error) => toast(error.response.errors[0].message)), {
    onSuccess: () => {}
  });
  return Mutation;
};
