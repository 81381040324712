import Button from 'components/General/Button/Button';
import { setFilter, StudentsSlice, toggleFiltersModal } from '../../StudentsSlice';
import { useEffect, useState } from 'react';
import { CheckboxGroup } from 'react-rainbow-components';
import { useDispatch, useSelector } from 'react-redux';
import './StatusFilter.scss';

export const StatusFilter = () => {
  const dispatch = useDispatch();
  const studentsState = useSelector((state: { students: StudentsSlice }) => state.students);
  const [values, setVlaues] = useState<string[] | undefined>(studentsState.filter.userStatus);

  const handleApplyFilters = () => {
    dispatch(setFilter({ userStatus: values }));
    dispatch(toggleFiltersModal(false));
  };

  const options = [
    { value: 'Active', label: 'Active', disabled: false },
    { value: 'Finished', label: 'Finished', disabled: false },
    { value: 'Cancelled', label: 'Cancelled', disabled: false },
    { value: 'Not Started', label: 'Not started', disabled: false },
  ];

  useEffect(() => {
    setVlaues(studentsState.filter.userStatus);
  }, [studentsState.filter.userStatus]);

  return (
    <div className="status-filter-container">
      <h2>Status</h2>
      <div className="filters-container">
        <CheckboxGroup
          options={options}
          value={values}
          onChange={(e) => setVlaues(e)}
          className="checkbox"
        />
      </div>
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
