import { useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Services
import { useCourse, useUserInfo } from 'services/Onboarding/General/useOnboarding';

// Router
import { useParams } from 'react-router';
import { useAdminGetPeronalInfo } from 'services/AdminDashboard/PersonalInfo/PersonalInfo/useAdminGetPeronalInfo';
import { useGetUserSteps } from 'services/Onboarding/AdminOnboarding/Queries/UserSteps/useGetUserSteps';
import onboarding, { OnboardingSlice } from './OnboardingSlice';

export const useLogic = () => {
  const { userId, className } = useParams();
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const { data: courses } = useCourse(onboardingState.plan.course || '', !!onboardingState.plan.course && !className);
  const { data: adminCourses } = useGetUserSteps(Number(userId), String(className), !!className && !!userId);
  const { data: userInfo } = useUserInfo();
  const { data: personalInfo } = useAdminGetPeronalInfo(Number(userId), !!userId);
  const { setItems, setPlanData, setActive, updateUserInfo } = onboarding.actions;
  const dispatch = useDispatch();

  // user onboarding
  useEffect(() => {
    if (!userId) {
      if (!courses) return;
      const items = courses?.userSteps;
      dispatch(setItems(items));
      let active = (items?.findIndex((item: any) => item.active === false) || 0) + 1;
      // FIXME we should use step ids instead of index
      if (active === (onboardingState.plan.course === 'IELTS' ? 3 : 4) && onboardingState.plan.isDraft && onboardingState.plan.id) {
        active++;
      } else if (onboardingState.plan.isDraft && !onboardingState.plan.id) {
        dispatch(setPlanData({ isDraft: false }));
      }
      dispatch(setActive(active));
    }
  }, []);

  // admin onboarding
  useEffect(() => {
    if (!adminCourses) return;
    const items = adminCourses?.userSteps.userSteps;
    dispatch(setItems(items));
    let active = (items?.findIndex((item: any) => item.active === false) || 0) + 1;
    // FIXME we should use step ids instead of index
    if (active === (onboardingState.plan.course === 'IELTS' ? 3 : 4) && onboardingState.plan.isDraft && onboardingState.plan.id) {
      active++;
    } else if (onboardingState.plan.isDraft && !onboardingState.plan.id) {
      dispatch(setPlanData({ isDraft: false }));
    }
    dispatch(setActive(active));
  }, [adminCourses]);

  useEffect(() => {
    if (className) {
      dispatch(setPlanData({
        course: className,
        courseID: (className === 'SAT' && 1) || (className === 'GMAT' && 2) || (className === 'IELTS' && 3) || 1
      }));
    }
  }, [adminCourses]);

  // user onboarding
  useEffect(() => {
    if (userInfo && (!userId)) {
      dispatch(updateUserInfo({
        firstName: userInfo.userGetInfo.first_name,
        lastName: userInfo.userGetInfo.last_name,
        email: userInfo.userGetInfo.email,
        birthday: userInfo.userGetInfo.birthday,
        country: userInfo.userGetInfo.info.country,
        schoolName: userInfo.userGetInfo.info.school_name,
        graduationYear: userInfo.userGetInfo.info.graduation_year,
        guardainName: userInfo.userGetInfo.info.guardain_name,
        guardainNumber: userInfo.userGetInfo.info.guardain_number,
        guardainEmail: userInfo.userGetInfo.info.guardain_email,
        phoneNumber: userInfo.userGetInfo.phoneNumber,
        info: {
          done_courses: userInfo.userGetInfo.info.done_courses,
        },
      }));
    }
  }, [userInfo]);

  // admin onboarding
  useEffect(() => {
    if (personalInfo && userId) {
      dispatch(updateUserInfo({
        firstName: personalInfo.adminGetUserInfo?.first_name,
        lastName: personalInfo.adminGetUserInfo?.last_name,
        email: personalInfo.adminGetUserInfo?.email,
        birthday: personalInfo.adminGetUserInfo?.birthday,
        country: personalInfo.adminGetUserInfo?.info?.country,
        schoolName: personalInfo.adminGetUserInfo?.info?.school_name,
        graduationYear: personalInfo.adminGetUserInfo?.info?.graduation_year,
        guardainName: personalInfo.adminGetUserInfo?.info?.guardain_name,
        guardainNumber: personalInfo.adminGetUserInfo?.info?.guardain_number,
        guardainEmail: personalInfo.adminGetUserInfo?.info?.guardain_email,
        phoneNumber: personalInfo.adminGetUserInfo?.phoneNumber,
        info: {
          done_courses: personalInfo.adminGetUserInfo.info.done_courses,
        },
      }));
    }
  }, [personalInfo]);

  return {
    onboardingState,
    className
  };
};
