// Components
import Button from 'components/General/Button/Button';
import OnboardingCard from 'components/Onboarding/OnboardingCard/OnboardingCard';
import SelectBox, { SchoolsSelectBox } from 'components/General/SelectBox/SelectBox';
import TextField from 'components/General/TextField/TextField';

// Assets
import { countries } from 'assets/files/countries';

// Date picker
import { DatePicker } from 'react-rainbow-components';

// Logic
import { useLogic } from './SchoolInfo.logic';

// Grid system
import { Col, Row } from 'react-grid-system';

// React hook form
import { Controller } from 'react-hook-form';
import { STUDENT_DASHBOARD } from 'routes/CONSTANTS';
import { useNavigate, useParams } from 'react-router';

const SchoolInfoIELTS = () => {
  const {
    options,
    onSubmit,
    errors,
    control,
    takenSat,
    onboardingState,
    setValue,
    getValues,
    getTotalScore,
    roundToNearestMark,
    isLoadingAdminFinishOnboarding,
    isLoadingFinishOnboarding,
    isLoadingAdminPostInfo,
    previousPage,
    dispatch
  } = useLogic();
  const navigate = useNavigate();
  const { userId, className } = useParams();
  return (
    <Row>
      <Col
        md={6}
        offset={{ md: 3 }}
      >
        <OnboardingCard>
          <h2>Tell us about your school</h2>
          <form onSubmit={onSubmit}>
            <Controller
              control={control}
              name="country"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                }
              }}
              render={({ fieldState: { error }, field: { onChange, value } }) => (
                <SelectBox
                  options={countries}
                  label="Country"
                  placeholder="United Arab Emirates"
                  error={error?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name="schoolName"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                }
              }}
              render={({ fieldState: { error }, field: { onChange, value } }) => (
                <SchoolsSelectBox
                  addSchool
                  label="School name"
                  placeholder="School name"
                  error={error?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name="graduationYear"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                }
              }}
              render={() => (
                <div className="rainbow-m-around_small">
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    labelAlignment="left"
                    className="date-picker"
                    label="Graduation year"
                    error={errors.graduationYear?.message}
                    icon={<></>}
                    value={getValues().graduationYear}
                    onChange={(value) => setValue('graduationYear', value)}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="takenCourse"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                }
              }}
              render={({ fieldState: { error }, field: { onChange } }) => (
                <SelectBox
                  options={options}
                  label={`Have you previously taken the ${className || onboardingState.plan.course}?`}
                  placeholder="Select..."
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
            { takenSat && (
            <>
              <Controller
                control={control}
                name="scores.listening"
                rules={{
                  required: {
                    value: takenSat,
                    message: 'This field is required'
                  },
                  min: {
                    value: 1,
                    message: 'Score cannot be below 1'
                  },
                  max: {
                    value: 9,
                    message: 'Score cannot be above 9'
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    color="light-gray"
                    placeholder="1 - 9"
                    step={0.5}
                    variant="rounded"
                    label="Listening"
                    id="listening-score"
                    type="number"
                    onChange={(event) => {
                      event.target.value = `${roundToNearestMark(+event.target.value)}`;
                      onChange(event);
                    }}
                    error={errors?.scores?.listening?.message}
                    value={value || ''}
                  />
                )}
              />
              <Controller
                control={control}
                name="scores.reading"
                rules={{
                  required: {
                    value: takenSat,
                    message: 'This field is required'
                  },
                  min: {
                    value: 1,
                    message: 'Score cannot be below 1'
                  },
                  max: {
                    value: 9,
                    message: 'Score cannot be above 9'
                  }
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    color="light-gray"
                    onChange={(event) => {
                      event.target.value = `${roundToNearestMark(+event.target.value)}`;
                      onChange(event);
                    }}
                    error={errors?.scores?.reading?.message}
                    id="reading-score"
                    type="number"
                    placeholder="1 - 9"
                    step={0.5}
                    label="Reading"
                    variant="rounded"
                    value={value || ''}
                  />
                )}
              />
              <Controller
                control={control}
                name="scores.writing"
                rules={{
                  required: {
                    value: takenSat,
                    message: 'This field is required'
                  },
                  min: {
                    value: 1,
                    message: 'Score cannot be below 1'
                  },
                  max: {
                    value: 9,
                    message: 'Score cannot be above 9'
                  }
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    color="light-gray"
                    onChange={(event) => {
                      event.target.value = `${roundToNearestMark(+event.target.value)}`;
                      onChange(event);
                    }}
                    error={errors?.scores?.writing?.message}
                    id="writing-score"
                    type="number"
                    placeholder="1 - 9"
                    step={0.5}
                    label="Writing"
                    variant="rounded"
                    value={value || ''}
                  />
                )}
              />
              <Controller
                control={control}
                name="scores.speaking"
                rules={{
                  required: {
                    value: takenSat,
                    message: 'This field is required'
                  },
                  min: {
                    value: 1,
                    message: 'Score cannot be below 1'
                  },
                  max: {
                    value: 9,
                    message: 'Score cannot be above 9'
                  }
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    color="light-gray"
                    onChange={(event) => {
                      event.target.value = `${roundToNearestMark(+event.target.value)}`;
                      onChange(event);
                    }}
                    error={errors?.scores?.speaking?.message}
                    id="speaking-score"
                    type="number"
                    placeholder="1 - 9"
                    step={0.5}
                    label="Speaking"
                    variant="rounded"
                    value={value || ''}
                  />
                )}
              />
              <TextField
                color="light-gray"
                variant="rounded"
                placeholder="1 - 9"
                id="total-score"
                label="Overall score"
                type="number"
                disabled
                value={getTotalScore()}
              />
            </>
            )}
            <div className="button-container mb-1">
              <Button
                color="primary"
                type="submit"
                isLoading={isLoadingAdminFinishOnboarding || isLoadingFinishOnboarding || isLoadingAdminPostInfo}
              >
                Next
              </Button>
              <div className="skip-container mb-3">
                <div className="back-container mb-2 mt-1">
                  <Button
                    color="white"
                    onClick={() => dispatch(previousPage())}
                  >
                    Back

                  </Button>
                </div>
              </div>
              {!userId && (
                <h3>
                  <a
                    href="#"
                    onClick={() => navigate(STUDENT_DASHBOARD)}
                  >
                    Skip
                  </a>

                </h3>
              )}
            </div>
          </form>
        </OnboardingCard>
      </Col>
    </Row>
  );
};
export default SchoolInfoIELTS;
