// Types
import { TeachersNames } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useGetTeacherNames = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeachersNames, Error> = useQuery<TeachersNames, Error>(['teachers-names'], async () => client.request(
    gql`
    query AdminGetTeachersNames {
      adminGetTeachersNames {
        value
        label
      }
    }
    `,
  ));
  return data;
};
