// Styling
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import './PersonalInfoData.scss';

// Types
import { PersonalInfoDataTypes, PersonalInfoDataScoreTypes } from './PersonalInfoData.types';

export const PersonalInfoData = ({ title, text, content }: PersonalInfoDataTypes) => (
  <div className="personal-info-data-container">
    <div className="title">
      <p>
        {title}
      </p>
    </div>
    <div className="text">
      <p>
        {text}
      </p>
      {content}
    </div>
  </div>
);

export const PersonalInfoDataScore = ({ title, total, scores }: PersonalInfoDataScoreTypes) => (
  <div className="personal-info-data-score-container">
    <div className="title">
      <p>
        {`${title}: ${total || ''}`}
      </p>
    </div>
    <div className="scores-container">
      {
        scores ? (
          scores?.map((score) => (
            <p key={score.subject}>{`${score?.subject}: ${score?.score}`}</p>
          ))
        ) : (
          <p>-</p>
        )
      }
    </div>
  </div>
);

export const PersonalInfoFinalTest = ({ title, data }: PersonalInfoDataScoreTypes) => (
  <div className="personal-info-data-score-container">
    <div className="title">
      <p>
        {title}
      </p>
    </div>
    <div className="scores-container">
      {data?.map((item) => (
        item.course ? (
          <>
            <ReactTooltip />
            <p
              key={item.id}
              data-tip={`Test date: ${item.finalTest ? moment(item.finalTest).format('YYYY-MM-DD') : '-'} / Score: ${item.targetScore || '-'}`}
              data-place="top"
              data-type="warning"
              data-effect="solid"
            >
              {item.course}
            </p>
          </>
        ) : (
          '-'
        )
      ))}
    </div>
  </div>
);
