import axios from 'axios';
import { useMutation } from 'react-query';

// Login
export const useLogin = () => useMutation(async (variables: { email: string; password: string; }) => axios.post('auth/login', variables));

// Signup
export const useSignup = () => useMutation(async (variables: { email: string; password: string; }) => axios.post('auth/register', variables));
export const useVerifySignup = () => useMutation(async (variables: { email: string; code: string }) => axios.post('auth/verifyReg', variables));
export const useResendCode = () => useMutation(async (variables: { email: string; }) => axios.post('auth/sendVerification', { ...variables, state: true }));

// Forgot Password
export const useForgotPassword = () => useMutation(async (variables: { email: string }) => axios.post('auth/sendVerification', { ...variables, state: false }));
export const useVerifyResetPassword = () => useMutation(async (variables: { email: string; code: string }) => axios.post('auth/verifyResetPass', variables));
export const useResetPassword = () => useMutation(async (variables: { email: string; code: string; password: string; }) => axios.post('auth/resetPassword', variables));

// Social Auth
export const useGoogleAuth = () => useMutation(async (variables: { token: string; }) => axios.post('auth/google-authenticate', variables));
export const useFacebookAuth = () => useMutation(async (variables: { token: string; }) => axios.post('auth/facebook-authenticate', variables));
