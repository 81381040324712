// Components
import Button from 'components/General/Button/Button';
import onboarding from 'pages/Onboarding/OnboardingSlice';

// Logic
import { useLogic } from '../Payment.logic';

export const Apple = () => {
  const { setChosePaymentMethod } = onboarding.actions;
  const { dispatch } = useLogic();

  return (
    <div className="apple-container">
      <h1>Apple</h1>
      <Button
        color="primary"
        onClick={() => dispatch(setChosePaymentMethod(false))}
      >
        Back to payment
      </Button>
    </div>
  );
};
