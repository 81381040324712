// Types
import { PaymentInfo } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const usePaymentInfo = (classId?: number, code?: string) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<PaymentInfo, Error> = useQuery<PaymentInfo, Error>(['payment-info', [classId, code]], async () => client.request(
    gql`
    query Query($classId: Float!) {
      userGetPaymentInfo(classId: $classId) {
        totalAmount
        discountAmount
        finalAmount
        usedCoupon
        planHours
      }
    }
    `,
    {
      classId
    }
  ).catch((error) => console.error(error)));
  return data;
};
