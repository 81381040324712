// Styling
import { ClickUpTaskCard } from 'components/ClickUpTaskCard/ClickUpTaskCard';
// import { CalendarCourseCard } from 'components/Dashboard/CalendarCourseCard/CalendarCourseCard';
import DotsLoader from 'components/General/Loader/Loader';
import { useLogic } from './Todos.logic';
import './Todos.scss';

import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  Appointments, CurrentTimeIndicator } from '@devexpress/dx-react-scheduler-material-ui';
import TeacherDashboard from '../Dashboard';
import Button from 'components/General/Button/Button';
import { TEACHER_CALENDAR, TEACHER_DASHBOARD } from 'routes/CONSTANTS';

export const Todos = () => {
  const {
    listTasks,
    isLoadingTasks,
    handleChangeStatus,
    isUpdatingStatus,
    updatedId,
    data,
    navigate
  } = useLogic();

  return (
    <TeacherDashboard>
      <div className="admin-todo-container">
        <div className="todos">
          <div className="tasks-todos">
            <h2>Your tasks</h2>
            <div className="tasks-container">
              {isLoadingTasks ? (
                <DotsLoader />
              ) : (
                listTasks?.adminGetListTasks.todo?.map((task) => (
                  <ClickUpTaskCard
                    key={task.id}
                    color={task.status.color}
                    onClick={() => handleChangeStatus(String(task.id), true)}
                    data={task.name}
                    isLoading={isUpdatingStatus && (String(task.id) === updatedId)}
                  />
                ))
              )}
              {listTasks?.adminGetListTasks.todo?.length === 0 && (
              <h3>No tasks!😇</h3>
              )}
            </div>
          </div>
          <div className="finished-todos">
            <h2>Finished tasks</h2>
            <div className="tasks-container">
              {isLoadingTasks ? (
                <DotsLoader />
              ) : (
                listTasks?.adminGetListTasks.finished?.map((task) => (
                  <ClickUpTaskCard
                    key={task.id}
                    color={task.status.color}
                    onClick={() => handleChangeStatus(String(task.id), false)}
                    data={task.name}
                    isLoading={isUpdatingStatus && (String(task.id) === updatedId)}
                    done
                  />
                ))
              )}
              {listTasks?.adminGetListTasks.todo?.length === 0 && (
              <h3>No tasks!😇</h3>
              )}
            </div>
          </div>
        </div>

        <div className="calendar">
          <div className="calendar-header">
            <h2 className="ml-2 mb-3">Todays sessions</h2>
            <div className="go-to-calendar-button">
              <Button
                color="white"
                variant="small"
                onClick={() => navigate(`/${TEACHER_DASHBOARD}/${TEACHER_CALENDAR}`)}
              >
                Calendar
              </Button>
            </div>
          </div>
          <Paper>
            <Scheduler
              data={data}
              height={565}
            >
              <ViewState
                currentDate={new Date()}
              />
              <DayView
                startDayHour={0}
                endDayHour={24}
                cellDuration={60}
              />
              <Appointments appointmentComponent={(props) => (
                <>
                  <div className="appointment-wrapper">
                    <div className="appointment-data">
                      {props.data.title}
                    </div>
                    <div>
                      <Button
                        color="primary"
                        variant="small"
                        onClick={() => window.open(props.data?.link, '_blank')}
                      >
                        Open
                      </Button>
                    </div>
                  </div>
                </>
              )}
              />
              <CurrentTimeIndicator />
            </Scheduler>
          </Paper>
        </div>

      </div>
    </TeacherDashboard>
  );
};
