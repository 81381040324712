import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter, StudentsSlice } from '../StudentsSlice';

export const useLogic = () => {
  const dispatch = useDispatch();
  const studentsState = useSelector((state: { teacherStudents: StudentsSlice }) => state.teacherStudents);
  const [value, setValue] = useState('');

  const filters = [
    { filter: 'Subjects', active: studentsState.filter.courses },
    { filter: 'Status', active: studentsState.filter.userStatus },
    { filter: 'Country', active: studentsState.filter.country },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filters?.[0]?.filter);

  useEffect(() => {
    dispatch(setFilter({ search: value }));
  }, [value]);

  return {
    filters,
    selectedFilter,
    setSelectedFilter,
    dispatch,
    studentsState,
    setValue,
    value
  };
};
