import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useUpdateAssignment } from 'services/Dashboard/Assignments/Mutations/useUpdateAssignment';
import { AssignmentSlice, setMark } from '../AssignmentSlice';
import { GiveMark } from './GiveMark.types';

export const useLogic = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const assignmentState = useSelector((state: { assignment: AssignmentSlice }) => state.assignment);
  const { mutateAsync: updateAssignment, isLoading } = useUpdateAssignment();
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<GiveMark>({
    defaultValues: {
      mark: 0,
      originalMark: 0,
    }
  });

  const onSubmit = handleSubmit((data) => {
    if (errors.mark) return;
    const mark = {
      receivedMark: Number(data.mark),
      classId: Number(id),
      id: assignmentState.mark?.id
    };
    updateAssignment(mark).then(() => {
      dispatch(setMark({ toggleModal: false }));
      queryClient.refetchQueries('assignment-chart');
      setValue('mark', 0);
    });
  });

  useEffect(() => {
    setValue('originalMark', assignmentState.mark?.originalMark);
  }, [assignmentState.mark]);

  return {
    control,
    errors,
    onSubmit,
    isLoading,
    assignmentState,
  };
};
