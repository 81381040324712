import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { SchoolInfoResponse } from './types';

export const usePostSchoolInfo = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ userId, stepId, country, graduationYear, schoolName, courseName, scores }: SchoolInfoResponse) => client.request(
    gql`
    mutation SchoolInfo($stepId: Float!, $userId: Float!, $country: String, $graduationYear: String, $schoolName: String, $courseName: String, $scores: adminScore) {
      schoolInfo(step_id: $stepId, userId: $userId, country: $country, graduation_year: $graduationYear, school_name: $schoolName, course_name: $courseName, scores: $scores) {
        message
      }
    } 
    `,
    {
      stepId,
      userId,
      country,
      graduationYear,
      schoolName,
      courseName,
      scores: {
        englishScore: scores.englishScore,
        mathScore: scores.mathScore,
        totalScore: scores.totalScore,
        reading: scores.reading,
        writing: scores.writing,
        speaking: scores.speaking,
        listening: scores.listening,
        verbalScore: scores.verbalScore,
        QuantitativeScore: scores.QuantitativeScore,
      }
    }
  ));
  return Mutation;
};
