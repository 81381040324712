// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { TeacherNearestSessionQuery } from './types';

export const useTeacherNearestSession = (classId: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeacherNearestSessionQuery, Error> = useQuery<TeacherNearestSessionQuery, Error>(['teacherNearestSession'], async () => client.request(
    gql`
    query TeacherSessions($classId: Float!) {
      teacherSessions(classId: $classId) {
        id
        dueAt
        userId
        sessionNumber
        class {
          classId
          plan {
            course {
              name
            }
          }
        }
        teacherLink
      }
    }
    `,
    {
      classId: Number(classId)
    }
  ));
  return data;
};
