import { base64 } from 'assets/utils/getBase64';
import { Files } from 'components/Dashboard/AssignmentCard/AssignmentCard.types';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';

// React form
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useCreatePracticeTest } from 'services/Dashboard/PracticeTests/Mutations/useCreatePracticeTest';
import { useUpdatePracticeTest } from 'services/Dashboard/PracticeTests/Mutations/useUpdatePracticeTest';
import { useFileUploadMutation } from 'services/UploadFiles/useUploadFile';
import { PracticeTestSlice, setEditPracticeTestId, setReusePracticeTest, setToggleNewPracticeTestModal } from '../PracticeTestSlice';

// Types
import { NewPracticeTestType, TestURLs } from './NewPracticeTest.types';

export const useLogic = () => {
  const { id, className, classId } = useParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { getBase64 } = base64();
  const editorRef = useRef<any>(null);
  const { mutateAsync: createPracticeTest, isLoading: isLoadingCreateTest } = useCreatePracticeTest();
  const { mutateAsync: updatePracticeTest, isLoading: isLoadingUpdateTest } = useUpdatePracticeTest();
  const { progress, setProgress, mutation: { mutateAsync: uploadFiles, isLoading: isUploading } } = useFileUploadMutation();
  const practiceTestState = useSelector((state: { practiceTest: PracticeTestSlice }) => state.practiceTest);
  const [editorInitialValue, setEditorInitialValue] = useState<string>();
  const [editorValue, setEditorValue] = useState<string>();
  const input = useRef<HTMLInputElement | null>(null);
  const [attachmentsIds, setAttachmentsIds] = useState<number[]>([]);
  const [files, setFiles] = useState<Files>([]);
  const [testURLs, setTestURLs] = useState<TestURLs>([]);
  const [deletedURLs, setDeletedURLs] = useState<string[]>([]);

  const isImage = (url: string) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);

  const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm<NewPracticeTestType>({
    defaultValues: {
      name: '',
      date: new Date()
    }
  });

  const addFiles = (file: Files) => {
    setFiles([...file, ...files]);
  };

  const onSubmit = handleSubmit((data) => {
    if (errors.name?.message || errors.date?.message) return;
    if (!editorValue) {
      toast.error('Please provide the assignment details.');
      return;
    }
    if (practiceTestState.editPracticeTestId) {
      const editPracticeTestData = {
        ...data,
        dueDate: moment(data.date).format('YYYY-MM-DD'),
        classId: Number(id),
        details: editorValue,
        addAttachmentsIds: attachmentsIds,
        deleteAttachmentsNames: deletedURLs
      };
      updatePracticeTest(editPracticeTestData).then(() => {
        setValue('name', undefined);
        setEditorInitialValue('');
        setEditorValue('');
        dispatch(setReusePracticeTest({}));
        dispatch(setEditPracticeTestId(undefined));
        dispatch(setToggleNewPracticeTestModal());
        setTestURLs([]);
        setFiles([]);
        setDeletedURLs([]);
        setAttachmentsIds([]);
        queryClient.refetchQueries('reusePracticeTests');
      });
    } else {
      const newPracticeTestData = {
        ...data,
        dueDate: moment(data.date).format('YYYY-MM-DD'),
        classId: Number(id),
        details: editorValue,
        attachmentsIds
      };
      createPracticeTest(newPracticeTestData).then(() => {
        setValue('name', undefined);
        setEditorInitialValue('');
        setEditorValue('');
        dispatch(setReusePracticeTest({}));
        dispatch(setEditPracticeTestId(undefined));
        dispatch(setToggleNewPracticeTestModal());
        setFiles([]);
        setAttachmentsIds([]);
        queryClient.refetchQueries('reusePracticeTests');
      });
    }
  });

  // for reuse and edit test, it fills the initial value with the selected test.
  useEffect(() => {
    setValue('id', practiceTestState.reusePracticeTest?.id);
    setValue('name', practiceTestState?.reusePracticeTest?.name);
    setValue('date', moment(practiceTestState?.reusePracticeTest?.dueDate).toDate());
    setTestURLs(practiceTestState.reusePracticeTest.attachments);
    setEditorInitialValue(practiceTestState?.reusePracticeTest?.details);
    setEditorValue(practiceTestState?.reusePracticeTest?.details);
    setAttachmentsIds(practiceTestState.reusePracticeTest.attachments?.filter((item) => item.teacherAttachment).map((attachment) => attachment.id) || []);
  }, [practiceTestState?.reusePracticeTest]);

  const handleChange = (e: any) => {
    setEditorValue(e.target.value || e.target.getContent());
  };

  useEffect(() => {
    if (practiceTestState?.showNewPracticeTestModal === false) {
      setValue('name', undefined);
      setValue('date', undefined);
      setEditorInitialValue('');
      setEditorValue('');
      dispatch(setReusePracticeTest({}));
      setFiles([]);
      setTestURLs([]);
      setDeletedURLs([]);
      dispatch(setEditPracticeTestId(undefined));
    }
  }, [practiceTestState?.showNewPracticeTestModal]);

  const handleUpload = async (e: any) => {
    if (!e.target.files) return;

    const formData = new FormData();
    const promises = [];
    const temp: Files = [];

    for (let i = 0; i < e.target.files.length || 0; i++) {
      if (e.target.files[i].size > 26214400) {
        toast.error('File cannot be bigger than 25 Megabytes');
        e.target.value = '';
        return;
      }
      formData.append('files[]', e.target.files[i]);
      formData.append('teacherAttachment', 'true');
      promises.push(getBase64(e.target.files[i]));
    }

    const responses = await Promise.all(promises);
    responses.map((res: any) => {
      const startIndex = String(res.result).search('base64,') + 7;
      const base46 = String(res.result).slice(startIndex, String(res.result).length);
      temp.push({ base64: base46, type: res.type });
      return res;
    });
    addFiles(temp);

    uploadFiles({ file: formData })
      .then((res: any) => {
        setProgress(0);
        setAttachmentsIds([...attachmentsIds, ...res.data.attachmentsIds]);
      }).catch(() => {
        setFiles([...files].slice(-1));
      });

    e.target.value = '';
  };

  const handleRemoveUploadedFile = (index: number) => {
    if (practiceTestState.reusePracticeTest.attachments) {
      setTestURLs(testURLs?.filter((url) => {
        setDeletedURLs([...deletedURLs, String(testURLs?.[index]?.attachment)]);
        return testURLs.indexOf(url) !== index;
      }));
      setAttachmentsIds(attachmentsIds.filter((attachment) => attachmentsIds.indexOf(attachment) !== index));
    }
  };

  const handleRemoveNewFile = (index: number) => {
    setFiles(files.filter((file) => files.indexOf(file) !== index));
    setAttachmentsIds(attachmentsIds.filter((attachment) => attachmentsIds.indexOf(attachment) !== index));
  };

  return {
    control,
    errors,
    editorRef,
    className,
    classId,
    practiceTestState,
    editorInitialValue,
    isLoadingCreateTest,
    isLoadingUpdateTest,
    input,
    isUploading,
    progress,
    files,
    testURLs,
    isImage,
    onSubmit,
    setValue,
    getValues,
    handleUpload,
    handleChange,
    handleRemoveNewFile,
    handleRemoveUploadedFile,
  };
};
