import { gql } from 'graphql-request';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useDeleteSession = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ sessionId }: { sessionId?: number }) => client.request(
    gql`
    mutation AdminDeleteSession($sessionId: Float!) {
      adminDeleteSession(sessionId: $sessionId) {
        message
      }
    }
    `,
    {
      sessionId,
    }
  ).catch((error) => toast(error.response.errors[0].message)), {
    onSuccess: (data: any) => { toast.success(data?.adminDeleteSession.message); }
  });
  return Mutation;
};
