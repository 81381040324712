import { StatisticsCard } from 'components/Dashboard/StatisticsCard/StatisticsCard';
import { useLogic } from './Statistics.logic';
import ArrowsIcon from 'assets/images/arrows-up-down.svg';
import ChatIcon from 'assets/images/active-student.svg';

// Styling
import './Statistics.scss';

export const Statistics = () => {
  const { isFetching, sessionsCount, data } = useLogic();
  return (
    <div className="statistics-cards-container">
      <StatisticsCard
        image={ArrowsIcon}
        count={sessionsCount}
        description="Current week's teaching hours"
        isLoading={isFetching}
      />
      <StatisticsCard
        image={ChatIcon}
        count={data?.teacherCalendar.sessions ? Math.round(sessionsCount / 7) : 0}
        description="Average teaching hours per week"
        isLoading={isFetching}
      />
    </div>
  );
};
