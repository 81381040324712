// Style
import './Recordings.scss';

// Components
import { StudentRecordingCard } from 'components/Dashboard/StudentRecordingCard/StudentRecordingCard';
import { useLogic } from './Recordings.logic';
import DotsLoader from 'components/General/Loader/Loader';

export const Recordings = () => {
  const {
    data,
    isLoading
  } = useLogic();

  return (
    <>
      {isLoading ? (
        <DotsLoader />
      ) : (
        data?.classSessions?.filteredSessions.map((record) => (
          <StudentRecordingCard
            duration={record.duration}
            key={record.sessionNumber}
            sessionNumber={record.sessionNumber}
            active={record.published}
            onClick={() => record.recordingLink && window.open(record.recordingLink, '_blank')}
            date={record.dueAt}
            status={record.status}
          />
        ))
      )}
    </>
  );
};
