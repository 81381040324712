import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TimeSLot } from 'pages/Onboarding/OnboardingSlice';

export type Filters = {
  courses?: string[],
  countries?: string[],
  teachersIds?: number[],
  classesIds?: number[];
  classStatus?: string[];
  selectedFilter?: string
};

export type Session = {
  id?: number;
  startDate?: string | Date;
  endDate?: string | Date;
  teacherId?: number;
  planId?: number;
  course?: string;
  dateTime?: string;
  duration?: string;
  sessionNumber?: number;
  acuityCalendarId?: number;
  studentName?: string;
  teacherName?: string;
  acuityAppTypeIds?: {
    SAT?: TypeId;
    GMAT?: TypeId;
    IELTS?: TypeId;
  }
};

type TypeId = {
  halfHour: number,
  hour: number,
  hourAndAHalf: number,
  threeHours: number,
  twoHours: number,
  twoHoursAndAHalf: number,
};

export type Plan = {
  id?: number,
  sessionsCount?: number,
  teacherID?: number,
  typeID?: number,
  calendarID?: number,
  sessionID?: number,
  sessionNumber?: number;
  price?: number,
  course?: string,
  courseID?: number;
  classID?: number;
  isPayingCash?: boolean;
  isDraft?: boolean;
  session?: Session;
  sessionDate?: string
  duration?: string
};

export interface MainCalendarsSlice {
  filter: Filters;
  filtersModal: boolean;
  appointmentViewModal: boolean;
  selectedTimeSlots: TimeSLot[],
  plan: Plan,
}

const initialState: MainCalendarsSlice = {
  filter: {},
  filtersModal: false,
  appointmentViewModal: false,
  selectedTimeSlots: [],
  plan: {
    id: undefined,
    sessionsCount: undefined,
    teacherID: undefined,
    typeID: undefined,
    calendarID: undefined,
    sessionID: undefined,
    sessionNumber: undefined,
    price: undefined,
    course: 'SAT',
    courseID: 1,
    classID: undefined,
    isPayingCash: false,
    isDraft: false,
  }
};

const mainCalendar = createSlice({
  name: 'mainCalendar',
  initialState,
  reducers: {
    toggleFiltersModal: (state, { payload }: PayloadAction<boolean>) => {
      state.filtersModal = payload;
    },
    toggleAppointmentViewModal: (state, { payload }: PayloadAction<boolean>) => {
      state.appointmentViewModal = payload;
    },
    setFilter: (state, { payload }: PayloadAction<Filters>) => {
      state.filter = { ...state.filter, ...payload };
    },
    toggleTimeSlot: (state, { payload }: PayloadAction<TimeSLot>) => {
      const index = state.selectedTimeSlots.findIndex((item) => item.datetime === payload.datetime);
      if (index !== -1) {
        state.selectedTimeSlots.splice(index, 1);
      } else {
        state.selectedTimeSlots.push(payload);
      }
    },
    removeTimeSlot: (state, { payload }: PayloadAction<TimeSLot>) => {
      state.selectedTimeSlots = state.selectedTimeSlots.filter((item) => item.datetime !== payload.datetime);
    },
    setPlanData: (state, { payload }: PayloadAction<Plan>) => {
      state.plan = {
        ...state.plan,
        ...payload,
      };
    },
    resetTimeSlots: (state) => {
      state.selectedTimeSlots = [];
    }
  },
});

export default mainCalendar;
export const {
  setFilter,
  toggleFiltersModal,
  toggleTimeSlot,
  removeTimeSlot,
  resetTimeSlots,
  setPlanData,
  toggleAppointmentViewModal,
} = mainCalendar.actions;
