import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useAdminUpdateLeadStatus } from 'services/AdminDashboard/Leads/ChangeLeadStatus/useAdminUpdateLeadStatus';
import { LeadsSlice, setUpdateStatus } from '../../LeadsSlice';

export const useLogic = () => {
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useAdminUpdateLeadStatus();
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);
  const [status, setStatus] = useState<string>();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSaveStatus = () => {
    if (status) {
      updateStatus({ leadId: leadsState.status.leadId, status })
        .then(() => {
          queryClient.refetchQueries('leads-table-data');
          queryClient.refetchQueries('leads-statistics');
          queryClient.refetchQueries('lead-payment-info');
          dispatch(setUpdateStatus({ modal: false }));
        });
      setStatus('');
    }
  };

  return {
    updateStatus,
    isUpdatingStatus,
    leadsState,
    dispatch,
    status,
    setStatus,
    handleSaveStatus,
  };
};
