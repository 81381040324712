import axios from 'axios';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';

// SetOut Instance
let axiosSetOutInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});
export const axiosSetOut = () => axiosSetOutInstance;

// Stripe Instance
export const axiosStripe = axios.create({
  baseURL: 'https://api.stripe.com/v1/',
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`
  },
});

export const AxiosConfig = () => {
  const [cookies] = useCookies();
  const [doneSetup, setDoneSetup] = useState<boolean>(false);

  const setup = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    const newTokenInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    });
    newTokenInstance.interceptors.request.use(async (config) => {
      if (cookies.userinfo?.token && config.headers) { config.headers.Authorization = `Bearer ${cookies.userinfo?.token}`; }
      return (config);
    });
    newTokenInstance.interceptors.response.use((response) => response, (error) => {
      toast.error(error.response.data.message);
      return Promise.reject(error);
    });
    setDoneSetup(true);
    axiosSetOutInstance = newTokenInstance;
  };

  useEffect(() => {
    if (cookies.userinfo?.token) { setup(); }
  }, [cookies.userinfo?.token]);

  return { setup, doneSetup };
};
