// Components
import Button from 'components/General/Button/Button';
import { Google } from './Google/Google';
import { Apple } from './Apple/Apple';
import { Cash } from './Cash/Cash';
import { CreditCard } from './CreditCard/CreditCard';

// Images
import CreditImage from 'assets/images/credit-card.svg';
import CashImage from 'assets/images/dollar.svg';

// Redux
import onboarding, { OnboardingSlice } from 'pages/Onboarding/OnboardingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { usePaymentInfo } from 'services/Payment/Queries/PaymentInfo/usePaymentInfo';
import { useUserUseCoupon } from 'services/Payment/Mutations/useUserUseCoupon';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useUserRemoveCoupon } from 'services/Payment/Mutations/useUserRemoveCoupon';
import { useParams } from 'react-router';

export const useLogic = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { setChosePaymentMethod, setPlanData, setManualPaymentConfirmationModal } = onboarding.actions;
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const [paymentMethod, setPaymentMethod] = useState<string | undefined>(undefined);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [promoCode, setPromoCode] = useState<string>();
  const { data: paymentInfo, isLoading: isLoadingPaymentInfo } = usePaymentInfo(onboardingState.plan.classID);
  const { mutateAsync: useCoupon, isLoading: isCheckingCoupon } = useUserUseCoupon();
  const { mutateAsync: removeCoupon, isLoading: isLoadingRemoveCoupon } = useUserRemoveCoupon();
  const [confirmationModal, setConfirmationModal] = useState(false);

  const paymentMethods = [
    {
      id: 1,
      name: 'Cash',
      icon: CashImage,
      diabled: false
    },
    {
      id: 2,
      name: 'Credit Card',
      icon: CreditImage,
      diabled: false
    },
  ];

  const generatePaymentMethod = () => {
    try {
      if (onboardingState.chosePaymentMethod) {
        switch (paymentMethod) {
          case 'Cash':
            return <Cash />;
          case 'Google':
            return <Google />;
          case 'Credit Card':
            return <CreditCard />;
          case 'Apple':
            return <Apple />;
          default:
            return (
              <div>
                Something went wrong with payment
                <Button
                  color="primary"
                  onClick={() => dispatch(setChosePaymentMethod(false))}
                >
                  Back to payment
                </Button>
              </div>
            );
        }
      } else {
        return (
          <div>
            Something went wrong with payment
            <Button
              color="primary"
              onClick={() => dispatch(setChosePaymentMethod(false))}
            >
              Back to payment
            </Button>
          </div>
        );
      }
    } catch {
      console.error('error');
      return (
        <div>
          Something went wrong with payment
          <Button
            color="primary"
            onClick={() => dispatch(setChosePaymentMethod(false))}
          >
            Back to payment
          </Button>
        </div>
      );
    }
  };

  const checkCoupon = () => {
    useCoupon({ classId: onboardingState.plan.classID, coupon: promoCode })
      .then(() => {
        setInvalidCode(false);
        queryClient.refetchQueries('payment-info');
      })
      .catch((err) => {
        toast(err.response.errors?.[0].message);
        setInvalidCode(true);
      });
  };

  const handleRemoveCoupon = () => {
    removeCoupon({ classId: onboardingState.plan.classID }).then(() => {
      queryClient.refetchQueries('payment-info');
    });
  };

  return {
    onboardingState,
    paymentMethods,
    paymentMethod,
    setPaymentMethod,
    generatePaymentMethod,
    dispatch,
    setChosePaymentMethod,
    setPlanData,
    showCodeInput,
    setShowCodeInput,
    paymentInfo,
    isLoadingPaymentInfo,
    invalidCode,
    setInvalidCode,
    promoCode,
    setPromoCode,
    checkCoupon,
    isCheckingCoupon,
    handleRemoveCoupon,
    isLoadingRemoveCoupon,
    userId,
    confirmationModal,
    setConfirmationModal,
    setManualPaymentConfirmationModal
  };
};
