import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const usePostFamilyInfo = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ userId, step_id, guardain_email, guardain_name, guardain_number }: { userId: number, guardain_name: string; guardain_number: string; guardain_email: string; step_id: number; }) => client.request(
    gql`
    mutation FamilyInfo($userId: Float!, $guardainEmail: String!, $guardainName: String!, $guardainNumber: String!, $stepId: Float!) {
      familyInfo(userId: $userId, guardain_email: $guardainEmail, guardain_name: $guardainName, guardain_number: $guardainNumber, step_id: $stepId) {
        message
      }
    }
    `,
    {
      stepId: step_id,
      guardainNumber: guardain_number,
      guardainName: guardain_name,
      guardainEmail: guardain_email,
      userId
    }
  ));
  return Mutation;
};
