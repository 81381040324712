import moment from 'moment-timezone';
import { useAcuityAvailableDates, useAcuityAvailableTimes } from 'services/Onboarding/General/useOnboarding';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarState, setCalendarDate } from './RescheduleCalendar.slice';
import onboarding, { OnboardingSlice } from 'pages/Onboarding/OnboardingSlice';
import { SessionSlice, setRescheduleModal } from 'pages/Dashboard/Course/Session/SessionsSlice';
import { useAdminRescheduleSession } from 'services/Dashboard/Sessions/Mutations/AdminRescheduleSession/useAdminRescheduleSession';
import { useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { useRoles } from 'assets/utils/useRoles';
import toast from 'react-hot-toast';
import { useAdminGetTimeSlots } from 'services/Onboarding/AdminOnboarding/Queries/AdminGetAllAvailableTimeSlots/useAdminGetTimeSlots';

export const useLogic = () => {
  const { isAdmin } = useRoles();
  const dispatch = useDispatch();
  const [activeOpenCalendar, setActiveOpenCalendar] = useState(false);
  const [timeSlots, setTimeSlots] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { calendarDate, calendarMonth } = useSelector((state: { calendar: CalendarState }) => state.calendar);
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const sessionState = useSelector((state: { sessions: SessionSlice }) => state.sessions);
  const { mutateAsync: rescheduleSession, isLoading: isLoadingConfirmSchedule } = useAdminRescheduleSession();
  const { data: availableDates, isLoading: isLoadingInitialAvailableDates, isPreviousData: isLoadingAvailableDates } = useAcuityAvailableDates({ appointmentTypeID: onboardingState.plan.typeID, calendarID: onboardingState.plan.calendarID, month: moment(calendarMonth).format('YYYY-MM') || '', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const { data: availableTimes, isPreviousData: isLoadingAvailableTimes, isLoading } = useAcuityAvailableTimes({ appointmentTypeID: onboardingState.plan.typeID, calendarID: onboardingState.plan.calendarID, date: moment(calendarDate).format('YYYY-MM-DD'), timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const { data: teacherAllAvailableTimeSlots, isLoading: isLoadingAllTimeSlots } = useAdminGetTimeSlots({ month: moment(calendarDate).format('YYYY-MM-DD'), calendarID: onboardingState.plan.calendarID, enabled: activeOpenCalendar });

  type Slots = {
    time: string,
    duration: number
  }[] | undefined;

  const generateTimes = (diff: number) => {
    const length = 24 / diff;
    const timesArr = [];
    let add = 0;
    let time = moment().startOf('day');
    for (let i = 0; i < length; i++) {
      time = moment(moment(calendarDate).format('YYYY-MM-DD')).startOf('day').add(add, 'h');
      timesArr.push(time.toLocaleString());
      add += diff;
    }
    return timesArr;
  };

  const filterTimes = (slots: Slots) => {
    if (slots) {
      const dates = slots.map((item) => ({
        start: moment(item.time).subtract(1, 's').toDate(),
        end: moment(item.time).add(item.duration, 'h').toDate()
      }));
      const duration = onboardingState.plan.duration?.split(' ')[0] ?? '';
      const processedDuration = +duration > 3 ? +duration / 60 : +duration;
      const result = generateTimes(processedDuration).filter((item) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const date of dates) {
          if (
            moment(item).isBetween(moment(date.start), moment(date.end))
            || moment(moment(item).add(processedDuration, 'h')).isBetween(moment(date.start), moment(date.end))
          ) {
            return false;
          }
        }
        return true;
      });
      return result;
    }
    return [];
  };

  // Fixed a bug where user can choose multible time slots at the same day only the first time they open the calendar
  // The reason is that the calendar date is initially undefined
  useEffect(() => {
    if (activeOpenCalendar) {
      dispatch(setCalendarDate(moment(onboardingState.plan.sessionDate).toDate()));
    }
  }, [activeOpenCalendar]);

  useEffect(() => {
    if (activeOpenCalendar) {
      const availableSlots = teacherAllAvailableTimeSlots?.adminGetAppointmentsTimeSlots.filter((time) => moment(time.time).isSame(calendarDate, 'day'));
      setTimeSlots(filterTimes(availableSlots));
    } else {
      setTimeSlots(availableTimes?.data.map((item) => moment(item.time).toDate().toUTCString()) || []);
    }
  }, [availableTimes, teacherAllAvailableTimeSlots, activeOpenCalendar]);

  const handleConfirmSchedule = () => {
    rescheduleSession({
      datetime: onboardingState.selectedTimeSlots[0].datetime,
      sessionId: onboardingState.plan.sessionID
    }).then(() => {
      queryClient.refetchQueries('session-recordings');
      dispatch(setRescheduleModal(false));
      dispatch(onboarding.actions.resetTimeSlots());
    }).catch((err: any) => toast.error(err.response?.errors[0].message));
  };

  useEffect(() => {
    if (!sessionState.rescheduleModal) {
      dispatch(onboarding.actions.resetTimeSlots());
    }
  }, [sessionState.rescheduleModal]);

  return {
    availableDates,
    handleConfirmSchedule,
    isLoadingConfirmSchedule,
    dispatch,
    onboardingState,
    calendarDate,
    timeSlots,
    activeOpenCalendar,
    setActiveOpenCalendar,
    isLoadingAllTimeSlots,
    isLoadingInitialAvailableDates,
    isLoadingAvailableDates,
    isLoadingAvailableTimes,
    onboarding,
    isLoading,
    isAdmin
  };
};
