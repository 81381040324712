// Types

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { StudentClassesQuery } from './types';

export const useStudentClasses = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<StudentClassesQuery, Error> = useQuery<StudentClassesQuery, Error>(['studentCourses'], async () => client.request(
    gql`
    query UserClassView {
      userClassView{
        id
        classId
        isPaid
        status
        isAccessible
        sessions{
          teacher{
            user{
              first_name
              last_name
            }
          }
        }
        plan{
          course{
            name
          }
        }
      }
    }
    `
  ).catch((error) => console.error(error)));
  return data;
};
