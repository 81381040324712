// Types

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { useAuth } from 'routes/Auth/AuthProvider';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { AdminNearesAssignment } from './types';

export const useAdminNearestAssignment = (classId?: number) => {
  const { client } = graphqlConfig();
  const user = useAuth();

  const data: UseQueryResult<AdminNearesAssignment, Error> = useQuery<AdminNearesAssignment, Error>(['adminNearestAssignment'], async () => client.request(
    gql`
    query AdminNearestAss($classId: Float!) {
      adminNearestAss(classId: $classId) {
        id
        name
        fullMark
        receivedMark
        classId
        details
        status
        dueDate
        ungraded
        attachments {
          id
          attachment
          teacherAttachment
        }
      }
    }
    `,
    {
      classId: classId || undefined
    }
  ).catch((error) => console.error(error)), { enabled: !!(user.user?.user.is_teacher || user.user?.user.is_admin) });
  return data;
};
