import Button from 'components/General/Button/Button';
import { MainCalendarsSlice, setFilter, toggleFiltersModal } from '../../CalendarSlice';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './TeacherFilter.scss';
import SelectBox from 'components/General/SelectBox/SelectBox';
import { useAdminCalendar } from 'services/AdminDashboard/Calendar/useAdminCalendar';

export const TeacherFilter = () => {
  const dispatch = useDispatch();
  const mainCalendarState = useSelector((state: { mainCalendar: MainCalendarsSlice }) => state.mainCalendar);
  const { data: calendarData, isFetching: isLoading } = useAdminCalendar({ ...mainCalendarState.filter, month: new Date() });
  const [value, setValue] = useState<number[]>([]);

  const handleApplyFilters = () => {
    dispatch(setFilter({ teachersIds: value }));
    dispatch(toggleFiltersModal(false));
  };

  return (
    <div className="search-filter-container">
      <h2>Select teacher</h2>
      <SelectBox
        isMulti
        options={calendarData?.adminCalendar.teachers?.map((teacher) => ({
          value: teacher.id,
          label: teacher.text,
        }))}
        isLoading={isLoading}
        onChange={(e: any) => {
          setValue(e?.map((item: any) => item.value));
        }}
      />
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
