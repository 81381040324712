import { DashboardSlice, setShowTabBar, setFeedbackData } from 'pages/Dashboard/DashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTeacherClasses } from 'services/Dashboard/Classes/TeacherClasses/useTeacherClasses';
import ClassFilters from './Filters/ClassFilter/ClassFilter';
import { LocationFilter } from './Filters/CountryFilter/LocationFilter';

export const useLogic = () => {
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const { data: teacherClassesQuery, isLoading, isFetching } = useTeacherClasses(dashboardState.filter);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCourseChange = (className: string, classID: number, id: number) => {
    dispatch(setShowTabBar(false));
    navigate(`/class/${id}/${classID}/${className}/stream`);
  };

  const generateFilter = (item?: string) => {
    switch (item) {
      case 'Subject':
        return <ClassFilters />;
      case 'Location':
        return <LocationFilter />;

      default:
        return <></>;
    }
  };

  return {
    teacherClassesQuery,
    isLoading,
    isFetching,
    handleCourseChange,
    dashboardState,
    dispatch,
    generateFilter,
    setFeedbackData
  };
};
