// Services
import { postPersonalInfo } from 'services/Onboarding/General/useOnboarding';
import { useQueryClient } from 'react-query';

// Types
import { PersonalInfoForm } from './PersonalInfo.types';

// Redux
import onboarding, { OnboardingSlice } from '../../OnboardingSlice';
import { useDispatch, useSelector } from 'react-redux';

// React hook form
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useParams } from 'react-router';
import { usePostPersonalInfo } from 'services/Onboarding/AdminOnboarding/Mutations/usePostPersonalInfo';
import toast from 'react-hot-toast';
import { getCountryCode } from 'assets/utils/getCountryCode';

export const useLogic = () => {
  const { userId } = useParams();
  const { mutateAsync: adminPostPersonalInfo } = usePostPersonalInfo();
  const { nextPage, updateUserInfo, previousPage } = onboarding.actions;
  const dispatch = useDispatch();
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const queryClient = useQueryClient();
  const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm<PersonalInfoForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: {},
      birthday: undefined
    }
  });
  const [phoneError, setPhoneError] = useState(false);
  const formatDate = (birthday: any) => {
    const date = birthday.getDate();
    const month = birthday.getMonth() + 1;
    const year = birthday.getFullYear();
    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}`;
  };

  const onSubmit = handleSubmit(async (data) => {
    if (errors.firstName?.message || errors.lastName?.message || errors.birthday?.message) return;
    if (data.phoneNumber.phone === '') {
      setPhoneError(true);
      return;
    }
    if (!data.phoneNumber.isoCode) {
      setPhoneError(true);
      toast.error('Please select country code');
      return;
    }
    dispatch(nextPage());
    if (userId) {
      await adminPostPersonalInfo({
        userId: Number(userId),
        first_name: data.firstName,
        last_name: data.lastName,
        birthday: formatDate(data.birthday),
        phoneNumber: `${data.phoneNumber.countryCode}-${data.phoneNumber.phone}`,
        step_id: onboardingState.activeStep
      });
    } else {
      await postPersonalInfo({
        first_name: data.firstName,
        last_name: data.lastName,
        birthday: formatDate(data.birthday),
        phoneNumber: `${data.phoneNumber.countryCode}-${data.phoneNumber.phone}`,
        step_id: onboardingState.activeStep
      });
    }
    dispatch(updateUserInfo({
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: `${data.phoneNumber.countryCode}-${data.phoneNumber.phone}`,
      birthday: moment(data.birthday).toString(),
    }));
    queryClient.refetchQueries(['userinfo']);
  });

  useEffect(() => {
    if (onboardingState.userInfo) {
      setValue('firstName', onboardingState.userInfo.firstName || '');
      setValue('lastName', onboardingState.userInfo.lastName || '');
      setValue('birthday', onboardingState.userInfo.birthday ? new Date(onboardingState.userInfo.birthday) : undefined);
      if (onboardingState.userInfo.phoneNumber?.includes('-')) {
        setValue('phoneNumber.phone', onboardingState.userInfo.phoneNumber?.split('-')[1] || onboardingState.userInfo.phoneNumber || '');
        setValue('phoneNumber.countryCode', onboardingState.userInfo.phoneNumber?.split('-')[0] || '');
        setValue('phoneNumber.isoCode', getCountryCode(onboardingState.userInfo.phoneNumber?.split('-')[0] || ''));
      } else {
        setValue('phoneNumber.phone', onboardingState.userInfo.phoneNumber || '');
        setValue('phoneNumber.isoCode', 'lb');
      }
    }
  }, [onboardingState.userInfo]);

  return {
    onSubmit,
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
    phoneError,
    dispatch,
    previousPage
  };
};
