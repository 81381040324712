import moment from 'moment-timezone';
import { useAcuityAvailableDates, useAcuityAvailableTimes, useAcuityFirstAvailableMonth } from 'services/Onboarding/General/useOnboarding';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarState, setCalendarDate, setCalendarMonth } from './Calendar.slice';
import { useEffect, useState } from 'react';
import onboarding, { OnboardingSlice } from 'pages/Onboarding/OnboardingSlice';
import { useComplaint } from 'services/Onboarding/Complaints/useComplaint';
import { useParams } from 'react-router';
import { useRoles } from 'assets/utils/useRoles';
import { useAdminGetTimeSlots } from 'services/Onboarding/AdminOnboarding/Queries/AdminGetAllAvailableTimeSlots/useAdminGetTimeSlots';

export const useLogic = () => {
  const { isAdmin } = useRoles();
  const { userId, className } = useParams();
  const dispatch = useDispatch();
  const [activeOpenCalendar, setActiveOpenCalendar] = useState(false);
  const [timeSlots, setTimeSlots] = useState<string[]>([]);
  const { acuityTypeID, acuityCalendarID, calendarDate, calendarMonth } = useSelector((state: { calendar: CalendarState }) => state.calendar);
  const { data: firstAvailableMonth, isLoading: isLoadingInitialFirstAvailableMonth, isPreviousData: isLoadingFirstAvailableMonth } = useAcuityFirstAvailableMonth({ appointmentTypeID: acuityTypeID, calendarID: acuityCalendarID, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const { data: availableDates, isLoading: isLoadingInitialAvailableDates, isPreviousData: isLoadingAvailableDates } = useAcuityAvailableDates({ appointmentTypeID: acuityTypeID, calendarID: acuityCalendarID, month: moment(calendarMonth).format('YYYY-MM') || '', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const { data: teacherAllAvailableTimeSlots, isLoading: isLoadingAllTimeSlots } = useAdminGetTimeSlots({ month: moment(calendarDate).format('YYYY-MM-DD'), calendarID: acuityCalendarID, enabled: activeOpenCalendar });
  const { data: availableTimes, isFetching: isLoadingAvailableTimes, isLoading } = useAcuityAvailableTimes({ appointmentTypeID: acuityTypeID, calendarID: acuityCalendarID, date: calendarDate ? moment(calendarDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'), timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const { mutateAsync: sendComplaint } = useComplaint();
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const [infoMessage, setInfoMessage] = useState(false);
  const [calendarView, setCalendarView] = useState<string>('month');
  const { setComplaintModal, setShowConfirmation, setSelectedTeacher } = onboarding.actions;
  type Durations = { time: string, length: number } | undefined;
  const durations: Durations[] = [
    { time: '30 minutes', length: 0.5 },
    { time: '1 hour', length: 1 },
    { time: '1.5 hour', length: 1.5 },
    { time: '2 hours', length: 2 },
    { time: '2.5 hour', length: 2.5 },
    { time: '3 hours', length: 3 },
  ];
  const appointmentTypeIds: any = {
    IELTS: [
      37361247,
      32390757,
      37361257,
      37361273,
      37361913,
      37361924
    ],
    SAT: [
      37360737,
      32366529,
      37361009,
      37361035,
      37362044,
      37362059
    ],
    GMAT: [
      37361235,
      32390362,
      37361094,
      37361116,
      37361991,
      37362009
    ]
  };
  const [duration, setDuration] = useState('2 hours');

  type Slots = {
    time: string,
    duration: number
  }[] | undefined;

  const generateTimes = (diff: number) => {
    const length = 24 / diff;
    const timesArr = [];
    let add = 0;
    let time = moment().startOf('day');
    for (let i = 0; i < length; i++) {
      time = moment(moment(calendarDate).format('YYYY-MM-DD')).startOf('day').add(add, 'h');
      timesArr.push(time.toLocaleString());
      add += diff;
    }
    return timesArr;
  };

  const filterTimes = (slots: Slots) => {
    if (slots) {
      const dates = slots.map((item) => ({
        start: moment(item.time).subtract(1, 's').toDate(),
        end: moment(item.time).add(item.duration, 'h').toDate()
      }));
      const durationn = duration?.split(' ')[0] ?? '';
      const processedDuration = +durationn > 3 ? +durationn / 60 : +durationn;
      const result = generateTimes(processedDuration).filter((item) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const date of dates) {
          if (
            moment(item).isBetween(moment(date.start), moment(date.end))
            || moment(moment(item).add(processedDuration, 'h')).isBetween(moment(date.start), moment(date.end))
          ) {
            return false;
          }
        }
        return true;
      });
      return result;
    }
    return [];
  };

  // Fixed a bug where user can choose multible time slots at the same day only the first time they open the calendar
  // The reason is that the calendar date is initially undefined
  useEffect(() => {
    if (activeOpenCalendar) {
      dispatch(setCalendarDate(moment(onboardingState.plan.sessionDate).toDate()));
    }
  }, [activeOpenCalendar]);

  useEffect(() => {
    if (activeOpenCalendar) {
      const availableSlots = teacherAllAvailableTimeSlots?.adminGetAppointmentsTimeSlots.filter((time) => moment(time.time).isSame(calendarDate, 'day'));
      setTimeSlots(filterTimes(availableSlots));
    } else {
      setTimeSlots(availableTimes?.data.map((item) => moment(item.time).toDate().toUTCString()) || []);
    }
  }, [availableTimes, teacherAllAvailableTimeSlots, activeOpenCalendar, duration]);
  // Fixed a bug where user can choose multible time slots at the same day only the first time they open the calendar
  // The reason is that the calendar date is initially undefined
  useEffect(() => {
    dispatch(setCalendarDate(firstAvailableMonth?.data ? new Date(moment(firstAvailableMonth?.data).toDate().toLocaleDateString()) : new Date()));
    if (firstAvailableMonth?.data) {
      dispatch(setCalendarMonth(new Date(moment(firstAvailableMonth?.data).toDate().toLocaleDateString()).toDateString()));
    }
  }, [firstAvailableMonth, activeOpenCalendar]);

  const handleComplaint = () => {
    if (onboardingState.plan.courseID) {
      sendComplaint(onboardingState.plan.courseID);
    }
  };

  const getTimeSlotDuration = (slot: string) => {
    const temp = moment(slot).format('HH:mm');
    const temp2 = moment(slot).add(duration.split(' ')[0], 'hour').format('HH:mm');
    return moment.utc(moment(temp2, 'HH:mm:ss').diff(moment(temp, 'HH:mm:ss'))).format('HH:mm');
  };

  useEffect(() => {
    dispatch(onboarding.actions.setTotalTime(onboardingState.selectedTimeSlots.reduce((prev, curr) => prev + Number(curr.length), 0)));
  }, [onboardingState.selectedTimeSlots]);

  return {
    availableDates,
    isLoadingInitialAvailableDates,
    isLoadingAvailableDates,
    availableTimes,
    isLoadingAvailableTimes,
    infoMessage,
    setInfoMessage,
    onboardingState,
    dispatch,
    acuityCalendarID,
    acuityTypeID,
    calendarDate,
    firstAvailableMonth,
    isLoadingInitialFirstAvailableMonth,
    isLoadingFirstAvailableMonth,
    calendarView,
    setCalendarView,
    handleComplaint,
    setComplaintModal,
    userId,
    isLoadingAllTimeSlots,
    activeOpenCalendar,
    setActiveOpenCalendar,
    timeSlots,
    isLoading,
    setShowConfirmation,
    setSelectedTeacher,
    durations,
    duration,
    setDuration,
    getTimeSlotDuration,
    isAdmin,
    appointmentTypeIds,
    className
  };
};
