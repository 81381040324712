// Types
import { AssignmentCard as IProps } from './AssignmentCard.types';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes as ExitIcon,
  faDownload as DownloadIcon,
  faEllipsisV as OptionsIcon,
  faPencilAlt as EditIcon,
  faTrash as DeleteIcon } from '@fortawesome/free-solid-svg-icons';
import DocumentIcon from 'assets/images/document-icon.svg';

// Styling
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import './AssignmentCard.scss';
import Button from 'components/General/Button/Button';
import { v4 as uuid } from 'uuid';
import { useLogic } from './AssignmentCard.logic';
import moment from 'moment-timezone';

// Progressbar
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';

export const AssignmentCard = ({ id, classId, title, status, date, body, attachments, fullMark, receivedMark, ungraded, onEdit, onRemove, toggleModal }: IProps) => {
  const {
    showAssignmentBody,
    setShowAssignmentBody,
    showOptions,
    setShowOptions,
    files,
    input,
    handleRemoveImage,
    isAdmin,
    isTeacher,
    handleUpload,
    handleSubmit,
    isLoading,
    progress,
    downlowadRef,
    isImage,
    submitted,
    setSubmitted,
    isLoadingSubmitAssignment,
    handleAllowResubmit,
    isLoadingUpdateAssignment,
    handleDone
  } = useLogic();

  return (
    <div className="assignment-card-container">
      <div className="top-container">
        <div className="left-container">
          <div className="document-icon">
            <img
              src={DocumentIcon}
              alt=""
            />
          </div>
          <h2>{title}</h2>
        </div>
        <div className="right-container">
          {ungraded && (
          <span className="fuchsia">Ungraded</span>
          )}
          {(receivedMark || receivedMark === 0) ? (
            <span className="status">{`${receivedMark} / ${fullMark}`}</span>
          ) : (
            <span className={`${(status === 'Waiting to be graded' && 'green') || (status === 'Overdue' && 'fuchsia') || (status === 'Active' && 'mustard')}`}>{status}</span>
          )}
          {((isAdmin || isTeacher) && (status === 'Active')) && (
            <>
              <FontAwesomeIcon
                icon={OptionsIcon}
                className="icon"
                color="#97ACCC"
                display={12}
                onClick={() => setShowOptions(!showOptions)}
              />
              <div className={showOptions ? 'show-tooltip' : 'hide-tooltip'}>
                <div className="tooltiptext">
                  <ButtonBase
                    tagName="div"
                    onClick={onEdit}
                  >
                    <div className="edit">
                      <FontAwesomeIcon
                        icon={EditIcon}
                        className="icon"
                        color="#97ACCC"
                        display={12}
                      />
                      <p>Edit</p>
                    </div>
                  </ButtonBase>
                  <ButtonBase
                    tagName="div"
                    onClick={onRemove}
                  >
                    <div className="delete">
                      <FontAwesomeIcon
                        icon={DeleteIcon}
                        className="icon"
                        color="#97ACCC"
                        display={12}
                      />
                      <p>Delete</p>
                    </div>
                  </ButtonBase>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      { !showAssignmentBody ? (
        <div className="bottom-container">
          <div className="date-container">
            <h3>{`Due date: ${moment(date).format('DD/MM/YYYY')}`}</h3>
          </div>
          <ButtonBase
            tagName="div"
            onClick={() => setShowAssignmentBody(!showAssignmentBody)}
          >
            <div className="button-container">
              <h3>{`${showAssignmentBody ? 'See less' : 'See more'}`}</h3>
            </div>
          </ButtonBase>
        </div>
      ) : (
        <>
          <div className="bottom-container">
            <div className="date-container">
              <h3>{`Due date: ${moment(date).format('DD/MM/YYYY')}`}</h3>
            </div>
            <ButtonBase
              tagName="div"
              onClick={() => setShowAssignmentBody(!showAssignmentBody)}
            >
              <div className="button-container">
                <h3>{`${showAssignmentBody ? 'See less' : 'See more'}`}</h3>
              </div>
            </ButtonBase>
          </div>
          <div className="assignment-body-container">
            <div dangerouslySetInnerHTML={{ __html: String(body) }} />
          </div>
          {(isAdmin || isTeacher) ? (
            <>
              <div className="teacher-block-uploads-container">
                <div className="teacher-uploads-container">
                  <div className="header-container">
                    <h3>{attachments && attachments.filter((attachment) => attachment.teacherAttachment === true).length > 0 ? 'Instructor\'s uploaded files.' : 'No uploaded files'}</h3>
                    {ungraded ? (
                      <div className="button-container">
                        <Button
                          color="primary"
                          onClick={() => handleDone(id, classId)}
                          isLoading={isLoadingUpdateAssignment}
                        >
                          Done
                        </Button>
                      </div>
                    ) : (
                      <>
                        {status !== 'Active' && (
                        <div className="button-container">
                          <Button
                            color="primary"
                            onClick={toggleModal}
                          >
                            Give mark
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => handleAllowResubmit(id, classId)}
                            isLoading={isLoadingUpdateAssignment}
                          >
                            Allow resubmit
                          </Button>
                        </div>
                        )}
                      </>
                    )}
                  </div>
                  <>
                    {attachments && attachments.filter((attachment) => attachment.teacherAttachment === true).length > 0 && (
                    <div className="images-container">
                      {attachments.filter((attachment) => attachment.teacherAttachment === true).map((attachment) => (
                        <div
                          className="image-container"
                          key={uuid()}
                        >
                          <FontAwesomeIcon
                            icon={DownloadIcon}
                            className="icon"
                            color="#97ACCC"
                            display={12}
                            onClick={(e) => {
                              e.stopPropagation();
                              downlowadRef.current?.click();
                            }}
                          />
                          <ButtonBase
                            tagName="div"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(attachment.attachment, '_blank');
                            }}
                          >
                            <a
                              download
                              ref={downlowadRef}
                            >
                              {isImage(attachment.attachment) ? (
                                <img
                                  src={attachment.attachment}
                                  alt=""
                                />
                              ) : (
                                <div className="document-icon">
                                  <img
                                    src={DocumentIcon}
                                    alt=""
                                  />
                                </div>
                              )}
                            </a>
                          </ButtonBase>
                        </div>
                      ))}
                    </div>
                    )}
                  </>
                </div>

                {attachments && attachments.filter((attachment) => attachment.teacherAttachment === false).length > 0 && (
                  <div className="student-uploads-container">
                    <h3>Students uploaded files</h3>
                    <div className="images-container">
                      {attachments.filter((attachment) => attachment.teacherAttachment === false).map((attachment) => (
                        <div
                          className="image-container"
                          key={uuid()}
                        >
                          <FontAwesomeIcon
                            icon={DownloadIcon}
                            className="icon"
                            color="#97ACCC"
                            display={12}
                            onClick={(e) => {
                              e.stopPropagation();
                              downlowadRef.current?.click();
                            }}
                          />
                          <ButtonBase
                            tagName="div"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(attachment.attachment, '_blank');
                            }}
                          >
                            <a
                              download
                              ref={downlowadRef}
                            >
                              {isImage(attachment.attachment) ? (
                                <img
                                  src={attachment.attachment}
                                  alt=""
                                />
                              ) : (
                                <div className="document-icon">
                                  <img
                                    src={DocumentIcon}
                                    alt=""
                                  />
                                </div>
                              )}
                            </a>
                          </ButtonBase>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="uploads-container">
                {attachments && attachments.filter((attachment) => attachment.teacherAttachment === true).length > 0 && (
                <div className="teacher-uploads-container">
                  <h3>Instructor&rsquo;s uploaded files.</h3>
                  <div className="images-container">
                    {attachments.filter((attachment) => attachment.teacherAttachment === true).map((attachment) => (
                      <div
                        className="image-container"
                        key={uuid()}
                      >
                        <FontAwesomeIcon
                          icon={DownloadIcon}
                          className="icon"
                          color="#97ACCC"
                          display={12}
                          onClick={(e) => {
                            e.stopPropagation();
                            downlowadRef.current?.click();
                          }}
                        />
                        <ButtonBase
                          tagName="div"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(attachment.attachment, '_blank');
                          }}
                        >
                          <a
                            download
                            ref={downlowadRef}
                          >
                            {isImage(attachment.attachment) ? (
                              <img
                                src={attachment.attachment}
                                alt=""
                              />
                            ) : (
                              <div className="document-icon">
                                <img
                                  src={DocumentIcon}
                                  alt=""
                                />
                              </div>
                            )}
                          </a>
                        </ButtonBase>
                      </div>
                    ))}
                  </div>
                </div>
                )}
                {attachments && attachments.filter((attachment) => attachment.teacherAttachment === false).length > 0 && (
                <div className="student-uploads-container">
                  <h3>Student&rsquo;s submissions</h3>
                  <div className="images-container">
                    {attachments.filter((attachment) => attachment.teacherAttachment === false).map((attachment) => (
                      <div
                        className="image-container"
                        key={uuid()}
                      >
                        <FontAwesomeIcon
                          icon={DownloadIcon}
                          className="icon"
                          color="#97ACCC"
                          display={12}
                          onClick={(e) => {
                            e.stopPropagation();
                            downlowadRef.current?.click();
                          }}
                        />
                        <ButtonBase
                          tagName="div"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(attachment.attachment, '_blank');
                          }}
                        >
                          <a
                            download
                            ref={downlowadRef}
                          >
                            {isImage(attachment.attachment) ? (
                              <img
                                src={attachment.attachment}
                                alt=""
                              />
                            ) : (
                              <div className="document-icon">
                                <img
                                  src={DocumentIcon}
                                  alt=""
                                />
                              </div>
                            )}
                          </a>
                        </ButtonBase>
                      </div>
                    ))}
                  </div>
                </div>
                )}
                {(status === 'Active' || status === 'Waiting for resubmission') && (
                  <>
                    <div className="aleardy-submitted-container">
                      <h3>
                        I already sent my answers
                      </h3>
                      <ToggleSwitch
                        checked={submitted}
                        onChange={() => setSubmitted((prev) => !prev)}
                      />
                    </div>
                    <div className="new-uploads-container">
                      <div className="top">
                        <h3>{`${files.length > 0 ? 'new uploaded files' : 'upload files'}`}</h3>
                        <div className="button-container">
                          {isLoading && (
                          <div
                            style={{ width: 40, height: 40 }}
                            className="progress=bar"
                          >
                            <CircularProgressbar
                              value={progress}
                              styles={{ path: { stroke: '#6371E0' }, text: { fill: '#6371E0', fontSize: 30 } }}
                              text={String(progress)}
                            />
                          </div>
                          )}
                          <div>
                            <Button
                              color="primary"
                              onClick={() => input.current?.click()}
                            // FIXME shouldn't be disabled while uploading
                              disabled={isLoading}
                            >
                              Upload
                            </Button>
                          </div>
                          <input
                            ref={input}
                            type="file"
                            name="filetobase64"
                            multiple
                            accept="image/*, .pdf"
                            onChange={handleUpload}
                          />
                        </div>
                      </div>
                      {files.length > 0 && (
                      <div className="images-container">
                        {files.map((file) => (
                          <div
                            className="image-container"
                            key={uuid()}
                          >
                            <FontAwesomeIcon
                              icon={ExitIcon}
                              className="icon"
                              color="#97ACCC"
                              display={12}
                              onClick={() => handleRemoveImage(files.indexOf(file))}
                            />
                            {file.type.includes('image') ? (
                              <img
                                src={`data:image/jpeg;base64, ${file.base64}`}
                                alt=""
                              />
                            ) : (
                              <div className="document-icon">
                                <img
                                  src={DocumentIcon}
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {(status === 'Active' || status === 'Waiting for resubmission') && (
                <div className="submit-container">
                  <Button
                    color="primary"
                    onClick={() => handleSubmit(id)}
                    // FIXME shouldn't be disabled while uploading
                    disabled={isLoading || (!submitted && files.length === 0)}
                    isLoading={isLoadingSubmitAssignment}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
