import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssignmentSliceType } from './Assignments.types';

export type Attachments = {
  id?: number,
  attachmentsIds: number[]
  alreadySubmitted?: boolean
};

export type Mark = {
  id?: number;
  mark?: number;
  originalMark?: number;
  toggleModal?: boolean
};

export interface AssignmentSlice {
  showCourse: boolean;
  showTabBar: boolean;
  reuseAssignment: AssignmentSliceType;
  mark: Mark;
  attachments: Attachments;
  editAssignmentId?: number,
  showNewAssignmentModal: boolean
  showReuseAssignmentModal: boolean
}

const initialState: AssignmentSlice = {
  showCourse: false,
  showTabBar: true,
  reuseAssignment: {},
  mark: { toggleModal: false },
  attachments: {
    attachmentsIds: []
  },
  editAssignmentId: undefined,
  showNewAssignmentModal: false,
  showReuseAssignmentModal: false
};

const assignment = createSlice({
  name: 'assignment',
  initialState,
  reducers: {
    setShowCourse: (state) => {
      state.showCourse = !state.showCourse;
    },
    setReuseAssignment: (state, { payload }: PayloadAction<AssignmentSliceType>) => {
      state.reuseAssignment = payload;
    },
    setMark: (state, { payload }: PayloadAction<Mark>) => {
      state.mark = payload;
    },
    setAttachments: (state, { payload }: PayloadAction<Attachments>) => {
      state.attachments.attachmentsIds = [...state.attachments.attachmentsIds, ...payload.attachmentsIds];
    },
    setEditAssignmentId: (state, { payload }: PayloadAction<number | undefined>) => {
      state.editAssignmentId = payload;
    },
    setToggleNewAssignmentModal: (state) => {
      state.showNewAssignmentModal = !state.showNewAssignmentModal;
    },
    setToggleReuseAssignmentModal: (state) => {
      state.showReuseAssignmentModal = !state.showReuseAssignmentModal;
    },
  },
});

export default assignment;
export const {
  setReuseAssignment,
  setShowCourse,
  setMark,
  setAttachments,
  setEditAssignmentId,
  setToggleNewAssignmentModal,
  setToggleReuseAssignmentModal
} = assignment.actions;
