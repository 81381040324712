import { useStudentsStatistics } from 'services/Teacher/Classes/Queries/StudentsStatistics/useStudentsStatistics';

export const useLogic = () => {
  const { data: statistics, isLoading } = useStudentsStatistics();

  return {
    statistics,
    isLoading
  };
};
