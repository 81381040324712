import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateTeacherStatus } from 'services/AdminPanel/Teachers/Mutations/useUpdateTeacherStatus';
import { TeachersSlice, setTeacherStatus } from '../../TeachersSlice';

export const useLogic = () => {
  const teachersState = useSelector((state: { teachers: TeachersSlice }) => state.teachers);
  const { mutateAsync: updateActivity, isLoading } = useUpdateTeacherStatus();
  const [active, setActive] = useState<boolean>();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSave = () => {
    updateActivity({ userId: teachersState.status.userId, status: active ? 'Active' : 'Inactive' }).then(() => {
      queryClient.refetchQueries('teachers-data');
      dispatch(setTeacherStatus({ modal: false }));
      setActive(undefined);
    });
  };

  useEffect(() => {
    if (!teachersState.status.modal) {
      setActive(undefined);
      dispatch(setTeacherStatus({ userId: undefined }));
    }
  }, [teachersState.status.modal]);

  return {
    dispatch,
    active,
    setActive,
    handleSave,
    isLoading
  };
};
