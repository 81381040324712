import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Filters = {
  courses?: string[],
  userStatus?: string[],
  country?: string[],
  search?: string,
  selectedFilter?: string
};

export interface StudentsSlice {
  filter: Filters;
  filtersModal: boolean;
}
const initialState: StudentsSlice = {
  filter: {},
  filtersModal: false,
};

const teacherStudents = createSlice({
  name: 'teacherStudents',
  initialState,
  reducers: {
    toggleFiltersModal: (state, { payload }: PayloadAction<boolean>) => {
      state.filtersModal = payload;
    },
    setFilter: (state, { payload }: PayloadAction<Filters>) => {
      state.filter = { ...state.filter, ...payload };
    },
  },
});

export default teacherStudents;
export const {
  setFilter,
  toggleFiltersModal,
} = teacherStudents.actions;
