import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SessionSlice } from '../SessionsSlice';

export const useLogic = () => {
  const [openStudent, setOpenStudent] = useState(false);
  const [openTeacher, setOpenTeacher] = useState(false);
  const sessionProblemState = useSelector((state: { sessions: SessionSlice }) => state.sessions.sessionProblem);

  useEffect(() => {
    if (!sessionProblemState.modal) {
      setOpenStudent(false);
      setOpenTeacher(false);
    }
  }, [sessionProblemState.modal]);

  const handleTime = (ms: number) => {
    let seconds = Math.floor(ms / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds %= 60;
    minutes = seconds >= 30 ? minutes + 1 : minutes;
    minutes %= 60;
    hours %= 24;

    if (hours >= 1) {
      return `${hours}h ${minutes}m`;
    }
    return `${minutes}min`;
  };

  return {
    openStudent,
    setOpenStudent,
    openTeacher,
    setOpenTeacher,
    sessionProblemState,
    handleTime
  };
};
