// Types
import { CallLogs } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useCallLogs = (leadId: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<CallLogs, Error> = useQuery<CallLogs, Error>(['call-logs', leadId], async () => client.request(
    gql`
    query AdminGetCallLogs($leadId: Float!) {
      adminGetCallLogs(leadId: $leadId) {
        id
        createdById
        leadId
        contacted
        note
        futureCall
        updatedAt
        createdAt
        createdBy {
          first_name
          last_name
        }
      }
    }
    `,
    {
      leadId
    }
  ).catch((error) => console.error(error)));
  return data;
};
