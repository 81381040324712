import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useSetFinalTest = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ classId, examDate, targetScore }: { classId: number, examDate?: Date, targetScore?: number }) => client.request(
    gql`
    mutation userCompleteClassInfo($classId: Float!, $examDate: DateTime, $targetScore: Float){
      userCompleteClassInfo(classId: $classId, examDate: $examDate, targetScore: $targetScore) {
        message
      }
    }   
    `,
    {
      classId,
      examDate,
      targetScore
    }
  ));
  return Mutation;
};
