import { useState } from 'react';

// Cookies
import { useCookies } from 'react-cookie';

// Navigation
import { useNavigate } from 'react-router';

// Routes
import { HOME } from 'routes/CONSTANTS';

// Layouts
import AuthenticationLayout from 'layouts/AuthenticationLayout/AuthenticationLayout';

// Stylesheet
import './SignupVerification.scss';

// imports
import VerificationInput from 'react-verification-input';
import Button from 'components/General/Button/Button';

// Context
import { useAuth } from 'routes/Auth/AuthProvider';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';

const SignupVerification = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [cookie] = useCookies(['email']);
  const [authError, setAuthError] = useState<string>();
  const { verifySignup, resendCode, isAuthenticating } = useAuth();

  const handleSubmit = () => {
    if (code.length < 5) return;
    const query = { email: cookie.email, code };
    verifySignup(query).then(() => {
      navigate(HOME);
    })
      .catch((err: AxiosError) => {
        setAuthError(err.response?.data?.message);
      });
  };

  const handleResend = () => {
    const query = { email: cookie.email };
    resendCode(query);
  };

  return (
    <AuthenticationLayout>
      <div className="Verification-container">
        <h1 className="title">Verification Code</h1>
        <h3>
          Enter The 5-Digit Code Sent To You At
          {' '}
          <br />
          <span className="email">{cookie.email}</span>
        </h3>
        <div className="inputs">
          <VerificationInput
            onChange={(e) => setCode(e)}
            length={5}
            autoFocus
            placeholder=""
            removeDefaultStyles
            classNames={{
              container: 'verification-container',
              character: `character ${authError ? 'code-error' : ''}`,
              characterInactive: 'character--inactive',
              characterSelected: 'character--selected',
            }}
          />
          <div className="code-error">
            {authError && <h4>{authError}</h4>}
          </div>
          <h4>
            Didnt Receive Code?
            {' '}
            <span>
              <Link
                className="link"
                onClick={handleResend}
                to=""
              >
                Resend
              </Link>
            </span>
          </h4>
          <Button
            color="primary"
            id="button"
            onClick={handleSubmit}
            isLoading={isAuthenticating}
          >
            Continue
          </Button>
        </div>

      </div>
    </AuthenticationLayout>
  );
};
export default SignupVerification;
