import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { io } from 'socket.io-client';

export const useReactQueryNotificationSubscription = (id: number) => {
  const queryClient = useQueryClient();
  const socket = io(String(process.env.REACT_APP_API_URL));

  useEffect(() => {
    socket.on(`connectionUser${id}`, () => {
    });
    socket.on(`connectionUser${id}`, (data) => {
      switch (data.action) {
        case 'Notification':
          queryClient.refetchQueries('userNotification');
          break;

          // Sessions
        case 'Session_in_30_min':
          queryClient.resetQueries('userNearestSession');
          break;
        default: console.log('error');
      }
    });

    return () => {
      socket.close();
    };
  }, [queryClient]);
};

// export enum SocketActions {
//   NEW_POST = "New_post",
//   EDIT_POST = "Edit_post",
//   DELETE_POST = "Delete_post",
//   NEW_COMMENT = "New_comment",
//   DELETE_COMMENT = "Delete_comment",
//   DELETE_ASSIGNMENT = "Delete_assignment",
//   DELETE_TEST = "Delete_test",
//   NEW_ASSIGNMENT = "New_assignment",
//   EDIT_ASSIGNMENT = "Edit_assignment",
//   NEW_TEST = "New_test",
//   EDIT_TEST = "Edit_test",
//   NOTIFICATION = "Notification",
// }
