// Styling
import './Students.scss';

// Icons

// Logic
import { useLogic } from './Students.logic';
import Modal from 'components/General/Modal/Modal';
import Typography from 'components/General/Typography/Typography';
import Table from 'components/Dashboard/Table/Table';
import Label from 'components/General/Label/Label';
import Avatar from 'components/General/Avatar/Avatar';
import moment from 'moment-timezone';
import { Statistics } from '../Statistics/Statistics';
import { Filters } from '../Filters/Filters';
import { toggleFiltersModal } from '../StudentsSlice';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import TeacherDashboard from '../../Dashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight as RightArrowIcon } from '@fortawesome/free-solid-svg-icons';
import { ENROLLMENTS, STUDENT_PROFILE, TEACHER_DASHBOARD } from 'routes/CONSTANTS';

export const Students = () => {
  const {
    generateFilter,
    studentsState,
    dispatch,
    Columns,
    data,
    isLoadingTableData,
    offset,
    setOffset,
    setSortBy,
    setDesc,
    navigate,
    isFetching
  } = useLogic();

  const NameCol = ({ src, name }: { src: string; name: string; }) => (
    <div className="name-col">
      <Avatar
        src={src}
        size={45}
      />
      <div className="name">
        <Typography
          color="black"
          className="mb-05"
        >
          {name}
        </Typography>
      </div>
    </div>
  );

  return (
    <TeacherDashboard>
      <div className="students-container">
        <Filters isLoading={isFetching} />
        <Statistics />
        <div className="table mt-4">
          <Table
            onSort={({ key, direction }) => {
              setDesc(direction === 'desc');
              setSortBy(key);
            }}
            isLoading={isLoadingTableData}
            columns={Columns}
            data={
              data?.teacherAllUsersStat?.allUsersStat?.map((item) => ([
                { value: <NameCol
                  src={item?.picture}
                  name={item?.name}
                /> },
                { value: item?.courses?.length > 0
                  ? (
                    <div style={{ display: 'flex', gap: 5 }}>
                      {
                        Array.from(new Set(item?.courses?.map(((course) => course?.name)))).map((course) => (
                          <Label color={course}>{course}</Label>
                        ))
                      }
                    </div>
                  )
                  : '-'
                }, // subject
                { value: <Label color={item?.status}>{item?.status}</Label> }, // status
                { value: <Typography>{item?.country || '-'}</Typography> }, // country
                { value: <Typography>{item?.takenSessions || '-'}</Typography> }, // completed
                { value: <Typography>{item?.remainingSessions || '-'}</Typography> }, // remaining
                { value: <Typography>{item.nearest ? moment(item?.nearest).format('YYYY-MM-DD') : '-'}</Typography> }, // next
                { value: <FontAwesomeIcon
                  icon={RightArrowIcon}
                  className="arrow-icon"
                  color="#696C71"
                  onClick={() => navigate(`/${TEACHER_DASHBOARD}/${STUDENT_PROFILE(String(item?.id))}/${ENROLLMENTS}`)}
                /> },
              ]))
            }
          />
        </div>
        {!isLoadingTableData && (
          <div className="pagination">
            <ButtonBase
              tagName="i"
              onClick={() => {
                if (offset < 20) return;
                setOffset((prev) => prev - 20);
              }}
            >
              <i className={`icon-chevron-left ${offset < 20 && 'disabled'}`} />
            </ButtonBase>
            <Typography>
              {`${offset} - ${Number(data?.teacherAllUsersStat?.usersTotal) - offset > 20 ? offset + 20 : data?.teacherAllUsersStat?.usersTotal} of ${data?.teacherAllUsersStat?.usersTotal}`}
            </Typography>
            <ButtonBase
              tagName="i"
              onClick={() => {
                if (Number(data?.teacherAllUsersStat?.usersTotal) - offset <= 20) return;
                setOffset((prev) => prev + 20);
              }}
            >
              <i className={`icon-chevron-right ${(Number(data?.teacherAllUsersStat?.usersTotal) - offset <= 20) && 'disabled'}`} />
            </ButtonBase>
          </div>
        )}
        <Modal
          isClosble
          isOpen={studentsState.filtersModal}
          onRequestClose={() => dispatch(toggleFiltersModal(false))}
        >
          {generateFilter(studentsState.filter.selectedFilter)}
        </Modal>
      </div>
    </TeacherDashboard>
  );
};
