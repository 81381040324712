import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import onboarding from 'pages/Onboarding/OnboardingSlice';
import calendar from 'pages/Onboarding/Steps/Schedule/Calendar/Calendar.slice';
import dashboard from 'pages/Dashboard/DashboardSlice';
import stream from 'pages/Dashboard/Course/Stream/StreamSlice';
import assignment from 'pages/Dashboard/Course/Assignment/AssignmentSlice';
import practiceTest from 'pages/Dashboard/Course/PracticeTest/PracticeTestSlice';
import students from 'pages/Dashboard/Admin/Students/StudentsSlice';
import session from 'pages/Dashboard/Course/Session/SessionsSlice';
import mainCalendar from 'pages/Dashboard/Admin/Calendar/CalendarSlice';
import leads from 'pages/Dashboard/Admin/Leads/LeadsSlice';
import teacherCalendar from 'pages/Dashboard/Teacher/Calendar/CalendarSlice';
import homework from 'pages/Dashboard/Teacher/Homework/HomeworkSlice';
import teacherStudents from 'pages/Dashboard/Teacher/Students/StudentsSlice';
import teachers from 'pages/Dashboard/Admin/Teachers/TeachersSlice';
import createSession from 'pages/Dashboard/Course/Session/CreateSession/CreateSession.slice';

const reducer = combineReducers({
  onboarding: onboarding.reducer,
  calendar: calendar.reducer,
  dashboard: dashboard.reducer,
  stream: stream.reducer,
  assignment: assignment.reducer,
  practiceTest: practiceTest.reducer,
  students: students.reducer,
  sessions: session.reducer,
  mainCalendar: mainCalendar.reducer,
  teacherCalendar: teacherCalendar.reducer,
  leads: leads.reducer,
  homework: homework.reducer,
  teachers: teachers.reducer,
  teacherStudents: teacherStudents.reducer,
  createSession: createSession.reducer,
});
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});
export default store;
