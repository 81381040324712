import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Style
import 'assets/scss/general.scss';
import 'assets/scss/typography.scss';
import 'assets/scss/spacing.scss';
import 'assets/scss/alignment.scss';
import 'assets/scss/colors.scss';
import 'assets/scss/datepicker.scss';
import 'assets/scss/icons.scss';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>,
  document.getElementById('root')
);
