// Styling
import './FinancialStatCard.scss';

// Types
import { FinancialStatCard as IProps } from './FinancialStatCard.types';

export const FinancialStatCard = ({ image, count, description }: IProps) => (
  <div className={`financial-card-container ${Number(count) < 0 ? 'negative' : ''}`}>
    <div className="left-container">
      <img
        src={image}
        alt=""
      />
    </div>
    <div className="right-container">
      <div className="upper-container">
        <h3>{`$${count}`}</h3>
      </div>
      <div className="bottom-container">
        <p className="sub-text">{description}</p>
      </div>
    </div>
  </div>
);
