// Components
import Button from 'components/General/Button/Button';
import { OnboardingCourses } from 'layouts/OnboardingLayout/OnboardingLayout';

// Styling
import './CoursesCard.scss';

// UUID
import { v4 as uuidv4 } from 'uuid';

// Icons
// Assets
import GMAT from 'assets/images/GMAT.svg';
import IELTS from 'assets/images/IELTS.svg';
import SAT from 'assets/images/SAT.svg';
import { useEffect } from 'react';
import { useChooseCourse } from 'services/Onboarding/General/Mutations/useChooseCourse';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import onboarding, { OnboardingSlice } from '../OnboardingSlice';
import { ADMIN_ONBOARDING, ONBOARDING } from 'routes/CONSTANTS';
import { useDraftPlan } from 'services/Onboarding/Draft/Mutations/useDraftPlan';
import { useCourse } from 'services/Onboarding/General/useOnboarding';
import { useGetUserSteps } from 'services/Onboarding/AdminOnboarding/Queries/UserSteps/useGetUserSteps';
import { useChoosePlan } from 'services/Onboarding/General/Mutations/useChoosePlan';
import { usePostCoursePlan } from 'services/Onboarding/AdminOnboarding/Mutations/usePostCoursePlan';
import { useAdminChooseCourse } from 'services/Onboarding/AdminOnboarding/Mutations/useAdminChooseCourse';
import { OverlayLoader } from 'components/General/Loader/Loader';

const CoursesCard = () => {
  const { userId, className } = useParams();
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const { data: courses, isLoading: isLoadingCourses } = useCourse(onboardingState.plan.course || '', !!onboardingState.plan.course && !className);
  const { data: adminCourses, isLoading: isLoadingAdminCourses } = useGetUserSteps(Number(userId), String(className), !!className && !!userId);
  const { mutateAsync: chooseCourse } = useChooseCourse();
  const { mutateAsync: adminChooseCourse } = useAdminChooseCourse();
  const { mutateAsync: draftPlan, isLoading: isLoadingDraft } = useDraftPlan();
  const { mutateAsync: adminChoosePlan } = usePostCoursePlan();
  const { mutateAsync: choosePlan } = useChoosePlan();
  const { setPlanData, setItems, setActive, nextPage } = onboarding.actions;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = () => isLoadingCourses || isLoadingAdminCourses;

  const handleDraftCourse = () => {
    if (onboardingState.plan.courseID) {
      if (userId) {
        adminChooseCourse({ userId: Number(userId), courseId: onboardingState.plan.courseID }).then(() => {
          navigate(ADMIN_ONBOARDING(String(userId), String(onboardingState.plan.course)));
        });
      } else {
        chooseCourse(onboardingState.plan.courseID);
        draftPlan({ courseId: onboardingState.plan.courseID }).then(() => {
          navigate(ONBOARDING);
        });
      }
    }
  };

  const handleClickPlan = (id: number, sessionsCount: number, price: number, hours: number) => {
    if (!onboardingState.plan.isDraft) {
      const planData = {
        id,
        sessionsCount,
        hours,
        price,
        courseID: onboardingState.plan.courseID
      };
      dispatch(setPlanData(planData));
      draftPlan({ courseId: onboardingState.plan.courseID, planId: id }).then(() => dispatch(nextPage()));
    }
    choosePlan(id);

    // for admin onboarding
    if (userId) {
      adminChoosePlan({
        userId: Number(userId),
        planId: id,
        courseId: onboardingState.plan.courseID
      });
    }
  };

  // user onboarding
  useEffect(() => {
    if (!courses) return;
    const items = courses?.userSteps;
    dispatch(setItems(items));
    let active = (items?.findIndex((item: any) => item.active === false) || 0) + 1;
    // FIXME we should use step ids instead of index
    if (active === (onboardingState.plan.course === 'IELTS' ? 3 : 4) && onboardingState.plan.isDraft && onboardingState.plan.id) {
      active++;
    } else if (onboardingState.plan.isDraft && !onboardingState.plan.id) {
      dispatch(setPlanData({ isDraft: false }));
    }
    dispatch(setActive(active));
  }, []);

  // admin onboarding
  useEffect(() => {
    if (!adminCourses) return;
    const items = adminCourses?.userSteps.userSteps;
    dispatch(setItems(items));
    const active = (items?.findIndex((item: any) => item.active === false) || 0) + 1;
    dispatch(setActive(active));
  }, [adminCourses]);

  useEffect(() => {
    if (className) {
      dispatch(setPlanData({
        course: className,
        courseID: (className === 'SAT' && 1) || (className === 'GMAT' && 2) || (className === 'IELTS' && 3) || 1
      }));
    }
  }, [adminCourses]);

  useEffect(() => {
    if ((onboardingState.plan.courseID && !onboardingState.plan.id && courses) && onboardingState.plan.isDraft) {
      navigate(ONBOARDING);
    }
    if ((onboardingState.plan.courseID && onboardingState.plan.id && onboardingState.plan.sessionsCount && onboardingState.plan.price && onboardingState.plan.hours && courses) && onboardingState.plan.isDraft) {
      navigate(ONBOARDING);
      handleClickPlan(onboardingState.plan.id, onboardingState.plan.sessionsCount, onboardingState.plan.price, onboardingState.plan.hours);
    }
  }, [onboardingState.plan.id, courses]);

  type ICourses = {
    id: number,
    icon: any,
    title: 'SAT' | 'GMAT' | 'IELTS'
  };

  const Courses: ICourses[] = [
    {
      id: 2,
      icon: GMAT,
      title: 'GMAT'
    },
    {
      id: 1,
      icon: SAT,
      title: 'SAT'
    },
    {
      id: 3,
      icon: IELTS,
      title: 'IELTS'
    }
  ];

  return (
    <OnboardingCourses>
      {isLoading() && <OverlayLoader />}
      <div>
        <div className="courses-card-container mt-10">
          <div className="header-container">
            <h2>Im looking for a private tutor for...</h2>
          </div>
          <div className="body-container">
            { Courses.map((item) => (
              <div
                className={`course ${onboardingState.plan.course === item.title ? 'active' : ''}`}
                key={uuidv4()}
              >
                <div className="course-logo">
                  <img
                    src={item.icon}
                    alt={item.title}
                    onClick={() => {
                      if (onboardingState.plan.isDraft) {
                        dispatch(setPlanData({ courseID: item.id, course: item.title, isDraft: true }));
                      } else {
                        dispatch(setPlanData({ courseID: item.id, course: item.title }));
                      }
                    }}
                    // eslint-disable-next-line
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    aria-label={item.title}
                  />
                </div>
                <div className="course-title"><h2>{item.title}</h2></div>
              </div>
            )) }
          </div>
          <div className="footer-container">
            <Button
              color="primary"
              onClick={handleDraftCourse}
              isLoading={isLoadingDraft}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </OnboardingCourses>
  );
};

export default CoursesCard;
