// Styling
import { BackButtonPersonalInfo } from 'components/Dashboard/BackButtonPersonalInfo/BackButtonPersonalInfo';
import { PersonalInfoData, PersonalInfoDataScore, PersonalInfoFinalTest } from 'components/Dashboard/PersonalInfoData/PersonalInfoData';
import { PersonalInfoProfile } from 'components/Dashboard/PersonalInfoProfile/PersonalInfoProfile';
import { PersonalInfoTabBar } from 'components/AdminDashboard/PersonalInfoTabBar/PersonalInfoTabBar';
import { DASHBOARD, STUDENTS, STUDENT_PROFILE, TEACHER_DASHBOARD, TEACHER_STUDENTS } from 'routes/CONSTANTS';
import { useLogic } from './PersonalInfoPage.logic';
import './PersonalInfoPage.scss';
import { PersonalInfoPage as IProps } from './PersonalInfoPage.types';
import moment from 'moment-timezone';
import { OverlayLoader } from 'components/General/Loader/Loader';
import Label from 'components/General/Label/Label';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';

export const PersonalInfoPage = ({ children }: IProps) => {
  const {
    navigate,
    tabs,
    id,
    data,
    isLoading,
    coursesNames,
    course,
    scores,
    setCourseName,
    isAdmin
  } = useLogic();

  return (
    <div className="personal-info-page-container">
      {isLoading && (
      <div className="loading">
        <OverlayLoader />
      </div>
      )}
      <div className="personal-info-left-container">

        <div className="back-button">

          <BackButtonPersonalInfo
            onClick={() => (isAdmin ? navigate(`/${DASHBOARD}/${STUDENTS}`) : navigate(`/${TEACHER_DASHBOARD}/${TEACHER_STUDENTS}`))}
            name={data?.adminGetUserInfo?.first_name && `${data?.adminGetUserInfo?.first_name} ${data?.adminGetUserInfo?.last_name}`}
          />
        </div>

        <div className="profile">
          <PersonalInfoProfile
            image={data?.adminGetUserInfo?.picture}
            chatOnClick={() => {
              window.open(`whatsapp://send?text=Hello from SetOut!&phone=${data?.adminGetUserInfo.phoneNumber}`, '_blank');
            }}
            emailOnClick={() => {
              window.open(`mailto:${data?.adminGetUserInfo.email}?subject=SendMail&body=Description`, '_blank');
            }}
            firstname={data?.adminGetUserInfo?.first_name || ''}
            lastname={data?.adminGetUserInfo?.last_name || ''}
            status={data?.adminGetUserInfo?.status || ''}
          />
        </div>

        <div className="details">
          <div className="left">
            <PersonalInfoData
              title="Email"
              text={data?.adminGetUserInfo?.email || '-'}
            />
            <PersonalInfoData
              title="Guardian email"
              text={data?.adminGetUserInfo?.info?.guardain_email || '-'}
            />
            <PersonalInfoData
              title="Phone Number"
              text={String(data?.adminGetUserInfo?.phoneNumber || '-')}
            />
            <PersonalInfoData
              title="Guardian Phone Number"
              text={String(data?.adminGetUserInfo?.info?.guardain_number || '-')}
            />
            <PersonalInfoData
              title="Subjects Taken"
              content={(
                <div className="courses-taken">
                  {
                    coursesNames.length ? (
                      coursesNames.map((item) => (
                        <ButtonBase
                          tagName="div"
                          onClick={() => setCourseName(item)}
                        >
                          <Label color={item}>{item}</Label>
                        </ButtonBase>
                      ))
                    ) : (
                      <p>-</p>
                    )
                  }
                </div>
              )}
            />
            <PersonalInfoDataScore
              title="Past Scores"
              scores={(course && scores) ? scores : []}
            />
          </div>
          <div className="right">
            <PersonalInfoData
              title="School Name"
              text={data?.adminGetUserInfo?.info?.school_name || '-'}
            />
            <PersonalInfoData
              title="Country"
              text={data?.adminGetUserInfo?.info?.country || '-'}
            />
            <PersonalInfoData
              title="Date Of Birth"
              text={moment(data?.adminGetUserInfo?.birthday).format('YYYY-MM-DD') || '-'}
            />
            <PersonalInfoData
              title="Age"
              text={(moment().diff(data?.adminGetUserInfo?.birthday, 'years').toLocaleString() === '0' ? `${moment().diff(data?.adminGetUserInfo?.birthday, 'days').toLocaleString()} days` : `${moment().diff(data?.adminGetUserInfo?.birthday, 'years').toLocaleString()} years`) || '-'}
            />
            <PersonalInfoData
              title="Guardian name"
              text={data?.adminGetUserInfo?.info?.guardain_name || '-'}
            />
            <PersonalInfoFinalTest
              title="Final tests"
              data={data?.adminGetUserInfo.coursesInfo}
            />
          </div>
        </div>
      </div>

      <div className="right-container">
        <PersonalInfoTabBar
          tabs={tabs}
          parentRoutes={[DASHBOARD, STUDENT_PROFILE(`${id}`)]}
        />
        {children}
      </div>
    </div>
  );
};
