import { useState } from 'react';

// Components
import Button from 'components/General/Button/Button';
import TextField from 'components/General/TextField/TextField';

// Logic
import { useLogic } from 'pages/Onboarding/Steps/Payment/Payment.logic';

import './CreditCard.scss';

// Toast
import toast from 'react-hot-toast';

// React payment inputs
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { useSelector } from 'react-redux';
import { DashboardSlice, togglePaymentModal } from 'pages/Dashboard/DashboardSlice';
import { useQueryClient } from 'react-query';
import { CardData } from './CreditCard.types';
import { usePayClass } from 'services/Payment/Mutations/usePayClass';
import onboarding from 'pages/Onboarding/OnboardingSlice';

export const CreditCard = () => {
  const { setChosePaymentMethod } = onboarding.actions;
  const { dispatch } = useLogic();
  const queryClient = useQueryClient();
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps } = usePaymentInputs();
  const [cardNumber, setCardNumber] = useState<number>();
  const [cvc, setCvc] = useState<number>();
  const [expiryDate, setExpiryDate] = useState<number>();
  const { mutateAsync: payClass, isLoading } = usePayClass();

  const pay = () => {
    const cardData: CardData = {
      classId: Number(dashboardState.payment.classId),
      expMonth: Number(`${String(expiryDate).slice(0, 2)}`),
      expYear: Number(`20${String(expiryDate).slice(5, 7)}`),
      cvc: String(cvc),
      cardNumber: String(cardNumber),
    };
    if (!dashboardState.payment.classId || !expiryDate || !cvc || !cardNumber) {
      toast('Please enter correct data.');
    } else {
      payClass(cardData)
        .then(() => {
          queryClient.refetchQueries('unpaidClasses');
          queryClient.refetchQueries('studentCourses');
          queryClient.refetchQueries('userNearestSession');
          toast.success('Paid successfuly.');
          dispatch(togglePaymentModal(false));
        })
        .catch((err) => {
          toast.error(err.response.errors?.[0].message);
        });
    }
  };

  return (
    <div className="credit-card-container mb-2">
      <h2>Add New Card</h2>
      <h3>{`You will pay $${dashboardState.payment.price} for ${dashboardState.payment.className} Course (${dashboardState.payment.session} Sessions)`}</h3>
      <div className="credit-card-inputs mb-3">
        <TextField
          label="Card holder name"
          placeholder="Holder Name"
          variant="rounded"
          color="light-gray"
        />

        <div className="card-number">
          <div className="card-input">
            <TextField
              label="Card number"
              placeholder="9999 9999 9999"
              variant="rounded"
              value={cardNumber}
              error={meta.isTouched && meta.erroredInputs.cardNumber}
              {...getCardNumberProps({ onChange: (e: any) => setCardNumber(e.target.value) })}
            />
          </div>
          <div className={`card-image ${(meta.isTouched && meta.erroredInputs.expiryDate) ? 'card-number-error' : ''}`}>
            <svg {...getCardImageProps({ images })} />
          </div>
        </div>

        <div className="cvc-expiry">
          <div className="expiry-date">
            <TextField
              label="Expiry date"
              placeholder="03/16"
              variant="rounded"
              value={expiryDate}
              error={meta.isTouched && meta.erroredInputs.expiryDate}
              {...getExpiryDateProps({ onChange: (e: any) => setExpiryDate(e.target.value) })}
            />
          </div>
          <div className="cvc">
            <TextField
              label="CVC"
              placeholder="123"
              variant="rounded"
              value={cvc}
              error={meta.isTouched && meta.erroredInputs.cvc}
              {...getCVCProps({ onChange: (e: any) => setCvc(e.target.value) })}
            />
          </div>

        </div>
      </div>
      <div>
        <Button
          color="primary"
          isLoading={isLoading}
          onClick={() => {
            pay();
          }}
        >
          CONFIRM PAYMENT
        </Button>
      </div>
      <div className="mt-1">
        <Button
          color="primary"
          onClick={() => {
            dispatch(setChosePaymentMethod(false));
          }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};
