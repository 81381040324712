import { useState } from 'react';

// Components
import Button from 'components/General/Button/Button';
import TextField from 'components/General/TextField/TextField';

// Logic
import { useLogic } from 'pages/Onboarding/Steps/Payment/Payment.logic';

import './CreditCard.scss';

// React payment inputs
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { useNavigate, useParams } from 'react-router';
import { STUDENT_DASHBOARD } from 'routes/CONSTANTS';
import { usePayClass } from 'services/Payment/Mutations/usePayClass';
import { useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { CardData } from './CreditCard.types';
import { usePaymentInfo } from 'services/Payment/Queries/PaymentInfo/usePaymentInfo';
import DotsLoader from 'components/General/Loader/Loader';
import { useAdminPayClass } from 'services/Payment/Mutations/useAdminPayClass';
import Modal from 'components/General/Modal/Modal';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import onboarding from 'pages/Onboarding/OnboardingSlice';

export const CreditCard = () => {
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const { setChosePaymentMethod } = onboarding.actions;
  const { dispatch, onboardingState, confirmationModal, setConfirmationModal } = useLogic();
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps } = usePaymentInputs();
  const [cardNumber, setCardNumber] = useState<number>();
  const [cvc, setCvc] = useState<number>();
  const [expiryDate, setExpiryDate] = useState<number>();
  const { mutateAsync: payClass, isLoading } = usePayClass();
  const { mutateAsync: adminPayClass, isLoading: isLoadingAdminPayClass } = useAdminPayClass();
  const navigate = useNavigate();
  const { data, isLoading: isLoadingPayment } = usePaymentInfo(onboardingState.plan.classID);

  const pay = () => {
    const cardData: CardData = {
      classId: Number(onboardingState.plan.classID),
      expMonth: Number(`${String(expiryDate).slice(0, 2)}`),
      expYear: Number(`20${String(expiryDate).slice(5, 7)}`),
      cvc: String(cvc),
      cardNumber: String(cardNumber),
    };
    if (!onboardingState.plan.classID || !expiryDate || !cvc || !cardNumber) {
      toast('Please enter correct data.');
    } else if (userId) {
      adminPayClass(cardData).then(() => {
        setConfirmationModal(true);
      }).catch((err) => toast.error(err.response.errors?.[0].message));
    } else {
      payClass(cardData).then(() => {
        toast.success('Paid successfuly.');
        queryClient.refetchQueries('userNearestSession');
        navigate(STUDENT_DASHBOARD);
      }).catch((err) => toast.error(err.response.errors?.[0].message));
    }
  };

  return (
    <div className="credit-card-container mb-2">
      <h2>Add New Card</h2>
      {isLoadingPayment ? (
        <DotsLoader />
      ) : (
        <h3>{`You will pay $${data?.userGetPaymentInfo.finalAmount} for ${onboardingState.plan.course} Course (${onboardingState.plan.sessionsCount} Sessions)`}</h3>
      )}
      <div className="credit-card-inputs mb-3">
        <TextField
          label="Card holder name"
          placeholder="Holder Name"
          variant="rounded"
          color="light-gray"
        />

        <div className="card-number">
          <div className="card-input">
            <TextField
              label="Card number"
              placeholder="9999 9999 9999"
              variant="rounded"
              value={cardNumber}
              error={meta.isTouched && meta.erroredInputs.cardNumber}
              {...getCardNumberProps({ onChange: (e: any) => setCardNumber(e.target.value) })}
            />
          </div>
          <div className={`card-image ${(meta.isTouched && meta.erroredInputs.expiryDate) ? 'card-number-error' : ''}`}>
            <svg {...getCardImageProps({ images })} />
          </div>
        </div>

        <div className="cvc-expiry">
          <div className="expiry-date">
            <TextField
              label="Expiry date"
              placeholder="03/16"
              variant="rounded"
              value={expiryDate}
              error={meta.isTouched && meta.erroredInputs.expiryDate}
              {...getExpiryDateProps({ onChange: (e: any) => setExpiryDate(e.target.value) })}
            />
          </div>
          <div className="cvc">
            <TextField
              label="CVC"
              placeholder="123"
              variant="rounded"
              value={cvc}
              error={meta.isTouched && meta.erroredInputs.cvc}
              {...getCVCProps({ onChange: (e: any) => setCvc(e.target.value) })}
            />
          </div>

        </div>
      </div>
      <div>
        <Button
          color="primary"
          isLoading={isLoading || isLoadingAdminPayClass}
          onClick={() => pay()}
        >
          CONFIRM PAYMENT
        </Button>
      </div>
      <div className="mt-1">
        <Button
          color="primary"
          onClick={() => {
            dispatch(setChosePaymentMethod(false));
          }}
        >
          Back
        </Button>
      </div>
      {!userId && (
      <h3>
        <a
          href="#"
          onClick={() => navigate(STUDENT_DASHBOARD)}
        >
          Skip
        </a>

      </h3>
      )}
      <Modal
        isClosble
        isOpen={confirmationModal}
        onRequestClose={() => setConfirmationModal(false)}
      >
        <ConfirmationModal />
      </Modal>
    </div>
  );
};
