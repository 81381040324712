import ListingTable from 'components/AdminDashboard/ListingTable/ListingTable';
import { useLogic } from './Notes.logic';
import './Notes.scss';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { CourseStatisticsCard } from 'components/Dashboard/CourseStatisticsCard/CourseStatisticsCard';
import moment from 'moment-timezone';

export const Notes = () => {
  const {
    Columns,
    data,
    selected,
    handleSelect,
    toggleNotes,
    setToggleNotes,
    sessions,
    navigate
  } = useLogic();
  return (
    <div className="notes-container">
      <div className="course-cards-container">
        <h3>Your course</h3>
        <div className="course-cards">
          {
          data?.adminGetUserClassSessionsComments?.map((card) => (
            <CourseStatisticsCard
              status={card.status}
              title={card.plan?.course?.name}
              selectedCourse={card.id === selected.id}
              onClick={() => handleSelect(card.id, card.plan?.course?.name)}
              goToCourse={() => navigate(`/class/${card.id}/${card.classId}/${card.plan?.course?.name}/stream`)}
            />
          ))
        }

        </div>
      </div>
      <div className="buttons-container">
        <ButtonBase
          tagName="div"
          onClick={() => setToggleNotes('Teacher')}
        >
          <span className={toggleNotes === 'Teacher' ? 'selected' : ''}>Teacher</span>
        </ButtonBase>
        <ButtonBase
          tagName="div"
          onClick={() => setToggleNotes('Student')}
        >
          <span className={toggleNotes === 'Student' ? 'selected' : ''}>Student</span>
        </ButtonBase>
      </div>
      <div className="notes-table-container">
        <h3>Notes records</h3>
        <ListingTable
          columns={Columns}
          data={sessions?.map((record) => (
            [
              { value: <p className="sub-text">{record.id}</p> },
              { value: <p className="sub-text">{`Session ${record.sessionNumber}`}</p> },
              { value: <p className="sub-text">{moment(record.dueAt).format('MM/DD/YYYY')}</p> },
              { value: <p className="sub-text">{record.ratings?.[0]?.userRating.first_name ? `${record.ratings?.[0]?.userRating.first_name} ${record.ratings?.[0]?.userRating.last_name}` : '-'}</p> },
              { value: <p className="sub-text">{record.ratings?.[0]?.rate || '-'}</p> },
              { value: <p className="sub-text comment">{record.ratings?.[0]?.comment ? record.ratings?.[0]?.comment : '-'}</p> },
            ]
          ))}
        />
      </div>
    </div>
  );
};
