import { useRoles } from 'assets/utils/useRoles';
import { DashboardSlice, setCardId, setShowTabBar } from 'pages/Dashboard/DashboardSlice';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useAdminArchiveClass } from 'services/Dashboard/Classes/AdminClasses/Mutations/ArchiveClass/useAdminArchiveClass';
import { useAdminCancelClass } from 'services/Dashboard/Classes/AdminClasses/Mutations/CancelClass/useAdminCancelClass';
import { useAdminClasses } from 'services/Dashboard/Classes/AdminClasses/Queries/useAdminClasses';
import ClassFilters from './Filters/ClassFilter/ClassFilter';
import { LocationFilter } from './Filters/CountryFilter/LocationFilter';

export const useLogic = () => {
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const { data: adminClassesQuery, isLoading, isFetching } = useAdminClasses(dashboardState.filter);
  const { mutateAsync: archiveClass, isLoading: isLoadingArchiveClass } = useAdminArchiveClass();
  const { mutateAsync: cancelClass, isLoading: isLoadingCancelClass } = useAdminCancelClass();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAdmin } = useRoles();

  const handleCourseChange = (className: string, classID: number, id: number) => {
    dispatch(setShowTabBar(false));
    navigate(`/class/${id}/${classID}/${className}/stream`);
  };

  const generateFilter = (item?: string) => {
    switch (item) {
      case 'Subject':
        return <ClassFilters />;
      case 'Location':
        return <LocationFilter />;
      default:
        return <ClassFilters />;
    }
  };

  const handleCancel = (classId: number) => {
    cancelClass(classId)
      .then((res) => {
        toast.success(res.adminCancelClass.message);
        queryClient.refetchQueries('adminCourses');
      })
      .catch((err) => toast.error(err.response.errors[0].message));
    dispatch(setCardId(classId));
  };

  const handleArchive = (classId: number) => {
    archiveClass(classId)
      .then((res) => {
        toast.success(res.adminArchiveClass.message);
        queryClient.refetchQueries('adminCourses');
      })
      .catch((err) => toast.error(err.response.errors[0].message));
    dispatch(setCardId(classId));
  };

  return {
    adminClassesQuery,
    isLoading,
    isFetching,
    handleCourseChange,
    generateFilter,
    dashboardState,
    dispatch,
    isAdmin,
    handleArchive,
    handleCancel,
    isLoadingArchiveClass,
    isLoadingCancelClass
  };
};
