import { TableColumn } from 'components/Dashboard/Table/Table.types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClassFilters from '../Filters/ClassFilter/ClassFilter';
import { LocationFilter } from '../Filters/CountryFilter/LocationFilter';
import { TeacherFilter } from '../Filters/TeacherFilter/TeacherFilter';
import { StatusFilter } from '../Filters/StatusFilter/StatusFilter';
import { StudentsSlice } from '../StudentsSlice';
import { useNavigate } from 'react-router';
import { useTableData } from 'services/AdminDashboard/TableData/StudentsTableData/useTableData';
import ClassStatusFilter from '../Filters/ClassStatusFilter/ClassStatusFilter';
import { SchoolFilter } from '../Filters/SchoolFilter/SchoolFilter';
import { EnrollmentDate } from '../Filters/EnrollmentDate/EnrollmentDate';
import { ClassOfFilter } from '../Filters/ClassOfFilter/ClassOfFilter';
import { PaymentMethodFilter } from '../Filters/PaymentMethodFilter/PaymentMethodFilter';
import TestDateRangeFilter from '../Filters/TestDateRangeFilter/TestDateRangeFilter';
import CourseDateFilter from '../Filters/CourseDateFilter/CourseDateFilter';

export const useLogic = () => {
  const studentsState = useSelector((state: { students: StudentsSlice }) => state.students);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddPatient, setShowAddPatient] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [sortBy, setSortBy] = useState('Created at');
  const [desc, setDesc] = useState(true);
  const { data, isLoading: isLoadingTableData, isFetching } = useTableData({ filterBy: studentsState.filter, offset, sortBy, desc, limit: 20 });
  const Columns: TableColumn[] = [
    {
      value: 'Name',
      sortable: true,
      key: 'Name',
      align: 'center'
    },
    {
      value: 'Subjects taken',
      sortable: true,
      key: 'Courses taken',
    },
    {
      value: 'Status',
      sortable: true,
      key: 'Status'
    },
    {
      value: 'Country',
      sortable: true,
      key: 'Country'
    },
    {
      value: 'Teacher',
      sortable: true,
      key: 'Taught by'
    },
    {
      value: 'Improvement rate',
      sortable: false,
      key: 'Taught by'
    },
    {
      value: 'Remaining sessions',
      sortable: true,
      key: 'Remaining sessions',
    },
    {
      value: 'Taken sessions',
      sortable: true,
      key: 'Sessions taken',
    },
    {
      value: 'Next session',
      sortable: true,
      key: 'Next session',
    },
    {
      value: 'Created at',
      sortable: true,
      key: 'Created at',
      hidden: true
    },
  ];

  const generateFilter = (item?: string) => {
    switch (item) {
      case 'Subject':
        return <ClassFilters />;
      case 'Teacher':
        return <TeacherFilter />;
      case 'Country':
        return <LocationFilter />;
      case 'Status':
        return <StatusFilter />;
      case 'Class Status':
        return <ClassStatusFilter />;
      case 'School':
        return <SchoolFilter />;
      case 'Enrollment date':
        return <EnrollmentDate />;
      case 'Class of':
        return <ClassOfFilter />;
      case 'Payment method':
        return <PaymentMethodFilter />;
      case 'Test date':
        return <TestDateRangeFilter />;
      case 'Course date':
        return <CourseDateFilter />;
      default:
        return <ClassFilters />;
    }
  };

  return {
    generateFilter,
    studentsState,
    dispatch,
    showAddPatient,
    setShowAddPatient,
    Columns,
    data,
    isLoadingTableData,
    isFetching,
    navigate,
    offset,
    setOffset,
    sortBy,
    setSortBy,
    setDesc
  };
};
