import { useParams } from 'react-router';
import { useAdminSessionsStats } from 'services/Dashboard/Sessions/Queries/ClassSessionsStats/useAdminSessionsStats';

export const useLogic = () => {
  const { id } = useParams();
  const { data: adminSessions, isFetching: isLoadingAdminsSessions } = useAdminSessionsStats({ classId: Number(id) });

  return {
    adminSessions,
    isLoadingAdminsSessions
  };
};
