import ListingTable from 'components/AdminDashboard/ListingTable/ListingTable';
import { CourseStatisticsCard } from 'components/Dashboard/CourseStatisticsCard/CourseStatisticsCard';
import Button from 'components/General/Button/Button';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import Modal from 'components/General/Modal/Modal';
import moment from 'moment-timezone';
import { setCalendarDate, setCalendarMonth } from 'pages/Onboarding/Steps/Schedule/Calendar/Calendar.slice';
import RescheduleCalendar from './RescheduleCalendar/RescheduleCalendar';
import { useLogic } from './Sessions.logic';
import './Sessions.scss';

export const Sessions = () => {
  const {
    Columns,
    data,
    selected,
    handleSelect,
    course,
    toggleSessions,
    setToggleSessions,
    sessionState,
    dispatch,
    setRescheduleModal,
    onboarding,
    navigate,
    setDuration,
    isAdmin
  } = useLogic();
  return (
    <div className="sessions-recordings-container">
      <div className="course-cards-container">
        <h3>Your course</h3>
        <div className="course-cards">
          {
          data?.adminGetUserClassSessionsRecordings?.map((card) => (
            <CourseStatisticsCard
              status={card.status}
              title={card.plan?.course?.name}
              selectedCourse={card.id === selected.id}
              onClick={() => handleSelect(card.id, card.plan?.course?.name)}
              goToCourse={() => navigate(`/class/${card.id}/${card.classId}/${card.plan?.course?.name}/stream`)}
            />
          ))
        }

        </div>
      </div>
      <div className="sessions-buttons-container">
        <ButtonBase
          tagName="div"
          onClick={() => setToggleSessions('Sessions')}
        >
          <span className={toggleSessions === 'Sessions' ? 'selected' : ''}>Sessions</span>
        </ButtonBase>
        <ButtonBase
          tagName="div"
          onClick={() => setToggleSessions('Recordings')}
        >
          <span className={toggleSessions === 'Recordings' ? 'selected' : ''}>Recordings</span>
        </ButtonBase>
      </div>
      <div className="sessions-recordings-table-container">
        <h3>Session recordings</h3>
        <ListingTable
          columns={Columns}
          data={course?.sessions?.map((record) => (
            [
              { value: <p className="sub-text">{record.id}</p> },
              { value: <p className="sub-text">{course.plan?.course?.name}</p> },
              { value: <p className="sub-text">{`Sessions ${record.sessionNumber}`}</p> },
              { value: <p className="sub-text">{moment(record.dueAt).format('MM/DD/YYYY - hh:mm A')}</p> },
              { value: toggleSessions === 'Recordings' ? (
                <>
                  {
                    record.recordingLink ? (
                      <p className="sub-text">
                        <a
                          href={record.recordingLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Link
                        </a>
                      </p>
                    )
                      : '-'
                  }
                </>
              ) : (
                <div className="change-button-container">
                  {isAdmin && (
                    <Button
                      color="primary"
                      variant="small"
                      onClick={() => {
                        dispatch(setRescheduleModal(true));
                        dispatch(onboarding.actions.setPlanData({ sessionID: record.id, sessionNumber: record.sessionNumber, sessionDate: record.dueAt }));
                        dispatch(setCalendarDate(moment(record.dueAt).toDate()));
                        dispatch(setCalendarMonth(moment(record.dueAt).format('YYYY-MM')));
                        setDuration(record.duration);
                      }}
                    >
                      Reschedule
                    </Button>
                  )}
                  <Button
                    color="primary"
                    variant="small"
                    onClick={() => {
                      if (record.adminLink && record.status === 'Not Started') {
                        window.open(record.adminLink, '_blank');
                      }
                    }}
                    disabled={!record.adminLink || record.status !== 'Not Started'}
                  >
                    Open
                  </Button>
                </div>
              ) }
            ]
          ))}
        />
      </div>
      <Modal
        isClosble
        isOpen={sessionState.rescheduleModal}
        onRequestClose={() => dispatch(setRescheduleModal(false))}
        fullHeight
      >
        {
          sessionState.rescheduleModal && (
            <RescheduleCalendar />
          )
        }
      </Modal>
    </div>
  );
};
