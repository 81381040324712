import { useLogic } from './SessionsStats.logic';
import './SessionsStats.scss';
import { ISessionStat } from './SessionStats.types';
import GreenAvatarICon from 'assets/images/green-avatar-2.svg';
import MissidCallIcon from 'assets/images/camera-avatar.svg';
import CalendarIcon from 'assets/images/calendar.svg';
import DotsLoader from 'components/General/Loader/Loader';

export const SessionStat = ({ icon, number, title, isLoading }: ISessionStat) => (
  <div className="session-stat">
    { isLoading ? (
      <DotsLoader />
    ) : (
      <>
        <div className="session-icon">
          {icon}
        </div>
        <h2>{number}</h2>
        <h4 className="session-title">
          {title}
        </h4>
      </>
    )}
  </div>
);

export const SessionsStats = () => {
  const {
    adminSessions,
    isLoadingAdminsSessions
  } = useLogic();

  return (
    <div className="admin-sessions-stats">
      <SessionStat
        title="Total attended session"
        number={adminSessions?.userClass.attendedSessions}
        isLoading={isLoadingAdminsSessions}
        icon={(
          <img
            alt=""
            src={GreenAvatarICon}
          />
        )}
      />
      <SessionStat
        title="Total missed sessions"
        number={adminSessions?.userClass.missedSessions}
        isLoading={isLoadingAdminsSessions}
        icon={(
          <img
            alt=""
            src={MissidCallIcon}
          />
        )}
      />
      <SessionStat
        title="Total sessions rescheduled"
        number={adminSessions?.userClass.rescheduledSessions}
        isLoading={isLoadingAdminsSessions}
        icon={(
          <img
            alt=""
            src={CalendarIcon}
          />
        )}
      />
    </div>
  );
};
