import { TableColumn } from 'components/Dashboard/Table/Table.types';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useAdminChangeActivity } from 'services/AdminDashboard/PersonalInfo/PaymentStatus/ChangeActivity/useAdminChangeActivity';
import { SessionPayment } from 'services/AdminDashboard/PersonalInfo/PaymentStatus/types';
import { usePaymentStatus } from 'services/AdminDashboard/PersonalInfo/PaymentStatus/usePaymentStatus';
import { useAdminGetPeronalInfo } from 'services/AdminDashboard/PersonalInfo/PersonalInfo/useAdminGetPeronalInfo';
import { useAdminDeleteManualPayment } from 'services/Payment/Mutations/useAdminDeleteManualPayment';
import { setPaymentConfirmation, setPaymentConfirmationsModal, StudentsSlice, setManualPaymentData } from '../../StudentsSlice';

export const useLogic = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: userInfo } = useAdminGetPeronalInfo(Number(id));
  const { data, isFetching: isLoading } = usePaymentStatus(Number(id));
  const { mutateAsync: deletePayment, isLoading: isLoadingDeletePayment } = useAdminDeleteManualPayment();
  const { mutateAsync: changeActivity, isLoading: isLoadingChangeActivity } = useAdminChangeActivity();
  const [selected, setSelected] = useState<{ id?: number, name?: string }>({});
  const [course, setCourse] = useState<SessionPayment>();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const studentsState = useSelector((state: { students: StudentsSlice }) => state.students);
  const Columns: TableColumn[] = [
    {
      value: '#',
      sortable: true,
      key: 'id',
    },
    {
      value: 'Type',
      sortable: true,
      key: 'type',
    },
    {
      value: 'Paid by',
      sortable: true,
      key: 'paid by',
    },
    {
      value: 'Transaction date',
      sortable: true,
      key: 'status'
    },
    {
      value: 'Amount',
      sortable: true,
      key: 'country'
    },
    {
      value: 'Paid amount',
      sortable: true,
      key: ''
    },
    {
      value: 'Discount',
      sortable: true,
      key: 'teacher'
    },
    {
      value: 'Comment',
      sortable: true,
      key: 'comment'
    },
    {
      value: 'Edit',
      sortable: true,
      key: 'review'
    },
    {
      value: 'Delete',
      sortable: true,
      key: 'review'
    },
  ];
  useEffect(() => {
    if (data) {
      setSelected({ id: data.adminGetUserClassPayments?.classes?.[0]?.id, name: data.adminGetUserClassPayments?.classes?.[0]?.plan?.course?.name });
      setCourse(data.adminGetUserClassPayments?.classes?.[0]);
    }
  }, [data]);

  const handleSelect = (cardId?: number, cardName?: string) => {
    setSelected({ id: cardId, name: cardName });
    setCourse(data?.adminGetUserClassPayments?.classes.find((item) => item.id === cardId));
  };

  const handleDeletePayment = (paymentId: number) => {
    deletePayment({
      paymentId
    }).then((res) => {
      queryClient.refetchQueries('payment-status');
      toast.success(res.adminDeleteManualPayment.message);
    });
  };

  const handleChangeAvailibility = (classId?: number, accessible?: boolean) => {
    changeActivity({
      accessible,
      classId
    }).then((res) => {
      queryClient.refetchQueries('payment-status');
      toast.success(res.adminEditClassAccessibility.message);
    });
  };

  return {
    id,
    Columns,
    data,
    isLoading,
    selected,
    course,
    handleSelect,
    showModal,
    setShowModal,
    dispatch,
    studentsState,
    setPaymentConfirmation,
    setPaymentConfirmationsModal,
    userInfo,
    navigate,
    setManualPaymentData,
    isLoadingDeletePayment,
    handleDeletePayment,
    handleChangeAvailibility,
    isLoadingChangeActivity
  };
};
