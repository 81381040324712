import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminChooseCourse = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ userId, courseId }: { userId: number, courseId: number }) => client.request(
    gql`
    mutation AdminChooseCourse($courseId: Float!, $userId: Float!) {
      adminChooseCourse(courseId: $courseId, userId: $userId) {
        message
      }
    }
    `,
    {
      courseId,
      userId
    }
  ));
  return Mutation;
};
