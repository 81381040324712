// Components
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { CircleLoader } from 'components/General/Loader/Loader';

// uuid
import { v4 as uuid } from 'uuid';
import { setFilter, toggleFiltersModal } from '../LeadsSlice';
import { useLogic } from './Filters.logic';

// Style
import './Filters.scss';

export const Filters = ({ isLoading }: { isLoading: boolean }) => {
  const {
    leadsState,
    setSelectedFilter,
    dispatch,
    filters
  } = useLogic();

  return (
    <div className="leads-filters-container">
      {(leadsState.filter.status || leadsState.filter.country || leadsState.filter.courses || leadsState.filter.teacherId) && (
      <div className="filter-container selected">
        <ButtonBase
          tagName="div"
          onClick={() => dispatch(setFilter({ country: undefined, courses: undefined, teacherId: undefined, status: undefined, selectedBox: 'Total of leads' }))}
        >
          <p>Clear all</p>
        </ButtonBase>
      </div>
      )}
      {filters.map((filter) => (
        <ButtonBase
          tagName="div"
          onClick={() => {
            setSelectedFilter(filter.filter);
            dispatch(setFilter({ selectedFilter: filter.filter }));
            dispatch(toggleFiltersModal(true));
          }}
          key={uuid()}
        >
          <div className={`filter-container ${filter.active && 'selected'}`}>
            <p>{filter.filter}</p>
          </div>
        </ButtonBase>
      ))}
      { isLoading && (
        <CircleLoader />
      )}
    </div>
  );
};
