import Button from 'components/General/Button/Button';
import TextField from 'components/General/TextField/TextField';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilter, toggleFiltersModal } from '../../CalendarSlice';
import './ClassIdFilter.scss';

export const ClassIdFilter = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<number[]>();

  const handleApplyFilters = () => {
    dispatch(setFilter({ classesIds: value }));
    dispatch(toggleFiltersModal(false));
  };

  return (
    <div className="search-filter-container">
      <h2>Search</h2>
      <TextField
        placeholder="Search course ID"
        variant="rounded"
        type="number"
        value={value?.[0]}
        onChange={(e) => setValue([Number(e.target.value)])}
      />
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
