// Styling
import './PersonalInfoProfile.scss';

// Types
import { PersonalInfoProfile as IProps } from './PersonalInfoProfile.types';

// Image
import chatImage from 'assets/images/chat-img.svg';
import emailImage from 'assets/images/email-img.svg';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import Label from 'components/General/Label/Label';

export const PersonalInfoProfile = ({ image, chatOnClick, emailOnClick, statsOnClick, firstname, lastname, status }:IProps) => (
  <div className="personal-info-profile-container">

    <img
      src={image}
      alt=""
    />

    <span>
      <ButtonBase
        tagName="div"
        onClick={statsOnClick}
      >
        <Label color={status}>{status}</Label>
      </ButtonBase>
    </span>

    <h2>
      {`${firstname} ${lastname}`}
    </h2>

    <div className="icons">
      <ButtonBase
        tagName="div"
        onClick={chatOnClick}
      >
        <img
          src={chatImage}
          alt=""
        />

      </ButtonBase>
      <ButtonBase
        tagName="div"
        onClick={emailOnClick}
      >
        <img
          src={emailImage}
          alt=""
        />

      </ButtonBase>
    </div>

  </div>
);
