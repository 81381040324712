import Button from 'components/General/Button/Button';
import onboarding, { OnboardingSlice } from 'pages/Onboarding/OnboardingSlice';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useAdminSetManuallPayment } from 'services/Payment/Mutations/useAdminSetManuallPayment';
import WarningIcon from 'assets/images/warning.svg';
import './ManualPaymentConfirmationModal.scss';
import { LeadsSlice, setManualPayment, setPayment } from 'pages/Dashboard/Admin/Leads/LeadsSlice';
import { useQueryClient } from 'react-query';

export const ManualPaymentConfirmationModal = () => {
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);
  const { setManualPaymentConfirmationModal } = onboarding.actions;
  const { mutateAsync: payManually, isLoading } = useAdminSetManuallPayment();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const closeTab = () => {
    window.opener = null;
    window.open('', '_self');
    window.close();
  };

  const handlePayManually = () => {
    payManually({
      classId: leadsState.manualPayment.modal ? Number(leadsState.payment.classId) : Number(onboardingState.plan.classID)
    }).then((res) => {
      if (leadsState.manualPayment.modal) {
        queryClient.refetchQueries('lead-payment-info');
        dispatch(setManualPayment({ modal: false }));
        dispatch(setPayment({ modal: false }));
      } else {
        closeTab();
      }
      toast.success(res.adminSetClassManualPayment.message);
    });
  };

  return (
    <div className="manual-confirmation-modal">
      <h2>Are you sure you want to pay later?</h2>
      <img
        src={WarningIcon}
        alt=""
      />
      <div className="buttons">
        <Button
          color="primary"
          onClick={() => {
            if (leadsState.manualPayment.modal) {
              dispatch(setManualPayment({ modal: false }));
            } else {
              dispatch(setManualPaymentConfirmationModal(false));
            }
          }}
        >
          No
        </Button>
        <Button
          color="white"
          onClick={() => handlePayManually()}
          isLoading={isLoading}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};
