import moment from 'moment-timezone';
import { useAcuityAvailableDates, useAcuityAvailableTimes } from 'services/Onboarding/General/useOnboarding';
import { useDispatch, useSelector } from 'react-redux';
import { CreateSession, setCalendarDate } from './CreateSession.slice';
import { SessionSlice, setAddExtraSession } from '../SessionsSlice';
import onboarding, { OnboardingSlice } from 'pages/Onboarding/OnboardingSlice';
import { useRoles } from 'assets/utils/useRoles';
import { useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useCreateSession } from 'services/Dashboard/Sessions/Mutations/useCreateSession';
import { useParams } from 'react-router';
import { useAdminGetTimeSlots } from 'services/Onboarding/AdminOnboarding/Queries/AdminGetAllAvailableTimeSlots/useAdminGetTimeSlots';

export const useLogic = () => {
  const { id, className } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { isTeacher, isAdmin } = useRoles();
  const [activeOpenCalendar, setActiveOpenCalendar] = useState(false);
  const [timeSlots, setTimeSlots] = useState<string[]>([]);
  const [duration, setDuration] = useState('2 hours');
  const { calendarDate, calendarMonth } = useSelector((state: { createSession: CreateSession }) => state.createSession);
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const sessionState = useSelector((state: { sessions: SessionSlice }) => state.sessions);
  const { data: availableDates, isLoading: isLoadingInitialAvailableDates, isPreviousData: isLoadingAvailableDates } = useAcuityAvailableDates({ appointmentTypeID: onboardingState.plan.typeID, calendarID: onboardingState.plan.calendarID, month: moment(calendarMonth).format('YYYY-MM') || '', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const { data: availableTimes, isPreviousData: isLoadingAvailableTimes, isLoading } = useAcuityAvailableTimes({ appointmentTypeID: onboardingState.plan.typeID, calendarID: onboardingState.plan.calendarID, date: moment(calendarDate).format('YYYY-MM-DD'), timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, change: activeOpenCalendar });
  const { data: teacherAllAvailableTimeSlots, isLoading: isLoadingAllTimeSlots } = useAdminGetTimeSlots({ month: moment(calendarDate).format('YYYY-MM-DD'), calendarID: onboardingState.plan.calendarID, enabled: activeOpenCalendar });
  const { mutateAsync: createSession, isLoading: isLoadingCreateSession } = useCreateSession();
  type Slots = {
    time: string,
    duration: number
  }[] | undefined;

  const generateTimes = (diff: number) => {
    const length = 24 / diff;
    const timesArr = [];
    let add = 0;
    let time = moment().startOf('day');
    for (let i = 0; i < length; i++) {
      time = moment(moment(calendarDate).format('YYYY-MM-DD')).startOf('day').add(add, 'h');
      timesArr.push(time.toLocaleString());
      add += diff;
    }
    return timesArr;
  };

  const filterTimes = (slots: Slots) => {
    if (slots) {
      const dates = slots.map((item) => ({
        start: moment(item.time).subtract(1, 's').toDate(),
        end: moment(item.time).add(item.duration, 'h').toDate()
      }));
      const durationn = duration?.split(' ')[0] ?? '';
      const processedDuration = +durationn > 3 ? +durationn / 60 : +durationn;
      const result = generateTimes(processedDuration).filter((item) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const date of dates) {
          if (
            moment(item).isBetween(moment(date.start), moment(date.end))
            || moment(moment(item).add(processedDuration, 'h')).isBetween(moment(date.start), moment(date.end))
          ) {
            return false;
          }
        }
        return true;
      });
      return result;
    }
    return [];
  };

  // Fixed a bug where user can choose multible time slots at the same day only the first time they open the calendar
  // The reason is that the calendar date is initially undefined
  useEffect(() => {
    if (activeOpenCalendar) {
      dispatch(setCalendarDate(moment(onboardingState.plan.sessionDate).toDate()));
    }
  }, [activeOpenCalendar]);

  useEffect(() => {
    if (activeOpenCalendar) {
      const availableSlots = teacherAllAvailableTimeSlots?.adminGetAppointmentsTimeSlots.filter((time) => moment(time.time).isSame(calendarDate, 'day'));
      setTimeSlots(filterTimes(availableSlots));
    } else {
      setTimeSlots(availableTimes?.data.map((item) => moment(item.time).toDate().toUTCString()) || []);
    }
  }, [availableTimes, teacherAllAvailableTimeSlots, activeOpenCalendar, duration]);
  const durations = ['30 minutes', '1 hour', '1.5 hour', '2 hours', '2.5 hour', '3 hours'];
  const appointmentTypeIds: any = {
    IELTS: [
      37361247,
      32390757,
      37361257,
      37361273,
      37361913,
      37361924
    ],
    SAT: [
      37360737,
      32366529,
      37361009,
      37361035,
      37362044,
      37362059
    ],
    GMAT: [
      37361235,
      32390362,
      37361094,
      37361116,
      37361991,
      37362009
    ]
  };
  const [tempDate, setTempDate] = useState<any>();

  const handleCreateSession = () => {
    createSession({
      appointmentTypeId: appointmentTypeIds[className || 'SAT'][durations.indexOf(duration)],
      datetime: onboardingState.selectedTimeSlots[0].datetime,
      classId: Number(id)
    }).then(() => {
      toast.success('Session created successfully.');
      queryClient.refetchQueries('class-sessions');
      dispatch(setAddExtraSession({ modal: false }));
      dispatch(setCalendarDate(moment(onboardingState.plan.sessionDate).toDate()));
    }).catch((err) => {
      toast.error(err.response.errors[0].message);
    });
  };

  // Fixed a bug where user can choose multible time slots at the same day only the first time they open the calendar
  // The reason is that the calendar date is initially undefined
  useEffect(() => {
    if (activeOpenCalendar) {
      setTempDate(calendarDate);
      dispatch(setCalendarDate(moment(onboardingState.plan.sessionDate).toDate()));
    } else {
      dispatch(setCalendarDate(moment(tempDate).toDate()));
    }
  }, [activeOpenCalendar]);

  useEffect(() => {
    if (!sessionState.extraSession.modal) {
      dispatch(onboarding.actions.resetTimeSlots());
      dispatch(setCalendarDate(new Date()));
    }
  }, [sessionState.extraSession.modal]);

  return {
    availableDates,
    isLoadingInitialAvailableDates,
    isLoadingAvailableDates,
    isLoadingAvailableTimes,
    handleCreateSession,
    onboardingState,
    isTeacher,
    isAdmin,
    timeSlots,
    setActiveOpenCalendar,
    activeOpenCalendar,
    isLoadingAllTimeSlots,
    isLoading,
    duration,
    setDuration,
    durations,
    isLoadingCreateSession,
    appointmentTypeIds,
    className
  };
};
