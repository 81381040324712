import { useLogic } from './FinalTest.logic';
import './FinalTest.scss';
import Button from 'components/General/Button/Button';
import { DatePicker } from 'react-rainbow-components';

export const FinalTest = () => {
  const {
    date,
    setDate,
    handleSubmit,
    isLoading
  } = useLogic();

  return (
    <div className="final-test-modal-container">
      <div className="date-fields-container">
        <DatePicker
          placeholder="YYYY-MM-DD"
          formatStyle="medium"
          labelAlignment="left"
          className="date-picker"
          label="Final test date"
          minDate={new Date()}
          value={date}
          onChange={(value) => setDate(value)}
          icon={<></>}
        />
      </div>
      <div className="button-container mt-2">
        <Button
          color="primary"
          onClick={handleSubmit}
          isLoading={isLoading}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
