import { useRoles } from 'assets/utils/useRoles';
import { useParams } from 'react-router';
import { useClassSessions } from 'services/Dashboard/Sessions/Queries/ClassSessions/useClassSessions';

export const useLogic = () => {
  const { id } = useParams();
  const { isAdmin } = useRoles();
  const { data, isFetching: isLoading } = useClassSessions({ classId: Number(id), filter: 'Recordings', isAdmin: Boolean(isAdmin) });

  return {
    data,
    isLoading
  };
};
