import { gql } from 'graphql-request';
import { AssignmentSliceType } from 'pages/Dashboard/Course/Assignment/Assignments.types';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useUpdateAssignment = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ details, dueDate, name, id, receivedMark, fullMark, classId, addAttachmentsIds, deleteAttachmentsNames, resubmit, done }: AssignmentSliceType) => client.request(
    gql`
    mutation Mutation($classId: Float!, $assignmentId: Float!, $deleteAttachmentsNames: [String!], $addAttachmentsIds: [Float!], $name: String, $details: String, $dueDate: String, $status: String, $receivedMark: Float, $fullMark: Float, $resubmit: Boolean, $done: Boolean) {
      adminUpdateAssignment(classId: $classId, assignmentId: $assignmentId, deleteAttachmentsNames: $deleteAttachmentsNames, addAttachmentsIds: $addAttachmentsIds, name: $name, details: $details, dueDate: $dueDate, status: $status, receivedMark: $receivedMark, fullMark: $fullMark, resubmit: $resubmit, done: $done) {
        id
        name
        fullMark
        receivedMark
        details
        status
        classId
        dueDate
        attachments {
          id
          attachment
          teacherAttachment
        }
      }
    }     
    `,
    // FIXME add resubmit input
    {
      assignmentId: Number(id) || undefined,
      classId: Number(classId) || undefined,
      receivedMark: Number(receivedMark) >= 0 ? Number(receivedMark) : undefined,
      fullMark: Number(fullMark) || undefined,
      dueDate: dueDate || undefined,
      details: details || undefined,
      name: name || undefined,
      addAttachmentsIds: Number(addAttachmentsIds?.length) > 0 ? addAttachmentsIds : undefined,
      deleteAttachmentsNames: Number(deleteAttachmentsNames?.length) > 0 ? deleteAttachmentsNames : undefined,
      resubmit: resubmit || undefined,
      done
    }
  ).catch((error) => toast(error.response.errors[0].message)), {
    onSuccess: () => {}
  });
  return Mutation;
};
