import { base64 } from 'assets/utils/getBase64';
import { Files } from 'components/Dashboard/AssignmentCard/AssignmentCard.types';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateNewTeacher } from 'services/AdminPanel/Teachers/Mutations/useCreateNewTeacher';
import { useFileUploadMutation } from 'services/UploadFiles/useUploadFile';
import { TeachersSlice, toggleAddTeacherModal } from '../TeachersSlice';
import { AddTeacher } from './AddTeacher.types';

// pk
// pk_26330647_D0479O2RXD9CNNFEI94CZBP3W8LH3TTS

// folder id
// 86388129

// calendar id
// 6520269
export const useLogic = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const teachersState = useSelector((state: { teachers: TeachersSlice }) => state.teachers);
  const [courses, setCourses] = useState<string[] | undefined>([]);
  const options = [
    { value: 'SAT', label: 'SAT', disabled: false },
    { value: 'GMAT', label: 'GMAT', disabled: false },
    { value: 'IELTS', label: 'IELTS', disabled: false },
  ];
  const [files, setFiles] = useState<Files>([]);
  const [attachmentsId, setAttachmentsIds] = useState<number>();
  const { getBase64 } = base64();
  const { progress, setProgress, mutation: { mutateAsync: uploadFiles, isLoading: isUploading } } = useFileUploadMutation();
  const input = useRef<any | null>(null);
  const { control, handleSubmit, setValue, watch, getValues, formState: { errors } } = useForm<AddTeacher>({
    defaultValues: {
      firstName: '',
      lastName: ''
    }
  });
  const { mutateAsync: createTeacher, isLoading: isLoadingCreateTeacher } = useCreateNewTeacher();

  const onSubmit = handleSubmit(async (data) => {
    createTeacher({
      acuityCalendarId: Number(data.acuityCalendarId),
      attachmentId: Number(attachmentsId),
      city: String(data.city),
      clickupFolderId: Number(data.clickupFolderId),
      clickupPk: String(data.clickupPk),
      country: String(data.country?.label),
      courses,
      degree: String(data.degree),
      details: String(data.details),
      email: String(data.email),
      firstName: String(data.firstName),
      lastName: String(data.lastName),
      phone: `${data.phone?.countryCode}-${data.phone?.phone}`,
      tutoringHours: Number(data.tutoringHours),
      university: String(data.university),
      yearsOfExperience: Number(data.yearsOfExperience),
      rate: Number(data.rate)
    }).then(() => {
      queryClient.refetchQueries('teachers-data');
      dispatch(toggleAddTeacherModal(false));
    }).catch((err) => {
      toast.error(err.response.errors[0].message);
    });
  });

  useEffect(() => {
    setValue('firstName', watch('firstName'));
    setValue('lastName', watch('lastName'));
  }, [watch('firstName'), watch('lastName')]);

  const addFiles = (file: Files) => {
    setFiles([...file, ...files]);
  };

  const handleUpload = async (e: any) => {
    if (!e.target.files) return;

    const formData = new FormData();
    const promises = [];
    const temp: Files = [];

    for (let i = 0; i < e.target.files.length || 0; i++) {
      if (e.target.files[i].size > 26214400) {
        toast.error('File cannot be bigger than 25 Megabytes');
        e.target.value = '';
        return;
      }
      formData.append('files[]', e.target.files[i]);
      promises.push(getBase64(e.target.files[i]));
    }

    const responses = await Promise.all(promises);
    responses.map((res: any) => {
      const startIndex = String(res.result).search('base64,') + 7;
      const base46 = String(res.result).slice(startIndex, String(res.result).length);
      temp.push({ base64: base46, type: res.type });
      return res;
    });
    addFiles(temp);

    uploadFiles({ file: formData })
      .then((res: any) => {
        setProgress(0);
        setAttachmentsIds(res.data.attachmentsIds);
      }).catch(() => {
        setFiles([...files].slice(-1));
      });

    e.target.value = '';
  };

  useEffect(() => {
    if (!teachersState.addTeacherModal) {
      setValue('acuityCalendarId', undefined);
      setValue('clickupFolderId', undefined);
      setValue('clickupPk', undefined);
      setValue('city', undefined);
      setValue('country.label', undefined);
      setValue('degree', undefined);
      setValue('university', undefined);
      setValue('details', undefined);
      setValue('email', undefined);
      setValue('firstName', undefined);
      setValue('lastName', undefined);
      setValue('phone.phone', '');
      setValue('phone.countryCode', '');
      setValue('phone.isoCode', '');
      setValue('tutoringHours', undefined);
      setValue('yearsOfExperience', undefined);
      setCourses([]);
    }
  }, [teachersState.addTeacherModal]);

  return {
    control,
    handleSubmit,
    setValue,
    errors,
    onSubmit,
    courses,
    setCourses,
    isLoadingCreateTeacher,
    getValues,
    watch,
    input,
    handleUpload,
    progress,
    isUploading,
    files,
    options
  };
};
