import { DashboardSlice, setTargetScore } from 'pages/Dashboard/DashboardSlice';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useSetFinalTest } from 'services/Dashboard/Todos/Mutations/useSetFinalTest';

export const useLogic = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const { mutateAsync: sendFinalTestData, isLoading } = useSetFinalTest();
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<{ score?: number }>({
    defaultValues: {
      score: undefined
    }
  });

  const rules = () => {
    if (dashboardState.targetScore.course === 'SAT') {
      return {
        rules: {
          required: {
            value: true,
            message: 'This field is required'
          },
          max: {
            value: 1600,
            message: 'Score cannot be greater than 1600'
          },
          min: {
            value: 400,
            message: 'Score cannot be less than 400'
          },
        },
        placeholder: '400 - 1600'
      };
    }
    if (dashboardState.targetScore.course === 'GMAT') {
      return {
        rules: {
          required: {
            value: true,
            message: 'This field is required'
          },
          max: {
            value: 800,
            message: 'Score cannot be greater than 800'
          },
          min: {
            value: 200,
            message: 'Score cannot be less than 200'
          },
        },
        placeholder: '200 - 800'
      };
    }
    if (dashboardState.targetScore.course === 'IELTS') {
      return {
        rules: {
          required: {
            value: true,
            message: 'This field is required'
          },
          max: {
            value: 9,
            message: 'Score cannot be greater than 9'
          },
          min: {
            value: 1,
            message: 'Score cannot be less than 1'
          },
        },
        placeholder: '1 - 9'
      };
    }

    return {
      required: {
        value: true,
        message: 'This field is required'
      },
      max: {
        value: 100,
        message: 'Score cannot be greater than 100'
      },
      min: {
        value: 0,
        message: 'Score cannot be greater than 0'
      },
    };
  };

  const onSubmit = handleSubmit((data) => {
    if (errors.score) return;
    sendFinalTestData({
      classId: Number(dashboardState.targetScore.id),
      targetScore: Number(data.score)
    }).then(() => {
      dispatch(setTargetScore({ modal: false }));
      queryClient.refetchQueries('final-tests');
      setValue('score', undefined);
      toast.success('Final test details submitted successfully');
    });
  });

  useEffect(() => {
    if (!dashboardState.finalTest.modal) {
      dispatch(setTargetScore({ targetScore: undefined }));
      setValue('score', undefined);
    }
  }, [dashboardState.finalTest.modal]);

  return {
    control,
    errors,
    onSubmit,
    isLoading,
    dashboardState,
    rules
  };
};
