// Types
import { TabBar as IProps } from './TabBar.types';

// Styling
import './TabBar.scss';
import { v4 as uuid } from 'uuid';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { useLocation, useNavigate } from 'react-router';

export const TabBar = ({ tabs, parentRoute }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTab = location.pathname.split('/')?.[2];

  const handleTabChange = (tab: string) => {
    navigate(`/${parentRoute}/teacher_${tab.toLocaleLowerCase()}`);
  };
  return (
    <div className="tab-bar-container">
      {tabs.map((tab) => (
        <ButtonBase
          tagName="div"
          onClick={() => handleTabChange(tab)}
          key={uuid()}
        >
          <div
            className={`tab-container ${selectedTab === `teacher_${tab.split(' ').join('_').toLocaleLowerCase()}` && 'selected'}`}
          >
            <h3>{tab}</h3>
          </div>
        </ButtonBase>
      ))}

    </div>
  );
};
