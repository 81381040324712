import { useState } from 'react';
import Rating_1 from 'assets/images/rating_emoji_1.svg';
import Rating_4 from 'assets/images/rating_emoji_4.svg';
import Rating_3 from 'assets/images/rating_emoji_3.svg';
import Rating_2 from 'assets/images/rating_emoji_2.svg';
import Rating_5 from 'assets/images/rating_emoji_5.svg';
import { useRoles } from 'assets/utils/useRoles';
import { useReportSession } from 'services/Dashboard/Sessions/Mutations/useReportSession';
import { useRateSession } from 'services/Dashboard/Sessions/Mutations/useRateSession';
import { setStreamRateData, StreamSlice } from '../../Stream/StreamSlice';
import { useDispatch, useSelector } from 'react-redux';

export const useLogic = () => {
  const { isTeacher } = useRoles();
  const streamState = useSelector((state: { stream: StreamSlice }) => state.stream);
  const { mutateAsync: reportSession, isLoading: isLoadingReport } = useReportSession();
  const { mutateAsync: rateSession, isLoading: isLoadingRate } = useRateSession();
  const isLoading = () => isLoadingRate || isLoadingReport;
  const [isDone, setIsDone] = useState('Yes');
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState<string>('');
  const reasons = isTeacher ? [
    "The student didn't show",
  ] : [
    "The teacher didn't show",
    'The course was not accessible',
  ];
  const [reason, setReason] = useState<string>(reasons[0]);
  const dispatch = useDispatch();

  const icons = [
    {
      icon: Rating_5,
      desc: 'Great!',
      rating: 5
    },
    {
      icon: Rating_4,
      desc: 'Very good',
      rating: 4
    },

    {
      icon: Rating_3,
      desc: 'Good',
      rating: 3
    },
    {
      icon: Rating_2,
      desc: 'Bad',
      rating: 2
    },
    {
      icon: Rating_1,
      desc: 'Very bad',
      rating: 1
    },

  ];
  const hours = [
    '00:05',
    '00:10',
    '00:15',
    '00:20',
    '00:25',
    '00:30',
    '00:35',
    '00:40',
    '00:45',
    '00:50',
    '00:55',
    '01:00',
    '01:05',
    '01:10',
    '01:15',
    '01:20',
    '01:25',
    '01:30',
    '01:35',
    '01:40',
    '01:45',
    '01:50',
    '01:55',
  ];

  const handleSubmit = () => {
    if (isDone === 'Yes') {
      const ratingData = {
        rate: rating,
        comment,
        ratedUserId: streamState.rateData?.ratedUserId,
        sessionId: streamState.rateData?.sessionId,
      };
      rateSession(ratingData).then(() => {
        dispatch(setStreamRateData({ toggleRatingModal: false }));
      });
      setComment('');
    } if (isDone === 'Partially') {
      const reportData = {
        comment,
        status: 'Partially',
        sessionId: streamState.rateData?.sessionId,
      };
      reportSession(reportData).then(() => {
        dispatch(setStreamRateData({ toggleRatingModal: false }));
      });
      setComment('');
    } if (isDone === 'No') {
      const reportData = {
        comment,
        status: reason,
        sessionId: streamState.rateData?.sessionId,
      };
      reportSession(reportData).then(() => {
        dispatch(setStreamRateData({ toggleRatingModal: false }));
      });
      setComment('');
    }
  };

  return {
    icons,
    isDone,
    setIsDone,
    rating,
    setRating,
    hours,
    reasons,
    isLoading,
    handleSubmit,
    comment,
    setComment,
    setReason,
  };
};
