import Button from 'components/General/Button/Button';
import { useState } from 'react';
import { CheckboxGroup } from 'react-rainbow-components';
import './TeacherFilter.scss';
import { TeacherFilter as IProps } from './TeacherFilter.types';

export const TeacherFilter = ({ onFilter }: IProps) => {
  const [values, setVlaues] = useState<string[]>([]);

  const filter = () => {
    onFilter(values);
    return values;
  };

  const options = [
    { value: 'Experience', label: 'Experience', disabled: false },
    { value: 'Teaching hours', label: 'Teaching hours', disabled: false },
  ];

  return (
    <div className="teachers-filter-container">
      <h2>Teacher</h2>
      <div className="filters-container">
        <CheckboxGroup
          options={options}
          value={values}
          onChange={(e) => setVlaues([e[1]])}
          className="checkbox"
        />
      </div>
      <div className="mt-3">
        <Button
          color="primary"
          onClick={filter}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
