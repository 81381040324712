import { DashboardSlice, setFinalTest } from 'pages/Dashboard/DashboardSlice';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useSetFinalTest } from 'services/Dashboard/Todos/Mutations/useSetFinalTest';

export const useLogic = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [date, setDate] = useState<Date>();
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const { mutateAsync: sendFinalTestData, isLoading } = useSetFinalTest();

  const handleSubmit = () => {
    if (!date) {
      toast.error('Please select date and time');
      return;
    }
    sendFinalTestData({
      classId: Number(dashboardState.finalTest.id),
      examDate: date
    }).then(() => {
      dispatch(setFinalTest({ modal: false }));
      queryClient.refetchQueries('final-tests');
      toast.success('Final test details submitted successfully');
    });
  };

  useEffect(() => {
    if (!dashboardState.finalTest.modal) {
      dispatch(setFinalTest({ finalTest: undefined }));
      setDate(undefined);
    }
  }, [dashboardState.finalTest.modal]);

  return {
    date,
    setDate,
    handleSubmit,
    isLoading
  };
};
