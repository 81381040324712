// react rainbow phoneInput component
export const countriesWithCode = [{
  countryCode: '+93',
  country: 'Afghanistan',
  isoCode: 'af'
}, {
  countryCode: '+358',
  country: 'Åland islands',
  isoCode: 'ax'
}, {
  countryCode: '+355',
  country: 'Albania',
  isoCode: 'al'
}, {
  countryCode: '+213',
  country: 'Algeria',
  isoCode: 'dz'
}, {
  countryCode: '+1',
  country: 'American Somoa',
  isoCode: 'as'
}, {
  countryCode: '+376',
  country: 'Andorra',
  isoCode: 'ad'
}, {
  countryCode: '+244',
  country: 'Angola',
  isoCode: 'ao'
}, {
  countryCode: '+1',
  country: 'Anguilla',
  isoCode: 'ai'
}, {
  countryCode: '+1',
  country: 'Antigua and Barbuda',
  isoCode: 'ag'
}, {
  countryCode: '+54',
  country: 'Argentina',
  isoCode: 'ar'
}, {
  countryCode: '+374',
  country: 'Armenia',
  isoCode: 'am'
}, {
  countryCode: '+297',
  country: 'Aruba',
  isoCode: 'aw'
}, {
  countryCode: '+247',
  country: 'Ascension Island',
  isoCode: 'ac'
}, {
  countryCode: '+61',
  country: 'Australia',
  isoCode: 'au'
}, {
  countryCode: '+43',
  country: 'Austria',
  isoCode: 'at'
}, {
  countryCode: '+994',
  country: 'Azerbaijan',
  isoCode: 'az'
}, {
  countryCode: '+1',
  country: 'Bahamas',
  isoCode: 'bs'
}, {
  countryCode: '+973',
  country: 'Bahrain',
  isoCode: 'bh'
}, {
  countryCode: '+880',
  country: 'Bangladesh',
  isoCode: 'bd'
}, {
  countryCode: '+1',
  country: 'Barbados',
  isoCode: 'bb'
}, {
  countryCode: '+375',
  country: 'Belarus',
  isoCode: 'by'
}, {
  countryCode: '+32',
  country: 'Belgium',
  isoCode: 'be'
}, {
  countryCode: '+501',
  country: 'Belize',
  isoCode: 'bz'
}, {
  countryCode: '+229',
  country: 'Benin',
  isoCode: 'bj'
}, {
  countryCode: '+1',
  country: 'Bermuda',
  isoCode: 'bm'
}, {
  countryCode: '+975',
  country: 'Bhutan',
  isoCode: 'bt'
}, {
  countryCode: '+591',
  country: 'Bolivia',
  isoCode: 'bo'
}, {
  countryCode: '+599',
  country: 'Bonaire',
  isoCode: 'bq'
}, {
  countryCode: '+387',
  country: 'Bosnia and Herzegovina',
  isoCode: 'ba'
}, {
  countryCode: '+267',
  country: 'Botswana',
  isoCode: 'bw'
}, {
  countryCode: '+55',
  country: 'Brazil',
  isoCode: 'br'
}, {
  countryCode: '+1',
  country: 'British Virgin Islands',
  isoCode: 'vg'
}, {
  countryCode: '+673',
  country: 'Brunei',
  isoCode: 'bn'
}, {
  countryCode: '+359',
  country: 'Bulgaria',
  isoCode: 'bg'
}, {
  countryCode: '+226',
  country: 'Burkina Faso',
  isoCode: 'bf'
}, {
  countryCode: '+257',
  country: 'Burundi',
  isoCode: 'bi'
}, {
  countryCode: '+855',
  country: 'Cambodia',
  isoCode: 'kh'
}, {
  countryCode: '+237',
  country: 'Cameroon',
  isoCode: 'cm'
}, {
  countryCode: '+1',
  country: 'Canada',
  isoCode: 'ca'
}, {
  countryCode: '+238',
  country: 'Cape Verde',
  isoCode: 'cv'
}, {
  countryCode: '+1',
  country: 'Cayman Islands',
  isoCode: 'ky'
}, {
  countryCode: '+236',
  country: 'Central African Republic',
  isoCode: 'cf'
}, {
  countryCode: '+235',
  country: 'Chad',
  isoCode: 'td'
}, {
  countryCode: '+56',
  country: 'Chile',
  isoCode: 'cl'
}, {
  countryCode: '+86',
  country: 'China',
  isoCode: 'cn'
}, {
  countryCode: '+61',
  country: 'Christmas Island',
  isoCode: 'cx'
}, {
  countryCode: '+61',
  country: 'Cocos Island',
  isoCode: 'cc'
}, {
  countryCode: '+57',
  country: 'Colombia',
  isoCode: 'co'
}, {
  countryCode: '+269',
  country: 'Comoros',
  isoCode: 'km'
}, {
  countryCode: '+242',
  country: 'Congo - Brazzaville',
  isoCode: 'cg'
}, {
  countryCode: '+243',
  country: 'Congo - Kinshasa',
  isoCode: 'cd'
}, {
  countryCode: '+682',
  country: 'Cook Islands',
  isoCode: 'ck'
}, {
  countryCode: '+506',
  country: 'Costa Rica',
  isoCode: 'cr'
}, {
  countryCode: '+225',
  country: 'Côte d’lvoire',
  isoCode: 'ci'
}, {
  countryCode: '+385',
  country: 'Croatia',
  isoCode: 'hr'
}, {
  countryCode: '+53',
  country: 'Cuba',
  isoCode: 'cu'
}, {
  countryCode: '+599',
  country: 'Curaçao',
  isoCode: 'cw'
}, {
  countryCode: '+357',
  country: 'Cyprus',
  isoCode: 'cy'
}, {
  countryCode: '+420',
  country: 'Czech Republic',
  isoCode: 'cz'
}, {
  countryCode: '+45',
  country: 'Denmark',
  isoCode: 'dk'
}, {
  countryCode: '+253',
  country: 'Djibouti',
  isoCode: 'dj'
}, {
  countryCode: '+1',
  country: 'Dominica',
  isoCode: 'dm'
}, {
  countryCode: '+1',
  country: 'Dominican Republic',
  isoCode: 'do'
}, {
  countryCode: '+593',
  country: 'Ecuador',
  isoCode: 'ec'
}, {
  countryCode: '+20',
  country: 'Egypt',
  isoCode: 'eg'
}, {
  countryCode: '+503',
  country: 'El Salvador',
  isoCode: 'sv'
}, {
  countryCode: '+240',
  country: 'Equatorial Guinea',
  isoCode: 'gq'
}, {
  countryCode: '+291',
  country: 'Eritrea',
  isoCode: 'er'
}, {
  countryCode: '+372',
  country: 'Estonia',
  isoCode: 'ee'
}, {
  countryCode: '+251',
  country: 'Ethiopia',
  isoCode: 'et'
}, {
  countryCode: '+500',
  country: 'Falkland Islands',
  isoCode: 'fk'
}, {
  countryCode: '+298',
  country: 'Faroe Islands',
  isoCode: 'fo'
}, {
  countryCode: '+679',
  country: 'Fiji',
  isoCode: 'fj'
}, {
  countryCode: '+358',
  country: 'Finland',
  isoCode: 'fi'
}, {
  countryCode: '+33',
  country: 'France',
  isoCode: 'fr'
}, {
  countryCode: '+594',
  country: 'French Guiana',
  isoCode: 'gf'
}, {
  countryCode: '+689',
  country: 'French Polynesia',
  isoCode: 'pf'
}, {
  countryCode: '+241',
  country: 'Gabon',
  isoCode: 'ga'
}, {
  countryCode: '+220',
  country: 'Gambia',
  isoCode: 'gm'
}, {
  countryCode: '+995',
  country: 'Georgia',
  isoCode: 'ge'
}, {
  countryCode: '+49',
  country: 'Germany',
  isoCode: 'de'
}, {
  countryCode: '+233',
  country: 'Ghana',
  isoCode: 'gh'
}, {
  countryCode: '+350',
  country: 'Gibraltar',
  isoCode: 'gi'
}, {
  countryCode: '+30',
  country: 'Greece',
  isoCode: 'gr'
}, {
  countryCode: '+299',
  country: 'Greenland',
  isoCode: 'gl'
}, {
  countryCode: '+1',
  country: 'Grenada',
  isoCode: 'gd'
}, {
  countryCode: '+590',
  country: 'Guadeloupe',
  isoCode: 'gp'
}, {
  countryCode: '+1',
  country: 'Guam',
  isoCode: 'gu'
}, {
  countryCode: '+502',
  country: 'Guatemala',
  isoCode: 'gt'
}, {
  countryCode: '+44',
  country: 'Guernsey',
  isoCode: 'gg'
}, {
  countryCode: '+224',
  country: 'Guinea',
  isoCode: 'gn'
}, {
  countryCode: '+245',
  country: 'Guinea Bissau',
  isoCode: 'gw'
}, {
  countryCode: '+592',
  country: 'Guyana',
  isoCode: 'gy'
}, {
  countryCode: '+509',
  country: 'Haiti',
  isoCode: 'ht'
}, {
  countryCode: '+504',
  country: 'Honduras',
  isoCode: 'hn'
}, {
  countryCode: '+852',
  country: 'Hong Kong SAR China',
  isoCode: 'hk'
}, {
  countryCode: '+36',
  country: 'Hungary',
  isoCode: 'hu'
}, {
  countryCode: '+354',
  country: 'Iceland',
  isoCode: 'is'
}, {
  countryCode: '+91',
  country: 'India',
  isoCode: 'in'
}, {
  countryCode: '+62',
  country: 'Indonesia',
  isoCode: 'id'
}, {
  countryCode: '+98',
  country: 'Iran',
  isoCode: 'ir'
}, {
  countryCode: '+964',
  country: 'Iraq',
  isoCode: 'iq'
}, {
  countryCode: '+353',
  country: 'Ireland',
  isoCode: 'ie'
}, {
  countryCode: '+44',
  country: 'Isle of Man',
  isoCode: 'im'
}, {
  countryCode: '+39',
  country: 'Italy',
  isoCode: 'it'
}, {
  countryCode: '+1',
  country: 'Jamaica',
  isoCode: 'jm'
}, {
  countryCode: '+81',
  country: 'Japan',
  isoCode: 'jp'
}, {
  countryCode: '+44',
  country: 'Jersey',
  isoCode: 'je'
}, {
  countryCode: '+962',
  country: 'Jordan',
  isoCode: 'jo'
}, {
  countryCode: '+7',
  country: 'Kazakhstan',
  isoCode: 'kz'
}, {
  countryCode: '+254',
  country: 'Kenya',
  isoCode: 'ke'
}, {
  countryCode: '+686',
  country: 'Kiribati',
  isoCode: 'ki'
}, {
  countryCode: '+383',
  country: 'Kosovo',
  isoCode: 'kv'
}, {
  countryCode: '+965',
  country: 'Kuwait',
  isoCode: 'kw'
}, {
  countryCode: '+996',
  country: 'Kyrgyzstan',
  isoCode: 'kg'
}, {
  countryCode: '+856',
  country: 'Laos',
  isoCode: 'la'
}, {
  countryCode: '+371',
  country: 'Latvia',
  isoCode: 'lv'
}, {
  countryCode: '+961',
  country: 'Lebanon',
  isoCode: 'lb'
}, {
  countryCode: '+266',
  country: 'Lesotho',
  isoCode: 'ls'
}, {
  countryCode: '+231',
  country: 'Liberia',
  isoCode: 'lr'
}, {
  countryCode: '+218',
  country: 'Libya',
  isoCode: 'ly'
}, {
  countryCode: '+423',
  country: 'Liechtenstein',
  isoCode: 'li'
}, {
  countryCode: '+370',
  country: 'Lithuania',
  isoCode: 'lt'
}, {
  countryCode: '+352',
  country: 'Luxembourg',
  isoCode: 'lu'
}, {
  countryCode: '+853',
  country: 'Macau SAR China',
  isoCode: 'mo'
}, {
  countryCode: '+389',
  country: 'Macedonia',
  isoCode: 'mk'
}, {
  countryCode: '+261',
  country: 'Madagascar',
  isoCode: 'mg'
}, {
  countryCode: '+265',
  country: 'Malawi',
  isoCode: 'mw'
}, {
  countryCode: '+60',
  country: 'Malaysia',
  isoCode: 'my'
}, {
  countryCode: '+960',
  country: 'Maldives',
  isoCode: 'mv'
}, {
  countryCode: '+223',
  country: 'Mali',
  isoCode: 'ml'
}, {
  countryCode: '+356',
  country: 'Malta',
  isoCode: 'mt'
}, {
  countryCode: '+692',
  country: 'Marshall Islands',
  isoCode: 'mh'
}, {
  countryCode: '+596',
  country: 'Martinique',
  isoCode: 'mq'
}, {
  countryCode: '+222',
  country: 'Mauritania',
  isoCode: 'mr'
}, {
  countryCode: '+230',
  country: 'Mauritius',
  isoCode: 'mu'
}, {
  countryCode: '+262',
  country: 'Mayotte',
  isoCode: 'yt'
}, {
  countryCode: '+52',
  country: 'Mexico',
  isoCode: 'mx'
}, {
  countryCode: '+691',
  country: 'Micronesia',
  isoCode: 'fm'
}, {
  countryCode: '+373',
  country: 'Moldova',
  isoCode: 'md'
}, {
  countryCode: '+377',
  country: 'Monaco',
  isoCode: 'mc'
}, {
  countryCode: '+976',
  country: 'Mongolia',
  isoCode: 'mn'
}, {
  countryCode: '+382',
  country: 'Montenegro',
  isoCode: 'me'
}, {
  countryCode: '+1',
  country: 'Montserrat',
  isoCode: 'ms'
}, {
  countryCode: '+212',
  country: 'Morocco',
  isoCode: 'ma'
}, {
  countryCode: '+258',
  country: 'Mozambique',
  isoCode: 'mz'
}, {
  countryCode: '+95',
  country: 'Myanmar',
  isoCode: 'mm'
}, {
  countryCode: '+264',
  country: 'Namibia',
  isoCode: 'na'
}, {
  countryCode: '+674',
  country: 'Nauru',
  isoCode: 'nr'
}, {
  countryCode: '+977',
  country: 'Nepal',
  isoCode: 'np'
}, {
  countryCode: '+31',
  country: 'Netherlands',
  isoCode: 'nl'
}, {
  countryCode: '+687',
  country: 'New Caledonia',
  isoCode: 'nc'
}, {
  countryCode: '+64',
  country: 'New Zealand',
  isoCode: 'nz'
}, {
  countryCode: '+505',
  country: 'Nicaragua',
  isoCode: 'ni'
}, {
  countryCode: '+227',
  country: 'Niger',
  isoCode: 'ne'
}, {
  countryCode: '+234',
  country: 'Nigeria',
  isoCode: 'ng'
}, {
  countryCode: '+683',
  country: 'Niue',
  isoCode: 'nu'
}, {
  countryCode: '+672',
  country: 'Norfolk Island',
  isoCode: 'nf'
}, {
  countryCode: '+850',
  country: 'North Korea',
  isoCode: 'kp'
}, {
  countryCode: '+1',
  country: 'Northern Mariana Islands',
  isoCode: 'mp'
}, {
  countryCode: '+47',
  country: 'Norway',
  isoCode: 'no'
}, {
  countryCode: '+968',
  country: 'Oman',
  isoCode: 'om'
}, {
  countryCode: '+92',
  country: 'Pakistan',
  isoCode: 'pk'
}, {
  countryCode: '+680',
  country: 'Palau',
  isoCode: 'pw'
}, {
  countryCode: '+970',
  country: 'Palestine',
  isoCode: 'ps'
}, {
  countryCode: '+507',
  country: 'Panama',
  isoCode: 'pa'
}, {
  countryCode: '+675',
  country: 'Papua New Guinea',
  isoCode: 'pg'
}, {
  countryCode: '+595',
  country: 'Paraguay',
  isoCode: 'py'
}, {
  countryCode: '+51',
  country: 'Peru',
  isoCode: 'pe'
}, {
  countryCode: '+63',
  country: 'Philippines',
  isoCode: 'ph'
}, {
  countryCode: '+48',
  country: 'Poland',
  isoCode: 'pl'
}, {
  countryCode: '+351',
  country: 'Portugal',
  isoCode: 'pt'
}, {
  countryCode: '+1',
  country: 'Puerto Rico',
  isoCode: 'pr'
}, {
  countryCode: '+974',
  country: 'Qatar',
  isoCode: 'qa'
}, {
  countryCode: '+262',
  country: 'Réunion',
  isoCode: 're'
}, {
  countryCode: '+40',
  country: 'Romania',
  isoCode: 'ro'
}, {
  countryCode: '+7',
  country: 'Russia',
  isoCode: 'ru'
}, {
  countryCode: '+250',
  country: 'Rwanda',
  isoCode: 'rw'
}, {
  countryCode: '+590',
  country: 'Saint Barthélemy',
  isoCode: 'bl'
}, {
  countryCode: '+290',
  country: 'Saint Helena',
  isoCode: 'sh'
}, {
  countryCode: '+1',
  country: 'Saint Kitts and Nevis',
  isoCode: 'kn'
}, {
  countryCode: '+1',
  country: 'Saint Lucia',
  isoCode: 'lc'
}, {
  countryCode: '+590',
  country: 'Saint Martin',
  isoCode: 'mf'
}, {
  countryCode: '+508',
  country: 'Saint Pierre and Miquelon',
  isoCode: 'pm'
}, {
  countryCode: '+1',
  country: 'Saint Vincent and the Grenadines',
  isoCode: 'vc'
}, {
  countryCode: '+685',
  country: 'Samoa',
  isoCode: 'ws'
}, {
  countryCode: '+378',
  country: 'San Marino',
  isoCode: 'sm'
}, {
  countryCode: '+239',
  country: 'São Tomé and Príncipe',
  isoCode: 'st'
}, {
  countryCode: '+966',
  country: 'Saudi Arabia',
  isoCode: 'sa'
}, {
  countryCode: '+221',
  country: 'Senegal',
  isoCode: 'sn'
}, {
  countryCode: '+381',
  country: 'Serbia',
  isoCode: 'rs'
}, {
  countryCode: '+248',
  country: 'Seychelles',
  isoCode: 'sc'
}, {
  countryCode: '+232',
  country: 'Sierra Leone',
  isoCode: 'sl'
}, {
  countryCode: '+65',
  country: 'Singapore',
  isoCode: 'sg'
}, {
  countryCode: '+1',
  country: 'Sint Maarten',
  isoCode: 'sx'
}, {
  countryCode: '+421',
  country: 'Slovakia',
  isoCode: 'sk'
}, {
  countryCode: '+386',
  country: 'Slovenia',
  isoCode: 'si'
}, {
  countryCode: '+677',
  country: 'Solomon Islands',
  isoCode: 'sb'
}, {
  countryCode: '+252',
  country: 'Somalia',
  isoCode: 'so'
}, {
  countryCode: '+27',
  country: 'South Africa',
  isoCode: 'za'
}, {
  countryCode: '+82',
  country: 'South Korea',
  isoCode: 'kr'
}, {
  countryCode: '+211',
  country: 'South Sudan',
  isoCode: 'ss'
}, {
  countryCode: '+34',
  country: 'Spain',
  isoCode: 'es'
}, {
  countryCode: '+94',
  country: 'Sri Lanka',
  isoCode: 'lk'
}, {
  countryCode: '+249',
  country: 'Sudan',
  isoCode: 'sd'
}, {
  countryCode: '+597',
  country: 'Suriname',
  isoCode: 'sr'
}, {
  countryCode: '+47',
  country: 'Svalbard and Jan Mayen',
  isoCode: 'sj'
}, {
  countryCode: '+268',
  country: 'Swaziland',
  isoCode: 'sz'
}, {
  countryCode: '+46',
  country: 'Sweden',
  isoCode: 'se'
}, {
  countryCode: '+41',
  country: 'Switzerland',
  isoCode: 'ch'
}, {
  countryCode: '+963',
  country: 'Syria',
  isoCode: 'sy'
}, {
  countryCode: '+886',
  country: 'Taiwan',
  isoCode: 'tw'
}, {
  countryCode: '+992',
  country: 'Tajikistan',
  isoCode: 'tj'
}, {
  countryCode: '+255',
  country: 'Tanzania',
  isoCode: 'tz'
}, {
  countryCode: '+66',
  country: 'Thailand',
  isoCode: 'th'
}, {
  countryCode: '+670',
  country: 'Timor-Leste',
  isoCode: 'tl'
}, {
  countryCode: '+228',
  country: 'Togo',
  isoCode: 'tg'
}, {
  countryCode: '+690',
  country: 'Tokelau',
  isoCode: 'tk'
}, {
  countryCode: '+676',
  country: 'Tonga',
  isoCode: 'to'
}, {
  countryCode: '+1',
  country: 'Trinidad and Tobago',
  isoCode: 'tt'
}, {
  countryCode: '+216',
  country: 'Tunisia',
  isoCode: 'tn'
}, {
  countryCode: '+90',
  country: 'Turkey',
  isoCode: 'tr'
}, {
  countryCode: '+993',
  country: 'Turkmenistan',
  isoCode: 'tm'
}, {
  countryCode: '+1',
  country: 'Turks and Caicos Islands',
  isoCode: 'tc'
}, {
  countryCode: '+688',
  country: 'Tuvalu',
  isoCode: 'tv'
}, {
  countryCode: '+1',
  country: 'U.S Virgin Islands',
  isoCode: 'vi'
}, {
  countryCode: '+256',
  country: 'Uganda',
  isoCode: 'ug'
}, {
  countryCode: '+380',
  country: 'Ukraine',
  isoCode: 'ua'
}, {
  countryCode: '+971',
  country: 'United Arab Emirates',
  isoCode: 'ae'
}, {
  countryCode: '+44',
  country: 'United Kingdom',
  isoCode: 'gb'
}, {
  countryCode: '+1',
  country: 'United States',
  isoCode: 'us'
}, {
  countryCode: '+598',
  country: 'Uruguay',
  isoCode: 'uy'
}, {
  countryCode: '+998',
  country: 'Uzbekistan',
  isoCode: 'uz'
}, {
  countryCode: '+678',
  country: 'Vanuatu',
  isoCode: 'vu'
}, {
  countryCode: '+379',
  country: 'Vatican City',
  isoCode: 'va'
}, {
  countryCode: '+58',
  country: 'Venezuela',
  isoCode: 've'
}, {
  countryCode: '+84',
  country: 'Vietnam',
  isoCode: 'vn'
}, {
  countryCode: '+681',
  country: 'Wallis and Futuna',
  isoCode: 'wf'
}, {
  countryCode: '+967',
  country: 'Yemen',
  isoCode: 'ye'
}, {
  countryCode: '+260',
  country: 'Zambia',
  isoCode: 'zm'
}, {
  countryCode: '+263',
  country: 'Zimbabwe',
  isoCode: 'zw'
}];
