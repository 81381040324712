import { useState } from 'react';

// Components
import Button from 'components/General/Button/Button';
import TextField from 'components/General/TextField/TextField';

import './CreditCard.scss';

// React payment inputs
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import toast from 'react-hot-toast';
import { CardData } from './CreditCard.types';
import { usePaymentInfo } from 'services/Payment/Queries/PaymentInfo/usePaymentInfo';
import DotsLoader from 'components/General/Loader/Loader';
import { useAdminPayClass } from 'services/Payment/Mutations/useAdminPayClass';
import { LeadsSlice, setPayment } from '../../../LeadsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';

export const CreditCard = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps } = usePaymentInputs();
  const [cardNumber, setCardNumber] = useState<number>();
  const [cvc, setCvc] = useState<number>();
  const [expiryDate, setExpiryDate] = useState<number>();
  const { mutateAsync: adminPayClass, isLoading: isLoadingAdminPayClass } = useAdminPayClass();
  const { data, isLoading: isLoadingPayment } = usePaymentInfo(leadsState.payment.classId);

  const pay = () => {
    const cardData: CardData = {
      classId: Number(leadsState.payment.classId),
      expMonth: Number(`${String(expiryDate).slice(0, 2)}`),
      expYear: Number(`20${String(expiryDate).slice(5, 7)}`),
      cvc: String(cvc),
      cardNumber: String(cardNumber),
    };
    if (!leadsState.payment.classId || !expiryDate || !cvc || !cardNumber) {
      toast.error('Please enter correct data.');
    } else {
      adminPayClass(cardData).then(() => {
        dispatch(setPayment({ modal: false }));
        queryClient.refetchQueries('lead-payment-info');
        queryClient.refetchQueries('leads-table-data');
        toast.success('Payment successful.');
      }).catch((err) => toast.error(err.response.errors?.[0].message));
    }
  };

  return (
    <div className="credit-card-container mb-2">
      <div className="credit-card-header">
        <h2>Add New Card</h2>
        {isLoadingPayment ? (
          <DotsLoader />
        ) : (
          <h4>{`You will pay $${data?.userGetPaymentInfo.finalAmount} for ${leadsState.payment.course} Course (${leadsState.payment.sessions} Sessions)`}</h4>
        )}
      </div>
      <div className="credit-card-inputs mb-3">
        <TextField
          label="Card holder name"
          placeholder="Holder Name"
          variant="rounded"
          color="light-gray"
        />

        <div className="card-number">
          <div className="card-input">
            <TextField
              label="Card number"
              placeholder="9999 9999 9999"
              variant="rounded"
              value={cardNumber}
              error={meta.isTouched && meta.erroredInputs.cardNumber}
              {...getCardNumberProps({ onChange: (e: any) => setCardNumber(e.target.value) })}
            />
          </div>
          <div className={`card-image ${(meta.isTouched && meta.erroredInputs.expiryDate) ? 'card-number-error' : ''}`}>
            <svg {...getCardImageProps({ images })} />
          </div>
        </div>

        <div className="cvc-expiry">
          <div className="expiry-date">
            <TextField
              label="Expiry date"
              placeholder="03/16"
              variant="rounded"
              value={expiryDate}
              error={meta.isTouched && meta.erroredInputs.expiryDate}
              {...getExpiryDateProps({ onChange: (e: any) => setExpiryDate(e.target.value) })}
            />
          </div>
          <div className="cvc">
            <TextField
              label="CVC"
              placeholder="123"
              variant="rounded"
              value={cvc}
              error={meta.isTouched && meta.erroredInputs.cvc}
              {...getCVCProps({ onChange: (e: any) => setCvc(e.target.value) })}
            />
          </div>

        </div>
      </div>
      <div>
        <Button
          color="primary"
          isLoading={isLoadingAdminPayClass}
          onClick={() => pay()}
        >
          CONFIRM PAYMENT
        </Button>
      </div>
      <div className="mt-1">
        <Button
          color="primary"
          onClick={() => {
            dispatch(setPayment({ ...leadsState.payment, chosePaymentMethod: false }));
          }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};
