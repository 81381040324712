// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { FolderLists } from './types';

export const useGetFolderLists = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<FolderLists, Error> = useQuery<FolderLists, Error>(['folder-lists'], async () => client.request(
    gql`
    query AdminGetFolderLists {
      adminGetFolderLists {
        id
        name
        task_count
      }
    }
    `
  ).catch((error) => console.error(error)));
  return data;
};
