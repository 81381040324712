// Types
import { AssignmentChart } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useGetAssignmentChart = (classId: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<AssignmentChart, Error> = useQuery<AssignmentChart, Error>(['assignment-chart'], async () => client.request(
    gql`
    query UserAssignmentsChart($classId: Float!) {
      userAssignmentsChart(classId: $classId) {
        marks
        names
      }
    }
    `,
    {
      classId
    }
  ).catch((error) => console.error(error)));
  return data;
};
