import { TableColumn } from 'components/Dashboard/Table/Table.types';
import moment from 'moment-timezone';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useDeleteCallLog } from 'services/AdminDashboard/Leads/Logs/Mutations/DeleteCallLog/useDeleteCallLog';
import { useCallLogs } from 'services/AdminDashboard/Leads/Logs/Queries/CallLogs/useCallLogs';
import leads, { LeadsSlice } from '../../LeadsSlice';

export const useLogic = () => {
  const { leadId } = useParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);
  const { toggleCallLogModal, setCallLogId } = leads.actions;
  const { data, isLoading } = useCallLogs(Number(leadId));
  const { mutateAsync: deleteLog, isLoading: isLoadingDeleteLog } = useDeleteCallLog();
  const { toggleUpdateCallLogModal } = leads.actions;
  const Columns: TableColumn[] = [
    {
      value: '#',
      sortable: true,
      key: 'id',
    },
    {
      value: 'Date',
      sortable: true,
      key: 'date'
    },
    {
      value: 'Note',
      sortable: true,
      key: 'note'
    },
    {
      value: 'Future call',
      sortable: true,
      key: 'future call',
    },
    {
      value: 'Created by',
      sortable: true,
      key: 'created by'
    },
    {
      value: '',
      sortable: true,
      key: 'delete'
    },
  ];

  const deleteCallLog = (logId: number) => {
    dispatch(setCallLogId(logId));
    deleteLog(logId).then((res) => {
      queryClient.refetchQueries('call-logs');
      toast.success(res.adminDeleteCallLog.message);
    });
  };

  const futureCallLabel = (recordDate: string, contacted: boolean) => {
    if (new Date(recordDate) < new Date()) {
      return {
        date: `${moment(recordDate).format('YYYY-MM-DD, HH:mm A')} (Overdue)`,
        color: 'fuchsia',
      };
    }
    if (contacted) {
      return {
        date: `${moment(recordDate).format('YYYY-MM-DD, HH:mm A')} (Done)`,
        color: 'primary',
      };
    }

    return {
      date: `${moment(recordDate).format('YYYY-MM-DD, HH:mm A')}`,
      color: 'primary',
    };
  };

  const handleUpdateLog = (logId: number) => {
    dispatch(toggleUpdateCallLogModal(true));
    dispatch(setCallLogId(logId));
  };

  return {
    Columns,
    data,
    isLoading,
    deleteCallLog,
    isLoadingDeleteLog,
    leadsState,
    toggleCallLogModal,
    dispatch,
    futureCallLabel,
    handleUpdateLog,
    toggleUpdateCallLogModal,
  };
};
