// Types
import { ButtonBaseProps as IProps } from './ButtonBase.types';

// Styling
import './ButtonBase.scss';

export const ButtonBase = ({ children, onClick, ...rest }: IProps) => (
  <div
    className="button-base"
    role="button"
    onKeyDown={() => {}}
    tabIndex={0}
    onClick={onClick}
    {...rest}
  >
    {children}
  </div>
);
