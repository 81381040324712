import Paper from '@mui/material/Paper';
import {
  ViewState,
  GroupingState,
  IntegratedGrouping,
} from '@devexpress/dx-react-scheduler';
import { styled } from '@mui/material/styles';

import {
  Scheduler,
  Resources,
  Appointments,
  GroupingPanel,
  Toolbar,
  ViewSwitcher,
  WeekView,
  MonthView,
  DateNavigator,
  DayView,
  TodayButton,
  CurrentTimeIndicator,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useLogic } from './Calendar.logic';
import { Filters } from './Filters/Filters';
import Modal from 'components/General/Modal/Modal';
import { OverlayLoader } from 'components/General/Loader/Loader';
import AppointmentView from './AppointmentView/AppointmentView';
import TeacherDashboard from '../Dashboard';
import moment from 'moment-timezone';
import { Statistics } from './Statistics/Statistics';
import Button from 'components/General/Button/Button';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import Dropdown from 'components/General/Dropdown/Dropdown';

export const Calendar = () => {
  const {
    height,
    setCurrentDate,
    grouping,
    resources,
    data,
    toggleFiltersModal,
    toggleAppointmentViewModal,
    teacherCalendarState,
    dispatch,
    generateFilter,
    isLoading,
    setPlanData,
    setSession,
    isFetching,
    setView,
    view,
    timeline,
  } = useLogic();

  const PREFIX = 'Demo';

  const classes = {
    cell: `${PREFIX}-cell`,
    content: `${PREFIX}-content`,
    text: `${PREFIX}-text`,
    sun: `${PREFIX}-sun`,
    cloud: `${PREFIX}-cloud`,
    rain: `${PREFIX}-rain`,
    sunBack: `${PREFIX}-sunBack`,
    cloudBack: `${PREFIX}-cloudBack`,
    rainBack: `${PREFIX}-rainBack`,
    opacity: `${PREFIX}-opacity`,
    appointment: `${PREFIX}-appointment`,
    apptContent: `${PREFIX}-apptContent`,
    flexibleSpace: `${PREFIX}-flexibleSpace`,
    flexContainer: `${PREFIX}-flexContainer`,
    tooltipContent: `${PREFIX}-tooltipContent`,
    tooltipText: `${PREFIX}-tooltipText`,
    title: `${PREFIX}-title`,
    icon: `${PREFIX}-icon`,
    circle: `${PREFIX}-circle`,
    textCenter: `${PREFIX}-textCenter`,
    dateAndTitle: `${PREFIX}-dateAndTitle`,
    titleContainer: `${PREFIX}-titleContainer`,
    container: `${PREFIX}-container`,
    flexSpece: 'FlexibleSpace-flexibleSpace',
    button: 'css-1rit21i-MuiInputBase-root-MuiOutlinedInput-root',
    buttonContainer: 'MuiInputBase-root-MuiOutlinedInput-root',
  };

  const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
    [`&.${classes.flexibleSpace}`]: {
      flex: 'none',
    },
    [`& .${classes.flexContainer}`]: {
      display: 'flex',
      alignItems: 'left',
      '.Switcher-inputRoot MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary css-1rit21i-MuiInputBase-root-MuiOutlinedInput-root': {
        borderRadius: '26px',
      }
    },
  }));

  const FlexibleSpace = (({ ...restProps }) => (
    <StyledToolbarFlexibleSpace
      {...restProps}
      className={classes.flexibleSpace}
    >
      <div className={classes.flexContainer}>
        <Filters />
      </div>
    </StyledToolbarFlexibleSpace>
  ));

  return (
    <TeacherDashboard>
      <div className="teacher-calendar-container">
        {
        isLoading ? (
          <OverlayLoader />
        ) : (
          <>
            {isFetching && <OverlayLoader />}
            <div className="teacher-stat-container">
              <Statistics />
            </div>
            <Paper>
              <Scheduler
                data={data}
                height={660 - height}
              >
                <ViewState
                  defaultCurrentDate={teacherCalendarState.currentDate}
                  onCurrentDateChange={(date) => dispatch(setCurrentDate(date))}
                  currentViewName={view}
                />
                <GroupingState
                  grouping={grouping}
                />
                <DayView
                  startDayHour={timeline?.min}
                  endDayHour={timeline?.max}
                />
                <WeekView
                  startDayHour={timeline?.min}
                  endDayHour={timeline?.max}
                />
                <MonthView />
                <Appointments appointmentComponent={(props) => (
                  <>
                    { new Date(props.data.startDate).getMonth() === teacherCalendarState.currentDate.getMonth()
                      && (
                      <ButtonBase
                        tagName="div"
                        className={`appointment-container ${moment().isAfter(props.data.startDate) ? 'old' : ''} ${props.data.isAccessible ? '' : 'blocked'}`}
                        onClick={() => {
                          setSession(props.data);
                          dispatch(toggleAppointmentViewModal(true));
                          dispatch(setPlanData({
                            sessionID: Number(props.data.id),
                            sessionNumber: props.data.sessionNumber,
                            sessionDate: moment(props.data.startDate).toLocaleString(),
                            session: props.data as any,
                          }));
                        }}
                      >
                        <div className="appointment-wrapper">
                          <div className="appointment-data">
                            {props.data.title}
                          </div>
                          {view === 'Day' && (
                            <div>
                              <Button
                                color="primary"
                                variant="small"
                                disabled={!props.data?.link}
                                onClick={() => window.open(props.data?.link, '_blank')}
                              >
                                Open
                              </Button>
                            </div>
                          )}
                        </div>
                      </ButtonBase>
                      ) }
                  </>
                )}
                />

                <Resources
                  data={resources}
                  mainResourceName="teacherId"
                />

                <IntegratedGrouping />
                <GroupingPanel />
                <Toolbar
                  flexibleSpaceComponent={FlexibleSpace}
                />
                <ViewSwitcher switcherComponent={(props) => (
                  <div>
                    <Dropdown
                      options={props.availableViews.map((item) => item.name)}
                      value={view}
                      onChange={(e) => {
                        props.onChange(e.target.value);
                        setView(e.target.value);
                      }}
                    />
                  </div>
                )}
                />
                <DateNavigator />
                <TodayButton buttonComponent={(props) => (
                  <div className="today-button">
                    <Button
                      color="white"
                      onClick={() => {
                        props.setCurrentDate(new Date());
                        setView('Day');
                      }}
                      variant="small"
                    >
                      Today
                    </Button>
                  </div>
                )}
                />
                <CurrentTimeIndicator />
              </Scheduler>
            </Paper>
          </>
        )
      }
        <Modal
          isClosble
          isOpen={teacherCalendarState.filtersModal}
          onRequestClose={() => dispatch(toggleFiltersModal(false))}
        >
          {generateFilter(teacherCalendarState.filter?.selectedFilter)}
        </Modal>
        <Modal
          isClosble
          isOpen={teacherCalendarState.appointmentViewModal}
          onRequestClose={() => dispatch(toggleAppointmentViewModal(false))}
        >
          <AppointmentView />
        </Modal>
      </div>
    </TeacherDashboard>
  );
};
