import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useUploadReceipt = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ attachmentsIds, classId }: { attachmentsIds: number[], classId?: number }) => client.request(
    gql`
    mutation CreateUserCashTrans($attachmentsIds: [Float!]!, $classId: Float!) {
      createUserCashTrans(attachmentsIds: $attachmentsIds, classId: $classId) {
        id
        status
        attachments {
          id
          attachment
        }
      }
    }
    `,
    {
      attachmentsIds,
      classId,
    }
  ));
  return Mutation;
};
