import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SessionTest } from 'services/AdminDashboard/PersonalInfo/CourseActivity/ProgressDiagram/types';
import { useProgressDiagram } from 'services/AdminDashboard/PersonalInfo/CourseActivity/ProgressDiagram/useProgressDiagram';

export const useLogic = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isFetching: isLoading } = useProgressDiagram(Number(id));
  const [selected, setSelected] = useState<{ id?: number, name?: string }>({});
  const [course, setCourse] = useState<SessionTest>();

  useEffect(() => {
    if (data) {
      setSelected({ id: data.adminGetUserClassTests?.[0]?.id, name: data.adminGetUserClassTests?.[0]?.plan?.course?.name });
      setCourse(data.adminGetUserClassTests?.[0]);
    }
  }, [data]);

  useEffect(() => {
    console.table([data?.adminGetUserClassTests?.[0]?.dates, data?.adminGetUserClassTests?.[0]?.marksArray]);
  }, [course]);

  const handleSelect = (cardId?: number, cardName?: string) => {
    setSelected({ id: cardId, name: cardName });
    setCourse(data?.adminGetUserClassTests?.find((item) => item.id === cardId));
  };

  return {
    handleSelect,
    selected,
    isLoading,
    data,
    course,
    navigate
  };
};
