// Components
import { CreatePost } from 'components/Dashboard/CreatePost/CreatePost';
import { PostContainer } from 'components/Dashboard/PostContainer/PostContainer';
import { PostEvent } from 'components/Dashboard/PostEvent/PostEvent';
import { PostComment } from 'components/Dashboard/PostComment/PostComment';
import { UpcomingEvent } from 'components/Dashboard/UpcomingEvent/UpcomingEvent';
import { CourseLayout } from 'layouts/CourseLayout/CourseLayout';

// Types
import { v4 as uuid } from 'uuid';

// Logic
import { useLogic } from './Stream.logic';

// Style
import './Stream.scss';
import DotsLoader from 'components/General/Loader/Loader';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload as DownloadIcon,
} from '@fortawesome/free-solid-svg-icons';
import DocumentIcon from 'assets/images/document-icon.svg';
import { setAssignment } from 'pages/Dashboard/DashboardSlice';
import SideBarImage from 'assets/images/student-side.svg';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment-timezone';
import Button from 'components/General/Button/Button';
import Modal from 'components/General/Modal/Modal';
import { RateSession } from 'pages/Dashboard/Student/Classes/RateSession/RateSession';

export const Stream = () => {
  const {
    posts,
    isLoadingPosts,
    downlowadRef,
    handleDeletePost,
    handleDeleteComment,
    isImage,
    handleEdit,
    streamState,
    handleSetEditable,
    dispatch,
    userNearestAssignment,
    userNearestPracticeTest,
    adminNearestAssignment,
    adminNearestPracticeTest,
    teacherNearestSession,
    isAdmin,
    isTeacher,
    userInfo,
    isLoadingAdminNearest,
    isLoadingUserNearest,
    handleClick,
    setFeedbackData,
    dashboardState
  } = useLogic();

  return (
    <CourseLayout sidebarContent={(
      <div className="sidebar-events-container">
        {(isAdmin || isTeacher) ? (
          <>
            {isLoadingAdminNearest() && (
              <DotsLoader />
            )}
            <div className="sidebar-event-container">
              {(teacherNearestSession?.teacherSessions && teacherNearestSession?.teacherSessions.length > 0) && (
                <>
                  <h2 className="ml-1">Upcoming session</h2>
                  <UpcomingEvent
                    disabled={teacherNearestSession?.teacherSessions?.[0]?.teacherLink === null}
                    buttonText="Open"
                    title={`${teacherNearestSession?.teacherSessions?.[0]?.class.plan.course.name} - Session ${teacherNearestSession?.teacherSessions?.[0]?.sessionNumber}`}
                    date={moment(teacherNearestSession?.teacherSessions?.[0]?.dueAt).format('YYYY-MM-DD')}
                    time={`${moment(teacherNearestSession?.teacherSessions?.[0]?.dueAt).format('h:mm')} - ${moment(teacherNearestSession?.teacherSessions?.[0]?.dueAt).add(1, 'h').format('h:mm a')}`}
                    onClick={() => handleClick()}
                  />
                </>
              )}
            </div>
            <div className="sidebar-event-container">
              {(adminNearestAssignment?.adminNearestAss && adminNearestAssignment?.adminNearestAss.length > 0) && (
                <>
                  <h2 className="ml-1">Last assignment</h2>
                  <UpcomingEvent
                    title={adminNearestAssignment.adminNearestAss?.[0]?.name}
                    date={adminNearestAssignment?.adminNearestAss?.[0]?.dueDate}
                    onClick={() => dispatch(setAssignment({ assignment: adminNearestAssignment?.adminNearestAss?.[0], tab: 'Assignments' }))}
                  />
                </>
              )}
            </div>
            <div className="sidebar-event-container">
              {(adminNearestPracticeTest?.adminNearestTest && adminNearestPracticeTest?.adminNearestTest.length > 0) && (
                <>
                  <h2 className="ml-1">Last practice test</h2>
                  <UpcomingEvent
                    title={adminNearestPracticeTest?.adminNearestTest?.[0]?.name}
                    date={adminNearestPracticeTest?.adminNearestTest?.[0]?.dueDate}
                    onClick={() => dispatch(setAssignment({ assignment: adminNearestPracticeTest?.adminNearestTest?.[0], tab: 'Practice tests' }))}
                  />
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {isLoadingUserNearest() && (
            <DotsLoader />
            )}
            <div className="sidebar-event-container">
              <img
                src={SideBarImage}
                alt=""
              />
            </div>
            <div className="sidebar-event-container">
              {(userNearestAssignment?.userNearestAss && userNearestAssignment?.userNearestAss.length > 0) && (
                <>
                  <h2 className="ml-1">Last Assignment</h2>
                  <UpcomingEvent
                    title={userNearestAssignment?.userNearestAss?.[0]?.name}
                    date={userNearestAssignment?.userNearestAss?.[0]?.dueDate}
                    onClick={() => dispatch(setAssignment({ assignment: userNearestAssignment.userNearestAss?.[0], tab: 'Assignments' }))}
                  />
                </>
              )}
            </div>
            <div className="sidebar-event-container">
              {(userNearestPracticeTest?.userNearestTest && userNearestPracticeTest?.userNearestTest.length > 0) && (
                <>
                  <h2 className="ml-1">Last practice test</h2>
                  <UpcomingEvent
                    title={userNearestPracticeTest?.userNearestTest?.[0]?.name}
                    date={userNearestPracticeTest?.userNearestTest?.[0]?.dueDate}
                    onClick={() => dispatch(setAssignment({ assignment: userNearestPracticeTest?.userNearestTest?.[0], tab: 'Practice tests' }))}
                  />
                </>
              )}
            </div>
          </>
        )}

      </div>
  )}
    >
      <div className="class-body-container">
        <CreatePost
          name={`${userInfo?.userGetInfo.first_name || ''} ${userInfo?.userGetInfo.last_name || ''}`}
          image={userInfo?.userGetInfo.picture}
        />
        {!isLoadingPosts ? (
          posts?.userClassPosts?.map((post) => (
            <PostContainer
              isOwner={userInfo?.userGetInfo.id === post.postedBy?.id || (isAdmin || isTeacher)}
              setEditable={handleSetEditable}
              editable={streamState.editablePost}
              onEdit={() => handleEdit(post)}
              onRemove={() => handleDeletePost(post.id)}
              key={post.id}
              id={post.id}
              name={`${post.postedBy?.first_name} ${post.postedBy?.last_name}`}
              time={post.createdAt}
              userImage={userInfo?.userGetInfo.picture || ''}
              ownerImage={post.postedBy?.picture || ''}
              link={(
                post.link && (
                  <>
                    <p>{post.text}</p>
                    <div className="button-container">
                      <Button
                        color="primary"
                        onClick={() => window.open(String(post.link), '_blank')}
                      >
                        Join
                      </Button>
                    </div>
                  </>
                )
              )}
              text={(
                (post.text && !post.link) && (
                  <>
                    <h4>{post.text}</h4>
                  </>
                )
              )}
              event={(
                <>
                  {
                    post.assignment && (
                      <PostEvent
                        title={post.assignment?.name}
                        body={post.assignment?.details}
                        onClick={() => dispatch(setAssignment({ assignment: post.assignment, tab: 'Assignments' }))}
                      />
                    )
                  }
                  {
                      post.test && (
                      <PostEvent
                        title={post.test?.name}
                        body={post.test?.details}
                        onClick={() => dispatch(setAssignment({ assignment: post.test, tab: 'Practice tests' }))}
                      />
                      )
                  }
                  {
                    post.attachments && post.attachments.length > 0 && (
                      <div className="attachments-container">
                        <h3>Uploaded files</h3>
                        <div className="images-container">
                          {post.attachments?.map((attachment) => (
                            <div
                              className="image-container"
                              key={uuid()}
                            >
                              <FontAwesomeIcon
                                icon={DownloadIcon}
                                className="icon"
                                color="#97ACCC"
                                display={12}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  downlowadRef.current?.click();
                                }}
                              />
                              <ButtonBase
                                tagName="div"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(attachment.attachment, '_blank');
                                }}
                              >
                                <a
                                  download
                                  ref={downlowadRef}
                                >
                                  {isImage(attachment.attachment) ? (
                                    <img
                                      src={attachment.attachment}
                                      alt=""
                                    />
                                  ) : (
                                    <div className="document-icon">
                                      <img
                                        src={DocumentIcon}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                </a>
                              </ButtonBase>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  }
                </>
              )}
              comments={
                (post.comments && post.comments?.length > 0) && (
                  <div className="post-comments">
                    {
                      post?.comments?.map((comment) => (
                        <PostComment
                          isOwner={userInfo?.userGetInfo.id === comment.commentedBy.id || (isAdmin || isTeacher)}
                          image={comment.commentedBy.picture}
                          name={`${comment.commentedBy.first_name} ${comment.commentedBy.last_name}`}
                          time={comment.createdAt}
                          messageBody={comment.text}
                          key={comment.id}
                          onRemove={() => handleDeleteComment(comment.id, post.id)}
                        />
                      ))
                    }
                  </div>

                )
              }
            />
          ))
        ) : (
          <DotsLoader />
        )}
      </div>
      <div className="editor-container-hidden">
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            height: 300,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar: 'undo redo | formatselect |'
            + 'bold italic underline link | alignleft aligncenter '
            + 'alignright alignjustify',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
      </div>
      <Modal
        isClosble
        isOpen={dashboardState.feedbacks.modal}
        onRequestClose={() => dispatch(setFeedbackData({ modal: false }))}
      >
        <RateSession />
      </Modal>
    </CourseLayout>
  );
};
