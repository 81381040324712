// Styling
import './SimpleStatisticsCard.scss';

// Types
import { SimpleStatisticsCard as IProps } from './SimpleStatisticsCard.types';

export const SimpleStatisticsCard = ({ image, number, description }: IProps) => (
  <div className="simple-statistics-container">
    <div className="left-container">
      <img
        src={image}
        alt=""
      />
    </div>
    <div className="middle-container">
      <h2>
        {number}
      </h2>
    </div>
    <div className="right-container">
      <h3>
        {description}
      </h3>
    </div>
  </div>
);
