// Types
import { TeacherCourseCard as IProps } from './TeacherCourseCard.types';

// Components
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV as OptionsIcon,
  faArchive as ArchiveIcon,
  faStopCircle as CancelIcon,
  faInfoCircle as InfoIcon
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';

// Styling
import './TeacherCourseCard.scss';
import { useEffect, useState } from 'react';
import { useRoles } from 'assets/utils/useRoles';
import { CircleLoader } from 'components/General/Loader/Loader';
import { DashboardSlice } from 'pages/Dashboard/DashboardSlice';
import { useSelector } from 'react-redux';

export const TeacherCourseCard = ({ courseName, teacherName, studentName, status, isAccessible, courseID, options, onClick, onCancel, onArchive, isLoading, id }: IProps) => {
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const [showOptions, setShowOptions] = useState(false);
  const { isAdmin } = useRoles();

  const onRequestClose = () => {
    setShowOptions(false);
  };

  useEffect(() => {
    window.addEventListener('click', onRequestClose);
    return () => {
      window.removeEventListener('click', onRequestClose);
    };
  }, []);

  return (
    <ButtonBase
      tagName="div"
      onClick={onClick}
    >
      <div className="teacher-course-card-container">
        <div className={`teacher-course-card ${(courseName === 'GMAT' && 'mustard') || (courseName === 'IELTS' && 'fuchsia') || (courseName === 'SAT' && 'primary')}`}>
          <div className="teacher-course-card-top">
            <div className="top-container">
              <h4>{courseName}</h4>
              <span>
                <p className="sub-text">{status}</p>
              </span>
              {isAccessible && (
                <>
                  <ReactTooltip />
                  <FontAwesomeIcon
                    icon={InfoIcon}
                    className="icon"
                    color="#fbaebc"
                    display={12}
                    data-tip="User cannot access this class"
                    data-place="top"
                    data-type="warning"
                    data-effect="solid"
                  />
                </>
              )}
            </div>
            { isAdmin && (
              <>
                {isLoading && (dashboardState.cardId === id) ? (
                  <CircleLoader color="white" />
                ) : (
                  <div className="options-container">
                    <FontAwesomeIcon
                      icon={OptionsIcon}
                      className="options-icon"
                      display={12}
                      onClick={(e) => {
                        setShowOptions(!showOptions);
                        e.stopPropagation();
                        options();
                      }}
                    />
                    <div className={showOptions ? 'show-tooltip' : 'hide-tooltip'}>
                      <div className="tooltip">
                        <ButtonBase
                          tagName="div"
                          onClick={(e) => {
                            e.stopPropagation();
                            onArchive();
                          }}
                        >
                          <div className="edit">
                            <FontAwesomeIcon
                              icon={ArchiveIcon}
                              className="icon"
                              color="#97ACCC"
                              display={12}
                            />
                            <p>Archive</p>
                          </div>
                        </ButtonBase>
                        <ButtonBase
                          tagName="div"
                          onClick={(e) => {
                            e.stopPropagation();
                            onCancel();
                          }}
                        >
                          <div className="delete">
                            <FontAwesomeIcon
                              icon={CancelIcon}
                              className="icon"
                              color="#97ACCC"
                              display={12}
                            />
                            <p>Cancel</p>
                          </div>
                        </ButtonBase>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="teacher-course-card-bottom">
            <div className="names">
              {teacherName && <p className="sub-text">{`Teacher: ${teacherName}`}</p>}
              {studentName && <p className="sub-text">{`Student: ${studentName}`}</p>}
            </div>
            <p className="sub-text">{`#${courseID}`}</p>
          </div>

        </div>
      </div>
    </ButtonBase>
  );
};
