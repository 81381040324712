import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TeacherCalendarsSlice } from '../CalendarSlice';

export const useLogic = () => {
  const dispatch = useDispatch();
  const teacherCalendarState = useSelector((state: { teacherCalendar: TeacherCalendarsSlice }) => state.teacherCalendar);

  const filters = [
    { filter: 'Subjects', active: teacherCalendarState.filter?.courses },
    { filter: 'Country', active: teacherCalendarState.filter?.countries },
    { filter: 'Class ID', active: teacherCalendarState.filter?.classesIds },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filters?.[0]?.filter);

  return {
    filters,
    selectedFilter,
    setSelectedFilter,
    dispatch,
    teacherCalendarState
  };
};
