import { gql } from 'graphql-request';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useReportSession = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ comment, status, sessionId }: { comment?: string, status?: string, sessionId?: number }) => client.request(
    gql`
    mutation ReportSession($comment: String!, $sessionId: Float!, $status: String!) {
      reportSession(comment: $comment, sessionId: $sessionId, status: $status) {
        message
      }
    }
    `,
    {
      comment,
      sessionId,
      status,
    }
  ).catch((error) => toast(error.response.errors[0].message)), {
    onSuccess: (data: any) => { toast.success(data?.reportSession.message); }
  });
  return Mutation;
};
