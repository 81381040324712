import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Filters = {
  courses?: string[],
  status?: string[],
  search?: string,
  searchName?: string,
  selectedFilter?: string
};

export type PaymentConfirmation = {
  id?: number;
  date?: string;
  attachments?: [
    {
      id: number,
      attachment: string
    },
  ]
};

export type Assignment = {
  modal: boolean;
  assignment?: {
    id?: number,
    name?: string,
    details?: string,
    status?: string,
    dueDate?: string,
    fullMark?: number,
    classId?: number,
    receivedMark?: number,
    link?: string,
    attachments?: [
      {
        id: number,
        attachment: string
        teacherAttachment: boolean
      },
    ]
  }
};
export interface GiveMark {
  mark?: number
  originalMark?: number
}
export interface HomeworkSlice {
  filter: Filters;
  filtersModal: boolean;
  paymentConfirmation: PaymentConfirmation;
  paymentConfirmationModal: boolean;
  assignment: Assignment;
  mark: GiveMark
}

const initialState: HomeworkSlice = {
  filter: {},
  filtersModal: false,
  paymentConfirmation: {},
  paymentConfirmationModal: false,
  assignment: {
    modal: false,
  },
  mark: {}
};

const homework = createSlice({
  name: 'homework',
  initialState,
  reducers: {
    toggleFiltersModal: (state, { payload }: PayloadAction<boolean>) => {
      state.filtersModal = payload;
    },
    setFilter: (state, { payload }: PayloadAction<Filters>) => {
      state.filter = { ...state.filter, ...payload };
    },
    setPaymentConfirmation: (state, { payload }: PayloadAction<PaymentConfirmation>) => {
      state.paymentConfirmation = { ...state.paymentConfirmation, ...payload };
    },
    setPaymentConfirmationsModal: (state, { payload }: PayloadAction<boolean>) => {
      state.paymentConfirmationModal = payload;
    },
    setAssignment: (state, { payload }: PayloadAction<Assignment>) => {
      state.assignment = { ...state.assignment, ...payload };
    },
    setMark: (state, { payload }: PayloadAction<GiveMark>) => {
      state.mark = { ...state.mark, ...payload };
    }
  },
});

export default homework;
export const {
  setFilter,
  toggleFiltersModal,
  setPaymentConfirmation,
  setPaymentConfirmationsModal,
  setAssignment,
  setMark
} = homework.actions;
