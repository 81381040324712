import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useCreateCallLog = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ leadId, note, futureCall }: { leadId?: number, note?: string, futureCall?: Date }) => client.request(
    gql`
    mutation AdminCreateCallLog($leadId: Float!, $note: String, $futureCall: DateTime) {
      adminCreateCallLog(leadId: $leadId, note: $note, futureCall: $futureCall) {
        id
        createdById
        leadId
        contacted
        note
        futureCall
        createdAt
        updatedAt
      }
    }
    `,
    {
      leadId,
      note,
      futureCall: futureCall && futureCall
    }
  ));
  return Mutation;
};
