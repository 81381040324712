import { useAuth } from 'routes/Auth/AuthProvider';

export const useRoles = () => {
  const { user } = useAuth();

  const isTeacher = user?.user.is_admin && user?.user.is_teacher;
  const isAdmin = user?.user.is_admin && !user?.user.is_teacher;

  return {
    isAdmin,
    isTeacher
  };
};
