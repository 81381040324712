// Styling
import { Marks } from '../PracticeTestCard/PracticeTestCard';
import './StatTestCard.scss';

// // Types
import { StatTestCard as IProps } from './StatTestCard.types';

export const StatTestCard = ({ selected, title, date, score, course }: IProps) => (
  <div className={`statistics-test-container ${selected ? 'selected' : ''}`}>
    <div className="upper-container">
      <div className="left">
        <p className={`${selected ? 'selected' : ''}`}>{title}</p>
      </div>
      <div className="right">
        <p className={`${selected ? 'selected' : ''}`}>{date}</p>
      </div>
    </div>
    <div className="bottom-container">
      <Marks
        marks={score}
        course={String(course)}
      />
    </div>
  </div>
);
