// Types
import { ClickUpTaskCard as IProps } from './ClickUpTaskCard.types';

// Styling
import './ClickUpTaskCard.scss';

// Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck as back } from '@fortawesome/free-solid-svg-icons';

// ButtonBase
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { CircleLoader } from 'components/General/Loader/Loader';

export const ClickUpTaskCard = ({ done, color, data, onClick, isLoading }: IProps) => (
  <div className="clickup-task-container">
    { isLoading ? (
      <CircleLoader />
    ) : (
      <ButtonBase
        tagName="button"
        onClick={onClick}
      >
        <div className={done ? 'circle' : 'simple-circle'}>
          {
          done && (

            <FontAwesomeIcon
              icon={back}
              className="icon"
              color="#FFF"
            />
          )
        }
        </div>
      </ButtonBase>
    )}
    <div className="height-container">
      <div
        className="color-column"
        style={{ backgroundColor: `${color}` }}
      />
      <div className="task-container">
        <p>{data}</p>
      </div>

    </div>

  </div>
);
