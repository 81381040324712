import { useRoles } from 'assets/utils/useRoles';
import { TableColumn } from 'components/Dashboard/Table/Table.types';
import { SessionSlice, setRescheduleModal } from 'pages/Dashboard/Course/Session/SessionsSlice';
import onboarding, { OnboardingSlice } from 'pages/Onboarding/OnboardingSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { SessionRecording } from 'services/AdminDashboard/PersonalInfo/SessionRecordings/types';
import { useSessionRecordings } from 'services/AdminDashboard/PersonalInfo/SessionRecordings/useSessionRecordings';

export const useLogic = () => {
  const { id } = useParams();
  const { isAdmin } = useRoles();
  const navigate = useNavigate();
  const { data, isFetching: isLoading } = useSessionRecordings(Number(id));
  const [toggleSessions, setToggleSessions] = useState('Sessions');
  const [selected, setSelected] = useState<{ id?: number, name?: string }>({});
  const [course, setCourse] = useState<SessionRecording>();
  const dispatch = useDispatch();
  const sessionState = useSelector((state: { sessions: SessionSlice }) => state.sessions);
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const [acuityTypeId, setAcuityTypeId] = useState<number>();
  const [duration, setDuration] = useState(2);
  const Columns: TableColumn[] = [
    {
      value: '#',
      sortable: true,
      key: 'id',
    },
    {
      value: 'Course',
      sortable: true,
      key: 'course',
    },
    {
      value: 'Session number',
      sortable: true,
      key: 'session number'
    },
    {
      value: 'Date',
      sortable: true,
      key: 'date'
    },
    {
      value: toggleSessions === 'Recordings' ? 'Session link' : 'Action',
      sortable: true,
      key: 'action',
      align: toggleSessions === 'Recordings' ? 'left' : 'center'
    },
  ];
  const appointmentTypeIds: any = {
    IELTS: {
      0.5: {
        id: 37361247,
        time: '30 minutes'
      },
      1: {
        id: 32390757,
        time: '1 hours'
      },
      1.5: {
        id: 37361257,
        time: '1.5 hours'
      },
      2: {
        id: 37361273,
        time: '2 hours'
      },
      2.5: {
        id: 37361913,
        time: '2.5 hours'
      },
      3: {
        id: 37361924,
        time: '3 hours'
      }
    },
    SAT: {
      0.5: {
        id: 37360737,
        time: '30 minutes'
      },
      1: {
        id: 32366529,
        time: '1 hours'
      },
      1.5: {
        id: 37361009,
        time: '1.5 hours'
      },
      2: {
        id: 37361035,
        time: '2 hours'
      },
      2.5: {
        id: 37362044,
        time: '2.5 hours'
      },
      3: {
        id: 37362059,
        time: '3 hours'
      }
    },
    GMAT: {
      0.5: {
        id: 37361235,
        time: '30 minutes'
      },
      1: {
        id: 32390362,
        time: '1 hours'
      },
      1.5: {
        id: 37361094,
        time: '1.5 hours'
      },
      2: {
        id: 37361116,
        time: '2 hours'
      },
      2.5: {
        id: 37361991,
        time: '2.5 hours'
      },
      3: {
        id: 37362009,
        time: '3 hours'
      }
    }
  };
  useEffect(() => {
    dispatch(onboarding.actions.setPlanData({
      id: data?.adminGetUserClassSessionsRecordings?.[0]?.planId,
      teacherID: data?.adminGetUserClassSessionsRecordings?.[0]?.sessions?.[0]?.teacher?.id,
      typeID: acuityTypeId,
      calendarID: data?.adminGetUserClassSessionsRecordings?.[0]?.sessions?.[0]?.teacher?.acuityCalendarId
    }));
  }, [data, acuityTypeId]);

  useEffect(() => {
    if (course?.plan.course.name) {
      dispatch(onboarding.actions.setPlanData({ ...onboardingState.plan, duration: appointmentTypeIds[course?.plan.course.name][duration]?.time }));
      setAcuityTypeId(appointmentTypeIds[course?.plan.course.name][duration]?.id);
    }
  }, [duration, course?.plan.course.name, selected]);

  useEffect(() => {
    if (data) {
      setSelected({ id: data.adminGetUserClassSessionsRecordings?.[0]?.id, name: data.adminGetUserClassSessionsRecordings?.[0]?.plan?.course?.name });
      setCourse(data.adminGetUserClassSessionsRecordings?.[0]);
    }
  }, [data]);

  const handleSelect = (cardId?: number, cardName?: string) => {
    setSelected({ id: cardId, name: cardName });
    setCourse(data?.adminGetUserClassSessionsRecordings?.find((item) => item.id === cardId));
  };

  return {
    Columns,
    data,
    isLoading,
    selected,
    course,
    handleSelect,
    toggleSessions,
    setToggleSessions,
    sessionState,
    dispatch,
    setRescheduleModal,
    onboarding,
    navigate,
    setDuration,
    isAdmin
  };
};
