// Types
import { ProfileCard as IProps } from './ProfileCard.types';
// Components
import MetaData from 'components/General/MetaData/MetaData';
// Icons
import { icons, colors } from '../Card/CONSTANTS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PrettyRating from 'pretty-rating-react';
import {
  faUniversity as universityIcon,
  faGraduationCap as educationIcon } from '@fortawesome/free-solid-svg-icons';
import { ChevronLeft, Clock } from 'react-feather';

// Styling
import './ProfileCard.scss';
import 'assets/scss/typography.scss';

const coursesColors = [
  'fuchsiaa',
  'mustard',
  'primary'
];

const ProfileCard = ({
  country,
  city,
  description,
  university,
  courses,
  education,
  rate,
  tutoringHours,
  user,
  onClickBack
}: IProps) => (
  <div className="teacher-profile">
    <header>
      <ChevronLeft
        className="back"
        onClick={onClickBack}
      />
      <h3>Teacher Profile</h3>
    </header>
    <div className="teacher-profile-details">
      <div className="avatar">
        <img
          src={user.picture ? user.picture : `url(https://eu.ui-avatars.com/api/?background=6371E0&color=fff&name=${user.first_name} ${user.last_name}&size=100)`}
          alt=""
        />
      </div>
      <div className="wrapper">
        <div className="userinfo">
          <h3>{`${user.first_name} ${user.last_name}`}</h3>
          <p>{`${country} ${city}`}</p>
          <PrettyRating
            value={rate || 0}
            icons={icons.star}
            colors={colors.star}
            max={5}
          />
        </div>
        <div className="metaData-container">
          <div className="metaData">
            <MetaData
              title="Tutor hours"
              subTitle={tutoringHours}
              icon={<Clock />}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="profile-card-body">
      <div className="about">
        <h3>About me</h3>
        <div className="about-body">
          <p>{description}</p>
        </div>
      </div>
      <div className="background">
        <h3>My educational background</h3>
        <div className="background-body">
          <div className="university-container">
            <FontAwesomeIcon icon={universityIcon} />
            <p>{university}</p>
          </div>
          <div className="education-container">
            <FontAwesomeIcon icon={educationIcon} />
            <p>{education}</p>
          </div>
        </div>
      </div>
      <div className="teacher-courses mb-3">
        <h3>What I teach</h3>
        <div className="courses">
          {courses?.map((course, index) => (
            <span
              key={course.id}
              className={coursesColors[index]}
            >
              {course.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default ProfileCard;
