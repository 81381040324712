import { useEffect, useState } from 'react';

// React Cookie
import { useCookies } from 'react-cookie';

// React Router
import { useNavigate } from 'react-router-dom';
import { DASHBOARD, NOTES, LEAD_PROFILE } from 'routes/CONSTANTS';
import { useLeadsTableData } from 'services/AdminDashboard/Leads/LeadsTableData/useLeadsTableData';
import { useUserInfo } from 'services/Onboarding/General/useOnboarding';

// React QUery

const useLogic = () => {
  const [cookies, setCookie] = useCookies(['delfina/sidenav/is_collapsed']);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(JSON.parse(cookies['delfina/sidenav/is_collapsed'] || false));
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { data: userInfo } = useUserInfo();

  // React Router
  const navigate = useNavigate();

  // Search query
  const { data: leads, isFetching: isSearching } = useLeadsTableData({ search: searchValue }, !!searchValue);

  // Show search modal when pressing ctrl + F
  useEffect(() => {
    const openSearch = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.altKey) && event.code === 'KeyF') {
        event.preventDefault();
        setShowSearch(true);
      }
    };

    window.addEventListener('keydown', openSearch);
  });

  const handleToggleCollapse = () => setIsCollapsed((prev) => {
    setCookie('delfina/sidenav/is_collapsed', !prev);
    return !prev;
  });

  const handleStudentNavigation = (id: number) => {
    navigate(`/${DASHBOARD}/${LEAD_PROFILE(`${id}/${NOTES}`)}`);
  };

  return {
    isCollapsed,
    showSearch,
    leads,
    isSearching,
    setSearchValue,
    handleToggleCollapse,
    setShowSearch,
    handleStudentNavigation,
    userInfo,
  };
};

export default useLogic;
