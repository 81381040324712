// Types
import { TableDataProps, TableData } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useTableData = ({ filterBy, search, limit, offset, sortBy, desc }: TableDataProps, enabled?: boolean) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TableData, Error> = useQuery<TableData, Error>(['table-data', { filterBy, search, limit, offset, sortBy, desc }], async () => client.request(
    gql`
    query AdminAllUsersStat($filterBy: filterByObject, $sortBy: String, $desc: Boolean, $search: String, $limit: Float, $offset: Float) {
      adminAllUsersStat(filterBy: $filterBy, sortBy: $sortBy, DESC: $desc, search: $search, limit: $limit, offset: $offset) {
        usersTotal
        allUsersStat {
          id
          name
          takenSessions
          missedSessions
          remainingSessions
          country
          status
          nearest
          picture
          createdAt
          improvementRate
          courses {
            name
            planId
            id
          }
          teachers {
            name
            id
          }
        } 
      }
    }
    `,
    {
      filterBy: {
        classStatus: filterBy?.classStatus,
        country: filterBy?.country,
        courses: filterBy?.courses,
        taughtBy: filterBy?.taughtBy,
        userStatus: filterBy?.userStatus,
        schools: filterBy?.schools,
        enrollment: filterBy?.enrollment,
        classOf: filterBy?.classOf,
        paymentMethod: filterBy?.paymentMethod,
        testsTimeInterval: filterBy?.testsTimeInterval,
        coursesTimeInterval: filterBy?.coursesTimeInterval
      },
      sortBy,
      desc,
      search,
      limit,
      offset
    }
  ), { enabled });
  return data;
};
