import SelectBox from 'components/General/SelectBox/SelectBox';
import './LocationFilter.scss';
import { countries } from 'assets/files/countries';
import Button from 'components/General/Button/Button';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { setFilter, toggleFiltersModal } from '../../StudentsSlice';

export const LocationFilter = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();

  const handleApplyFilters = () => {
    dispatch(setFilter({ country: value }));
    dispatch(toggleFiltersModal(false));
  };

  return (
    <div className="location-filter-container">
      <h2>Country</h2>
      <SelectBox
        styles={{ }}
        placeholder="UAE"
        options={countries}
        onChange={(e: any) => setValue(e?.label)}
      />
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
