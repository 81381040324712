import { gql } from 'graphql-request';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useDeletePost = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async (postId?: number) => client.request(
    gql`
    mutation DeletePost($postId: Float!) {
      deletePost(postId: $postId) {
        message
      }
    }
    `,
    {
      postId
    }
  ).catch((error) => toast(error.response.errors[0].message)), {
    onSuccess: (data: any) => { toast(data?.deletePost?.message); }
  });
  return Mutation;
};
