// Style
import './AllSessions.scss';

// Components
import { StudentRecordingCard } from 'components/Dashboard/StudentRecordingCard/StudentRecordingCard';
import { useLogic } from './AllSessions.logic';
import DotsLoader from 'components/General/Loader/Loader';
import { RescheduleSessionCard } from 'components/Dashboard/RescheduleSessionCard/RescheduleSessionCard';

import moment from 'moment-timezone';
import Modal from 'components/General/Modal/Modal';
import { ConfirmationModal } from './ConfirmationModal/ConfirmationModal';

export const AllSessions = () => {
  const {
    dispatch,
    data,
    isLoading,
    isAdmin,
    isAllowed,
    isTeacher,
    handleOpenClick,
    handleRescheduleClick,
    setSessionDuration,
    setSessionProblem,
    setRemoveSession,
    sessionState
  } = useLogic();

  return (
    <>
      {isLoading ? (
        <DotsLoader />
      ) : (
        <>
          {
            data?.classSessions?.filteredSessions.filter((item) => item.isRecorded).map((record) => (
              <StudentRecordingCard
                duration={record.duration}
                key={record.sessionNumber}
                sessionNumber={record.sessionNumber}
                active={record.published}
                onClick={() => record.recordingLink && window.open(record.recordingLink, '_blank')}
                date={record.dueAt}
                problem={isAdmin && record.problem}
                edit={isAdmin && record.problem}
                remove={isAdmin && record.problem}
                status={record.status}
                onProblemClick={() => {
                  dispatch(setSessionProblem({
                    modal: true,
                    duration: record.duration,
                    studentTimeIn: record.studentTimeIn,
                    teacherTimeIn: record.teacherTimeIn,
                    sessionId: record.id,
                    sessionNumber: record.sessionNumber,
                    studentReport: record.studentReport,
                    teacherReport: record.teacherReport
                  }));
                }}
                onEditClick={() => {
                  dispatch(setSessionDuration({
                    modal: true,
                    sessionId: record.id,
                    duration: record.duration
                  }));
                }}
                onRemoveClick={() => dispatch(setRemoveSession({ modal: true, sessionId: record.id }))}
              />
            ))
          }
          {
            data?.classSessions?.filteredSessions.filter((item) => !item.isRecorded).map((record) => (
              <RescheduleSessionCard
                duration={record.duration}
                isValidLink={(!!record?.studentLink && record.status === 'Not Started') || (!!record?.teacherLink && record.status === 'Not Started') || (!!record?.adminLink && record.status === 'Not Started')}
                canReschedule={isAdmin || isTeacher || isAllowed}
                key={record.id}
                sessionNumber={record.sessionNumber}
                date={moment(record.acuitySession.datetime).format('MM/DD/YYYY')}
                time={moment(record.acuitySession.datetime).format('hh:mm A')}
                status={(moment(record.acuitySession.datetime).isBefore()) ? record.status : ''}
                onOpenClick={() => handleOpenClick(record)}
                onRescheduleClick={() => handleRescheduleClick(record)}
                problem={isAdmin && record.problem}
                edit={isAdmin && record.problem}
                remove={isAdmin && record.problem}
                disable={isTeacher}
                onProblemClick={() => {
                  dispatch(setSessionProblem({
                    modal: true,
                    duration: record.duration,
                    studentTimeIn: record.studentTimeIn,
                    teacherTimeIn: record.teacherTimeIn,
                    sessionId: record.id,
                    sessionNumber: record.sessionNumber,
                    studentReport: record.studentReport,
                    teacherReport: record.teacherReport
                  }));
                }}
                onEditClick={() => {
                  dispatch(setSessionDuration({
                    modal: true,
                    sessionId: record.id,
                    duration: record.duration
                  }));
                }}
                onRemoveClick={() => dispatch(setRemoveSession({ modal: true, sessionId: record.id }))}
              />
            ))
          }
        </>
      )}
      <Modal
        isClosble
        isOpen={sessionState.removeSession.modal}
        onRequestClose={() => dispatch(setRemoveSession({ modal: false }))}
      >
        <ConfirmationModal />
      </Modal>
    </>
  );
};
