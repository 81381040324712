// Navigation
import { useNavigate } from 'react-router';

// Routes
import { SIGNIN } from 'routes/CONSTANTS';

// Cookies
import { useCookies } from 'react-cookie';

// Types
import { NewPassword as IProps } from './NewPassword.types';

// Components
import TextField from 'components/General/TextField/TextField';
import Button from 'components/General/Button/Button';

// React hook form
import { useForm, Controller } from 'react-hook-form';

// Layouts
import AuthenticationLayout from 'layouts/AuthenticationLayout/AuthenticationLayout';

// Stylesheet
import './NewPassword.scss';
import { useAuth } from 'routes/Auth/AuthProvider';

const NewPassword = () => {
  const [cookies] = useCookies(['userinfo', 'email', 'code']);
  const navigate = useNavigate();
  const { setNewPassword, isAuthenticating } = useAuth();
  const { control, handleSubmit, formState: { errors }, getValues } = useForm<IProps>({
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });

  const onSubmit = handleSubmit((data) => {
    if (errors.password?.message || errors.confirmPassword?.message) return;
    const query = {
      email: cookies.email,
      code: cookies.code,
      password: data.password
    };
    setNewPassword(query).then((res: any) => {
      if (res.status === 201) {
        navigate(SIGNIN);
      }
    });
  });

  return (
    <AuthenticationLayout>
      <div className="form-container">
        <h1 className="auth-title">New Password</h1>
        <form onSubmit={onSubmit}>
          <Controller
            control={control}
            name="password"
            rules={{
              required: 'This field is required',
              minLength: {
                value: 5,
                message: 'Password should be longer than 5 characters'
              },
              maxLength: {
                value: 20,
                message: 'Password cannot be longer than 20 characters'
              },
            }}
            render={({ field: { onChange } }) => (
              <TextField
                variant="rounded"
                type="password"
                placeholder="******"
                id="password"
                onChange={onChange}
                label="New password"
                error={errors.password?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="confirmPassword"
            rules={{
              required: {
                value: true,
                message: 'This field is required'
              },
              minLength: {
                value: 5,
                message: 'Password should be longer than 5 characters'
              },
              maxLength: {
                value: 20,
                message: 'Password cannot be longer than 20 characters'
              },
              validate: {
                equalPasswords: (value) => (value === getValues().password || 'Passwords do not match')
              }
            }}
            render={({ field: { onChange } }) => (
              <TextField
                variant="rounded"
                type="password"
                placeholder="******"
                id="confirm-password"
                onChange={onChange}
                label="Confirm new password"
                error={errors?.confirmPassword?.message}
              />
            )}
          />
          <div className="signup-button mt-5">
            <Button
              color="primary"
              type="submit"
              isLoading={isAuthenticating}
            >
              Change Password
            </Button>
          </div>
        </form>
      </div>
    </AuthenticationLayout>
  );
};
export default NewPassword;
