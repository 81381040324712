import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainCalendarsSlice } from '../CalendarSlice';

export const useLogic = () => {
  const dispatch = useDispatch();
  const calendarState = useSelector((state: { mainCalendar: MainCalendarsSlice }) => state.mainCalendar);

  const filters = [
    { filter: 'Subjects', active: calendarState.filter?.courses },
    { filter: 'Teacher', active: calendarState.filter?.teachersIds },
    { filter: 'Country', active: calendarState.filter?.countries },
    { filter: 'Class ID', active: calendarState.filter?.classesIds },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filters?.[0]?.filter);

  return {
    filters,
    selectedFilter,
    setSelectedFilter,
    dispatch,
    calendarState
  };
};
