import { useSelector } from 'react-redux';
import { TeachersSlice } from '../../TeachersSlice';

export const useLogic = () => {
  const names = useSelector((state: { teachers: TeachersSlice }) => state.teachers.names.names);

  return {
    names
  };
};
