// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { FeedbacksProps } from './types';

export const useGetSessionFeedbacks = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<FeedbacksProps, Error> = useQuery<FeedbacksProps, Error>(['feedbacks'], async () => client.request(
    gql`
    query feedbackSessions{
      feedbackSessions{
        id
        userId
        dueAt
        teacher {
          id
        }
        sessionNumber
        class {
          classId
          plan {
            course {
              name
            }
          }
        }
      }
    }
    `
  ).catch((error) => console.error(error)));
  return data;
};
