// Styling
import './RescheduleSessionCard.scss';

// Types
import { RescheduleSessionCard as IProps } from './RescheduleSessionCard.types';

// Image
import CalendarAvatar from 'assets/images/calendar-avatar.svg';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import {
  faPencilAlt as EditIcon,
  faExclamationCircle as ProblemIcon,
  faTrash as DeleteIcon
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const RescheduleSessionCard = ({ sessionNumber, date, time, canReschedule, isValidLink, duration, status, edit, remove, problem, disable, onOpenClick, onRescheduleClick, onEditClick, onProblemClick, onRemoveClick }:IProps) => (

  <div className="student-reschedule-session-card-container">
    <div className="recording-top-container">
      <div className="img-title-container">
        <img
          src={CalendarAvatar}
          alt=""
        />
        <h2>
          {`Session ${sessionNumber} ${duration ? `(${duration} ${duration > 1 ? 'hours' : 'hour'})` : ''}`}
        </h2>
      </div>
      <div className="span-buttons-container">
        {!disable && (
        <span className={canReschedule ? 'active-span' : 'not-active-span'}>
          <ButtonBase
            tagName="div"
            onClick={onRescheduleClick}
            className={canReschedule ? 'active-span' : 'not-active-span'}
          >
            <h3>Reschedule</h3>
          </ButtonBase>
        </span>
        )}
        <span className={isValidLink ? 'active-span' : 'not-active-span'}>
          <ButtonBase
            tagName="div"
            onClick={onOpenClick}
            className={isValidLink ? 'active-span' : 'not-active-span'}
          >
            <h3>Open</h3>
          </ButtonBase>
        </span>
      </div>
    </div>
    <div className="recording-bottom-container">
      <h3>{date}</h3>
      <div className="recording-bottom-right-container">
        <div className="right-container">
          { edit && (
          <ButtonBase
            tagName="div"
            onClick={onEditClick}
          >
            <div className="edit">
              <FontAwesomeIcon
                icon={EditIcon}
                className="icon"
                color="#6371E0"
                display={12}
              />
            </div>
          </ButtonBase>
          )}
          { remove && (
          <ButtonBase
            tagName="div"
            onClick={onRemoveClick}
          >
            <div className="remove">
              <FontAwesomeIcon
                icon={DeleteIcon}
                className="icon"
                color="#ec516e"
                display={12}
              />
            </div>
          </ButtonBase>
          )}
          { problem && (
          <ButtonBase
            tagName="div"
            onClick={onProblemClick}
          >
            <div className="problem">
              <h4>Problem</h4>
              <FontAwesomeIcon
                icon={ProblemIcon}
                className="icon"
                color="#EC516E"
                display={12}
              />
            </div>
          </ButtonBase>
          )}
          {status && (
          <div className={`attendance-status ${(status === 'Attended') && 'active-attendance-status'} ${(status === 'Absent') && 'not-active-attendance-status'}`}>
            <h4 className={`attendance-status-body ${(status === 'Attended') && 'active-attendance-status-body'} ${(status === 'Absent') && 'not-active-attendance-status-body'}`}>
              {status}
            </h4>
          </div>
          )}
        </div>
        <h3>{time}</h3>
      </div>
    </div>
  </div>

);
