// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { TeacherTodaysSessions } from './types';

export const useTeacherTodaysSessions = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeacherTodaysSessions, Error> = useQuery<TeacherTodaysSessions, Error>(['teacher-todays-sessions'], async () => client.request(
    gql`
    query Class {
      teacherSessionsForToday {
        id
        dueAt
        sessionNumber
        teacherLink
        duration
        class {
          plan {
            course {
              name
            }
          }
        }
        user {
          first_name
          last_name
        }
      }
    }
    `
  ));
  return data;
};
