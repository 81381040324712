import { useLogic } from './StudentsNames.logic';
import './StudentsNames.scss';

export const StudentsNames = () => {
  const {
    names
  } = useLogic();

  return (
    <div className="students-names">
      <h3>Active students: </h3>
      {
        names?.map((name) => (
          <p>{`- ${name}`}</p>
        ))
      }
    </div>
  );
};
