// Styling
import { BackButtonPersonalInfo } from 'components/Dashboard/BackButtonPersonalInfo/BackButtonPersonalInfo';
import { PersonalInfoData, PersonalInfoDataScore } from 'components/Dashboard/PersonalInfoData/PersonalInfoData';
import { PersonalInfoProfile } from 'components/Dashboard/PersonalInfoProfile/PersonalInfoProfile';
import { PersonalInfoTabBar } from 'components/AdminDashboard/PersonalInfoTabBar/PersonalInfoTabBar';
import { DASHBOARD, LEADS, LEAD_PROFILE } from 'routes/CONSTANTS';
import { useLogic } from './LeadPersonalInfoPage.logic';
import './LeadPersonalInfoPage.scss';
import { LeadPersonalInfoPage as IProps } from './LeadPersonalInfoPage.types';
import moment from 'moment-timezone';
import { OverlayLoader } from 'components/General/Loader/Loader';
import Label from 'components/General/Label/Label';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import Button from 'components/General/Button/Button';
import Modal from 'components/General/Modal/Modal';
import { Payment } from 'pages/Dashboard/Admin/Leads/Leads/Payment/Payment';
import { CashTransactionValidation } from 'pages/Dashboard/Admin/Leads/Leads/Payment/CashTransactionValidation/CashTransactionValidation';
import { setUpdateStatus } from 'pages/Dashboard/Admin/Leads/LeadsSlice';
import { LeadStatus } from 'pages/Dashboard/Admin/Leads/Leads/LeadStatus/LeadStatus';

export const LeadPersonalInfoPage = ({ children }: IProps) => {
  const {
    navigate,
    tabs,
    id,
    data,
    isLoading,
    coursesNames,
    course,
    scores,
    setCourseName,
    handleAdminOnboarding,
    leadPaymentInfo,
    dispatch,
    leadsState,
    setPayment,
    handleLeadPayment,
    leadId
  } = useLogic();

  return (
    <div className="personal-info-page-container">
      {isLoading && (
      <div className="loading">
        <OverlayLoader />
      </div>
      )}
      <div className="personal-info-left-container">

        <div className="back-button">

          <BackButtonPersonalInfo
            onClick={() => navigate(`/${DASHBOARD}/${LEADS}`)}
            name={(data?.adminGetUserInfo?.first_name && `${data?.adminGetUserInfo?.first_name} ${data?.adminGetUserInfo?.last_name}`) || data?.adminGetUserInfo?.email}
          />
        </div>

        <div className="lead-buttons-container pb-3">
          {!((leadPaymentInfo?.adminPayClassInfo.leadStatus === 'Need payment review') || (leadPaymentInfo?.adminPayClassInfo.leadStatus === 'Scheduled')) && (
          <div>
            <Button
              color="primary"
              onClick={handleAdminOnboarding}
            >
              Continue Onboarding
            </Button>
          </div>
          )}
          {((leadPaymentInfo?.adminPayClassInfo.leadStatus === 'Need payment review') || (leadPaymentInfo?.adminPayClassInfo.leadStatus === 'Scheduled')) && (
          <div>
            <Button
              color="primary"
              onClick={() => handleLeadPayment()}
            >
              {leadPaymentInfo?.adminPayClassInfo.leadStatus === 'Need payment review' ? 'Review payment' : 'Pay'}
            </Button>
          </div>
          )}
          {}
        </div>

        <div className="lead-profile">
          <PersonalInfoProfile
            image={data?.adminGetUserInfo?.picture}
            chatOnClick={() => {
              window.open(`whatsapp://send?text=Hello from SetOut!&phone=${data?.adminGetUserInfo.phoneNumber}`, '_blank');
            }}
            emailOnClick={() => {
              window.open(`mailto:${data?.adminGetUserInfo.email}?subject=SendMail&body=Description`, '_blank');
            }}
            firstname={data?.adminGetUserInfo?.first_name || ''}
            lastname={data?.adminGetUserInfo?.last_name || ''}
            status={leadPaymentInfo?.adminPayClassInfo.leadStatus || ''}
            statsOnClick={() => dispatch(setUpdateStatus({ leadId: Number(leadId), modal: true }))}
          />
        </div>

        <div className="details">
          <div className="left">

            <PersonalInfoData
              title="Email"
              text={data?.adminGetUserInfo?.email || '-'}
            />
            <PersonalInfoData
              title="Date Of Birth"
              text={data?.adminGetUserInfo?.birthday ? moment(data?.adminGetUserInfo?.birthday).format('YYYY-MM-DD') : '-'}
            />
            <PersonalInfoData
              title="School Name"
              text={data?.adminGetUserInfo?.info?.school_name || '-'}
            />
            <PersonalInfoData
              title="Courses Taken"
              content={(
                <div className="courses-taken">
                  {
                    coursesNames.length ? (
                      coursesNames.map((item) => (
                        <ButtonBase
                          tagName="div"
                          onClick={() => setCourseName(item)}
                        >
                          <Label color={item}>{item}</Label>
                        </ButtonBase>
                      ))
                    ) : (
                      <p>-</p>
                    )
                  }
                </div>
              )}
            />
            <PersonalInfoDataScore
              title="Past Scores"
              total={course?.scores?.totalScore}
              scores={course && scores}
            />
          </div>

          <div className="right">
            <PersonalInfoData
              title="Guardian Phone Number"
              text={String(data?.adminGetUserInfo?.info?.guardain_number || '-')}
            />
            <PersonalInfoData
              title="Phone Number"
              text={String(data?.adminGetUserInfo?.phoneNumber || '-')}
            />
            <PersonalInfoData
              title="Age"
              text={(data?.adminGetUserInfo?.birthday && (moment().diff(data?.adminGetUserInfo?.birthday, 'years').toLocaleString() === '0' ? `${moment().diff(data?.adminGetUserInfo?.birthday, 'days').toLocaleString()} days` : `${moment().diff(data?.adminGetUserInfo?.birthday, 'years').toLocaleString()} years`)) || '-'}
            />
            <PersonalInfoData
              title="Country"
              text={data?.adminGetUserInfo?.info?.country || '-'}
            />
          </div>
        </div>
      </div>

      <div className="right-container">
        <PersonalInfoTabBar
          tabs={tabs}
          parentRoutes={[DASHBOARD, LEAD_PROFILE(String(id))]}
        />
        {children}
      </div>
      <Modal
        isClosble
        isOpen={leadsState.payment.modal}
        onRequestClose={() => dispatch(setPayment({ modal: false }))}
        fullHeight
      >
        <Payment />
      </Modal>
      <Modal
        isClosble
        isOpen={leadsState.payment.cashPaymentModal}
        onRequestClose={() => dispatch(setPayment({ cashPaymentModal: false }))}
      >
        <CashTransactionValidation />
      </Modal>
      <Modal
        isClosble
        isOpen={leadsState.status.modal}
        onRequestClose={() => dispatch(setUpdateStatus({ modal: false }))}
      >
        <LeadStatus />
      </Modal>
    </div>
  );
};
