import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StudentsSlice } from '../StudentsSlice';

export const useLogic = () => {
  const dispatch = useDispatch();
  const studentsState = useSelector((state: { students: StudentsSlice }) => state.students);

  const filters = [
    { filter: 'Subjects', active: studentsState.filter.courses },
    { filter: 'Teacher', active: studentsState.filter.taughtBy },
    { filter: 'Country', active: studentsState.filter.country },
    { filter: 'Status', active: studentsState.filter.userStatus },
    { filter: 'Class Status', active: studentsState.filter.classStatus },
    { filter: 'School', active: studentsState.filter.schools },
    { filter: 'Enrollment date', active: studentsState.filter.enrollment },
    { filter: 'Class of', active: studentsState.filter.classOf },
    { filter: 'Payment method', active: studentsState.filter.paymentMethod },
    { filter: 'Test date', active: studentsState.filter.testsTimeInterval?.start || studentsState.filter.testsTimeInterval?.end },
    { filter: 'Course date', active: studentsState.filter.coursesTimeInterval?.start || studentsState.filter.coursesTimeInterval?.start },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filters?.[0]?.filter);

  return {
    filters,
    selectedFilter,
    setSelectedFilter,
    dispatch,
    studentsState
  };
};
