// Types
import { Statistics } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useStatistics = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<Statistics, Error> = useQuery<Statistics, Error>(['admin-statistics'], async () => client.request(
    gql`
    query AdminStatTotalUsers {
        adminStatTotalUsers {
          usersTotal
          activeUsersTotal
          numberOfSessionsTaken
          numberOfSessionsMissed
        }
      }
    `,
  ).catch((error) => console.error(error)));
  return data;
};
