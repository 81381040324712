import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

type Props = {
  classId?: number,
  amount?: number,
  comment?: string,
  discount?: number,
  paymentMethod?: 'CASH' | 'CARD',
  submittedAt?: Date
};

export const useAdminPayManually = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ classId, amount, comment, discount, paymentMethod, submittedAt }: Props) => client.request(
    gql`
    mutation AdminAddManualPayment($amount: Float!, $classId: Float!, $comment: String!, $discount: Float!, $paymentMethod: paymentMethod!, $submittedAt: DateTime!) {
      adminAddManualPayment(amount: $amount, classId: $classId, comment: $comment, discount: $discount, paymentMethod: $paymentMethod, submittedAt: $submittedAt) {
        message
      }
    }
    `,
    {
      classId,
      amount,
      comment,
      discount,
      paymentMethod,
      submittedAt
    }
  ));
  return Mutation;
};
