import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useChooseCourse = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async (courseId: number) => client.request(
    gql`
    mutation UserChooseCourse($courseId: Float!) {
        userChooseCourse(courseId: $courseId) {
            message
        }
    }
    `,
    {
      courseId,
    }
  ));
  return Mutation;
};
