// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { SessionsQuery } from './types';

export const useClassSessions = ({ classId, filter, isAdmin }: { classId: number, filter: string, isAdmin: boolean }) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<SessionsQuery, Error> = useQuery<SessionsQuery, Error>(['class-sessions'], async () => client.request(
    gql`
    query ClassSessions($isAdmin: Boolean!, $classId: Float!, $filter: String!) {
      classSessions(classId: $classId, filter: $filter) {
        id
        userId
        planId
        rescheduledSessionsLimit
        planHours
        takenHours
        takenSessions
        plan {
          course {
            name
          }
        }
        filteredSessions {
          id
          appointmentTypeId
          status
          sessionNumber
          isRecorded
          recordingLink
          published
          dueAt
          teacherLink
          adminLink
          teacherTimeIn @include(if: $isAdmin)
          studentTimeIn @include(if: $isAdmin)
          duration
          problem @include(if: $isAdmin)
          acuitySession {
            datetime
          }
          teacher {
            id
            userId
            acuityCalendarId
            acuityAppTypeIds {
              GMAT {
                halfHour
                hour
                hourAndAHalf
                threeHours
                twoHours
                twoHoursAndAHalf
              }
              IELTS {
                halfHour
                hour
                hourAndAHalf
                threeHours
                twoHours
                twoHoursAndAHalf
              }
              SAT {
                halfHour
                hour
                hourAndAHalf
                threeHours
                twoHours
                twoHoursAndAHalf
              }
            }
          }
          studentReport @include(if: $isAdmin) {
            comment
            createdAt
            id
            isSolved
            reportedBy {
              first_name
              last_name
            } 
            reportedById
            sessionId
            status
          }
          teacherReport @include(if: $isAdmin) {
            comment
            createdAt
            id
            isSolved
            reportedBy {
              first_name
              last_name
            }
            reportedById
            sessionId
            status
          }
        }
      }
    }
    `,
    {
      classId: Number(classId),
      filter,
      isAdmin
    }
  ));
  return data;
};
