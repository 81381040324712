// Types
import { SessionNotes } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useNotes = (userId: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<SessionNotes, Error> = useQuery<SessionNotes, Error>(['session-notes', userId], async () => client.request(
    gql`
    query AdminGetUserClassSessionsComments($userId: Float!) {
      adminGetUserClassSessionsComments(userId: $userId) {
        id
        classId
        status
        plan {
          course {
            name
          }
        }
        sessions {
          id
          sessionNumber
          dueAt
          ratings {
            comment
            rate
            userRating {
              first_name
              last_name
            }
          }
        }
        studentRatingSessions {
          id
          sessionNumber
          dueAt
          ratings {
            comment
            rate
            userRating {
              first_name
              last_name
            }
          }
        }
      }
    }
    `,
    {
      userId
    }
  ).catch((error) => console.error(error)));
  return data;
};
