// Styling
import './CourseStatisticsCard.scss';

// Image
import upperShape from 'assets/images/upper-course-shape.svg';
import bottomShape from 'assets/images/bottom-course-shape.svg';
import upperShapeGray from 'assets/images/upper-course-shape-gray.svg';
import bottomShapeGray from 'assets/images/bottom-course-shape-gray.svg';

// Types
import { CourseStatistics as IProps } from './CourseStatisticsCard.types';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight as RightArrowIcon } from '@fortawesome/free-solid-svg-icons';

export const CourseStatisticsCard = ({ selectedCourse, status, title, onClick, goToCourse }: IProps) => (
  <ButtonBase
    tagName="div"
    onClick={onClick}
  >
    <div className={`course-statistics-container ${selectedCourse ? 'selected' : ''}`}>
      <div className="left-container">
        <div className="text">
          <h3 className={selectedCourse ? 'selected' : ''}>
            {title}
          </h3>
          <div className="spans-container">
            <span>
              <p className={selectedCourse ? 'selected' : ''}>
                {status}
              </p>
            </span>
            <span className="course-button-span">
              <ButtonBase
                tagName="div"
                onClick={goToCourse}
              >
                <div className={`course-button ${selectedCourse ? 'selected' : ''}`}>
                  <p className={`${selectedCourse ? 'selected' : ''}`}>
                    Go to course
                  </p>
                  <FontAwesomeIcon
                    icon={RightArrowIcon}
                    className="arrow-icon"
                    color="#6371e0"
                  />
                </div>
              </ButtonBase>
            </span>
          </div>
          {/* <h4>{status === 'Not Started' && 'Payment is under review'}</h4> */}
        </div>
        <div className="bottomside-design">
          {selectedCourse
            ? (
              <img
                src={bottomShape}
                alt=""
              />
            )
            : (
              <img
                src={bottomShapeGray}
                alt=""
              />
            )}
        </div>
      </div>
      <div className="right-containerr">
        <div className="upperside-design">
          {selectedCourse
            ? (
              <img
                src={upperShape}
                alt=""
              />
            )
            : (
              <img
                src={upperShapeGray}
                alt=""
              />
            )}
        </div>
      </div>

    </div>
  </ButtonBase>
);
