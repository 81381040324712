import { base64 } from 'assets/utils/getBase64';
import { getCountryCode } from 'assets/utils/getCountryCode';
import { Files } from 'components/Dashboard/AssignmentCard/AssignmentCard.types';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useAdminGetTeachersInfo } from 'services/AdminPanel/Teachers/AdminGetTeacherInfo/useAdminGetTeachersInfo';
import { useUpdateTeacher } from 'services/AdminPanel/Teachers/Mutations/useUpdateTeacher';
import { useFileUploadMutation } from 'services/UploadFiles/useUploadFile';
import { setTeacher, TeachersSlice, toggleEditTeacherModal } from '../TeachersSlice';
import { EditTeacher } from './EditTeacher.types';

export const useLogic = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const teachersState = useSelector((state: { teachers: TeachersSlice }) => state.teachers);
  const [image, setImage] = useState<string>();
  const [files, setFiles] = useState<Files>([]);
  const [attachmentsId, setAttachmentsIds] = useState<number>();
  const { getBase64 } = base64();
  const { progress, setProgress, mutation: { mutateAsync: uploadFiles, isLoading: isUploading } } = useFileUploadMutation();
  const input = useRef<any | null>(null);
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<EditTeacher>();
  const { mutateAsync: updateTeacher, isLoading: isLoadingUpdateTeacher } = useUpdateTeacher();
  const { data: teacherInfo, isFetching: isLoading } = useAdminGetTeachersInfo(Number(teachersState.teacher.id));
  const [courses, setCourses] = useState<string[] | undefined>([]);
  const options = [
    { value: 'SAT', label: 'SAT', disabled: false },
    { value: 'GMAT', label: 'GMAT', disabled: false },
    { value: 'IELTS', label: 'IELTS', disabled: false },
  ];

  const onSubmit = handleSubmit(async (data) => {
    updateTeacher({
      userId: Number(teachersState.teacher.id),
      acuityCalendarId: Number(data.acuityCalendarId),
      attachmentId: Number(attachmentsId),
      city: data.city,
      clickupFolderId: Number(data.clickupFolderId),
      clickupPk: data.clickupPk,
      country: data.country?.label,
      courses,
      degree: data.degree,
      details: data.details,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: `${data.phone?.countryCode}-${data.phone?.phone}`,
      tutoringHours: Number(data.tutoringHours),
      university: data.university,
      yearsOfExperience: Number(data.yearsOfExperience),
      rate: Number(data.rate)
    }).then(() => {
      dispatch(toggleEditTeacherModal(false));
      queryClient.refetchQueries('teachers-data');
    }).catch((err) => {
      toast.error(err.response.errors[0].message);
    });
  });

  const addFiles = (file: Files) => {
    setFiles([...file, ...files]);
  };

  const handleUpload = async (e: any) => {
    if (!e.target.files) return;

    const formData = new FormData();
    const promises = [];
    const temp: Files = [];

    for (let i = 0; i < e.target.files.length || 0; i++) {
      if (e.target.files[i].size > 26214400) {
        toast.error('File cannot be bigger than 25 Megabytes');
        e.target.value = '';
        return;
      }
      formData.append('files[]', e.target.files[i]);
      promises.push(getBase64(e.target.files[i]));
    }

    const responses = await Promise.all(promises);
    responses.map((res: any) => {
      const startIndex = String(res.result).search('base64,') + 7;
      const base46 = String(res.result).slice(startIndex, String(res.result).length);
      temp.push({ base64: base46, type: res.type });
      return res;
    });
    addFiles(temp);

    uploadFiles({ file: formData })
      .then((res: any) => {
        setProgress(0);
        setAttachmentsIds(res.data.attachmentsIds);
      }).catch(() => {
        setFiles([...files].slice(-1));
      });

    e.target.value = '';
  };

  useEffect(() => {
    setValue('acuityCalendarId', teacherInfo?.adminGetTeacherInfo?.teacher?.acuityCalendarId || undefined);
    setValue('clickupFolderId', teacherInfo?.adminGetTeacherInfo?.clickupCredential.clickupFolderId || undefined);
    setValue('clickupPk', teacherInfo?.adminGetTeacherInfo?.clickupCredential.clickupPK || undefined);
    setValue('city', teacherInfo?.adminGetTeacherInfo?.teacher?.city || undefined);
    setValue('country.label', teacherInfo?.adminGetTeacherInfo?.teacher?.country || undefined);
    setValue('degree', teacherInfo?.adminGetTeacherInfo?.teacher?.education || undefined);
    setValue('university', teacherInfo?.adminGetTeacherInfo?.teacher?.university || undefined);
    setValue('details', teacherInfo?.adminGetTeacherInfo?.teacher?.description || undefined);
    setValue('email', teacherInfo?.adminGetTeacherInfo?.email || undefined);
    setValue('firstName', teacherInfo?.adminGetTeacherInfo?.first_name || undefined);
    setValue('lastName', teacherInfo?.adminGetTeacherInfo?.last_name || undefined);
    setValue('phone.phone', teacherInfo?.adminGetTeacherInfo.phoneNumber?.split('-')[1] || teacherInfo?.adminGetTeacherInfo.phoneNumber || '');
    setValue('phone.countryCode', teacherInfo?.adminGetTeacherInfo.phoneNumber?.split('-')[0] || '');
    setValue('phone.isoCode', getCountryCode(teacherInfo?.adminGetTeacherInfo.phoneNumber?.split('-')[0] || ''));
    setValue('tutoringHours', teacherInfo?.adminGetTeacherInfo?.teacher?.tutoringHours || undefined);
    setValue('yearsOfExperience', teacherInfo?.adminGetTeacherInfo?.teacher?.yearsOfExperience || undefined);
    setValue('rate', teacherInfo?.adminGetTeacherInfo?.teacher?.defaultRate || undefined);
    setValue('tutoringHours', teacherInfo?.adminGetTeacherInfo?.teacher?.defaultTutoringHours || undefined);
    setCourses(() => teacherInfo?.adminGetTeacherInfo?.teacher?.courses?.map((item) => item.name));
    setImage(teacherInfo?.adminGetTeacherInfo?.picture || '');
  }, [teacherInfo]);

  useEffect(() => {
    if (!teachersState.editTeacherModal) {
      dispatch(setTeacher({ id: undefined }));
    }
  }, [teachersState.editTeacherModal]);

  return {
    control,
    handleSubmit,
    setValue,
    errors,
    onSubmit,
    courses,
    setCourses,
    isLoadingUpdateTeacher,
    isLoading,
    image,
    handleUpload,
    isUploading,
    progress,
    input,
    files,
    options,
  };
};
