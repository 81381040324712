import { useRoles } from 'assets/utils/useRoles';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAdminGetPeronalInfo } from 'services/AdminDashboard/PersonalInfo/PersonalInfo/useAdminGetPeronalInfo';

export const useLogic = () => {
  const { isAdmin } = useRoles();
  const { id } = useParams();
  const { data, isFetching: isLoading } = useAdminGetPeronalInfo(Number(id));
  const object = data?.adminGetUserInfo.info.done_courses;
  const coursesArray = Object.entries(object || {});
  const coursesNames = coursesArray.filter((item) => item[1].taken === true).map((item) => item[0]);
  const courses = coursesArray.filter((item) => item[1].taken === true).map((item) => item);
  const [course, setCourse] = useState(courses[0]?.[1]);
  const [courseName, setCourseName] = useState(courses[0]?.[0]);
  const [scores, setScores] = useState<any>();
  const navigate = useNavigate();
  const tabs = isAdmin ? [
    'Enrollments',
    'Payment status',
    'Course activity',
    'Notes'
  ] : [
    'Enrollments',
    'Course activity',
  ];

  useEffect(() => {
    if (data) {
      setCourse(courses[0]?.[1]);
    }
  }, [data]);

  useEffect(() => {
    if (course) {
      setCourse(courses.find((item) => item[0] === courseName)?.[1] || courses[0]?.[1]);
      if (course) {
        setScores(Object.entries(course?.scores).filter((item) => item[1] !== null).map((item) => (
          { subject: item[0].charAt(0).toLocaleUpperCase() + item[0].slice(1), score: item[1] || '-' }
        )));
      }
    }
  }, [course, courseName]);

  return {
    navigate,
    tabs,
    id,
    data,
    isLoading,
    coursesNames,
    courses,
    course,
    setCourse,
    scores,
    courseName,
    setCourseName,
    isAdmin
  };
};
