import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useReadNotifications = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async () => client.request(
    gql`
    mutation UserViewedNotification {
      userViewedNotification {
        message
      }
    }
    `,
  ));
  return Mutation;
};
