import Button from 'components/General/Button/Button';
import { setFilter, toggleFiltersModal } from '../../StudentsSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './TeacherFilter.scss';
import { useGetTeacherNames } from 'services/AdminDashboard/TableData/TeachersNames/useGetTeacherNames';
import SelectBox from 'components/General/SelectBox/SelectBox';
import { Name } from 'services/AdminDashboard/TableData/TeachersNames/types';

export const TeacherFilter = () => {
  const dispatch = useDispatch();
  const { data, isLoading } = useGetTeacherNames();
  const [value, setValue] = useState<string[]>([]);
  const [options, setOptions] = useState<Name[]>([]);

  const handleApplyFilters = () => {
    dispatch(setFilter({ taughtBy: value }));
    dispatch(toggleFiltersModal(false));
  };

  useEffect(() => {
    if (data?.adminGetTeachersNames) {
      setOptions(data?.adminGetTeachersNames);
    }
  }, [data]);

  return (
    <div className="search-filter-container">
      <h2>Search</h2>
      <SelectBox
        options={options}
        isLoading={isLoading}
        onChange={(e: any) => setValue(e?.label)}
      />
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
