// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { UserNearestAssignment } from './types';

export const useUserNearestAssignment = (classId?: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<UserNearestAssignment, Error> = useQuery<UserNearestAssignment, Error>(['userNearestAssignment'], async () => client.request(
    gql`
    query Query($classId: Float!) {
      userNearestAss(classId: $classId) {
        id
        name
        fullMark
        classId
        receivedMark
        details
        status
        dueDate
        ungraded
        attachments {
          id
          attachment
          teacherAttachment
        }
      }
    }
    `,
    {
      classId: classId || undefined
    }
  ).catch((error) => console.error(error)));
  return data;
};
