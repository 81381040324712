import { TableColumn } from 'components/Dashboard/Table/Table.types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Session, SessionNote } from 'services/AdminDashboard/PersonalInfo/Notes/types';
import { useNotes } from 'services/AdminDashboard/PersonalInfo/Notes/useNotes';

export const useLogic = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<{ id?: number, name?: string }>({});
  const [course, setCourse] = useState<SessionNote>();
  const [sessions, setSessions] = useState<Session[]>();
  const [toggleNotes, setToggleNotes] = useState('Teacher');
  const { data, isFetching: isLoading } = useNotes(Number(id));
  const Columns: TableColumn[] = [
    {
      value: '#',
      sortable: true,
      key: 'id',
    },
    {
      value: 'Session number',
      sortable: true,
      key: 'status'
    },
    {
      value: 'Date',
      sortable: true,
      key: 'country'
    },
    {
      value: 'Created by',
      sortable: true,
      key: 'teacher'
    },
    {
      value: 'Rate',
      sortable: true,
      key: 'rate',
    },
    {
      value: 'Comments',
      sortable: true,
      key: 'teacher'
    }
  ];

  useEffect(() => {
    if (data) {
      setSelected({ id: data.adminGetUserClassSessionsComments?.[0]?.id, name: data.adminGetUserClassSessionsComments?.[0]?.plan?.course?.name });
      setCourse(data.adminGetUserClassSessionsComments?.[0]);
      setSessions(data.adminGetUserClassSessionsComments?.[0]?.sessions);
    }
  }, [data]);

  const handleSelect = (cardId?: number, cardName?: string) => {
    setSelected({ id: cardId, name: cardName });
    setCourse(data?.adminGetUserClassSessionsComments?.find((item) => item.id === cardId));
    setSessions(data?.adminGetUserClassSessionsComments?.[0]?.sessions);
    setToggleNotes('Teacher');
  };

  useEffect(() => {
    if (toggleNotes === 'Student') {
      setSessions(course?.studentRatingSessions);
    } else {
      setSessions(course?.sessions);
    }
  }, [toggleNotes, course]);

  return {
    id,
    Columns,
    data,
    isLoading,
    selected,
    course,
    handleSelect,
    toggleNotes,
    setToggleNotes,
    sessions,
    navigate
  };
};
