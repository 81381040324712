// Styling
import './BackButtonPersonalInfo.scss';

// Types
import { BackButtonPersonalInfo as IProps } from './BackButtonPersonalInfo.types';

// Icon
import { faChevronLeft as back } from '@fortawesome/free-solid-svg-icons';

//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DotsLoader from 'components/General/Loader/Loader';
import stringLimit from 'assets/utils/stringLimit';

export const BackButtonPersonalInfo = ({ onClick, name }:IProps) => (
  <div className="personal-info-back-button-container">
    <div className="icon">
      <FontAwesomeIcon
        icon={back}
        className="icon"
        color="#696C71"
        onClick={onClick}
      />
    </div>

    <div className="personal-info-name-container">
      {name ? (
        <h3>{`Personal Info: ${stringLimit(String(name), 19)}`}</h3>
      ) : (
        <DotsLoader />
      )}
    </div>
  </div>
);
