// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { UnpaidClasses } from './types';

export const useGetUnpaidClasses = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<UnpaidClasses, Error> = useQuery<UnpaidClasses, Error>(['unpaidClasses'], async () => client.request(
    gql`
    query UserTodoUnpaidClasses {
      userTodoUnpaidClasses {
        id
        isPaid
        classId
        status
        userId
        createdAt
        planId
        cashTransactions {
          id
          rejectionReason
          status
          attachments {
            id
            attachment
          }
        }
        plan {
          price
          sessions_count
          course {
            id
            name
          }
        }
        user {
          stripeCustomerId
        }
      }
    }
    `
  ).catch((error) => console.error(error)));
  return data;
};
