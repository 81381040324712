import Button from 'components/General/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import WarningIcon from 'assets/images/warning.svg';
import './ConfirmationModal.scss';
import { SessionSlice, setRemoveSession } from '../../SessionsSlice';
import { useQueryClient } from 'react-query';
import { useDeleteSession } from 'services/Dashboard/Sessions/Mutations/useDeleteSession';

export const ConfirmationModal = () => {
  const sessionState = useSelector((state: { sessions: SessionSlice }) => state.sessions);
  const { mutateAsync: removeSession, isLoading: isLoadingRemoveSession } = useDeleteSession();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleRemoveSession = () => {
    removeSession({ sessionId: sessionState.removeSession.sessionId }).then(() => {
      queryClient.refetchQueries('class-sessions');
      dispatch(setRemoveSession({ modal: false }));
    });
  };

  return (
    <div className="manual-confirmation-modal">
      <h2>Are you sure you want to remove session?</h2>
      <img
        src={WarningIcon}
        alt=""
      />
      <div className="buttons">
        <Button
          color="primary"
          onClick={() => dispatch(setRemoveSession({ modal: false }))}
        >
          No
        </Button>
        <Button
          color="white"
          onClick={() => handleRemoveSession()}
          isLoading={isLoadingRemoveSession}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};
