import Button from 'components/General/Button/Button';
import TextField from 'components/General/TextField/TextField';
import { Controller } from 'react-hook-form';
import { useLogic } from './GiveMark.Logic';
import './GiveMark.scss';

export const GiveMark = () => {
  const { control, onSubmit, errors, isLoading, assignmentState } = useLogic();

  return (

    <div className="give-mark-container">
      <h2>Give Mark</h2>
      <div className="text-fields-container">
        <div className="mark-container">
          <Controller
            control={control}
            name="mark"
            rules={{
              required: {
                value: true,
                message: 'This field is required'
              },
              max: {
                value: assignmentState.mark?.originalMark || 100,
                message: 'The given mark cannot be greater than the original mark.'
              },
              min: {
                value: 0,
                message: 'The given mark cannot be less than zero.'
              },
            }}
            render={({ field: { onChange, value } }) => (
              <div className="rainbow-m-around_small">
                <TextField
                  color="light-gray"
                  variant="rounded"
                  placeholder="10"
                  id="mark"
                  label="Mark"
                  type="number"
                  error={errors.mark?.message}
                  onChange={onChange}
                  value={value}
                />
              </div>
            )}
          />
        </div>
        <div className="original-mark-container">
          <Controller
            control={control}
            name="originalMark"
            rules={{
              required: {
                value: true,
                message: 'This field is required'
              }
            }}
            render={({ field: { value } }) => (
              <div className="rainbow-m-around_small">
                <TextField
                  color="light-gray"
                  variant="rounded"
                  placeholder="10"
                  id="mark"
                  label="Original mark"
                  type="number"
                  value={value}
                  disabled
                />
              </div>
            )}
          />
        </div>

      </div>

      <div className="button-container mt-3">
        <Button
          color="primary"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
