import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Filters = {
  courses?: string[],
  country?: string[],
  status?: string[],
  teacherId?: number,
  selectedFilter?: string
  selectedBox?: string
};

type Teacher = {
  id?: number
};

type Names = {
  names?: string[],
  modal?: boolean
};

type Status = {
  status?: string,
  userId?: number,
  modal?: boolean
};

export interface TeachersSlice {
  filter: Filters;
  filtersModal: boolean;
  addTeacherModal: boolean;
  editTeacherModal: boolean;
  teacher: Teacher;
  status: Status
  names: Names
}

const initialState: TeachersSlice = {
  filter: {},
  filtersModal: false,
  addTeacherModal: false,
  editTeacherModal: false,
  teacher: {},
  status: {},
  names: {},
};

const teachers = createSlice({
  name: 'teachers',
  initialState,
  reducers: {
    toggleFiltersModal: (state, { payload }: PayloadAction<boolean>) => {
      state.filtersModal = payload;
    },
    toggleAddTeacherModal: (state, { payload }: PayloadAction<boolean>) => {
      state.addTeacherModal = payload;
    },
    toggleEditTeacherModal: (state, { payload }: PayloadAction<boolean>) => {
      state.editTeacherModal = payload;
    },
    setFilter: (state, { payload }: PayloadAction<Filters>) => {
      state.filter = { ...state.filter, ...payload };
    },
    setTeacher: (state, { payload }: PayloadAction<Teacher>) => {
      state.teacher = { ...state.teacher, ...payload };
    },
    setTeacherStatus: (state, { payload }: PayloadAction<Status>) => {
      state.status = { ...state.status, ...payload };
    },
    setNames: (state, { payload }: PayloadAction<Names>) => {
      state.names = { ...state.names, ...payload };
    },
  },
});

export default teachers;
export const {
  setFilter,
  toggleFiltersModal,
  toggleAddTeacherModal,
  toggleEditTeacherModal,
  setTeacher,
  setTeacherStatus,
  setNames
} = teachers.actions;
