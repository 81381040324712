import { StatisticsCardLeads } from 'components/Dashboard/StatisticsCardLeads/StatisticsCardLeads';
import { useLogic } from './Statistics.logic';
import ArrowsIcon from 'assets/images/arrows-up-down.svg';
import ChatIcon from 'assets/images/active-student.svg';
import GreenAvatarIcon from 'assets/images/green-avatar.svg';
import YellowAvatarIcon from 'assets/images/yellow-avatar.svg';
import { LeadsSlice, setFilter } from '../LeadsSlice';

// Styling
import './Statistics.scss';
import { useDispatch, useSelector } from 'react-redux';

export const Statistics = () => {
  const { statistics, isLoading } = useLogic();
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);
  const dispatch = useDispatch();

  return (
    <div className="lead-statistics-cards-container">
      <StatisticsCardLeads
        image={ArrowsIcon}
        count={statistics?.adminLeadsTotalStat.TotalLeads}
        description="Total of leads"
        isLoading={isLoading}
        onClick={() => dispatch(setFilter({ status: undefined, selectedBox: 'Total of leads' }))}
        selected={leadsState.filter.selectedBox === 'Total of leads'}
      />
      <StatisticsCardLeads
        image={ChatIcon}
        count={statistics?.adminLeadsTotalStat.convertedLeads}
        description="Converted rate"
        isLoading={isLoading}
        percentage={statistics?.adminLeadsTotalStat.convertedLeadsRate}
        onClick={() => dispatch(setFilter({ status: ['Done'], selectedBox: 'Converted rate' }))}
        selected={leadsState.filter.selectedBox === 'Converted rate'}
      />
      <StatisticsCardLeads
        image={YellowAvatarIcon}
        count={statistics?.adminLeadsTotalStat.pendingLeads}
        description="Pending leads"
        isLoading={isLoading}
        percentage={statistics?.adminLeadsTotalStat.pendingLeadsRate}
        onClick={() => dispatch(setFilter({ status: ['New', 'Chose subject', 'Entered info', 'Chose a plan', 'Scheduled', 'Help in scheduling', 'Need payment review'], selectedBox: 'Pending leads' }))}
        selected={leadsState.filter.selectedBox === 'Pending leads'}
      />
      <StatisticsCardLeads
        image={GreenAvatarIcon}
        count={statistics?.adminLeadsTotalStat.lostLeads}
        description="Lost leads"
        isLoading={isLoading}
        percentage={statistics?.adminLeadsTotalStat.lostLeadsRate}
        onClick={() => dispatch(setFilter({ status: ['Declined'], selectedBox: 'Lost leads' }))}
        selected={leadsState.filter.selectedBox === 'Lost leads'}
      />
      <StatisticsCardLeads
        image={GreenAvatarIcon}
        count={statistics?.adminLeadsTotalStat.futureLeads}
        description="Future leads"
        isLoading={isLoading}
        percentage={statistics?.adminLeadsTotalStat.futureLeadsRate}
        onClick={() => dispatch(setFilter({ status: ['Future'], selectedBox: 'Future leads' }))}
        selected={leadsState.filter.selectedBox === 'Future leads'}
      />
    </div>
  );
};
