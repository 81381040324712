// Types
import { Card as IProps } from './Card.types';

// Components
import MetaData from 'components/General/MetaData/MetaData';

// Icons
import { colors, icons } from './CONSTANTS';
import PrettyRating from 'pretty-rating-react';
import { Clock } from 'react-feather';
import ReactTooltip from 'react-tooltip';

// Styling
import './Card.scss';
import 'assets/scss/typography.scss';
import { FC } from 'react';

const Card: FC<IProps> = ({
  country,
  city,
  rate,
  tutoringHours,
  yearsOfExperience,
  user,
  selected,
  onClick,
  onViewProfileClick,
  ...rest
}) => {
  const roundUp = (num: number) => {
    const wholeNum = Math.floor(num);
    const decimals = Math.round(((num % 1) + Number.EPSILON) * 100) / 100;
    let res = 0;
    if (decimals > 0) {
      if (decimals <= 0.25) {
        res = wholeNum;
      } else if (decimals < 0.75) {
        res = wholeNum + 0.50;
      } else if (decimals >= 0.75) {
        res = wholeNum + 1;
      }
    } else {
      return num;
    }
    return res;
  };
  return (
    <>
      <ReactTooltip />
      <div
        className={`card ${selected && 'selected-card'}`}
        onClick={onClick}
        tabIndex={0}
        onKeyDown={() => {}}
        role="button"
        {...(rest as any)}
      >
        <div className="wrapper">
          <div className="avatar">
            <img
              src={user.picture ? user.picture : `url(https://eu.ui-avatars.com/api/?background=6371E0&color=fff&name=${user.first_name} ${user.last_name}&size=100)`}
              alt=""
            />
          </div>
          <div className="details">
            <div className="userinfo">
              <strong className="sub-text">{`${user.first_name} ${user.last_name}`}</strong>
              <span className="address body-sub-text">{`${country}, ${city}`}</span>
              <div className="rating">
                <PrettyRating
                  value={roundUp(Number(rate)) || 0}
                  icons={icons.star}
                  colors={colors.star}
                  max={5}
                />
              </div>
            </div>
            <div className="metadeta">
              <MetaData
                title="Tutoring hours"
                subTitle={`${tutoringHours} h`}
                icon={<Clock />}
              />
            </div>
          </div>
        </div>
        <div
          className="link-container pb-1"
          onClick={onViewProfileClick}
          tabIndex={0}
          onKeyDown={() => {}}
          role="button"
        >
          View Profile
        </div>
      </div>
    </>
  );
};

export default Card;
