import { useStatistics } from 'services/AdminDashboard/Statistics/useStatistics';

export const useLogic = () => {
  const { data: statistics, isLoading } = useStatistics();

  return {
    statistics,
    isLoading
  };
};
