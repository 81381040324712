// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { UserNotificationsQuery } from './types';

export const useUserNotifications = (limit: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<UserNotificationsQuery, Error> = useQuery<UserNotificationsQuery, Error>(['userNotification', limit], async () => client.request(
    gql`
    query UserNotifications($limit: Float!, $offset: Float!) {
      userNotifications(limit: $limit, offset: $offset) {
        newNotificationsCount
        notifications {
          id
          title
          message
          createdAt
        }
      }
    }
    `,
    {
      limit,
      offset: 0
    }
  ));
  return data;
};
