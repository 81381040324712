import { FC } from 'react';

// Types
import { LabelProps } from './Label.types';

// Style
import './Label.scss';

const Label: FC<LabelProps> = ({ active, children, className, color, ...rest }) => (
  <span
    className={`chip-label${active ? ' active' : ''} 
     ${className} 
     ${(color === 'GMAT' && 'mustard')
    || (color === 'SAT' && 'primary')
    || (color === 'IELTS' && 'fuchsia')
    || (color === 'primary' && 'primary')
    || (color === 'fuchsia' && 'fuchsia')
    || (color === 'mustard' && 'mustard')
    || (color === 'IELTS' && 'fuchsia')
    || (color === 'Active' && 'success')
    || (color === 'Finished' && 'mustard')
    || (color === 'Cancelled' && 'fuchsia')
    || (color === 'Inactive' && 'fuchsia')
    || (color === 'Not Started' && 'blue')
    || (color === 'Chose a plan' && 'primary')
    || (color === 'Chose subject' && 'secondary')
    || (color === 'Entered info' && 'blue')
    || (color === 'Done' && 'success')
    || (color === 'Future' && 'mustard')
    || (color === 'Scheduled' && 'mustard')
    || (color === 'Waiting to be graded' && 'blue')
    || (color === 'Corrected' && 'secondary')
    || (color === 'Overdue' && 'fuchsia')
    || (color === 'Declined' && 'fuchsia')}`}
    {...rest}
  >
    {children}
  </span>
);

export default Label;
