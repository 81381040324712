import { useDispatch, useSelector } from 'react-redux';
import { AssignmentSlice, setMark } from './AssignmentSlice';
import { useAdminNearestAssignment } from 'services/Dashboard/PracticeTests/Queries/AdminNearestAssignment/useAdminNearestAssignment';
import { useParams } from 'react-router';
import { DashboardSlice } from 'pages/Dashboard/DashboardSlice';
import { setEditAssignmentId, setReuseAssignment, setToggleNewAssignmentModal } from 'pages/Dashboard/Course/Assignment/AssignmentSlice';
import { useRoles } from 'assets/utils/useRoles';
import { useGetAssignments } from 'services/Dashboard/Assignments/Queries/Assignments/useGetAssignments';
import { useDeleteAssignment } from 'services/Dashboard/Assignments/Mutations/useDeleteAssignment';
import { useUserNearestAssignment } from 'services/Dashboard/Assignments/Queries/UserNearestAssignment/useUserNearestAssignment';
import { useGetAssignmentChart } from 'services/Dashboard/Assignments/Queries/AssignmentChart/useGetAssignmentChart';
import toast from 'react-hot-toast';

export const useLogic = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const assignmentState = useSelector((state: { assignment: AssignmentSlice }) => state.assignment);
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const { data: assignmentsData, isFetching: isLoading } = useGetAssignments(Number(id));
  const { mutateAsync: deleteAssignment } = useDeleteAssignment();
  const { data: userNearestAssignment, isFetching: isLoadingnUserNearestAssignment } = useUserNearestAssignment(Number(id));
  const { data: adminNearestAssignment, isFetching: isLoadingnAdminNearestAssignment } = useAdminNearestAssignment(Number(id));
  const { data: chartData, isLoading: isLoadingChartData } = useGetAssignmentChart(Number(id));
  const { isAdmin, isTeacher } = useRoles();

  const handeRemove = (assignmentId?: number) => {
    deleteAssignment(assignmentId).then((data) => toast.success(data.adminDeleteAssignment.message));
  };

  const handleEdit = (assignment: any) => {
    dispatch(setReuseAssignment(assignment));
    dispatch(setEditAssignmentId(assignment.id));
    dispatch(setToggleNewAssignmentModal());
  };

  return {
    assignmentState,
    dashboardState,
    isLoading,
    assignmentsData,
    handeRemove,
    dispatch,
    setMark,
    userNearestAssignment,
    adminNearestAssignment,
    isLoadingnAdminNearestAssignment,
    isLoadingnUserNearestAssignment,
    handleEdit,
    isAdmin,
    isTeacher,
    chartData,
    isLoadingChartData
  };
};
