import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useUpdateTaskStatus = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ taskId, finished }: { taskId: string, finished: boolean }) => client.request(
    gql`
    mutation AdminChangeTaskStatus($taskId: String!, $finished: Boolean!) {
      adminChangeTaskStatus(taskId: $taskId, finished: $finished) {
        id
        name
        text_content
        status {
          status
          color
        }
      }
    }
    `,
    {
      taskId,
      finished
    }
  ));
  return Mutation;
};
