// Types
import { SessionAttendanceQuery } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useSessionAttendance = (userId: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<SessionAttendanceQuery, Error> = useQuery<SessionAttendanceQuery, Error>(['session-attendance', userId], async () => client.request(
    gql`
    query Query($userId: Float!) {
      adminGetUserClassSessions(userId: $userId) {
        id
        classId
        status
        attendedSessions
        discountAmount
        rescheduledSessions
        missedSessions
        plan {
          course {
            name
          }
        }
        sessions {
          id
          status
          dueAt
          sessionNumber
        }
      }
    }
    `,
    {
      userId
    }
  ).catch((error) => console.error(error)));
  return data;
};
