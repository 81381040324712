import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useUpdateTeacherStatus = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ userId, status }: { userId?: number, status?: string }) => client.request(
    gql`
    mutation AdminEditTeacherStatus($status: teacherStatus!, $userId: Float!) {
      adminEditTeacherStatus(status: $status, userId: $userId) {
        message
      }
    }
    `,
    {
      status,
      userId
    }
  ));
  return Mutation;
};
