import { StudentsSlice, setAttendance } from 'pages/Dashboard/Admin/Students/StudentsSlice';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useAdminChangeAttendance } from 'services/Dashboard/Sessions/Mutations/AdminChangeAttendance/useAdminChangeAttendance';

export const useLogic = () => {
  const studentsState = useSelector((state: { students: StudentsSlice }) => state.students);
  const { mutateAsync: changeAttendance, isLoading } = useAdminChangeAttendance();
  const [attended, setAttended] = useState<boolean>();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSaveAttendance = () => {
    changeAttendance({ sessionId: studentsState.attendance.sessionId, attended }).then(() => {
      queryClient.refetchQueries('session-attendance');
      dispatch(setAttendance({ modal: false }));
      setAttended(undefined);
    });
  };

  useEffect(() => {
    if (!studentsState.attendance.modal) {
      setAttended(undefined);
      dispatch(setAttendance({ sessionId: undefined }));
    }
  }, [studentsState.attendance.modal]);

  return {
    dispatch,
    attended,
    setAttended,
    handleSaveAttendance,
    isLoading
  };
};
