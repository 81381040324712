import { useEffect, useState } from 'react';

// React Cookie
import { useCookies } from 'react-cookie';

// React Router
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import { DASHBOARD, STUDENT_PROFILE, ENROLLMENTS, LEAD_PROFILE } from 'routes/CONSTANTS';
import { useLeadsTableData } from 'services/AdminDashboard/Leads/LeadsTableData/useLeadsTableData';
import { useTableData } from 'services/AdminDashboard/TableData/StudentsTableData/useTableData';

import { useUserInfo } from 'services/Onboarding/General/useOnboarding';

// React QUery

const useLogic = () => {
  const location = useLocation();
  const [cookies, setCookie] = useCookies(['delfina/sidenav/is_collapsed']);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(JSON.parse(cookies['delfina/sidenav/is_collapsed'] || false));
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const selectedTab = location.pathname.split('/')?.[2];
  const navigate = useNavigate();
  const { data: students, isFetching: isLoadingStudentsData } = useTableData({ search: searchValue }, !!searchValue);
  const { data: userInfo } = useUserInfo();
  const { data: leads, isFetching: isLoadingLeadssData } = useLeadsTableData({ search: searchValue }, !!searchValue);

  const tabs = [
    'Todos',
    'Calendar',
    'Classes',
    'Students',
    'Leads',
    'Teachers'
  ];
  // Show search modal when pressing ctrl + F
  useEffect(() => {
    const openSearch = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.altKey) && event.code === 'KeyF') {
        event.preventDefault();
        setShowSearch(true);
      }
    };

    window.addEventListener('keydown', openSearch);
  });

  const handleToggleCollapse = () => setIsCollapsed((prev) => {
    setCookie('delfina/sidenav/is_collapsed', !prev);
    return !prev;
  });

  const handleStudentNavigation = (id: number) => {
    navigate(`/${DASHBOARD}/${STUDENT_PROFILE(`${id}/${ENROLLMENTS}`)}`);
  };

  const handleLeadNavigation = (id: number, leadId: number, course: string) => {
    navigate(`/${DASHBOARD}/${LEAD_PROFILE(String(id), String(leadId), course)}`);
  };

  return {
    isCollapsed,
    showSearch,
    students,
    isLoadingStudentsData,
    setSearchValue,
    handleToggleCollapse,
    setShowSearch,
    handleStudentNavigation,
    selectedTab,
    tabs,
    userInfo,
    leads,
    isLoadingLeadssData,
    handleLeadNavigation
  };
};

export default useLogic;
