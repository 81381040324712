import { gql } from 'graphql-request';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useSolveSessionProblem = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ duration, status, sessionId }: { duration?: number, status?: string, sessionId?: number }) => client.request(
    gql`
    mutation AdminSolveSessionProblem($duration: Float!, $sessionId: Float!, $status: sessionStatus!) {
      adminSolveSessionProblem(duration: $duration, sessionId: $sessionId, status: $status) {
        message
      }
    }
    `,
    {
      duration,
      sessionId,
      status,
    }
  ).catch((error) => toast(error.response.errors[0].message)), {
    onSuccess: (data: any) => { toast.success(data?.adminSolveSessionProblem.message); }
  });
  return Mutation;
};
