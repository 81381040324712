// React Calendar
import Calendar from 'react-calendar';

// uuid
import { v4 as uuidv4 } from 'uuid';

// Components
import Button from 'components/General/Button/Button';
import TimeSlot from 'components/Onboarding/TimeSlot/TimeSlot';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { CalendarState, setCalendarDate, setCalendarMonth } from './RescheduleCalendar.slice';

// Logic
import { useLogic } from './RescheduleCalendar.logic';

// Moment
import moment from 'moment-timezone';

// React
import { FC } from 'react';

// Components
import { OverlayLoader, SectionLoader } from 'components/General/Loader/Loader';

// Tooltip
import ReactTooltip from 'react-tooltip';

// Redux
import { Col, Row } from 'react-grid-system';

// Style
import './RescheduleCalendar.scss';
import { toggleTimeSlot } from '../CalendarSlice';
import { Toggle } from 'components/Dashboard/CheckBox/CheckBox';

const RescheduleCalendar: FC<any> = () => {
  const dispatch = useDispatch();
  const { calendarDate } = useSelector((state: { calendar: CalendarState }) => state.calendar);
  const {
    availableDates,
    isLoadingInitialAvailableDates,
    isLoadingAvailableDates,
    isLoadingAvailableTimes,
    handleConfirmSchedule,
    isLoadingConfirmSchedule,
    mainCalendarState,
    activeOpenCalendar,
    setActiveOpenCalendar,
    timeSlots,
    isLoadingAllTimeSlots,
    isLoading,
    calendarState
  } = useLogic();

  return (
    <div className="calendar-container">
      <div className="calendar-card">
        <h2>{`You're changing (session ${mainCalendarState.plan.sessionNumber})`}</h2>
        {isLoadingInitialAvailableDates ? <div className="pt-10"><SectionLoader /></div>
          : (
            <>
              <div className="calendar-container">
                {(isLoadingAvailableDates || isLoadingAllTimeSlots) && <OverlayLoader />}
                <div className="admin-open-calendar">
                  <h3>Activate open calendar</h3>
                  <Toggle
                    checked={activeOpenCalendar}
                    onChange={() => setActiveOpenCalendar((prev) => !prev)}
                  />
                </div>
                <Calendar
                  defaultValue={moment(mainCalendarState.plan.sessionDate).toDate() || new Date()}
                  defaultActiveStartDate={moment(mainCalendarState.plan.sessionDate).toDate() || new Date()}
                  maxDate={mainCalendarState.plan.typeID ? undefined : new Date()}
                  onActiveStartDateChange={(props) => { if (props.view === 'month') dispatch(setCalendarMonth(`${moment(props.activeStartDate).year()}-${moment(props.activeStartDate).month() <= 8 ? `0${moment(props.activeStartDate).month() + 1}` : moment(props.activeStartDate).month() + 1}`)); }}
                  onChange={(date: Date) => dispatch(setCalendarDate(date))}
                  minDate={mainCalendarState.plan.typeID ? undefined : new Date()}
                  tileDisabled={({ date, view }) => {
                    if (view !== 'month') { return false; }
                    const dates = availableDates?.data?.map((availableDate) => new Date(availableDate.date).toLocaleDateString());
                    if (activeOpenCalendar) {
                      return moment(moment().subtract(1, 'day')).diff(date.toLocaleDateString()) >= 0;
                    }
                    return dates?.filter((availableDate) => moment(date.toLocaleDateString()).isSame(availableDate)).length === 0;
                  }}
                  tileClassName={({ date }) => {
                    const dates = mainCalendarState.selectedTimeSlots?.map((availableDate) => new Date(availableDate.datetime).toLocaleDateString());
                    return dates?.filter((availableDate) => moment(date.toLocaleDateString()).isSame(availableDate)).length > 0 ? 'selected-date' : '';
                  }}
                />
              </div>
              {(isLoadingAvailableTimes && !isLoadingAvailableDates) || isLoading ? <div className="pt-3 pb-3"><SectionLoader /></div>
                : (
                  <div className="available-times">
                    <Row style={{ rowGap: 35 }}>
                      {timeSlots.length ? (
                        timeSlots.map((item) => (
                          <Col
                            xs={6}
                            md={6}
                            key={uuidv4()}
                          >
                            <TimeSlot
                              addTime={{
                                hours: String(mainCalendarState.plan?.duration)?.split(' ')[0],
                                type: mainCalendarState.plan.duration?.split(' ')[1].includes('hour') ? 'hours' : 'minutes'
                              }}
                              time={item}
                              onClick={() => dispatch(toggleTimeSlot({
                                appointmentTypeId: calendarState.acuityTypeID,
                                datetime: item
                              }))}
                              selected={mainCalendarState.selectedTimeSlots.some((slot) => slot.datetime.includes(item))}
                              deny={
                                // If the user scheduled all required sessions
                                mainCalendarState.selectedTimeSlots.length === 1
                                // Prevent the user from selecting two sessions in the same day
                                || mainCalendarState.selectedTimeSlots.filter((time) => moment(new Date(time.datetime).toLocaleDateString()).isSame(calendarDate?.toLocaleDateString())).length > 0
                              }
                            />
                          </Col>
                        ))
                      ) : (
                        <div className="no-times-available">
                          <h4>No available times</h4>
                        </div>
                      )}
                    </Row>
                  </div>
                )}

              <div className="buttons-container">
                <ReactTooltip />
                <Button
                  onClick={() => { if (mainCalendarState.selectedTimeSlots.length === 1) handleConfirmSchedule(); }}
                  color="primary"
                  data-tip={mainCalendarState.selectedTimeSlots.length < (1 || 0) ? 'Please select a time.' : ''}
                  data-place="top"
                  data-type="warning"
                  data-effect="solid"
                  isLoading={isLoadingConfirmSchedule}
                >
                  <>
                    {`Change ${mainCalendarState.selectedTimeSlots.length} out of 1`}
                  </>
                </Button>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

export default RescheduleCalendar;
