import { FC, useEffect } from 'react';

// Types
import { OptionsMenuProps } from './OptionsMenu.types';

// Style
import './OptionsMenu.scss';

// Components
import { ButtonBase } from '../ButtonBase/ButtonBase';

const OptionsMenu: FC<OptionsMenuProps> = ({ isOpen, onRequestClose, options, header, className, ...rest }) => {
  const handleEsc = (e: KeyboardEvent) => {
    if (e.key === 'Escape') { onRequestClose(); }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleEsc);
    window.addEventListener('click', onRequestClose);
    return () => {
      // unsubscribe event
      window.removeEventListener('keydown', handleEsc);
      window.removeEventListener('click', onRequestClose);
    };
  }, []);

  return (
    <>
      {isOpen
      && (
        <ButtonBase
          tagName="div"
          className={`options-menu ${className}`}
          onClick={(event) => event.stopPropagation()}
          {...rest}
        >
          {header}
          <ul className="options">
            {options.map((option) => (
              <ButtonBase
                tagName="li"
                onClick={option.onClick}
              >
                <li>
                  {option.icon}
                  {option.label}
                </li>
              </ButtonBase>
            ))}
          </ul>
        </ButtonBase>
      )}
    </>
  );
};

export default OptionsMenu;
