// Types
import { TeacherStats } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useTeacherClassesStats = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeacherStats, Error> = useQuery<TeacherStats, Error>(['teacher-classes-statistics'], async () => client.request(
    gql`
    query TeacherClassesViewStat {
      teacherClassesViewStat {
        averageRating
        hoursTaught
        studentsTaught
      }
    }
    `,
  ).catch((error) => console.error(error)));
  return data;
};
