import Button from 'components/General/Button/Button';
import { setFilter, StudentsSlice, toggleFiltersModal } from '../../StudentsSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './PaymentMethodFilter.scss';
import { CheckboxGroup } from 'react-rainbow-components';

export const PaymentMethodFilter = () => {
  const studentsState = useSelector((state: { students: StudentsSlice }) => state.students);
  const dispatch = useDispatch();
  const [values, setValues] = useState<string[] | undefined>(studentsState.filter.courses);

  const handleApplyFilters = () => {
    dispatch(setFilter({ paymentMethod: values && values[0] }));
    dispatch(toggleFiltersModal(false));
  };

  const options = [
    { value: 'CARD', label: 'Credit card', disabled: false },
    { value: 'CASH', label: 'Cash', disabled: false },
  ];

  useEffect(() => {
    if (!studentsState.filter.paymentMethod) {
      setValues(undefined);
    }
  }, [studentsState.filter]);

  return (
    <div className="search-filter-container">
      <h2>Payment method</h2>
      <CheckboxGroup
        options={options}
        value={values}
        onChange={(e) => setValues(e)}
        className="checkbox"
      />
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
