// Types
import { AdminCalendarProps, AdminCalendarFilters } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminCalendar = ({ classesIds, countries, courses, teachersIds, month }: AdminCalendarFilters) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<AdminCalendarProps, Error> = useQuery<AdminCalendarProps, Error>(['admin-calendar', [classesIds, countries, courses, teachersIds, month]], async () => client.request(
    gql`
    query AdminCalendar($classesIds: [Float!], $countries: [String!], $courses: [String!], $teachersIds: [Float!], $month: DateTime!) {
      adminCalendar(classesIds: $classesIds, countries: $countries, courses: $courses, teachersIds: $teachersIds, month: $month) {
        sessions {
          id
          startDate
          endDate
          teacherId
          planId
          course
          datetime
          duration
          sessionNumber
          studentName
          teacherName
          adminLink
          isAccessible
          acuityCalendarId
          acuityAppTypeIds {
            GMAT {
              halfHour
              hour
              hourAndAHalf
              threeHours
              twoHours
              twoHoursAndAHalf
            }
            IELTS {
              halfHour
              hour
              hourAndAHalf
              threeHours
              twoHours
              twoHoursAndAHalf
            }
            SAT {
              halfHour
              hour
              hourAndAHalf
              threeHours
              twoHours
              twoHoursAndAHalf
            }
          }
          isAccessible
          status
        }
        teachers {
          id
          text
          color
          sessionsCount
        }
      }
    }
    `,
    {
      courses,
      countries,
      classesIds,
      teachersIds,
      month
    }
  ));
  return data;
};
