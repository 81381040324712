import { TableColumn } from 'components/Dashboard/Table/Table.types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClassFilters from '../Filters/ClassFilter/ClassFilter';
import { LocationFilter } from '../Filters/CountryFilter/LocationFilter';
import { StatusFilter } from '../Filters/StatusFilter/StatusFilter';
import { LeadsSlice, setUpdateStatus, setPayment } from '../LeadsSlice';
import { useNavigate } from 'react-router';
import { useLeadsTableData } from 'services/AdminDashboard/Leads/LeadsTableData/useLeadsTableData';
import { LeadsSortProp } from './Leads.types';

export const useLogic = () => {
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddPatient, setShowAddPatient] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [sortBy, setSortBy] = useState<LeadsSortProp[]>([{ column: 'created_at', ASC: false }]);
  const { data, isLoading: isLoadingTableData, isFetching } = useLeadsTableData({ filterBy: leadsState.filter, offset, sortBy, limit: 20 });

  const Columns: TableColumn[] = [
    {
      value: 'Name',
      sortable: true,
      key: 'name',
      align: 'left'
    },
    {
      value: 'Subject',
      sortable: true,
      key: 'course',
    },
    {
      value: 'Status',
      sortable: true,
      key: 'status'
    },
    {
      value: 'Country',
      sortable: true,
      key: 'country'
    },
    {
      value: 'Number',
      sortable: true,
      key: 'number'
    },
    {
      value: 'Time in this status',
      sortable: true,
      key: 'status_date',
    },
    {
      value: 'Plan',
      sortable: true,
      key: 'plan',
    },
    {
      value: 'Completion rate',
      sortable: true,
      key: 'completion_rate',
    },
    {
      value: 'Future call',
      sortable: true,
      key: 'future_call',
    },
    {
      value: 'Created at',
      sortable: true,
      key: 'created_at',
    },

  ];

  const generateFilter = (item?: string) => {
    switch (item) {
      case 'Subject':
        return <ClassFilters />;
      case 'Country':
        return <LocationFilter />;
      case 'Status':
        return <StatusFilter />;
      default:
        return <ClassFilters />;
    }
  };

  return {
    generateFilter,
    leadsState,
    dispatch,
    showAddPatient,
    setShowAddPatient,
    Columns,
    data,
    isLoadingTableData,
    isFetching,
    navigate,
    offset,
    setOffset,
    sortBy,
    setSortBy,
    setUpdateStatus,
    setPayment,
  };
};
