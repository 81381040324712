// Styling
import './ToggleSwitch.scss';
import { ToggleSwitch as IProps } from './ToggleSwitch.types';
import { v4 as uuid } from 'uuid';

export const ToggleSwitch = ({ ...rest }: IProps) => {
  const id = uuid();
  return (
    <>
      <label
        htmlFor={id}
        className="switch"
      >
        <input
          {...rest}
          id={id}
          type="checkbox"
        />
        <span className="slider round" />
      </label>
    </>
  );
};
