// Components
import TextField from 'components/General/TextField/TextField';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight as RightArrowIcon, faChevronDown as DownArrowIcon, faFolderOpen as OpenedFolderIcon, faFolder as ClosedFolderIcon } from '@fortawesome/free-solid-svg-icons';

// uuid
import { v4 as uuid } from 'uuid';

// Logic
import { useLogic } from './ReusePracticeTest.logic';

// Style
import './ReusePracticeTest.scss';

// Types
import { ReusePracticeTest as IProps } from './ReusePracticeTest.types';
import DotsLoader from 'components/General/Loader/Loader';

export const ReusePracticeTest = ({ onReuseClick }: IProps) => {
  const {
    className,
    searchValue,
    OpenedFolder,
    isOpenFolder,
    setSearchValue,
    setIsOpenFolder,
    setOpenedFolder,
    handleSetReusableAssignment,
    reusePracticeTests,
    isLoading,
    searchPracticeTests
  } = useLogic();
  return (
    <div className="reuse-assignemnt-container">
      <h2>Reuse assignments</h2>
      <div className="search-container">
        <TextField
          variant="rounded"
          placeholder="Search by class ID"
          value={searchValue || ''}
          onChange={(e: any) => setSearchValue(e.target.value)}
        />
      </div>
      {!isLoading ? (
        <div className="folders-container">
          {reusePracticeTests ? (
            searchPracticeTests(searchValue)?.map((item) => (
              <div
                className="folder-container"
                key={uuid()}
              >
                <div className="folder-details">
                  <FontAwesomeIcon
                    icon={(isOpenFolder && item.id === OpenedFolder) ? DownArrowIcon : RightArrowIcon}
                    className="arrow-icon"
                    size="1x"
                    color="#696C71"
                    onClick={() => {
                      setIsOpenFolder(!isOpenFolder);
                      setOpenedFolder(item.id);
                    }}
                  />
                  <FontAwesomeIcon
                    icon={(isOpenFolder && item.id === OpenedFolder) ? OpenedFolderIcon : ClosedFolderIcon}
                    className="folder-icon"
                    size="1x"
                    color="#696C71"
                    onClick={() => {
                      setIsOpenFolder(!isOpenFolder);
                      setOpenedFolder(item.id);
                    }}
                  />
                  <h3>{`${className} Class #${item.classId}`}</h3>
                </div>
                {isOpenFolder && item.id === OpenedFolder && (
                <div className="assignments-container">
                  {item.tests.length ? (
                    item.tests.map((test) => (
                      <div
                        className="assignment"
                        key={uuid()}
                      >
                        <h3>{`${test.name} ${test.id}`}</h3>
                        <h3>
                          <a
                            href="#"
                            onClick={() => {
                              handleSetReusableAssignment({
                                id: test.id,
                                name: test.name,
                                dueDate: test.dueDate,
                                fullMark: test.fullMark,
                                details: test.details,
                                attachments: test.attachments,
                                link: test.link
                              });
                              onReuseClick();
                            }}
                            type="button"
                          >
                            Reuse
                          </a>
                        </h3>
                      </div>
                    ))
                  ) : (
                    <h3>No assignments</h3>
                  )}
                </div>
                )}
              </div>
            ))
          ) : (
            <h2>No files found</h2>
          )}
        </div>
      ) : (
        <DotsLoader />
      )}
    </div>
  );
};
