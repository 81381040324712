import { useTeacherClassesStats } from 'services/Teacher/Classes/Queries/ClassesStats/useTeacherClassesStats';

export const useLogic = () => {
  const { data, isLoading } = useTeacherClassesStats();

  return {
    data,
    isLoading
  };
};
