import { TableColumn } from 'components/Dashboard/Table/Table.types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClassFilters from '../Filters/ClassFilter/ClassFilter';
import { LocationFilter } from '../Filters/CountryFilter/LocationFilter';
import { StatusFilter } from '../Filters/StatusFilter/StatusFilter';
import { TeachersSlice, toggleAddTeacherModal, toggleEditTeacherModal, setTeacher, setTeacherStatus, setNames } from '../TeachersSlice';
import { useNavigate } from 'react-router';
import { useTeachersData } from 'services/AdminPanel/Teachers/TeachersData/useTeachersData';

export const useLogic = () => {
  const teachersState = useSelector((state: { teachers: TeachersSlice }) => state.teachers);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddPatient, setShowAddPatient] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [sortBy, setSortBy] = useState('create_at');
  const [desc, setDesc] = useState(true);
  const { data, isLoading: isLoadingTableData, isFetching } = useTeachersData({ desc, limit: 20, offset, sortBy, filteredBy: teachersState.filter });

  const Columns: TableColumn[] = [
    {
      value: 'Name',
      sortable: true,
      key: 'name',
      align: 'center'
    },
    {
      value: 'Email',
      sortable: true,
      key: 'email'
    },
    {
      value: 'Subjects',
      sortable: true,
      key: 'courses',
    },
    {
      value: 'Status',
      sortable: true,
      key: 'status'
    },
    {
      value: 'Country',
      sortable: true,
      key: 'country',
      hidden: true
    },
    {
      value: 'Phone',
      sortable: true,
      key: 'phone',
    },
    {
      value: 'Rate',
      sortable: true,
      key: 'rate',
    },
    {
      value: 'Default rate',
      sortable: false,
      key: 'defaultRate',
      hidden: true,
    },
    {
      value: 'Tutoring hours',
      sortable: false,
      key: 'tutoringHours',
    },
    {
      value: 'Default tutoring hours',
      sortable: false,
      key: 'defaultTutoringHours',
      hidden: true,
    },
    {
      value: 'Active students',
      sortable: false,
    },
    {
      value: 'Weekly hours',
      sortable: false,
    },
    {
      value: 'Number of ratings',
      sortable: false,
      hidden: true
    },
    {
      value: 'Created at',
      sortable: true,
      key: 'create_at',
      hidden: true
    },
  ];

  const generateFilter = (item?: string) => {
    switch (item) {
      case 'Subject':
        return <ClassFilters />;
      case 'Country':
        return <LocationFilter />;
      case 'Status':
        return <StatusFilter />;
      default:
        return <ClassFilters />;
    }
  };

  return {
    generateFilter,
    teachersState,
    dispatch,
    showAddPatient,
    setShowAddPatient,
    Columns,
    data,
    isLoadingTableData,
    navigate,
    offset,
    setOffset,
    sortBy,
    setSortBy,
    setDesc,
    toggleAddTeacherModal,
    toggleEditTeacherModal,
    setTeacher,
    setTeacherStatus,
    setNames,
    isFetching
  };
};
