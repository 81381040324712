// Types
import { AdminGetAllAvailableDates, TeacherAllAvailableTimeSlots } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminGetTimeSlots = ({ month, calendarID, enabled }: AdminGetAllAvailableDates) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeacherAllAvailableTimeSlots, Error> = useQuery<TeacherAllAvailableTimeSlots, Error>(['teacher-all-available-time-slots', month, calendarID, enabled], async () => client.request(
    gql`
    query AdminGetAppointmentsTimeSlots($calendarId: Float!, $date: String!) {
      adminGetAppointmentsTimeSlots(calendarID: $calendarId, date: $date) {
        duration
        time
      }
    }
    `,
    {
      calendarId: calendarID,
      date: month
    }
  ).catch((error) => console.error(error)), { enabled });
  return data;
};
