import ListingTable from 'components/AdminDashboard/ListingTable/ListingTable';
import { FinancialStatCard } from 'components/Dashboard/FinancialStatCard/FinancialStatCard';
import { useLogic } from './PaymentStatus.logic';
import './PaymentStatus.scss';
// Images
import FinancialCard from 'assets/images/purple-card-finance.svg';
import FinancialCardYellow from 'assets/images/yellow-card-finance.svg';
import FinancialCardRed from 'assets/images/red-card-finance.svg';
import { CourseStatisticsCard } from 'components/Dashboard/CourseStatisticsCard/CourseStatisticsCard';
import moment from 'moment-timezone';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import Modal from 'components/General/Modal/Modal';
import { CashTransactionValidation } from 'pages/Dashboard/Admin/Students/PersonalInfo/PaymentStatus/CashTransactionValidation/CashTransactionValidation';
import Button from 'components/General/Button/Button';
import { Toggle } from 'components/Dashboard/CheckBox/CheckBox';
import { CreatePayment } from './CreatePayment/CreatePayment';
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt as EditIcon,
  faTrash as DeleteIcon } from '@fortawesome/free-solid-svg-icons';
import DotsLoader, { CircleLoader } from 'components/General/Loader/Loader';

export const PaymentStatus = () => {
  const {
    Columns,
    data,
    selected,
    handleSelect,
    course,
    studentsState,
    setPaymentConfirmationsModal,
    dispatch,
    navigate,
    setManualPaymentData,
    handleDeletePayment,
    isLoadingDeletePayment,
    isLoading,
    handleChangeAvailibility
  } = useLogic();
  return (
    <div className="payment-status-container">
      <div className="financial-cards-container">
        <h3>General financial statistic</h3>
        { isLoading ? (
          <DotsLoader />
        ) : (
          <div className="financial-cards">
            <FinancialStatCard
              image={FinancialCard}
              count={data?.adminGetUserClassPayments?.allPayments}
              description="All Payments"
            />
            <FinancialStatCard
              image={FinancialCard}
              count={data?.adminGetUserClassPayments?.netPayments}
              description="Net payments"
            />
            <FinancialStatCard
              image={FinancialCardRed}
              count={data?.adminGetUserClassPayments?.remainingAmount}
              description="Remaining amount"
            />
            <FinancialStatCard
              image={FinancialCardYellow}
              count={data?.adminGetUserClassPayments?.allDiscount}
              description="All discount"
            />
          </div>
        )}
      </div>
      <div className="course-cards-container">
        <h3>Your course</h3>
        <div className="course-cards">
          {
          data?.adminGetUserClassPayments?.classes.map((card) => (
            <CourseStatisticsCard
              status={card.status}
              title={card.plan?.course?.name}
              selectedCourse={card.id === selected.id}
              onClick={() => handleSelect(card.id, card.plan?.course?.name)}
              goToCourse={() => navigate(`/class/${card.id}/${card.classId}/${card.plan?.course?.name}/stream`)}
            />
          ))
        }

        </div>
      </div>
      <div className="payment-status-table-container">
        <div className="table-header">
          <h3>Record history</h3>
          <div className="header-right-container">
            <div>
              <Button
                color="primary"
                variant="small"
                onClick={() => dispatch(setManualPaymentData({ madal: true, id: course?.id }))}
              >
                Add manually
              </Button>
            </div>
            <div className="activity-toggle-container">
              <p>Inactive</p>
              <Toggle
                checked={course?.isAccessible}
                onChange={() => {
                  handleChangeAvailibility(course?.id, !course?.isAccessible);
                }}
              />
              <p>Active</p>
            </div>
          </div>
        </div>
        <ListingTable
          columns={Columns}
          data={
            course?.payments.map((item) => (
              [
                { value: <p className="sub-text">{item.id}</p> },
                { value: <p className="sub-text">{item.paymentMethod ? `${item.paymentMethod}-${item.type}${item.refund ? '-refund' : ''}` : '-'}</p> },
                { value: <p className="sub-text">{item.createdBy || '-'}</p> },
                { value: <p className="sub-text">{item.transactionDate ? moment(item.transactionDate).format('MM/DD/YYYY') : '-'}</p> },
                { value: <p className="sub-text">{`$${item.amount}`}</p> },
                { value: <p className="sub-text">{`$${item.amountPaid}`}</p> },
                { value: <p className="sub-text">{`$${item.discount}`}</p> },
                { value: <p className="sub-text">{item.comment || '-'}</p> },
                { value: item.type === 'MANUAL' ? (
                  <ButtonBase
                    tagName="div"
                  >
                    <FontAwesomeIcon
                      icon={EditIcon}
                      className="icon"
                      color="#97ACCC"
                      display={12}
                      onClick={() => dispatch(setManualPaymentData({
                        madal: true,
                        id: course.id,
                        amount: item.amount,
                        comment: item.comment,
                        date: new Date(item.transactionDate).toDateString(),
                        discount: item.discount,
                        transactionId: item.id,
                        paymentMethod: item.paymentMethod === 'CASH' ? 'Cash' : 'Credit card'
                      }))}
                    />
                  </ButtonBase>
                ) : (
                  '-'
                ) },
                { value: item.type === 'MANUAL' ? (
                  <>
                    {
                      (isLoadingDeletePayment && item.id === studentsState.manualPayment.transactionId) ? (
                        <CircleLoader />
                      ) : (
                        <ButtonBase
                          tagName="div"
                        >
                          <FontAwesomeIcon
                            icon={DeleteIcon}
                            className="icon"
                            color="#97ACCC"
                            display={12}
                            onClick={() => {
                              dispatch(setManualPaymentData({ transactionId: item.id }));
                              handleDeletePayment(item.id);
                            }}
                          />
                        </ButtonBase>
                      )
                    }
                  </>
                ) : (
                  '-'
                ) },
              ]
            ))
          }
        />
      </div>
      <Modal
        isClosble
        isOpen={studentsState.paymentConfirmationModal}
        onRequestClose={() => dispatch(setPaymentConfirmationsModal(false))}
      >
        <CashTransactionValidation />
      </Modal>
      <Modal
        isClosble
        isOpen={studentsState.manualPayment.madal}
        onRequestClose={() => dispatch(setManualPaymentData({ madal: false }))}
      >
        <CreatePayment />
      </Modal>
    </div>

  );
};
