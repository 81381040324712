import { createContext, useContext } from 'react';
import { AuthState } from './auth.types';
import { useAuthProviderLogic } from './AuthProvider.logic';

// Context API used for Auth related information and methods.
const authContext = createContext<AuthState>({ user: null,
  signin: () => new Promise(() => {}),
  signout: () => {},
  signup: () => {},
  verifySignup: () => {},
  resendCode: () => {},
  forgotPassword: () => {},
  verifyResetPassword: () => {},
  setNewPassword: () => {},
  socialAuth: () => new Promise(() => {}),
  isAuthenticating: false,
});

// Context Provider to wrap the whole app within and make auth information available.
export function AuthProvider(props: any) {
  const { children } = props;
  const auth = useAuthProviderLogic();

  return (
    <authContext.Provider value={auth}>
      {!auth.loadingInitial && children}
    </authContext.Provider>
  );
}

// Custom Hook
export function useAuth() {
  return useContext(authContext);
}
