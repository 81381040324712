// Types
import { PracticeTestsQuery } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useGetPracticeTests = (id?: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<PracticeTestsQuery, Error> = useQuery<PracticeTestsQuery, Error>(['practiceTests'], async () => client.request(
    gql`
    query Query($classId: Float!, $limit: Float!, $offset: Float!) {
      userClassTest(classId: $classId, limit: $limit, offset: $offset) {
        id
        name
        classId
        details
        link
        status
        dueDate
        attachments {
          id
          attachment
          teacherAttachment
        }
        receivedMark {
          QuantitativeScore
          englishScore
          listening
          mathScore
          reading
          speaking
          totalScore
          verbalScore
          writing
        }
      }
    }
    `,
    {
      classId: id,
      limit: 0,
      offset: 0
    }
  ).catch((error) => console.error(error)));
  return data;
};
