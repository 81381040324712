import { useMutation } from 'react-query';
import { axiosSetOut } from 'services/config/axiosConfig';
import { AxiosError } from 'axios';
import { useState } from 'react';

interface IArgs {
  file: FormData
}

export const useFileUploadMutation = () => {
  const [progress, setProgress] = useState(0);

  const mutation = useMutation<void, AxiosError, IArgs>(
    (args) => axiosSetOut().post(
      'user/upload',
      args.file,
      { onUploadProgress: (ev) => setProgress(Math.round((ev.loaded * 100) / ev.total)) }
    )
  );

  return { mutation, progress, setProgress };
};
