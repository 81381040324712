// Types
import { Plan as IProps } from './Plan.types';

// Components
import Button from 'components/General/Button/Button';

// Styling
import './Plan.scss';
import { useSelector } from 'react-redux';
import { OnboardingSlice } from 'pages/Onboarding/OnboardingSlice';

const Plan = ({ id, title, description, price, onClick, isLoading }: IProps) => {
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);

  return (
    <div className="plan-container">
      <h2 className="plan-title">{title}</h2>
      { description.map((line) => <h3>{line}</h3>)}
      <h2>{`$${price}`}</h2>
      <div className="button-container">
        <Button
          color="primary"
          onClick={onClick}
          isLoading={isLoading && (onboardingState.plan.id === id)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
export default Plan;
