import { StatisticsCard } from 'components/Dashboard/StatisticsCard/StatisticsCard';
import ArrowsIcon from 'assets/images/arrows-up-down.svg';
import ChatIcon from 'assets/images/active-student.svg';

// Styling
import './Statistics.scss';
import { useLogic } from './Statistics.logic';

export const Statistics = () => {
  const { data, isLoading } = useLogic();

  return (
    <div className="teacher-statistics-cards-container">
      <StatisticsCard
        image={ChatIcon}
        count={data?.teacherClassesViewStat.studentsTaught}
        description="Taught students"
        isLoading={isLoading}
      />
      <StatisticsCard
        image={ArrowsIcon}
        count={data?.teacherClassesViewStat.averageRating}
        description="Average rating"
        isLoading={isLoading}
      />
      <StatisticsCard
        image={ChatIcon}
        count={data?.teacherClassesViewStat.hoursTaught}
        description="Total hours taught"
        isLoading={isLoading}
      />
    </div>
  );
};
