import Button from 'components/General/Button/Button';
import { LeadsSlice, setFilter, toggleFiltersModal } from '../../LeadsSlice';
import { useEffect, useState } from 'react';
import { CheckboxGroup } from 'react-rainbow-components';
import { useDispatch, useSelector } from 'react-redux';
import './StatusFilter.scss';

export enum LeadStatus {
  NEW = 'New', // Just made a sign-up
  CHOSE_A_CLASS = 'Chose subject', // Select what he wants (SAT, GMAT ..)
  ENTERED_INFO = 'Entered info', // For these steps (Personal info, Family info, school info)
  CHOSE_A_PLAN = 'Chose a plan', // He Select his plan
  SCHEDULED = 'Scheduled', // Booked his sessions
  DONE = 'Done', // Who Paid and we will move to the Student tab
  DECLINED = 'Declined', // Who not interested at all and we will not call him in future
  FUTURE = 'Future', // Not interested now but we can call him after a few months
}

export const StatusFilter = () => {
  const dispatch = useDispatch();
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);
  const [values, setVlaues] = useState<string[] | undefined>(leadsState.filter.status);

  const handleApplyFilters = () => {
    dispatch(setFilter({ status: values }));
    dispatch(toggleFiltersModal(false));
  };

  const options = [
    { value: 'Done', label: 'Done', disabled: false },
    { value: 'Declined', label: 'Declined', disabled: false },
    { value: 'Future', label: 'Future', disabled: false },
    { value: 'Scheduled', label: 'Scheduled', disabled: false },
    { value: 'Chose a plan', label: 'Chose a plan', disabled: false },
    { value: 'Entered info', label: 'Entered info', disabled: false },
    { value: 'Chose subject', label: 'Chose subject', disabled: false },
    { value: 'New', label: 'New', disabled: false },
    { value: 'Need payment review', label: 'Need payment review', disabled: false },
    { value: 'Help in scheduling', label: 'Help in scheduling', disabled: false },
  ];

  useEffect(() => {
    setVlaues(leadsState.filter.status);
  }, [leadsState.filter.status]);

  return (
    <div className="status-filter-container">
      <h2>Status</h2>
      <div className="filters-container">
        <CheckboxGroup
          options={options}
          value={values}
          onChange={(e) => setVlaues(e)}
          className="checkbox"
        />
      </div>
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
