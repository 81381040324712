import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminCancelClass = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async (classId: number) => client.request(
    gql`
    mutation AdminCancelClass($classId: Float!) {
      adminCancelClass(classId: $classId) {
        message
      }
    }
    `,
    {
      classId
    }
  ));
  return Mutation;
};
