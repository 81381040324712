// Types
import { TodoComponent as IProps } from './TodoComponent.types';

// Styling
import './TodoComponent.scss';
import Button from 'components/General/Button/Button';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash as RemoveIcon } from '@fortawesome/free-solid-svg-icons';
import DotsLoader from 'components/General/Loader/Loader';

export const TodoComponent = ({ body, onClick, onRemove, isRemovable, buttonText, status, rejectionMessage, isLoading }: IProps) => (
  <div className="todo-container">
    <div className="todo-body">
      <p>{body}</p>
      <p className="rejection-message">{rejectionMessage}</p>
    </div>
    <div className="todo-button">
      {status && (
        <span className="span">
          <p className="sub-text">{status}</p>
        </span>
      )}
      <Button
        color="primary"
        onClick={onClick}
      >
        {buttonText}
      </Button>
      { (isLoading && isRemovable) && (
        <DotsLoader />
      )}
      {(isRemovable && !isLoading) && (
      <FontAwesomeIcon
        icon={RemoveIcon}
        className="remove-icon"
        color="#97ACCC"
        display={12}
        onClick={onRemove}
      />
      )}
    </div>
  </div>
);
