// Logic
import { useLogic } from './NewCallLog.logic';

// React
import { FC } from 'react';

import './NewCallLog.scss';
import { PostTextField } from 'components/Dashboard/PostTextField/PostTextField';
import Button from 'components/General/Button/Button';
import SelectBox from 'components/General/SelectBox/SelectBox';
import { Calendar } from 'react-rainbow-components';

const NewCallLog: FC<any> = () => {
  const {
    hours,
    time,
    setTime,
    setDate,
    date,
    note,
    setNote,
    handleSave,
    isLoadingCreateCallLog
  } = useLogic();

  return (
    <>
      <div className="new-call-log-container mb-2">
        <div className="text-container">
          <h3>New note</h3>
          <PostTextField
            rows={21}
            color="gray"
            variant="round"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
        </div>
        <div className="calendar-container">
          <Calendar
            value={date}
            onChange={(e) => setDate(e)}
            minDate={new Date()}
          />
          <SelectBox
            label="Time"
            options={hours}
            value={time}
            onChange={(e: any) => setTime(e)}
          />
        </div>
      </div>
      <Button
        color="primary"
        onClick={handleSave}
        isLoading={isLoadingCreateCallLog}
      >
        Save
      </Button>
    </>
  );
};

export default NewCallLog;
