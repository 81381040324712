import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useConfirmTransaction = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ transId, isVerified, reason }: { transId?: number, isVerified?: boolean, reason?: string }) => client.request(
    gql`
    mutation VerifyTransaction($transId: Float!, $isVerified: Boolean!, $reason: String) {
      verifyTransaction(transId: $transId, isVerified: $isVerified, reason: $reason) {
        id
        rejectionReason
        createdAt
        attachments {
          id
          attachment
        }
      }
    } 
    `,
    {
      transId,
      isVerified,
      reason: reason && reason
    }
  ));
  return Mutation;
};
