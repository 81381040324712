// Types
import { BackButtonBar as IProps } from './BackButtonBar.types';

// Styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft as back } from '@fortawesome/free-solid-svg-icons';

import './BackButtonBar.scss';

export const BackButtonBar = ({ className, classID, onClick }: IProps) => (
  <div className="back-bar-container">
    <FontAwesomeIcon
      icon={back}
      className="icon"
      size="1x"
      color="#696C71"
      onClick={onClick}
    />
    <h2>{`${className} ${classID}`}</h2>
  </div>
);
