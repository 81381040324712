// Types
import { TeachersData, TeachersDataProps } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useTeachersData = ({ desc, filteredBy, limit, offset, search, sortBy }: TeachersDataProps) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeachersData, Error> = useQuery<TeachersData, Error>(['teachers-data', [desc, filteredBy, offset, search, sortBy]], async () => client.request(
    gql`
    query AdminAllTeachersStat($sortBy: sortTeachersEnum, $search: String, $offset: Float, $limit: Float, $filterBy: filterTeachersBy, $desc: Boolean) {
      adminAllTeachersStat(sortBy: $sortBy, search: $search, offset: $offset, limit: $limit, filterBy: $filterBy, DESC: $desc) {
        teachersStatistics {
          country
          courses
          createdAt
          email
          id
          name
          numberOfRatings
          phone
          rate
          status
          picture
          ActiveStudentsNames
          ActiveStudentsNumber
          weekHours
          defaultRate
          tutoringHours
          defaultTutoringHours
        }
        totalTeachers
        activeTeachers
        inactiveTeachers
      }
    }
    `,
    {
      sortBy,
      search,
      offset,
      limit,
      filterBy: {
        courses: filteredBy?.courses,
        country: filteredBy?.country,
        status: filteredBy?.status
      },
      desc
    }
  ).catch((error) => console.error(error)));
  return data;
};
