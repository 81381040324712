import { gql } from 'graphql-request';
import { AssignmentSliceType } from 'pages/Dashboard/Course/Assignment/Assignments.types';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useCreatePracticeTest = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ details, dueDate, name, classId, attachmentsIds }: AssignmentSliceType) => client.request(
    gql`
    mutation CreateTest($classId: Float!, $dueDate: String!, $details: String!, $name: String!, $attachmentsIds: [Float!]) {
      createTest(classId: $classId, dueDate: $dueDate, details: $details, name: $name, attachmentsIds: $attachmentsIds) {
        classId
        details
        name
        status
        dueDate
        attachments {
          id
          attachment
          teacherAttachment
        }
        receivedMark {
          QuantitativeScore
          englishScore
          listening
          mathScore
          reading
          speaking
          totalScore
          verbalScore
          writing
        }
      }
    } 
    `,
    {
      classId,
      dueDate,
      details,
      name,
      attachmentsIds: Number(attachmentsIds?.length) > 0 ? attachmentsIds : undefined
    }
  ), {
    onSuccess: () => {}
  });
  return Mutation;
};
