import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { UpdateTeacherProps } from './types';

export const useUpdateTeacher = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ userId, acuityCalendarId, attachmentId, city, clickupFolderId, clickupPk, country, courses, degree, details, email, firstName, lastName, phone, tutoringHours, university, yearsOfExperience, rate }: UpdateTeacherProps) => client.request(
    gql`
    mutation AdminUpdateTeacher($acuityCalendarId: Float!, $city: String!, $clickupFolderId: Float!, $clickupPk: String!, $country: String!, $courses: [String!]!, $degree: String!, $details: String!, $firstName: String!, $lastName: String!, $phone: String!, $university: String!, $userId: Float!, $yearsOfExperience: Float!, $attachmentId: Float, $tutoringHours: Float, $rate: Float) {
      adminUpdateTeacher(acuityCalendarId: $acuityCalendarId, city: $city, clickupFolderId: $clickupFolderId, clickupPK: $clickupPk, country: $country, courses: $courses, degree: $degree, details: $details, firstName: $firstName, lastName: $lastName, phone: $phone, university: $university, userId: $userId, yearsOfExperience: $yearsOfExperience, attachmentId: $attachmentId, tutoringHours: $tutoringHours, rate: $rate) {
        message
      }
    }
    `,
    {
      acuityCalendarId,
      city,
      clickupFolderId,
      clickupPk,
      country,
      courses,
      degree,
      details,
      email,
      firstName,
      lastName,
      phone,
      university,
      yearsOfExperience,
      attachmentId,
      tutoringHours,
      userId,
      rate
    }
  ));
  return Mutation;
};
