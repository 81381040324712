import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useDeleteCallLog = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async (callLogId?: number) => client.request(
    gql`
    mutation AdminDeleteCallLog($callLogId: Float!) {
      adminDeleteCallLog(callLogId: $callLogId) {
        message
      }
    }
    `,
    {
      callLogId
    }
  ));
  return Mutation;
};
