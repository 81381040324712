// Types
import { UserDraftsQuery } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useUserDraft = (enabled?: boolean) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<UserDraftsQuery, Error> = useQuery<UserDraftsQuery, Error>(['userDraft'], async () => client.request(
    gql`
    query UserViewDrafts {
      userViewDrafts {
        id
        userId
        planId
        courseId
        createdAt
        updatedAt
        plan {
          name
          id
          sessions_count
          price
        }
        course {
          name
          id
        }
      }
    }
      
    `,
  ).catch((error) => console.error(error)), { enabled });
  return data;
};
