import toast from 'react-hot-toast';

export const base64 = () => {
  const isValidImage = async (file: File): Promise<boolean> => new Promise(
    (resolve) => {
      if (file) {
        const url = window.URL || window.webkitURL;
        const image = new Image();
        image.onload = () => {
          resolve(true);
        };
        image.onerror = () => {
          resolve(false);
        };
        image.src = url.createObjectURL(file);
      }
      resolve(false);
    }
  );

  const getBase64 = async (file: File) => new Promise((resolve, reject) => {
    const isImage = isValidImage(file).then((res) => res);
    if (!file) {
      const err = 'Please select a file.';
      reject(err);
      toast(err);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        result: reader.result,
        type: file.type,
        name: file.name,
        isImage
      });
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

  return {
    getBase64,
    isValidImage
  };
};
