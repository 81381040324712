import { gql } from 'graphql-request';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useDeleteComment = () => {
  type Comment = {
    commentId: number,
    classId: number,
    postId?: number
  };
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ commentId, classId, postId }: Comment) => client.request(
    gql`
    mutation Mutation($commentId: Float!, $postId: Float!, $classId: Float!) {
      deleteComment(commentId: $commentId, postId: $postId, classId: $classId) {
        message
      }
    }
    `,
    {
      commentId,
      postId,
      classId
    }
  ).catch((error) => toast(error.response.errors[0].message)), {
    onSuccess: (data: any) => { toast(data?.deletePost?.message); }
  });
  return Mutation;
};
