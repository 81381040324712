// Types
import { Checkbox as IProps } from './Checkbox.types';

// Styling
import './Checkbox.scss';

// UUID
import { v4 as uuid } from 'uuid';

export const Checkbox = ({ disabled, label, ...rest }: IProps) => {
  const uid = uuid();
  return (
    <div className={`simple-checkbox ${disabled && 'disabled'}`}>
      <input
        type="checkbox"
        id={uid}
        {...rest}
        disabled={disabled}
      />
      <label htmlFor={uid}>
        <i className="icon-checkmark" />
        {label && `${label}`}
      </label>
    </div>
  );
};
