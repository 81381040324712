// Types
import { SessionAssignments } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useHomeworkRecords = (userId: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<SessionAssignments, Error> = useQuery<SessionAssignments, Error>(['homework-records', userId], async () => client.request(
    gql`
    query AdminGetUserClassAss($userId: Float!) {
      adminGetUserClassAss(userId: $userId) {
        plan {
          course {
            name
          }
        }
        id
        classId
        status
        totalHomework
        totalMissedHomework
        lowestMark
        assignments {
          id
          receivedMark
          status
          fullMark
          createdAt
        }
      }
    }
    `,
    {
      userId
    }
  ).catch((error) => console.error(error)));
  return data;
};
