import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TeachersSlice } from '../TeachersSlice';

export const useLogic = () => {
  const dispatch = useDispatch();
  const teachersState = useSelector((state: { teachers: TeachersSlice }) => state.teachers);

  const filters = [
    { filter: 'Subjects', active: teachersState.filter.courses },
    { filter: 'Country', active: teachersState.filter.country },
    { filter: 'Status', active: teachersState.filter.status },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filters?.[0]?.filter);

  return {
    filters,
    selectedFilter,
    setSelectedFilter,
    dispatch,
    teachersState
  };
};
