import Button from 'components/General/Button/Button';
import { setFilter, toggleFiltersModal } from '../../StudentsSlice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import './EnrollmentDate.scss';
import { DatePicker } from 'react-rainbow-components';

export const EnrollmentDate = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<Date>();

  const handleApplyFilters = () => {
    dispatch(setFilter({ enrollment: value }));
    dispatch(toggleFiltersModal(false));
  };

  return (
    <div className="search-filter-container">
      <h2>Enrollmen date</h2>
      <div className="rainbow-m-around_small">
        <DatePicker
          placeholder="YYYY-MM-DD"
          labelAlignment="left"
          className="date-picker"
          label="Enrollmen dat"
          icon={<></>}
          onChange={(e) => setValue(e)}
          value={value}
        />
      </div>
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
