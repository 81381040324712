import { FC } from 'react';

// Types
import { TypographyProps } from './Typography.types';

// Style
import './Typography.scss';

const Typography: FC<TypographyProps> = ({ children, color, className, ...rest }) => (
  <p
    className={`typography ${color} ${className}`}
    {...rest}
  >
    {children}
  </p>
);

export default Typography;
