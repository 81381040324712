// Components
import Button from 'components/General/Button/Button';
import OnboardingCard from 'components/Onboarding/OnboardingCard/OnboardingCard';
import TextField from 'components/General/TextField/TextField';

// Logic
import { useLogic } from './FamilyInfo.logic';

// Grid system
import { Col, Row } from 'react-grid-system';

// React hook form
import { Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { STUDENT_DASHBOARD } from 'routes/CONSTANTS';
import { PhoneInput } from 'react-rainbow-components';

const FamilyInfo = () => {
  const { control, onSubmit, errors, phoneError, dispatch, previousPage } = useLogic();
  const navigate = useNavigate();
  const { userId } = useParams();

  return (
    <Row>
      <Col
        md={6}
        offset={{ md: 3 }}
      >
        <OnboardingCard>
          <h2 className="mt-3">Tell us about your family</h2>
          <form onSubmit={onSubmit}>
            <Controller
              control={control}
              name="guardianName"
              rules={{
                required: 'This field is required',
                minLength: {
                  value: 2,
                  message: 'Name should be longer than 2 characters'
                },
                maxLength: {
                  value: 20,
                  message: 'Name cannot be longer than 20 characters'
                }
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  color="light-gray"
                  variant="rounded"
                  placeholder="Father name"
                  id="guardian-name"
                  label="Guaardian"
                  type="text"
                  onChange={onChange}
                  error={errors?.guardianName?.message}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name="guardianNumber"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                minLength: {
                  value: 8,
                  message: 'Number should be longer than 8 characters'
                },
                maxLength: {
                  value: 20,
                  message: 'Number cannot be longer than 20 characters'
                }
              }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  label="Guardian's Phone Number"
                  placeholder="70123456"
                  onChange={onChange}
                  value={value}
                  className={`phone-input ${`${phoneError && 'error'}`}`}
                />
              )}
            />
            <Controller
              control={control}
              name="guardianEmail"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                }
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="name@mail.com"
                    id="guardian-number"
                    label="Guardian's email"
                    type="email"
                    error={errors.guardianEmail?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
            <div className="button-container mb-1">
              <Button
                color="primary"
                type="submit"
              >
                Next
              </Button>
            </div>
          </form>
          <div className="skip-container mb-3">
            <div className="back-container mb-2">
              <Button
                color="white"
                onClick={() => dispatch(previousPage())}
              >
                Back

              </Button>
            </div>
          </div>
          {!userId && (
          <h3>
            <a
              href="#"
              onClick={() => navigate(STUDENT_DASHBOARD)}
            >
              Skip
            </a>

          </h3>
          )}

        </OnboardingCard>
      </Col>
    </Row>
  );
};
export default FamilyInfo;
