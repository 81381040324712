import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminSetManuallPayment = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ classId }: { classId: number }) => client.request(
    gql`
    mutation AdminSetClassManualPayment($classId: Float!) {
      adminSetClassManualPayment(classId: $classId) {
        message
      }
    }
    `,
    {
      classId
    }
  ));
  return Mutation;
};
