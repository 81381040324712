import React, { FC, useCallback, useEffect, useState } from 'react';
import { Row as IRow, Cell as ICell, Sort, SORTDIRECTION, TableProps as IProps } from './Table.types';

import './Table.scss';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import Checkbox from '../CheckBox/CheckBox';
import { OverlayLoaderTop } from 'components/General/Loader/Loader';

// Components

const Row: FC<IRow> = ({ head, children, top, background }) => (
  <div
    className={`table-row${head ? ' table-head' : ' table-row-data'}`}
    style={{
      transform: `translateY(${top}px )`,
      backgroundColor: `${background ? '#DFE2E5' : ''}`,
      paddingBottom: `${head && 0}`
    }}
  >
    {children}
  </div>
);

const Cell: FC<ICell> = ({ onClick, children, align }) => (
  <div
    className="table-col"
    onClick={onClick}
    onKeyDown={() => { }}
    tabIndex={0}
    role="button"
    style={{ textAlign: align }}
  >
    {children}
  </div>
);

const Table: FC<IProps> = ({ onSort, isLoading, columns, data }) => {
  const [sort, setSort] = useState<Sort>({ key: '', direction: 'desc' });
  const [hiddenColumns, setHiddenColumns] = useState<number[]>([]);
  const [showColsVisibilit, setShowColsVisibility] = useState<boolean>(false);

  const getOppDirection = (direction: SORTDIRECTION): SORTDIRECTION => (direction === 'asc' ? 'desc' : 'asc');

  const sortCol = (colKey: string) => {
    setSort((prev) => {
      const newSort = {
        direction: prev.key === colKey ? getOppDirection(prev.direction) : 'desc',
        key: colKey
      };
      if (onSort) { onSort(newSort); }
      return newSort;
    });
  };

  useEffect(() => {
    setHiddenColumns(() => {
      const hidden: number[] = [];
      columns.map((item, index) => {
        if (item.hidden) { hidden.push(index); }
        return null;
      });
      return hidden;
    });
  }, []);

  const toggleColumn = (index: number) => {
    const temp = [...hiddenColumns];
    const findIndex = temp.findIndex((item) => item === index);
    if (findIndex !== -1) {
      temp.splice(findIndex, 1);
    } else {
      temp.push(index);
    }
    setHiddenColumns(temp);
  };

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setShowColsVisibility(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <>
      <div
        className="dashboard-table"
      >
        {isLoading && (
          <div className="loading">
            <OverlayLoaderTop />
          </div>
        )}
        <Row
          head
          top={0}
        >
          {
          columns.map((col, index) => (
            <>
              { hiddenColumns.includes(index) ? ''
                : (
                  <Cell
                    onClick={() => { if (col.sortable) sortCol(col.key || ''); }}
                    align={col.align}
                  >
                    {col.value}
                    {sort.key === col.key && <i className={`icon-chevron-down${sort.direction === 'desc' ? ' flip' : ''}`} />}
                  </Cell>
                ) }
            </>
          ))
        }
          <Cell
            onClick={() => setShowColsVisibility(true)}
            align="center"
          >
            <i
              className="icon-grid-o"
              style={{ fontSize: 20 }}
            />
          </Cell>
        </Row>

        { data?.map((row) => (
          <Row
            top={(0)}
          >
            {
              row.map((cell, index) => (
                <>
                  { hiddenColumns.includes(index) ? ''
                    : <Cell key={cell.value?.toString()}>{cell.value}</Cell>}
                </>
              ))
            }
            {/* <Cell align="center">
              <i
                className="icon-chevron-right"
                style={{ fontSize: 10, color: 'var(--gray)' }}
              />
            </Cell> */}
          </Row>
        ))}

        {/* Columns */}
        <div className={`cols-visibility${showColsVisibilit ? ' show' : ''}`}>
          <h2>Columns</h2>
          <ul>
            { columns.map((col, index) => (
              <li>
                <Checkbox
                  checked={!hiddenColumns.includes(index)}
                  onChange={() => {
                    toggleColumn(index);
                  }}
                  label={col.value}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ButtonBase
        tagName="div"
        className={`columns-overlay${showColsVisibilit ? ' show' : ''}`}
        onClick={() => setShowColsVisibility(false)}
      />
    </>
  );
};

export default Table;
