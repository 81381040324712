import { useSelector } from 'react-redux';
import { useTeachersData } from 'services/AdminPanel/Teachers/TeachersData/useTeachersData';
import { TeachersSlice } from '../TeachersSlice';

export const useLogic = () => {
  const teachersState = useSelector((state: { teachers: TeachersSlice }) => state.teachers);
  const { data: statistics, isLoading } = useTeachersData({ filteredBy: teachersState.filter });

  return {
    statistics,
    isLoading
  };
};
