// Types
import { UpcomingEvent as IProps } from './UpcomingEvent.types';

// Styling
import 'assets/scss/spacing.scss';
import 'assets/scss/typography.scss';
import Button from 'components/General/Button/Button';
import './UpcomingEvent.scss';

export const UpcomingEvent = ({ title, date, time, buttonText, disabled, onClick }: IProps) => (
  <div className={`upcoming-event-container ${disabled && 'disabled'}`}>
    <div className="upcoming-event-top">
      <div className="title">
        <h3>{title}</h3>
      </div>
      <div className="button-container">
        <Button
          disabled={disabled}
          color="primary"
          onClick={onClick}
        >
          {buttonText || 'See details'}
        </Button>
      </div>
    </div>
    <div className="upcoming-event-bottom">
      <p>{`Date: ${date}`}</p>
      {time && (
        <p>{`Time: ${time}`}</p>
      )}
    </div>
  </div>
);
