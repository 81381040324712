// Types
import { HomeworkQuery, HomeworkQueryVariables } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useGetHomework = ({ filterBy, desc, limit, offset, search, searchByAssTitle, sortBy } : HomeworkQueryVariables) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<HomeworkQuery, Error> = useQuery<HomeworkQuery, Error>(['homework', filterBy, desc, sortBy, searchByAssTitle, search, offset, limit], async () => client.request(
    gql`
    query TeacherAssignments($filterBy: filterAssignmentsBy, $sortBy: String, $search: String, $searchByAssTitle: String, $desc: Boolean, $limit: Float, $offset: Float) {
      teacherAssignments(filterBy: $filterBy, sortBy: $sortBy, search: $search, searchByAssTitle: $searchByAssTitle, DESC: $desc, limit: $limit, offset: $offset) {
        totalAssignments
        assignments {
          id
          name
          submissionText
          receivedMark
          fullMark
          createdById
          classId
          details
          status
          dueDate
          submittedAt
          createdAt
          updatedAt
          createdBy {
            first_name
            last_name
          }
          attachments {
            id
            attachment
            teacherAttachment
          }
          class {
            user {
              first_name
              last_name
              picture
            }
            plan {
              course {
                name
              }
            }
          }
        }
      }
    }
    `,
    {
      filterBy: {
        courses: filterBy.courses,
        status: filterBy.status
      },
      sortBy,
      search,
      searchByAssTitle,
      desc,
      limit,
      offset
    }
  ).catch((error) => console.error(error)));
  return data;
};
