// Types
import { UserSteps } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useGetUserSteps = (userId: number, courseName: string, enableCourses: boolean) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<UserSteps, Error> = useQuery<UserSteps, Error>(['admin-onboarding-steps', courseName, userId], async () => client.request(
    gql`
    query UserSteps($courseName: String!, $userId: Float!) {
      userSteps(courseName: $courseName, userId: $userId) {
        userSteps {
          id
          active
          order
          name
        }
      }
    }
    `,
    {
      courseName,
      userId
    }
  ).catch((error) => console.error(error)), { enabled: enableCourses });
  return data;
};
