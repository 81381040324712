import { FC } from 'react';

// Types
import { Coupon as IProps } from './Coupon.types';

// Style
import './Coupon.scss';

// Icons
import Tag from 'assets/images/tag.svg';
import { X } from 'react-feather';
import { CircleLoader } from '../Loader/Loader';

const Coupon: FC<IProps> = ({ name, discount, isLoading, onClick }) => (
  <div className="coupon-container">
    <img
      src={Tag}
      alt=""
      className="tag"
    />
    <h4>{`${name} (${discount}%)`}</h4>
    {isLoading ? (
      <CircleLoader />
    ) : (
      <X
        color="white"
        className="close-icon"
        onClick={onClick}
      />
    )}
  </div>
);

export default Coupon;
