// Styling
import './Leads.scss';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight as RightArrowIcon } from '@fortawesome/free-solid-svg-icons';

// Logic
import { useLogic } from './Leads.logic';
import Modal from 'components/General/Modal/Modal';
import { AdminDashboardLayout } from 'layouts/AdminDashboardLayout/AdminDashboardLayout';
import Typography from 'components/General/Typography/Typography';
import Table from 'components/Dashboard/Table/Table';
import Label from 'components/General/Label/Label';
import Avatar from 'components/General/Avatar/Avatar';
import { Statistics } from '../Statistics/Statistics';
import { Filters } from '../Filters/Filters';
import { toggleFiltersModal } from '../LeadsSlice';
import { DASHBOARD, LEAD_PROFILE, NOTES } from 'routes/CONSTANTS';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import moment from 'moment-timezone';
import { LeadStatus } from './LeadStatus/LeadStatus';
import { Payment } from './Payment/Payment';
import stringLimit from 'assets/utils/stringLimit';

export const Leads = () => {
  const {
    generateFilter,
    leadsState,
    dispatch,
    Columns,
    data,
    isLoadingTableData,
    isFetching,
    navigate,
    offset,
    setOffset,
    setSortBy,
    sortBy,
    setUpdateStatus,
    setPayment,
  } = useLogic();

  const NameCol = ({ src, name }: { src: string; name: string; }) => (
    <div className="name-col">
      <Avatar
        src={src}
        size={45}
      />
      <div className="name">
        <Typography
          color="black"
          className="mb-05"
        >
          {name}
        </Typography>
      </div>
    </div>
  );

  return (
    <AdminDashboardLayout>
      <div className="students-container">
        <Filters isLoading={isFetching} />
        <div className="stats-container">
          <Statistics />
        </div>
        <div className="table mt-4">
          <Table
            onSort={({ key, direction }) => {
              const filter = sortBy.find((item) => item.column === key);
              if (filter) {
                // const filtered = sortBy.filter((item) => item.column !== filter.column);
                // return setSortBy([...filtered, { column: key, ASC: direction === 'asc' }]);
                return setSortBy([{ column: key, ASC: direction === 'asc' }]);
              }
              // return setSortBy([...sortBy, { column: key, ASC: direction === 'asc' }]);
              return setSortBy([{ column: key, ASC: direction === 'asc' }]);
            }}
            isLoading={isLoadingTableData}
            columns={Columns}
            data={
              data?.adminAllLeadsStat.leadsStatistics.map((user) => ([
                { value: (
                  <NameCol
                    src={user.picture}
                    name={
                      user.firstName
                        ? `${user.firstName} ${user.lastName}`
                        : (stringLimit(user.email, 13))
                    }
                  />
                )
                },
                { value: <Label color={user.course}>{user.course || '-'}</Label> },
                { value: (
                  <ButtonBase
                    tagName="div"
                    onClick={() => dispatch(setUpdateStatus({ leadId: user.leadId, modal: true }))}
                  >
                    <Label color={user.status}>{user.status}</Label>
                  </ButtonBase>
                )
                },
                { value: <Typography>{user.country || '-'}</Typography> },
                { value: <Typography>{user.number || '-'}</Typography> },
                { value: <Typography>{moment(user.statusDate).fromNow().slice(0, -4)}</Typography> },
                { value: <Typography>{user.planSessionsCount || '-'}</Typography> },
                { value: <Typography>{`${user.completionRate}%` || '-'}</Typography> },
                { value: <Typography>{user.futureCall ? moment(user.futureCall).fromNow() : '-'}</Typography> },
                { value: <Typography>{user.createdAt ? moment(user.createdAt).format('YYYY-MM-DD') : '-'}</Typography> },
                { value: <FontAwesomeIcon
                  icon={RightArrowIcon}
                  className="arrow-icon"
                  color="#696C71"
                  onClick={() => navigate(`/${DASHBOARD}/${LEAD_PROFILE(String(user.userId), String(user.leadId), String(user.course))}/${NOTES}`)}
                /> },
              ]))
            }
          />
        </div>
        {!isLoadingTableData && (
          <div className="pagination">
            <ButtonBase
              tagName="i"
              onClick={() => {
                if (offset < 20) return;
                setOffset((prev) => prev - 20);
              }}
            >
              <i className={`icon-chevron-left ${offset < 20 && 'disabled'}`} />
            </ButtonBase>
            <Typography>
              {`${offset} - ${Number(data?.adminAllLeadsStat.totalLeads) - offset > 20 ? offset + 20 : data?.adminAllLeadsStat.totalLeads} of ${data?.adminAllLeadsStat.totalLeads}`}
            </Typography>
            <ButtonBase
              tagName="i"
              onClick={() => {
                if (Number(data?.adminAllLeadsStat.totalLeads) - offset <= 20) return;
                setOffset((prev) => prev + 20);
              }}
            >
              <i className={`icon-chevron-right ${(Number(data?.adminAllLeadsStat.totalLeads) - offset <= 20) && 'disabled'}`} />
            </ButtonBase>
          </div>
        )}
        <Modal
          isClosble
          isOpen={leadsState.filtersModal}
          onRequestClose={() => dispatch(toggleFiltersModal(false))}
        >
          {generateFilter(leadsState.filter.selectedFilter)}
        </Modal>
        <Modal
          isClosble
          isOpen={leadsState.status.modal}
          onRequestClose={() => dispatch(setUpdateStatus({ modal: false }))}
          width={400}
        >
          <LeadStatus />
        </Modal>
        <Modal
          isClosble
          isOpen={leadsState.payment.modal}
          onRequestClose={() => dispatch(setPayment({ modal: false }))}
        >
          <Payment />
        </Modal>
      </div>
    </AdminDashboardLayout>
  );
};
