import { StatisticsCard } from 'components/Dashboard/StatisticsCard/StatisticsCard';
import { useLogic } from './Statistics.logic';
import ArrowsIcon from 'assets/images/arrows-up-down.svg';
import ChatIcon from 'assets/images/active-student.svg';

// Styling
import './Statistics.scss';

export const Statistics = () => {
  const { statistics, isLoading } = useLogic();
  return (
    <div className="statistics-cards-container">
      <StatisticsCard
        image={ArrowsIcon}
        count={statistics?.adminStatTotalUsers.usersTotal}
        description="Total number of students"
        isLoading={isLoading}
      />
      <StatisticsCard
        image={ChatIcon}
        count={statistics?.adminStatTotalUsers.activeUsersTotal}
        description="Active students"
        isLoading={isLoading}
      />
    </div>
  );
};
