import { gql } from 'graphql-request';
import { useMutation, useQuery, UseQueryResult } from 'react-query';
import { useAuth } from 'routes/Auth/AuthProvider';
import { axiosStripe, axiosSetOut } from 'services/config/axiosConfig';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { AvailableDates, AvailableDatesResponse, AvailableTimes, AvailableTimesResponse, FirstAvailableMonth, FirstAvailableMonthResponse, UserInfoResponse } from './types';

// Get
export const useCourse = (course: string, enableCourses: boolean) => {
  const { user } = useAuth();
  return useQuery(['onboarding-course', course, user?.user.id], () => axiosSetOut().get(`user/steps/${course}`).then((res) => res.data), { enabled: !!enableCourses });
};

export const usePlan = (plan: string, enableCourses: boolean) => {
  const { user } = useAuth();
  return useQuery(['onboarding-plan', user?.user.id], () => axiosSetOut().get(`/user/course/plans/${plan}`).then((res) => res.data), { enabled: !!enableCourses });
};

// Post
export const postPlan = (variables: { plan_id: string; course_id: string; }) => axiosSetOut().post('user/course-plan', variables).then((res) => res.data);
export const postPersonalInfo = (variables: { first_name: string; last_name: string; birthday: string; phoneNumber: string, step_id: number }) => axiosSetOut().post('user/personalInfo', variables).then((res) => res.data);
export const postSchoolInfo = (variables: { country: string; school_name: string; graduation_year: string; course_name: string | undefined; step_id: number; scores: any }) => axiosSetOut().post('user/schoolInfo', variables).then((res) => res.data);
export const postFamilyInfo = (variables: { guardain_name: string; guardain_number: string; guardain_email: string; step_id: number; }) => axiosSetOut().post('user/familyInfo', variables).then((res) => res.data);

// Acuity dates
export const useAcuityFirstAvailableMonth = (variables: FirstAvailableMonth) => useQuery<FirstAvailableMonthResponse, Error>(['first-available-month', variables], async (): Promise<any> => axiosSetOut().post('/acuity/availability/month/date', variables), { enabled: !!variables.appointmentTypeID && !!variables.calendarID });
export const useAcuityAvailableDates = (variables: AvailableDates) => useQuery<AvailableDatesResponse, Error>(['available-dates', variables], async (): Promise<any> => axiosSetOut().post('/acuity/availability/dates', variables), { enabled: !!variables.appointmentTypeID && !!variables.calendarID && !!variables.month });
export const useAcuityAvailableTimes = (variables: AvailableTimes) => useQuery<AvailableTimesResponse, Error>(['available-times', variables], async (): Promise<any> => axiosSetOut().post('/acuity/availability/times', variables), { enabled: !!variables.appointmentTypeID && !!variables.calendarID && !!variables.date });

export const usePostAcuityAppointment = (variables: { planId: number | undefined; teacherId: number | undefined; datetime: Array<string>, calendarId?: number }, enabled: boolean) => useQuery(['appointment-type'], () => axiosSetOut().post('/acuity/appointments', variables).then((res) => res.data), { enabled });

// Stripe
export const getStripeCardToken = (variables: { card: { number: string; exp_month: number; exp_year: number; cvc: string } }) => {
  const params = new URLSearchParams();
  params.set('card[number]', variables.card.number);
  params.set('card[exp_month]', String(variables.card.exp_month));
  params.set('card[exp_year]', String(variables.card.exp_year));
  params.set('card[cvc]', variables.card.cvc);
  return axiosStripe.post('tokens', params);
};
export const getStripeClientSecret = (variables: { amount: number | undefined; currency: string | undefined; customerStripeId: string | undefined }) => axiosSetOut().post('/stripe/create-payment-intent', variables);
export const confirmPayment = (variables: { classId: number; intentId: string; }) => axiosSetOut().post('/stripe/confirm-payment', variables);

// Schools
export const useSchools = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async (keyword: string) => client.request(
    gql`
    query UserGetSchools($name: String!, $limit: Float!, $offset: Float!) {
      userGetSchools(name: $name, limit: $limit, offset: $offset) {
        id
        name
      }
    }
    `,
    {
      name: keyword,
      limit: 5,
      offset: 0
    }
  ));
  return Mutation;
};

// Get userinfo
export const useUserInfo = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<UserInfoResponse, Error> = useQuery<UserInfoResponse, Error>(['userinfo'], async () => client.request(
    gql`
    query UserGetInfo {
      userGetInfo {
        id
        first_name
        last_name
        email
        birthday
        status
        picture
        phoneNumber
        info {
          country
          school_name
          graduation_year
          guardain_name
          guardain_number
          guardain_email
          done_courses {
            IELTS {
              taken
              scores {
                englishScore
                totalScore
                mathScore
                verbalScore
                QuantitativeScore
                reading
                writing
                speaking
                listening
              }
            }
            SAT {
              taken
              scores {
                englishScore
                totalScore
                mathScore
                verbalScore
                QuantitativeScore
                reading
                writing
                speaking
                listening
              }
            }
            GMAT {
              taken
              scores {
                englishScore
                totalScore
                mathScore
                verbalScore
                QuantitativeScore
                reading
                writing
                speaking
                listening
              }
            }
          }
        }
      }
    }
    `
  ));
  return data;
};
