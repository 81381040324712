// Components
import Button from 'components/General/Button/Button';

// Logic
import { useLogic } from '../Payment.logic';

export const Google = () => {
  const { dispatch, setChosePaymentMethod } = useLogic();

  return (
    <div className="google-container">
      <h1>Google</h1>
      <Button
        color="primary"
        onClick={() => dispatch(setChosePaymentMethod(false))}
      >
        Back to payment
      </Button>
    </div>
  );
};
