import { gql } from 'graphql-request';
import { AssignmentSliceType } from 'pages/Dashboard/Course/Assignment/Assignments.types';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useUpdatePracticeTest = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ details, dueDate, name, id, marks, addAttachmentsIds, deleteAttachmentsNames }: AssignmentSliceType) => client.request(
    gql`
    mutation AdminUpdateTest($details: String, $dueDate: String, $status: String, $name: String, $deleteAttachmentsNames: [String!], $addAttachmentsIds: [Float!], $testId: Float!, $gmatMarks: GMATScores, $ieltsMarks: IELTSScores, $satMarks: SATScores) {
      adminUpdateTest(details: $details, dueDate: $dueDate, status: $status, name: $name, deleteAttachmentsNames: $deleteAttachmentsNames, addAttachmentsIds: $addAttachmentsIds, testId: $testId, GMATMarks: $gmatMarks, IELTSMarks: $ieltsMarks, SATMarks: $satMarks) {
        id
        createdById
        classId
        receivedMark {
          QuantitativeScore
          englishScore
          listening
          mathScore
          reading
          speaking
          totalScore
          verbalScore
          writing
        }
        details
        name
        status
        dueDate
        attachments {
          id
          attachment
          teacherAttachment
        }
      }
    }   
    `,
    {
      testId: Number(id) || undefined,
      dueDate: dueDate || undefined,
      details: details || undefined,
      name: name || undefined,
      addAttachmentsIds: Number(addAttachmentsIds?.length) > 0 ? addAttachmentsIds : undefined,
      deleteAttachmentsNames: Number(deleteAttachmentsNames?.length) > 0 ? deleteAttachmentsNames : undefined,
      gmatMarks: marks?.gmatMarks,
      ieltsMarks: marks?.ieltsMarks,
      satMarks: marks?.saTMarks,
    },
  ).catch((error) => toast(error.response.errors[0].message)), {
    onSuccess: () => {}
  });
  return Mutation;
};
