// Types
import { DashboardNavbar as IProps } from './DashboardNavbar.types';

// Assets
import logo from 'assets/images/SetOut.svg';

// Stylesheet
import './DashboardNavbar.scss';

// Icons
import SearchIcon from 'assets/images/search.svg';

import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';

import OptionsMenu from 'components/General/OptionsMenu/OptionsMenu';
import { useState } from 'react';
import { useAuth } from 'routes/Auth/AuthProvider';
import Avatar from 'components/General/Avatar/Avatar';
import { useRoles } from 'assets/utils/useRoles';
import { NotificationButton, Notification } from '../Notifications/Notification';
import { useUserInfo } from 'services/Onboarding/General/useOnboarding';

export const DashboardNavbar = ({ onClick }: IProps) => {
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { signout } = useAuth();
  const { isAdmin } = useRoles();
  const { data: userInfo } = useUserInfo();
  const name = `${userInfo?.userGetInfo.first_name || ''} ${userInfo?.userGetInfo.last_name || ''}`;
  const image = String(userInfo?.userGetInfo.picture);

  return (
    <div className="dashboard-navbar-container">
      <nav>
        <div className="logo-container">
          <div className="right-container">
            <img
              src={logo}
              alt=""
            />
          </div>
          {isAdmin && (
            <ButtonBase
              tagName="div"
              onClick={onClick}
            >
              <div className="search">
                <img
                  src={SearchIcon}
                  alt=""
                />
                <h3>Search</h3>
              </div>
            </ButtonBase>
          )}
        </div>
        <div className="name-container">
          <ButtonBase
            className="notification"
            tagName="div"
            onClick={(event) => {
              event.stopPropagation();
              setShowNotification((prev) => !prev);
              setShowOptionsMenu(false);
            }}
          >
            <NotificationButton />
            <Notification
              isOpen={showNotification}
              onRequestClose={() => setShowNotification(false)}
            />
          </ButtonBase>
          <ButtonBase
            tagName="div"
            className="image"
            onClick={(event) => {
              event.stopPropagation();
              setShowOptionsMenu((prev) => !prev);
              setShowNotification(false);
            }}
          >
            <Avatar
              src={image}
              size={40}
            />
            <OptionsMenu
              header={(
                <div className="options-menu-header">
                  <Avatar
                    src={image}
                    size={40}
                  />
                  <div className="details">
                    <div>{userInfo?.userGetInfo.email}</div>
                    <div className="name">{name || ''}</div>
                  </div>
                </div>
            )}
              isOpen={showOptionsMenu}
              onRequestClose={() => setShowOptionsMenu(false)}
              options={[{ label: 'Logout', icon: <i className="icon-collapse" />, onClick: () => signout() }]}
            />
          </ButtonBase>
          <h3>{name}</h3>
        </div>
      </nav>
    </div>
  );
};
