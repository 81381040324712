// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { FinalTestsScoresProps } from './types';

export const useGetFinalTests = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<FinalTestsScoresProps, Error> = useQuery<FinalTestsScoresProps, Error>(['final-tests'], async () => client.request(
    gql`
    query UserTodoCompleteClassInfo($informationType: String!,$informationType2: String!) {
      finalTest: userTodoCompleteClassInfo(informationType: $informationType) {
         id
         finalTest
         classId
         plan {
           course {
             name
           }
         }
       }
       targetScore: userTodoCompleteClassInfo(informationType: $informationType2) {
         id
         targetScore
         classId
         plan {
           course {
             name
           }
         }
       }
     }
    `,
    {
      informationType: 'Final test',
      informationType2: 'Target score',
    }
  ).catch((error) => console.error(error)));
  return data;
};
