// React Calendar
import Calendar from 'react-calendar';

// uuid
import { v4 as uuidv4 } from 'uuid';

// Components
import Button from 'components/General/Button/Button';
import TimeSlot from 'components/Onboarding/TimeSlot/TimeSlot';

// Assets
import NoTeacher from 'assets/images/select_teacher.svg';

// Redux
import { setCalendarDate, setCalendarMonth } from './Calendar.slice';

// Logic
import { useLogic } from './Calendar.logic';

// Moment
import moment from 'moment-timezone';
// React
import { FC } from 'react';

// Components
import { OverlayLoader, SectionLoader } from 'components/General/Loader/Loader';

// Tooltip
import ReactTooltip from 'react-tooltip';

// Redux
import onboarding from 'pages/Onboarding/OnboardingSlice';
import { Col, Row } from 'react-grid-system';
import { Toggle } from 'components/Dashboard/CheckBox/CheckBox';
import './Calendar.scss';
import Dropdown from 'components/General/Dropdown/Dropdown';

const ScheduleCalendar: FC<any> = () => {
  const {
    availableDates,
    isLoadingAvailableDates,
    isLoadingInitialAvailableDates,
    isLoadingAvailableTimes,
    infoMessage,
    setInfoMessage,
    onboardingState,
    dispatch,
    acuityCalendarID,
    acuityTypeID,
    calendarDate,
    firstAvailableMonth,
    isLoadingInitialFirstAvailableMonth,
    isLoadingFirstAvailableMonth,
    calendarView,
    setCalendarView,
    handleComplaint,
    setComplaintModal,
    userId,
    activeOpenCalendar,
    setActiveOpenCalendar,
    timeSlots,
    isLoadingAllTimeSlots,
    isLoading,
    setShowConfirmation,
    setSelectedTeacher,
    durations,
    duration,
    setDuration,
    getTimeSlotDuration,
    isAdmin,
    appointmentTypeIds,
    className
  } = useLogic();

  return (
    <div className="calendar-card">
      {acuityCalendarID
        ? (
          <>
            {isLoadingInitialAvailableDates || isLoadingInitialFirstAvailableMonth ? <SectionLoader />
              : (
                <>
                  <div
                    className="calendar-container"
                    key={firstAvailableMonth?.data}
                  >
                    {(isLoadingAvailableDates || isLoadingFirstAvailableMonth) && <OverlayLoader />}
                    {infoMessage && calendarView === 'month' && (
                      <div className="overlay-info">
                        <h3>No available dates this month. Please check another month</h3>
                      </div>
                    )}
                    {userId && (
                      <>
                        <div className="admin-open-calendar">
                          <h3>Activate open calendar</h3>
                          <Toggle
                            checked={activeOpenCalendar}
                            onChange={() => setActiveOpenCalendar((prev) => !prev)}
                          />
                        </div>
                        <div className="slots-duration mb-2">
                          <Dropdown
                            options={durations.map((item) => String(item?.time))}
                            onChange={(e) => setDuration(e.target.value)}
                            value={duration}
                          />
                        </div>
                      </>
                    )}
                    <Calendar
                      defaultValue={firstAvailableMonth?.data ? new Date(moment(firstAvailableMonth?.data).toDate().toLocaleDateString()) : new Date()}
                      defaultActiveStartDate={firstAvailableMonth?.data ? new Date(moment(firstAvailableMonth?.data).toDate().toLocaleDateString()) : new Date()}
                      maxDate={acuityTypeID ? undefined : new Date()}
                      onActiveStartDateChange={(props) => {
                        if (props.view === 'month') {
                          dispatch(setCalendarMonth(`${moment(props.activeStartDate).year()}-${moment(props.activeStartDate).month() <= 8 ? `0${moment(props.activeStartDate).month() + 1}` : moment(props.activeStartDate).month() + 1}`));
                        }
                      }}
                      onChange={(date: Date) => dispatch(setCalendarDate(date))}
                      minDate={acuityTypeID ? undefined : new Date()}
                      tileDisabled={({ date, view }) => {
                        if (view !== 'month') { return false; }
                        const dates = availableDates?.data?.map((availableDate) => new Date(availableDate.date).toLocaleDateString());
                        if (activeOpenCalendar) {
                          return moment(moment().subtract(1, 'day')).diff(date.toLocaleDateString()) >= 0;
                        }
                        return dates?.filter((availableDate) => moment(date.toLocaleDateString()).isSame(availableDate)).length === 0;
                      }}
                      tileClassName={({ date }) => {
                        if (!availableDates?.data?.length) {
                          setInfoMessage(true);
                        } else {
                          setInfoMessage(false);
                        }
                        const dates = onboardingState.selectedTimeSlots?.map((availableDate) => new Date(availableDate.datetime).toLocaleDateString());
                        return dates?.filter((availableDate) => moment(date.toLocaleDateString()).isSame(availableDate)).length > 0 ? 'selected-date' : '';
                      }}
                      onViewChange={(props) => setCalendarView(props.view)}
                    />
                  </div>
                  {isLoading || (isLoadingAllTimeSlots) || (isLoadingAvailableTimes && !isLoadingAvailableDates && !isLoadingFirstAvailableMonth) ? <div className="pt-3 pb-3"><SectionLoader /></div>
                    : (
                      <div className="available-times">
                        <Row
                          style={{ rowGap: 35 }}
                          className="time-slots"
                        >
                          {timeSlots ? (
                            timeSlots.map((item) => (
                              <Col
                                xs={6}
                                md={6}
                                key={uuidv4()}
                                // style={{ padding: 0 }}
                              >
                                <TimeSlot
                                  addTime={{
                                    hours: duration.split(' ')[0],
                                    type: duration.split(' ')[1].includes('hour') ? 'hours' : 'minutes'
                                  }}
                                  time={item}
                                  onClick={() => dispatch(onboarding.actions.toggleTimeSlot({
                                    datetime: item,
                                    appointmentTypeId: appointmentTypeIds[className || 'SAT'][durations.indexOf(durations.find((el) => el?.time === duration))],
                                    totalTime: getTimeSlotDuration(item),
                                    length: durations.find((el) => el?.time === duration)?.length
                                  }))}
                                  selected={onboardingState.selectedTimeSlots.some((slot) => slot.datetime.includes(item))}
                                  deny={!isAdmin
                                    && (// If the user scheduled all required sessions
                                      onboardingState.totalTime === onboardingState.plan.hours
                                    // Prevent the user from selecting two sessions in the same day
                                    || onboardingState.selectedTimeSlots.filter((time) => moment(new Date(time.datetime).toLocaleDateString()).isSame(calendarDate?.toLocaleDateString())).length > 0)}
                                />
                              </Col>
                            ))
                          ) : (
                            <div>
                              <h4>No available times</h4>
                            </div>
                          )}
                        </Row>
                      </div>
                    )}

                  <div className="buttons-container mt-3">
                    <div className="confirmation-button">
                      <Button
                        onClick={() => { if (onboardingState.selectedTimeSlots.length === onboardingState.plan.sessionsCount) dispatch(setShowConfirmation(true)); }}
                        color="primary"
                        data-tip={onboardingState.selectedTimeSlots.length < (onboardingState.plan.sessionsCount || 0) ? `Please select a time for all ${onboardingState.plan.sessionsCount} sessions.` : ''}
                        data-place="top"
                        data-type="warning"
                        data-effect="solid"
                      >
                        { onboardingState.totalTime === onboardingState.plan.hours ? 'Next'
                          : (
                            <>
                              {`${onboardingState.totalTime} out of ${onboardingState.plan.hours}`}
                            </>
                          ) }
                      </Button>
                      <Button
                        color="white"
                        onClick={() => {
                          dispatch(setSelectedTeacher(false));
                        }}
                      >
                        Select another teacher
                      </Button>
                    </div>
                    <ReactTooltip />
                    <Button
                      color="white"
                      onClick={() => {
                        dispatch(setComplaintModal(true));
                        handleComplaint();
                      }}
                    >
                      I cannot find times that work for me.
                    </Button>
                  </div>
                </>
              )}

          </>
        )
        : (
          <>
            <img
              src={NoTeacher}
              alt="Select teacher"
            />
            <h3 className="text-center">Please select a teacher</h3>
          </>
        )}
    </div>
  );
};

export default ScheduleCalendar;
