// Styling
import './CashTransactionValidation.scss';

// Components
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';

// Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck as check } from '@fortawesome/free-solid-svg-icons';
import { useLogic } from './CashTransactionValidation.logic';
import DocumentIcon from 'assets/images/document-icon.svg';
import moment from 'moment-timezone';
import Button from 'components/General/Button/Button';
import { Textarea } from 'react-rainbow-components';

export const CashTransactionValidation = () => {
  const {
    studentsState,
    isImage,
    confirmed,
    setConfirmed,
    handleConfirmTransaction,
    isLoading,
    setRejectionReason
  } = useLogic();
  return (
    <div className="cash-transaction-validation-container">
      <div className="title">
        <h2>Uploaded reciept</h2>
      </div>
      <div className="submission-date">
        <p>{`Submission date: ${moment(studentsState.paymentConfirmation.date).format('MM/DD/YYYY')}`}</p>
      </div>
      <div className="cash-transaction-switch-card">
        <div className="left">
          {studentsState.paymentConfirmation.attachments?.map((attachment) => (
            <ButtonBase
              tagName="div"
              onClick={(e) => {
                e.stopPropagation();
                window.open(attachment.attachment, '_blank');
              }}
            >
              {isImage(attachment.attachment) ? (
                <img
                  src={attachment.attachment}
                  alt=""
                />
              ) : (
                <div className="document-icon">
                  <img
                    src={DocumentIcon}
                    alt=""
                  />
                </div>
              )}
            </ButtonBase>
          ))}
        </div>
        <div className="right">
          <div className="buttons">
            <Button
              variant="small"
              color="green"
              onClick={() => setConfirmed(true)}
            >
              Accept
            </Button>
            <Button
              variant="small"
              color="fuchsia"
              onClick={() => setConfirmed(false)}
            >
              Reject
            </Button>
          </div>
          <span className={`icon-cricle ${confirmed ? 'selected' : 'not-selected'}`}>
            <FontAwesomeIcon
              icon={check}
              className="icon"
              color="#FFF"
            />
          </span>
        </div>
      </div>
      { (confirmed !== undefined && !confirmed) && (
        <div className="rejection-reason">
          <h3>Rejection reason</h3>
          <Textarea
            placeholder="type your reason..."
            onChange={(e) => setRejectionReason(e.target.value)}
          />
        </div>
      )}
      <Button
        onClick={handleConfirmTransaction}
        isLoading={isLoading}
        color="primary"
      >
        Save
      </Button>
    </div>
  );
};
