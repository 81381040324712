import NewPassword from 'pages/Authentication/NewPassword/NewPassword';
import SignIn from 'pages/Authentication/SignIn/SignIn';
import SignUp from 'pages/Authentication/SignUp/SignUp';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import RequireAuth from './Auth/RequireAuth';
import { FORGOT_PASSWORD, HOME, RESET_PASSWORD, RESET_VERIFY, SIGNIN, SIGNUP, SIGNUP_VERIFY, ONBOARDING, ONBOARDING_COURSES, CLASS, STUDENT_DASHBOARD, DASHBOARD, STUDENTS, TODOS, CLASSES, STUDENT_PROFILE, PAYMENT_STATUS, COURSE_ACTIVITY, NOTES, ENROLLMENTS, PROGRESS_DIAGRAM, ATTENDANCE_RECORDS, HOMEWORK_RECORDS, CALENDAR, LEADS, LEAD_PROFILE, ADMIN_ONBOARDING, ADMIN_ONBOARDING_COURSES, TEACHER_DASHBOARD, TEACHER_TODOS, TEACHER_CALENDAR, TEACHER_CLASSES, TEACHER_HOMEWORK, TEACHER_STUDENTS, STREAM, ASSIGNMENTS, TESTS, SESSIONS_RECORDINGS, TEACHERS, PRIVACY } from './CONSTANTS';
import ForgotPassword from 'pages/Authentication/ForgotPassword/ForgotPassword';
import SignupVerification from 'pages/Authentication/SignupVerification/SignupVerification';
import PasswordVerification from 'pages/Authentication/PasswordVerification/PasswordVerification';
import Onboarding from 'pages/Onboarding/Onboarding';
import CoursesCard from 'pages/Onboarding/Courses/CoursesCard';
import { useEffect } from 'react';
import { AxiosConfig } from 'services/config/axiosConfig';
import StudentDashboard from 'pages/Dashboard/Student/Dashboard';
import { useRoles } from 'assets/utils/useRoles';
import { useAuth } from './Auth/AuthProvider';
import { useUserInfo } from 'services/Onboarding/General/useOnboarding';
import { Classes } from 'pages/Dashboard/Admin/Classes/Classes';
import { Todos } from 'pages/Dashboard/Admin/Todos/Todos';
import { PersonalInfoLayout } from 'layouts/PersonalInfoLayout/PersonalInfoLayout';
import { Students } from 'pages/Dashboard/Admin/Students/Students/Students';
import { PaymentStatus } from 'pages/Dashboard/Admin/Students/PersonalInfo/PaymentStatus/PaymentStatus';
import { Notes } from 'pages/Dashboard/Admin/Students/PersonalInfo/Notes/Notes';
import { Sessions } from 'pages/Dashboard/Admin/Students/PersonalInfo/Sessions/Sessions';
import { ProgressDiagram } from 'pages/Dashboard/Admin/Students/PersonalInfo/CourseActivity/ProgressDiagram/ProgressDiagram';
import { AttendanceRecords } from 'pages/Dashboard/Admin/Students/PersonalInfo/CourseActivity/AttendanceRecords/AttendanceRecords';
import { HomeworkRecords } from 'pages/Dashboard/Admin/Students/PersonalInfo/CourseActivity/HomeworkRecords/HomeworkRecords';
import { CourseActivityLayout } from 'layouts/CourseActivityLayout/CourseActivityLayout';
import { useUserDraft } from 'services/Onboarding/Draft/Queries/userDrafts/useUserDraft';
import { OverlayLoader } from 'components/General/Loader/Loader';
import { Calendar } from 'pages/Dashboard/Admin/Calendar/Calendar';
import { Leads } from 'pages/Dashboard/Admin/Leads/Leads/Leads';
import { LeadPersonalInfoLayout } from 'layouts/LeadPersonalInfoLayout/LeadPersonalInfoLayout';
import { LeadNotes } from 'pages/Dashboard/Admin/Leads/PersonalInfo/LeadNotes/LeadNotes';
import { Todos as TeacherTodos } from 'pages/Dashboard/Teacher/Todos/Todos';
import { Classes as TeacherClasses } from 'pages/Dashboard/Teacher/Classes/Classes';
import { Calendar as TeacherCalendar } from 'pages/Dashboard/Teacher/Calendar/Calendar';
import { Homework } from 'pages/Dashboard/Teacher/Homework/Homework/Homework';
import { Students as TeacherStudents } from 'pages/Dashboard/Teacher/Students/Students/Students';
import { Stream } from 'pages/Dashboard/Course/Stream/Stream';
import { Assignment } from 'pages/Dashboard/Course/Assignment/Assignment';
import { PracticeTest } from 'pages/Dashboard/Course/PracticeTest/PracticeTest';
import { Session } from 'pages/Dashboard/Course/Session/SessionTab';
import { Teachers } from 'pages/Dashboard/Admin/Teachers/Teachers/Teachers';
import { PrivacyPolicy } from 'pages/PrivacyPolicy/PrivacyPolicy';

export default () => {
  const { setup, doneSetup } = AxiosConfig();
  const { isAdmin, isTeacher } = useRoles();
  const { user } = useAuth();
  const { data: userInfo } = useUserInfo();
  const { data: userDrafts, isLoading } = useUserDraft(!!user?.token);
  const status = userInfo?.userGetInfo.status || user?.user.status;

  const location = useLocation();

  useEffect(() => {
    setup();
  }, []);

  if (!doneSetup) {
    return (<></>);
  }

  const selectDashboard = () => {
    if (isLoading) return <OverlayLoader />;
    if (isAdmin) {
      return (
        <Navigate
          to={`/${DASHBOARD}/${CLASSES}`}
          state={{ from: location }}
        />
      );
    }
    if (isTeacher) {
      return (
        <Navigate
          to={`/${TEACHER_DASHBOARD}/${TEACHER_CLASSES}`}
          state={{ from: location }}
        />
      );
    }
    if (status === 'Inactive' && userDrafts?.userViewDrafts.length) return <StudentDashboard />;
    if (status === 'Inactive') return <CoursesCard />;
    return <StudentDashboard />;
  };

  return (
    <Routes>
      <Route
        path={PRIVACY}
        element={(
          <RequireAuth authPage>
            <PrivacyPolicy />
          </RequireAuth>
        )}
      />

      <Route
        path={SIGNIN}
        element={(
          <RequireAuth authPage>
            <SignIn />
          </RequireAuth>
        )}
      />

      <Route
        path={SIGNUP}
        element={(
          <RequireAuth authPage>
            <SignUp />
          </RequireAuth>
        )}
      />

      <Route
        path={SIGNUP_VERIFY}
        element={(
          <RequireAuth authPage>
            <SignupVerification />
          </RequireAuth>
        )}
      />

      <Route
        path={FORGOT_PASSWORD}
        element={(
          <RequireAuth authPage>
            <ForgotPassword />
          </RequireAuth>
        )}
      />

      <Route
        path={RESET_VERIFY}
        element={(
          <RequireAuth authPage>
            <PasswordVerification />
          </RequireAuth>
        )}
      />

      <Route
        path={RESET_PASSWORD}
        element={(
          <RequireAuth authPage>
            <NewPassword />
          </RequireAuth>
        )}
      />

      <Route
        path={ONBOARDING_COURSES}
        element={(
          <RequireAuth>
            <CoursesCard />
          </RequireAuth>
        )}
      />

      <Route
        path={ADMIN_ONBOARDING_COURSES(':userId')}
        element={(
          <RequireAuth>
            <CoursesCard />
          </RequireAuth>
        )}
      />

      <Route
        path={ONBOARDING}
        element={(
          <RequireAuth>
            <Onboarding />
          </RequireAuth>
        )}
      />

      <Route
        path={ADMIN_ONBOARDING(':userId', ':className')}
        element={(
          <RequireAuth>
            <Onboarding />
          </RequireAuth>
        )}
      />

      <Route
        path={STUDENT_DASHBOARD}
        element={(
          <RequireAuth>
            <StudentDashboard />
          </RequireAuth>
        )}
      />

      <Route
        path={HOME}
        element={(
          <RequireAuth>
            {selectDashboard()}
          </RequireAuth>
        )}
      />

      <Route
        path={CLASS}
      >
        <Route
          path={STREAM}
          element={(<RequireAuth><Stream /></RequireAuth>)}
        />
        <Route
          path={ASSIGNMENTS}
          element={(<RequireAuth><Assignment /></RequireAuth>)}
        />
        <Route
          path={TESTS}
          element={(<RequireAuth><PracticeTest /></RequireAuth>)}
        />
        <Route
          path={SESSIONS_RECORDINGS}
          element={(<RequireAuth><Session /></RequireAuth>)}
        />
      </Route>

      {/* Teacher Dashboard Routes */}
      <Route path={TEACHER_DASHBOARD}>
        <Route
          path={TEACHER_TODOS}
          element={(<RequireAuth><TeacherTodos /></RequireAuth>)}
        />
        <Route
          path={TEACHER_CALENDAR}
          element={(<RequireAuth><TeacherCalendar /></RequireAuth>)}
        />
        <Route
          path={TEACHER_CLASSES}
          element={(<RequireAuth><TeacherClasses /></RequireAuth>)}
        />
        <Route
          path={TEACHER_HOMEWORK}
          element={(<RequireAuth><Homework /></RequireAuth>)}
        />
        <Route
          path={TEACHER_STUDENTS}
          element={(<RequireAuth><TeacherStudents /></RequireAuth>)}
        />
        <Route
          path={STUDENT_PROFILE(':id')}
          element={(<RequireAuth><PersonalInfoLayout /></RequireAuth>)}
        >
          <Route
            path={ENROLLMENTS}
            element={(<Sessions />)}
          />
          <Route
            path={COURSE_ACTIVITY}
            element={(<CourseActivityLayout />)}
          >
            <Route
              path={PROGRESS_DIAGRAM}
              element={(<ProgressDiagram />)}
            />
            <Route
              path={ATTENDANCE_RECORDS}
              element={(<AttendanceRecords />)}
            />
            <Route
              path={HOMEWORK_RECORDS}
              element={(<HomeworkRecords />)}
            />
          </Route>
        </Route>
      </Route>

      {/* Dashboard Routes */}
      <Route path={DASHBOARD}>
        <Route
          path={TODOS}
          element={(<RequireAuth><Todos /></RequireAuth>)}
        />
        <Route
          path={CALENDAR}
          element={(<RequireAuth><Calendar /></RequireAuth>)}
        />
        <Route
          path={CLASSES}
          element={(<RequireAuth><Classes /></RequireAuth>)}
        />
        <Route
          path={STUDENTS}
          element={(<RequireAuth><Students /></RequireAuth>)}
        />
        <Route
          path={LEADS}
          element={(<RequireAuth><Leads /></RequireAuth>)}
        />
        <Route
          path={TEACHERS}
          element={(<RequireAuth><Teachers /></RequireAuth>)}
        />
        <Route
          path={LEAD_PROFILE(':id', ':leadId', ':className')}
          element={(<RequireAuth><LeadPersonalInfoLayout /></RequireAuth>)}
        >
          <Route
            path={NOTES}
            element={(<LeadNotes />)}
          />
        </Route>
        <Route
          path={STUDENT_PROFILE(':id')}
          element={(<RequireAuth><PersonalInfoLayout /></RequireAuth>)}
        >
          <Route
            path={ENROLLMENTS}
            element={(<Sessions />)}
          />
          <Route
            path={PAYMENT_STATUS}
            element={(<PaymentStatus />)}
          />
          <Route
            path={NOTES}
            element={(<Notes />)}
          />
          <Route
            path={COURSE_ACTIVITY}
            element={(<CourseActivityLayout />)}
          >
            <Route
              path={PROGRESS_DIAGRAM}
              element={(<ProgressDiagram />)}
            />
            <Route
              path={ATTENDANCE_RECORDS}
              element={(<AttendanceRecords />)}
            />
            <Route
              path={HOMEWORK_RECORDS}
              element={(<HomeworkRecords />)}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
