import { useRoles } from 'assets/utils/useRoles';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useAdminNearestAssignment } from 'services/Dashboard/PracticeTests/Queries/AdminNearestAssignment/useAdminNearestAssignment';
import { useGetStreamPosts } from 'services/Dashboard/Posts/Queries/StreamPosts/useGetStreamPosts';
import { setPostData, StreamSlice, toggleEditablePost } from './StreamSlice';
import { StreamPost } from './Strem.types';
import { useDeletePost } from 'services/Dashboard/Posts/Mutations/useDeletePost';
import { useDeleteComment } from 'services/Dashboard/Comments/useDeleteComment';
import { useUserNearestAssignment } from 'services/Dashboard/Assignments/Queries/UserNearestAssignment/useUserNearestAssignment';
import { useUserNearestPracticeTest } from 'services/Dashboard/PracticeTests/Queries/UserNearestPracticeTest/useUserNearestPracticeTest';
import { useAdminNearestPracticeTest } from 'services/Dashboard/PracticeTests/Queries/AdminNearestPracticeTest/useAdminNearestPracticeTest';
import { useUserInfo } from 'services/Onboarding/General/useOnboarding';
import { useQueryClient } from 'react-query';
import { useTeacherNearestSession } from 'services/Dashboard/Sessions/Queries/TeacherNearestSession/useTeacherNearestSession';
import { DashboardSlice, setFeedbackData } from 'pages/Dashboard/DashboardSlice';
import { HOME } from 'routes/CONSTANTS';

export const useLogic = () => {
  const { id } = useParams();
  const { isAdmin, isTeacher } = useRoles();
  const navigate = useNavigate();
  const downlowadRef = useRef<HTMLAnchorElement | null>(null);
  const { data: posts, isFetching: isLoadingPosts, error } = useGetStreamPosts(Number(id));
  const { data: userInfo } = useUserInfo();
  const { mutateAsync: deletePost } = useDeletePost();
  const { mutateAsync: deleteComment } = useDeleteComment();
  const isImage = (url: string) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  const streamState = useSelector((state: { stream: StreamSlice }) => state.stream);
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const { data: userNearestAssignment, isFetching: isLoadingnUserNearestAssignment } = useUserNearestAssignment(Number(id));
  const { data: userNearestPracticeTest, isFetching: isLoadingnUserNarestPracticeTest } = useUserNearestPracticeTest(Number(id));
  const { data: adminNearestPracticeTest, isFetching: isLoadingnAdminNarestPracticeTest } = useAdminNearestPracticeTest(Number(id));
  const { data: adminNearestAssignment, isFetching: isLoadingnAdminNearestAssignment } = useAdminNearestAssignment(Number(id));
  const { data: teacherNearestSession, isFetching: isLoadingTeacherNearestSession } = useTeacherNearestSession(Number(id));
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleEdit = (post: StreamPost) => {
    dispatch(setPostData(post));
    dispatch(toggleEditablePost(true));
  };

  const handleSetEditable = () => {
    dispatch(toggleEditablePost(false));
    dispatch(setPostData({}));
  };

  const handleDeletePost = (_id?: number) => {
    deletePost(_id);
  };

  const handleDeleteComment = (commentId: number, postId?: number) => {
    deleteComment({ commentId, postId, classId: Number(id) });
  };

  useEffect(() => {
    if (userInfo?.userGetInfo.status === 'Inactive') {
      queryClient.refetchQueries('userinfo');
    }
  }, [userInfo]);

  const isLoadingAdminNearest = () => isLoadingnAdminNarestPracticeTest || isLoadingnAdminNearestAssignment || isLoadingTeacherNearestSession;
  const isLoadingUserNearest = () => isLoadingnUserNearestAssignment || isLoadingnUserNarestPracticeTest;

  const handleClick = () => {
    let meetingWindow = window.open(String(teacherNearestSession?.teacherSessions?.[0]?.teacherLink), '_blank');
    let timer: any = null;

    const windowListner = () => {
      if (meetingWindow?.closed) {
        clearInterval(timer);
        meetingWindow = null;
        dispatch(setFeedbackData({
          modal: true,
          sessionId: teacherNearestSession?.teacherSessions?.[0]?.id,
          ratedUserId: teacherNearestSession?.teacherSessions?.[0]?.userId,
          classId: teacherNearestSession?.teacherSessions[0].class.classId,
          sessionNumber: teacherNearestSession?.teacherSessions[0].sessionNumber,
          courseName: teacherNearestSession?.teacherSessions[0].class.plan.course.name,
          date: teacherNearestSession?.teacherSessions[0].dueAt
        }));
      }
    };

    timer = setInterval(windowListner, 500);
  };

  useEffect(() => {
    if (error?.message.includes('Forbidden resource')) {
      navigate(HOME);
    }
  }, [error]);

  return {
    posts,
    isLoadingPosts,
    downlowadRef,
    handleDeletePost,
    handleDeleteComment,
    isImage,
    handleEdit,
    streamState,
    handleSetEditable,
    dispatch,
    userNearestAssignment,
    userNearestPracticeTest,
    adminNearestAssignment,
    adminNearestPracticeTest,
    teacherNearestSession,
    isAdmin,
    isTeacher,
    userInfo,
    isLoadingAdminNearest,
    isLoadingUserNearest,
    handleClick,
    dashboardState,
    setFeedbackData
  };
};
