import { StatisticsCard } from 'components/Dashboard/StatisticsCard/StatisticsCard';
import { useLogic } from './Statistics.logic';
import ArrowsIcon from 'assets/images/arrows-up-down.svg';
import ChatIcon from 'assets/images/active-student.svg';
import GreenAvatarIcon from 'assets/images/green-avatar.svg';
import YellowAvatarIcon from 'assets/images/yellow-avatar.svg';

// Styling
import './Statistics.scss';

export const Statistics = () => {
  const { statistics, isLoading } = useLogic();
  return (
    <div className="statistics-cards-container">
      <StatisticsCard
        image={ArrowsIcon}
        count={statistics?.teacherAssignmentsStat.allAssignments}
        description="All assignments"
        isLoading={isLoading}
      />
      <StatisticsCard
        image={ChatIcon}
        count={statistics?.teacherAssignmentsStat.totalPending}
        description="Total of pending"
        isLoading={isLoading}
        percentage={statistics?.teacherAssignmentsStat.totalPendingPercentage}
      />
      <StatisticsCard
        image={YellowAvatarIcon}
        count={statistics?.teacherAssignmentsStat.totalSubmitted}
        description="Total of submitted"
        isLoading={isLoading}
        percentage={statistics?.teacherAssignmentsStat.totalSubmittedPercentage}
      />
      <StatisticsCard
        image={GreenAvatarIcon}
        count={statistics?.teacherAssignmentsStat.totalGraded}
        description="Total of graded"
        isLoading={isLoading}
        percentage={statistics?.teacherAssignmentsStat.totalGradedPercentage}
      />
    </div>
  );
};
