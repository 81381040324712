// Services
import { postFamilyInfo } from 'services/Onboarding/General/useOnboarding';
import { useQueryClient } from 'react-query';

// Types
import { FamilyInfoForm } from './FamilyInfo.types';

// Redux
import onboarding, { OnboardingSlice } from '../../OnboardingSlice';
import { useDispatch, useSelector } from 'react-redux';

// React hook form
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { usePostFamilyInfo } from 'services/Onboarding/AdminOnboarding/Mutations/usePostFamilyInfo';
import toast from 'react-hot-toast';
import { getCountryCode } from 'assets/utils/getCountryCode';

export const useLogic = () => {
  const { userId } = useParams();
  const { mutateAsync: adminPostFamilyInfo } = usePostFamilyInfo();
  const { nextPage, updateUserInfo, previousPage } = onboarding.actions;
  const dispatch = useDispatch();
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);

  const queryClient = useQueryClient();

  const { control, handleSubmit, setValue, formState: { errors } } = useForm<FamilyInfoForm>({
    defaultValues: {
      guardianName: '',
      guardianEmail: '',
      guardianNumber: {},
    }
  });
  const [phoneError, setPhoneError] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    if (errors.guardianName?.message || errors.guardianEmail?.message) return;
    if (data.guardianNumber.phone === '') {
      setPhoneError(true);
      return;
    }
    if (!data.guardianNumber.isoCode) {
      setPhoneError(true);
      toast.error('Please select country code');
      return;
    }
    dispatch(nextPage());
    if (userId) {
      await adminPostFamilyInfo({
        userId: Number(userId),
        guardain_name: data.guardianName,
        guardain_email: data.guardianEmail,
        guardain_number: `${data.guardianNumber.countryCode}-${data.guardianNumber.phone}`,
        step_id: onboardingState.activeStep
      });
    } else {
      await postFamilyInfo({
        guardain_name: data.guardianName,
        guardain_email: data.guardianEmail,
        guardain_number: `${data.guardianNumber.countryCode}-${data.guardianNumber.phone}`,
        step_id: onboardingState.activeStep
      });
    }
    dispatch(updateUserInfo({
      guardainName: data.guardianName,
      guardainNumber: `${data.guardianNumber.countryCode}-${data.guardianNumber.phone}`,
      guardainEmail: data.guardianEmail,
    }));
    queryClient.refetchQueries(['userinfo']);
  });

  useEffect(() => {
    setValue('guardianName', onboardingState.userInfo.guardainName || '');
    setValue('guardianEmail', onboardingState.userInfo.guardainEmail || '');
    if (onboardingState.userInfo.guardainNumber?.includes('-')) {
      setValue('guardianNumber.phone', onboardingState.userInfo.guardainNumber?.split('-')[1] || onboardingState.userInfo.guardainNumber || '');
      setValue('guardianNumber.countryCode', onboardingState.userInfo.guardainNumber?.split('-')[0] || '');
      setValue('guardianNumber.isoCode', getCountryCode(onboardingState.userInfo.guardainNumber?.split('-')[0] || ''));
    } else {
      setValue('guardianNumber.phone', onboardingState.userInfo.guardainNumber || '');
      setValue('guardianNumber.isoCode', 'lb');
    }
  }, [onboardingState.userInfo]);

  return {
    onSubmit,
    control,
    handleSubmit,
    errors,
    phoneError,
    previousPage,
    dispatch
  };
};
