import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Feedbacks = {
  modal: boolean,
  sessionId?: number,
  ratedUserId?: number,
  classId?: number,
  date?: string,
  sessionNumber?: number,
  courseName?: string
};

export type Mark = {
  id?: number;
  mark?: number;
  originalMark?: number;
  toggleModal?: boolean
};

type ToggleTabData = {
  assignment?: any;
  tab?: string;
};

export type Filters = {
  id?: number,
  courses?: string[],
  country?: string[],
  classStatus?: string[],
  search?: string,
  archive?: boolean
  selectedFilter?: string
};

export type Payment = {
  id?: number;
  classId?: number;
  className?: string;
  price?: number;
  session?: number;
  customerStripeId?: string;
  stripeClientSecret?: string
  paymentMethod?: string;
};

export type CashTransaction = {
  id: number;
  rejectionReason: string;
  status: string;
  attachments: [
    {
      id: number,
      attachment: string
      teacherAttachment: boolean
    },
  ]
}[];

type FinalTest = {
  id?: number;
  finalTest?: number;
  modal?: boolean;
};

type TargetScore = {
  id?: number;
  targetScore?: number;
  modal?: boolean;
  course?: string
};

export interface DashboardSlice {
  showCourse: boolean;
  showTabBar: boolean;
  feedbacks: Feedbacks;
  assignment: ToggleTabData;
  filter: Filters;
  filtersModal: boolean;
  paymentModal: boolean;
  cashPaymentModal: boolean;
  payment: Payment;
  cashTransactions: CashTransaction
  showPaymentInstructions: boolean,
  finalTest: FinalTest,
  targetScore: TargetScore,
  cardId?: number
}

const initialState: DashboardSlice = {
  showCourse: false,
  showTabBar: true,
  feedbacks: { modal: false },
  assignment: {},
  filter: {},
  filtersModal: false,
  paymentModal: false,
  cashPaymentModal: false,
  payment: {},
  cashTransactions: [],
  showPaymentInstructions: false,
  finalTest: {},
  targetScore: {},
};

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setShowCourse: (state) => {
      state.showCourse = !state.showCourse;
    },
    setShowTabBar: (state, { payload }: PayloadAction<boolean>) => {
      state.showTabBar = payload;
    },
    setAssignment: (state, { payload }: PayloadAction<ToggleTabData>) => {
      state.assignment = payload;
    },
    toggleFiltersModal: (state, { payload }: PayloadAction<boolean>) => {
      state.filtersModal = payload;
    },
    setFilter: (state, { payload }: PayloadAction<Filters>) => {
      state.filter = { ...state.filter, ...payload };
    },
    setPayment: (state, { payload }: PayloadAction<Payment>) => {
      state.payment = { ...state.payment, ...payload };
    },
    setCashTransaction: (state, { payload }: PayloadAction<CashTransaction>) => {
      state.cashTransactions = payload;
    },
    togglePaymentModal: (state, { payload }: PayloadAction<boolean>) => {
      state.paymentModal = payload;
    },
    toggleCashPaymentModal: (state, { payload }: PayloadAction<boolean>) => {
      state.cashPaymentModal = payload;
    },
    setShowPaymentInstructions: (state, { payload }: PayloadAction<boolean>) => {
      state.showPaymentInstructions = payload;
    },
    setFinalTest: (state, { payload }: PayloadAction<FinalTest>) => {
      state.finalTest = { ...state.finalTest, ...payload };
    },
    setTargetScore: (state, { payload }: PayloadAction<TargetScore>) => {
      state.targetScore = { ...state.targetScore, ...payload };
    },
    setCardId: (state, { payload }: PayloadAction<number>) => {
      state.cardId = payload;
    },
    setFeedbackData: (state, { payload }: PayloadAction<Feedbacks>) => {
      state.feedbacks = { ...state.feedbacks, ...payload };
    },
  },
});

export default dashboard;
export const {
  setShowCourse,
  setShowTabBar,
  setAssignment,
  setFilter,
  toggleFiltersModal,
  togglePaymentModal,
  setPayment,
  setCashTransaction,
  setShowPaymentInstructions,
  toggleCashPaymentModal,
  setFinalTest,
  setTargetScore,
  setCardId,
  setFeedbackData
} = dashboard.actions;
