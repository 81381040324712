// Components
import { TeacherCourseCard } from 'components/Dashboard/TeacherCourseCard/TeacherCourseCard';

// Grid system
import { Col, Row } from 'react-grid-system';

// Styling
import './Classes.scss';

// Logic
import { useLogic } from './Classes.logic';
import DotsLoader from 'components/General/Loader/Loader';
import { toggleFiltersModal } from 'pages/Dashboard/DashboardSlice';
import Modal from 'components/General/Modal/Modal';
import TeacherDashboard from '../Dashboard';
import { Statistics } from './Statistics/Statistics';
import { Filters } from './Filters/Filters';

export const Classes = () => {
  const {
    teacherClassesQuery,
    isLoading,
    isFetching,
    handleCourseChange,
    dashboardState,
    dispatch,
    generateFilter
  } = useLogic();

  return (
    <TeacherDashboard>
      <div className="teacher-classes-container">
        <Filters isLoading={isFetching} />
        <Statistics />
        {isLoading ? (
          <div className="loader-container mt-4">
            <DotsLoader />
          </div>
        ) : (
          <div className="cards-container">
            <Row
              className="cards"
            >
              {teacherClassesQuery?.teacherClassesView ? (
                teacherClassesQuery?.teacherClassesView.map((card) => (
                  <Col
                    xs={12}
                    md={4}
                    lg={2.4}
                    style={{ paddingRight: 30 }}
                    key={card.id}
                  >
                    <TeacherCourseCard
                      isAccessible={!card.isAccessible}
                      onClick={() => handleCourseChange(card.plan.course.name, card.classId, card.id)}
                      options={() => {}}
                      onCancel={() => {}}
                      onArchive={() => {}}
                      courseID={card.classId}
                      courseName={card.plan.course.name}
                      status={card.status}
                      studentName={`${card.user.first_name} ${card.user.last_name}`}
                    />
                  </Col>
                ))
              ) : (
                <>
                  <h2>No Classes yet</h2>
                </>
              )}
            </Row>
          </div>
        )}
        <Modal
          isClosble
          isOpen={dashboardState.filtersModal}
          onRequestClose={() => dispatch(toggleFiltersModal(false))}
        >
          {generateFilter(dashboardState.filter.selectedFilter)}
        </Modal>
      </div>
    </TeacherDashboard>
  );
};
