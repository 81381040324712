// Styling
import './Homework.scss';

// Icons

// Logic
import { useLogic } from './Homework.logic';
import Modal from 'components/General/Modal/Modal';
import Typography from 'components/General/Typography/Typography';
import Table from 'components/Dashboard/Table/Table';
import Label from 'components/General/Label/Label';
import Avatar from 'components/General/Avatar/Avatar';
import moment from 'moment-timezone';
import { Statistics } from '../Statistics/Statistics';
import { Filters } from '../Filters/Filters';
import { toggleFiltersModal, setAssignment } from '../HomeworkSlice';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import TeacherDashboard from '../../Dashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight as RightArrowIcon } from '@fortawesome/free-solid-svg-icons';
import { AssignmentCard } from './AssignmentCard/AssignmentCard';

export const Homework = () => {
  const {
    generateFilter,
    homeworkState,
    dispatch,
    Columns,
    data,
    isLoadingTableData,
    isFetching,
    offset,
    setOffset,
    setSortBy,
    setDesc,
  } = useLogic();

  const NameCol = ({ src, name }: { src: string; name: string; }) => (
    <div className="name-col">
      <Avatar
        src={src}
        size={45}
      />
      <div className="name">
        <Typography
          color="black"
          className="mb-05"
        >
          {name}
        </Typography>
      </div>
    </div>
  );

  return (
    <TeacherDashboard>
      <div className="students-container">
        <Filters isLoading={isFetching} />
        <Statistics />
        <div className="table mt-4">
          <Table
            onSort={({ key, direction }) => {
              setDesc(direction === 'desc');
              setSortBy(key);
            }}
            isLoading={isLoadingTableData}
            columns={Columns}
            data={
              data?.teacherAssignments.assignments.map((item) => ([
                { value: <NameCol
                  src={item.class?.user?.picture}
                  name={`${item.class?.user?.first_name} ${item.class?.user?.last_name}`}
                /> },
                { value: <Label color={item.class?.plan?.course?.name}>{item.class?.plan?.course?.name || '-'}</Label> },
                { value: <Typography>{item?.name || '-'}</Typography> },
                { value: <Label color={item.status}>{item.status}</Label> },
                { value: <Typography>{moment(item.dueDate).format('YYYY-MM-DD') || '-'}</Typography> },
                { value: <Typography>{moment(item.createdAt).format('YYYY-MM-DD') || '-'}</Typography> },
                { value: <Typography>{(item.createdBy?.first_name && `${item.createdBy?.first_name} ${item.createdBy?.last_name}`) || '-'}</Typography> },
                { value: <FontAwesomeIcon
                  icon={RightArrowIcon}
                  className="arrow-icon"
                  color="#696C71"
                  onClick={() => {
                    dispatch(setAssignment({
                      assignment: {
                        attachments: item?.attachments,
                        classId: item?.classId,
                        dueDate: item?.dueDate,
                        status: item?.status,
                        name: item?.name,
                        details: item?.details,
                        fullMark: item?.fullMark,
                        receivedMark: item?.receivedMark,
                        id: item?.id,
                      },
                      modal: true
                    }));
                  }}
                /> },
              ]))
            }
          />
        </div>
        {!isLoadingTableData && (
          <div className="pagination">
            <ButtonBase
              tagName="i"
              onClick={() => {
                if (offset < 20) return;
                setOffset((prev) => prev - 20);
              }}
            >
              <i className={`icon-chevron-left ${offset < 20 && 'disabled'}`} />
            </ButtonBase>
            <Typography>
              {`${offset} - ${Number(data?.teacherAssignments.totalAssignments) - offset > 20 ? offset + 20 : data?.teacherAssignments.totalAssignments} of ${data?.teacherAssignments.totalAssignments}`}
            </Typography>
            <ButtonBase
              tagName="i"
              onClick={() => {
                if (Number(data?.teacherAssignments.totalAssignments) - offset <= 20) return;
                setOffset((prev) => prev + 20);
              }}
            >
              <i className={`icon-chevron-right ${(Number(data?.teacherAssignments.totalAssignments) - offset <= 20) && 'disabled'}`} />
            </ButtonBase>
          </div>
        )}
        <Modal
          isClosble
          isOpen={homeworkState.filtersModal}
          onRequestClose={() => dispatch(toggleFiltersModal(false))}
        >
          {generateFilter(homeworkState.filter.selectedFilter)}
        </Modal>
        <Modal
          isClosble
          isOpen={homeworkState.assignment.modal}
          onRequestClose={() => dispatch(setAssignment({ ...homeworkState.filter, modal: false }))}
        >
          <AssignmentCard
            attachments={homeworkState.assignment.assignment?.attachments}
            date={homeworkState.assignment.assignment?.dueDate}
            status={homeworkState.assignment.assignment?.status}
            title={homeworkState.assignment.assignment?.name}
            body={homeworkState.assignment.assignment?.details}
            fullMark={homeworkState.assignment.assignment?.fullMark}
            receivedMark={homeworkState.assignment.assignment?.receivedMark}
            id={homeworkState.assignment.assignment?.id}
          />
        </Modal>
      </div>
    </TeacherDashboard>
  );
};
