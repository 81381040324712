import { useEffect, useState } from 'react';
import { Teacher } from './Schedule.types';
import onboarding, { OnboardingSlice } from 'pages/Onboarding/OnboardingSlice';
import { CalendarState, setAcuityCalendarID, setAcuityTypeID } from './Calendar/Calendar.slice';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Services
import { useTeachers } from 'services/Onboarding/Teachers/Queries/teachers/useTeachers';
import { usePostAcuityAppointment } from 'services/Onboarding/General/useOnboarding';
import { useParams } from 'react-router';
import { useAdminScheduleSession } from 'services/Dashboard/Sessions/Mutations/AdminScheduleSession/useAdminScheduleSession';

export const useLogic = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [appointmentData, setAppointmentData] = useState<any>();
  const { data: acuityAppointmentData, isFetching: isLoadingSchedule, isSuccess } = usePostAcuityAppointment(appointmentData, !!appointmentData);
  const { nextPage, setPlanData, resetTimeSlots, setComplaintModal, previousPage, setSelectedTeacher, setShowConfirmation } = onboarding.actions;
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const [filter, setFilter] = useState<string>();
  const { data: teachersQuery, isFetching: isLoadingTeachers } = useTeachers(onboardingState.plan.course || '', filter);
  const [showComplaintModal, setShowComplaintModal] = useState<boolean>(false);
  const [showTeacherProfile, setShowTeacherProfile] = useState<boolean>(true);
  const [teacherData, setTeacherData] = useState<Teacher | undefined>(undefined);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const { mutateAsync: adminSchedule, isLoading: isLoadingAdminSchedule } = useAdminScheduleSession();
  const { acuityCalendarID } = useSelector((state: { calendar: CalendarState }) => state.calendar);
  const isLoading = () => isLoadingSchedule || isLoadingAdminSchedule;

  const handleConfirmSchedule = () => {
    if (userId) {
      adminSchedule({
        userId: Number(userId),
        sessions: onboardingState.selectedTimeSlots.map((item) => ({
          datetime: item.datetime,
          appointmentTypeId: item.appointmentTypeId
        })),
        planId: onboardingState.plan.id,
        teacherId: onboardingState.plan.teacherID,
        calendarId: acuityCalendarID
      }).then((res) => {
        dispatch(setPlanData({ classID: res.adminSchedule.classId }));
        dispatch(nextPage());
        setAppointmentData({});
      });
    } else {
      setAppointmentData({
        planId: onboardingState.plan.id,
        teacherId: onboardingState.plan.teacherID,
        sessions: onboardingState.selectedTimeSlots.map((item) => ({
          datetime: new Date(item.datetime),
          appointmentTypeId: onboardingState.plan.typeID
        })),
        calendarId: acuityCalendarID
      });
      if (isSuccess) dispatch(nextPage());
    }
  };

  useEffect(() => {
    console.log('classID', onboardingState.plan.classID);
  }, [onboardingState.plan.classID]);

  const handleTeacherSelect = ({ acuityCalendarId, acuityAppTypeId, id }: { acuityCalendarId: number; acuityAppTypeId: number; id: number; }) => {
    if (onboardingState.selectedTimeSlots.length > 0) { return; }
    dispatch(setAcuityCalendarID(acuityCalendarId || 0));
    dispatch(setAcuityTypeID(acuityAppTypeId || 0));
    const data = {
      teacherID: id,
      typeID: acuityAppTypeId
    };
    dispatch(setPlanData(data));
  };

  useEffect(() => {
    if (isSuccess && appointmentData) {
      dispatch(setPlanData({ classID: acuityAppointmentData.classId }));
      dispatch(nextPage());
      setAppointmentData({});
    }
  }, [isSuccess]);

  return {
    teachersQuery,
    isLoadingTeachers,
    showComplaintModal,
    onboardingState,
    showTeacherProfile,
    teacherData,
    setShowComplaintModal,
    setShowTeacherProfile,
    handleConfirmSchedule,
    setTeacherData,
    handleTeacherSelect,
    isLoading,
    showFiltersModal,
    setShowFiltersModal,
    filter,
    setFilter,
    dispatch,
    resetTimeSlots,
    setComplaintModal,
    previousPage,
    setSelectedTeacher,
    setShowConfirmation,
    setPlanData
  };
};
