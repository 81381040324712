import { gql } from 'graphql-request';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { Post } from 'pages/Dashboard/Course/Stream/Strem.types';

export const useEditPost = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ text, addAttachmentsIds, postId, deleteAttachmentsNames }: Post) => client.request(
    gql`
    mutation EditPost($postId: Float!, $addAttachmentsIds: [Float!], $deleteAttachmentsNames: [String!], $text: String) {
      editPost(postId: $postId, addAttachmentsIds: $addAttachmentsIds, deleteAttachmentsNames: $deleteAttachmentsNames, text: $text) {
        id
        text
        classId
        postedById
        assignmentId
        testId
        createdAt
        updatedAt
        attachments {
          id
          attachment
        }
      }
    }
    `,
    {
      postId,
      addAttachmentsIds: addAttachmentsIds?.length ? addAttachmentsIds : undefined,
      deleteAttachmentsNames: deleteAttachmentsNames?.length ? deleteAttachmentsNames : undefined,
      text: text || undefined
    }
  )
    .catch((error) => toast(error.response.errors[0].message)));
  return Mutation;
};
