import { gql } from 'graphql-request';

import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { AttachmentProps } from './types';

export const useUserSubmitTest = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ id, attachmentsIds }: AttachmentProps) => client.request(
    gql`
    mutation UserSubmitTest($testId: Float!, $attachmentsIds: [Float!]) {
      userSubmitTest(testId: $testId, attachmentsIds: $attachmentsIds) {
        id
        createdById
        classId
        details
        name
        link
        status
        attachments {
          id
          attachment
          teacherAttachment
        }
      }
    }
    `,
    {
      testId: id,
      attachmentsIds
    }
  ).catch((error) => toast(error.response.errors[0].message)), {
    onSuccess: () => {}
  });
  return Mutation;
};
