import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Filters = {
  courses?: string[],
  country?: string[],
  userStatus?: string[],
  classStatus?: string[],
  taughtBy?: string[],
  schools?: string[],
  enrollment?:Date,
  classOf?: string,
  paymentMethod?: string,
  testsTimeInterval?: {
    start?: Date,
    end?: Date
  },
  coursesTimeInterval?: {
    start?: Date,
    end?: Date
  }
  selectedFilter?: string
};

type ManualPayment = {
  id?: number,
  madal?: boolean,
  amount?: number,
  discount?: number,
  date?: string,
  transactionId?: number,
  comment?: string,
  paymentMethod?: 'Credit card' | 'Cash'
};

export type PaymentConfirmation = {
  id?: number;
  date?: string;
  attachments?: [
    {
      id: number,
      attachment: string
    },
  ]
};

export type Attendance = {
  modal?: boolean;
  sessionId?: number;
};

export interface StudentsSlice {
  filter: Filters;
  filtersModal: boolean;
  paymentConfirmation: PaymentConfirmation;
  paymentConfirmationModal: boolean;
  attendance: Attendance;
  manualPayment: ManualPayment
}

const initialState: StudentsSlice = {
  filter: {},
  filtersModal: false,
  paymentConfirmation: {},
  paymentConfirmationModal: false,
  attendance: { modal: false },
  manualPayment: { madal: false }
};

const students = createSlice({
  name: 'students',
  initialState,
  reducers: {
    toggleFiltersModal: (state, { payload }: PayloadAction<boolean>) => {
      state.filtersModal = payload;
    },
    setFilter: (state, { payload }: PayloadAction<Filters>) => {
      state.filter = { ...state.filter, ...payload };
    },
    setPaymentConfirmation: (state, { payload }: PayloadAction<PaymentConfirmation>) => {
      state.paymentConfirmation = { ...state.paymentConfirmation, ...payload };
    },
    setPaymentConfirmationsModal: (state, { payload }: PayloadAction<boolean>) => {
      state.paymentConfirmationModal = payload;
    },
    setAttendance: (state, { payload }: PayloadAction<Attendance>) => {
      state.attendance = { ...state.attendance, ...payload };
    },
    setManualPaymentData: (state, { payload }: PayloadAction<ManualPayment>) => {
      state.manualPayment = { ...state.manualPayment, ...payload };
    }
  },
});

export default students;
export const {
  setFilter,
  toggleFiltersModal,
  setPaymentConfirmation,
  setPaymentConfirmationsModal,
  setAttendance,
  setManualPaymentData
} = students.actions;
