import { FC } from 'react';

// Types
import { AvatarProps } from './Avatar.types';

// Style
import './Avatar.scss';

const Avatar: FC<AvatarProps> = ({ src, size, style, ...rest }) => (
  <div
    className="global-avatar"
    style={{ backgroundImage: `url(${src})`, width: `${size}px`, height: `${size}px`, ...style }}
    {...rest}
  />
);

export default Avatar;
