// Types
import { OnboardingCard as IProps } from './OnboardingCard.types';

// Styling
import './OnboardingCard.scss';

const OnboardingCard = ({ children, ...rest }: IProps) => (
  <div
    className="card-container"
    {...rest}
  >
    {children}
  </div>
);

export default OnboardingCard;
