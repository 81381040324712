// Components
import Plan from 'components/Onboarding/Plan/Plan';

// Grid system
import { Row, Col } from 'react-grid-system';

// Styling
import './Plans.scss';

// Logic
import DotsLoader from 'components/General/Loader/Loader';
import { useNavigate, useParams } from 'react-router';
import { STUDENT_DASHBOARD } from 'routes/CONSTANTS';
import { useDispatch, useSelector } from 'react-redux';
import onboarding, { OnboardingSlice } from '../OnboardingSlice';
import { usePostCoursePlan } from 'services/Onboarding/AdminOnboarding/Mutations/usePostCoursePlan';
import { useDraftPlan } from 'services/Onboarding/Draft/Mutations/useDraftPlan';
import { useChoosePlan } from 'services/Onboarding/General/Mutations/useChoosePlan';
import { useCourse, usePlan } from 'services/Onboarding/General/useOnboarding';
import { useEffect } from 'react';
import { useAdminChoosePlan } from 'services/Onboarding/AdminOnboarding/Mutations/useAdminChoosePlan';
import { useGetUserSteps } from 'services/Onboarding/AdminOnboarding/Queries/UserSteps/useGetUserSteps';
import { useAdminDraft } from 'services/Onboarding/Draft/Queries/adminDrafts/useAdminDraft';
import { useAdminDraftPlan } from 'services/Onboarding/AdminOnboarding/Mutations/useAdminDraftPlan';
import Button from 'components/General/Button/Button';

const Plans = () => {
  const { userId, className } = useParams();
  const { setPlanData, nextPage, previousPage } = onboarding.actions;
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const { data: courses } = useCourse(onboardingState.plan.course || '', !!onboardingState.plan.course && !className);
  const { data: adminCourses } = useGetUserSteps(Number(userId), className || '', !!className && !!userId);
  const { data: plans, isFetching: isLoadingPlans } = usePlan(onboardingState.plan.course || '', !!onboardingState.plan.course);
  const { data: adminDrafts } = useAdminDraft(Number(userId), !!userId);
  const { mutateAsync: adminPostPlan } = usePostCoursePlan();
  const { mutateAsync: draftPlan, isLoading } = useDraftPlan();
  const { mutateAsync: adminDraftPlan, isLoading: isLoadingAdminDraftPlan } = useAdminDraftPlan();
  const { mutateAsync: choosePlan } = useChoosePlan();
  const { mutateAsync: adminChoosePlan } = useAdminChoosePlan();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickPlan = (id: number, sessionsCount: number, price: number, hours: number) => {
    if (!onboardingState.plan.isDraft) {
      const planData = {
        id,
        sessionsCount,
        hours,
        price,
        courseID: onboardingState.plan.courseID
      };
      dispatch(setPlanData(planData));
      draftPlan({ courseId: onboardingState.plan.courseID, planId: id }).then(() => dispatch(nextPage()));
    }
    choosePlan(id);

    // for admin onboarding
    if (userId) {
      adminDraftPlan({
        userId: Number(userId),
        planId: id,
        courseId: onboardingState.plan.courseID
      });
      adminPostPlan({
        userId: Number(userId),
        planId: id,
        courseId: onboardingState.plan.courseID
      });
      adminChoosePlan({
        userId: Number(userId),
        planId: id,
      }).then(() => { if (onboardingState.plan.isDraft) dispatch(nextPage()); });
    }
  };

  useEffect(() => {
    if ((onboardingState.plan.courseID && onboardingState.plan.id && onboardingState.plan.sessionsCount && onboardingState.plan.price && onboardingState.plan.hours && (courses || adminCourses)) && onboardingState.plan.isDraft) {
      handleClickPlan(onboardingState.plan.id, onboardingState.plan.sessionsCount, onboardingState.plan.price, onboardingState.plan.hours);
    }
  }, [onboardingState.plan.id, courses, adminCourses]);

  useEffect(() => {
    const draft = adminDrafts?.adminGetUserDrafts.find((item) => item.course?.name === className);
    if (draft) {
      dispatch(setPlanData({
        id: draft.planId,
        isDraft: true,
        course: draft.course?.name,
        courseID: draft.course?.id,
        sessionsCount: draft.plan?.sessions_count,
        hours: draft.plan?.hours_count,
        price: draft.plan?.price,
      }));
    }
  }, [adminDrafts]);

  return (
    <>
      <div className="plans-container">
        {isLoadingPlans ? (
          <DotsLoader />
        ) : (
          <>
            <Row style={{ rowGap: 30 }}>
              {plans?.plans.map((item: any) => (
                <Col
                  lg={4}
                  sm={6}
                  key={item.id}
                >
                  <Plan
                    id={item.id}
                    isLoading={isLoading || isLoadingAdminDraftPlan}
                    key={item.id}
                    color="primary"
                    title={item.name}
                    description={[
                      `${item.sessions_count} sessions`,
                      `${item.hours_count} hours`,
                      'Certified instructors',
                      'Multiple summer schedules',
                      '4 proctored practice tests',
                      '1 book (hard copy)'
                    ]}
                    price={item.price}
                    onClick={() => handleClickPlan(item.id, item.sessions_count, item.price, item.hours_count)}
                  />
                </Col>
              ))}
            </Row>
            <div className="skip-container mb-3">
              <div className="back-container mb-2">
                <Button
                  color="white"
                  onClick={() => dispatch(previousPage())}
                >
                  Back

                </Button>
              </div>
              {!userId && (
              <h3>
                <a
                  href="#"
                  onClick={() => navigate(STUDENT_DASHBOARD)}
                >
                  Skip
                </a>

              </h3>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Plans;
