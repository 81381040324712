import { gql } from 'graphql-request';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { Post } from 'pages/Dashboard/Course/Stream/Strem.types';

export const useCreateComment = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ text, classId, postId }: Post) => client.request(
    gql`
    mutation CreateComment($text: String!, $postId: Float!, $classId: Float!) {
      createComment(text: $text, postId: $postId, classId: $classId) {
        id
        text
        postId
        createdAt
      }
    }
    `,
    {
      text,
      postId,
      classId
    }
  )
    .catch((error) => toast(error.response.errors[0].message)));
  return Mutation;
};
