// Types
import { AdminClassesQuery } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { Filters } from 'pages/Dashboard/DashboardSlice';

export const useAdminClasses = ({ courses, country, classStatus, search, archive }: Filters) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<AdminClassesQuery, Error> = useQuery<AdminClassesQuery, Error>(['adminCourses', { country, courses, classStatus, search }, archive], async () => client.request(
    gql`
    query AdminClassesView($courses: [String!], $country: [String!], $classStatus: [String!], $limit: Float, $offset: Float, $search: String, $archived: Boolean) {
      adminClassesView(courses: $courses, country: $country, classStatus: $classStatus, limit: $limit, offset: $offset, search: $search, archived: $archived) {
        id
        status
        classId
        isAccessible
        sessions{
          teacher{
            user{
              first_name
              last_name
            }
          }
        }
        plan{
          course{
            name
          }
        }
        user {
          first_name
          last_name
        }
      }
    }
    `,
    {
      courses: courses || undefined,
      country: country || undefined,
      classStatus: classStatus || undefined,
      search: search || undefined,
      archived: archive,
      limit: 0,
      offset: 0
    },
  ).catch((error) => console.error(error)));
  return data;
};
