// Types
import { TimeSlot as IProps } from './TimeSlot.types';

// Styling
import './TimeSlot.scss';
import moment from 'moment-timezone';

const TimeSlot = ({ time, selected, onClick, deny, addTime }: IProps) => (
  <div
    className="time-slot"
  >
    <span
      className={`${selected && 'selected'} ${deny && 'denied'}`}
      onClick={(e) => { if ((!deny || selected) && onClick) { onClick(e); } }}
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
    >
      {`${moment(new Date(time)).format('h:mm')} - ${moment(new Date(time)).add(addTime?.hours || 2, addTime?.type || 'h').format('h:mm a')}`}

    </span>
  </div>
);

export default TimeSlot;
