import { useLogic } from './SessionProblem.logic';
import './SessionProblem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft as LeftArrowIcon, faChevronDown as DownArrowIcon } from '@fortawesome/free-solid-svg-icons';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import GreenAvatarICon from 'assets/images/green-avatar-2.svg';
import YellowAvatarICon from 'assets/images/yellow-avatar-2.svg';
import { ISessionStat } from '../SessionsStats/SessionStats.types';

export const InfoCard = ({ icon, number, title }: ISessionStat) => (
  <div className="info-card">
    <div className="info-card-icon">
      {icon}
    </div>
    <h3>{number}</h3>
    <h4 className="info-card-title">
      {title}
    </h4>
  </div>
);

export const SessionProblem = () => {
  const {
    openStudent,
    setOpenStudent,
    openTeacher,
    setOpenTeacher,
    sessionProblemState,
    handleTime
  } = useLogic();
  return (
    <div className="session-problem-container">
      <div className="session-problem-info">
        <InfoCard
          icon={(
            <img
              src={GreenAvatarICon}
              alt=""
            />
          )}
          title="Teacher duration"
          number={handleTime(Number(sessionProblemState.teacherTimeIn))}
        />
        <InfoCard
          icon={(
            <img
              src={YellowAvatarICon}
              alt=""
            />
          )}
          title="Student duration"
          number={handleTime(Number(sessionProblemState.studentTimeIn))}
        />
      </div>
      { sessionProblemState.studentReport && (
        <div className="problem-section">
          <div className="problem-section-header">
            <h2>From student</h2>
            <ButtonBase
              tagName="div"
              onClick={() => setOpenStudent((prev) => !prev)}
            >
              <FontAwesomeIcon
                icon={openStudent ? DownArrowIcon : LeftArrowIcon}
                className="arrow-icon"
                color="#97ACCC"
              />
            </ButtonBase>
          </div>
          <>
            { openStudent && (
              <div className="problem-section-body">
                <>
                  <h3>Reason</h3>
                  <span>
                    <h3>{sessionProblemState.studentReport ? sessionProblemState?.studentReport?.status : ''}</h3>
                  </span>
                </>
                <>
                  <h3 className="pt-2">Notes</h3>
                  <div className="problem-note-container">
                    <p>{sessionProblemState.studentReport ? sessionProblemState?.studentReport?.comment : ''}</p>
                  </div>
                </>
              </div>
            )}
          </>
        </div>
      )}
      { sessionProblemState.teacherReport && (
        <div className="problem-section">
          <div className="problem-section-header">
            <h2>From teacher</h2>
            <ButtonBase
              tagName="div"
              onClick={() => setOpenTeacher((prev) => !prev)}
            >
              <FontAwesomeIcon
                icon={openTeacher ? DownArrowIcon : LeftArrowIcon}
                className="arrow-icon"
                color="#97ACCC"
              />
            </ButtonBase>
          </div>
          { openTeacher && (
            <>
              <div className="problem-section-body">
                <>
                  <h3>Reason</h3>
                  <span>
                    <h3>{sessionProblemState.teacherReport ? sessionProblemState?.teacherReport?.status : ''}</h3>
                  </span>
                </>
                <>
                  <h3 className="pt-2">Notes</h3>
                  <div className="problem-note-container">
                    <p>{sessionProblemState.teacherReport ? sessionProblemState?.teacherReport?.comment : ''}</p>
                  </div>
                </>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
