// Types
import { MetaData as IProps } from './MetaData.types';

// Styling
import './MetaData.scss';

const MetaData = ({ title, subTitle, icon }: IProps) => (
  <>
    <div className="meta">
      <span className="icon">{icon}</span>
      <span>
        <p className="metadata-title">{title}</p>
        <span>{`${subTitle}`}</span>
      </span>
    </div>
  </>
);

export default MetaData;
