// Types
import { LeadsTableDataProps, LeadsTableData } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useLeadsTableData = ({ filterBy, search, limit, offset, sortBy }: LeadsTableDataProps, enabled?: boolean) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<LeadsTableData, Error> = useQuery<LeadsTableData, Error>(['leads-table-data', [filterBy, search, limit, offset, sortBy]], async () => client.request(
    gql`
    query LeadsStatistics($filterBy: filterLeadsBy, $sortBy: [sortLeadsBy!], $search: String, $limit: Float, $offset: Float) {
      adminAllLeadsStat(filterBy: $filterBy, sortBy: $sortBy, search: $search, limit: $limit, offset: $offset) {
        totalLeads
        leadsStatistics {
          leadId
            firstName
            planSessionsCount
            lastName
            course
            status
            country
            statusDate
            futureCall
            userId
            number
            completionRate
            createdAt
            classId
            picture
            email
        }
      }
    }
    `,
    {
      filterBy: {
        status: filterBy?.status,
        country: filterBy?.country,
        courses: filterBy?.courses,
      },
      sortBy,
      search,
      limit,
      offset
    }
  ), { enabled });
  return data;
};
