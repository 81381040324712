import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useUserUseCoupon = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ classId, coupon }: { classId?: number, coupon?: string }) => client.request(
    gql`
    mutation UserUseCoupon($classId: Float!, $coupon: String!) {
      userUseCoupon(classId: $classId, coupon: $coupon) {
        message
      }
    }
    `,
    {
      classId,
      coupon
    }
  ));
  return Mutation;
};
