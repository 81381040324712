// Images
import CreditImage from 'assets/images/credit-card.svg';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { usePaymentInfo } from 'services/Payment/Queries/PaymentInfo/usePaymentInfo';
import { useUserUseCoupon } from 'services/Payment/Mutations/useUserUseCoupon';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useUserRemoveCoupon } from 'services/Payment/Mutations/useUserRemoveCoupon';
import { LeadsSlice, setManualPayment } from '../../LeadsSlice';

export const useLogic = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);
  const [paymentMethod, setPaymentMethod] = useState<string | undefined>('Credit Card');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [promoCode, setPromoCode] = useState<string>();
  const { data: paymentInfo, isFetching: isLoadingPaymentInfo } = usePaymentInfo(leadsState.payment.classId);
  const { mutateAsync: useCoupon, isLoading: isCheckingCoupon } = useUserUseCoupon();
  const { mutateAsync: removeCoupon, isLoading: isLoadingRemoveCoupon } = useUserRemoveCoupon();

  const paymentMethods = [
    {
      id: 2,
      name: 'Credit Card',
      icon: CreditImage,
      diabled: false
    },
  ];

  const checkCoupon = () => {
    useCoupon({ classId: leadsState.payment.classId, coupon: promoCode })
      .then(() => {
        setInvalidCode(false);
        queryClient.refetchQueries('payment-info');
      })
      .catch((err) => {
        toast(err.response.errors?.[0].message);
        setInvalidCode(true);
      });
  };

  const handleRemoveCoupon = () => {
    removeCoupon({ classId: leadsState.payment.classId }).then(() => {
      queryClient.refetchQueries('payment-info');
    });
  };

  return {
    paymentMethods,
    paymentMethod,
    setPaymentMethod,
    dispatch,
    paymentInfo,
    isLoadingPaymentInfo,
    setPromoCode,
    checkCoupon,
    isCheckingCoupon,
    invalidCode,
    setShowCodeInput,
    showCodeInput,
    handleRemoveCoupon,
    isLoadingRemoveCoupon,
    leadsState,
    setManualPayment
  };
};
