import { CourseActivityLayout as IProps } from './CourseActivityLayout.types';
import './CourseActivityLayout.scss';
import { WhiteTabBar } from 'components/Dashboard/WhiteTabBar/WhiteTabBar';
import { Outlet, useParams } from 'react-router';
import { COURSE_ACTIVITY, DASHBOARD, STUDENT_PROFILE } from 'routes/CONSTANTS';

export const CourseActivityLayout = ({ children }: IProps) => {
  const tabs = [
    'Progress diagram',
    'Attendance records',
    'Homework records',
  ];
  const { id } = useParams();
  return (
    <div className="course-activity-layout-container">
      <div className="courses-tab-bar-container">
        <WhiteTabBar
          tabs={tabs}
          selectedTab={tabs?.[0]}
          parentRoutes={[DASHBOARD, STUDENT_PROFILE(`${id}`), COURSE_ACTIVITY]}
        />
      </div>
      <div>
        {children}
        <Outlet />
      </div>
    </div>
  );
};
