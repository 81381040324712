// Components
import { DashboardNavbar } from 'components/Dashboard/DashboardNavbar/DashboardNavbar';
import { DashboardSideBar } from 'components/Dashboard/DashboardSideBar/DashboardSideBar';

// Styles
import './PersonalInfoLayout.scss';

import { PersonalInfoPage } from './PersonalInfoPage/PersonalInfoPage';
import { Outlet } from 'react-router';
import SearchModal from 'components/General/SearchModal/SearchModal';
import useLogic from './PersonalInfoLayout.logic';
import { StudentSearchCard } from 'components/General/SearchCards/StudentSearchCard/StudentSearchCard';

export const PersonalInfoLayout = () => {
  const {
    showSearch,
    students,
    isSearching,
    setSearchValue,
    setShowSearch,
    handleStudentNavigation,
    userInfo,
  } = useLogic();

  return (
    <div>
      <DashboardNavbar
        onClick={() => setShowSearch(true)}
        name={`${userInfo?.userGetInfo.first_name || ''} ${userInfo?.userGetInfo.last_name || ''}`}
        image={userInfo?.userGetInfo.picture}
      />
      <SearchModal
        isOpen={showSearch}
        onRequestClose={() => setShowSearch(false)}
        onSearch={(value) => setSearchValue(value)}
        isLoading={isSearching}
        searchResults={
          students?.adminAllUsersStat.allUsersStat.map((student) => ({
            element: <StudentSearchCard
              name={student.name || ''}
              avatar={`https://eu.ui-avatars.com/api/?background=F1F1F1&color=6371e0&name=${encodeURI(student.name || '')}&size=80`}
            />,
            onClick: () => handleStudentNavigation(student.id || 0)
          })) || []
        }
      />
      <div className="personal-info-layout-container">
        <div className="side-container">
          <DashboardSideBar />
        </div>
        <div className="body-container">
          <PersonalInfoPage>
            <Outlet />
          </PersonalInfoPage>
        </div>
      </div>
    </div>
  );
};
