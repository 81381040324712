// Components
import ConfirmationTimeSlot from 'components/Onboarding/ConfirmationTimeSlot/ConfirmationTimeSlot';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import onboarding, { OnboardingSlice } from 'pages/Onboarding/OnboardingSlice';

// uuid
import { v4 as uuidv4 } from 'uuid';
import Button from 'components/General/Button/Button';
import { FC } from 'react';

import './ConfirmSchedule.scss';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { useRoles } from 'assets/utils/useRoles';

export const ConfirmSchedule: FC<{ onConfirm(): void; isLoading: boolean; }> = ({ onConfirm, isLoading }) => {
  const { isAdmin } = useRoles();
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const dispatch = useDispatch();
  const { resetTimeSlots } = onboarding.actions;

  return (
    <div className="confirm-schedule-container">
      <div className="confirm-schedule-header">
        <div>
          <h2 style={{ margin: 0 }}>Confirm your schedule</h2>
          <div className="change-teacher-button">
            <ButtonBase
              tagName="div"
              onClick={() => dispatch(resetTimeSlots())}
            >
              <span>Change teacher</span>
            </ButtonBase>
          </div>
        </div>
        <h4>Please remember that you are committing to the schedule you choose.</h4>
        <h4>Once you submit your schedule, you can only change up to two sessions.</h4>
        <h4>If you don&apos;t pay for your course in the next 72 hours, your schedule will get cancelled automatically.</h4>
      </div>
      { onboardingState.selectedTimeSlots.length ? (
        [...onboardingState.selectedTimeSlots].sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()).map((item, index) => (
          <div
            className="time-slot-container"
            key={uuidv4()}
          >
            <ConfirmationTimeSlot
              time={item.datetime}
              index={index}
              onClick={() => dispatch(onboarding.actions.toggleTimeSlot(item))}
            />
          </div>
        ))
      ) : (
        <div className="mt-3">
          <h3>No selected time slots yet</h3>
        </div>
      )}
      <div className="buttons-container">
        <div className="mt-4">
          <Button
            onClick={() => {
              if (isAdmin || onboardingState.selectedTimeSlots.length === onboardingState.plan.sessionsCount) {
                onConfirm();
              }
            }}
            color="primary"
            data-tip={onboardingState.selectedTimeSlots.length < (onboardingState.plan.sessionsCount || 0) ? `Please select a time for all ${onboardingState.plan.sessionsCount} sessions.` : ''}
            data-place="top"
            data-type="warning"
            data-effect="solid"
            isLoading={isLoading}
          >
            { onboardingState.totalTime === onboardingState.plan.hours ? 'Confirm schedule'
              : (
                <>
                  {`${onboardingState.totalTime} out of ${onboardingState.plan.hours} hours`}
                </>
              ) }
          </Button>
        </div>
      </div>
    </div>
  );
};
