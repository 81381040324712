// Components
import Button from 'components/General/Button/Button';
import { Checkbox } from 'components/General/Checkbox/Checkbox';

// UUID
import { v4 as uuidv4 } from 'uuid';

// Styling
import './Payment.scss';

// Logic
import { useLogic } from './Payment.logic';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { CircleLoader, SectionLoader } from 'components/General/Loader/Loader';
import Coupon from 'components/General/Coupon/Coupon';
import { PostTextField } from 'components/Dashboard/PostTextField/PostTextField';
import { CreditCard } from './CreditCard/CreditCard';
import { setPayment } from '../../LeadsSlice';
import Modal from 'components/General/Modal/Modal';
import { ManualPaymentConfirmationModal } from 'pages/Onboarding/Steps/Payment/ManualPaymentConfirmationModal/ManualPaymentConfirmationModal';

export const Payment = () => {
  const {
    paymentMethods,
    paymentMethod,
    setPaymentMethod,
    dispatch,
    paymentInfo,
    isLoadingPaymentInfo,
    setPromoCode,
    checkCoupon,
    isCheckingCoupon,
    invalidCode,
    setShowCodeInput,
    showCodeInput,
    handleRemoveCoupon,
    isLoadingRemoveCoupon,
    leadsState,
    setManualPayment,
  } = useLogic();

  return (
    <>
      {!leadsState.payment.chosePaymentMethod ? (
        <div className="payment-container mt-1">
          <div className="payment-header">
            <h2>How would you like to pay?</h2>
          </div>
          { (isLoadingPaymentInfo) ? (
            <SectionLoader />
          ) : (
            <>
              <div className="payment-options mt-4 mb-3">
                {paymentMethods.map((method) => (
                  <div
                    className={`payment-box ${method.diabled && 'disabled'}`}
                    key={uuidv4()}
                  >
                    <img
                      src={method.icon}
                      alt=""
                    />
                    <h4>{method.name}</h4>
                    <Checkbox
                      checked={paymentMethod === method.name}
                      onChange={() => setPaymentMethod(method.name)}
                      disabled={method.diabled}
                    />
                  </div>
                ))}
              </div>
              <div className="payment-summary-container mb-3">
                <>
                  <div className="top-container">
                    <div className="course-cost">
                      <h4 className="title">{`You enrolled in ${leadsState.payment.course} Single Subject (${paymentInfo?.userGetPaymentInfo.planHours} hours).`}</h4>
                    </div>
                    <div className="sub-total">
                      <h4 className="title">Total amount</h4>
                      <h4>{`USD ${paymentInfo?.userGetPaymentInfo?.totalAmount}`}</h4>
                    </div>
                  </div>
                  <div className="promo-code-container">
                    { paymentInfo?.userGetPaymentInfo.usedCoupon ? (
                      <div className="coupon">
                        <Coupon
                          name={paymentInfo.userGetPaymentInfo.usedCoupon}
                          discount={(paymentInfo.userGetPaymentInfo.discountAmount / paymentInfo.userGetPaymentInfo.totalAmount) * 100}
                          onClick={() => handleRemoveCoupon()}
                          isLoading={isLoadingRemoveCoupon}
                        />
                        <h4>{`-$${paymentInfo?.userGetPaymentInfo.discountAmount}`}</h4>
                      </div>
                    ) : (
                      <>
                        { showCodeInput ? (
                          <>
                            <div className="code-input-container">
                              <div className="code-input">
                                <PostTextField
                                  placeholderColor="primary"
                                  value={paymentInfo?.userGetPaymentInfo.usedCoupon && paymentInfo?.userGetPaymentInfo.usedCoupon}
                                  onChange={(e) => setPromoCode(e.target.value)}
                                />
                              </div>
                              <div className="apply-container">
                                {isCheckingCoupon && <CircleLoader />}
                                <ButtonBase
                                  tagName="div"
                                  onClick={() => checkCoupon()}
                                >
                                  <p>Apply</p>
                                </ButtonBase>
                              </div>
                            </div>
                            <div className="invalid-code">
                              {invalidCode && <p>This code is invalid</p>}
                            </div>
                          </>
                        ) : (
                          <div className="code-button">
                            <ButtonBase
                              tagName="div"
                              onClick={() => setShowCodeInput(true)}
                            >
                              <p>Add Promotion code</p>
                            </ButtonBase>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="bottom-container">
                    <div className="total">
                      <h4 className="title">Total Amount Due</h4>
                      <h4>{`USD ${paymentInfo?.userGetPaymentInfo.finalAmount}`}</h4>
                    </div>
                  </div>
                </>
              </div>
              <Button
                color="primary"
                onClick={() => {
                  dispatch(setPayment({ ...leadsState.payment, chosePaymentMethod: true }));
                }}
                disabled={paymentMethod === undefined}
              >
                Pay
              </Button>
              <div className="mt-1">
                <Button
                  color="white-fuchsia"
                  onClick={() => dispatch(setManualPayment({ modal: true }))}
                >
                  Pay later
                </Button>
              </div>
            </>
          )}
        </div>
      ) : (
        <CreditCard />
      )}
      <Modal
        isClosble
        isOpen={leadsState.manualPayment.modal}
        onRequestClose={() => dispatch(setManualPayment({ modal: false }))}
      >
        <ManualPaymentConfirmationModal />
      </Modal>
    </>
  );
};
