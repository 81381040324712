import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useUpdatePracticeTest } from 'services/Dashboard/PracticeTests/Mutations/useUpdatePracticeTest';
import { PracticeTestSlice, setMark } from '../PracticeTestSlice';
import { GiveMark } from './GiveMark.types';

export const useLogic = () => {
  const { className } = useParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const practiceTestState = useSelector((state: { practiceTest: PracticeTestSlice }) => state.practiceTest);
  const { mutateAsync: updatePracticeTest, isLoading } = useUpdatePracticeTest();
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<GiveMark>({
    defaultValues: {}
  });

  const onSubmit = handleSubmit((data) => {
    if (className === 'SAT') {
      if (errors.englishScore || errors.mathScore || errors.totalScore) return;
      updatePracticeTest({
        id: practiceTestState.mark.id,
        marks: {
          saTMarks: {
            englishScore: Number(data.englishScore),
            mathScore: Number(data.mathScore),
            totalScore: Number(data.totalScore),
          }
        }
      }).then(() => {
        dispatch(setMark({ toggleModal: false }));
        queryClient.refetchQueries('test-chart');
        setValue('englishScore', undefined);
        setValue('mathScore', undefined);
        setValue('totalScore', undefined);
      });
    }
    if (className === 'GMAT') {
      if (errors.QuantitativeScore || errors.verbalScore || errors.totalScore) return;
      updatePracticeTest({
        id: practiceTestState.mark.id,
        marks: {
          gmatMarks: {
            QuantitativeScore: Number(data.QuantitativeScore),
            verbalScore: Number(data.verbalScore),
            totalScore: Number(data.totalScore),
          }
        }
      }).then(() => {
        dispatch(setMark({ toggleModal: false }));
        queryClient.refetchQueries('test-chart');
        setValue('QuantitativeScore', undefined);
        setValue('verbalScore', undefined);
        setValue('totalScore', undefined);
      });
    }
    if (className === 'IELTS') {
      if (errors.speaking || errors.reading || errors.listening || errors.writing || errors.totalScore) return;
      updatePracticeTest({
        id: practiceTestState.mark.id,
        marks: {
          ieltsMarks: {
            listening: Number(data.listening),
            reading: Number(data.reading),
            speaking: Number(data.speaking),
            writing: Number(data.writing),
            totalScore: Number(data.totalScore),
          }
        }
      }).then(() => {
        dispatch(setMark({ toggleModal: false }));
        queryClient.refetchQueries('test-chart');
        setValue('listening', undefined);
        setValue('reading', undefined);
        setValue('speaking', undefined);
        setValue('writing', undefined);
        setValue('totalScore', undefined);
      });
    }
  });

  return {
    control,
    errors,
    onSubmit,
    isLoading,
    practiceTestState,
    className
  };
};
