import React, { FC, useCallback, useEffect, useState } from 'react';
import { Row as IRow, Cell as ICell, Sort, SORTDIRECTION, ListingTableProps as IProps } from './ListingTable.types';

import './ListingTable.scss';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { OverlayLoader } from 'components/General/Loader/Loader';

const Row: FC<IRow> = ({ head, children, top }) => (
  <div
    className={`table-row${head ? ' table-head' : ' table-row-data'}`}
    style={{
      transform: `translateY(${top}px )`,
      paddingBottom: `${head && 0}`
    }}
  >
    {children}
  </div>
);

const Cell: FC<ICell> = ({ onClick, children, align }) => (
  <div
    className="table-col"
    onClick={onClick}
    onKeyDown={() => { }}
    tabIndex={0}
    role="button"
    style={{ textAlign: align }}
  >
    {children}
  </div>
);

const ListingTable: FC<IProps> = ({ onSort, isLoading, columns, data }) => {
  const [sort, setSort] = useState<Sort>({ key: '', direction: 'desc' });
  const [hiddenColumns, setHiddenColumns] = useState<number[]>([]);
  const [showColsVisibilit, setShowColsVisibility] = useState<boolean>(false);

  const getOppDirection = (direction: SORTDIRECTION): SORTDIRECTION => (direction === 'asc' ? 'desc' : 'asc');

  const sortCol = (colKey: string) => {
    setSort((prev) => {
      const newSort = {
        direction: prev.key === colKey ? getOppDirection(prev.direction) : 'desc',
        key: colKey
      };
      if (onSort) { onSort(newSort); }
      return newSort;
    });
  };

  useEffect(() => {
    setHiddenColumns(() => {
      const hidden: number[] = [];
      columns.map((item, index) => {
        if (item.hidden) { hidden.push(index); }
        return null;
      });
      return hidden;
    });
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setShowColsVisibility(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <>
      <div
        className="listing-table"
      >
        {isLoading && <div className="loading"><OverlayLoader /></div>}
        <Row
          head
          top={0}
        >
          {
          columns.map((col, index) => (
            <>
              { hiddenColumns.includes(index) ? ''
                : (
                  <Cell
                    onClick={() => { if (col.sortable) sortCol(col.key || ''); }}
                    align={col.align}
                  >
                    <>
                      <p className="title sub-text">{col.value}</p>
                      {sort.key === col.key
                    && (''
                    )}
                    </>
                  </Cell>
                ) }
            </>
          ))
        }
        </Row>

        { data?.map((row) => (
          <Row
            top={0}
          >
            {
              row.map((cell, index) => (
                <>
                  { hiddenColumns.includes(index) ? ''
                    : <Cell key={cell.value?.toString()}>{cell.value}</Cell>}
                </>
              ))
            }
          </Row>
        ))}
      </div>
      <ButtonBase
        tagName="div"
        className={`columns-overlay${showColsVisibilit ? ' show' : ''}`}
        onClick={() => setShowColsVisibility(false)}
      />
    </>
  );
};

export default ListingTable;
