// Types
import { SessionRecordings } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useSessionRecordings = (userId: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<SessionRecordings, Error> = useQuery<SessionRecordings, Error>(['session-recordings', userId], async () => client.request(
    gql`
    query AdminGetUserClassSessionsRecordings($userId: Float!) {
      adminGetUserClassSessionsRecordings(userId: $userId) {
        id
        classId
        status
        planId
        plan {
          course {
            name
          }
        }
        sessions {
          id
          status
          sessionNumber
          duration
          adminLink
          dueAt
          recordingLink
          acuitySession {
            datetime
          }
          teacher {
            id
            acuityCalendarId
            acuityAppTypeIds {
              GMAT {
                halfHour
                hour
                hourAndAHalf
                threeHours
                twoHours
                twoHoursAndAHalf
              }
              IELTS {
                halfHour
                hour
                hourAndAHalf
                threeHours
                twoHours
                twoHoursAndAHalf
              }
              SAT {
                halfHour
                hour
                hourAndAHalf
                threeHours
                twoHours
                twoHoursAndAHalf
              }
            }
          }
        }
      }
    }
    `,
    {
      userId
    }
  ).catch((error) => console.error(error)));
  return data;
};
