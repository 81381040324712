// Components
import { NavBarOnboarding } from 'components/General/Navbar/Navbar';
import Timeline from 'components/Onboarding/Timeline/Timeline';
import { OnboardingLayout as IProps } from './OnboardingLayout.types';
import { useNavigate } from 'react-router';
import { ONBOARDING_COURSES } from 'routes/CONSTANTS';

// Styling
import './OnboardingLayout.scss';

// React grid
import { Container } from 'react-grid-system';

// Redux
import onboarding, { OnboardingSlice } from '../../pages/Onboarding/OnboardingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FC } from 'react';
import { setAcuityCalendarID, setAcuityTypeID } from 'pages/Onboarding/Steps/Schedule/Calendar/Calendar.slice';

const OnboardingLayout = ({ children, steps }: IProps) => {
  const navigate = useNavigate();
  const { previousPage } = onboarding.actions;
  const dispatch = useDispatch();
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const { resetTimeSlots } = onboarding.actions;

  const handleBack = () => {
    if (onboardingState.plan.course === 'IELTS') {
      if (onboardingState.activeStep === 1) {
        navigate(ONBOARDING_COURSES);
        dispatch(resetTimeSlots());
        dispatch(setAcuityTypeID(0));
        dispatch(setAcuityCalendarID(0));
      } else if (onboardingState.activeStep === 5) {
        dispatch(previousPage());
      } else {
        dispatch(previousPage());
        if (onboardingState.selectedTimeSlots.length > 0) {
          dispatch(resetTimeSlots());
        }
      }
      return;
    }
    if (onboardingState.activeStep === 1) {
      navigate(ONBOARDING_COURSES);
      dispatch(resetTimeSlots());
      dispatch(setAcuityTypeID(0));
      dispatch(setAcuityCalendarID(0));
    } else if (onboardingState.activeStep === 6) {
      dispatch(previousPage());
    } else {
      dispatch(previousPage());
      if (onboardingState.selectedTimeSlots.length > 0) {
        dispatch(resetTimeSlots());
        dispatch(setAcuityCalendarID(0));
      }
    }
  };

  return (
    <div className="onboarding-container">

      <NavBarOnboarding />

      <Container>

        {steps && (
        <Timeline
          options={steps || []}
          onClick={handleBack}
        />
        )}

        {children}

      </Container>
    </div>
  );
};

export const OnboardingCourses: FC<IProps> = ({ children }) => (
  <div className="onboarding-container">

    <NavBarOnboarding />

    <Container>

      {children}

    </Container>
  </div>
);

export default OnboardingLayout;
