// Grid System
import { Container } from 'react-grid-system';

import Hamburger from 'hamburger-react';

// Component
import Button from 'components/General/Button/Button';

// Assets
import logo from 'assets/images/SetOut.svg';

// Stylesheet
import './Navbar.scss';

// Navigation
import { SIGNIN, SIGNUP } from 'routes/CONSTANTS';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import { useUserInfo } from 'services/Onboarding/General/useOnboarding';
import Avatar from '../Avatar/Avatar';
import { useAuth } from 'routes/Auth/AuthProvider';
import { ButtonBase } from '../ButtonBase/ButtonBase';

const Navbar = () => {
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  return (
    <div className="nav-container">
      <Container>
        <nav>
          <div className="logo">
            <img
              src={logo}
              alt=""
            />
          </div>
          <div className="burger-icon">
            <Hamburger
              toggled={toggleMenu}
              toggle={setToggleMenu}
            />

          </div>
          <div className={`nav-items ${toggleMenu ? 'expanded' : ''}`}>
            <>
              <ul>
                <li>
                  <a href="">
                    <h4>College</h4>
                  </a>
                </li>
                <li>
                  <a href="">
                    <h4>Business School</h4>
                  </a>
                </li>
                <li>
                  <a href="">
                    <h4>Become A Tutor</h4>
                  </a>
                </li>
                <li>
                  <Button
                    color="white"
                    onClick={() => navigate(SIGNIN)}
                  >
                    Sign in
                  </Button>
                </li>
                <li>
                  <Button
                    color="primary"
                    onClick={() => navigate(SIGNUP)}
                  >
                    Sign Up
                  </Button>
                </li>
              </ul>
            </>
          </div>
        </nav>
      </Container>
    </div>
  );
};

export const NavBarOnboarding = () => {
  const { data: userInfo } = useUserInfo();
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const { user, signout } = useAuth();
  const name = `${userInfo?.userGetInfo.first_name || ''} ${userInfo?.userGetInfo.last_name || ''}`;
  const email = userInfo?.userGetInfo.email || user?.user.email;
  const image = userInfo?.userGetInfo.picture || `https://eu.ui-avatars.com/api/?background=6371e0&color=fff&name=${encodeURI(`${user?.user.first_name || user?.user.email}`)}&size=40`;

  return (
    <div className="nav-container">
      <Container>
        <nav>
          <div className="logo">
            <img
              src={logo}
              alt=""
            />
          </div>
          <div className="name-container">
            <ButtonBase
              tagName="div"
              className="image"
              onClick={(event) => {
                event.stopPropagation();
                setShowOptionsMenu((prev) => !prev);
              }}
            >
              <Avatar
                src={image}
                size={40}
              />
              <OptionsMenu
                header={(
                  <div className="options-menu-header">
                    <Avatar
                      src={image}
                      size={40}
                    />
                    <div className="details">
                      <div>{email}</div>
                      <div className="name">{name}</div>
                    </div>
                  </div>
            )}
                isOpen={showOptionsMenu}
                onRequestClose={() => setShowOptionsMenu(false)}
                options={[{ label: 'Logout', icon: <i className="icon-collapse" />, onClick: () => signout() }]}
              />
            </ButtonBase>
          </div>
        </nav>
      </Container>
    </div>
  );
};

export default Navbar;
