// Types
import { Button as IProps } from './Button.types';

// Components
import DotsLoader from 'components/General/Loader/Loader';

// Styling
import './Button.scss';

const Button = ({ children, color, variant, isLoading, onClick, ...rest }: IProps) => (
  <button
    className={`button ${isLoading ? 'primary' : color}${isLoading ? ' loading' : ''} ${variant}`}
    type="button"
    onClick={onClick}
    disabled={isLoading || rest.disabled}
    {...rest}
  >
    {isLoading ? <DotsLoader color="#FFF" /> : children}
  </button>
);

export default Button;
