import Button from 'components/General/Button/Button';
import Dropdown from 'components/General/Dropdown/Dropdown';
import TextField from 'components/General/TextField/TextField';
import { useLogic } from './SessionDuration.logic';
import './SessionDuration.scss';

export const SessionDuration = () => {
  const {
    duration,
    setDuration,
    sessionState,
    statusArr,
    setStatus,
    status,
    handleSolve,
    isLoading
  } = useLogic();
  return (
    <div className="session-duration-container">
      <div className="session-duration-input">
        <h2>Change session duration</h2>
        <TextField
          value={`${sessionState.sessionDuration.duration} hours`}
          disabled
          variant="rounded"
        />
      </div>
      <div className="session-duration-input">
        <h2>Convert to</h2>
        <Dropdown
          options={['3 hours', '2.5 hours', '2 hours', '1.5 hour', '1 hour', '0.5 hour']}
          onChange={(e) => setDuration(e.target.value)}
          value={duration}
        />
      </div>
      <div className="session-duration-input">
        <h2>Session status</h2>
        <Dropdown
          options={statusArr.map((item) => item.label)}
          onChange={(e) => setStatus(e.target.value)}
          value={status}
        />
      </div>
      <Button
        color="primary"
        isLoading={isLoading}
        onClick={handleSolve}
      >
        Save
      </Button>
    </div>
  );
};
