// Types
import { ConfirmationTimeSlot as IProps } from './ConfirmationTimeSlot.types';

// Styling
import './ConfirmationTimeSlot.scss';

// Moment
import moment from 'moment-timezone';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash as DeleteIcon } from '@fortawesome/free-solid-svg-icons';

const ConfirmationTimeSlot = ({ time, selected, index, onClick }: IProps) => (
  <div
    className="confirmation-time-slot"
  >
    <span
      className={`${selected && 'selected'}`}
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
    >
      {`${moment(new Date(time)).format('MM/DD/YYYY')} - ${moment(new Date(time)).format('h:mm a')}`}
    </span>
    <div className="actions">
      <span>{`Session ${index === undefined ? undefined : index + 1}`}</span>
      <ButtonBase
        tagName="div"
        onClick={onClick}
      >
        <div className="delete">
          <FontAwesomeIcon
            icon={DeleteIcon}
            className="icon"
            color="#97ACCC"
            display={12}
          />
        </div>
      </ButtonBase>
    </div>

  </div>
);

export default ConfirmationTimeSlot;
