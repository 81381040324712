import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateAssignment } from 'services/Dashboard/Assignments/Mutations/useUpdateAssignment';
import { HomeworkSlice, setAssignment, setMark } from '../../../HomeworkSlice';
import { GiveMark } from './GiveMark.types';

export const useLogic = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const homeworkState = useSelector((state: { homework: HomeworkSlice }) => state.homework);
  const { mutateAsync: updateAssignment, isLoading } = useUpdateAssignment();
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<GiveMark>({
    defaultValues: {
      mark: 0,
      originalMark: 0,
    }
  });

  const onSubmit = handleSubmit((data) => {
    if (errors.mark) return;
    const mark = {
      receivedMark: Number(data.mark),
      classId: homeworkState.assignment.assignment?.classId,
      id: homeworkState.assignment.assignment?.id,
    };
    updateAssignment(mark).then(() => {
      dispatch(setMark({}));
      dispatch(setAssignment({ modal: false }));
      queryClient.refetchQueries('homework');
    });
  });

  useEffect(() => {
    setValue('originalMark', homeworkState.assignment.assignment?.fullMark);
  }, [homeworkState.assignment.assignment?.fullMark]);

  return {
    control,
    errors,
    onSubmit,
    isLoading,
    homeworkState
  };
};
