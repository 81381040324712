import { FC } from 'react';

// Types
import { DropdownProps } from './Dropdown.types';

// Style
import './Dropdown.scss';

const Dropdown: FC<DropdownProps> = ({ options, onClick, ...rest }) => (
  <select
    className="dropdown"
    {...rest}
  >
    {options.map((option) => (
      <option>{option}</option>
    ))}
  </select>
);

export default Dropdown;
