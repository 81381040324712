import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useAuth } from 'routes/Auth/AuthProvider';
import { useReadNotifications } from 'services/Dashboard/Notifications/Mutations/useReadNotifications';
import { useUserNotifications } from 'services/Dashboard/Notifications/Queries/UserNotifications/useUserNotifications';
import { useReactQueryNotificationSubscription } from 'services/Socket/socket.notifications';

export const useLogic = () => {
  const { user } = useAuth();
  const queryClinet = useQueryClient();
  const [limit, setLimit] = useState(4);
  const { data: notifications, isFetching: isLoading } = useUserNotifications(limit);
  const { mutateAsync: readNotifications } = useReadNotifications();
  useReactQueryNotificationSubscription(Number(user?.user.id));

  return {
    notifications,
    isLoading,
    setLimit,
    queryClinet,
    readNotifications
  };
};
