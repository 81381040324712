// Styling
import './Students.scss';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight as RightArrowIcon } from '@fortawesome/free-solid-svg-icons';

// Logic
import { useLogic } from './Students.logic';
import Modal from 'components/General/Modal/Modal';
import { AdminDashboardLayout } from 'layouts/AdminDashboardLayout/AdminDashboardLayout';
import Typography from 'components/General/Typography/Typography';
import Table from 'components/Dashboard/Table/Table';
import Label from 'components/General/Label/Label';
import Avatar from 'components/General/Avatar/Avatar';
import moment from 'moment-timezone';
import { Statistics } from '../Statistics/Statistics';
import { Filters } from '../Filters/Filters';
import { toggleFiltersModal } from '../StudentsSlice';
import { DASHBOARD, ENROLLMENTS, STUDENT_PROFILE } from 'routes/CONSTANTS';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';

export const Students = () => {
  const {
    generateFilter,
    studentsState,
    dispatch,
    Columns,
    data,
    isLoadingTableData,
    isFetching,
    navigate,
    offset,
    setOffset,
    setSortBy,
    setDesc
  } = useLogic();

  const NameCol = ({ src, name }: { src: string; name: string; }) => (
    <div className="name-col">
      <Avatar
        src={src}
        size={45}
      />
      <div className="name">
        <Typography
          color="black"
          className="mb-05"
        >
          {name}
        </Typography>
      </div>
    </div>
  );

  return (
    <AdminDashboardLayout>
      <div className="students-container">
        <Filters isLoading={isFetching} />
        <Statistics />
        <div className="table mt-4">
          <Table
            onSort={({ key, direction }) => {
              setDesc(direction === 'desc');
              setSortBy(key);
            }}
            isLoading={isLoadingTableData}
            columns={Columns}
            data={
              data?.adminAllUsersStat.allUsersStat.map((user) => ([
                { value: <NameCol
                  src={user.picture}
                  name={user.name}
                /> },
                { value: user?.courses?.length > 0
                  ? (
                    <div style={{ display: 'flex', gap: 5 }}>
                      {
                        Array.from(new Set(user.courses.map(((course) => course.name)))).map((course) => (
                          <Label color={course}>{course}</Label>
                        ))
                      }
                    </div>
                  )
                  : '-'
                },
                { value: <Label color={user.status}>{user.status}</Label> },
                { value: <Typography>{user.country ? user.country : '-'}</Typography> },
                { value: <Typography>{user.teachers?.[0]?.name ? user.teachers?.[0]?.name : '-'}</Typography> },
                { value: <Typography>{user.improvementRate ? `%${user.improvementRate}` : '-'}</Typography> },
                { value: <Typography>{(user.remainingSessions === 0 && '-') || (user.remainingSessions > 1 ? `${user.remainingSessions} sessions` : `${user.remainingSessions} session`)}</Typography> },
                { value: <Typography>{(user.takenSessions === 0 && '-') || (user.takenSessions > 1 ? `${user.takenSessions} sessions` : `${user.takenSessions} session`)}</Typography> },
                { value: <Typography>{user.nearest ? moment(user.nearest).fromNow() : '-'}</Typography> },
                { value: <Typography>{user.createdAt ? moment(user.createdAt).format('YYYY-MM-DD') : '-'}</Typography> },
                { value: <FontAwesomeIcon
                  icon={RightArrowIcon}
                  className="arrow-icon"
                  color="#696C71"
                  onClick={() => navigate(`/${DASHBOARD}/${STUDENT_PROFILE(String(user.id))}/${ENROLLMENTS}`)}
                /> },
              ]))
            }
          />
        </div>
        {!isLoadingTableData && (
          <div className="pagination">
            <ButtonBase
              tagName="i"
              onClick={() => {
                if (offset < 20) return;
                setOffset((prev) => prev - 20);
              }}
            >
              <i className={`icon-chevron-left ${offset < 20 && 'disabled'}`} />
            </ButtonBase>
            <Typography>
              {`${offset} - ${Number(data?.adminAllUsersStat.usersTotal) - offset > 20 ? offset + 20 : data?.adminAllUsersStat.usersTotal} of ${data?.adminAllUsersStat.usersTotal}`}
            </Typography>
            <ButtonBase
              tagName="i"
              onClick={() => {
                if (Number(data?.adminAllUsersStat.usersTotal) - offset <= 20) return;
                setOffset((prev) => prev + 20);
              }}
            >
              <i className={`icon-chevron-right ${(Number(data?.adminAllUsersStat.usersTotal) - offset <= 20) && 'disabled'}`} />
            </ButtonBase>
          </div>
        )}
        <Modal
          isClosble
          isOpen={studentsState.filtersModal}
          onRequestClose={() => dispatch(toggleFiltersModal(false))}
        >
          {generateFilter(studentsState.filter.selectedFilter)}
        </Modal>
      </div>
    </AdminDashboardLayout>
  );
};
