import { useLogic } from './CashPayment.logic';
import './CashPayment.scss';
import Button from 'components/General/Button/Button';
import { CircularProgressbar } from 'react-circular-progressbar';
import { v4 as uuid } from 'uuid';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash as DeleteIcon } from '@fortawesome/free-solid-svg-icons';
import DocumentIcon from 'assets/images/document-icon.svg';
import Upload from 'assets/images/receipt.svg';
import { CashPaymentInstructions } from './CashPaymentInstructions/CashPaymentInstructions';

export const CashPayment = () => {
  const {
    onSubmit,
    isUploading,
    progress,
    files,
    handleRemoveNewFile,
    input,
    handleUpload,
    isUploadingReceipt,
    dispatch,
    dashboardState,
    isImage,
    downlowadRef,
    handleRemoveUploadedFile,
    receiptURLs,
    isLoadingUpdateReceipt,
    setShowPaymentInstructions,
    handlePayByCard
  } = useLogic();

  return (
    <div className="cash-payment-container">
      {dashboardState.showPaymentInstructions ? (
        <>
          <CashPaymentInstructions />
        </>
      ) : (
        <>
          <div className="header-container">
            <h2>{dashboardState.cashTransactions.length ? 'Edit receipt' : 'Upload receipt'}</h2>
          </div>
          <h3>
            {dashboardState.cashTransactions.length
              ? ''
              : 'You should pay course fees via Western union and upload receipt. Review the receiver’s info.'}
          </h3>
          <div className="upload-image-comntainer">
            {files.length === 0 && (
            <ButtonBase
              tagName="div"
              onClick={() => input.current?.click()}
            >
              <img
                src={Upload}
                alt=""
              />
              <input
                ref={input}
                type="file"
                name="filetobase64"
                multiple
                accept="image/*, .pdf"
                onChange={handleUpload}
              />
            </ButtonBase>
            )}
          </div>
          <div className="uploads-container">
            <>
              {files.length > 0 && (
              <>
                <h3>New upload</h3>
                <div className="new-uploads-container">
                  {files.map((file) => (
                    <div
                      className="image-container"
                      key={uuid()}
                    >
                      {file.type.includes('image') ? (
                        <ButtonBase
                          tagName="div"
                          onClick={(e) => {
                            e.stopPropagation();
                            const image = new Image();
                            image.src = `data:image/jpg;base64,${file.base64}`;

                            const w = window.open('');
                            w?.document.write(image.outerHTML);
                          }}
                        >
                          <img
                            src={`data:image/jpeg;base64, ${file.base64}`}
                            alt=""
                          />
                        </ButtonBase>
                      ) : (
                        <div className="document-icon">
                          <img
                            src={DocumentIcon}
                            alt=""
                          />
                        </div>
                      )}
                      <div className="right-container">
                        {isUploading && (
                        <div
                          style={{ width: 40, height: 40 }}
                          className="progress=bar"
                        >
                          <CircularProgressbar
                            value={progress}
                            styles={{ path: { stroke: '#6371E0' }, text: { fill: '#6371E0', fontSize: 30 } }}
                            text={String(progress)}
                          />
                        </div>
                        )}
                        <FontAwesomeIcon
                          icon={DeleteIcon}
                          className="icon"
                          color="#97ACCC"
                          display={12}
                          onClick={() => handleRemoveNewFile(files.indexOf(file))}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
              )}
              {Number(receiptURLs?.length) > 0 && (
              <>
                <h3>Uploaded reciept</h3>
                <div className="images-container">
                  {receiptURLs?.map((url) => (
                    <div
                      className="image-container"
                      key={uuid()}
                    >
                      <ButtonBase
                        tagName="div"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(url.attachment, '_blank');
                        }}
                      >
                        <a
                          download
                          ref={downlowadRef}
                        >
                          {isImage(String(url.attachment)) ? (
                            <img
                              src={url.attachment}
                              alt=""
                            />
                          ) : (
                            <div className="document-icon">
                              <img
                                src={DocumentIcon}
                                alt=""
                              />
                            </div>
                          )}
                        </a>
                      </ButtonBase>
                      <div className="right-container">
                        <FontAwesomeIcon
                          icon={DeleteIcon}
                          className="icon"
                          color="#97ACCC"
                          display={12}
                          onClick={() => handleRemoveUploadedFile(receiptURLs.indexOf(url))}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
              )}

            </>
          </div>
          <ButtonBase
            onClick={() => dispatch(setShowPaymentInstructions(true))}
            tagName="h3"
            className="button-container"
          >
            {files.length === 0 && <h3>Read payment instructions</h3>}
          </ButtonBase>
          <div className="mt-2">
            <Button
              color="primary"
              onClick={onSubmit}
              disabled={isUploading}
              isLoading={isUploadingReceipt || isLoadingUpdateReceipt}
            >
              Upload
            </Button>
          </div>
          {dashboardState.cashTransactions.length > 0 && (
          <div className="mt-1">
            <Button
              color="primary"
              onClick={handlePayByCard}
            >
              Pay by Credit Card
            </Button>
          </div>
          )}
        </>
      )}
    </div>
  );
};
