// Types
import { TeacherCalendarProps, TeacherCalendarFilters } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useTeacherCalendar = ({ classesIds, countries, courses, month }: TeacherCalendarFilters) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeacherCalendarProps, Error> = useQuery<TeacherCalendarProps, Error>(['teacher-calendar', [classesIds, countries, courses, month]], async () => client.request(
    gql`
    query TeacherCalendar($courses: [String!], $countries: [String!], $classesIds: [Float!], $teachersIds: [Float!], $month: DateTime!) {
      teacherCalendar(courses: $courses, countries: $countries, classesIds: $classesIds, teachersIds: $teachersIds, month: $month) {
        sessions {
          id
          startDate
          endDate
          teacherId
          planId
          course
          datetime
          sessionNumber
          studentName
          teacherName
          isAccessible
          teacherLink
          isAccessible
          status
          duration
          acuityCalendarId
          acuityAppTypeIds {
            GMAT {
              halfHour
              hour
              hourAndAHalf
              threeHours
              twoHours
              twoHoursAndAHalf
            }
            IELTS {
              halfHour
              hour
              hourAndAHalf
              threeHours
              twoHours
              twoHoursAndAHalf
            }
            SAT {
              halfHour
              hour
              hourAndAHalf
              threeHours
              twoHours
              twoHoursAndAHalf
            }
          }
        }
        teachers {
          id
          text
          color
          sessionsCount
        }
      }
    }
    `,
    {
      courses,
      countries,
      classesIds,
      month
    }
  ));
  return data;
};
