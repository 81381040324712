import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Attachments = {
  id: number,
  attachment: string
}[];

export type PracticeTest = {
  id?: number;
  status?: string;
  name?: string;
  dueDate?: string | Date;
  fullMark?: number;
  receivedMark?: IReceivedMarks;
  details?: string;
  classId?: number;
  link?: string;
  attachments?: {
    id: number,
    attachment: string;
    teacherAttachment: boolean
  }[]
};

export type Mark = {
  id?: number;
  mark?: number;
  originalMark?: number;
  toggleModal?: boolean
};

export type IReceivedMarks = {
  QuantitativeScore?: number
  englishScore?: number
  listening?: number
  mathScore?: number
  reading?: number
  speaking?: number
  totalScore?: number
  verbalScore?: number
  writing?: number
};

export interface PracticeTestSlice {
  reusePracticeTest: PracticeTest;
  practiceTest: PracticeTest;
  mark: Mark;
  attachments: Attachments;
  editPracticeTestId?: number,
  showNewPracticeTestModal: boolean
  showReusePracticeTestModal: boolean
}

const initialState: PracticeTestSlice = {
  reusePracticeTest: {},
  practiceTest: {},
  mark: { toggleModal: false },
  attachments: [],
  editPracticeTestId: undefined,
  showNewPracticeTestModal: false,
  showReusePracticeTestModal: false
};

const practiceTest = createSlice({
  name: 'practiceTest',
  initialState,
  reducers: {
    setReusePracticeTest: (state, { payload }: PayloadAction<PracticeTest>) => {
      state.reusePracticeTest = payload;
    },
    setPracticeTest: (state, { payload }: PayloadAction<PracticeTest>) => {
      state.practiceTest = payload;
    },
    setMark: (state, { payload }: PayloadAction<Mark>) => {
      state.mark = payload;
    },
    setAttachments: (state, { payload }: PayloadAction<Attachments>) => {
      state.attachments = [...state.attachments, ...payload];
    },
    setEditPracticeTestId: (state, { payload }: PayloadAction<number | undefined>) => {
      state.editPracticeTestId = payload;
    },
    setToggleNewPracticeTestModal: (state) => {
      state.showNewPracticeTestModal = !state.showNewPracticeTestModal;
    },
    setToggleReusePracticeTestModal: (state) => {
      state.showReusePracticeTestModal = !state.showReusePracticeTestModal;
    },
  },
});

export default practiceTest;
export const {
  setReusePracticeTest,
  setPracticeTest,
  setMark,
  setAttachments,
  setEditPracticeTestId,
  setToggleNewPracticeTestModal,
  setToggleReusePracticeTestModal
} = practiceTest.actions;
