import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StreamPost } from './Strem.types';

export type File = {
  base64?: string,
  type?: string,
  description?: string
};

type RateData = {
  toggleRatingModal: boolean,
  sessionId?: number,
  ratedUserId?: number
};
export interface StreamSlice {
  file: File;
  post: StreamPost;
  editablePost: boolean;
  rateData: RateData;

}

const initialState: StreamSlice = {
  file: {},
  post: {},
  editablePost: false,
  rateData: { toggleRatingModal: false },
};

const stream = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    setFileData: (state, { payload }: PayloadAction<File>) => {
      state.file = {
        ...state.file,
        ...payload
      };
    },
    setPostData: (state, { payload }: PayloadAction<StreamPost>) => {
      state.post = payload;
    },
    toggleEditablePost: (state, { payload }: PayloadAction<boolean>) => {
      state.editablePost = payload;
    },
    setStreamRateData: (state, { payload }: PayloadAction<RateData>) => {
      state.rateData = payload;
    },
  },
});

export default stream;
export const {
  setFileData,
  setPostData,
  toggleEditablePost,
  setStreamRateData,
} = stream.actions;
