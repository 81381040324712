import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Report } from 'services/Dashboard/Sessions/Queries/ClassSessions/types';

type Session = {
  id?: number;
  sessionNumber?: number;
  dueAt?: string,
  duration?: string,
  teacher?: {
    id?: number,
    acuityCalendarId?: number,
    acuityAppTypeIds?: {
      IELTS?: number,
      SAT?: number,
      GMAT?: number
    }
  }
};

type SessionRate = {
  modal: boolean,
  sessionId?: number,
  ratedUserId?: number
};

type SessionProblem = {
  modal?: boolean,
  sessionId?: number,
  course?: string,
  duration?: number,
  studentTimeIn?: number,
  teacherTimeIn?: number,
  sessionNumber?: number,
  teacherReport?: Report,
  studentReport?: Report,
};

type SessionDuration = {
  modal: boolean,
  sessionId?: number,
  duration?: number
};

type ExtraSession = {
  modal: boolean
};

type SessionsInfo = {
  planHours?: number,
  takenHours?: number,
  sessions?: number,
};

type RemoveSession = {
  modal: boolean,
  sessionId?: number,
};

export interface SessionSlice {
  rescheduleData: Session
  rescheduleModal: boolean;
  sessionRate: SessionRate;
  sessionProblem: SessionProblem;
  sessionDuration: SessionDuration;
  extraSession: ExtraSession;
  sessionsInfo: SessionsInfo;
  removeSession: RemoveSession
}

const initialState: SessionSlice = {
  rescheduleData: {},
  rescheduleModal: false,
  sessionRate: { modal: false },
  sessionProblem: { modal: false },
  sessionDuration: { modal: false },
  extraSession: { modal: false },
  sessionsInfo: {},
  removeSession: { modal: false }
};

const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setRescheduleData: (state, { payload }: PayloadAction<Session>) => {
      state.rescheduleData = { ...state.rescheduleData, ...payload };
    },
    setRescheduleModal: (state, { payload }: PayloadAction<boolean>) => {
      state.rescheduleModal = payload;
    },
    setSessionRate: (state, { payload }: PayloadAction<SessionRate>) => {
      state.sessionRate = { ...state.sessionRate, ...payload };
    },
    setSessionProblem: (state, { payload }: PayloadAction<SessionProblem>) => {
      state.sessionProblem = { ...state.sessionProblem, ...payload };
    },
    setSessionDuration: (state, { payload }: PayloadAction<SessionDuration>) => {
      state.sessionDuration = { ...state.sessionDuration, ...payload };
    },
    setAddExtraSession: (state, { payload }: PayloadAction<ExtraSession>) => {
      state.extraSession = { ...state.extraSession, ...payload };
    },
    setSessionsInfo: (state, { payload }: PayloadAction<SessionsInfo>) => {
      state.sessionsInfo = { ...state.sessionsInfo, ...payload };
    },
    setRemoveSession: (state, { payload }: PayloadAction<RemoveSession>) => {
      state.removeSession = { ...state.removeSession, ...payload };
    },
  },
});

export default session;
export const {
  setRescheduleModal,
  setRescheduleData,
  setSessionRate,
  setSessionProblem,
  setSessionDuration,
  setAddExtraSession,
  setSessionsInfo,
  setRemoveSession
} = session.actions;
