import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useCreateSession = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ appointmentTypeId, classId, datetime }: { appointmentTypeId: number, classId?: number, datetime: string }) => client.request(
    gql`
    mutation AdminCreateSession($appointmentTypeId: Float!, $classId: Float!, $datetime: DateTime!) {
      adminCreateSession(appointmentTypeId: $appointmentTypeId, classId: $classId, datetime: $datetime) {
        message
      }
    }
    `,
    {
      appointmentTypeId,
      classId,
      datetime
    }
  ));
  return Mutation;
};
