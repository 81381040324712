import Button from 'components/General/Button/Button';
import TextField from 'components/General/TextField/TextField';
import { Controller } from 'react-hook-form';
import { useLogic } from './GiveMark.Logic';

export const GiveMark = () => {
  const {
    control,
    onSubmit,
    errors,
    isLoading,
    className
  } = useLogic();

  return (

    <div className="give-mark-container">
      <h2>Give Mark</h2>
      <div className="text-fields-container">
        { className === 'SAT' && (
          <div className="mark-container">
            <Controller
              control={control}
              name="englishScore"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                max: {
                  value: 800,
                  message: 'Score cannot be greater than the 800.'
                },
                min: {
                  value: 200,
                  message: 'Score cannot be less than the 200.'
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="200 - 800"
                    id="mark"
                    label="English score"
                    type="number"
                    error={errors.englishScore?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="mathScore"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                max: {
                  value: 800,
                  message: 'Score cannot be greater than the 800.'
                },
                min: {
                  value: 200,
                  message: 'Score cannot be less than the 200.'
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="200 - 800"
                    id="mark"
                    label="Math score"
                    type="number"
                    error={errors.mathScore?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="totalScore"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                max: {
                  value: 1600,
                  message: 'Score cannot be greater than the 1600.'
                },
                min: {
                  value: 400,
                  message: 'Score cannot be less than the 400.'
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="400 - 1600"
                    id="mark"
                    label="Total score"
                    type="number"
                    error={errors.totalScore?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
          </div>
        )}
        { className === 'IELTS' && (
          <div className="mark-container">
            <Controller
              control={control}
              name="reading"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                max: {
                  value: 9,
                  message: 'Score cannot be greater than 9.'
                },
                min: {
                  value: 1,
                  message: 'Score cannot be less than 1.'
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="1 - 9"
                    id="mark"
                    label="Reading score"
                    type="number"
                    error={errors.reading?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="writing"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                max: {
                  value: 9,
                  message: 'Score cannot be greater than 9.'
                },
                min: {
                  value: 1,
                  message: 'Score cannot be less than 1.'
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="1 - 9"
                    id="mark"
                    label="Writing score"
                    type="number"
                    error={errors.writing?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="speaking"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                max: {
                  value: 9,
                  message: 'Score cannot be greater than 9.'
                },
                min: {
                  value: 1,
                  message: 'Score cannot be less than 1.'
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="1 - 9"
                    id="mark"
                    label="Speaking score"
                    type="number"
                    error={errors.speaking?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="listening"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                max: {
                  value: 9,
                  message: 'Score cannot be greater than 9.'
                },
                min: {
                  value: 1,
                  message: 'Score cannot be less than 1.'
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="1 - 9"
                    id="mark"
                    label="Listening score"
                    type="number"
                    error={errors.listening?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="totalScore"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                max: {
                  value: 9,
                  message: 'Score cannot be greater than 9.'
                },
                min: {
                  value: 1,
                  message: 'Score cannot be less than 1.'
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="1 - 9"
                    id="mark"
                    label="Total score"
                    type="number"
                    error={errors.totalScore?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
          </div>
        )}
        { className === 'GMAT' && (
          <div className="mark-container">
            <Controller
              control={control}
              name="QuantitativeScore"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                max: {
                  value: 60,
                  message: 'Score cannot be greater than 60'
                },
                min: {
                  value: 0,
                  message: 'Score mark cannot be less zero.'
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="0 - 60"
                    id="mark"
                    label="Quantitative score"
                    type="number"
                    error={errors.QuantitativeScore?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="verbalScore"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                max: {
                  value: 60,
                  message: 'Score cannot be greater than 60'
                },
                min: {
                  value: 0,
                  message: 'Score mark cannot be less zero.'
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="0 - 60"
                    id="mark"
                    label="Verbal score"
                    type="number"
                    error={errors.verbalScore?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="totalScore"
              rules={{
                required: {
                  value: true,
                  message: 'This field is required'
                },
                max: {
                  value: 800,
                  message: 'Score cannot be greater than 800'
                },
                min: {
                  value: 200,
                  message: 'Score mark cannot be less 200.'
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="rainbow-m-around_small">
                  <TextField
                    color="light-gray"
                    variant="rounded"
                    placeholder="200 - 800"
                    id="mark"
                    label="Total score"
                    type="number"
                    error={errors.totalScore?.message}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              )}
            />
          </div>
        )}
      </div>
      <div className="button-container mt-3">
        <Button
          color="primary"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
