import { TableColumn } from 'components/Dashboard/Table/Table.types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClassFilters from '../Filters/ClassFilter/ClassFilter';
import { StatusFilter } from '../Filters/StatusFilter/StatusFilter';
import { useGetHomework } from 'services/Dashboard/Assignments/Queries/Homework/useGetHomework';
import { HomeworkSlice } from '../HomeworkSlice';

export const useLogic = () => {
  const homeworkState = useSelector((state: { homework: HomeworkSlice }) => state.homework);
  const dispatch = useDispatch();
  const [showAddPatient, setShowAddPatient] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [sortBy, setSortBy] = useState('created_at');
  const [desc, setDesc] = useState(true);
  const { data, isLoading: isLoadingTableData, isFetching } = useGetHomework({ searchByAssTitle: homeworkState.filter.searchName, search: homeworkState.filter.search, filterBy: homeworkState.filter, offset, sortBy, desc, limit: 20 });
  const Columns: TableColumn[] = [
    {
      value: 'Name',
      sortable: true,
      key: 'name',
      align: 'left'
    },
    {
      value: 'Course',
      sortable: true,
      key: 'Course taken',
    },
    {
      value: 'Assignment title',
      sortable: true,
      key: 'assignment_title'
    },
    {
      value: 'Status',
      sortable: true,
      key: 'status'
    },
    {
      value: 'Due date',
      sortable: true,
      key: 'due_at'
    },
    {
      value: 'Date created',
      sortable: true,
      key: 'created_at',
    },
    {
      value: 'Created by',
      sortable: true,
      key: 'created_by',
    },
  ];

  const generateFilter = (item?: string) => {
    switch (item) {
      case 'Subject':
        return <ClassFilters />;
      case 'Status':
        return <StatusFilter />;
      default:
        return <ClassFilters />;
    }
  };

  return {
    generateFilter,
    homeworkState,
    dispatch,
    showAddPatient,
    setShowAddPatient,
    Columns,
    data,
    isLoadingTableData,
    isFetching,
    offset,
    setOffset,
    sortBy,
    setSortBy,
    setDesc,
  };
};
