// Components
import Modal from 'components/General/Modal/Modal';
import { CourseLayout } from 'layouts/CourseLayout/CourseLayout';
import { AssignmentEvent } from 'components/Dashboard/AssignmentEvent/AssignmentEvent';

// Style
import './PracticeTest.scss';

// Logic
import { useLogic } from './PracticeTest.logic';

// uuid
import { v4 as uuid } from 'uuid';
import DotsLoader from 'components/General/Loader/Loader';
import { GiveMark } from './GiveMark/GiveMark';

// images
import SideBarImage from 'assets/images/student-side.svg';
import { setToggleNewPracticeTestModal, setToggleReusePracticeTestModal } from 'pages/Dashboard/Course/PracticeTest/PracticeTestSlice';
import { NewPracticeTest } from './NewPracticeTest/NewPracticeTest';
import { ReusePracticeTest } from './ReusePracticeTest/ReusePracticeTest';
import { PracticeTestCard } from 'components/Dashboard/PracticeTestCard/PracticeTestCard';
import { UpcomingEvent } from 'components/Dashboard/UpcomingEvent/UpcomingEvent';
import { setAssignment } from 'pages/Dashboard/DashboardSlice';
import LineChart from 'components/AdminDashboard/LineChart/LineChart';
import moment from 'moment-timezone';

export const PracticeTest = () => {
  const {
    isLoading,
    practiceTests,
    handeRemove,
    dispatch,
    setMark,
    userNearestPracticeTest,
    adminNearestPracticeTest,
    isLoadingnAdminNarestPracticeTest,
    isLoadingnUserNarestPracticeTest,
    practiceTestState,
    dashboardState,
    handleEdit,
    isAdmin,
    isTeacher,
    chartData,
    isLoadingChartData,
  } = useLogic();

  return (
    <>
      <CourseLayout sidebarContent={(
        <div className="sidebar">
          <div className="sidebar-events-container">
            {(isAdmin || isTeacher) ? (
              <>
                <div className="sidebar-event-container">
                  <AssignmentEvent
                    title="Create practice test"
                    buttonText="Create"
                    onClick={() => dispatch(setToggleNewPracticeTestModal())}
                  />
                </div>
                <div className="sidebar-event-container">
                  <AssignmentEvent
                    title="Reuse practice test"
                    buttonText="Reuse"
                    onClick={() => dispatch(setToggleReusePracticeTestModal())}
                  />
                </div>
                <div className="sidebar-event-container">
                  {(adminNearestPracticeTest?.adminNearestTest && adminNearestPracticeTest?.adminNearestTest.length > 0) ? (
                    <>
                      <h2 className="ml-1">Last practice test</h2>
                      <UpcomingEvent
                        title={adminNearestPracticeTest?.adminNearestTest?.[0]?.name}
                        date={adminNearestPracticeTest?.adminNearestTest?.[0]?.dueDate}
                        onClick={() => dispatch(setAssignment({ assignment: adminNearestPracticeTest?.adminNearestTest?.[0], tab: 'Practice tests' }))}
                      />
                    </>
                  ) : (
                    <>
                      {(isLoadingnAdminNarestPracticeTest || isLoadingChartData) && (
                      <DotsLoader />
                      )}

                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="sidebar-event-container">
                  <img
                    src={SideBarImage}
                    alt=""
                  />
                </div>
                <div className="sidebar-event-container">
                  {(userNearestPracticeTest?.userNearestTest && userNearestPracticeTest?.userNearestTest.length > 0) ? (
                    <>
                      <h2 className="ml-1">Last practice test</h2>
                      <UpcomingEvent
                        title={userNearestPracticeTest?.userNearestTest?.[0]?.name}
                        date={userNearestPracticeTest?.userNearestTest?.[0]?.dueDate}
                        onClick={() => dispatch(setAssignment({ assignment: userNearestPracticeTest?.userNearestTest?.[0], tab: 'Practice tests' }))}
                      />
                    </>
                  ) : (
                    <>
                      {isLoadingnUserNarestPracticeTest && (
                      <DotsLoader />
                      )}
                    </>
                  )}
                </div>
              </>

            )}
          </div>
          <div className="chart mt-2">
            <h2>Grades chart</h2>
            <LineChart
              maxStep={10}
              data={{
                labels: chartData?.userTestsChart?.names.map((date) => moment(date).format('YYYY-MM-DD')) || [],
                datasets: [{
                  label: 'Grade',
                  data: chartData?.userTestsChart?.marks || [],
                  backgroundColor: '#6371E0',
                  borderColor: '#6371E0',
                  pointRadius: 10,
                  pointBackgroundColor: '#6371E0',
                  pointHoverBackgroundColor: '#6371E0',
                  pointHoverRadius: 11,
                  type: 'line',
                }]
              }}
            />
          </div>
        </div>
  )}
      >
        <div className="class-body-container">
          <h2>{dashboardState.assignment.assignment?.classId ? 'Last Practice test' : 'Last Practice tests'}</h2>
          {isLoading ? (
            <DotsLoader />
          ) : (
            <div className="assignments-container">
              {dashboardState.assignment.assignment?.classId ? (
                <PracticeTestCard
                  key={uuid()}
                  link={dashboardState.assignment.assignment?.link}
                  id={dashboardState.assignment.assignment?.id}
                  title={dashboardState.assignment.assignment?.name}
                  date={dashboardState.assignment.assignment?.dueDate}
                  status={dashboardState.assignment.assignment?.status}
                  fullMark={dashboardState.assignment.assignment?.fullMark}
                  receivedMark={dashboardState.assignment.assignment?.receivedMark}
                  body={dashboardState.assignment.assignment?.details}
                  attachments={dashboardState.assignment.assignment?.attachments}
                  onEdit={() => handleEdit(dashboardState.assignment.assignment)}
                  onRemove={() => handeRemove(dashboardState.assignment.assignment?.id)}
                  toggleModal={() => {
                    dispatch(setMark({ originalMark: dashboardState.assignment.assignment?.fullMark, id: dashboardState.assignment.assignment?.id, toggleModal: true }));
                  }}
                />
              ) : (
                practiceTests?.userClassTest?.map((practiceTest) => (
                  <PracticeTestCard
                    key={uuid()}
                    link={practiceTest.link}
                    id={practiceTest.id}
                    title={practiceTest.name}
                    date={practiceTest.dueDate}
                    status={practiceTest.status}
                    fullMark={practiceTest.fullMark}
                    receivedMark={practiceTest.receivedMark}
                    body={practiceTest.details}
                    attachments={practiceTest.attachments}
                    onEdit={() => handleEdit(practiceTest)}
                    onRemove={() => handeRemove(practiceTest.id)}
                    toggleModal={() => {
                      dispatch(setMark({ originalMark: practiceTest.fullMark, id: practiceTest.id, toggleModal: true }));
                    }}
                  />
                ))
              )}
            </div>
          )}
        </div>
      </CourseLayout>
      {(isAdmin || isTeacher) && (
        <>
          <Modal
            isClosble
            isOpen={practiceTestState?.showNewPracticeTestModal}
            onRequestClose={() => dispatch(setToggleNewPracticeTestModal())}
            fullHeight
          >
            <NewPracticeTest closeModal={() => dispatch(setToggleNewPracticeTestModal())} />
          </Modal>

          <Modal
            isClosble
            isOpen={practiceTestState?.showReusePracticeTestModal}
            onRequestClose={() => dispatch(setToggleReusePracticeTestModal())}
            fullHeight
          >
            <ReusePracticeTest onReuseClick={() => {
              dispatch(setToggleReusePracticeTestModal());
              dispatch(setToggleNewPracticeTestModal());
            }}
            />
          </Modal>
          <Modal
            isClosble
            isOpen={practiceTestState?.mark?.toggleModal}
            onRequestClose={() => dispatch(setMark({ toggleModal: false }))}
          >
            <GiveMark />
          </Modal>
        </>
      )}
    </>
  );
};
