import Button from 'components/General/Button/Button';
import moment from 'moment-timezone';
import { NotificationCard } from '../NotificationCard/NotificationCard';
import { useLogic } from './Notification.logic';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { Notification as IProps } from './Notification.types';
import NotificationIcon from 'assets/images/bell.svg';
import { useEffect } from 'react';
import './Notification.scss';

export const Notification = ({ isOpen, onRequestClose }: IProps) => {
  const {
    isLoading,
    notifications,
    setLimit,
    readNotifications,
    queryClinet
  } = useLogic();

  useEffect(() => {
    queryClinet.refetchQueries('userNotification');
    readNotifications();
  }, [isOpen]);

  const handleEsc = (e: KeyboardEvent) => {
    if (e.key === 'Escape') { onRequestClose(); }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleEsc);
    window.addEventListener('click', onRequestClose);
    return () => {
      // unsubscribe event
      window.removeEventListener('keydown', handleEsc);
      window.removeEventListener('click', onRequestClose);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setLimit(4);
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <ButtonBase
          tagName="div"
          className="notification-container"
          onClick={(event) => event.stopPropagation()}
        >
            {notifications && (
            <>
              {notifications?.userNotifications.notifications?.map((item) => (
                <NotificationCard
                  key={item.id}
                  title={item.title}
                  createdAt={moment(item.createdAt).isSame(new Date(), 'day') ? moment(new Date(item.createdAt)).format('HH:mm a') : moment(new Date(item.createdAt)).format('MMM-D HH:mm a')}
                  message={item.message}
                />
              ))}
            </>
            )}
          <div className="see-more">
            {notifications?.userNotifications.notifications.length ? (
              <>
                <Button
                  color="primary"
                  onClick={() => setLimit((limit) => limit + 4)}
                  isLoading={isLoading}
                >
                  See more
                </Button>
              </>
            ) : (
              <p>No notifications yet. 😇</p>
            )}
          </div>
        </ButtonBase>
      )}
    </>
  );
};

export const NotificationButton = () => {
  const { notifications } = useLogic();
  return (
    <div className="notification-button-container">
      {Number(notifications?.userNotifications.newNotificationsCount) > 0 && (
      <span>
        <p className="sub-text">{notifications?.userNotifications.newNotificationsCount}</p>
      </span>
      )}
      <img
        src={NotificationIcon}
        alt=""
        className="icon"
      />
    </div>
  );
};
