// Types
import { LeadPaymentInfo } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useLeadPaymentInfo = (leadId: number, enabled: boolean) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<LeadPaymentInfo, Error> = useQuery<LeadPaymentInfo, Error>(['lead-payment-info', leadId], async () => client.request(
    gql`
    query AdminPayClassInfo($leadId: Float!) {
      adminPayClassInfo(leadId: $leadId) {
        leadStatus
        classId
        paymentMethod
        isPaid
        courseName
        numberOfSessions
        cashTransaction {
          id
          rejectionReason
          userId
          checkedById
          classId
          status
          updatedAt
          createdAt
          attachments {
            postId
            attachment
            id
          }
        }
      }
    }
    `,
    {
      leadId
    },
  ).catch((error) => console.error(error)), { enabled });
  return data;
};
