import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirmTransaction } from 'services/AdminDashboard/PersonalInfo/PaymentStatus/ConfirmTransaction/useConfirmTransaction';
import { LeadsSlice, setPayment } from '../../../LeadsSlice';

export const useLogic = () => {
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);
  const isImage = (url: string) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  const dispatch = useDispatch();
  const [confirmed, setConfirmed] = useState<boolean | undefined>(undefined);
  const [rejectionReason, setRejectionReason] = useState('');
  const { mutateAsync: confirmTransaction, isLoading } = useConfirmTransaction();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!leadsState.payment.cashPaymentModal) {
      dispatch(setPayment({ ...leadsState.payment, paymentConfirmation: {} }));
      setConfirmed(undefined);
      setRejectionReason('');
    }
  }, [leadsState.payment.cashPaymentModal]);

  const handleConfirmTransaction = () => {
    if (!confirmed && rejectionReason.length === 0) {
      toast('Please provide the rejecetion reason.');
      return;
    }
    confirmTransaction({
      transId: leadsState.payment.paymentConfirmation?.id,
      reason: rejectionReason.length > 0 ? rejectionReason : undefined,
      isVerified: confirmed
    }).then(() => {
      queryClient.refetchQueries('paymnet-status');
      dispatch(setPayment({ cashPaymentModal: false }));
      queryClient.refetchQueries('lead-payment-info');
    }).catch((err: any) => {
      toast.error(err?.response?.errors?.[0].message);
    });
  };

  const handlePayByCard = () => {
    dispatch(setPayment({ ...leadsState.payment, cashPaymentModal: false, paymentConfirmation: {}, modal: true }));
  };

  return {
    isImage,
    isLoading,
    confirmed,
    setConfirmed,
    handleConfirmTransaction,
    setRejectionReason,
    leadsState,
    handlePayByCard
  };
};
// dispatch(setPayment({ classId: user.classId, sessions: user.planSessionsCount, course: user.course, modal: true })
