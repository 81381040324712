import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useCreatePost } from 'services/Dashboard/Posts/Mutations/useCreatePost';
import { Files } from '../AssignmentCard/AssignmentCard.types';
import { base64 } from 'assets/utils/getBase64';
import { useFileUploadMutation } from 'services/UploadFiles/useUploadFile';
import toast from 'react-hot-toast';

export const useLogic = () => {
  const { id } = useParams();
  const [showEmojis, setShowEmojis] = useState(false);
  const [text, setText] = useState<string | undefined>('');
  const [files, setFiles] = useState<Files>([]);
  const [attachmentsIds, setAttachmentsIds] = useState<number[]>([]);
  const [chosenEmoji, setChosenEmoji] = useState<any>(undefined);
  const input = useRef<HTMLInputElement | null>(null);
  const { getBase64 } = base64();
  const { mutateAsync: createPost, isLoading: isLoadingCreatePost } = useCreatePost();
  const { progress, setProgress, mutation: { mutateAsync: uploadFiles, isLoading } } = useFileUploadMutation();

  const onEmojiClick = (event: any, emojiObject: any) => {
    setChosenEmoji(emojiObject);
  };

  useEffect(() => {
    if (chosenEmoji?.emoji) {
      setText(text + chosenEmoji?.emoji);
    }
  }, [chosenEmoji]);

  const addFiles = (file: Files) => {
    setFiles([...file, ...files]);
  };

  const handleUpload = async (e: any) => {
    if (!e.target.files) return;

    const formData = new FormData();
    const promises = [];
    const temp: Files = [];

    for (let i = 0; i < e.target.files.length || 0; i++) {
      if (e.target.files[i].size > 26214400) {
        toast.error('File cannot be bigger than 25 Megabytes');
        e.target.value = '';
        return;
      }
      formData.append('files[]', e.target.files[i]);
      promises.push(getBase64(e.target.files[i]));
    }

    const responses = await Promise.all(promises);
    responses.map((res: any) => {
      const startIndex = String(res.result).search('base64,') + 7;
      const base46 = String(res.result).slice(startIndex, String(res.result).length);
      temp.push({ base64: base46, type: res.type });
      return res;
    });
    addFiles(temp);

    uploadFiles({ file: formData })
      .then((res: any) => {
        setProgress(0);
        setAttachmentsIds([...attachmentsIds, ...res.data.attachmentsIds]);
      }).catch(() => {
        setFiles([...files].slice(-1));
      });

    e.target.value = '';
  };

  const handlePost = () => {
    createPost({
      text,
      classId: Number(id) || 0,
      attachmentsIds
    });
    setText('');
    setChosenEmoji(undefined);
    setAttachmentsIds([]);
    setFiles([]);
    setShowEmojis(false);
  };

  const handleRemoveImage = (index: number) => {
    setFiles(files.filter((file) => files.indexOf(file) !== index));
    setAttachmentsIds(attachmentsIds.filter((attachment) => attachmentsIds.indexOf(attachment) !== index));
  };

  return {
    text,
    showEmojis,
    input,
    files,
    progress,
    isLoading,
    setShowEmojis,
    onEmojiClick,
    handlePost,
    setText,
    handleUpload,
    handleRemoveImage,
    isLoadingCreatePost
  };
};
