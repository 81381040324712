// Logic
import { useLogic } from './UpdateCallLog.logic';

// React
import { FC } from 'react';

import './UpdateCallLog.scss';
import Button from 'components/General/Button/Button';
import DotsLoader from 'components/General/Loader/Loader';

const UpdateCallLog: FC<any> = () => {
  const {
    handleUpdateLog,
    isLoadingUpdateLog,
  } = useLogic();

  return (
    <>
      <div className="update-call-log-container">
        <h3>Did you call him/her?</h3>
        <div className="update-log-buttons-container">
          {
            isLoadingUpdateLog ? (
              <DotsLoader />
            ) : (
              <>
                <Button
                  color="white"
                  onClick={() => handleUpdateLog(false)}
                >
                  No
                </Button>
                <Button
                  color="primary"
                  onClick={() => handleUpdateLog(true)}
                >
                  Yes
                </Button>
              </>
            )
          }
        </div>
      </div>
    </>
  );
};

export default UpdateCallLog;
