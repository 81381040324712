import { ClassesLayout as IProps } from './ClassesLayout.types';
import './ClassesLayout.scss';

export const ClassesLayout = ({ children, sidebarContent }: IProps) => (
  <div className="class-layout-container">
    <div className="body-container">
      {children}
    </div>
    <div className="classes-sidebar-container">
      {sidebarContent}
    </div>
  </div>
);
