// Components
import { Google } from './Google/Google';
import { Apple } from './Apple/Apple';
import { CreditCard } from './CreditCard/CreditCard';

// Images
import CreditImage from 'assets/images/credit-card.svg';
import CashImage from 'assets/images/dollar.svg';

// Redux
import onboarding, { OnboardingSlice } from 'pages/Onboarding/OnboardingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { DashboardSlice } from '../../../DashboardSlice';
import { CashPayment } from '../CashPayment/CashPayment';
import { usePaymentInfo } from 'services/Payment/Queries/PaymentInfo/usePaymentInfo';
import { useUserUseCoupon } from 'services/Payment/Mutations/useUserUseCoupon';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useUserRemoveCoupon } from 'services/Payment/Mutations/useUserRemoveCoupon';
import Button from 'components/General/Button/Button';

export const useLogic = () => {
  const dispatch = useDispatch();
  const { setChosePaymentMethod, setPlanData } = onboarding.actions;
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const [paymentMethod, setPaymentMethod] = useState<string | undefined>(undefined);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [promoCode, setPromoCode] = useState<string>();
  const { data: paymentInfo, isLoading: isLoadingPaymentInfo } = usePaymentInfo(dashboardState.payment.classId, promoCode);
  const { mutateAsync: useCoupon, isLoading: isCheckingCoupon } = useUserUseCoupon();
  const { mutateAsync: removeCoupon, isLoading: isLoadingRemoveCoupon } = useUserRemoveCoupon();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!dashboardState.paymentModal) {
      dispatch(setChosePaymentMethod(false));
      setPaymentMethod(undefined);
    }
  }, [dashboardState.paymentModal]);

  const paymentMethods = [
    {
      id: 1,
      name: 'Cash',
      icon: CashImage,
      disabled: false
    },
    {
      id: 2,
      name: 'Credit Card',
      icon: CreditImage,
      disabled: false
    },
  ];

  const generatePaymentMethod = () => {
    try {
      if (onboardingState.chosePaymentMethod) {
        switch (paymentMethod || dashboardState.payment.paymentMethod) {
          case 'Cash':
            return <CashPayment />;
          case 'Google':
            return <Google />;
          case 'Credit Card':
            return <CreditCard />;
          case 'Apple':
            return <Apple />;
          default:
            dispatch(setChosePaymentMethod(false));
            return (
              <div>
                Something went wrong while processing your request.
                <Button
                  color="primary"
                  onClick={() => dispatch(setChosePaymentMethod(false))}
                >
                  Back to payment
                </Button>
              </div>
            );
        }
      } else {
        dispatch(setChosePaymentMethod(false));
        return (
          <div>
            Something went wrong while processing your request.
            <Button
              color="primary"
              onClick={() => dispatch(setChosePaymentMethod(false))}
            >
              Back to payment
            </Button>
          </div>
        );
      }
    } catch {
      dispatch(setChosePaymentMethod(false));
      return (
        <div>
          Something went wrong while processing your request.
          <Button
            color="primary"
            onClick={() => dispatch(setChosePaymentMethod(false))}
          >
            Back to payment
          </Button>
        </div>
      );
    }
  };

  const checkCoupon = () => {
    useCoupon({ classId: dashboardState.payment.id, coupon: promoCode })
      .then(() => {
        setInvalidCode(false);
        queryClient.refetchQueries('payment-info');
      })
      .catch((err) => {
        toast(err.response.errors?.[0].message);
        setInvalidCode(true);
      });
  };

  const handleRemoveCoupon = () => {
    removeCoupon({ classId: dashboardState.payment.id }).then(() => {
      queryClient.refetchQueries('payment-info');
    });
  };

  return {
    onboardingState,
    dashboardState,
    paymentMethods,
    paymentMethod,
    setPaymentMethod,
    generatePaymentMethod,
    dispatch,
    setChosePaymentMethod,
    setPlanData,
    showCodeInput,
    setShowCodeInput,
    invalidCode,
    setInvalidCode,
    paymentInfo,
    isLoadingPaymentInfo,
    setPromoCode,
    isCheckingCoupon,
    checkCoupon,
    handleRemoveCoupon,
    isLoadingRemoveCoupon
  };
};
