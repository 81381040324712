import { useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { PracticeTest, setReusePracticeTest } from '../PracticeTestSlice';
import { useParams } from 'react-router';
import { useReusePracticeTests } from 'services/Dashboard/PracticeTests/Queries/ReusePracticeTests/useReusePracticeTests';

export const useLogic = () => {
  const { className } = useParams();
  const dispatch = useDispatch();
  const [OpenedFolder, setOpenedFolder] = useState<number | undefined>(undefined);
  const [isOpenFolder, setIsOpenFolder] = useState(false);
  const [searchValue, setSearchValue] = useState<number>();
  const { data: reusePracticeTests, isFetching: isLoading } = useReusePracticeTests(className);

  const searchPracticeTests = (id?: number) => {
    if (id) {
      const data = reusePracticeTests?.adminAllTests.filter((item: any) => String(item.classId).includes(String(id)));
      return data;
    }
    return reusePracticeTests?.adminAllTests;
  };

  const handleSetReusableAssignment = (assignment: PracticeTest) => {
    dispatch(setReusePracticeTest(assignment));
  };

  return {
    className,
    searchValue,
    reusePracticeTests,
    OpenedFolder,
    isOpenFolder,
    isLoading,
    handleSetReusableAssignment,
    setSearchValue,
    setIsOpenFolder,
    setOpenedFolder,
    searchPracticeTests
  };
};
