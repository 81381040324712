// Types
import { PracticeTestCard as IProps } from './PracticeTestCard.types';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes as ExitIcon,
  faDownload as DownloadIcon,
  faEllipsisV as OptionsIcon,
  faPencilAlt as EditIcon,
  faTrash as DeleteIcon } from '@fortawesome/free-solid-svg-icons';
import DocumentIcon from 'assets/images/document-icon.svg';

// Styling
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import './PracticeTestCard.scss';
import Button from 'components/General/Button/Button';
import { v4 as uuid } from 'uuid';
import { useLogic } from './PracticeTestCard.logic';

// Progressbar
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { IReceivedMarks } from 'pages/Dashboard/Course/PracticeTest/PracticeTestSlice';

export const Marks = ({ marks, course }: { marks?: IReceivedMarks, course: string }) => (
  <>
    {course === 'SAT' && (
      <div className="marks-container">
        <span className="status">{`English: ${marks?.englishScore || '-'}`}</span>
        <span className="status">{`Math: ${marks?.mathScore || '-'}`}</span>
        <span className="status">{`Total: ${marks?.totalScore || '-'}`}</span>
      </div>
    )}
    {course === 'GMAT' && (
      <div className="marks-container">
        <span className="status">{`Q: ${marks?.QuantitativeScore || '-'}`}</span>
        <span className="status">{`V: ${marks?.verbalScore || '-'}`}</span>
        <span className="status">{`Total: ${marks?.totalScore || '-'}`}</span>
      </div>
    )}
    {course === 'IELTS' && (
      <div className="marks-container">
        <span className="status">{`R: ${marks?.reading || '-'}`}</span>
        <span className="status">{`W: ${marks?.writing || '-'}`}</span>
        <span className="status">{`L: ${marks?.listening || '-'}`}</span>
        <span className="status">{`S: ${marks?.speaking || '-'}`}</span>
        <span className="status">{`Total: ${marks?.totalScore || '-'}`}</span>
      </div>
    )}
  </>
);

export const PracticeTestCard = ({ id, title, status, date, body, attachments, receivedMark, link, onEdit, onRemove, toggleModal }: IProps) => {
  const {
    showAssignmentBody,
    setShowAssignmentBody,
    showOptions,
    setShowOptions,
    files,
    input,
    handleRemoveImage,
    isAdmin,
    isTeacher,
    handleUpload,
    isLoading,
    progress,
    downlowadRef,
    isImage,
    handleSubmit,
    isLoadingSubmitTest,
    className
  } = useLogic();

  return (
    <div className="pratice-test-card-container">
      <div className="top-container">
        <div className="left-container">
          <div className="document-icon">
            <img
              src={DocumentIcon}
              alt=""
            />
          </div>
          <h2>{title}</h2>
        </div>
        <div className="right-container">
          {status === 'Corrected' ? (
            <Marks
              marks={receivedMark}
              course={String(className)}
            />
          ) : (
            <span className={`${(status === 'Submitted' && 'green') || (status === 'Overdue' && 'fuchsia') || (status === 'Active' && 'mustard')}`}>{status}</span>
          )}
          {((isAdmin || isTeacher) && (status === 'Active')) && (
            <>
              <FontAwesomeIcon
                icon={OptionsIcon}
                className="icon"
                color="#97ACCC"
                display={12}
                onClick={() => setShowOptions(!showOptions)}
              />
              <div className={showOptions ? 'show-tooltip' : 'hide-tooltip'}>
                <div className="tooltiptext">
                  <ButtonBase
                    tagName="div"
                    onClick={onEdit}
                  >
                    <div className="edit">
                      <FontAwesomeIcon
                        icon={EditIcon}
                        className="icon"
                        color="#97ACCC"
                        display={12}
                      />
                      <p>Edit</p>
                    </div>
                  </ButtonBase>
                  <ButtonBase
                    tagName="div"
                    onClick={onRemove}
                  >
                    <div className="delete">
                      <FontAwesomeIcon
                        icon={DeleteIcon}
                        className="icon"
                        color="#97ACCC"
                        display={12}
                      />
                      <p>Delete</p>
                    </div>
                  </ButtonBase>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      { !showAssignmentBody ? (
        <div className="bottom-container">
          <div className="date-container">
            <h3>{`Date: ${date}`}</h3>
          </div>
          <ButtonBase
            tagName="div"
            onClick={() => setShowAssignmentBody(!showAssignmentBody)}
          >
            <div className="button-container">
              <h3>{`${showAssignmentBody ? 'See less' : 'See more'}`}</h3>
            </div>
          </ButtonBase>
        </div>
      ) : (
        <>
          <div className="bottom-container">
            <div className="date-container">
              <h3>{`Date: ${date}`}</h3>
            </div>
            <ButtonBase
              tagName="div"
              onClick={() => setShowAssignmentBody(!showAssignmentBody)}
            >
              <div className="button-container">
                <h3>{`${showAssignmentBody ? 'See less' : 'See more'}`}</h3>
              </div>
            </ButtonBase>
          </div>
          <div className="assignment-body-container">
            <div dangerouslySetInnerHTML={{ __html: String(body) }} />
          </div>
          {link && (
            <div className="link-container">
              <p>Form link</p>
              <div className="link">
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {link}
                </a>
              </div>
            </div>
          )}
          {(isAdmin || isTeacher) ? (
            <>
              <div className="teacher-block-uploads-container">
                <div className="teacher-uploads-container">
                  <div className="header-container">
                    <h3>{attachments && attachments.filter((attachment) => attachment.teacherAttachment === true).length > 0 ? 'Instructor\'s uploaded files.' : 'No uploaded files'}</h3>
                    {status === 'Submitted' && (
                      <div className="button-container">
                        <Button
                          color="primary"
                          onClick={toggleModal}
                        >
                          Give mark
                        </Button>
                      </div>
                    )}
                  </div>
                  <>
                    {attachments && attachments.filter((attachment) => attachment.teacherAttachment === true).length > 0 && (
                    <div className="images-container">
                      {attachments.filter((attachment) => attachment.teacherAttachment === true).map((attachment) => (
                        <div
                          className="image-container"
                          key={uuid()}
                        >
                          <FontAwesomeIcon
                            icon={DownloadIcon}
                            className="icon"
                            color="#97ACCC"
                            display={12}
                            onClick={(e) => {
                              e.stopPropagation();
                              downlowadRef.current?.click();
                            }}
                          />
                          <ButtonBase
                            tagName="div"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(attachment.attachment, '_blank');
                            }}
                          >
                            <a
                              download
                              ref={downlowadRef}
                            >
                              {isImage(attachment.attachment) ? (
                                <img
                                  src={attachment.attachment}
                                  alt=""
                                />
                              ) : (
                                <div className="document-icon">
                                  <img
                                    src={DocumentIcon}
                                    alt=""
                                  />
                                </div>
                              )}
                            </a>
                          </ButtonBase>
                        </div>
                      ))}
                    </div>
                    )}
                  </>
                </div>

                {attachments && attachments.filter((attachment) => attachment.teacherAttachment === false).length > 0 && (
                <div className="student-uploads-container">
                  <h3>Student&rsquo;s submissions</h3>
                  <div className="images-container">
                    {attachments.filter((attachment) => attachment.teacherAttachment === false).map((attachment) => (
                      <div
                        className="image-container"
                        key={uuid()}
                      >
                        <FontAwesomeIcon
                          icon={DownloadIcon}
                          className="icon"
                          color="#97ACCC"
                          display={12}
                          onClick={(e) => {
                            e.stopPropagation();
                            downlowadRef.current?.click();
                          }}
                        />
                        <ButtonBase
                          tagName="div"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(attachment.attachment, '_blank');
                          }}
                        >
                          <a
                            download
                            ref={downlowadRef}
                          >
                            {isImage(attachment.attachment) ? (
                              <img
                                src={attachment.attachment}
                                alt=""
                              />
                            ) : (
                              <div className="document-icon">
                                <img
                                  src={DocumentIcon}
                                  alt=""
                                />
                              </div>
                            )}
                          </a>
                        </ButtonBase>
                      </div>
                    ))}
                  </div>
                </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="uploads-container">
                {attachments && attachments.filter((attachment) => attachment.teacherAttachment === true).length > 0 && (
                <div className="teacher-uploads-container">
                  <h3>Instuctor&rsquo;s uploaded files</h3>
                  <div className="images-container">
                    {attachments.filter((attachment) => attachment.teacherAttachment === true).map((attachment) => (
                      <div
                        className="image-container"
                        key={uuid()}
                      >
                        <FontAwesomeIcon
                          icon={DownloadIcon}
                          className="icon"
                          color="#97ACCC"
                          display={12}
                          onClick={(e) => {
                            e.stopPropagation();
                            downlowadRef.current?.click();
                          }}
                        />
                        <ButtonBase
                          tagName="div"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(attachment.attachment, '_blank');
                          }}
                        >
                          <a
                            download
                            ref={downlowadRef}
                          >
                            {isImage(attachment.attachment) ? (
                              <img
                                src={attachment.attachment}
                                alt=""
                              />
                            ) : (
                              <div className="document-icon">
                                <img
                                  src={DocumentIcon}
                                  alt=""
                                />
                              </div>
                            )}
                          </a>
                        </ButtonBase>
                      </div>
                    ))}
                  </div>
                </div>
                )}
                {attachments && attachments.filter((attachment) => attachment.teacherAttachment === false).length > 0 && (
                <div className="student-uploads-container">
                  <h3>Student&rsquo;s submissions</h3>
                  <div className="images-container">
                    {attachments.filter((attachment) => attachment.teacherAttachment === false).map((attachment) => (
                      <div
                        className="image-container"
                        key={uuid()}
                      >
                        <FontAwesomeIcon
                          icon={DownloadIcon}
                          className="icon"
                          color="#97ACCC"
                          display={12}
                          onClick={(e) => {
                            e.stopPropagation();
                            downlowadRef.current?.click();
                          }}
                        />
                        <ButtonBase
                          tagName="div"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(attachment.attachment, '_blank');
                          }}
                        >
                          <a
                            download
                            ref={downlowadRef}
                          >
                            {isImage(attachment.attachment) ? (
                              <img
                                src={attachment.attachment}
                                alt=""
                              />
                            ) : (
                              <div className="document-icon">
                                <img
                                  src={DocumentIcon}
                                  alt=""
                                />
                              </div>
                            )}
                          </a>
                        </ButtonBase>
                      </div>
                    ))}
                  </div>
                </div>
                )}
                {status !== 'Submitted' && (
                  <div className="new-uploads-container">
                    <div className="top">
                      <h3>{`${files.length > 0 ? 'new uploaded files' : 'upload files'}`}</h3>
                      <div className="button-container">
                        {isLoading && (
                        <div
                          style={{ width: 40, height: 40 }}
                          className="progress=bar"
                        >
                          <CircularProgressbar
                            value={progress}
                            styles={{ path: { stroke: '#6371E0' }, text: { fill: '#6371E0', fontSize: 30 } }}
                            text={String(progress)}
                          />
                        </div>
                        )}
                        <div>
                          <Button
                            color="primary"
                            onClick={() => input.current?.click()}
                              // FIXME shouldn't be disabled while uploading
                            disabled={isLoading}
                          >
                            Upload
                          </Button>
                        </div>
                        <input
                          ref={input}
                          type="file"
                          name="filetobase64"
                          multiple
                          accept="image/*, .pdf"
                          onChange={handleUpload}
                        />
                      </div>
                    </div>
                    {files.length > 0 && (
                    <div className="images-container">
                      {files.map((file) => (
                        <div
                          className="image-container"
                          key={uuid()}
                        >
                          <FontAwesomeIcon
                            icon={ExitIcon}
                            className="icon"
                            color="#97ACCC"
                            display={12}
                            onClick={() => handleRemoveImage(files.indexOf(file))}
                          />
                          {file.type.includes('image') ? (
                            <img
                              src={`data:image/jpeg;base64, ${file.base64}`}
                              alt=""
                            />
                          ) : (
                            <div className="document-icon">
                              <img
                                src={DocumentIcon}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    )}
                  </div>
                )}
              </div>
              {status !== 'Submitted' && (
                <div className="submit-container">
                  <Button
                    color="primary"
                    onClick={() => handleSubmit(id)}
                    // FIXME shouldn't be disabled while uploading
                    disabled={isLoading || (files.length === 0)}
                    isLoading={isLoadingSubmitTest}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
