import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useAdminEditManualPayment } from 'services/Payment/Mutations/useAdminEditManualPayment';
// import toast from 'react-hot-toast';
import { useAdminPayManually } from 'services/Payment/Mutations/useAdminPayManually';
import { StudentsSlice, setManualPaymentData } from '../../../StudentsSlice';
import { ICreatePayment } from './CreatePayment.types';

export const useLogic = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const studentsState = useSelector((state: { students: StudentsSlice }) => state.students);
  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm<ICreatePayment>({
    defaultValues: {
      paymentMethod: 'Credit card',
      discount: 0,
      comment: ''
    }
  });
  const { mutateAsync: pay, isLoading: isLoadingCreatePayment } = useAdminPayManually();
  const { mutateAsync: edit, isLoading: isLoadingEditPayment } = useAdminEditManualPayment();
  const isLoading = () => isLoadingCreatePayment || isLoadingEditPayment;
  const [type, setType] = useState(true);
  const rules = {
    min: {
      value: 0,
      message: 'Amount should not be less than 0'
    },
    max: {
      value: 1000,
      message: 'Amount cannot be more than $1000'
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    if (errors.paymentMethod?.message || errors.amount?.message || errors.date?.message || errors.discount?.message || errors.comment?.message) return;
    if (studentsState.manualPayment.transactionId) {
      edit({
        amount: type ? Number(data?.amount) : Number(data?.amount) * -1,
        comment: data?.comment,
        discount: type ? Number(data?.discount) : 0,
        paymentMethod: data?.paymentMethod === 'Credit card' ? 'CARD' : 'CASH',
        submittedAt: String(data.date),
        manualTransId: studentsState.manualPayment.transactionId
      }).then((res) => {
        toast.success(res.adminEditManualPayment.message);
        queryClient.refetchQueries('payment-status');
        dispatch(setManualPaymentData({ madal: false }));
        setValue('amount', undefined);
        setValue('comment', '');
        setValue('date', undefined);
        setValue('paymentMethod', 'Credit card');
        setValue('discount', 0);
      });
    } else {
      pay({
        amount: type ? Number(data?.amount) : Number(data?.amount) * -1,
        comment: data?.comment,
        discount: Number(data?.discount),
        paymentMethod: data?.paymentMethod === 'Credit card' ? 'CARD' : 'CASH',
        classId: studentsState.manualPayment.id,
        submittedAt: data.date
      }).then((res) => {
        toast.success(res.adminAddManualPayment.message);
        queryClient.refetchQueries('payment-status');
        dispatch(setManualPaymentData({ madal: false }));
        setValue('amount', undefined);
        setValue('comment', '');
        setValue('date', undefined);
        setValue('paymentMethod', 'Credit card');
        setValue('discount', 0);
      });
    }
  });

  useEffect(() => {
    if (!studentsState.manualPayment.madal) {
      setValue('amount', 0);
      setValue('comment', '');
      setValue('date', undefined);
      setValue('paymentMethod', 'Credit card');
      setValue('discount', 0);
    }
  }, [studentsState.manualPayment.madal]);

  useEffect(() => {
    if (studentsState.manualPayment.transactionId) {
      setValue('amount', studentsState.manualPayment.amount);
      setValue('comment', studentsState.manualPayment.comment);
      setValue('date', new Date(String(studentsState.manualPayment.date)));
      setValue('paymentMethod', studentsState.manualPayment.paymentMethod);
      setValue('discount', studentsState.manualPayment.discount);
    }
  }, [studentsState.manualPayment.transactionId]);

  return {
    onSubmit,
    control,
    errors,
    getValues,
    setValue,
    isLoading,
    type,
    setType,
    rules,
    watch
  };
};
