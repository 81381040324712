// Types
import { DotsLoader as IProps } from './Loader.types';

// Styling
import './Loader.scss';

const DotsLoader = ({ color }: IProps) => (
  <div className="dots-loader">
    <span style={{ backgroundColor: color }} />
    <span style={{ backgroundColor: color }} />
    <span style={{ backgroundColor: color }} />
  </div>
);

const SectionLoader = () => (
  <div className="section-loader">
    <DotsLoader />
  </div>
);

const OverlayLoader = () => (
  <div className="overlay-loader">
    <DotsLoader />
  </div>
);

const OverlayLoaderTop = () => (
  <div className="overlay-loader-top">
    <DotsLoader />
  </div>
);

const CircleLoader = ({ color }: IProps) => (
  <div
    className="circle-loader"
    style={{ borderBottomColor: color }}
  />
);

export default DotsLoader;
export { SectionLoader, OverlayLoader, CircleLoader, OverlayLoaderTop };
