import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useRescheduleSession = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ sessionId, datetime }: { sessionId?: number, datetime: string }) => client.request(
    gql`
    mutation UserRescheduleSession($datetime: DateTime!, $sessionId: Float!) {
      userRescheduleSession(datetime: $datetime, sessionId: $sessionId) {
        id
      }
    }
    `,
    {
      sessionId,
      datetime
    }
  ));
  return Mutation;
};
