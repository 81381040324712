import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTeacherCalendar } from 'services/Dashboard/Calendar/useTeacherCalendar';
import { TeacherCalendarsSlice } from '../CalendarSlice';

export const useLogic = () => {
  const teacherCalendarState = useSelector((state: { teacherCalendar: TeacherCalendarsSlice }) => state.teacherCalendar);
  const [month, setMonth] = useState(new Date());
  const { data, isFetching } = useTeacherCalendar({ ...teacherCalendarState.filter, month });
  const [sessionsCount, setSessionsCount] = useState(0);

  const calculataData = (date: Date) => {
    let startDate = '';
    let endDate = '';

    startDate = moment(date)
      .startOf('week')
      .isoWeekday(0)
      .add(1, 'week')
      .format('YYYY-MM-DD');
    endDate = moment(date)
      .endOf('week')
      .isoWeekday(-1)
      .add(1, 'week')
      .format('YYYY-MM-DD');

    const dateFilteredData = data?.teacherCalendar.sessions.filter((item) => moment(item.endDate).isBetween(moment(startDate).subtract(12, 'hours'), moment(endDate).add(12, 'hours')));
    const alldateFilteredData = data?.teacherCalendar.sessions.filter((item) => moment(item.endDate).isBetween(moment(startDate).subtract(12, 'hours'), moment(endDate).add(12, 'hours')));
    const dateFilteredDataCount = Number(alldateFilteredData?.reduce((prev, curr) => prev += curr.duration, 0));

    return {
      data: dateFilteredData,
      dataCount: dateFilteredDataCount,
    };
  };

  useEffect(() => {
    setSessionsCount(calculataData(teacherCalendarState.currentDate || new Date()).dataCount);
  }, [teacherCalendarState.date, data]);

  useEffect(() => {
    const date = moment().date(moment(teacherCalendarState.currentDate).date()).month(moment(teacherCalendarState.currentDate).month()).year(moment(teacherCalendarState.currentDate).year())
      .toDate();
    setMonth(date);
  }, [moment(teacherCalendarState.currentDate).month()]);

  return {
    sessionsCount,
    isFetching,
    data
  };
};
