import { useState } from 'react';
// Types
import { SelectBox as IProps } from './SelectBox.types';

// Styling
import './SelectBox.scss';

// React Select
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useSchools } from 'services/Onboarding/General/useOnboarding';

const SelectBox = ({ options, label, placeholder, error, ...rest }: IProps) => (
  // no need to use 'value' attribute
  <div className="mt-2">
    <p className={`sub-text ml-1 mb-05 ${error && 'fuchsia'}`}>{label}</p>
    <Select
      classNamePrefix="react-select"
      isClearable
      options={options}
      placeholder={<div className={`select-placeholder-text ${error && 'fuchsia'}`}>{placeholder}</div>}
      menuPortalTarget={document.body}
      {...rest}
    />
    {error && <p className="error">{error}</p>}
  </div>
);

export const SchoolsSelectBox = ({ options, label, placeholder, error, addSchool, ...rest }: IProps) => {
  const [keyword, setKeyword] = useState<string>('');
  const { mutateAsync: getSchoolsData, isLoading } = useSchools();
  const getSchools = (value: string) => new Promise((resolve) => {
    setKeyword(value);
    getSchoolsData(keyword)
      .then((data) => {
        resolve(data?.userGetSchools?.map((school: any) => ({ label: school.name, value: school.name })));
      });
  });

  return (
    <div className="mt-2">
      <p className={`sub-text ml-1 mb-05 ${error && 'fuchsia'}`}>{label}</p>
      <AsyncCreatableSelect
        classNamePrefix="react-select"
        isClearable
        placeholder={<div className={`select-placeholder-text ${error && 'fuchsia'}`}>{placeholder}</div>}
        loadOptions={getSchools}
        isLoading={isLoading}
        menuPortalTarget={document.body}
        formatCreateLabel={() => {
          if (addSchool) {
            return 'Add school ✏️';
          }
          return undefined;
        }}
        noOptionsMessage={() => 'Type your school name'}
        {...rest}
      />
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default SelectBox;
