// Types
import { AdminTabBar as IProps } from './AdminTabBar.types';

// Styling
import './AdminTabBar.scss';
import { v4 as uuid } from 'uuid';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import {
  useNavigate,
  useLocation } from 'react-router-dom';

export const AdminTabBar = ({ tabs, parentRoute }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTab = location.pathname.split('/')?.[2];

  const handleTabChange = (tab: string) => {
    navigate(`/${parentRoute}/${tab.toLocaleLowerCase()}`);
  };

  return (
    <div className="admin-tab-bar-container">
      { tabs.map((tab) => (
        <ButtonBase
          tagName="div"
          onClick={() => handleTabChange(tab)}
          key={uuid()}
        >
          <div
            className={`tab-container ${selectedTab === tab.split(' ').join('_').toLocaleLowerCase() && 'selected'}`}
          >
            <h3>
              {tab}
            </h3>
          </div>
        </ButtonBase>
      ))}
    </div>
  );
};
