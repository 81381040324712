// Types
import { AssignmentEvent as IProps } from './AssignmentEvent.types';

// Styling
// import Button from 'components/General/Button/Button';
import './AssignmentEvent.scss';
import Button from 'components/General/Button/Button';

export const AssignmentEvent = ({ title, buttonText, onClick }: IProps) => (
  <div className="assignment-event">
    <div className="event-title">
      <h3>{title}</h3>
    </div>
    <div className="event-button">
      <Button
        color="primary"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  </div>
);
