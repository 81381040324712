// React Router
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

// Routes
import { FORGOT_PASSWORD, SIGNUP_VERIFY } from 'routes/CONSTANTS';

// Types
import { SignInForm } from './SignIn.types';

// Components
import TextField from 'components/General/TextField/TextField';
import SocialButton from 'components/General/SocialButton/SocialButton';
import Button from 'components/General/Button/Button';

// React hook form
import { useForm, Controller } from 'react-hook-form';

// Axios
import { AxiosError } from 'axios';

// React Cookie
import { useCookies } from 'react-cookie';

// Layouts
import AuthenticationLayout from 'layouts/AuthenticationLayout/AuthenticationLayout';

// Auth context
import { useAuth } from 'routes/Auth/AuthProvider';

// Social login
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

// Stylesheet
import './SignIn.scss';
import { useState } from 'react';
import toast from 'react-hot-toast';

const SignIn = () => {
  const navigate = useNavigate();
  const setCookie = useCookies(['email'])[1];
  const [authError, setAuthError] = useState<string>();
  const [generalError, setGeneralError] = useState<string>();
  const { signin, socialAuth, isAuthenticating } = useAuth();
  const { control, handleSubmit, formState: { errors } } = useForm<SignInForm>({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = handleSubmit((data) => {
    if (errors.email?.message || errors.password?.message) return;
    signin(data)
      .catch((error: AxiosError | any) => {
        if (error.response?.data.message === 'Unverfied User!') {
          setCookie('email', data.email, { path: '/' });
          navigate(SIGNUP_VERIFY);
        } else if (error.response.data.statusCode) {
          setAuthError(' ');
          setGeneralError(error.response.data?.message);
        }
      });
  });

  return (
    <AuthenticationLayout>
      <div className="form-container">
        <h1 className="auth-title">Sign In</h1>
        <form onSubmit={onSubmit}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: 'This field is required',
              minLength: {
                value: 5,
                message: 'Email should be longer than 5 characters'
              },
              maxLength: {
                value: 30,
                message: 'Email cannot be longer than 30 characters'
              }
            }}
            render={({ field: { onChange } }) => (
              <TextField
                variant="rounded"
                type="text"
                placeholder="User@email.com"
                id="email"
                onChange={onChange}
                label="Email"
                error={errors?.email?.message || authError}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{
              required: {
                value: true,
                message: 'This field is required'
              },
              // pattern: {
              //   value: /^[a-zA-Z0-9]$/,
              //   message: 'Password should have uppercase and number characters'
              // },
              minLength: {
                value: 5,
                message: 'Password should be longer than 5 characters'
              },
              maxLength: {
                value: 20,
                message: 'Password cannot be longer than 20 characters'
              }
            }}
            render={({ field: { onChange } }) => (
              <TextField
                variant="rounded"
                type="password"
                placeholder="*******"
                id="password"
                onChange={onChange}
                label="Password"
                error={errors?.password?.message || generalError || authError}
              />
            )}
          />
          <div className="social-buttons-container">
            <h4>Or Sign In With</h4>
            <div className="social-buttons">
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_ID || ''}
                fields="name,email,picture"
                callback={(res: any) => socialAuth({ authType: 'facebook', token: res.accessToken })}
                render={(renderProps: any) => (
                  <SocialButton
                    {...renderProps}
                    disabled={isAuthenticating}
                  >
                    Facebook
                  </SocialButton>
                )}
              />
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_ID || ''}
                render={(renderProps) => (
                  <SocialButton
                    {...renderProps}
                    disabled={isAuthenticating}
                  >
                    Google
                  </SocialButton>
                )}
                buttonText="Login"
                onSuccess={(res: any) => {
                  socialAuth({ authType: 'google', token: res.accessToken });
                }}
                onFailure={(err) => toast.error(err.error)}
                cookiePolicy="single_host_origin"
              />
            </div>
          </div>
          <h4>
            Forgot Your Password?
            {' '}
            <span><Link to={FORGOT_PASSWORD}>Reset</Link></span>
          </h4>
          <div className="signup-button">
            <Button
              color="primary"
              type="submit"
              isLoading={isAuthenticating}
            >
              Sign in
            </Button>
          </div>
        </form>
      </div>
    </AuthenticationLayout>
  );
};
export default SignIn;
