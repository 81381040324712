import { LeadsSlice, setPayment } from 'pages/Dashboard/Admin/Leads/LeadsSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { ADMIN_ONBOARDING, ADMIN_ONBOARDING_COURSES } from 'routes/CONSTANTS';
import { useLeadPaymentInfo } from 'services/AdminDashboard/PersonalInfo/LeadPaymentInfo/useLeadPaymentInfo';
import { useAdminGetPeronalInfo } from 'services/AdminDashboard/PersonalInfo/PersonalInfo/useAdminGetPeronalInfo';

export const useLogic = () => {
  const { id, className, leadId } = useParams();
  const { data, isFetching: isLoading } = useAdminGetPeronalInfo(Number(id));
  const { data: leadPaymentInfo } = useLeadPaymentInfo(Number(leadId), !!leadId);
  const object = data?.adminGetUserInfo.info?.done_courses;
  const coursesArray = Object.entries(object || {});
  const coursesNames = coursesArray.filter((item) => item[1].taken === true).map((item) => item[0]);
  const courses = coursesArray.filter((item) => item[1].taken === true).map((item) => item);
  const [course, setCourse] = useState(courses[0]?.[1]);
  const [courseName, setCourseName] = useState(courses[0]?.[0]);
  const [scores, setScores] = useState<any>();
  const navigate = useNavigate();
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);
  const dispatch = useDispatch();
  const tabs = [
    'Notes'
  ];

  useEffect(() => {
    if (data) {
      setCourse(courses[0]?.[1]);
    }
  }, [data]);

  useEffect(() => {
    if (course) {
      setCourse(courses.find((item) => item[0] === courseName)?.[1] || courses[0]?.[1]);
      if (course) {
        setScores(Object.entries(course?.scores).filter((item) => item[1] !== null).map((item) => (
          { subject: item[0].charAt(0).toLocaleUpperCase() + item[0].slice(1), score: item[1] || '-' }
        )));
      }
    }
  }, [course, courseName]);

  const handleAdminOnboarding = () => {
    if (className !== 'null') {
      const url = `${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL === '/' ? '' : process.env.PUBLIC_URL}${ADMIN_ONBOARDING(String(id), String(className))}`;
      window.open(url, '_blank');
    } else {
      const url = `${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL === '/' ? '' : process.env.PUBLIC_URL}${ADMIN_ONBOARDING_COURSES(String(id))}`;
      window.open(url, '_blank');
    }
  };

  const handleLeadPayment = () => {
    if (leadPaymentInfo?.adminPayClassInfo.leadStatus === 'Need payment review') {
      dispatch(setPayment({
        cashPaymentModal: true,
        classId: leadPaymentInfo.adminPayClassInfo.classId,
        course: leadPaymentInfo.adminPayClassInfo.courseName,
        sessions: leadPaymentInfo.adminPayClassInfo.numberOfSessions,
        paymentConfirmation: {
          date: leadPaymentInfo.adminPayClassInfo.cashTransaction.createdAt,
          id: leadPaymentInfo.adminPayClassInfo.cashTransaction.id,
          attachments: leadPaymentInfo.adminPayClassInfo.cashTransaction.attachments,
        }
      }));
    } else if (leadPaymentInfo?.adminPayClassInfo.leadStatus === 'Scheduled') {
      dispatch(setPayment({
        modal: true,
        classId: leadPaymentInfo.adminPayClassInfo.classId,
        course: leadPaymentInfo.adminPayClassInfo.courseName,
        sessions: leadPaymentInfo.adminPayClassInfo.numberOfSessions,
      }));
    }
  };

  return {
    navigate,
    tabs,
    id,
    data,
    isLoading,
    coursesNames,
    courses,
    course,
    setCourse,
    scores,
    courseName,
    setCourseName,
    handleAdminOnboarding,
    leadPaymentInfo,
    dispatch,
    leadsState,
    setPayment,
    handleLeadPayment,
    leadId
  };
};
