import Button from 'components/General/Button/Button';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { DatePicker } from 'react-rainbow-components';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter, StudentsSlice, toggleFiltersModal } from '../../StudentsSlice';
import './CourseDateFilter.scss';

const CourseDateFilter = () => {
  const dispatch = useDispatch();
  const studentsState = useSelector((state: { students: StudentsSlice }) => state.students);
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();

  const handleApplyFilters = () => {
    if (!start || !end) {
      toast.error('Please select the start and end date');
      return;
    }
    dispatch(setFilter({ coursesTimeInterval: { start, end } }));
    dispatch(toggleFiltersModal(false));
  };

  useEffect(() => {
    setStart(studentsState.filter.coursesTimeInterval?.start);
    setEnd(studentsState.filter.coursesTimeInterval?.end);
  }, [studentsState.filter.coursesTimeInterval]);

  return (
    <div className="class-filter-container">
      <h2>Course date</h2>
      <div className="test-date-filter-container">
        <DatePicker
          placeholder="YYYY-MM-DD"
          labelAlignment="left"
          className="date-picker"
          label="Start date"
          icon={<></>}
          onChange={(e) => setStart(e)}
          value={start}
        />
        <DatePicker
          placeholder="YYYY-MM-DD"
          labelAlignment="left"
          className="date-picker"
          label="End date"
          icon={<></>}
          onChange={(e) => setEnd(e)}
          value={end}
        />
      </div>
      <div className="mt-3">
        <Button
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default CourseDateFilter;
