import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HomeworkSlice, setFilter } from '../HomeworkSlice';

export const useLogic = () => {
  const dispatch = useDispatch();
  const homeworkState = useSelector((state: { homework: HomeworkSlice }) => state.homework);
  const [search, setSearch] = useState<string>();
  const [searchName, setSearchName] = useState<string>();

  const filters = [
    { filter: 'Subjects', active: homeworkState.filter.courses },
    { filter: 'Status', active: homeworkState.filter.status },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filters?.[0]?.filter);

  useEffect(() => {
    setSearch(homeworkState.filter.search);
  }, [homeworkState.filter.search]);

  useEffect(() => {
    setSearchName(homeworkState.filter.searchName);
  }, [homeworkState.filter.searchName]);

  useEffect(() => {
    dispatch(setFilter({ search }));
  }, [search]);

  useEffect(() => {
    dispatch(setFilter({ searchName }));
  }, [searchName]);

  return {
    filters,
    selectedFilter,
    setSelectedFilter,
    dispatch,
    homeworkState,
    search,
    setSearch,
    searchName,
    setSearchName
  };
};
