import { gql } from 'graphql-request';
import { AssignmentSliceType } from 'pages/Dashboard/Course/Assignment/Assignments.types';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useCreateAssignment = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ details, fullMark, dueDate, name, classId, attachmentsIds, ungraded }: AssignmentSliceType) => client.request(
    gql`
    mutation CreateAssignment($classId: Float!, $fullMark: Float, $dueDate: String!, $details: String!, $name: String!, $attachmentsIds: [Float!], $ungraded: Boolean!) {
      createAssignment(classId: $classId, fullMark: $fullMark, dueDate: $dueDate, details: $details, name: $name, attachmentsIds: $attachmentsIds, ungraded: $ungraded) {
        id
        name
        submissionText
        fullMark
        classId
        createdById
        details
        receivedMark
        status
        dueDate
        createdAt
        ungraded
        attachments {
          id
          attachment
          teacherAttachment
        }
      }
    }   
    `,
    {
      classId,
      fullMark: fullMark ? Number(fullMark) : undefined,
      dueDate,
      details,
      name,
      attachmentsIds: Number(attachmentsIds?.length) > 0 ? attachmentsIds : undefined,
      ungraded
    }
  ).catch((error) => toast(error.response.errors[0].message)), {
    onSuccess: () => {}
  });
  return Mutation;
};
