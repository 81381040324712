// Types
import { PersonalInfoTabBar as IProps } from './PersonalInfoTabBar.types';

// Styling
import './PersonalInfoTabBar.scss';
import { v4 as uuid } from 'uuid';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { PROGRESS_DIAGRAM } from 'routes/CONSTANTS';

export const PersonalInfoTabBar = ({ tabs, parentRoutes }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { leadId } = useParams();
  const selectedTab = location.pathname.split('/')?.[leadId ? 6 : 4];

  const handleNavigate = (tab: string) => {
    if (tab === 'Course activity') {
      navigate(`/${parentRoutes?.[0]}/${parentRoutes?.[1]}/${tab.split(' ').join('_').toLocaleLowerCase()}/${PROGRESS_DIAGRAM}`);
    } else {
      navigate(`/${parentRoutes?.[0]}/${parentRoutes?.[1]}/${tab.split(' ').join('_').toLocaleLowerCase()}`);
    }
  };

  return (
    <div className="personal-info-tab-bar-container">
      { tabs.map((tab) => (
        <ButtonBase
          tagName="div"
          onClick={() => handleNavigate(tab)}
          key={uuid()}
        >
          <div
            className={`tab-container ${selectedTab === tab.split(' ').join('_').toLocaleLowerCase() && 'selected'}`}
          >
            <h3>
              {tab}
            </h3>
            <div className="border-bottom" />
          </div>
        </ButtonBase>
      ))}

    </div>
  );
};
