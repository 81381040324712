import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminChangeActivity = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ accessible, classId }: { accessible?: boolean, classId?: number }) => client.request(
    gql`
    mutation AdminEditClassAccessibility($accessible: Boolean!, $classId: Float!) {
      adminEditClassAccessibility(accessible: $accessible, classId: $classId) {
        message
      }
    }
    `,
    {
      accessible,
      classId
    }
  ));
  return Mutation;
};
