// Styling
import DotsLoader from 'components/General/Loader/Loader';
import './StatisticsCard.scss';

// Types
import { Todos as IProps } from './StatisticsCard.types';

export const StatisticsCard = ({ image, count, description, isLoading, percentage }: IProps) => (
  <div className="statistics-container">
    <div className="left-container">
      <img
        src={image}
        alt=""
      />
    </div>
    <div className="right-container">
      <div className="upper-container">
        { isLoading ? (
          <DotsLoader />
        ) : (
          <div className="numbers-container">
            <h2>{count}</h2>
            {
              count ? (
                <h4>{percentage ? `(${percentage}%)` : ''}</h4>
              ) : (
                <h4>{percentage ? `${percentage}%` : ''}</h4>
              )
            }
          </div>
        )}
      </div>
      <div className="bottom-container">
        <p>{description}</p>
      </div>
    </div>
  </div>
);
