// Style
import './Sessions.scss';
// Components
import { RescheduleSessionCard } from 'components/Dashboard/RescheduleSessionCard/RescheduleSessionCard';
import { useLogic } from './Sessions.logic';
import DotsLoader from 'components/General/Loader/Loader';
import moment from 'moment-timezone';
import Modal from 'components/General/Modal/Modal';
import RescheduleCalendar from './RescheduleCalendar/RescheduleCalendar';

export const Sessions = () => {
  const {
    data,
    isLoading,
    dispatch,
    sessionState,
    setRescheduleModal,
    isAllowed,
    isTeacher,
    isAdmin,
    handleOpenClick,
    handleRescheduleClick,
  } = useLogic();

  return (
    <>
      {
        isLoading ? (
          <DotsLoader />
        ) : (
          data?.classSessions.filteredSessions.map((record) => (
            <RescheduleSessionCard
              duration={record.duration}
              isValidLink={(!!record?.studentLink && record?.status === 'Not Started') || (!!record?.teacherLink && record.status === 'Not Started') || (!!record?.adminLink && record.status === 'Not Started')}
              canReschedule={isAdmin || isTeacher || isAllowed}
              key={record.id}
              sessionNumber={record.sessionNumber}
              date={moment(record.acuitySession?.datetime).format('MM/DD/YYYY')}
              time={moment(record.acuitySession?.datetime).format('hh:mm A')}
              onOpenClick={() => handleOpenClick(record)}
              onRescheduleClick={() => handleRescheduleClick(record)}
              disable={isTeacher}
            />
          ))
        )
      }
      <Modal
        isClosble
        isOpen={sessionState.rescheduleModal}
        onRequestClose={() => dispatch(setRescheduleModal(false))}
      >
        {sessionState.rescheduleModal && (
          <RescheduleCalendar />
        )}
      </Modal>
    </>
  );
};
