import { base64 } from 'assets/utils/getBase64';
import { Files } from 'components/Dashboard/AssignmentCard/AssignmentCard.types';
import { TestURLs } from 'pages/Dashboard/Course/PracticeTest/NewPracticeTest/NewPracticeTest.types';
import { DashboardSlice, togglePaymentModal, setPayment, setShowPaymentInstructions, setCashTransaction, toggleCashPaymentModal } from 'pages/Dashboard/DashboardSlice';
import onboarding from 'pages/Onboarding/OnboardingSlice';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateReceipt } from 'services/UploadFiles/useUpdateReceipt';
import { useFileUploadMutation } from 'services/UploadFiles/useUploadFile';
import { useUploadReceipt } from 'services/UploadFiles/useUploadReceipt';

export const useLogic = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { setChosePaymentMethod } = onboarding.actions;
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const { progress, setProgress, mutation: { mutateAsync: uploadFiles, isLoading: isUploading } } = useFileUploadMutation();
  const { mutateAsync: updateReceipt, isLoading: isLoadingUpdateReceipt } = useUpdateReceipt();
  const { mutateAsync: uploadReceipt, isLoading: isUploadingReceipt } = useUploadReceipt();
  const [attachmentsIds, setAttachmentsIds] = useState<number[]>([]);
  const { getBase64 } = base64();
  const input = useRef<HTMLInputElement | null>(null);
  const isImage = (url: string) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  const downlowadRef = useRef<HTMLAnchorElement | null>(null);
  const [files, setFiles] = useState<Files>([]);
  const [receiptURLs, setReceiptURLs] = useState<TestURLs>([]);
  const [deletedURLs, setDeletedURLs] = useState<string[]>([]);

  const addFiles = (file: Files) => {
    setFiles([...file, ...files]);
  };

  const handleUpload = async (e: any) => {
    if (!e.target.files) return;

    const formData = new FormData();
    const promises = [];
    const temp: Files = [];

    for (let i = 0; i < e.target.files.length || 0; i++) {
      if (e.target.files[i].size > 26214400) {
        toast.error('File cannot be bigger than 25 Megabytes');
        e.target.value = '';
        return;
      }
      formData.append('files[]', e.target.files[i]);
      promises.push(getBase64(e.target.files[i]));
    }

    const responses = await Promise.all(promises);
    responses.map((res: any) => {
      const startIndex = String(res.result).search('base64,') + 7;
      const base46 = String(res.result).slice(startIndex, String(res.result).length);
      temp.push({ base64: base46, type: res.type });
      return res;
    });
    addFiles(temp);

    uploadFiles({ file: formData })
      .then((res: any) => {
        setProgress(0);
        setAttachmentsIds([...attachmentsIds, ...res.data.attachmentsIds]);
      });

    e.target.value = '';
  };

  const handleRemoveNewFile = (index: number) => {
    setFiles(files.filter((file) => files.indexOf(file) !== index));
    setAttachmentsIds(attachmentsIds.filter((attachment) => attachmentsIds.indexOf(attachment) !== index));
  };

  const handleRemoveUploadedFile = (index: number) => {
    if (dashboardState.cashTransactions?.[0]?.attachments) {
      setReceiptURLs(receiptURLs?.filter((url) => {
        setDeletedURLs([...deletedURLs, String(receiptURLs?.[index]?.attachment)]);
        return receiptURLs.indexOf(url) !== index;
      }));
    }
  };

  const onSubmit = () => {
    if (!files.length) {
      toast('Please upload your receipt photo.');
      return;
    }

    if (dashboardState.cashTransactions.length) {
      const data = {
        transactionId: dashboardState.cashTransactions?.[0]?.id,
        addAttachmentsIds: attachmentsIds,
        deleteAttachmentsNames: deletedURLs,
      };
      updateReceipt(data).then(() => {
        queryClient.refetchQueries('unpaidClasses');
        dispatch(toggleCashPaymentModal(false));
        dispatch(togglePaymentModal(false));
        setDeletedURLs([]);
        setFiles([]);
        setAttachmentsIds([]);
        dispatch(setPayment({}));
        dispatch(setCashTransaction([]));
      });
    } else {
      const uploadData = {
        attachmentsIds,
        classId: dashboardState.payment.classId
      };
      uploadReceipt(uploadData).then(() => {
        dispatch(toggleCashPaymentModal(false));
        dispatch(togglePaymentModal(false));
        setDeletedURLs([]);
        setFiles([]);
        setAttachmentsIds([]);
        dispatch(setPayment({}));
        dispatch(setCashTransaction([]));
        queryClient.refetchQueries('unpaidClasses');
      });
    }
  };

  useEffect(() => {
    setReceiptURLs(dashboardState.cashTransactions?.[0]?.attachments);
  }, [dashboardState.cashTransactions]);

  useEffect(() => {
    if (!dashboardState.cashPaymentModal) {
      setDeletedURLs([]);
      setFiles([]);
      setAttachmentsIds([]);
      dispatch(setPayment({}));
      dispatch(setCashTransaction([]));
    }
  }, [dashboardState.paymentModal]);

  const handlePayByCard = () => {
    dispatch(toggleCashPaymentModal(false));
    dispatch(togglePaymentModal(true));
    dispatch(setPayment({ paymentMethod: 'Credit Card' }));
    dispatch(setChosePaymentMethod(true));
  };

  return {
    onSubmit,
    isUploading,
    progress,
    dashboardState,
    handleUpload,
    dispatch,
    togglePaymentModal,
    files,
    handleRemoveNewFile,
    input,
    isUploadingReceipt,
    setChosePaymentMethod,
    isImage,
    downlowadRef,
    handleRemoveUploadedFile,
    receiptURLs,
    isLoadingUpdateReceipt,
    setShowPaymentInstructions,
    toggleCashPaymentModal,
    handlePayByCard
  };
};
