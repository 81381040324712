// Types

// Services
import { gql } from 'graphql-request';
import { Filters } from 'pages/Dashboard/DashboardSlice';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { TeacherClassesQuery } from './types';

export const useTeacherClasses = ({ courses, country, classStatus, search, archive }: Filters) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeacherClassesQuery, Error> = useQuery<TeacherClassesQuery, Error>(['teacherCourses', { country, courses, classStatus, search }], async () => client.request(
    gql`
    query TeacherClassesView($limit: Float, $offset: Float, $courses: [String!], $country: [String!], $classStatus: [String!], $search: String, $archived: Boolean) {
      teacherClassesView(limit: $limit, offset: $offset, courses: $courses, country: $country, classStatus: $classStatus, search: $search, archived: $archived) {
        id
        status
        classId
        isAccessible
        plan{
          course{
            name
          }
        }
        user {
          first_name
          last_name
        }
      }
    }
    `,
    {
      courses: courses || undefined,
      country: country || undefined,
      classStatus: classStatus || undefined,
      search: search || undefined,
      archived: archive,
      limit: 0,
      offset: 0
    },
  ).catch((error) => console.error(error)));
  return data;
};
