import { StreamSlice } from 'pages/Dashboard/Course/Stream/StreamSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useCreateComment } from 'services/Dashboard/Comments/useCreateComment';

export const useLogic = () => {
  const { id } = useParams();
  const [commentText, setCommentText] = useState<string>('');
  const [showOptions, setShowOptions] = useState(false);
  const streamState = useSelector((state: { stream: StreamSlice }) => state.stream);

  const { mutateAsync: createComment } = useCreateComment();

  const handeSendComment = (postId?: number) => {
    if (commentText.length > 0) {
      createComment({
        text: commentText,
        classId: Number(id),
        postId
      });
      setCommentText('');
    }
  };

  useEffect(() => {
    setShowOptions(false);
  }, [streamState.editablePost]);

  return {
    commentText,
    setCommentText,
    handeSendComment,
    showOptions,
    setShowOptions,
    streamState
  };
};
