// Types
import { ReuseAssignments } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useReuseAssignment = (courseName?: string) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<ReuseAssignments, Error> = useQuery<ReuseAssignments, Error>(['reuseAssignments'], async () => client.request(
    gql`
    query AdminAllAssignments($courseName: String!, $limit: Float!, $offset: Float!) {
      adminAllAssignments(courseName: $courseName, limit: $limit, offset: $offset){
        id
        classId
        assignments{
          id
          name
          dueDate
          fullMark
          status
          details
          attachments {
            id
            attachment
            teacherAttachment
          }
        }
      }
    }
    `,
    {
      courseName,
      limit: 0,
      offset: 0
    }
  ).catch((error) => console.error(error)));
  return data;
};
