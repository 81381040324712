import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useTeacherTodaysSessions } from 'services/Dashboard/Sessions/Queries/TeacherTodaysSessions/useTeacherTodaysSessions';
import { useUpdateTaskStatus } from 'services/Dashboard/Todos/Mutations/useUpdateTaskStatus copy';
import { useGetFolderLists } from 'services/Dashboard/Todos/Queries/FolderLists/useGetFolderLists';
import { useGetListTasks } from 'services/Dashboard/Todos/Queries/ListTasks/useGetListTasks';

export const useLogic = () => {
  const navigate = useNavigate();
  const { data: folderLists, isLoading: isLoadingLists } = useGetFolderLists();
  const { data: listTasks, isLoading: isLoadingTasks } = useGetListTasks(String(folderLists?.adminGetFolderLists?.[-1]?.id));
  const { data: calendarData, isLoading: isLoadingCalendarData } = useTeacherTodaysSessions();
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useUpdateTaskStatus();
  const [updatedId, setUpdatedId] = useState<string>();
  const queryClient = useQueryClient();
  const [data, setData] = useState<any[]>([]);

  const handleChangeStatus = (taskId: string, finished: boolean) => {
    setUpdatedId(taskId);
    updateStatus({ taskId, finished }).then(() => {
      queryClient.refetchQueries('list-tasks');
      setUpdatedId(undefined);
    });
  };

  useEffect(() => {
    if (calendarData) {
      setData(() => calendarData.teacherSessionsForToday?.map((item) => ({
        title: (
          <div className="calender-appointment">
            <p>{`${item.class.plan.course.name} ${moment(item.dueAt).format('H:mm')} - ${moment(item.dueAt).add(item.duration, 'h').format('H:mm')}`}</p>
            <p>{`Student: ${item.user.first_name} ${item.user.last_name}`}</p>
          </div>
        ),
        id: item.id,
        startDate: moment(item.dueAt),
        endDate: moment(item.dueAt).add(item.duration, 'h'),
        link: item.teacherLink,
      })));
    }
  }, [calendarData]);

  return {
    folderLists,
    isLoadingLists,
    listTasks,
    isLoadingTasks,
    handleChangeStatus,
    isUpdatingStatus,
    updatedId,
    navigate,
    calendarData,
    isLoadingCalendarData,
    data
  };
};
