import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { NewTeacherProps } from './types';

export const useCreateNewTeacher = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ acuityCalendarId, attachmentId, city, clickupFolderId, clickupPk, country, courses, degree, details, email, firstName, lastName, phone, tutoringHours, university, yearsOfExperience, rate }: NewTeacherProps) => client.request(
    gql`
    mutation AdminCreateTeacher($acuityCalendarId: Float!, $city: String!, $clickupFolderId: Float!, $clickupPk: String!, $country: String!, $courses: [String!]!, $degree: String!, $details: String!, $email: String!, $firstName: String!, $lastName: String!, $phone: String!, $university: String!, $yearsOfExperience: Float!, $attachmentId: Float, $tutoringHours: Float, $rate: Float) {
      adminCreateTeacher(acuityCalendarId: $acuityCalendarId, city: $city, clickupFolderId: $clickupFolderId, clickupPK: $clickupPk, country: $country, courses: $courses, degree: $degree, details: $details, email: $email, firstName: $firstName, lastName: $lastName, phone: $phone, university: $university, yearsOfExperience: $yearsOfExperience, attachmentId: $attachmentId, tutoringHours: $tutoringHours, rate: $rate) {
        message
      }
    }
    `,
    {
      acuityCalendarId,
      city,
      clickupFolderId,
      clickupPk,
      country,
      courses,
      degree,
      details,
      email,
      firstName,
      lastName,
      phone,
      university,
      yearsOfExperience,
      attachmentId,
      tutoringHours,
      rate
    }
  ));
  return Mutation;
};
