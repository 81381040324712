import { GraphQLClient, } from 'graphql-request';
import { useCookies } from 'react-cookie';

export const graphqlConfig = () => {
  const [cookie] = useCookies(['userinfo']);
  const client = new GraphQLClient(`${process.env.REACT_APP_API_URL}graphql`, {
    headers: {
      authorization: `Bearer ${cookie.userinfo?.token}`,
    }
  });

  return {
    client
  };
};
