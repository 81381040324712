// Types
import { PostTextField as IProps } from './PostTextField.types';

// Styling
import 'assets/scss/spacing.scss';
import 'assets/scss/typography.scss';
import './PostTextField.scss';

export const PostTextField = ({ color, variant, placeholderColor, error, ...rest } : IProps) => (
  <div className={`post-text-field ${error && 'error'}`}>
    <textarea
      className={`post-input ${color} ${variant} ${placeholderColor}`}
      name="post-input"
      rows={1}
      {...rest}
    />
    {error && <p>{error}</p>}
  </div>
);
