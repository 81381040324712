import { useLogic } from './TargetScore.logic';
import './TargetScore.scss';
import Button from 'components/General/Button/Button';
import TextField from 'components/General/TextField/TextField';
import { Controller } from 'react-hook-form';

export const TargetScore = () => {
  const {
    onSubmit,
    errors,
    isLoading,
    control,
    rules
  } = useLogic();

  return (
    <div className="final-test-modal-container">
      <Controller
        control={control}
        name="score"
        rules={rules().rules}
        render={({ field: { onChange, value } }) => (
          <div className="rainbow-m-around_small">
            <TextField
              color="light-gray"
              variant="rounded"
              placeholder={rules().placeholder}
              id="mark"
              label="Target score"
              type="number"
              error={errors.score?.message}
              onChange={onChange}
              value={value}
            />
          </div>
        )}
      />
      <div className="button-container mt-2">
        <Button
          color="primary"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
