// Types
import { TeachersQuery } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useTeachers = (course: string, sortBy?: string) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeachersQuery, Error> = useQuery<TeachersQuery, Error>(['teachers', sortBy], async () => client.request(
    gql`
    query CourseTeachers($course: String!) {
      courseTeachers(course: $course) {
        teachers {
          id
          acuityDaysAvailable
          description
          university
          education
          country
          city
          tutoringHours
          yearsOfExperience
          rate
          acuityCalendarId
          acuityAppTypeIds {
            GMAT {
              halfHour
              hour
              hourAndAHalf
              threeHours
              twoHours
              twoHoursAndAHalf
            }
            IELTS {
              halfHour
              hour
              hourAndAHalf
              threeHours
              twoHours
              twoHoursAndAHalf
            }
            SAT {
              halfHour
              hour
              hourAndAHalf
              threeHours
              twoHours
              twoHoursAndAHalf
            }
          }
          courses {
            id
            name
          }
          user {
            first_name
            last_name
            picture
          }
        }
      }
    }
    `,
    {
      course,
      sortBy
    }
  ).catch((error) => console.error(error)));
  return data;
};
