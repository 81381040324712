import Button from 'components/General/Button/Button';
import { Checkbox } from 'components/General/Checkbox/Checkbox';
import Label from 'components/General/Label/Label';
import { useLogic } from './LeadStatus.logic';
import './LeadStatus.scss';

export const LeadStatus = () => {
  const {
    isUpdatingStatus,
    status,
    setStatus,
    handleSaveStatus,
  } = useLogic();

  return (
    <div className="lead-status-container">
      <h2>Change Status</h2>
      <div className="lead-status">
        <Label color="mustard">Future</Label>
        <Checkbox
          checked={status === 'Future'}
          onChange={() => setStatus('Future')}
        />
      </div>
      <div className="lead-status">
        <Label color="fuchsia">Decline</Label>
        <Checkbox
          checked={status === 'Declined'}
          onChange={() => setStatus('Declined')}
        />
      </div>

      <div className="mt-1">
        <Button
          color="primary"
          onClick={handleSaveStatus}
          isLoading={isUpdatingStatus}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
