import ListingTable from 'components/AdminDashboard/ListingTable/ListingTable';
import { CourseStatisticsCard } from 'components/Dashboard/CourseStatisticsCard/CourseStatisticsCard';
import { useLogic } from './HomeworkRecords.logic';
import { SimpleStatisticsCard } from 'components/Dashboard/SimpleStatisticsCard/SimpleStatisticsCard';
import YellowAvatarICon from 'assets/images/yellow-avatar.svg';
import MissidCallIcon from 'assets/images/camera-avatar.svg';
import CalendarIcon from 'assets/images/calendar.svg';
import './HomeworkRecords.scss';
import moment from 'moment-timezone';
import DotsLoader from 'components/General/Loader/Loader';

export const HomeworkRecords = () => {
  const {
    Columns,
    data,
    selected,
    handleSelect,
    course,
    isLoading,
    navigate
  } = useLogic();
  return (
    <div className="homework-records-container">
      <div className="course-cards-container">
        <h3>Your course</h3>
        <div className="course-cards">
          {
          data?.adminGetUserClassAss?.map((card) => (
            <CourseStatisticsCard
              status={card.status}
              title={card.plan?.course?.name}
              selectedCourse={card.id === selected.id}
              onClick={() => handleSelect(card.id, card.plan?.course?.name)}
              goToCourse={() => navigate(`/class/${card.id}/${card.classId}/${card.plan?.course?.name}/stream`)}
            />
          ))
        }

        </div>
      </div>
      <div className="info-cards-container mt-4 ml-2 mr-2">
        {isLoading ? (
          <DotsLoader />
        ) : (
          <>
            <SimpleStatisticsCard
              image={YellowAvatarICon}
              number={course?.totalHomework || '0'}
              description="Total homework"
            />
            <SimpleStatisticsCard
              image={MissidCallIcon}
              number={course?.totalMissedHomework || '0'}
              description="Total missed homework"
            />
            <SimpleStatisticsCard
              image={CalendarIcon}
              number={course?.lowestMark || '0'}
              description="Lowest mark"
            />
          </>
        )}
      </div>
      <div className="homework-records-table-container">
        <h3>Homework history</h3>
        <ListingTable
          columns={Columns}
          data={course?.assignments?.map((record) => (
            [
              /* ID */{ value: <p className="sub-text">{record.id}</p> },
              /* Name */{ value: <p className="sub-text">{course.plan?.course?.name}</p> },
              /* Done */{ value: <p className="sub-text">Yes/No</p> },
              /* Date */{ value: <p className="sub-text">{moment(record.createdAt).format('MM/DD/YYYY')}</p> },
              /* Status */{ value: <p className="sub-text">{record.status}</p> },
              /* Mark */{ value: <p className="sub-text">{record.receivedMark ? `${(record.receivedMark / record.fullMark) * 100}%` : '-'}</p> },
              /* Details */{ value: <p className="sub-text">Details</p> },
            ]
          ))}
        />
      </div>
    </div>
  );
};
