import { useDispatch, useSelector } from 'react-redux';
import { Recordings } from './Recordings/Recordings';
import { Sessions } from './Sessions/Sessions';
import { AllSessions } from './AllSessions/AllSessions';
import { useState } from 'react';
import { SessionSlice, setSessionRate, setSessionDuration, setSessionProblem, setRescheduleModal, setAddExtraSession } from './SessionsSlice';
import { useRoles } from 'assets/utils/useRoles';
import { CreateSession } from './CreateSession/CreateSession.slice';
import { setStreamRateData, StreamSlice } from '../Stream/StreamSlice';

export const useLogic = () => {
  const { isAdmin } = useRoles();
  const sessionState = useSelector((state: { sessions: SessionSlice }) => state.sessions);
  const streamState = useSelector((state: { stream: StreamSlice }) => state.stream);
  const createSessionState = useSelector((state: { createSession: CreateSession }) => state.createSession);
  const [selectedTab, setSelectedTab] = useState('All');

  const dispatch = useDispatch();

  const handleTabChange = (tab:string) => {
    setSelectedTab(tab);
  };

  const getSelectedTab = () => {
    if (selectedTab) {
      switch (selectedTab) {
        case 'All':
          return <AllSessions />;
        case 'Recording':
          return <Recordings />;
        case 'Upcoming':
          return <Sessions />;
        default:
          return <AllSessions />;
      }
    } else {
      return (
        <h2>Something is wrong</h2>
      );
    }
  };

  return {
    dispatch,
    getSelectedTab,
    handleTabChange,
    selectedTab,
    setSelectedTab,
    sessionState,
    isAdmin,
    setRescheduleModal,
    setSessionRate,
    setSessionDuration,
    setSessionProblem,
    createSessionState,
    setAddExtraSession,
    setStreamRateData,
    streamState
  };
};
