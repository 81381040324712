import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useUpdateTaskStatus } from 'services/Dashboard/Todos/Mutations/useUpdateTaskStatus copy';
import { useGetFolderLists } from 'services/Dashboard/Todos/Queries/FolderLists/useGetFolderLists';
import { useGetListTasks } from 'services/Dashboard/Todos/Queries/ListTasks/useGetListTasks';

export const useLogic = () => {
  const navigate = useNavigate();
  const { data: folderLists, isLoading: isLoadingLists } = useGetFolderLists();
  const { data: listTasks, isLoading: isLoadingTasks } = useGetListTasks(String(folderLists?.adminGetFolderLists?.[folderLists?.adminGetFolderLists.length - 1]?.id));
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useUpdateTaskStatus();
  const [updatedId, setUpdatedId] = useState<string>();
  const queryClient = useQueryClient();
  const times = [
    '8:00',
    '9:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '24:00',
  ];
  const handleChangeStatus = (taskId: string, finished: boolean) => {
    setUpdatedId(taskId);
    updateStatus({ taskId, finished }).then(() => {
      queryClient.refetchQueries('list-tasks');
      setUpdatedId(undefined);
    });
  };

  return {
    folderLists,
    isLoadingLists,
    listTasks,
    isLoadingTasks,
    handleChangeStatus,
    isUpdatingStatus,
    updatedId,
    times,
    navigate
  };
};
