import { DashboardSlice, setFilter } from 'pages/Dashboard/DashboardSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useLogic = () => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const [value, setValue] = useState('');
  const [status, setStatus] = useState('');

  const filters = [
    { filter: 'Class', active: dashboardState.filter.courses },
    { filter: 'Location', active: dashboardState.filter.country },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filters?.[0]?.filter);

  const handleStatusFilter = (selected: string) => {
    setStatus(selected);
    switch (selected) {
      case 'All':
        dispatch(setFilter({ classStatus: undefined, archive: false }));
        break;
      case 'Active':
        dispatch(setFilter({ classStatus: ['Active', 'Not Started', 'Finished'], archive: false }));
        break;
      case 'Cancelled':
        dispatch(setFilter({ classStatus: ['Cancelled'], archive: false }));
        break;
      case 'Archive':
        dispatch(setFilter({ classStatus: undefined, archive: true }));
        break;
      default:
        dispatch(setFilter({ classStatus: ['Active', 'Not Started', 'Finished'], archive: false }));
    }
  };

  useEffect(() => {
    dispatch(setFilter({ search: value }));
  }, [value]);

  return {
    filters,
    selectedFilter,
    setSelectedFilter,
    dispatch,
    dashboardState,
    value,
    setValue,
    status,
    setStatus,
    handleStatusFilter
  };
};
