import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useRemoveDraft = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async (draftId: number) => client.request(
    gql`
    mutation RemoveUserDraft($draftId: Float!) {
      removeUserDraft(draftId: $draftId) {
        message
      }
    }
    `,
    {
      draftId
    }
  ));
  return Mutation;
};
