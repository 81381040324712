import Button from 'components/General/Button/Button';
import { Checkbox } from 'components/General/Checkbox/Checkbox';
import Label from 'components/General/Label/Label';
import { useLogic } from './ActivityModal.logic';
import './ActivityModal.scss';

export const ActivityModal = () => {
  const {
    active,
    setActive,
    handleSave,
    isLoading
  } = useLogic();

  return (
    <div className="attendance-container">
      <h2>Change activity</h2>
      <div className="attendance-option">
        <Label color="Active">Active</Label>
        <Checkbox
          checked={active}
          onChange={() => setActive(true)}
        />
      </div>
      <div className="attendance-option">
        <Label color="fuchsia">Inactive</Label>
        <Checkbox
          checked={active === false}
          onChange={() => setActive(false)}
        />
      </div>

      <div className="mt-1">
        <Button
          color="primary"
          onClick={handleSave}
          isLoading={isLoading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
