import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

export interface CalendarState {
  acuityCalendarID?: number;
  acuityTypeID?: number;
  calendarDate?: Date;
  calendarMonth?: string;
}

const initialState: CalendarState = {
  calendarMonth: `${moment(new Date()).format('YYYY-MM')}`
};

const calendar = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setAcuityCalendarID: (state, { payload }: PayloadAction<number | undefined>) => {
      state.acuityCalendarID = payload;
    },
    setAcuityTypeID: (state, { payload }: PayloadAction<number | undefined>) => {
      state.acuityTypeID = payload;
    },
    setCalendarDate: (state, { payload }: PayloadAction<Date>) => {
      state.calendarDate = payload;
    },
    setCalendarMonth: (state, { payload }: PayloadAction<string>) => {
      state.calendarMonth = payload;
    },
  },
});

export const { setAcuityCalendarID, setAcuityTypeID, setCalendarDate, setCalendarMonth } = calendar.actions;

export default calendar;
