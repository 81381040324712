// Types
import { ReusePracticeTests } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useReusePracticeTests = (courseName?: string) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<ReusePracticeTests, Error> = useQuery<ReusePracticeTests, Error>(['reusePracticeTests'], async () => client.request(
    gql`
    query AdminAllTests($courseName: String!, $limit: Float!, $offset: Float!) {
      adminAllTests(courseName: $courseName, limit: $limit, offset: $offset) {
        id
        classId
        status
        tests{
          id
          name
          classId
          details
          link
          status
          dueDate
          attachments {
            id
            attachment
            teacherAttachment
          }
          receivedMark {
            QuantitativeScore
            englishScore
            listening
            mathScore
            reading
            speaking
            totalScore
            verbalScore
            writing
          }
        }
      }
    }
    `,
    {
      courseName: courseName || undefined,
      limit: 0,
      offset: 0
    }

  ).catch((error) => console.error(error)));
  return data;
};
