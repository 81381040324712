// Components
import { TeacherCourseCard } from 'components/Dashboard/TeacherCourseCard/TeacherCourseCard';

// Grid system
import { Row, Col } from 'react-grid-system';

// Styling
import './Classes.scss';

// Logic
import { useLogic } from './Classes.logic';
import { OverlayLoader } from 'components/General/Loader/Loader';
import Modal from 'components/General/Modal/Modal';
import { toggleFiltersModal } from 'pages/Dashboard/DashboardSlice';
import { AdminDashboardLayout } from 'layouts/AdminDashboardLayout/AdminDashboardLayout';
import { Filters } from './Filters/Filters';

export const Classes = () => {
  const {
    adminClassesQuery,
    isLoading,
    isFetching,
    handleCourseChange,
    generateFilter,
    dashboardState,
    dispatch,
    isAdmin,
    handleArchive,
    handleCancel,
    isLoadingArchiveClass,
    isLoadingCancelClass
  } = useLogic();

  return (
    <AdminDashboardLayout>
      <div className="admin-classes-container">
        {isLoading ? (
          <div className="loader-container">
            <OverlayLoader />
          </div>
        ) : (
          <div className="cards-container">
            <Filters isLoading={isFetching} />
            <Row
              className="cards"
            >
              {adminClassesQuery?.adminClassesView ? (
                adminClassesQuery.adminClassesView.map((card) => (
                  <Col
                    xs={12}
                    md={4}
                    lg={2.4}
                    style={{ paddingRight: 30 }}
                    key={card.id}
                  >
                    <TeacherCourseCard
                      id={card.id}
                      onClick={() => handleCourseChange(card.plan.course.name, card.classId, card.id)}
                      options={() => {}}
                      onCancel={() => handleCancel(card.id)}
                      onArchive={() => handleArchive(card.id)}
                      courseID={card.classId}
                      courseName={card.plan.course.name}
                      status={card.status}
                      teacherName={`${card?.sessions[0]?.teacher.user.first_name} ${card?.sessions[0]?.teacher.user.last_name}`}
                      studentName={isAdmin ? `${card?.user.first_name} ${card?.user.last_name}` : ''}
                      isLoading={isLoadingArchiveClass || isLoadingCancelClass}
                      isAccessible={!card.isAccessible}
                    />
                  </Col>
                ))
              ) : (
                <>
                  <h2>No Classes yet</h2>
                </>
              )}
            </Row>
          </div>
        )}
        <Modal
          isClosble
          isOpen={dashboardState.filtersModal}
          onRequestClose={() => dispatch(toggleFiltersModal(false))}
        >
          {generateFilter(dashboardState.filter.selectedFilter)}
        </Modal>
      </div>
    </AdminDashboardLayout>
  );
};
