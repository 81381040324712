// Types
import { StudentSearchCardProps } from './StudentSearchCard.types';

// Style
import './StudentSearchCard.scss';
import Avatar from 'components/General/Avatar/Avatar';
import Typography from 'components/General/Typography/Typography';

// Components

export const StudentSearchCard = ({ avatar, name, className, ...rest }: StudentSearchCardProps) => (
  <div
    className={`student-search-card ${className || ''}`}
    {...rest}
  >
    <Avatar
      src={avatar}
      size={40}
    />
    <Typography>{name}</Typography>
  </div>
);
