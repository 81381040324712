import { gql } from 'graphql-request';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { Post } from 'pages/Dashboard/Course/Stream/Strem.types';

export const useCreatePost = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ text, classId, attachmentsIds }: Post) => client.request(
    gql`
    mutation CreatePost($classId: Float!, $attachmentsIds: [Float!], $text: String) {
      createPost(classId: $classId, attachmentsIds: $attachmentsIds, text: $text) {
        id
        text
        classId
        createdAt
        updatedAt
        attachments {
          id
          attachment
        }
      }
    }
    `,
    {
      classId,
      attachmentsIds: attachmentsIds?.length ? attachmentsIds : undefined,
      text
    }
  )
    .catch((error) => toast(error.response.errors[0].message)));
  return Mutation;
};
