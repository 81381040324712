// Components
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { CircleLoader } from 'components/General/Loader/Loader';

// uuid
import { v4 as uuid } from 'uuid';
import { setFilter, toggleFiltersModal } from '../StudentsSlice';
import { useLogic } from './Filters.logic';

// Style
import './Filters.scss';

export const Filters = ({ isLoading }: { isLoading: boolean }) => {
  const {
    studentsState,
    setSelectedFilter,
    dispatch,
    filters
  } = useLogic();

  return (
    <div className="students-filters-container">
      {(
        studentsState.filter.userStatus
        || studentsState.filter.classStatus
        || studentsState.filter.country
        || studentsState.filter.courses
        || studentsState.filter.taughtBy
        || studentsState.filter.schools
        || studentsState.filter.classOf
        || studentsState.filter.enrollment
        || studentsState.filter.paymentMethod
        || studentsState.filter.coursesTimeInterval
        || studentsState.filter.testsTimeInterval
      ) && (
        <div className="filter-container selected">
          <ButtonBase
            tagName="div"
            onClick={() => dispatch(setFilter({
              userStatus: undefined,
              classStatus: undefined,
              country: undefined,
              courses: undefined,
              taughtBy: undefined,
              schools: undefined,
              classOf: undefined,
              enrollment: undefined,
              paymentMethod: undefined,
              coursesTimeInterval: undefined,
              testsTimeInterval: undefined,
            }))}
          >
            <p>Clear all</p>
          </ButtonBase>
        </div>
      )}
      {filters.map((filter) => (
        <ButtonBase
          tagName="div"
          onClick={() => {
            setSelectedFilter(filter.filter);
            dispatch(setFilter({ selectedFilter: filter.filter }));
            dispatch(toggleFiltersModal(true));
          }}
          key={uuid()}
        >
          <div className={`filter-container ${filter.active && 'selected'}`}>
            <p>{filter.filter}</p>
          </div>
        </ButtonBase>
      ))}
      { isLoading && (
        <CircleLoader />
      )}
    </div>
  );
};
