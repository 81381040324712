import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useUpdateReceipt = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ transactionId, deleteAttachmentsNames, addAttachmentsIds }: { transactionId?: number, addAttachmentsIds: number[], deleteAttachmentsNames: string[] }) => client.request(
    gql`
    mutation UpdateUserCashTrans($transactionId: Float!, $deleteAttachmentsNames: [String!], $addAttachmentsIds: [Float!]) {
      updateUserCashTrans(transactionId: $transactionId, deleteAttachmentsNames: $deleteAttachmentsNames, addAttachmentsIds: $addAttachmentsIds) {
        id
        status
        attachments {
          id
          attachment
        }
      }
    }
    `,
    {
      transactionId,
      deleteAttachmentsNames: deleteAttachmentsNames.length > 0 ? deleteAttachmentsNames : undefined,
      addAttachmentsIds
    }
  ));
  return Mutation;
};
