import { useEffect } from 'react';

// Components
import { DashboardLayout } from 'layouts/DashboardLayout/DashboardLayout';
import { TabBar } from 'components/Dashboard/TabBar/TabBar';
import { DashboardSlice, setFeedbackData } from 'pages/Dashboard/DashboardSlice';

// Styling
import './Dashboard.scss';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setAssignment } from '../DashboardSlice';
import { TEACHER_DASHBOARD } from 'routes/CONSTANTS';
import { TeacherDashboard as IProps } from './Dashboard.types';
import { useRoles } from 'assets/utils/useRoles';
import { useGetSessionFeedbacks } from 'services/Dashboard/Sessions/Queries/Feedbacks/useGetSessionFeedbacks';
import Modal from 'components/General/Modal/Modal';
import { RateSession } from 'pages/Dashboard/Student/Classes/RateSession/RateSession';

const TeacherDashboard = ({ children }: IProps) => {
  const { data: feedbacks } = useGetSessionFeedbacks();
  const { isTeacher } = useRoles();
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const dispatch = useDispatch();
  const location = useLocation();
  const tabs = [
    'Todos',
    'Classes',
    'Calendar',
    'Homework',
    'Students'
  ];

  useEffect(() => {
    dispatch(setAssignment({}));
  }, [location]);

  useEffect(() => {
    try {
      if (feedbacks?.feedbackSessions[0]) {
        dispatch(setFeedbackData({
          modal: true,
          classId: feedbacks?.feedbackSessions[0].class?.classId,
          sessionId: feedbacks?.feedbackSessions[0].id,
          ratedUserId: isTeacher ? feedbacks?.feedbackSessions[0].teacher.id : feedbacks?.feedbackSessions[0].userId,
          sessionNumber: feedbacks?.feedbackSessions[0].sessionNumber,
          courseName: String(feedbacks?.feedbackSessions[0].class.plan.course.name),
          date: feedbacks?.feedbackSessions[0].dueAt
        }));
      }
    } catch {
      console.error('error');
    }
  }, [feedbacks]);

  return (
    <>
      <DashboardLayout>
        <div>
          <TabBar
            selectedTab="Classes"
            tabs={tabs}
            parentRoute={TEACHER_DASHBOARD}
          />
          {children}
        </div>
      </DashboardLayout>
      <Modal
        isOpen={dashboardState.feedbacks.modal}
        onRequestClose={() => dispatch(setFeedbackData({ modal: false }))}
      >
        <RateSession />
      </Modal>
    </>
  );
};
export default TeacherDashboard;
