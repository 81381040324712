import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';

// Types
import { LineChartProps } from './LineChart.types';

// Style
import './LineChart.scss';

ChartJS.register(...registerables);

const LineChart: FC<LineChartProps> = ({ data, title, subTitle, yLabels, id, maxStep }) => (
  <div className="line-chart">
    <div className="chart-title-container">
      <p className="chart-title sub-text">{title}</p>
      <p className="chart-sub-title sub-text">{subTitle}</p>
    </div>
    <Line
      id={id}
      height={185}
      width={200}
      data={data}
      options={{
        plugins: {
          legend: {
            display: false,
            align: 'end',
            labels: {
              usePointStyle: true,
              pointStyle: 'rectRounded'
            }
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: (item) => `${item.dataset.label}: ${yLabels?.[item.formattedValue] || item.formattedValue}`
            }
          }
        },
        responsive: true,
        scales: {
          y: {
            display: true,
            suggestedMax: maxStep,
            ticks: {
              stepSize: 10,
            },
            grid: {
              drawBorder: false,
              color: '#F7F7F7'
            },
          },
          xAxes: {
            display: true,
            ticks: {
              stepSize: 20,
              color: '#6A6A6A',
              font: {
                size: 10,
                family: 'Poppins',
                weight: '500',
              },
              padding: 15
            },
            grid: {
              display: true,
              drawBorder: false
            }
          }
        }
      }}
      plugins={[{
        id: '1',
        afterDraw: (chart) => {
          if (chart.data.datasets[0].data.every((item) => item === 0)) {
            const { ctx } = chart;
            const { width } = chart;
            const { height } = chart;

            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText('No data', width / 2, height / 2);
            ctx.restore();
          }
        }
      }]}
    />
  </div>
);

export default LineChart;
