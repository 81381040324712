// Components
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';

// Styling
import './StudentCourseCard.scss';

// Types
import { StudentCourseCard as IProps } from './StudentCourseCard.types';

export const StudentCourseCard = ({ courseName, body, courseID, teacherName, disabled, message, onClick }: IProps) => (
  <ButtonBase
    tagName="div"
    onClick={onClick}
  >
    <div className="student-course-card-container">
      <div className={`student-course-card ${(courseName === 'GMAT' && 'mustard') || (courseName === 'IELTS' && 'fuchsia') || (courseName === 'SAT' && 'primary')} ${disabled && 'disabled'}`}>
        <div className={`top-container ${disabled && 'disabled-top-container'}`}>
          <h3>{courseName}</h3>
          <div>
            <h4>{body}</h4>
            <p>{message && message}</p>
          </div>
        </div>
        <div className={`bottom-container ${disabled && 'pb-5'}`}>
          <h4>{teacherName}</h4>
          <h4>{courseID && `#${courseID}`}</h4>
        </div>
      </div>
    </div>
  </ButtonBase>
);
