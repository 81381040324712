import React from 'react';

// Types
import { TextField as IProps } from './TextField.types';

// Styling
import 'assets/scss/spacing.scss';
import 'assets/scss/typography.scss';
import './TextField.scss';

const TextField = ({ color, variant, label, error, ...rest } : IProps, ref: any) => (
  <div className="text-field">
    <div className={`mt-2 ${error ? 'fuchsia' : color}`}>
      <label
        htmlFor={rest?.id}
        className="ml-05"
      >
        {label}
      </label>
    </div>
    <input
      className={`input ${error ? 'fuchsia' : color} ${variant}`}
      {...rest}
      ref={ref}
    />
    {error && <p className="error">{error}</p>}
  </div>
);

export default React.forwardRef(TextField);
