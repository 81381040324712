import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminUpdateLeadStatus = () => {
  const { client } = graphqlConfig();

  const Mutation = useMutation(async ({ leadId, status }: { leadId?: number, status?: string }) => client.request(
    gql`
    mutation AdminUpdateLead($leadId: Float!, $status: status!) {
      adminUpdateLead(leadId: $leadId, status: $status) {
        id
        userId
        courseId
      }
    }
    `,
    {
      leadId,
      status,
    }
  ));
  return Mutation;
};
