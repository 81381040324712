import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirmTransaction } from 'services/AdminDashboard/PersonalInfo/PaymentStatus/ConfirmTransaction/useConfirmTransaction';
import { setPaymentConfirmation, setPaymentConfirmationsModal, StudentsSlice } from '../../../StudentsSlice';

export const useLogic = () => {
  const studentsState = useSelector((state: { students: StudentsSlice }) => state.students);
  const isImage = (url: string) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  const dispatch = useDispatch();
  const [confirmed, setConfirmed] = useState<boolean | undefined>(undefined);
  const [rejectionReason, setRejectionReason] = useState('');
  const { mutateAsync: confirmTransaction, isLoading } = useConfirmTransaction();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (!studentsState.paymentConfirmationModal) {
      dispatch(setPaymentConfirmation({}));
      setConfirmed(undefined);
      setRejectionReason('');
    }
  }, [studentsState.paymentConfirmationModal]);

  const handleConfirmTransaction = () => {
    if (!confirmed && rejectionReason.length === 0) {
      toast('Please provide the rejection reason.');
      return;
    }
    confirmTransaction({
      transId: studentsState.paymentConfirmation.id,
      reason: rejectionReason.length > 0 ? rejectionReason : undefined,
      isVerified: confirmed
    }).then(() => {
      queryClient.refetchQueries('paymnet-status');
      dispatch(setPaymentConfirmationsModal(false));
      queryClient.refetchQueries('payment-status');
    }).catch((err: any) => {
      toast.error(err?.response?.errors?.[0].message);
    });
  };

  return {
    studentsState,
    isImage,
    isLoading,
    confirmed,
    setConfirmed,
    handleConfirmTransaction,
    setRejectionReason
  };
};
