// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setShowTabBar, DashboardSlice, setPayment, toggleCashPaymentModal, setFinalTest, setTargetScore, setFeedbackData } from '../../DashboardSlice';
import onboarding, { Plan } from '../../../Onboarding/OnboardingSlice';

// Utils
import { useEffect } from 'react';
import { useStudentClasses } from 'services/Dashboard/Classes/StudentClasses/useStudentClasses';
import { useUserNearestSession } from 'services/Dashboard/Sessions/Queries/UserNearestSession/useUserNearestSession';
import { useNavigate } from 'react-router';
import { useUserDraft } from 'services/Onboarding/Draft/Queries/userDrafts/useUserDraft';
import { ONBOARDING_COURSES } from 'routes/CONSTANTS';
import { useRemoveDraft } from 'services/Onboarding/Draft/Mutations/useRemoveDraft';
import { useQueryClient } from 'react-query';
import { useGetUnpaidClasses } from 'services/Dashboard/Todos/Queries/UnpaidClasses/useGetUnpaidClasses';
import { useGetFinalTests } from 'services/Dashboard/Todos/Queries/FinalTest/useGetFinalTests';

export const useLogic = () => {
  const dashboardState = useSelector((state: { dashboard: DashboardSlice }) => state.dashboard);
  const { setPlanData } = onboarding.actions;
  const { data: studentClasses, isFetching: isLoading } = useStudentClasses();
  const { data: userNearestSession, isFetching: isLoadingNearestSession } = useUserNearestSession();
  const { data: userDrafts, isFetching: isLoadingDrafts } = useUserDraft();
  const { data: unpaidClasses, isFetching: isLoadingUnpaidClasses } = useGetUnpaidClasses();
  const { data: finalTestsData, isLoading: isLoadingFinalTests } = useGetFinalTests();
  const { mutateAsync: removeDraft, isLoading: isLoadingRemoveDraft } = useRemoveDraft();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleCourseChange = (className: string, classID: number, id: number) => {
    dispatch(setShowTabBar(false));
    navigate(`/class/${id}/${classID}/${className}/stream`);
  };

  const handleClick = (link: string) => {
    let meetingWindow = window.open(link, '_blank');
    let timer: any = null;

    const windowListner = () => {
      if (meetingWindow?.closed) {
        clearInterval(timer);
        meetingWindow = null;
        dispatch(setFeedbackData({
          modal: true,
          sessionId: userNearestSession?.userNearestSession?.[0]?.id,
          ratedUserId: userNearestSession?.userNearestSession?.[0]?.teacher.user.id,
          classId: userNearestSession?.userNearestSession[0].class.classId,
          sessionNumber: userNearestSession?.userNearestSession[0].sessionNumber,
          courseName: userNearestSession?.userNearestSession[0].class.plan.course.name,
          date: userNearestSession?.userNearestSession[0].dueAt
        }));
      }
    };

    timer = setInterval(windowListner, 500);
  };

  const handleDraft = ({ id, course, courseID, sessionsCount, price }: Plan) => {
    dispatch(setPlanData({ id, course, courseID, sessionsCount, price, isDraft: true }));
    queryClient.refetchQueries('onboarding-course').then(() => navigate(ONBOARDING_COURSES));
  };

  const handleRemoveDraft = (draftId: number) => {
    removeDraft(draftId).then(() => {
      queryClient.setQueryData(['userDraft'], (oldData: any) => {
        const index = oldData.userViewDrafts?.findIndex((item: any) => item.id === draftId);
        if (index !== -1) {
          oldData.userViewDrafts.splice(index, 1);
        }
        return oldData;
      });
    });
  };

  useEffect(() => {
    queryClient.refetchQueries('studentCourses');
    queryClient.refetchQueries('unpaidClasses');
  }, [studentClasses]);

  useEffect(() => {
    if (!dashboardState.paymentModal) {
      dispatch(setPayment({ className: undefined }));
    }
  }, [dashboardState.paymentModal]);

  useEffect(() => {
    if (!dashboardState.finalTest.modal) {
      dispatch(setFinalTest({ finalTest: undefined }));
    }
  }, [dashboardState.finalTest.modal]);

  useEffect(() => {
    if (!dashboardState.targetScore.modal) {
      dispatch(setTargetScore({ targetScore: undefined }));
    }
  }, [dashboardState.targetScore.modal]);

  return {
    studentClasses,
    handleCourseChange,
    userNearestSession,
    isLoadingNearestSession,
    dashboardState,
    dispatch,
    isLoading,
    handleClick,
    userDrafts,
    isLoadingDrafts,
    handleDraft,
    handleRemoveDraft,
    setPlanData,
    isLoadingUnpaidClasses,
    unpaidClasses,
    setPayment,
    isLoadingRemoveDraft,
    toggleCashPaymentModal,
    finalTestsData,
    isLoadingFinalTests,
    setFinalTest,
    setTargetScore,
  };
};
