import { base64 } from 'assets/utils/getBase64';
import { Files } from 'components/Dashboard/AssignmentCard/AssignmentCard.types';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';

// React form
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useCreateAssignment } from 'services/Dashboard/Assignments/Mutations/useCreateAssignment';
import { useUpdateAssignment } from 'services/Dashboard/Assignments/Mutations/useUpdateAssignment';
import { useFileUploadMutation } from 'services/UploadFiles/useUploadFile';
import { TestURLs } from '../../PracticeTest/NewPracticeTest/NewPracticeTest.types';

// Types
import { NewAssignment } from '../Assignments.types';
import { AssignmentSlice, setEditAssignmentId, setReuseAssignment, setToggleNewAssignmentModal } from '../AssignmentSlice';

export const useLogic = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { classId, className, id } = useParams();
  const [editorInitialValue, setEditorInitialValue] = useState<string>();
  const [editorValue, setEditorValue] = useState<string>();
  const assignmentState = useSelector((state: { assignment: AssignmentSlice }) => state.assignment);
  const { mutateAsync: createAssignment, isLoading: isLoadingCreateAssignment } = useCreateAssignment();
  const { mutateAsync: updateAssignment, isLoading: isLoadingUpdateAssignment } = useUpdateAssignment();
  const reuseAssignmentDate = moment(assignmentState.reuseAssignment?.dueDate).toDate();
  const { progress, setProgress, mutation: { mutateAsync: uploadFiles, isLoading: isUploading } } = useFileUploadMutation();
  const [attachmentsIds, setAttachmentsIds] = useState<number[]>([]);
  const { getBase64 } = base64();
  const [files, setFiles] = useState<Files>([]);
  const [testURLs, setTestURLs] = useState<TestURLs>([]);
  const [deletedURLs, setDeletedURLs] = useState<string[]>([]);
  const input = useRef<HTMLInputElement | null>(null);
  const isImage = (url: string) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  const [graded, setGraded] = useState(true);

  const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm<NewAssignment>({
    defaultValues: {
      name: '',
      fullMark: 0,
      date: new Date()
    }
  });

  const onSubmit = handleSubmit((data) => {
    if (errors.name?.message || errors.date?.message || errors.fullMark?.message) return;
    if (!editorValue) {
      toast.error('Please provide the assignment details.');
      return;
    }
    if (assignmentState.editAssignmentId) {
      const editAssignmentData = {
        ...data,
        classId: Number(id),
        dueDate: moment(data.date).format('YYYY-MM-DD'),
        details: editorValue,
        addAttachmentsIds: attachmentsIds,
        deleteAttachmentsNames: deletedURLs
      };
      updateAssignment(editAssignmentData).then(() => {
        setValue('name', undefined);
        setValue('fullMark', undefined);
        setEditorInitialValue('');
        setEditorValue('');
        dispatch(setToggleNewAssignmentModal());
        dispatch(setReuseAssignment({}));
        dispatch(setEditAssignmentId(undefined));
        setFiles([]);
        setAttachmentsIds([]);
        setDeletedURLs([]);
        setTestURLs([]);
        queryClient.refetchQueries('reuseAssignments');
      });
    } else {
      const createAssignmentData = {
        ...data,
        classId: Number(id),
        dueDate: moment(data.date).format('YYYY-MM-DD'),
        details: editorValue,
        attachmentsIds,
        ungraded: !graded
      };
      createAssignment(createAssignmentData).then(() => {
        setValue('name', undefined);
        setValue('fullMark', undefined);
        setEditorInitialValue('');
        setEditorValue('');
        dispatch(setToggleNewAssignmentModal());
        dispatch(setReuseAssignment({}));
        dispatch(setEditAssignmentId(undefined));
        setFiles([]);
        setAttachmentsIds([]);
        setGraded(true);
        queryClient.refetchQueries('reuseAssignments');
      });
    }
  });

  // for reuse assignment, it fills the initial value with the selected assignment.
  useEffect(() => {
    setValue('id', assignmentState.reuseAssignment?.id);
    setValue('classId', assignmentState.reuseAssignment?.classId);
    setValue('name', assignmentState.reuseAssignment?.name);
    setValue('date', reuseAssignmentDate);
    setValue('fullMark', assignmentState.reuseAssignment?.fullMark);
    setGraded(!assignmentState.reuseAssignment.ungraded);
    setTestURLs(assignmentState.reuseAssignment.attachments);
    setEditorInitialValue(assignmentState.reuseAssignment?.details);
    setEditorValue(assignmentState.reuseAssignment?.details);
    setAttachmentsIds(assignmentState.reuseAssignment?.attachments?.filter((item) => item.teacherAttachment).map((attachment) => attachment.id) || []);
  }, [assignmentState.reuseAssignment]);

  const editorRef = useRef<any>(null);

  const handleChange = (e: any) => {
    setEditorValue(e.target.value || e.target.getContent());
  };

  useEffect(() => {
    if (assignmentState.showNewAssignmentModal === false) {
      setValue('name', undefined);
      setValue('fullMark', undefined);
      setValue('date', undefined);
      setEditorInitialValue('');
      setEditorValue('');
      dispatch(setReuseAssignment({}));
      dispatch(setEditAssignmentId(undefined));
      setGraded(true);
    }
  }, [assignmentState.showNewAssignmentModal]);

  const addFiles = (file: Files) => {
    setFiles([...file, ...files]);
  };

  const handleUpload = async (e: any) => {
    if (!e.target.files) return;

    const formData = new FormData();
    const promises = [];
    const temp: Files = [];

    for (let i = 0; i < e.target.files.length || 0; i++) {
      if (e.target.files[i].size > 26214400) {
        toast.error('File cannot be bigger than 25 Megabytes');
        e.target.value = '';
        return;
      }
      formData.append('files[]', e.target.files[i]);
      formData.append('teacherAttachment', 'true');
      promises.push(getBase64(e.target.files[i]));
    }

    const responses = await Promise.all(promises);
    responses.map((res: any) => {
      const startIndex = String(res.result).search('base64,') + 7;
      const base46 = String(res.result).slice(startIndex, String(res.result).length);
      temp.push({ base64: base46, type: res.type });
      return res;
    });
    addFiles(temp);

    uploadFiles({ file: formData })
      .then((res: any) => {
        setProgress(0);
        setAttachmentsIds([...attachmentsIds, ...res.data.attachmentsIds]);
      }).catch(() => {
        setFiles([...files].slice(-1));
      });

    e.target.value = '';
  };

  const handleRemoveUploadedFile = (index: number) => {
    if (assignmentState.reuseAssignment.attachments) {
      setTestURLs(testURLs?.filter((url) => {
        setDeletedURLs([...deletedURLs, String(testURLs?.[index]?.attachment)]);
        return testURLs.indexOf(url) !== index;
      }));
      setAttachmentsIds(attachmentsIds.filter((attachment) => attachmentsIds.indexOf(attachment) !== index));
    }
  };

  const handleRemoveNewFile = (index: number) => {
    setFiles(files.filter((file) => files.indexOf(file) !== index));
    setAttachmentsIds(attachmentsIds.filter((attachment) => attachmentsIds.indexOf(attachment) !== index));
  };

  return {
    classId,
    className,
    control,
    errors,
    editorRef,
    assignmentState,
    onSubmit,
    setValue,
    getValues,
    handleChange,
    editorInitialValue,
    isLoadingCreateAssignment,
    handleRemoveNewFile,
    handleRemoveUploadedFile,
    handleUpload,
    progress,
    isUploading,
    input,
    testURLs,
    isImage,
    files,
    isLoadingUpdateAssignment,
    graded,
    setGraded
  };
};
