import { useDispatch, useSelector } from 'react-redux';
import leads, { LeadsSlice } from '../../LeadsSlice';
import { useUpdateCallLog } from 'services/AdminDashboard/Leads/Logs/Mutations/UpdateCallLog/useUpdateCallLog';
import { useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

export const useLogic = () => {
  const dispatch = useDispatch();
  const { mutateAsync: updateLog, isLoading: isLoadingUpdateLog } = useUpdateCallLog();
  const leadsState = useSelector((state: { leads: LeadsSlice }) => state.leads);
  const queryClient = useQueryClient();
  const { toggleUpdateCallLogModal } = leads.actions;

  const handleUpdateLog = (contacted: boolean) => {
    updateLog({
      callLogId: leadsState.callLogId,
      contacted,
    }).then(() => {
      toast.success('Call log updated successfully');
      dispatch(toggleUpdateCallLogModal(false));
      queryClient.refetchQueries('call-logs');
    });
  };

  return {
    leadsState,
    handleUpdateLog,
    isLoadingUpdateLog,
  };
};
