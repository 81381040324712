// Types
import { Modal as IProps } from './Modal.types';

// Icons
import { X } from 'react-feather';

// Styling
import './Modal.scss';
import useWindowDimensions from 'assets/utils/getWindowDimentions';

const Modal = ({ children, isOpen, width, fullHeight, onRequestClose, isClosble }: IProps) => {
  const handleClose = () => {
    onRequestClose();
  };

  const { height } = useWindowDimensions();

  return (
    <div
      className={`modal ${isOpen ? 'display-block' : 'display-none'}`}
      onClick={() => {
        if (isClosble) {
          handleClose();
        }
      }}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      <section
        className={`modal-main ${fullHeight && (height < 800) ? 'full-height' : ''}`}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        style={{
          width: width || '550px'
        }}
      >
        <div className="modal-header-container">
          { isClosble && (
            <div className="close-icon-container">
              <X
                className="close-icon"
                onClick={handleClose}
              />
            </div>
          )}
        </div>
        <div className="modal-body-container">
          <div>
            {children}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Modal;
