import { useEffect, useRef, useState } from 'react';
import { Files } from '../AssignmentCard/AssignmentCard.types';
import { base64 } from 'assets/utils/getBase64';
import { useFileUploadMutation } from 'services/UploadFiles/useUploadFile';
import { setPostData, StreamSlice, toggleEditablePost } from 'pages/Dashboard/Course/Stream/StreamSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PostURLs } from './EditPost.types';
import { useEditPost } from 'services/Dashboard/Posts/Mutations/useEditPost';
import toast from 'react-hot-toast';

export const useLogic = () => {
  const [showEmojis, setShowEmojis] = useState(false);
  const [text, setText] = useState<string | undefined>('');
  const [files, setFiles] = useState<Files>([]);
  const [postURLs, setPostURLs] = useState<PostURLs>([]);
  const [attachmentsIds, setAttachmentsIds] = useState<number[]>([]);
  const [chosenEmoji, setChosenEmoji] = useState<any>(undefined);
  const [deletedURLs, setDeletedURLs] = useState<string[]>([]);
  const input = useRef<HTMLInputElement | null>(null);
  const { getBase64 } = base64();
  const { mutateAsync: editPost, isLoading: isLoadingEditPost } = useEditPost();
  const { progress, setProgress, mutation: { mutateAsync: uploadFiles, isLoading } } = useFileUploadMutation();
  const streamState = useSelector((state: { stream: StreamSlice }) => state.stream);
  const isImage = (url: string) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  const dispatch = useDispatch();

  const onEmojiClick = (emojiObject: any) => {
    setChosenEmoji(emojiObject);
  };

  useEffect(() => {
    if (chosenEmoji?.emoji) {
      setText(text + chosenEmoji?.emoji);
    }
  }, [chosenEmoji]);

  const addFiles = (file: Files) => {
    setFiles([...file, ...files]);
  };

  const handleUpload = async (e: any) => {
    if (!e.target.files) return;

    const formData = new FormData();
    const promises = [];
    const temp: Files = [];

    for (let i = 0; i < e.target.files.length || 0; i++) {
      if (e.target.files[i].size > 26214400) {
        toast.error('File cannot be bigger than 25 Megabytes');
        e.target.value = '';
        return;
      }
      formData.append('files[]', e.target.files[i]);
      promises.push(getBase64(e.target.files[i]));
    }

    const responses = await Promise.all(promises);
    responses.map((res: any) => {
      const startIndex = String(res.result).search('base64,') + 7;
      const base46 = String(res.result).slice(startIndex, String(res.result).length);
      temp.push({ base64: base46, type: res.type });
      return res;
    });
    addFiles(temp);

    uploadFiles({ file: formData })
      .then((res: any) => {
        setProgress(0);
        setAttachmentsIds([...attachmentsIds, ...res.data.attachmentsIds]);
      }).catch(() => {
        setFiles([...files].slice(-1));
      });

    e.target.value = '';
  };

  const handlePost = () => {
    if (streamState.post.id) {
      editPost({
        postId: streamState.post.id,
        text,
        addAttachmentsIds: attachmentsIds,
        deleteAttachmentsNames: deletedURLs
      })
        .then(() => {
          dispatch(setPostData({}));
          dispatch(toggleEditablePost(false));
          setText('');
          setChosenEmoji(undefined);
          setAttachmentsIds([]);
          setFiles([]);
          setPostURLs([]);
        });
    }
  };

  const handleRemoveImage = (index: number) => {
    if (streamState.post) {
      setPostURLs(postURLs.filter((url) => {
        setDeletedURLs([...deletedURLs, url.attachment]);
        return postURLs.indexOf(url) !== index;
      }));
    } else {
      setFiles(files.filter((file) => files.indexOf(file) !== index));
      setAttachmentsIds(attachmentsIds.filter((attachment) => attachmentsIds.indexOf(attachment) !== index));
    }
  };

  // Edit post
  useEffect(() => {
    setText(streamState.post.text);
    setPostURLs(streamState.post.attachments ? streamState.post.attachments : []);
    setDeletedURLs(streamState.post.attachments ? deletedURLs : []);
  }, [streamState.post]);

  return {
    text,
    showEmojis,
    files,
    postURLs,
    input,
    attachmentsIds,
    streamState,
    setShowEmojis,
    onEmojiClick,
    handlePost,
    setText,
    handleUpload,
    progress,
    isLoading,
    handleRemoveImage,
    isImage,
    isLoadingEditPost
  };
};
