import { useLogic } from './AttendanceRecords.logic';
import './AttendanceRecords.scss';
import { SimpleStatisticsCard } from 'components/Dashboard/SimpleStatisticsCard/SimpleStatisticsCard';
import YellowAvatarICon from 'assets/images/yellow-avatar.svg';
import MissidCallIcon from 'assets/images/camera-avatar.svg';
import CalendarIcon from 'assets/images/calendar.svg';
import { CourseStatisticsCard } from 'components/Dashboard/CourseStatisticsCard/CourseStatisticsCard';
import moment from 'moment-timezone';
import DotsLoader from 'components/General/Loader/Loader';
import ListingTable from 'components/AdminDashboard/ListingTable/ListingTable';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import Modal from 'components/General/Modal/Modal';
import { AttendanceModal } from './AttendanceModal/AttendanceModal';

export const AttendanceRecords = () => {
  const {
    Columns,
    data,
    selected,
    handleSelect,
    course,
    isLoading,
    navigate,
    studentsState,
    dispatch,
    setAttendance
  } = useLogic();
  return (
    <div className="attendance-records-container">
      <div className="course-cards-container">
        <h3>Your course</h3>
        <div className="course-cards">
          {
          data?.adminGetUserClassSessions?.map((card) => (
            <CourseStatisticsCard
              status={card.status}
              title={card.plan?.course?.name}
              selectedCourse={card.id === selected.id}
              onClick={() => handleSelect(card.id, card.plan?.course?.name)}
              goToCourse={() => navigate(`/class/${card.id}/${card.classId}/${card.plan?.course?.name}/stream`)}
            />
          ))
        }

        </div>
      </div>
      <div className="info-cards-container mt-4 ml-2 mr-2">
        {isLoading ? (
          <DotsLoader />
        ) : (
          <>
            <SimpleStatisticsCard
              image={YellowAvatarICon}
              number={course?.attendedSessions}
              description="Total attended sessions"
            />
            <SimpleStatisticsCard
              image={MissidCallIcon}
              number={course?.missedSessions}
              description="Total missed sessions"
            />
            {/**
           FIXME add missing attribute misses sessions
           */}
            <SimpleStatisticsCard
              image={CalendarIcon}
              number={course?.rescheduledSessions}
              description="Total rescheduled sessions"
            />
          </>
        )}
      </div>
      <div className="attendance-records-table-container">
        <h3>Attendance history</h3>
        <ListingTable
          columns={Columns}
          data={course?.sessions?.map((record) => (
            [
              { value: <p className="sub-text">{record.id}</p> },
              { value: <p className="sub-text">{course?.plan?.course?.name}</p> },
              { value: <p className="sub-text">{`Session ${record.sessionNumber}`}</p> },
              { value: <p className="sub-text">{moment(record.dueAt).format('MM/DD/YYYY')}</p> },
              { value: (
                <ButtonBase
                  tagName="div"
                  onClick={() => {
                    if (record.status === 'Attended' || record.status === 'Absent') {
                      dispatch(setAttendance({ sessionId: record.id, modal: true }));
                    }
                  }}
                >
                  <p
                    className="sub-text"
                    style={{ color: `${(record.status === 'Absent' ? 'red' : '') || (record.status === 'Attended' ? 'green' : '')}` }}
                  >
                    {record.status}
                  </p>
                </ButtonBase>
              ) },
            ]
          ))}
        />
      </div>
      <Modal
        isClosble
        isOpen={studentsState.attendance.modal}
        onRequestClose={() => dispatch(setAttendance({ modal: false }))}
      >
        <AttendanceModal />
      </Modal>
    </div>
  );
};
