import Button from 'components/General/Button/Button';
import './ConfirmationModal.scss';

export const ConfirmationModal = () => {
  const closeTab = () => {
    window.opener = null;
    window.open('', '_self');
    window.close();
  };
  return (
    <div className="confirmation-modal">
      <h3>Completed Onboarding!</h3>
      <Button
        color="primary"
        onClick={closeTab}
      >
        Back to admin panel
      </Button>
    </div>
  );
};
