import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { io } from 'socket.io-client';

export const useReactQuerySubscription = (id: number) => {
  const queryClient = useQueryClient();
  const socket = io(String(process.env.REACT_APP_API_URL));

  useEffect(() => {
    socket.on('connect', () => {
      console.log('connected');
    });
    socket.on(`connection${id}`, (data) => {
      switch (data.action) {
        // Posts
        case 'New_post':
          queryClient.setQueryData(['streamPosts'], (oldData: any) => {
            const newData = { ...oldData };
            newData.userClassPosts.unshift(data.data);
            return newData;
          });
          break;

        case 'Edit_post':
          queryClient.setQueryData(['streamPosts'], (oldData: any) => {
            const index = oldData.userClassPosts.findIndex((item: any) => item.id === data.data.id);
            if (index !== -1) {
              const newData = { ...oldData };
              newData.userClassPosts.splice(index, 1);
              newData.userClassPosts.unshift(data.data);
              return newData;
            }
            return oldData;
          });
          break;

        case 'Delete_post':
          queryClient.setQueryData(['streamPosts'], (oldData: any) => {
            const index = oldData.userClassPosts?.findIndex((item: any) => item.id === data.data.postId);
            if (index !== -1) {
              oldData.userClassPosts.splice(index, 1);
            }
            return oldData;
          });
          break;

        case 'New_comment':
          queryClient.setQueryData(['streamPosts'], (oldData: any) => {
            const index = oldData.userClassPosts?.findIndex((item: any) => item.id === data.data.postId);
            if (index !== -1) {
              const post = oldData.userClassPosts?.[index];
              const oldComments = oldData.userClassPosts?.[index]?.comments;
              const comments = [...oldComments];
              comments.unshift(data.data);
              const newPost = { ...post, comments };
              oldData.userClassPosts.splice(index, 1);
              const newData = { ...oldData };
              newData.userClassPosts[index] = newPost;
              return newData;
            }
            return oldData;
          });
          break;

        case 'Delete_comment':
          queryClient.setQueryData(['streamPosts'], (oldData: any) => {
            const index = oldData.userClassPosts?.findIndex((item: any) => item.id === data.data.postId);
            if (index !== -1) {
              oldData.userClassPosts?.[index]?.comments.splice(data.data.id, 1);
            }
            return oldData;
          });
          break;

        // Assignment
        case 'New_assignment':
          queryClient.setQueryData(['assignments'], (oldData: any) => {
            const newData = { ...oldData };
            newData.userClassAss.unshift(data.data);
            return newData;
          });
          break;

        case 'Delete_assignment':
          queryClient.setQueryData(
            ['assignments'],
            (oldData: any) => {
              const index = oldData.userClassAss.findIndex((item: any) => item.id === data.data.assignmentId);
              if (index !== -1) {
                oldData.userClassAss.splice(index, 1);
              }
              return oldData;
            }
          );
          break;

        case 'Edit_assignment':
          queryClient.setQueryData(
            ['assignments'],
            (oldData: any) => {
              const index = oldData.userClassAss.findIndex((item: any) => item.id === data.data.id);
              if (index !== -1) {
                const newData = { ...oldData };
                newData.userClassAss.splice(index, 1);
                newData.userClassAss.unshift(data.data);
                return newData;
              }
              return oldData;
            }
          );
          break;

        // Practice test
        case 'New_test':
          queryClient.setQueryData(['practiceTests'], (oldData: any) => {
            const newData = { ...oldData };
            newData.userClassTest.unshift(data.data);
            return newData;
          });
          break;

        case 'Delete_test':
          queryClient.setQueryData(
            ['practiceTests'],
            (oldData: any) => {
              const index = oldData.userClassTest.findIndex((item: any) => item.id === data.data.testId);
              if (index !== -1) {
                oldData.userClassTest.splice(index, 1);
              }
              return oldData;
            }
          );
          break;

        case 'Edit_test':
          queryClient.setQueryData(
            ['practiceTests'],
            (oldData: any) => {
              const index = oldData.userClassTest.findIndex((item: any) => item.id === data.data.id);
              if (index !== -1) {
                const newData = { ...oldData };
                newData.userClassTest.splice(index, 1);
                newData.userClassTest.unshift(data.data);
                return newData;
              }
              return oldData;
            }
          );
          break;

          // Sessions
        case 'Session_in_30_min':
          queryClient.resetQueries('teacherNearestSession');
          break;
        default:
          console.log('error');
      }
    });

    return () => {
      socket.close();
    };
  }, [queryClient]);
};

// export enum SocketActions {
//   NEW_POST = "New_post",
//   EDIT_POST = "Edit_post",
//   DELETE_POST = "Delete_post",
//   NEW_COMMENT = "New_comment",
//   DELETE_COMMENT = "Delete_comment",
//   DELETE_ASSIGNMENT = "Delete_assignment",
//   DELETE_TEST = "Delete_test",
//   NEW_ASSIGNMENT = "New_assignment",
//   EDIT_ASSIGNMENT = "Edit_assignment",
//   NEW_TEST = "New_test",
//   EDIT_TEST = "Edit_test",
//   NOTIFICATION = "Notification",
// }
