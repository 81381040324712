// Types
import { AssignmentsQuery } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useGetAssignments = (id?: number) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<AssignmentsQuery, Error> = useQuery<AssignmentsQuery, Error>(['assignments'], async () => client.request(
    gql`
    query UserClassAss($classId: Float!, $limit: Float!, $offset: Float!) {
      userClassAss(classId: $classId, limit: $limit, offset: $offset) {
        id
        name
        details
        status
        dueDate
        fullMark
        classId
        receivedMark
        ungraded
        attachments {
          id
          attachment
          teacherAttachment
        }
      }
    }
    `,
    {
      classId: id,
      limit: 0,
      offset: 0
    }
  ).catch((error) => console.error(error)));
  return data;
};
