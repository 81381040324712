import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminChoosePlan = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ userId, planId }: { userId: number, planId?: number }) => client.request(
    gql`
    mutation AdminChoosePlan($planId: Float!, $userId: Float!) {
      adminChoosePlan(planId: $planId, userId: $userId) {
        message
      }
    }
    `,
    {
      planId,
      userId
    }
  ));
  return Mutation;
};
