// Cookies
import { useCookies } from 'react-cookie';

// Types
import { ForgotPassword as IProps } from './ForgotPassword.types';

// Components
import TextField from 'components/General/TextField/TextField';
import Button from 'components/General/Button/Button';

// Layouts
import AuthenticationLayout from 'layouts/AuthenticationLayout/AuthenticationLayout';

// Stylesheet
import './ForgotPassword.scss';

// React hook form
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from 'routes/Auth/AuthProvider';
import { Link, useNavigate } from 'react-router-dom';
import { SIGNIN, RESET_VERIFY } from 'routes/CONSTANTS';
import { useState } from 'react';
import { AxiosError } from 'axios';

const ForgotPassword = () => {
  // eslint-disable-next-line
  const [_, setCookie] = useCookies(['email']);
  const { forgotPassword, isAuthenticating } = useAuth();
  const navigate = useNavigate();
  const [authError, setAuthError] = useState<string>();

  const { control, handleSubmit, formState: { errors } } = useForm<IProps>({
    defaultValues: {
      email: '',
    }
  });

  const onSubmit = handleSubmit((data) => {
    if (errors.email?.message) return;
    setCookie('email', data.email, { path: '/' });
    forgotPassword(data).then(() => {
      navigate(RESET_VERIFY);
    }).catch((err: AxiosError) => {
      setAuthError(err.response?.data?.message);
    });
  });

  return (
    <AuthenticationLayout>
      <div className="password-recovery-container">
        <h1 className="auth-title">Forgot Password</h1>
        <div className="form-container">
          <form onSubmit={onSubmit}>
            <div className="text-field">
              <Controller
                control={control}
                name="email"
                rules={{
                  required: 'This field is required',
                  minLength: {
                    value: 5,
                    message: 'Email should be longer than 5 characters'
                  },
                  maxLength: {
                    value: 30,
                    message: 'Email cannot be longer than 30 characters'
                  }
                }}
                render={({ field: { onChange } }) => (
                  <TextField
                    variant="rounded"
                    type="email"
                    placeholder="User@email.com"
                    id="email"
                    onChange={onChange}
                    label="Email"
                    error={errors?.email?.message || authError}
                  />
                )}
              />
            </div>
            <Button
              color="primary"
              type="submit"
              isLoading={isAuthenticating}
            >
              Change
            </Button>
            <h4>
              Did You Remember Your Password?
              {' '}
              <span><Link to={SIGNIN}>Sign In</Link></span>
            </h4>

          </form>
        </div>
      </div>

    </AuthenticationLayout>
  );
};
export default ForgotPassword;
