import { TableColumn } from 'components/Dashboard/Table/Table.types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { SessionAttendance } from 'services/AdminDashboard/PersonalInfo/CourseActivity/SessionAttendance/types';
import { useSessionAttendance } from 'services/AdminDashboard/PersonalInfo/CourseActivity/SessionAttendance/useSessionAttendance';
import { StudentsSlice, setAttendance } from '../../../StudentsSlice';

export const useLogic = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const studentsState = useSelector((state: { students: StudentsSlice }) => state.students);
  const { data, isFetching: isLoading } = useSessionAttendance(Number(id));
  const [selected, setSelected] = useState<{ id?: number, name?: string }>({});
  const [course, setCourse] = useState<SessionAttendance>();
  const Columns: TableColumn[] = [
    {
      value: '#',
      sortable: true,
      key: 'id',
    },
    {
      value: 'Course',
      sortable: true,
      key: 'course',
    },
    {
      value: 'Session number',
      sortable: true,
      key: 'session_number'
    },
    {
      value: 'Date',
      sortable: true,
      key: 'date'
    },
    {
      value: 'Status',
      sortable: true,
      key: 'status'
    },
  ];

  useEffect(() => {
    if (data) {
      setSelected({ id: data.adminGetUserClassSessions?.[0]?.id, name: data.adminGetUserClassSessions?.[0]?.plan?.course?.name });
      setCourse(data.adminGetUserClassSessions?.[0]);
    }
  }, [data]);

  const handleSelect = (cardId?: number, cardName?: string) => {
    setSelected({ id: cardId, name: cardName });
    setCourse(data?.adminGetUserClassSessions?.find((item) => item.id === cardId));
  };

  return {
    Columns,
    handleSelect,
    selected,
    isLoading,
    data,
    course,
    navigate,
    studentsState,
    dispatch,
    setAttendance
  };
};
