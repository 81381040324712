import { FC, useEffect, useRef, useState } from 'react';

// Types
import { SearchModalProps } from './SearchModal.types';

// Style
import './SearchModal.scss';

// Utils
import { getModifierKeyPrefix } from 'assets/utils/os';

// Components
import { useCookies } from 'react-cookie';
import { ButtonBase } from '../ButtonBase/ButtonBase';

const SearchModal: FC<SearchModalProps> = ({ isOpen, isLoading, onRequestClose, onSearch, searchResults, className, ...rest }) => {
  // This is important to change the input text while typing
  const [searchValue, setSearchValue] = useState('');

  // This is important to set the search value after the user stops typing
  const [debounceSearchValue, setDebounceSearchValue] = useState('');

  // Active list item
  const [activeIndex, setActiveIndex] = useState(0);

  // Previous searches list
  const [{ previousSearches }, setPreviousSearches] = useCookies(['previousSearches']);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => searchInputRef.current?.focus();

  // When the user selects a previous search there's no need to wait for the debounce
  const handleSelectPreviousSearch = (value: string) => {
    setSearchValue(value);
    onSearch?.(value);
    setActiveIndex(0);
    setDebounceSearchValue(value);
  };

  useEffect(() => {
    // Close when escape is pressed
    const hideOnEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onRequestClose();
      }
    };

    window.addEventListener('keydown', hideOnEscape);

    return (() => {
      window.removeEventListener('keydown', hideOnEscape);
    });
  }, []);

  useEffect(() => {
    // Navigate by arrows
    const navigateByArrows = (event: KeyboardEvent) => {
      const navigationList = debounceSearchValue ? searchResults : previousSearches;
      if (event.key === 'ArrowUp') {
        setActiveIndex((prev) => {
          if (prev === 0) return (navigationList?.length || 0) - 1;
          return prev - 1;
        });
      }
      if (event.key === 'ArrowDown') {
        setActiveIndex((prev) => {
          if (prev === (navigationList?.length || 0) - 1) return 0;
          return prev + 1;
        });
      }
      if (event.key === 'Enter' && searchInputRef.current) {
        if (debounceSearchValue) {
          searchResults[activeIndex]?.onClick?.();
          onRequestClose();
          setSearchValue('');
        } else {
          setSearchValue(previousSearches?.[activeIndex] || '');
          handleSelectPreviousSearch(previousSearches?.[activeIndex] || '');
        }
      }
    };

    window.addEventListener('keydown', navigateByArrows);

    return (() => {
      window.removeEventListener('keydown', navigateByArrows);
    });
  }, [activeIndex, debounceSearchValue, searchResults, previousSearches]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        focusInput();
      }, 100);
    }
  }, [isOpen]);

  // On search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue) {
        const temp = [...(previousSearches || [])];
        const index = temp.findIndex((value) => value === searchValue);
        if (index !== -1) { temp.splice(index, 1); }
        setPreviousSearches('previousSearches', [searchValue, ...temp.slice(0, 3)]);
        onSearch?.(searchValue);
        setActiveIndex(0);
        setDebounceSearchValue(searchValue);
      }
    }, 700);

    if (!searchValue) {
      onSearch?.(searchValue);
      setActiveIndex(0);
      setDebounceSearchValue(searchValue);
    }

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  // Help bar compontent
  const HelperBar = () => (
    <div className="helper-bar">
      <div className="actions">
        <div>
          <i className="icon-navigate" />
          {' '}
          Navigate
        </div>
        <div>
          <i className="icon-enter" />
          {' '}
          Go
        </div>
      </div>
      <div className="quick-open">
        Press
        {' '}
        <span>
          {getModifierKeyPrefix()}
          {' '}
          + F
        </span>
        {' '}
        to open
      </div>
    </div>
  );

  return (
    <>
      { isOpen
    && (
    <ButtonBase
      tagName="div"
      className="search-modal"
      onClick={onRequestClose}
    >
      <ButtonBase
        tagName="div"
        className={`modal-container${` ${className}` || ''}`}
        onClick={(event) => {
          event.stopPropagation();
          focusInput();
        }}
        {...rest}
      >
        <div className="search-input">
          <i className="icon-search" />
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Type to search..."
            // eslint-disable-next-line
            autoFocus
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>

        { (isLoading || previousSearches || (debounceSearchValue && searchResults))
        && (
          <div className="search-content">
            {/* Loading state */}
            { isLoading
            && (
              <div className="loader">
                <div className="shine" />
                <div className="shine" />
                <div className="shine" />
              </div>
            ) }

            {/* Previous searches list state */}
            { previousSearches && !isLoading && !debounceSearchValue
          && (
          <div className="previous-searches">
            <h3>Recently Searched</h3>
            <ul>
              {previousSearches.map((searchKeyword: string, index: number) => (
                <li>
                  <ButtonBase
                    tagName="li"
                    className={activeIndex === index ? 'active' : ''}
                    onMouseEnter={() => setActiveIndex(index)}
                    onClick={() => handleSelectPreviousSearch(searchKeyword)}
                  >
                    <i className="icon-search" />
                    {searchKeyword}
                    { activeIndex === index && <i className="icon-enter" /> }
                  </ButtonBase>
                </li>
              ))}
            </ul>
            <HelperBar />
          </div>
          ) }

            {/* Search results state */}
            {
            debounceSearchValue && searchResults.length > 0 && !isLoading
            && (
            <div className="search-results">
              <ul>
                {searchResults.map((result, index) => (
                  <ButtonBase
                    tagName="li"
                    className={activeIndex === index ? 'active' : ''}
                    onMouseEnter={() => setActiveIndex(index)}
                    onClick={result.onClick}
                  >
                    <li>
                      {result.element}
                      { activeIndex === index && <i className="icon-enter" /> }
                    </li>
                  </ButtonBase>
                ))}
              </ul>
              <HelperBar />
            </div>
            )
          }

            {/* No results state */}
            {debounceSearchValue && searchResults.length === 0 && !isLoading && <div className="no-results">No results.</div>}
          </div>
        ) }
      </ButtonBase>
    </ButtonBase>
    ) }
    </>
  );
};

export default SearchModal;
