import { PostTextField } from 'components/Dashboard/PostTextField/PostTextField';
import Button from 'components/General/Button/Button';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import Dropdown from 'components/General/Dropdown/Dropdown';
import { useLogic } from './RateSession.logic';
import './RateSession.scss';
import { v4 as uuid } from 'uuid';
import moment from 'moment-timezone';

export const RateSession = () => {
  const {
    icons,
    isDone,
    setIsDone,
    rating,
    setRating,
    reasons,
    handleSubmit,
    comment,
    setComment,
    setReason,
    isLoading,
    dashboardState
  } = useLogic();
  return (
    <div className="rate-session-container">
      <div className="session-info">
        <h3>{`${dashboardState.feedbacks.courseName} #${dashboardState.feedbacks.classId} - session #${dashboardState.feedbacks.sessionNumber}`}</h3>
        <h3>{`${moment(dashboardState.feedbacks.date).format('YYYY-MM-DD HH:mm')}`}</h3>
      </div>
      <div className="rate-session-input">
        <h3>Is the session done?</h3>
        <Dropdown
          options={['Yes', 'No', 'Partially']}
          onChange={(e) => setIsDone(e.target.value)}
          value={isDone}
        />
      </div>
      { isDone === 'Yes' && (
        <div className="icons-container">
          {icons.map((item) => (
            <ButtonBase
              tagName="div"
              onClick={() => setRating(item.rating)}
              key={uuid()}
            >
              <div
                className={`icon ${rating === item.rating && 'active'}`}
              >
                <img
                  src={item.icon}
                  alt={item.desc}
                />
                <div className="icon-title">
                  <h3>{item.desc}</h3>
                </div>
              </div>
            </ButtonBase>

          ))}
        </div>
      )}
      { isDone === 'No' && (
        <div className="rate-session-input">
          <h3>Reason</h3>
          <Dropdown
            options={reasons}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
      )}
      {/* { isDone === 'Partially' && (
        <div className="rate-session-input">
          <h3>How much of the session was done?</h3>
          <Dropdown
            options={hours}
          />
        </div>
      )} */}
      <div className="rate-session-input">
        <h3>Comments</h3>
        <PostTextField
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          color="gray"
          rows={7}
          variant="round"
        />
      </div>
      <Button
        color="primary"
        isLoading={isLoading()}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </div>
  );
};
