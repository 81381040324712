import { gql } from 'graphql-request';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useDraftPlan = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ planId, courseId }: { courseId?: number, planId?: number, }) => client.request(
    gql`
    mutation UserDraftPlan($courseId: Float!, $planId: Float) {
      userDraftPlan(courseId: $courseId, planId: $planId) {
        message
      }
    }
    `,
    {
      courseId,
      planId
    }
  )
    .catch((error) => toast(error.response.errors[0].message)));
  return Mutation;
};
