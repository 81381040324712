import { StatisticsCard } from 'components/Dashboard/StatisticsCard/StatisticsCard';
import { useLogic } from './Statistics.logic';
import ArrowsIcon from 'assets/images/arrows-up-down.svg';
import ChatIcon from 'assets/images/active-student.svg';
import YellowAvatarIcon from 'assets/images/yellow-avatar.svg';

// Styling
import './Statistics.scss';

export const Statistics = () => {
  const { statistics, isLoading } = useLogic();
  return (
    <div className="statistics-cards-container">
      <StatisticsCard
        image={ArrowsIcon}
        percentage={statistics?.teacherStudentsStat.averageStudentImprovement}
        description="Student improvement"
        isLoading={isLoading}
      />
      <StatisticsCard
        image={ChatIcon}
        count={statistics?.teacherStudentsStat.studentsTaught || 0}
        description="Total taught students"
        isLoading={isLoading}
      />
      <StatisticsCard
        image={YellowAvatarIcon}
        count={statistics?.teacherStudentsStat.studentsActive || 0}
        description="Total active students"
        isLoading={isLoading}
      />
    </div>
  );
};
