import React, { useEffect, useState, FC } from 'react';

// Types
import { Timeline as IProps } from './Timeline.types';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft as back } from '@fortawesome/free-solid-svg-icons';

// Styling
import './Timeline.scss';

// Redux
import { useSelector } from 'react-redux';
import { OnboardingSlice } from '../../../pages/Onboarding/OnboardingSlice';
import { useParams } from 'react-router';

const Timeline: FC<IProps> = ({ options, onClick }) => {
  const { userId } = useParams();
  const onboardingState = useSelector((state: { onboarding: OnboardingSlice }) => state.onboarding);
  const [progressBarWidth, setProgressBarWidth] = useState<number>(0);
  const itemsCount = onboardingState.userSteps.length;
  const activeItemsCount = onboardingState.activeStep;

  // currentStep changes according to the chosen course, IELTS has only for steps meanwhile SAT and GMAT have 5.
  // Its used to hide the back button after confirming the schedule.
  const currentStep = onboardingState.plan.course === 'IELTS' ? 5 : 6;

  useEffect(() => {
    if (activeItemsCount >= 1) {
      // eslint-disable-next-line
      setProgressBarWidth(itemsCount > 1 ? (activeItemsCount - 0.5) / (itemsCount - 1) * 100 : 0)
    } else {
      setProgressBarWidth(0);
    }
  }, [options, activeItemsCount]);

  return (
    <div className="timeline-main-container">
      <div className="back-container">
        {!(((onboardingState.plan.isDraft || userId) && onboardingState.activeStep === 1) || onboardingState.activeStep === currentStep) && (
        <>
          <FontAwesomeIcon
            icon={back}
            className="icon"
            size="1x"
            color="#696C71"
          />
          <h4>
            <a
              href="#"
              onClick={onClick}
            >
              Back
            </a>
          </h4>
        </>
        )}
      </div>
      <div className="timeline-container">
        <div
          className="timeline-progress"
          style={{ width: `${progressBarWidth}%` }}
        />
        <div className="timeline-items">
          {React.Children.toArray(options.map((option) => (
            <div className={`timeline-item ${activeItemsCount >= options.indexOf(option) + 1 ? 'active' : ''}`} />
          )))}
        </div>
      </div>
      <h4>
        {`${Math.round(progressBarWidth > 100 ? 100 : progressBarWidth)}% Done`}
      </h4>
    </div>
  );
};

export default Timeline;
