import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminChangeAttendance = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ sessionId, attended }: { sessionId?: number, attended?: boolean }) => client.request(
    gql`
    mutation AdminChangeSessionStatus($sessionId: Float!, $attended: Boolean!) {
      adminChangeSessionStatus(sessionId: $sessionId, attended: $attended) {
        id
      }
    }
    `,
    {
      sessionId,
      attended
    }
  ));
  return Mutation;
};
