// Types
import { SocialButton as IProps } from './SocialButton.types';

// Styling
import './SocialButton.scss';
import 'assets/scss/typography.scss';

// Icons
import Google from 'assets/images/google.svg';
import Facebook from 'assets/images/facebook.svg';

const SocialButton = ({ children, ...rest }: IProps) => (

  <button
    className="social-button"
    type="button"
    {...rest}
  >
    <img
      src={children === 'Facebook' ? Facebook : Google}
      alt={children}
    />
    <h4>
      {children}
    </h4>
  </button>
);

export default SocialButton;
