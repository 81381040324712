// Types
import { TeacherStats } from './types';

// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useStudentsStatistics = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<TeacherStats, Error> = useQuery<TeacherStats, Error>(['teacher-students-statistics'], async () => client.request(
    gql`
    query TeacherStudentsStat {
      teacherStudentsStat {
        averageStudentImprovement
        studentsTaught
        studentsActive
      }
    }
    `,
  ).catch((error) => console.error(error)));
  return data;
};
