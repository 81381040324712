// Redux
import Button from 'components/General/Button/Button';
import ModalImage from 'assets/images/customer-service 1.svg';
import WhatsappIcon from 'assets/images/whatsapp.png';

import './ComplaintModal.scss';
import { useNavigate } from 'react-router';
import { STUDENT_DASHBOARD } from 'routes/CONSTANTS';
import useWindowDimensions from 'assets/utils/getWindowDimentions';

export const ComplaintModal = () => {
  const navigate = useNavigate();
  const dimensions = useWindowDimensions();
  const isMobile = dimensions.width <= 768;

  return (
    <div className="schedule-modal-container">
      <div className="modal-image-container mt-4">
        <img
          className="modal-image"
          src={ModalImage}
          alt="ModalImage"
        />
      </div>
      {/* <h3>Text us your schedule preferences.</h3> */}
      <h4>
        One of our agents will contact you by the end of the next business day to help you create your schedule.
      </h4>
      <div className="button-container">
        {isMobile ? (
          <div className="mb-1">
            <Button
              color="primary"
              onClick={() => window.open('whatsapp://send?text=Hello from SetOut!&phone=961-81716629', '_blank')}
            >
              Text us your schedule preferences.
              <img
                src={WhatsappIcon}
                alt="whatsapp"
                height={30}
                width={30}
              />
            </Button>
          </div>
        ) : (
          <div className="qrcode-container mb-2">
            <h3>Text us your schedule preferences.</h3>
            <img
              src="/images/qr-code.jpg"
              alt="QRCode"
            />
          </div>
        )}
        <Button
          color="primary"
          onClick={() => navigate(STUDENT_DASHBOARD)}
        >
          Go to dashboard
        </Button>
      </div>
    </div>
  );
};
