// Types
import { AdminDashboardLayout as IProps } from './AdminDashboardLayout.types';

// Components
import { DashboardNavbar } from 'components/Dashboard/DashboardNavbar/DashboardNavbar';
import { DashboardSideBar } from 'components/Dashboard/DashboardSideBar/DashboardSideBar';

// Styles
import './AdminDashboardLayout.scss';

// Grid system

// Context
import { AdminTabBar } from 'components/Dashboard/AdminTabBar/AdminTabBar';
import { DASHBOARD } from 'routes/CONSTANTS';
import SearchModal from 'components/General/SearchModal/SearchModal';
import useLogic from './AdminDashboardLayout.logic';
import { StudentSearchCard } from 'components/General/SearchCards/StudentSearchCard/StudentSearchCard';

export const AdminDashboardLayout = ({ children }: IProps) => {
  const {
    showSearch,
    students,
    isLoadingStudentsData,
    setSearchValue,
    setShowSearch,
    handleStudentNavigation,
    tabs,
    userInfo,
    selectedTab,
    leads,
    isLoadingLeadssData,
    handleLeadNavigation
  } = useLogic();

  return (
    <div>
      <DashboardNavbar
        onClick={() => {
          setShowSearch(true);
        }}
        name={`${userInfo?.userGetInfo.first_name || ''} ${userInfo?.userGetInfo.last_name || ''}`}
        image={userInfo?.userGetInfo.picture}
      />
      {
        selectedTab === 'leads' ? (
          <SearchModal
            isOpen={showSearch}
            onRequestClose={() => setShowSearch(false)}
            onSearch={(value) => setSearchValue(value)}
            isLoading={isLoadingLeadssData}
            searchResults={
              leads?.adminAllLeadsStat.leadsStatistics.map((lead) => ({
                element: <StudentSearchCard
                  name={`${lead.firstName} ${lead.lastName}` || ''}
                  avatar={`https://eu.ui-avatars.com/api/?background=F1F1F1&color=6371e0&name=${encodeURI(`${lead.firstName} ${lead.lastName}` || '')}&size=80`}
                />,
                onClick: () => handleLeadNavigation(lead.userId, lead.leadId, lead.course)
              })) || []
            }
          />
        ) : (
          <SearchModal
            isOpen={showSearch}
            onRequestClose={() => setShowSearch(false)}
            onSearch={(value) => setSearchValue(value)}
            isLoading={isLoadingStudentsData}
            searchResults={
              students?.adminAllUsersStat.allUsersStat.map((student) => ({
                element: <StudentSearchCard
                  name={student.name || ''}
                  avatar={`https://eu.ui-avatars.com/api/?background=F1F1F1&color=6371e0&name=${encodeURI(student.name || '')}&size=80`}
                />,
                onClick: () => handleStudentNavigation(student.id || 0)
              })) || []
            }
          />
        )
      }
      <div className="admin-dashboard-container">
        <div className="admin-sidebar-container">
          <DashboardSideBar />
        </div>
        <div className="body-container">
          <AdminTabBar
            selectedTab="Classes"
            tabs={tabs}
            parentRoute={DASHBOARD}
          />
          {children}
        </div>
      </div>
    </div>
  );
};
