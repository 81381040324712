// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { ListTasks } from './types';

export const useGetListTasks = (listId?: string) => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<ListTasks, Error> = useQuery<ListTasks, Error>(['list-tasks', listId], async () => client.request(
    gql`
    query AdminGetListTasks($listId: String!) {
      adminGetListTasks(listId: $listId) {
        todo {
          id
          name
          text_content
          status {
            status
            color
          }
        }
        finished {
          id
          name
          text_content
          status {
            status
            color
          }
        }
      }
    }
    `,
    {
      listId
    }
  ).catch((error) => console.error(error)));
  return data;
};
