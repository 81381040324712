import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import RouterConfig from 'routes/RouterConfig';
import { AuthProvider } from 'routes/Auth/AuthProvider';
import { Toaster } from 'react-hot-toast';

// Redux
import { Provider } from 'react-redux';
import store from 'redux/store';
import { CookiesProvider } from 'react-cookie';
import { Application } from 'react-rainbow-components';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from 'services/config/QueryClient';

const App = () => (
  <div className="App">
    <Application
      theme={{
        rainbow: {
          palette: {
            brand: '#6371E0',
          },
        },
      }}
    >
      <CookiesProvider>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <BrowserRouter>
                <RouterConfig />
                <Toaster position="bottom-center" />
              </BrowserRouter>
            </AuthProvider>
            {/* <ReactQueryDevtools initialIsOpen /> */}
          </QueryClientProvider>
        </Provider>
      </CookiesProvider>
    </Application>
  </div>
);

export default App;
