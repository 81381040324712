// React
import Button from 'components/General/Button/Button';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainCalendarsSlice, toggleAppointmentViewModal } from '../CalendarSlice';
import { setRescheduleModal } from 'pages/Dashboard/Course/Session/SessionsSlice';
import moment from 'moment-timezone';

const AppointmentView: FC<any> = () => {
  const dispatch = useDispatch();
  const mainCalendarState = useSelector((state: { mainCalendar: MainCalendarsSlice }) => state.mainCalendar);

  return (
    <div className="appt-view-container">
      <h3>{`Course: ${mainCalendarState.plan.session?.course}`}</h3>
      <h3>{`Student: ${mainCalendarState.plan.session?.studentName}`}</h3>
      <h3>{`Teacher: ${mainCalendarState.plan.session?.teacherName}`}</h3>
      <h3>{`Session number: ${mainCalendarState.plan.session?.sessionNumber}`}</h3>
      <h3>{`Date: ${moment(mainCalendarState.plan.session?.startDate).format('YYYY-MM-DD')}`}</h3>
      <h3>{`Time: ${moment(mainCalendarState.plan.session?.startDate).format('h:mm a')} - ${moment(mainCalendarState.plan.session?.endDate).format('h:mm a')}`}</h3>

      <div className="edit-button">
        <Button
          color="primary"
          onClick={() => {
            dispatch(toggleAppointmentViewModal(false));
            dispatch(setRescheduleModal(true));
          }}
        >
          Change date
        </Button>
      </div>
    </div>
  );
};

export default AppointmentView;
