// Components
import Modal from 'components/General/Modal/Modal';
import OnboardingCard from 'components/Onboarding/OnboardingCard/OnboardingCard';
import Card from 'components/General/Card/Card';

import { useLogic } from './Schedule.logic';

// React grid system
import { Row, Col, Container } from 'react-grid-system';

// Styling
import './Schedule.scss';

// Custom Scrollbar
import { Scrollbars } from 'react-custom-scrollbars';

// Componenets
import { ConfirmSchedule } from './ConfirmSchedule/ConfirmSchedule';
import ProfileCard from 'components/General/ProfileCard/ProfileCard';
import ScheduleCalendar from './Calendar/Calendar';
import { SectionLoader } from 'components/General/Loader/Loader';

// Icons
import { Sliders } from 'react-feather';

// Redux
import { CalendarState } from './Calendar/Calendar.slice';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { STUDENT_DASHBOARD } from 'routes/CONSTANTS';
import { TeacherFilter } from './TeacherFilter/TeacherFilter';
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { ComplaintModal } from './ComplaintModal/ComplaintModal';
import Button from 'components/General/Button/Button';

const Schedule = () => {
  const { acuityCalendarID } = useSelector((state: { calendar: CalendarState }) => state.calendar);
  const navigate = useNavigate();
  const { userId } = useParams();
  const {
    teachersQuery,
    isLoadingTeachers,
    onboardingState,
    showTeacherProfile,
    teacherData,
    setShowComplaintModal,
    setShowTeacherProfile,
    setTeacherData,
    handleConfirmSchedule,
    handleTeacherSelect,
    isLoading,
    showFiltersModal,
    setShowFiltersModal,
    setFilter,
    dispatch,
    resetTimeSlots,
    setComplaintModal,
    previousPage,
    setSelectedTeacher,
    setShowConfirmation,
    setPlanData
  } = useLogic();

  return (
    <>
      <OnboardingCard style={{ padding: 0 }}>
        <div className={`confirmation ${onboardingState.showConfirmation ? 'show' : 'hide'}`}>
          <ConfirmSchedule
            onConfirm={handleConfirmSchedule}
            isLoading={isLoading()}
          />
        </div>
        <Row
          className={`main-container ${onboardingState.showConfirmation ? 'show' : 'show'}`}
          style={{ display: onboardingState.showConfirmation ? 'none' : 'flex', justifyContent: 'center' }}
        >
          <Col
            className={`left-panel-container ${onboardingState.selectedTeacher ? 'hide' : 'show'}`}
            style={{
              flex: 'none'
            }}
          >
            <ScheduleCalendar setShowComplaintModal={setShowComplaintModal} />
            {!userId && (
            <h3 className="pb-3">
              <a
                className="skip"
                href="#"
                onClick={() => {
                  navigate(STUDENT_DASHBOARD);
                  dispatch(resetTimeSlots());
                }}
              >
                Skip
              </a>

            </h3>
            )}
          </Col>
          <Col
            className={`right-panel-container ${onboardingState.selectedTeacher ? 'show' : 'hide'}`}
          >
            <Scrollbars
              autoHide
              renderThumbVertical={(props) => (
                <div
                  {...props}
                  className="thumb-vertical"
                />
              )}
            >
              {onboardingState.selectedTimeSlots.length > 0 ? (
                <ConfirmSchedule
                  onConfirm={handleConfirmSchedule}
                  isLoading={isLoading()}
                />
              ) : (
                <>
                  { showTeacherProfile ? (
                    <div className={`teachers ${onboardingState.selectedTimeSlots.length > 0 && 'one-teacher'}`}>
                      <div className="cards-container-header pb-2">
                        <h3>{`${onboardingState.plan.course} Teachers`}</h3>
                        <div className="filter-icon">
                          <ButtonBase
                            tagName="div"
                            onClick={() => setShowFiltersModal(true)}
                          >
                            <Sliders />
                          </ButtonBase>
                        </div>
                      </div>
                      <div className="cards-container">
                        {isLoadingTeachers && <div className="pt-5 pb-5"><SectionLoader /></div>}
                        { teachersQuery?.courseTeachers.teachers.map((item) => (
                          <div
                            className={`teacher-card-container mt-2 ${Number(item.acuityDaysAvailable) < Number(onboardingState?.plan.sessionsCount) && 'disable-teacher-card'}`}
                            key={item.id}
                          >
                            <Card
                              data-tip={(onboardingState.selectedTimeSlots.length > 0 && acuityCalendarID !== item.acuityCalendarId) ? 'You should schedule all sessions with one teacher' : ''}
                              data-type="warning"
                              key={item.id}
                              city={item.city}
                              country={item.country}
                              user={{
                                first_name: item.user.first_name,
                                last_name: item.user.last_name,
                                picture: item.user.picture
                              }}
                              yearsOfExperience={item.yearsOfExperience}
                              tutoringHours={item.tutoringHours}
                              rate={item.rate}
                              onClick={(e) => {
                                e.preventDefault();
                                if (Number(item.acuityDaysAvailable) < Number(onboardingState?.plan.sessionsCount)) { return; }
                                handleTeacherSelect({
                                  // eslint-disable-next-line @typescript-eslint/dot-notation
                                  acuityAppTypeId: item.acuityAppTypeIds ? item.acuityAppTypeIds[onboardingState.plan.course || 'SAT'].twoHours : 0,
                                  acuityCalendarId: item.acuityCalendarId || 0,
                                  id: item.id || 0
                                });
                                dispatch(setPlanData({ ...onboardingState.plan, teacherID: item.id, typeID: item.acuityAppTypeIds ? item.acuityAppTypeIds[onboardingState.plan.course || 'SAT'].twoHours : 0 }));
                                dispatch(setSelectedTeacher(true));
                              }}
                              onViewProfileClick={(e) => {
                                if (onboardingState.selectedTimeSlots.length > 0 && acuityCalendarID !== item.acuityCalendarId) { return; }
                                e.preventDefault();
                                const data = {
                                  country: item.country,
                                  city: item.city,
                                  tutoringHours: item.tutoringHours,
                                  yearsOfExperience: item.yearsOfExperience,
                                  rate: item.rate,
                                  description: item.description,
                                  education: item.education,
                                  university: item.university,
                                  courses: item.courses,
                                  user: {
                                    first_name: item.user.first_name,
                                    last_name: item.user.last_name,
                                    picture: item.user.picture,
                                  }
                                };
                                setTeacherData(data);
                                setShowTeacherProfile(!showTeacherProfile);
                              }}
                              selected={acuityCalendarID === item.acuityCalendarId}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Container>
                      <div className="profile-card-container mt-3">
                        <ProfileCard
                          user={{
                            first_name: teacherData?.user.first_name,
                            last_name: teacherData?.user.last_name,
                            picture: teacherData?.user.picture,
                          }}
                          country={teacherData?.country}
                          city={teacherData?.city}
                          description={teacherData?.description}
                          education={teacherData?.education}
                          university={teacherData?.university}
                          courses={teacherData?.courses}
                          tutoringHours={teacherData?.tutoringHours}
                          yearsOfExperience={teacherData?.yearsOfExperience}
                          rate={teacherData?.rate}
                          onClickBack={() => setShowTeacherProfile(true)}
                        />
                      </div>

                    </Container>
                  )}
                </>
              )}
            </Scrollbars>
          </Col>
          <Modal
            isClosble
            isOpen={onboardingState.showComplaintModal}
            onRequestClose={() => dispatch(setComplaintModal(false))}
          >
            <ComplaintModal />
          </Modal>
          <Modal
            isClosble
            isOpen={showFiltersModal}
            onRequestClose={() => setShowFiltersModal(false)}
          >
            <TeacherFilter
              onFilter={(filter) => {
                setFilter(filter[0]);
                setShowFiltersModal(false);
              }}
            />
          </Modal>
        </Row>
      </OnboardingCard>
      <div className="skip-container mb-3 mt-2">
        <div className="back-container mb-2">
          <Button
            color="white"
            onClick={() => {
              if (onboardingState.showConfirmation) {
                dispatch(setShowConfirmation(false));
              } else {
                dispatch(previousPage());
              }
            }}
          >
            Back
          </Button>
        </div>
        {!userId && (
        <h3>
          <a
            href="#"
            onClick={() => {
              navigate(STUDENT_DASHBOARD);
              dispatch(resetTimeSlots());
            }}
          >
            Skip
          </a>

        </h3>
        )}
      </div>
    </>
  );
};
export default Schedule;
