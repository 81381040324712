import { useLogic } from './Statistics.logic';
import ArrowsIcon from 'assets/images/arrows-up-down.svg';
import ChatIcon from 'assets/images/active-student.svg';
import YellowAvatarIcon from 'assets/images/yellow-avatar.svg';

// Styling
import './Statistics.scss';
import { StatisticsCard } from 'components/Dashboard/StatisticsCard/StatisticsCard';

export const Statistics = () => {
  const { statistics, isLoading } = useLogic();

  return (
    <div className="teachers-statistics-cards-container">
      <StatisticsCard
        image={ArrowsIcon}
        count={statistics?.adminAllTeachersStat.totalTeachers}
        description="All teachers"
        isLoading={isLoading}
      />
      <StatisticsCard
        image={ChatIcon}
        count={statistics?.adminAllTeachersStat.activeTeachers}
        description="Active teachers"
        isLoading={isLoading}
      />
      <StatisticsCard
        image={YellowAvatarIcon}
        count={statistics?.adminAllTeachersStat.inactiveTeachers}
        description="Inactive teachers"
        isLoading={isLoading}
      />
    </div>
  );
};
