// Components
import { ButtonBase } from 'components/General/ButtonBase/ButtonBase';
import { CircleLoader } from 'components/General/Loader/Loader';
import TextField from 'components/General/TextField/TextField';

// uuid
import { v4 as uuid } from 'uuid';
import { setFilter, toggleFiltersModal } from '../HomeworkSlice';
import { useLogic } from './Filters.logic';

// Style
import './Filters.scss';

export const Filters = ({ isLoading }: { isLoading: boolean }) => {
  const {
    homeworkState,
    setSelectedFilter,
    dispatch,
    filters,
    search,
    // searchName,
    setSearch,
    setSearchName
  } = useLogic();

  return (
    <div className="students-filters-container">
      {(homeworkState.filter.status || homeworkState.filter.courses || homeworkState.filter.search || homeworkState.filter.searchName) && (
      <div className="filter-container selected">
        <ButtonBase
          tagName="div"
          onClick={() => {
            dispatch(setFilter({ courses: undefined, status: undefined, search: undefined, searchName: undefined }));
            setSearch('');
            setSearchName('');
          }}
        >
          <p>Clear all</p>
        </ButtonBase>
      </div>
      )}
      {filters.map((filter) => (
        <ButtonBase
          tagName="div"
          onClick={() => {
            setSelectedFilter(filter.filter);
            dispatch(setFilter({ selectedFilter: filter.filter }));
            dispatch(toggleFiltersModal(true));
          }}
          key={uuid()}
        >
          <div className={`filter-container ${filter.active && 'selected'}`}>
            <p>{filter.filter}</p>
          </div>
        </ButtonBase>
      ))}
      <div className="search-filter-container">
        <TextField
          placeholder="Search"
          variant="rounded"
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {/* <div className="search-filter-container">
        <TextField
          placeholder="Search assignment"
          variant="rounded"
          type="text"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
      </div> */}
      { isLoading && (
        <CircleLoader />
      )}
    </div>
  );
};
