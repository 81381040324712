// Services
import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';
import { UserNearestSessionQuery } from './types';

export const useUserNearestSession = () => {
  const { client } = graphqlConfig();

  const data: UseQueryResult<UserNearestSessionQuery, Error> = useQuery<UserNearestSessionQuery, Error>(['userNearestSession'], async () => client.request(
    gql`
    query userNearestSession{
      userNearestSession{
        id
        status
        studentLink
        teacherLink
        duration
        sessionNumber
        dueAt
        teacher{
          user{
            id
            first_name
            last_name
          }
        }
        class{
          classId
          user{
            id
          first_name
            last_name
        }
          plan{
            course{
              name
            }
          }
        }
      }
    }
    `
  ).catch((error) => console.error(error)));
  return data;
};
