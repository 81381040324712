import LineChart from 'components/AdminDashboard/LineChart/LineChart';
import { CourseStatisticsCard } from 'components/Dashboard/CourseStatisticsCard/CourseStatisticsCard';
import { StatTestCard } from 'components/Dashboard/StatTestCard/StatTestCard';
import moment from 'moment-timezone';
import { useLogic } from './ProgressDiagram.logic';
import './ProgressDiagram.scss';

export const ProgressDiagram = () => {
  const {
    data,
    selected,
    handleSelect,
    course,
    navigate
  } = useLogic();

  return (
    <div className="progress-diagram-container">
      <div className="progress-diagram-top">
        <div className="course-cards-container">
          <h3>Your course</h3>
          <div className="course-cards">
            {
              data?.adminGetUserClassTests?.map((card) => (
                <CourseStatisticsCard
                  status={card.status}
                  title={card.plan?.course?.name}
                  selectedCourse={card.id === selected.id}
                  onClick={() => handleSelect(card.id, card.plan?.course?.name)}
                  goToCourse={() => navigate(`/class/${card.id}/${card.classId}/${card.plan?.course?.name}/stream`)}
                />
              ))
            }
          </div>
        </div>
      </div>
      <div className="progress-diagram-bottom">
        <div className="test-result-cards-container">
          <h3>Practice test result</h3>
          <div className="test-result-cards">
            {
              course?.tests?.length ? (
                course?.tests?.map((record) => (
                  <StatTestCard
                    selected
                    title={record.name}
                    date={moment(record.dueDate).format('MM/DD/YYYY')}
                    score={record.receivedMark}
                    course={data?.adminGetUserClassTests ? data?.adminGetUserClassTests[0].plan?.course?.name : ''}
                  />
                ))
              ) : (
                <h2>No results yet😇</h2>
              )
            }
          </div>
        </div>
        <div className="test-chart-container">
          <h3>Prictice test result chart</h3>
          <div className="chart">
            <LineChart
              maxStep={10}
              data={{
                labels: course?.dates.map((date) => moment(date).format('YYYY-MM-DD')) || [],
                datasets: [{
                  label: 'Grade',
                  data: course?.marksArray || [],
                  backgroundColor: '#6371E0',
                  borderColor: '#6371E0',
                  pointRadius: 10,
                  pointBackgroundColor: '#6371E0',
                  pointHoverBackgroundColor: '#6371E0',
                  pointHoverRadius: 11,
                  type: 'line',
                }]
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
