import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { graphqlConfig } from 'services/config/graphqlConfig';

export const useAdminDraftPlan = () => {
  const { client } = graphqlConfig();
  const Mutation = useMutation(async ({ userId, courseId, planId }: { userId: number, courseId?: number, planId: number }) => client.request(
    gql`
    mutation AdminDraftPlan($courseId: Float!, $userId: Float!, $planId: Float) {
      adminDraftPlan(courseId: $courseId, userId: $userId, planId: $planId) {
        message
      }
    }
    `,
    {
      planId,
      courseId,
      userId
    }
  ));
  return Mutation;
};
