import { useEffect, useMemo, useState } from 'react';

// React cookie
import { useCookies } from 'react-cookie';

// React query mutations
import { useLogin, useResetPassword, useSignup, useForgotPassword, useVerifySignup, useResendCode, useVerifyResetPassword, useGoogleAuth, useFacebookAuth } from 'services/Auth/useAuth';

// Types
import { AuthUser } from './auth.types';

import onboarding from 'pages/Onboarding/OnboardingSlice';
import { useDispatch } from 'react-redux';

export const useAuthProviderLogic = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['userinfo']);
  const { updateUserInfo, setPlanData } = onboarding.actions;
  const dispatch = useDispatch();

  /**
    * An indicator for loading initial data
    * This is important to make sure that everything is loaded and initialized
    * before rendering any component
  */
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [user, setUser] = useState<AuthUser | null>();

  // Login mutation
  const { mutateAsync: login, isLoading: isLoggingIn } = useLogin();

  // Sign up mutations
  const { mutateAsync: register, isLoading: isSingingUp } = useSignup();
  const { mutateAsync: verify, isLoading: isVerifyingSignUp } = useVerifySignup();
  const { mutateAsync: resend } = useResendCode();

  // Reset Password nutations
  const { mutateAsync: recoverPassword, isLoading: isRequestingForgotPassword } = useForgotPassword();
  const { mutateAsync: verifyReset, isLoading: isVerifyingResetPassword } = useVerifyResetPassword();
  const { mutateAsync: changePassword, isLoading: isResettingPassword } = useResetPassword();

  // Social Auth mutations
  const { mutateAsync: googleAuth, isLoading: isAuthenticatingGoogle } = useGoogleAuth();
  const { mutateAsync: facebookAuth, isLoading: isAuthenticatingFacebook } = useFacebookAuth();

  /**
    * Loading state
    * Since we have only one mutation in each authentication page
    * we only need a general loading state
  */
  const isLoading = () => isLoggingIn || isSingingUp || isVerifyingSignUp || isRequestingForgotPassword || isVerifyingResetPassword || isResettingPassword || isAuthenticatingGoogle || isAuthenticatingFacebook;

  /**
     * Function: Login
     * @param {email, password}
     * Description:
     * Check username, password validaty through B.E endpoint
     * if the information is correct then store user's token, details in the context state.
     * The user's token, details will be also stored in cookies for re-initializing user's data
     * once the page get reloaded
  */
  const signin = async (data: { email: string; password: string; }) => {
    const res = await login(data);
    const userInfo = res.data;
    setCookie('userinfo', userInfo, { path: '/' });
    console.log(userInfo);
    setUser(userInfo);
    return res;
  };

  /**
     * Function: SignOut
     * @param {}
     * Description:
     * Reset user's object to be null and remove any relevant cookies
  */

  const signout = () => {
    setUser(null);
    removeCookie('userinfo');
    dispatch(updateUserInfo({}));
    dispatch(setPlanData({}));

    // FIXME
    // Automatically signing in after signout.
    // temp solved with setTimeout
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  /**
     * Function: Sign Up
     * @param {email, password}
     * Description:
     * Check username availability through B.E endpoint
     * if the information is correct then the user must be got registered in the DB.
     * The user will need an extra step to verify his email
  */
  const signup = async (data: { email: string; password: string; }) => register(data);

  /**
     * Function: Sign Up Verification
     * @param {email, code}
     * Description:
     * Check if the code matches the email through B.E endpoint
     * if the information is correct then the user must be got verified in the DB.
     * Store user's token, details in the context state.
     * The user's token, details will be also stored in cookies for re-initializing user's data
     * once the page get reloaded
  */
  const verifySignup = async (data: { email: string; code: string }) => {
    const res = await verify(data);
    const userInfo = res.data;
    setCookie('userinfo', userInfo, { path: '/' });
    setUser(userInfo);
    return res;
  };

  /**
     * Function: Resend code
     * @param {email}
     * Description:
     * Resend verification code to user's email
  */
  const resendCode = (data: { email: string; }) => resend(data);

  /**
     * Function: Forgot password
     * @param {email}
     * Description:
     * Send resetting password instructions to user's email
  */
  const forgotPassword = async (data: { email: string; }) => recoverPassword(data);

  /**
     * Function: Reset Password Verification
     * @param {email, code}
     * Description:
     * Check if the code matches the email through B.E endpoint
  */
  const verifyResetPassword = async (data: { email: string; code: string }) => verifyReset(data);

  /**
     * Function: Set New Password
     * @param {email, newPassword, code}
     * Description:
     * Reset user's password
  */
  const setNewPassword = async (data: { email: string; password: string; code: string; }) => changePassword(data);

  /**
     * Function: socialAuth
     * @param {authType: facebook | google, token}
     * Description:
     * Reset user's password
  */
  const socialAuth = async (data: { authType: 'google' | 'facebook'; token: string; }) => {
    let res;
    if (data.authType === 'google') {
      res = await googleAuth({ token: data.token });
    } else {
      res = await facebookAuth({ token: data.token });
    }
    const userInfo = res.data;
    setCookie('userinfo', userInfo, { path: '/' });
    setUser(userInfo);
    return res;
  };

  // Load initial user's data from cookies
  useEffect(() => {
    if (cookies.userinfo && !user) {
      setUser(cookies.userinfo);
    } else {
      setUser(null);
    }
  }, []);

  // Update initial data loading indicator once initial data get loaded
  useEffect(() => {
    if (user !== undefined && loadingInitial) {
      setLoadingInitial(false);
    }
  }, [user]);

  /**
   * Make the provider update only when it should.
   * We only want to force re-renders if the user,
   * loading or initializing states change.
  */
  const memoedValue = useMemo(
    () => ({
      user,
      signin,
      signout,
      signup,
      verifySignup,
      resendCode,
      forgotPassword,
      verifyResetPassword,
      setNewPassword,
      isAuthenticating: isLoading(),
      loadingInitial,
      socialAuth
    }),
    [user, isLoading(), loadingInitial]
  );

  return memoedValue;
};
