// Styling
import { ClickUpTaskCard } from 'components/ClickUpTaskCard/ClickUpTaskCard';
// import { CalendarCourseCard } from 'components/Dashboard/CalendarCourseCard/CalendarCourseCard';
import DotsLoader from 'components/General/Loader/Loader';
import { AdminDashboardLayout } from 'layouts/AdminDashboardLayout/AdminDashboardLayout';
import { useLogic } from './Todos.logic';
import './Todos.scss';

import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  Appointments,
  CurrentTimeIndicator
} from '@devexpress/dx-react-scheduler-material-ui';
import Button from 'components/General/Button/Button';
import { CALENDAR, DASHBOARD } from 'routes/CONSTANTS';

export const Todos = () => {
  const {
    listTasks,
    isLoadingTasks,
    handleChangeStatus,
    isUpdatingStatus,
    updatedId,
    navigate
  } = useLogic();

  const schedulerData = [
    { startDate: '2022-06-09T08:20', endDate: '2022-06-09T09:20', title: 'Stand-up meeting' },
    { startDate: '2022-06-09T12:00', endDate: '2022-06-09T13:30', title: 'SAT session' },
  ];

  return (
    <AdminDashboardLayout>
      <div className="admin-todo-container">
        <div className="todos">
          <div className="tasks-todos">
            <h2>Your tasks</h2>
            <div className="tasks-container">
              {isLoadingTasks ? (
                <DotsLoader />
              ) : (
                listTasks?.adminGetListTasks.todo?.map((task) => (
                  <ClickUpTaskCard
                    key={task.id}
                    color={task.status.color}
                    onClick={() => handleChangeStatus(String(task.id), true)}
                    data={task.name}
                    isLoading={isUpdatingStatus && (String(task.id) === updatedId)}
                  />
                ))
              )}
              {listTasks?.adminGetListTasks.todo?.length === 0 && (
                <h3>No tasks!😇</h3>
              )}
            </div>
          </div>
          <div className="finished-todos">
            <h2>Finished tasks</h2>
            <div className="tasks-container">
              {isLoadingTasks ? (
                <DotsLoader />
              ) : (
                listTasks?.adminGetListTasks.finished?.map((task) => (
                  <ClickUpTaskCard
                    key={task.id}
                    color={task.status.color}
                    onClick={() => handleChangeStatus(String(task.id), false)}
                    data={task.name}
                    isLoading={isUpdatingStatus && (String(task.id) === updatedId)}
                    done
                  />
                ))
              )}
              {listTasks?.adminGetListTasks.todo?.length === 0 && (
                <h3>No tasks!😇</h3>
              )}
            </div>
          </div>
        </div>

        <div className="calendar">
          <div className="calendar-header">
            <h2 className="ml-2 mb-3">Todays sessions</h2>
            <div className="go-to-calendar-button">
              <Button
                color="white"
                variant="small"
                onClick={() => navigate(`/${DASHBOARD}/${CALENDAR}`)}
              >
                Calendar
              </Button>
            </div>
          </div>
          <Paper>
            <Scheduler
              data={schedulerData}
              height={565}
            >
              <ViewState
                currentDate={new Date()}
              />
              <DayView
                startDayHour={0}
                endDayHour={23}
                cellDuration={60}
              />
              <Appointments />
              <CurrentTimeIndicator />
            </Scheduler>
          </Paper>
        </div>

      </div>
    </AdminDashboardLayout>
  );
};
